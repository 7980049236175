import { SerializedEditor, SerializedElementNode } from 'lexical';
import { useMemo } from 'react';

export const useIsCommentEmpty = (commentState: string) => {
  return useMemo(() => {
    const state = JSON.parse(commentState) as SerializedEditor;
    const rootNode = state?.editorState?.root?.children[0] as SerializedElementNode;

    if (!rootNode?.children[0]) {
      return true;
    }

    return false;
  }, [commentState]);
};

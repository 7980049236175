import { ColumnSizingState, OnChangeFn } from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';

export function useTableColumnSizing(tableId: string, saveDebounceMs = 16) {
  const columnSizingKey = useMemo(() => `${tableId}:column-sizing`, [tableId]);
  const [columnSizing, setColumnSizing] = useState<ColumnSizingState>(
    JSON.parse(localStorage.getItem(columnSizingKey) ?? '{}'),
  );

  const onColumnSizingChange: OnChangeFn<ColumnSizingState> = setColumnSizing;

  useEffect(() => {
    const timeout = setTimeout(
      () => localStorage.setItem(columnSizingKey, JSON.stringify(columnSizing)),
      saveDebounceMs,
    );
    return () => clearTimeout(timeout);
  }, [columnSizing, columnSizingKey, saveDebounceMs]);

  return { columnSizing, onColumnSizingChange };
}

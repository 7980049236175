import { ReactNode } from 'react';

import { BaseDynamicFormFieldConfig } from './types';

export type GenericDynamicFormComponentRegistry = Record<string, DynamicFormFieldComponent>;

export interface DynamicFormComponentRegistry<
  TRegistry extends GenericDynamicFormComponentRegistry,
  TConfigExtra extends object = {},
> {
  register<TKind extends string, TComponent extends DynamicFormFieldComponent>(
    kind: TKind,
    component: TComponent,
  ): DynamicFormComponentRegistry<TRegistry & Record<TKind, TComponent>, TConfigExtra>;
  get<TKind extends keyof TRegistry>(kind: TKind): TRegistry[TKind];
}

export type InferDynamicFormComponentRegistry<T> = T extends DynamicFormComponentRegistry<
  infer TRegistry,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any
>
  ? TRegistry
  : never;

export type InferDynamicFormComponentConfigExtra<T> = T extends DynamicFormComponentRegistry<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any,
  infer TConfigExtra
>
  ? TConfigExtra
  : never;

export type AnyDynamicFormComponentRegistry =
  DynamicFormComponentRegistry<GenericDynamicFormComponentRegistry>;

export type DynamicFormFieldConfig<
  T extends AnyDynamicFormComponentRegistry,
  TRegistry extends GenericDynamicFormComponentRegistry = InferDynamicFormComponentRegistry<T>,
  TConfigExtra extends object = InferDynamicFormComponentConfigExtra<T>,
> = {
  [TKind in keyof TRegistry]: TKind extends string
    ? TRegistry[TKind] extends DynamicFormFieldComponent<infer TConfig>
      ? TConfig & WithDynamicFormFieldConfigKind<TKind> & TConfigExtra
      : never
    : never;
}[keyof TRegistry];

export type PickDynamicFormFieldConfig<
  T,
  TKind extends keyof InferDynamicFormComponentRegistry<T> & string,
> = T extends WithDynamicFormFieldConfigKind<TKind> ? T : never;

export interface WithDynamicFormFieldConfigKind<TKind extends string> {
  kind: TKind;
}

export type DynamicFormFieldComponent<
  TConfig extends BaseDynamicFormFieldConfig = BaseDynamicFormFieldConfig,
> = (config: DynamicFormComponentProps<TConfig>) => ReactNode;

export interface DynamicFormComponentProps<TConfig extends BaseDynamicFormFieldConfig> {
  config: TConfig;
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-declaration-merging
export class DynamicFormComponentRegistry<
  TRegistry extends GenericDynamicFormComponentRegistry,
  TConfigExtra extends object,
> implements DynamicFormComponentRegistry<TRegistry, TConfigExtra>
{
  constructor(protected readonly registry: TRegistry = Object.create(null)) {}

  register<TKind extends string, TComponent extends DynamicFormFieldComponent>(
    kind: TKind,
    component: DynamicFormFieldComponent,
  ): DynamicFormComponentRegistry<TRegistry & Record<TKind, TComponent>, TConfigExtra> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.registry[kind] = component as any;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this as any;
  }

  get<TKind extends keyof TRegistry>(kind: TKind): TRegistry[TKind] {
    const component = this.registry[kind];

    if (!component) {
      throw new Error(`No DynamicFormFieldComponent registered for kind '${String(kind)}'!`);
    }

    return component;
  }
}

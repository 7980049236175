import { datadogLogs } from '@datadog/browser-logs';
import { isNonNullable, toError } from '@main/shared/utils';
import { stringify } from 'csv-stringify/browser/esm';

export function exportToCSV<T>(arrOfData: T[], fileName: string) {
  // Convert array of objects to CSV format
  stringify(arrOfData, { header: true, quoted_empty: true }, (err, output) => {
    if (err) {
      datadogLogs.logger.error(`Couldn't make export to csv`, {}, toError(err));
      return;
    }

    const blob = new Blob([output], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);

    // Create a temporary link element
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${fileName}.csv`);
    link.style.display = 'none';

    // Append the link to the document body
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  });
}

export type CSVInput = Record<string, CSVValue | CSVValue[]>;
export type CSVValue = string | number | undefined | null;

export function formatCSVInput(data: CSVInput[]) {
  return data.map((input) => {
    const entries = Object.entries(input).map(([key, value]) => {
      if (Array.isArray(value)) {
        const formattedValue = value
          .filter(isNonNullable)
          .map((value) => `'${value}'`)
          .join(', ');
        return [key, formattedValue];
      }
      return [key, value];
    });
    return Object.fromEntries(entries);
  });
}

import { createAction, createReducer, createSelector, Reducer } from '@reduxjs/toolkit';
import { ReactNode } from 'react';

import { BulkDownloaderProgress, BulkDownloaderStatus, DownloadFile } from './bulk-downloader';
import { BulkDownloaderLogger } from './logger';
import { StreamDownloader } from './stream-downloader';
import { StreamSink } from './stream-sink';

export interface BulkDownloaderState {
  downloads: Record<string, BulkDownload>;
  lastDownloadId: number;
}

export interface BulkDownload<TFiles = unknown> {
  status: BulkDownloaderStatus;
  progress: number;
  downloader: BulkDownloaderProgress<TFiles>;
}

const initialState: BulkDownloaderState = {
  downloads: {},
  lastDownloadId: 0,
};

export const startBulkDownload = createAction<{
  downloadId: string;
  files: DownloadFile<unknown>[];
  downloader: StreamDownloader<unknown>;
  sink: StreamSink;
  title?: ReactNode;
  progressTitle?(progress: number): ReactNode;
  successTitle?: ReactNode;
  errorTitle?: ReactNode;
  logger?: BulkDownloaderLogger;
}>('start bulk download');
export const bulkDownloadStarted = createAction<{
  downloadId: string;
  downloader: BulkDownloaderProgress<unknown>;
}>('bulk download started');
export const bulkDownloadUpdated = createAction<{
  downloadId: string;
  progress?: number;
  status?: BulkDownloaderStatus;
}>('bulk download updated');
export const bulkDownloadCompleted = createAction<{ downloadId: string }>(
  'bulk download completed',
);

export const bulkDownloaderReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(bulkDownloadStarted, (state, action) => {
      const downloader = action.payload.downloader;

      state.lastDownloadId++;
      state.downloads[action.payload.downloadId] = {
        downloader,
        status: downloader.getStatus(),
        progress: downloader.getProgress(),
      };
    })
    .addCase(bulkDownloadUpdated, (state, action) => {
      const download = state.downloads[action.payload.downloadId];

      if (!download) {
        return;
      }

      if (action.payload.progress !== undefined) {
        download.progress = action.payload.progress;
      }

      if (action.payload.status !== undefined) {
        download.status = action.payload.status;
      }
    })
    .addCase(bulkDownloadCompleted, (state, action) => {
      delete state.downloads[action.payload.downloadId];
    }),
);

export const BulkDownloaderPath = 'bulkDownloader' as const;

export const bulkDownloaderSlice = {
  [BulkDownloaderPath]: bulkDownloaderReducer,
};

export interface BulkDownloaderSlice {
  [BulkDownloaderPath]: BulkDownloaderState;
}

export type WithBulkDownloaderReducer = {
  [K in keyof BulkDownloaderSlice]: Reducer<BulkDownloaderSlice[K]>;
};

export const getBulkDownloaderState = (state: BulkDownloaderSlice) => state[BulkDownloaderPath];

export const getAllBulkDownloads = createSelector(
  [getBulkDownloaderState],
  (state) => state.downloads,
);

export const getBulkDownload = createSelector(
  [getAllBulkDownloads, (_, downloadId: string) => downloadId],
  (downloads, downloadId) => downloads[downloadId],
);

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
export type EvidenceProgramFragment = {
  __typename?: 'evidences';
  control_evidences: Array<{
    __typename?: 'control_evidences';
    status: ClientTypes.ControlEvidenceStatus;
    control: {
      __typename?: 'controls';
      programs: Array<{ __typename?: 'program_controls'; program_id: string }>;
    };
  }>;
};

export const EvidenceProgramFragmentDoc = `
    fragment EvidenceProgram on evidences {
  control_evidences {
    status
    control {
      programs: program_controls {
        program_id
      }
    }
  }
}
    `;

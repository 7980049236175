/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type AddCommentMutationVariables = Types.Exact<{
  input: Types.Comments_Insert_Input;
}>;

export type AddCommentMutation = {
  __typename?: 'mutation_root';
  insert_comments_one?: { __typename?: 'comments'; id: string; text: string };
};

export type DeleteCommentMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
}>;

export type DeleteCommentMutation = {
  __typename?: 'mutation_root';
  delete_comments_by_pk?: { __typename?: 'comments'; id: string };
};

export type AddCommentUploadsMutationVariables = Types.Exact<{
  input: Array<Types.Comment_Uploads_Insert_Input> | Types.Comment_Uploads_Insert_Input;
}>;

export type AddCommentUploadsMutation = {
  __typename?: 'mutation_root';
  insert_comment_uploads?: {
    __typename?: 'comment_uploads_mutation_response';
    affected_rows: number;
  };
};

export type DeleteCommentUploadMutationVariables = Types.Exact<{
  commentId: Types.Scalars['uuid']['input'];
  fileId: Types.Scalars['uuid']['input'];
}>;

export type DeleteCommentUploadMutation = {
  __typename?: 'mutation_root';
  delete_comment_uploads?: {
    __typename?: 'comment_uploads_mutation_response';
    affected_rows: number;
  };
};

export const AddCommentDocument = `
    mutation AddComment($input: comments_insert_input!) {
  insert_comments_one(
    object: $input
    on_conflict: {constraint: comments_pkey, update_columns: text}
  ) {
    id
    text
  }
}
    ` as string & TypedDocumentNode<AddCommentMutation, AddCommentMutationVariables>;
export const DeleteCommentDocument = `
    mutation DeleteComment($id: uuid!) {
  delete_comments_by_pk(id: $id) {
    id
  }
}
    ` as string & TypedDocumentNode<DeleteCommentMutation, DeleteCommentMutationVariables>;
export const AddCommentUploadsDocument = `
    mutation AddCommentUploads($input: [comment_uploads_insert_input!]!) {
  insert_comment_uploads(objects: $input) {
    affected_rows
  }
}
    ` as string & TypedDocumentNode<AddCommentUploadsMutation, AddCommentUploadsMutationVariables>;
export const DeleteCommentUploadDocument = `
    mutation DeleteCommentUpload($commentId: uuid!, $fileId: uuid!) {
  delete_comment_uploads(
    where: {comment_id: {_eq: $commentId}, file_id: {_eq: $fileId}}
  ) {
    affected_rows
  }
}
    ` as string &
  TypedDocumentNode<DeleteCommentUploadMutation, DeleteCommentUploadMutationVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    AddComment: build.mutation<AddCommentMutation, AddCommentMutationVariables>({
      query: (variables) => ({ document: AddCommentDocument, variables }),
    }),
    DeleteComment: build.mutation<DeleteCommentMutation, DeleteCommentMutationVariables>({
      query: (variables) => ({ document: DeleteCommentDocument, variables }),
    }),
    AddCommentUploads: build.mutation<
      AddCommentUploadsMutation,
      AddCommentUploadsMutationVariables
    >({
      query: (variables) => ({ document: AddCommentUploadsDocument, variables }),
    }),
    DeleteCommentUpload: build.mutation<
      DeleteCommentUploadMutation,
      DeleteCommentUploadMutationVariables
    >({
      query: (variables) => ({ document: DeleteCommentUploadDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useAddCommentMutation,
  useDeleteCommentMutation,
  useAddCommentUploadsMutation,
  useDeleteCommentUploadMutation,
} = injectedRtkApi;

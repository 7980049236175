/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { OrganizationEvidenceFragmentDoc } from '../fragments/OrganizationEvidenceFragment.generated';
import { EvidenceProgramFragmentDoc } from '../fragments/EvidenceProgramFragment.generated';
import { api } from '@main/graphql/shared-api';
export type GetOrganizationEvidencesQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['uuid']['input'];
}>;

export type GetOrganizationEvidencesQuery = {
  __typename?: 'query_root';
  evidences: Array<{
    __typename?: 'evidences';
    id: string;
    name?: string;
    updated_at: string;
    owner_id?: string;
    description?: string;
    internal_id?: string;
    controls_aggregate_status: ClientTypes.EvidenceStatus;
    created_at: string;
    is_confidential: boolean;
    tags: Array<{
      __typename?: 'evidence_tags';
      id: string;
      tag: { __typename?: 'organization_evidence_tags'; id: string; name: string };
    }>;
    evidence_versions: Array<{
      __typename?: 'evidence_versions';
      validity_start: string;
      url?: string;
      created_by?: string;
      updated_at: string;
      evidence_version_file?: {
        __typename?: 'evidence_version_file';
        file: { __typename?: 'files'; id: string; name?: string };
      };
    }>;
    acl: Array<{ __typename?: 'evidence_acl'; id: string; user_id: string }>;
    control_evidences: Array<{
      __typename?: 'control_evidences';
      status: ClientTypes.ControlEvidenceStatus;
      control: {
        __typename?: 'controls';
        programs: Array<{ __typename?: 'program_controls'; program_id: string }>;
      };
    }>;
  }>;
};

export const GetOrganizationEvidencesDocument = `
    query GetOrganizationEvidences($organizationId: uuid!) {
  evidences(
    where: {organization_id: {_eq: $organizationId}}
    order_by: {internal_id: asc}
  ) {
    ...OrganizationEvidence
  }
}
    ${OrganizationEvidenceFragmentDoc}
${EvidenceProgramFragmentDoc}` as string &
  TypedDocumentNode<GetOrganizationEvidencesQuery, GetOrganizationEvidencesQueryVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetOrganizationEvidences: build.query<
      GetOrganizationEvidencesQuery,
      GetOrganizationEvidencesQueryVariables
    >({
      query: (variables) => ({ document: GetOrganizationEvidencesDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetOrganizationEvidencesQuery, useLazyGetOrganizationEvidencesQuery } =
  injectedRtkApi;

// disabling only for
// eslint-disable-next-line @nx/enforce-module-boundaries
import type {
  DynamicFormFieldConfig,
  QuestionnaireFormComponentRegistry,
} from '@main/dynamic-form';

import type {
  Comments,
  Control_Evidences,
  Controls,
  Policies,
  Policy_Acknowledgement_Statuses_Enum,
  Policy_Approval_Statuses_Enum,
  Policy_Approval_Users,
  Policy_Approvals,
  Policy_Versions,
  Risk_Controls,
  Risks,
  Vendor_Questionnaire_Statuses_Enum,
  Vendor_Questionnaires,
  Vendor_Risks,
  Vendors,
} from './types.generated';

export type { ColumnFiltersState, SortingState } from '@tanstack/react-table';

export * from './integration-configs';

declare module 'graphql' {
  interface GraphQLErrorExtensions {
    code?: GraphqlExtensionsCode;
  }
}

export type UserMetaData = Partial<{
  firstName: string;
  lastName: string;
  isOnboardingFinished: boolean;
}>;

type Properties = 'category' | 'group' | 'criteria';
export type ControlTags = Array<Record<Properties, string[]>>;

export enum ControlEvidenceStatus {
  CALCULATING = 'Calculating',
  VALID = 'Valid',
  INVALID = 'Invalid',
  AT_RISK = 'At risk',
}

export enum ControlStatus {
  PENDING = 'Pending',
  NOT_RELEVANT = 'Not relevant',
  VALID = 'Valid',
  AT_RISK = 'At risk',
  FAILING = 'Failing',
}

export enum TaskStatus {
  PENDING = 'Pending',
  IN_PROGRESS = 'In progress',
  COMPLETED = 'Completed',
  OVERDUE = 'Overdue',
}

export enum VendorAssessmentStatus {
  PENDING = 'Pending',
  REVIEWED = 'Reviewed',
  EXPIRING = 'Expiring',
  OVERDUE = 'Overdue',
  TERMINATED = 'Terminated',
}

export enum RiskAssessmentStatus {
  PENDING = 'Pending',
  REVIEWED = 'Reviewed',
  EXPIRING = 'Expiring',
  OVERDUE = 'Overdue',
}

export enum EvidenceStatus {
  PENDING = 'Pending',
  VALID = 'Valid',
  AT_RISK = 'At risk',
  INVALID = 'Invalid',
}

export type RiskAuditRow =
  | { table_name: 'risks'; row_data: Risks; changed_fields: Risks }
  | { table_name: 'risk_controls'; row_data: Risk_Controls; changed_fields: Risk_Controls };
export type RiskRowData = RiskAuditRow['row_data'];
export type RiskHistoryTables = RiskAuditRow['table_name'];
export type RiskChangedFields = RiskAuditRow['changed_fields'];

export type ControlAuditRow =
  | { table_name: 'controls'; row_data: Controls; changed_fields: Controls }
  | {
      table_name: 'control_evidences';
      row_data: Control_Evidences;
      changed_fields: Control_Evidences;
    }
  | {
      table_name: 'comments';
      row_data: Comments;
      changed_fields: Comments;
    };
export type ControlRowData = ControlAuditRow['row_data'];
export type ControlHistoryTables = ControlAuditRow['table_name'];
export type ControlChangedFields = ControlAuditRow['changed_fields'];

export type PolicyAuditRow =
  | { table_name: 'policies'; row_data: Policies; changed_fields: Policies }
  | {
      table_name: 'policy_versions';
      row_data: Policy_Versions;
      changed_fields: Policy_Versions;
    }
  | {
      table_name: 'policy_approvals';
      row_data: Policy_Approvals;
      changed_fields: Policy_Approvals;
    }
  | {
      table_name: 'policy_approval_users';
      row_data: Policy_Approval_Users;
      changed_fields: Policy_Approval_Users;
    }
  | {
      table_name: 'comments';
      row_data: Comments;
      changed_fields: Comments;
    };
export type PolicyRowData = PolicyAuditRow['row_data'];
export type PolicyHistoryTables = PolicyAuditRow['table_name'];
export type PolicyChangedFields = PolicyAuditRow['changed_fields'];

export type VendorAuditRow =
  | { table_name: 'vendors'; row_data: Vendors; changed_fields: Vendors }
  | {
      table_name: 'vendor_questionnaires';
      row_data: Vendor_Questionnaires;
      changed_fields: Vendor_Questionnaires;
    }
  | {
      table_name: 'vendor_risks';
      row_data: Vendor_Risks;
      changed_fields: Vendor_Risks;
    }
  | {
      table_name: 'comments';
      row_data: Comments;
      changed_fields: Comments;
    };
export type VendorRowData = VendorAuditRow['row_data'];
export type VendorHistoryTables = VendorAuditRow['table_name'];
export type VendorChangedFields = VendorAuditRow['changed_fields'];

export type AuditTableHasuraUser = {
  'x-hasura-user-id': string;
  'x-hasura-impersonater-id'?: string;
} & (
  | {
      'x-hasura-role': 'user';
    }
  | { 'x-hasura-role': 'admin' }
);

export type AuditTableActions = 'I' | 'U' | 'D' | 'T';

export type ControlAIQuestions = Array<{
  heading: string;
  question: string;
  suggestion: string;
}>;

export type ControlAIReviewResult = Array<{
  heading: string;
  question: string;
  suggestion: string;
  openAIReview: 'PASSED' | 'FAILED';
}>;

export type GraphqlExtensionsCode =
  | 'constraint-violation'
  | 'USER_ALREADY_EXISTS'
  | 'USER_ALREADY_INVITED'
  | 'INVALID_INPUT'
  | 'NOT_FOUND'
  | 'DUPLICATE'
  | 'FORBIDDEN'
  | 'access-denied';

export type FormConfig = DynamicFormFieldConfig<QuestionnaireFormComponentRegistry>[];

export type VendorQuestionnaireAIReviewResult = Array<
  FormConfig[number] & {
    //VendorQuestionnaireAnswer
    answer?: {
      id: string;
      value_id: string;
      value_literal: string;
      comment: string;
    };
    //VendorQuestionnaireAssessment
    assessment?: {
      high_confidence?: boolean;
      rationale?: string;
    };
    //Criteria set in questionnaire form
    criteria?: string;
    message?: string;
    has_review?: boolean;
  }
>;

export type NotificationParams =
  | {
      assignorId: string;
      type?: 'acknowledgement-request' | 'approval-request';
    }
  | { controlStatus: ControlStatus }
  | {
      riskStatus: RiskAssessmentStatus;
    }
  | {
      vendorStatus: VendorAssessmentStatus;
    }
  | {
      questionnaireStatus: Vendor_Questionnaire_Statuses_Enum;
    }
  | {
      taskStatus: TaskStatus;
    }
  | {
      policyApprovalStatus:
        | Policy_Approval_Statuses_Enum.Expiring
        | Policy_Approval_Statuses_Enum.Overdue;
    }
  | {
      policyAcknowledgementStatus:
        | Policy_Acknowledgement_Statuses_Enum.Expiring
        | Policy_Acknowledgement_Statuses_Enum.Overdue;
    }
  | {
      vendorQuestionnaireStatus: Vendor_Questionnaire_Statuses_Enum;
    };

export type AiAutocompleteEntities =
  | 'risk-treatment-plan'
  | 'control-description'
  | 'risk-description';

export type AiCreateEntityInput =
  | AiCreateTaskFromControlFindingInput
  | AiCreateRiskFromControlFindingInput
  | AiCreateTaskFromVQFindingInput
  | AiCreateRiskFromVQFindingInput;

export interface AiCreateTaskFromControlFindingInput {
  entity: 'task-control-finding';
  findingId: string;
}

export interface AiCreateRiskFromControlFindingInput {
  entity: 'risk-control-finding';
  findingId: string;
}

export interface AiCreateTaskFromVQFindingInput {
  entity: 'task-vq-finding';
  questionNameId: string;
  vqReviewHistoryId: string;
}

export interface AiCreateRiskFromVQFindingInput {
  entity: 'risk-vq-finding';
  questionNameId: string;
  vqReviewHistoryId: string;
}

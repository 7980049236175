import {
  Box,
  Center,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Icon,
  IconButton,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import {
  ClipboardDocumentCheckIcon,
  FolderArrowDownIcon,
  FolderOpenIcon,
} from '@heroicons/react/24/outline';
import { useDrawerAnimation } from '@main/ui';
import { useTranslation } from 'react-i18next';

import { AssignedToMeTab, CreatedByMeTab } from './sidebar-tabs';

export const TaskSidebar = () => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { motionProps } = useDrawerAnimation();

  const tabColor = useColorModeValue('gray.400', 'gray.500');
  const activeTabColor = useColorModeValue('gray.700', 'white');
  const activeTabIndicatorColor = useColorModeValue('blue.500', 'purple.400');
  const tabListBorderColor = useColorModeValue('gray.200', 'gray.600');
  const tabListBgColor = useColorModeValue('white', 'gray.700');

  return (
    <>
      <Center position={'relative'} cursor={'pointer'} onClick={onOpen} w={9} h={9}>
        <IconButton
          color={'gray.500'}
          variant={'unstyled'}
          size={'xs'}
          aria-label="Open task sidebar"
          icon={<Icon as={ClipboardDocumentCheckIcon} w={6} h={6} />}
        />
      </Center>

      <Drawer size={'sm'} isOpen={isOpen} onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent motionProps={motionProps}>
          <DrawerCloseButton />
          <DrawerHeader fontSize={'xl'} fontWeight={'bold'}>
            {t('tasks.sidebar.header')}
          </DrawerHeader>
          <DrawerBody p={0} mb={16}>
            <Tabs variant="unstyled" isLazy position="relative">
              <Box position={'sticky'} top={0} zIndex={1}>
                <TabList
                  px={5}
                  borderBottom={'1px'}
                  borderColor={tabListBorderColor}
                  bg={tabListBgColor}
                  position="relative"
                >
                  {tabItems.map((tab) => (
                    <Tab
                      key={tab.label}
                      gap={2}
                      px={5}
                      fontSize={'sm'}
                      _selected={{ color: activeTabColor }}
                      textColor={tabColor}
                    >
                      <Icon boxSize={5} as={tab.icon} />
                      <Text>{t(`tasks.sidebar.tabs.${tab.label}`)}</Text>
                    </Tab>
                  ))}
                </TabList>
                <TabIndicator mt="-2px" height="2px" bg={activeTabIndicatorColor} />
              </Box>
              <TabPanels>
                <TabPanel p={0}>
                  <AssignedToMeTab onClose={onClose} />
                </TabPanel>
                <TabPanel p={0}>
                  <CreatedByMeTab onClose={onClose} />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

const tabItems = [
  {
    label: 'assignedToMe',
    icon: FolderArrowDownIcon,
  },
  {
    label: 'createdByMe',
    icon: FolderOpenIcon,
  },
] as const;

import { AnyDynamicFormComponentRegistry, DynamicFormFieldConfig } from '@main/dynamic-form';
import { Vendor_Questionnaire_Form_Answer_Statuses_Enum } from '@main/graphql/types.generated';

import {
  QuestionnnaireFormFieldAnswer,
  QuestionnnaireFormFilterMode,
  QuestionnnaireFormFilters,
} from './form-types';

export function getQuestionFilter(filters: QuestionnnaireFormFilters) {
  return <TRegistry extends AnyDynamicFormComponentRegistry>(
    config: DynamicFormFieldConfig<TRegistry>,
    answer?: QuestionnnaireFormFieldAnswer,
  ) => {
    return (
      (filters.mode === QuestionnnaireFormFilterMode.Unanswered &&
        answer?.status === Vendor_Questionnaire_Form_Answer_Statuses_Enum.Completed) ||
      (filters.search.length > 0 &&
        config.label &&
        !config.label.toLowerCase().includes(filters.search))
    );
  };
}

export function createNewAnswerFor(
  field: DynamicFormFieldConfig<AnyDynamicFormComponentRegistry>,
): QuestionnnaireFormFieldAnswer {
  return {
    field_name: field.name,
    status: Vendor_Questionnaire_Form_Answer_Statuses_Enum.Pending,
  };
}

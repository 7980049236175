/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { TaskTableFragmentFragmentDoc } from '../tasks/tasks.fragment.generated';
import { FieldValueFragmentDoc } from '../../../../../libs/graphql/src/fragments/FieldValueFragments.generated';
import { api } from '@main/graphql/shared-api';
export type MyTasksQueryVariables = Types.Exact<{
  userId: Types.Scalars['uuid']['input'];
  organizationId: Types.Scalars['uuid']['input'];
}>;

export type MyTasksQuery = {
  __typename?: 'query_root';
  tasks: Array<{
    __typename?: 'tasks';
    id: string;
    name?: string;
    status: ClientTypes.TaskStatus;
    due_date?: string;
    description?: string;
    created_by?: string;
    task_owners: Array<{ __typename?: 'task_owners'; completed_at?: string; owner_id: string }>;
    field_values: Array<{
      __typename?: 'field_values';
      field_config_id: string;
      entity_name: Types.Field_Entities_Enum;
      field_type: Types.Field_Types_Enum;
      text_field_value?: {
        __typename?: 'text_field_values';
        field_value_id: string;
        value: string;
      };
      select_field_value?: {
        __typename?: 'select_field_values';
        field_value_id: string;
        select_field_option_id: string;
      };
    }>;
  }>;
};

export const MyTasksDocument = `
    query MyTasks($userId: uuid!, $organizationId: uuid!) {
  tasks(
    where: {task_owners: {owner_id: {_eq: $userId}}, organization_id: {_eq: $organizationId}}
    order_by: {internal_id: asc}
  ) {
    ...TaskTableFragment
  }
}
    ${TaskTableFragmentFragmentDoc}
${FieldValueFragmentDoc}` as string & TypedDocumentNode<MyTasksQuery, MyTasksQueryVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    MyTasks: build.query<MyTasksQuery, MyTasksQueryVariables>({
      query: (variables) => ({ document: MyTasksDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useMyTasksQuery, useLazyMyTasksQuery } = injectedRtkApi;

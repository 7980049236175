/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type AddVendorMutationVariables = Types.Exact<{
  vendor_input: Types.Vendors_Insert_Input;
}>;

export type AddVendorMutation = {
  __typename?: 'mutation_root';
  insert_vendors_one?: { __typename?: 'vendors'; id: string };
};

export const AddVendorDocument = `
    mutation AddVendor($vendor_input: vendors_insert_input!) {
  insert_vendors_one(object: $vendor_input) {
    id
  }
}
    ` as string & TypedDocumentNode<AddVendorMutation, AddVendorMutationVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    AddVendor: build.mutation<AddVendorMutation, AddVendorMutationVariables>({
      query: (variables) => ({ document: AddVendorDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useAddVendorMutation } = injectedRtkApi;

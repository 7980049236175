import { FormControl, FormErrorMessage, FormLabel, Input, Stack, Switch } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Policy_Statuses_Enum } from '@main/graphql/types.generated';
import { Promisable, UseFormWithViewReturn } from '@main/shared/types';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { serializeDate, toDatePart } from '../../../utils/date';
import { useSearchPolicies } from '../../policies/policy-search';
import { EntitySearchInput } from '../../shared/entity-search-input';

export type PolicyEvidenceFormSchema = z.infer<typeof formSchema>;

const formSchema = z.object({
  policyId: z.string({ invalid_type_error: 'Please select a policy' }).uuid().optional(),
  policyName: z.string().optional(),
  validityStart: z.coerce.date().transform((date) => date.toISOString()),
  withVersionsChapter: z.boolean(),
  withRevisionDetails: z.boolean(),
  withApprovalsChapter: z.boolean(),
  withAcknowledgementsChapter: z.boolean(),
});

export function usePolicyEvidenceForm({
  defaultValues,
  onSubmit,
}: {
  defaultValues?: Partial<PolicyEvidenceFormSchema>;
  onSubmit: (data: PolicyEvidenceFormSchema) => Promisable<void>;
}): UseFormWithViewReturn {
  const { t } = useTranslation();
  const searchPolicies = useSearchPolicies({
    statusFilter: { _in: [Policy_Statuses_Enum.AwaitingApproval, Policy_Statuses_Enum.Approved] },
  });

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: normalizeDefaultValues(defaultValues),
  });

  const { register, control, formState } = form;

  const reset = () => {
    form.reset(normalizeDefaultValues(defaultValues));
  };

  const handleSubmit = form.handleSubmit(async (data) => {
    await onSubmit({
      ...data,
      validityStart: serializeDate(data.validityStart),
    });
  });

  const view = (
    <Stack spacing={6}>
      {defaultValues ? null : (
        <FormControl isInvalid={!!formState.errors.policyId}>
          <FormLabel>{t('evidences.version.label.policyId')}:</FormLabel>
          <Controller
            name="policyId"
            control={control}
            render={({ field }) => (
              <EntitySearchInput
                placeholder={t('evidences.version.placeholder.policyId')}
                searchEntities={searchPolicies}
                onChange={(option) => {
                  field.onChange(option?.value);
                }}
              />
            )}
          />
          <FormErrorMessage>{formState.errors.policyId?.message}</FormErrorMessage>
        </FormControl>
      )}

      {defaultValues ? (
        <FormControl isInvalid={!!formState.errors.validityStart}>
          <FormLabel>{t('evidences.version.label.validityStart')}:</FormLabel>
          <Input type="date" {...register('validityStart')} />
          <FormErrorMessage>{formState.errors.validityStart?.message}</FormErrorMessage>
        </FormControl>
      ) : (
        <>
          <FormControl isInvalid={!!formState.errors.withVersionsChapter}>
            <FormLabel>{t('policies.exportModal.withVersionsChapterLabel')}:</FormLabel>
            <Switch {...register('withVersionsChapter')} />
            <FormErrorMessage>{formState.errors.withVersionsChapter?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!formState.errors.withRevisionDetails}>
            <FormLabel>{t('policies.exportModal.withRevisionDetailsLabel')}:</FormLabel>
            <Switch {...register('withRevisionDetails')} />
            <FormErrorMessage>{formState.errors.withRevisionDetails?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!formState.errors.withApprovalsChapter}>
            <FormLabel>{t('policies.exportModal.withApprovalsChapterLabel')}:</FormLabel>
            <Switch {...register('withApprovalsChapter')} />
            <FormErrorMessage>{formState.errors.withApprovalsChapter?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!formState.errors.withAcknowledgementsChapter}>
            <FormLabel>{t('policies.exportModal.withAcknowledgementsChapterLabel')}:</FormLabel>
            <Switch {...register('withAcknowledgementsChapter')} />
            <FormErrorMessage>
              {formState.errors.withAcknowledgementsChapter?.message}
            </FormErrorMessage>
          </FormControl>
        </>
      )}
    </Stack>
  );

  return {
    formState,
    view,
    reset,
    handleSubmit,
  };
}

function normalizeDefaultValues(
  defaultValues?: Partial<PolicyEvidenceFormSchema>,
): PolicyEvidenceFormSchema {
  return {
    validityStart: toDatePart(defaultValues?.validityStart),
    policyId: defaultValues?.policyId,
    policyName: defaultValues?.policyName,
    withVersionsChapter: defaultValues?.withVersionsChapter ?? true,
    withRevisionDetails: defaultValues?.withRevisionDetails ?? true,
    withApprovalsChapter: defaultValues?.withApprovalsChapter ?? true,
    withAcknowledgementsChapter: defaultValues?.withAcknowledgementsChapter ?? true,
  };
}

import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Divider,
  Flex,
  Heading,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useGetRiskMatrixQuery } from '@main/graphql/features/RiskMatrix.generated';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../../../hooks/redux-toolkit-hooks';
import { getCurrentUserSelectedOrgId } from '../../../../user/slice';
import { MatrixTable } from './table';

export const MatrixConfigurations = () => {
  const { t } = useTranslation();
  const organizationId = useAppSelector(getCurrentUserSelectedOrgId);
  const { isLoading: isMatrixLoading } = useGetRiskMatrixQuery(
    { organizationId },
    { refetchOnMountOrArgChange: true },
  );

  if (isMatrixLoading) {
    return null;
  }

  return (
    <>
      <Flex py="6" px={[4, 4, 8]} justifyContent="space-between" alignItems={'center'}>
        <Box>
          <Heading as="h2" size="md">
            {t('settings.organization.risks.matrix.heading')}
          </Heading>
          <Text fontSize="14" color={'gray.500'}>
            {t('settings.organization.risks.matrix.subheading')}
          </Text>
        </Box>
      </Flex>
      <Divider orientation="horizontal" />
      <Box w="100%" py="6" px={[4, 4, 8]}>
        <Stack gap={4}>
          <Card variant="outline">
            <CardHeader px={6} py={5} display="flex" justifyContent="space-between">
              <Text fontSize="xl" fontWeight="semibold">
                {t('settings.organization.risks.matrix.inherentRisksMatrix')}
              </Text>
            </CardHeader>

            <Divider />
            <CardBody px={6} overflowX="auto">
              <MatrixTable riskLevelType="inherent" />
            </CardBody>
          </Card>
          <Card variant="outline">
            <CardHeader px={6} py={5} display="flex" justifyContent="space-between">
              <Text fontSize="xl" fontWeight="semibold">
                {t('settings.organization.risks.matrix.residualRisksMatrix')}
              </Text>
            </CardHeader>

            <Divider />
            <CardBody px={6} overflowX="auto">
              <MatrixTable riskLevelType="residual" />
            </CardBody>
          </Card>
        </Stack>
      </Box>
    </>
  );
};

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type GetGroupTagsQueryVariables = Types.Exact<{
  orgId: Types.Scalars['uuid']['input'];
  nameFilter?: Types.InputMaybe<Types.String_Comparison_Exp>;
}>;

export type GetGroupTagsQuery = {
  __typename?: 'query_root';
  groups: Array<{ __typename?: 'groups'; id: string; name: string }>;
};

export const GetGroupTagsDocument = `
    query GetGroupTags($orgId: uuid!, $nameFilter: String_comparison_exp = {}) {
  groups(
    order_by: {name: asc}
    where: {organization_id: {_eq: $orgId}, name: $nameFilter}
  ) {
    id
    name
  }
}
    ` as string & TypedDocumentNode<GetGroupTagsQuery, GetGroupTagsQueryVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetGroupTags: build.query<GetGroupTagsQuery, GetGroupTagsQueryVariables>({
      query: (variables) => ({ document: GetGroupTagsDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetGroupTagsQuery, useLazyGetGroupTagsQuery } = injectedRtkApi;

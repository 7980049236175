/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { UserNameEmailFragmentFragmentDoc } from '../../../../../../libs/graphql/src/fragments/UserNameEmailFragment.generated';
import { FileInfoFragmentFragmentDoc } from '../../../../../../libs/graphql/src/fragments/FileInfoFragment.generated';
import { api } from '@main/graphql/shared-api';
export type GetVendorQuestionnairesForViewQueryVariables = Types.Exact<{
  vendorId: Types.Scalars['uuid']['input'];
}>;

export type GetVendorQuestionnairesForViewQuery = {
  __typename?: 'query_root';
  vendor_questionnaires: Array<{
    __typename?: 'vendor_questionnaires';
    id: string;
    status: Types.Vendor_Questionnaire_Statuses_Enum;
    due_date?: string;
    is_disabled: boolean;
    sent_by_user: { __typename?: 'users'; id: string; email?: string; displayName: string };
    questionnaire: { __typename?: 'questionnaires'; id: string; name?: string; form_id?: string };
  }>;
};

export type UpdateVendorQuestionnaireMutationVariables = Types.Exact<{
  vendorQuestionnaireId: Types.Scalars['uuid']['input'];
  input: Types.Vendor_Questionnaires_Set_Input;
}>;

export type UpdateVendorQuestionnaireMutation = {
  __typename?: 'mutation_root';
  update_vendor_questionnaires_by_pk?: { __typename?: 'vendor_questionnaires'; id: string };
};

export type DeteleVendorQuestionnaireMutationVariables = Types.Exact<{
  vendorQuestionnaireId: Types.Scalars['uuid']['input'];
}>;

export type DeteleVendorQuestionnaireMutation = {
  __typename?: 'mutation_root';
  delete_vendor_questionnaires_by_pk?: { __typename?: 'vendor_questionnaires'; id: string };
};

export type GetVendorQuestionnairesByIdQueryVariables = Types.Exact<{
  vqId: Types.Scalars['uuid']['input'];
}>;

export type GetVendorQuestionnairesByIdQuery = {
  __typename?: 'query_root';
  vendor_questionnaires_by_pk?: {
    __typename?: 'vendor_questionnaires';
    id: string;
    status: Types.Vendor_Questionnaire_Statuses_Enum;
    due_date?: string;
    created_at: string;
    sent_to_email: string;
    sent_by_user: { __typename?: 'users'; id: string; email?: string; displayName: string };
    vendor_questionnaire_ai_review_histories: Array<{
      __typename?: 'vendor_questionnaire_ai_review_history';
      id: string;
      result?: ClientTypes.VendorQuestionnaireAIReviewResult;
      status: Types.Vendor_Questionnaire_Ai_Review_Status_Enum;
      updated_at: string;
    }>;
    questionnaire: {
      __typename?: 'questionnaires';
      id: string;
      name?: string;
      description?: string;
      internal_id?: string;
      form_id?: string;
      file?: { __typename?: 'files'; id: string; bucketId: string; name?: string; size?: number };
    };
    vendor: {
      __typename?: 'vendors';
      id: string;
      name?: string;
      internal_id?: string;
      contact_email?: string;
      organization_id: string;
    };
    answer_upload?: {
      __typename?: 'vendor_questionnaire_uploads';
      id: string;
      file: { __typename?: 'files'; id: string; bucketId: string; name?: string; size?: number };
    };
    uploads: Array<{
      __typename?: 'vendor_questionnaire_uploads';
      id: string;
      file: { __typename?: 'files'; id: string; bucketId: string; name?: string; size?: number };
    }>;
    form?: {
      __typename?: 'vendor_questionnaire_forms';
      id: string;
      config_snapshot?: ClientTypes.FormConfig;
      answers: Array<{
        __typename?: 'vendor_questionnaire_form_answers';
        id: string;
        field_name: string;
        status: Types.Vendor_Questionnaire_Form_Answer_Statuses_Enum;
        value?: any;
        comment?: string;
        form_uploads: Array<{
          __typename?: 'vendor_questionnaire_form_uploads';
          id: string;
          file: {
            __typename?: 'files';
            id: string;
            bucketId: string;
            name?: string;
            size?: number;
          };
        }>;
      }>;
    };
    vendor_questionnaire_ai_review_findings: Array<{
      __typename?: 'vendor_questionnaire_ai_review_findings';
      id: string;
      task_id?: string;
      risk_id?: string;
      ignored_at?: string;
      question_name_id: string;
    }>;
  };
};

export const GetVendorQuestionnairesForViewDocument = `
    query GetVendorQuestionnairesForView($vendorId: uuid!) {
  vendor_questionnaires(
    where: {vendor_id: {_eq: $vendorId}}
    order_by: {created_at: desc}
  ) {
    id
    status
    due_date
    is_disabled
    sent_by_user {
      ...UserNameEmailFragment
    }
    questionnaire {
      id
      name
      form_id
    }
  }
}
    ${UserNameEmailFragmentFragmentDoc}` as string &
  TypedDocumentNode<
    GetVendorQuestionnairesForViewQuery,
    GetVendorQuestionnairesForViewQueryVariables
  >;
export const UpdateVendorQuestionnaireDocument = `
    mutation updateVendorQuestionnaire($vendorQuestionnaireId: uuid!, $input: vendor_questionnaires_set_input!) {
  update_vendor_questionnaires_by_pk(
    pk_columns: {id: $vendorQuestionnaireId}
    _set: $input
  ) {
    id
  }
}
    ` as string &
  TypedDocumentNode<UpdateVendorQuestionnaireMutation, UpdateVendorQuestionnaireMutationVariables>;
export const DeteleVendorQuestionnaireDocument = `
    mutation DeteleVendorQuestionnaire($vendorQuestionnaireId: uuid!) {
  delete_vendor_questionnaires_by_pk(id: $vendorQuestionnaireId) {
    id
  }
}
    ` as string &
  TypedDocumentNode<DeteleVendorQuestionnaireMutation, DeteleVendorQuestionnaireMutationVariables>;
export const GetVendorQuestionnairesByIdDocument = `
    query GetVendorQuestionnairesById($vqId: uuid!) {
  vendor_questionnaires_by_pk(id: $vqId) {
    id
    status
    due_date
    created_at
    sent_to_email
    sent_by_user {
      ...UserNameEmailFragment
    }
    vendor_questionnaire_ai_review_histories(order_by: {created_at: desc}, limit: 1) {
      id
      result
      status
      updated_at
    }
    questionnaire {
      id
      name
      description
      internal_id
      file {
        ...FileInfoFragment
      }
      form_id
    }
    vendor {
      id
      name
      internal_id
      contact_email
      organization_id
    }
    answer_upload {
      id
      file {
        ...FileInfoFragment
      }
    }
    uploads {
      id
      file {
        ...FileInfoFragment
      }
    }
    form {
      id
      config_snapshot
      answers {
        id
        field_name
        status
        value
        comment
        form_uploads {
          id
          file {
            ...FileInfoFragment
          }
        }
      }
    }
    vendor_questionnaire_ai_review_findings {
      id
      task_id
      risk_id
      ignored_at
      question_name_id
    }
  }
}
    ${UserNameEmailFragmentFragmentDoc}
${FileInfoFragmentFragmentDoc}` as string &
  TypedDocumentNode<GetVendorQuestionnairesByIdQuery, GetVendorQuestionnairesByIdQueryVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetVendorQuestionnairesForView: build.query<
      GetVendorQuestionnairesForViewQuery,
      GetVendorQuestionnairesForViewQueryVariables
    >({
      query: (variables) => ({ document: GetVendorQuestionnairesForViewDocument, variables }),
    }),
    updateVendorQuestionnaire: build.mutation<
      UpdateVendorQuestionnaireMutation,
      UpdateVendorQuestionnaireMutationVariables
    >({
      query: (variables) => ({ document: UpdateVendorQuestionnaireDocument, variables }),
    }),
    DeteleVendorQuestionnaire: build.mutation<
      DeteleVendorQuestionnaireMutation,
      DeteleVendorQuestionnaireMutationVariables
    >({
      query: (variables) => ({ document: DeteleVendorQuestionnaireDocument, variables }),
    }),
    GetVendorQuestionnairesById: build.query<
      GetVendorQuestionnairesByIdQuery,
      GetVendorQuestionnairesByIdQueryVariables
    >({
      query: (variables) => ({ document: GetVendorQuestionnairesByIdDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useGetVendorQuestionnairesForViewQuery,
  useLazyGetVendorQuestionnairesForViewQuery,
  useUpdateVendorQuestionnaireMutation,
  useDeteleVendorQuestionnaireMutation,
  useGetVendorQuestionnairesByIdQuery,
  useLazyGetVendorQuestionnairesByIdQuery,
} = injectedRtkApi;

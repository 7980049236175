import { datadogLogs } from '@datadog/browser-logs';
import { toError } from '@main/shared/utils';
import { errorToast, successToast, useDrawer } from '@main/ui';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { getCurrentUserSelectedOrgRole } from '../user/slice';
import { useDuplicateControlMutation } from './get-control.generated';

export function useDuplicateControl() {
  const { t } = useTranslation();
  const drawer = useDrawer();
  const [_duplicateControl] = useDuplicateControlMutation();

  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canDuplicateControls = userRole.permissionMap?.create_controls;

  const duplicateControl = useCallback(
    async (controlId: string) => {
      try {
        const { duplicate_control } = await _duplicateControl({ controlId }).unwrap();
        if (duplicate_control?.id) {
          successToast(t('successMessages.duplicateSucceeded', { entity: t('entities.control') }));
          drawer.open({ entity: 'control', entityId: duplicate_control.id });
        } else {
          errorToast(t('errorMessages.duplicateFailed', { entity: t('entities.control') }));
        }
      } catch (error) {
        errorToast(t('errorMessages.duplicateFailed', { entity: t('entities.control') }));
        datadogLogs.logger.error('Control duplicate failed', {}, toError(error));
      }
    },
    [_duplicateControl, drawer, t],
  );

  return canDuplicateControls ? duplicateControl : null;
}

import { Box, Button, Card, Icon, Stack, useDisclosure } from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { ArrowPathIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import { VendorAssessmentStatus } from '@main/graphql/client-scalars';
import { Vendor_Questionnaire_Statuses_Enum } from '@main/graphql/types.generated';
import { QUESTIONNAIRE_STATUSES } from '@main/shared/types';
import { formatDate, isNonNullable, toError } from '@main/shared/utils';
import {
  createColumnHelper,
  errorToast,
  successToast,
  Table,
  useAlertDialog,
  useDrawer,
  useStableCallback,
} from '@main/ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { serializeDate } from '../../utils/date';
import { getCurrentUserSelectedOrgRole } from '../user/slice';
import {
  useDeteleVendorQuestionnaireMutation,
  useUpdateVendorQuestionnaireMutation,
} from '../vendors/vendor-questionnaires/vendor-questionnaires.generated';
import { SendQuestionnaireModal } from './send-questionnaire-modal';
import { VendorsTabGetVendorsQuery } from './vendors-tab.generated';
import { useVendorsTabGetVendorsSubscription } from './vendors-tab.subs';

type VendorQuestionnaire = VendorsTabGetVendorsQuery['vendor_questionnaires'][number];

export function QuestionnairesVendorsTab({ questionnaireId }: { questionnaireId: string }) {
  const { t } = useTranslation();
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canUpdateVendors = !!userRole.permissionMap?.write_vendors;

  const { data, isLoading } = useVendorsTabGetVendorsSubscription({ questionnaireId });
  const questionnaires = data?.vendor_questionnaires ?? [];

  const columns = useQuestionnaireVendorsTableColumns();
  const tableItemName = useMemo(() => {
    return {
      singular: t('entities.vendor').toLowerCase(),
      plural: t('entities.plural.vendors').toLowerCase(),
      alternateSubheading: true,
      hideSubheading: !canUpdateVendors,
    };
  }, [canUpdateVendors, t]);

  const createVendorDisclosure = useDisclosure();

  return (
    <Stack spacing={6}>
      <Card variant="table-styles" overflowY="hidden">
        <Table
          minW="700px"
          data={questionnaires}
          isLoading={isLoading}
          columns={columns}
          itemName={tableItemName}
          pageSize={15}
        />
      </Card>
      {canUpdateVendors && (
        <>
          <Box alignSelf="end" mt="4">
            <Button
              leftIcon={<Icon color="white" _dark={{ color: 'black' }} w={4} h={4} as={PlusIcon} />}
              colorScheme="blue"
              variant="solid"
              onClick={createVendorDisclosure.onOpen}
            >
              {t('questionnaires.vendors.addButton')}
            </Button>
          </Box>
          <SendQuestionnaireModal
            questionnaireId={questionnaireId}
            disclosure={createVendorDisclosure}
          />
        </>
      )}
    </Stack>
  );
}

function useQuestionnaireVendorsTableColumns() {
  const { t } = useTranslation();
  const drawer = useDrawer();
  const { openDialog } = useAlertDialog();
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canUpdateVendors = !!userRole.permissionMap?.write_vendors;
  const [updateVendorQuestionnaire] = useUpdateVendorQuestionnaireMutation();
  const [deteleVendorQuestionnaire] = useDeteleVendorQuestionnaireMutation();

  const onDueDateUpdate = useStableCallback(
    async (vendorQuestionnaire: VendorQuestionnaire, dueDate: string) => {
      const adjustedDueDate = serializeDate(dueDate);

      try {
        await updateVendorQuestionnaire({
          vendorQuestionnaireId: vendorQuestionnaire.id,
          input: {
            due_date: adjustedDueDate,
          },
        }).unwrap();
      } catch (error) {
        errorToast(t('errorMessages.updateFailed', { entity: t('entities.questionnaire') }));
        datadogLogs.logger.error('Vendor questionnaire update failed', {}, toError(error));
      }
    },
  );

  return useMemo(() => {
    const columnHelper = createColumnHelper<VendorQuestionnaire>();
    return [
      columnHelper.columns.status({
        id: 'status',
        header: t('vendors.questionnaires.tableColumns.status'),
        accessorFn: (data) => {
          const statusMeta = QUESTIONNAIRE_STATUSES[data.status];

          return {
            value: t(statusMeta.value),
            colorScheme: statusMeta?.colorScheme,
          };
        },
        size: 120,
      }),
      columnHelper.columns.text({
        id: 'name',
        header: t('vendors.questionnaires.tableColumns.name'),
        accessorFn: (data) => data.vendor.name,
        meta: {
          cell: {
            onClick: ({ row }) =>
              drawer.open({ entity: 'vendor-questionnaire', entityId: row.original.id }),
          },
        },
      }),
      columnHelper.columns.date({
        id: 'dueDate',
        header: t('vendors.questionnaires.tableColumns.dueDate'),
        accessorFn: (data) => formatDate(data.due_date),
        edit: {
          onChange: (row, value) => onDueDateUpdate(row.original, value),
          canEditGuard: (row) =>
            canUpdateVendors &&
            row.original.vendor.assessment_status !== VendorAssessmentStatus.TERMINATED,
        },
        size: 150,
        meta: {
          cell: { fontSize: 'xs', color: 'gray.500' },
        },
      }),
      columnHelper.columns.avatar({
        id: 'sentBy',
        header: t('vendors.questionnaires.tableColumns.sentBy'),
        variant: 'short',
        accessorFn: (data) => ({
          id: data?.sent_by_user?.id ?? '',
          displayName: data?.sent_by_user?.displayName ?? 'Unknown',
        }),
        size: 80,
      }),
      canUpdateVendors
        ? columnHelper.columns.actions({
            id: 'actions',
            size: 52,
            secondaryActions: ({ row }) =>
              [
                !row.original.is_disabled
                  ? {
                      icon: <Icon as={ArrowPathIcon} />,
                      label: t('vendors.questionnaires.actions.resendQuestionnaire'),
                      onClick: async () => {
                        try {
                          await updateVendorQuestionnaire({
                            vendorQuestionnaireId: row.original.id,
                            input: { status: Vendor_Questionnaire_Statuses_Enum.Created },
                          }).unwrap();
                          successToast(t('vendors.questionnaires.toasts.resendSuccess'));
                        } catch (error) {
                          errorToast(t('vendors.questionnaires.toasts.resendFailed'));
                          datadogLogs.logger.error(
                            'Vendor questionnaire re-send failed',
                            { vendorQuestionnaireId: row.original.id },
                            toError(error),
                          );
                        }
                      },
                    }
                  : undefined,
                {
                  icon: <Icon as={TrashIcon} />,
                  label: t('vendors.questionnaires.actions.deleteQuestionnaire'),
                  onClick: () =>
                    openDialog({
                      dialogHeader: t('vendors.questionnaires.deleteAlert.header'),
                      dialogContent: t('vendors.questionnaires.deleteAlert.content'),
                      confirmAction: {
                        children: t('buttons.delete'),
                        onClick: async () => {
                          try {
                            await deteleVendorQuestionnaire({
                              vendorQuestionnaireId: row.original.id,
                            }).unwrap();
                            successToast(t('vendors.questionnaires.toasts.deleteSuccess'));
                          } catch (error) {
                            errorToast(t('vendors.questionnaires.toasts.deleteFailed'));
                            datadogLogs.logger.error(
                              'Vendor questionnaire request deletion failed',
                              { vendorQuestionnaireId: row.original.id },
                              toError(error),
                            );
                          }
                        },
                      },
                    }),
                },
              ].filter(isNonNullable),
          })
        : undefined,
    ].filter(isNonNullable);
  }, [
    t,
    canUpdateVendors,
    drawer,
    onDueDateUpdate,
    updateVendorQuestionnaire,
    openDialog,
    deteleVendorQuestionnaire,
  ]);
}

import { useMultipleFilesUpload } from '@nhost/react';
import { useState } from 'react';

import { FileInputContext } from './file-input';
import { getFileUploadResult, getMultipleFilesUploadResult, UploadedFile } from './shared';

export function useLazyFileUpload({
  file,
  onFileAdd,
  onFileDelete,
  onFileDownload,
}: {
  file?: UploadedFile;
  onFileAdd?: (file: File) => void;
  onFileDelete?: () => void;
  onFileDownload?: (fileId: string) => void;
}) {
  const fileUpload = useLazyMultipleFilesUpload({
    files: file ? [file] : undefined,
    onFilesAdd: ([file]) => {
      if (file) {
        onFileAdd?.(file);
      }
    },
    onFileDelete,
    onFileDownload,
  });

  const upload = async () => {
    const uploadResult = await fileUpload.upload();
    return getFileUploadResult(uploadResult);
  };

  return {
    ...fileUpload,
    props: {
      ...fileUpload.props,
      isMulti: false,
    },
    upload,
  };
}

export function useLazyMultipleFilesUpload({
  files,
  onFilesAdd,
  onFileDelete,
  onFileDownload,
}: {
  files?: UploadedFile[];
  onFilesAdd?: (files: File[]) => void;
  onFileDelete?: (fileName: string) => void;
  onFileDownload?: (fileId: string) => void;
}) {
  const filesUpload = useMultipleFilesUpload();
  const [deletedFileIds, setDeletedFileIds] = useState<string[]>([]);

  const props: FileInputContext = {
    isMulti: true,
    uploadedFiles: files?.filter((uploadedFile) => !deletedFileIds.includes(uploadedFile.id)),
    onUploadedFileDownload: onFileDownload,
    onUploadedFileDelete: async (fileId) => {
      setDeletedFileIds((prevFileIds) => [...prevFileIds, fileId]);
      onFileDelete?.(fileId);
    },
    stagedFiles: filesUpload.files,
    onStagedFilesAdd: (selectedFiles) => {
      const isFileAlreadyAttached = filesUpload.files.some((file) => {
        const stagedFile = file.getSnapshot()?.context.file;
        return selectedFiles.some(
          (selectedFile) =>
            stagedFile?.name === selectedFile.name && stagedFile?.size === selectedFile.size,
        );
      });

      if (!isFileAlreadyAttached) {
        filesUpload.add({ files: selectedFiles });
        onFilesAdd?.(selectedFiles);
      }
    },
    onStagedFileDelete: (fileId, file) => {
      filesUpload.files.find((file) => file.id === fileId)?.send('DESTROY');
      onFileDelete?.(file.name);
    },
  };

  const upload = async () => {
    if (!filesUpload.files.length) {
      return {
        errors: [],
        files: [],
      };
    }

    const uploadResult = await filesUpload.upload();
    return getMultipleFilesUploadResult(uploadResult);
  };

  const clearDeletedFileIds = () => setDeletedFileIds([]);

  return {
    props,
    deletedFileIds,
    upload,
    clear: filesUpload.clear,
    clearDeletedFileIds,
  };
}

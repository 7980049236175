import {
  Policy_Acknowledgement_Statuses_Enum,
  Policy_Statuses_Enum,
  Policy_Version_Statuses_Enum,
} from '@main/graphql/types.generated';

export const POLICY_TABLE_FILTER_PARAM_NAME = 'policiesFilter' as string;
export const POLICY_TABLE_SORT_PARAM_NAME = 'policiesSort' as string;

export const POLICY_STATUSES = {
  [Policy_Statuses_Enum.Draft]: { value: Policy_Statuses_Enum.Draft, colorScheme: 'gray' },
  [Policy_Statuses_Enum.AwaitingApproval]: {
    value: Policy_Statuses_Enum.AwaitingApproval,
    colorScheme: 'orange',
  },
  [Policy_Statuses_Enum.Approved]: { value: Policy_Statuses_Enum.Approved, colorScheme: 'green' },
};

export const POLICY_VERSION_STATUSES = {
  [Policy_Version_Statuses_Enum.Draft]: {
    value: Policy_Version_Statuses_Enum.Draft,
    colorScheme: 'gray',
  },
  [Policy_Version_Statuses_Enum.AwaitingApproval]: {
    value: Policy_Version_Statuses_Enum.AwaitingApproval,
    colorScheme: 'orange',
  },
  [Policy_Version_Statuses_Enum.Retired]: {
    value: Policy_Version_Statuses_Enum.Retired,
    colorScheme: 'red',
  },
  [Policy_Version_Statuses_Enum.Approved]: {
    value: Policy_Version_Statuses_Enum.Approved,
    colorScheme: 'green',
  },
};

export const POLICY_ACKNOWLEDGEMENT_STATUSES = {
  [Policy_Acknowledgement_Statuses_Enum.Pending]: {
    value: Policy_Acknowledgement_Statuses_Enum.Pending,
    colorScheme: 'gray',
  },
  [Policy_Acknowledgement_Statuses_Enum.Expiring]: {
    value: Policy_Acknowledgement_Statuses_Enum.Expiring,
    colorScheme: 'orange',
  },
  [Policy_Acknowledgement_Statuses_Enum.Retired]: {
    value: Policy_Acknowledgement_Statuses_Enum.Retired,
    colorScheme: 'red',
  },
  [Policy_Acknowledgement_Statuses_Enum.Overdue]: {
    value: Policy_Acknowledgement_Statuses_Enum.Overdue,
    colorScheme: 'red',
  },
  [Policy_Acknowledgement_Statuses_Enum.Acknowledged]: {
    value: Policy_Acknowledgement_Statuses_Enum.Acknowledged,
    colorScheme: 'green',
  },
};

export const getFormattedPolicyVersionId = (versionId: number) => `v${versionId}`;

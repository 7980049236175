import { Card, Icon, useDisclosure } from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { PencilSquareIcon } from '@heroicons/react/24/outline';
import { isNonNullable, toError } from '@main/shared/utils';
import { createColumnHelper, errorToast, Table, useDrawer } from '@main/ui';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { COMPLYANCE_USER } from '../../utils/constants';
import { serializeDate } from '../../utils/date';
import { getCurrentOrgUsersMap, getCurrentUserSelectedOrgRole } from '../user/slice';
import { POLICY_VERSION_STATUSES } from './constants';
import { useGetPolicyQuery, useUpdatePolicyVersionMutation } from './manage-policies.generated';
import { RevisionDetailsModal } from './revision-details-modal';
import { getMappedPolicy } from './slice';

type PolicyVersion = NonNullable<ReturnType<typeof getMappedPolicy>['policy_versions']>[number];

export const PolicyVersionsTab = ({ policyId }: { policyId: string }) => {
  const { t } = useTranslation();
  const { isLoading } = useGetPolicyQuery({
    id: policyId,
  });
  const {
    isOpen: isRevisionModalOpen,
    onOpen: revisionModalOpen,
    onClose: revisionModalClose,
  } = useDisclosure();
  const [revisionPolicyVersionDetails, setRevisionPolicyVersionDetails] = useState<{
    policyVersionId: string;
    versionRevisionDetails?: string;
  }>();

  const drawer = useDrawer();
  const openVersion = useCallback(
    (row: { original: PolicyVersion }) => {
      drawer.open({
        entity: 'policy-version',
        entityId: `${policyId}:${row.original.id}`,
      });
    },
    [drawer, policyId],
  );

  const openRevisionEditModal = useCallback(
    (row: { original: PolicyVersion }) => {
      setRevisionPolicyVersionDetails({
        policyVersionId: row.original.id,
        versionRevisionDetails: row.original.revision_details,
      });
      revisionModalOpen();
    },
    [revisionModalOpen],
  );
  const columns = useVersionsTableColumns(openRevisionEditModal);
  const tableItemName = useMemo(() => {
    return {
      singular: t('entities.policyVersion').toLowerCase(),
      plural: t('entities.plural.policyVersions').toLowerCase(),
    };
  }, [t]);
  const policy = useAppSelector((state) => getMappedPolicy(state, policyId));

  return (
    <Card variant={'table-styles'}>
      <Table
        minW="400px"
        data={policy.policy_versions ?? []}
        isLoading={isLoading}
        columns={columns}
        itemName={tableItemName}
        onRowClick={openVersion}
      />
      {revisionPolicyVersionDetails && (
        <RevisionDetailsModal
          isOpen={isRevisionModalOpen}
          onClose={() => {
            revisionModalClose();
            setRevisionPolicyVersionDetails(undefined);
          }}
          policyVersionId={revisionPolicyVersionDetails?.policyVersionId}
          versionRevisionDetails={revisionPolicyVersionDetails?.versionRevisionDetails}
        />
      )}
    </Card>
  );
};

function useVersionsTableColumns(
  openRevisionEditModal: (row: { original: PolicyVersion }) => void,
) {
  const { t } = useTranslation();
  const currentOrgUsers = useAppSelector(getCurrentOrgUsersMap);
  const [updateVersion] = useUpdatePolicyVersionMutation();
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canEditPolicy = userRole.permissionMap?.write_policies;

  const onValidityDateUpdate = useCallback(
    async (policyVersion: PolicyVersion, validityDate: string) => {
      const adjustedValidityStart = serializeDate(validityDate);

      try {
        await updateVersion({
          id: policyVersion.id,
          updatePayload: {
            validity_start: adjustedValidityStart || null,
          },
        }).unwrap();
      } catch (error) {
        errorToast(t('errorMessages.updateFailed', { entity: t('entities.policyVersion') }));
        datadogLogs.logger.error('Policy version update failed', {}, toError(error));
      }
    },
    [updateVersion, t],
  );

  return useMemo(() => {
    const columnHelper = createColumnHelper<PolicyVersion>();

    return [
      columnHelper.columns.tag({
        id: 'versionId',
        header: t('policies.versions.tableColumns.version'),
        accessorFn: ({ formattedVersionId }) => {
          return {
            value: formattedVersionId,
            colorScheme: 'purple',
          };
        },
      }),

      columnHelper.columns.status({
        id: 'status',
        header: t('policies.versions.tableColumns.status'),
        accessorFn: ({ status }) => {
          const statusMeta = POLICY_VERSION_STATUSES[status];
          return {
            value: t(`policies.versions.status.${statusMeta.value}`),
            colorScheme: statusMeta.colorScheme,
          };
        },
        size: 180,
      }),

      columnHelper.columns.date({
        id: 'validityStart',
        header: t('policies.versions.tableColumns.validityStart'),
        accessorFn: ({ validity_start }) => validity_start,
        size: 160,
        edit: {
          onChange: (row, value) => onValidityDateUpdate(row.original, value),
          canEditGuard: () => !!canEditPolicy,
        },
      }),

      columnHelper.columns.avatar({
        id: 'createdBy',
        header: t('policies.versions.tableColumns.createdBy'),
        accessorFn: ({ created_by }) => {
          if (!created_by) {
            return COMPLYANCE_USER;
          }

          const user = currentOrgUsers[created_by];
          if (!user) {
            return;
          }

          return {
            id: user.id,
            displayName: user.displayName,
          };
        },
        size: 100,
      }),
      columnHelper.columns.actions({
        id: 'actions',
        size: 70,
        secondaryActions: ({ row }) => {
          return [
            canEditPolicy
              ? {
                  icon: <Icon as={PencilSquareIcon} />,
                  label: t('policies.versions.revisionDetails.editLabel'),
                  onClick: () => openRevisionEditModal(row),
                }
              : undefined,
          ].filter(isNonNullable);
        },
      }),
    ];
  }, [canEditPolicy, currentOrgUsers, onValidityDateUpdate, openRevisionEditModal, t]);
}

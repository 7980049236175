import { BufferedQueue } from '@pooley/queues';

/** @internal */
export class PausableQueue<T> extends BufferedQueue<T> {
  protected isPaused = false;
  protected pauseQueue: T[] = [];

  override pushAll(data: T[]): void {
    if (this.isPaused) {
      const newQueueSize = this.pauseQueue.length + data.length;

      if (newQueueSize > this.bufferSize) {
        this.bufferOverflowStrategy(newQueueSize, this);
      }

      for (const item of data) {
        this.pauseQueue.push(item);
      }
      return;
    }

    super.pushAll(data);
  }

  pause() {
    this.isPaused = true;
    this.pauseQueue = this.queue;
    this.queue = [];
  }

  resume() {
    this.isPaused = false;
    this.queue = this.pauseQueue;
    this.pauseQueue = [];
    this.pushAll([]);
  }
}

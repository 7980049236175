/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type GetQuestionnairesQueryVariables = Types.Exact<{
  organization_id: Types.Scalars['uuid']['input'];
}>;

export type GetQuestionnairesQuery = {
  __typename?: 'query_root';
  questionnaires: Array<{
    __typename?: 'questionnaires';
    created_at: string;
    name?: string;
    description?: string;
    id: string;
    owner_id?: string;
    form_id?: string;
  }>;
};

export const GetQuestionnairesDocument = `
    query GetQuestionnaires($organization_id: uuid!) {
  questionnaires(
    order_by: {internal_id: asc}
    where: {organization_id: {_eq: $organization_id}}
  ) {
    created_at
    name
    description
    id
    owner_id
    form_id
  }
}
    ` as string & TypedDocumentNode<GetQuestionnairesQuery, GetQuestionnairesQueryVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetQuestionnaires: build.query<GetQuestionnairesQuery, GetQuestionnairesQueryVariables>({
      query: (variables) => ({ document: GetQuestionnairesDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetQuestionnairesQuery, useLazyGetQuestionnairesQuery } = injectedRtkApi;

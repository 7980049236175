/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Box } from '@chakra-ui/react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useLexicalEditable } from '@lexical/react/useLexicalEditable';
import type { TableCellNode, TableDOMCell, TableMapType, TableMapValueType } from '@lexical/table';
import {
  $computeTableMapSkipCellCheck,
  $getTableNodeFromLexicalNodeOrThrow,
  $getTableRowIndexFromTableCellNode,
  $isTableCellNode,
  $isTableRowNode,
  getDOMCellFromTarget,
} from '@lexical/table';
import { calculateZoomLevel } from '@lexical/utils';
import type { LexicalEditor } from 'lexical';
import { $getNearestNodeFromDOMNode } from 'lexical';
import {
  CSSProperties,
  MouseEventHandler,
  ReactPortal,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';

export interface TableCellResizerPluginProps {
  handleColor?: string;
  handleWidth?: number;
  minRowHeight?: number;
  minColumnWidth?: number;
}

export default function TableCellResizerPlugin(
  props?: TableCellResizerPluginProps,
): null | ReactPortal {
  const [editor] = useLexicalComposerContext();
  const isEditable = useLexicalEditable();

  return useMemo(
    () =>
      isEditable
        ? createPortal(
            <TableCellResizer
              handleColor={props?.handleColor}
              handleWidth={props?.handleWidth}
              minRowHeight={props?.minRowHeight}
              minColumnWidth={props?.minColumnWidth}
              editor={editor}
            />,
            document.body,
          )
        : null,
    [
      editor,
      isEditable,
      props?.handleColor,
      props?.handleWidth,
      props?.minColumnWidth,
      props?.minRowHeight,
    ],
  );
}

type MousePosition = {
  x: number;
  y: number;
};

type MouseDraggingDirection = 'right' | 'bottom';

interface TableCellResizerProps extends TableCellResizerPluginProps {
  editor: LexicalEditor;
}

function TableCellResizer({
  editor,
  handleColor = 'var(--chakra-colors-blue-300)',
  handleWidth = 10,
  minRowHeight = 33,
  minColumnWidth = 50,
}: TableCellResizerProps): JSX.Element {
  const targetRef = useRef<HTMLElement | null>(null);
  const resizerRef = useRef<HTMLDivElement | null>(null);
  const tableRectRef = useRef<DOMRect | null>(null);

  const mouseStartPosRef = useRef<MousePosition | null>(null);
  const [mouseCurrentPos, updateMouseCurrentPos] = useState<MousePosition | null>(null);

  const [activeCell, setActiveCell] = useState<TableDOMCell | null>(null);
  const [isMouseDown, setIsMouseDown] = useState<boolean>(false);
  const [draggingDirection, setDraggingDirection] = useState<MouseDraggingDirection | null>(null);

  const resetState = useCallback(() => {
    setActiveCell(null);
    targetRef.current = null;
    setDraggingDirection(null);
    mouseStartPosRef.current = null;
    tableRectRef.current = null;
  }, []);

  useEffect(() => {
    const onMouseMove = (event: MouseEvent) => {
      setTimeout(() => {
        const target = event.target;

        if (draggingDirection) {
          updateMouseCurrentPos({
            x: event.clientX,
            y: event.clientY,
          });
          return;
        }
        setIsMouseDown(isMouseDownOnEvent(event));
        if (resizerRef.current && resizerRef.current.contains(target as Node)) {
          return;
        }

        if (targetRef.current !== target) {
          targetRef.current = target as HTMLElement;
          const cell = getDOMCellFromTarget(target as HTMLElement);

          if (cell && activeCell !== cell) {
            editor.update(() => {
              const tableCellNode = $getNearestNodeFromDOMNode(cell.elem);
              if (!tableCellNode) {
                throw new Error('TableCellResizer: Table cell node not found.');
              }

              const tableNode = $getTableNodeFromLexicalNodeOrThrow(tableCellNode);
              const tableElement = editor.getElementByKey(tableNode.getKey());

              if (!tableElement) {
                throw new Error('TableCellResizer: Table element not found.');
              }

              targetRef.current = target as HTMLElement;
              tableRectRef.current = tableElement.getBoundingClientRect();
              setActiveCell(cell);
            });
          } else if (cell == null) {
            resetState();
          }
        }
      }, 0);
    };

    const onMouseDown = () => setTimeout(() => setIsMouseDown(true), 0);
    const onMouseUp = () => setTimeout(() => setIsMouseDown(false), 0);

    const removeRootListener = editor.registerRootListener((rootElement, prevRootElement) => {
      rootElement?.addEventListener('mousemove', onMouseMove);
      rootElement?.addEventListener('mousedown', onMouseDown);
      rootElement?.addEventListener('mouseup', onMouseUp);

      prevRootElement?.removeEventListener('mousemove', onMouseMove);
      prevRootElement?.removeEventListener('mousedown', onMouseDown);
      prevRootElement?.removeEventListener('mouseup', onMouseUp);
    });

    return () => {
      removeRootListener();
    };
  }, [activeCell, draggingDirection, editor, resetState]);

  const updateRowHeight = useCallback(
    (heightChange: number) => {
      if (!activeCell) {
        throw new Error('TableCellResizer: Expected active cell.');
      }

      editor.update(
        () => {
          const tableCellNode = $getNearestNodeFromDOMNode(activeCell.elem);
          if (!$isTableCellNode(tableCellNode)) {
            throw new Error('TableCellResizer: Table cell node not found.');
          }

          const tableNode = $getTableNodeFromLexicalNodeOrThrow(tableCellNode);

          const tableRowIndex = $getTableRowIndexFromTableCellNode(tableCellNode);

          const tableRows = tableNode.getChildren();

          if (tableRowIndex >= tableRows.length || tableRowIndex < 0) {
            throw new Error('Expected table cell to be inside of table row.');
          }

          const tableRow = tableRows[tableRowIndex];

          if (!$isTableRowNode(tableRow)) {
            throw new Error('Expected table row');
          }

          let height = tableRow.getHeight();
          if (height === undefined) {
            const rowCells = tableRow.getChildren<TableCellNode>();
            height = Math.min(
              ...rowCells.map((cell) => getCellNodeHeight(cell, editor) ?? Infinity),
            );
          }

          const newHeight = Math.max(height + heightChange, minRowHeight);
          tableRow.setHeight(newHeight);
        },
        { tag: 'skip-scroll-into-view' },
      );
    },
    [activeCell, editor, minRowHeight],
  );

  const updateColumnWidth = useCallback(
    (widthChange: number) => {
      if (!activeCell) {
        throw new Error('TableCellResizer: Expected active cell.');
      }
      editor.update(
        () => {
          const tableCellNode = $getNearestNodeFromDOMNode(activeCell.elem);
          if (!$isTableCellNode(tableCellNode)) {
            throw new Error('TableCellResizer: Table cell node not found.');
          }

          const tableNode = $getTableNodeFromLexicalNodeOrThrow(tableCellNode);
          const [tableMap] = $computeTableMapSkipCellCheck(tableNode, null, null);
          const columnIndex = getCellColumnIndex(tableCellNode, tableMap);
          if (columnIndex === undefined) {
            throw new Error('TableCellResizer: Table column not found.');
          }

          for (let row = 0; row < tableMap.length; row++) {
            const cell: TableMapValueType = tableMap[row]![columnIndex]!;
            if (
              cell.startRow === row &&
              (columnIndex === tableMap[row]!.length - 1 ||
                tableMap[row]![columnIndex]!.cell !== tableMap[row]![columnIndex + 1]!.cell)
            ) {
              const width = getCellNodeWidth(cell.cell, editor);
              if (width === undefined) {
                continue;
              }
              const newWidth = Math.max(width + widthChange, minColumnWidth);
              cell.cell.setWidth(newWidth);
            }
          }
        },
        { tag: 'skip-scroll-into-view' },
      );
    },
    [activeCell, editor, minColumnWidth],
  );

  const mouseUpHandler = useCallback(
    (direction: MouseDraggingDirection) => {
      const handler = (event: MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();

        if (!activeCell) {
          throw new Error('TableCellResizer: Expected active cell.');
        }

        if (mouseStartPosRef.current) {
          const { x, y } = mouseStartPosRef.current;

          if (activeCell === null) {
            return;
          }
          const zoom = calculateZoomLevel(event.target as Element);

          if (isHeightChanging(direction)) {
            const heightChange = (event.clientY - y) / zoom;
            updateRowHeight(heightChange);
          } else {
            const widthChange = (event.clientX - x) / zoom;
            updateColumnWidth(widthChange);
          }

          resetState();
          document.removeEventListener('mouseup', handler);
        }
      };
      return handler;
    },
    [activeCell, resetState, updateColumnWidth, updateRowHeight],
  );

  const toggleResize = useCallback(
    (direction: MouseDraggingDirection): MouseEventHandler<HTMLDivElement> =>
      (event) => {
        event.preventDefault();
        event.stopPropagation();

        if (!activeCell) {
          throw new Error('TableCellResizer: Expected active cell.');
        }

        mouseStartPosRef.current = {
          x: event.clientX,
          y: event.clientY,
        };
        updateMouseCurrentPos(mouseStartPosRef.current);
        setDraggingDirection(direction);

        document.addEventListener('mouseup', mouseUpHandler(direction));
      },
    [activeCell, mouseUpHandler],
  );

  const getResizers = useCallback(() => {
    if (!activeCell) {
      return {
        bottom: null,
        left: null,
        right: null,
        top: null,
      };
    }

    const { height, width, top, left } = activeCell.elem.getBoundingClientRect();
    const zoom = calculateZoomLevel(activeCell.elem);
    const styles = {
      bottom: {
        position: 'absolute',
        backgroundColor: 'none',
        cursor: 'row-resize',
        height: `${handleWidth}px`,
        left: `${window.scrollX + left}px`,
        top: `${window.scrollY + top + height - handleWidth / 2}px`,
        width: `${width}px`,
        zIndex: 'calc(var(--chakra-zIndices-modal, 0) + 1)',
      } as CSSProperties,
      right: {
        position: 'absolute',
        backgroundColor: 'none',
        cursor: 'col-resize',
        height: `${height}px`,
        left: `${window.scrollX + left + width - handleWidth / 2}px`,
        top: `${window.scrollY + top}px`,
        width: `${handleWidth}px`,
        zIndex: 'calc(var(--chakra-zIndices-modal, 0) + 1)',
      } as CSSProperties,
    };

    const tableRect = tableRectRef.current;

    if (draggingDirection && mouseCurrentPos && tableRect) {
      if (isHeightChanging(draggingDirection)) {
        styles[draggingDirection].left = `${window.scrollX + tableRect.left}px`;
        styles[draggingDirection].top = `${window.scrollY + mouseCurrentPos.y / zoom}px`;
        styles[draggingDirection].height = '3px';
        styles[draggingDirection].width = `${tableRect.width}px`;
      } else {
        styles[draggingDirection].top = `${window.scrollY + tableRect.top}px`;
        styles[draggingDirection].left = `${window.scrollX + mouseCurrentPos.x / zoom}px`;
        styles[draggingDirection].width = '3px';
        styles[draggingDirection].height = `${tableRect.height}px`;
      }

      styles[draggingDirection].backgroundColor = handleColor;
    }

    return styles;
  }, [activeCell, draggingDirection, handleColor, handleWidth, mouseCurrentPos]);

  const resizerStyles = getResizers();

  return (
    <Box ref={resizerRef}>
      {activeCell != null && !isMouseDown && (
        <>
          <Box
            className="TableCellResizer__resizer"
            style={resizerStyles.right || undefined}
            onMouseDown={toggleResize('right')}
          />
          <Box
            className="TableCellResizer__resizer"
            style={resizerStyles.bottom || undefined}
            onMouseDown={toggleResize('bottom')}
          />
        </>
      )}
    </Box>
  );
}

function isMouseDownOnEvent(event: MouseEvent) {
  return (event.buttons & 1) === 1;
}

function isHeightChanging(direction: MouseDraggingDirection) {
  if (direction === 'bottom') {
    return true;
  }
  return false;
}

function getCellNodeWidth(cell: TableCellNode, activeEditor: LexicalEditor): number | undefined {
  const width = cell.getWidth();
  if (width !== undefined) {
    return width;
  }

  const domCellNode = activeEditor.getElementByKey(cell.getKey());
  if (domCellNode == null) {
    return undefined;
  }
  const computedStyle = getComputedStyle(domCellNode);
  return (
    domCellNode.clientWidth -
    parseFloat(computedStyle.paddingLeft) -
    parseFloat(computedStyle.paddingRight)
  );
}

function getCellNodeHeight(cell: TableCellNode, activeEditor: LexicalEditor): number | undefined {
  const domCellNode = activeEditor.getElementByKey(cell.getKey());
  return domCellNode?.clientHeight;
}

function getCellColumnIndex(tableCellNode: TableCellNode, tableMap: TableMapType) {
  for (let row = 0; row < tableMap.length; row++) {
    for (let column = 0; column < tableMap[row]!.length; column++) {
      if (tableMap[row]![column]!.cell === tableCellNode) {
        return column;
      }
    }
  }
  return undefined;
}

import { useComboboxContext } from '@ariakit/react';
import { Box, Modal, ModalContent, ModalOverlay, UseDisclosureReturn } from '@chakra-ui/react';
import { MakeRequired } from '@main/shared/types';
import useEventListener from 'ahooks/es/useEventListener';

import { Shortcut, ShortcutModifier } from '../shortcut';
import { GlobalSearch, GlobalSearchProps, GlobalSearchResult } from './global-search';

export interface GlobalSearchModalProps<TResult extends GlobalSearchResult>
  extends GlobalSearchProps<TResult> {
  disclosure: MakeRequired<Partial<UseDisclosureReturn>, 'isOpen'>;
  openOnShortcut?: boolean;
  shortcutMatcher?: (e: KeyboardEvent) => boolean;
}

export function GlobalSearchModal<TResult extends GlobalSearchResult>(
  props: GlobalSearchModalProps<TResult>,
) {
  const shortcutMatcher =
    props.shortcutMatcher ?? props.openOnShortcut
      ? (e: KeyboardEvent) => e.code === 'KeyK' && (e.metaKey || e.ctrlKey)
      : () => false;
  const hasDefaultShortcut = !!props.openOnShortcut && !props.shortcutMatcher;
  const rightElement = hasDefaultShortcut ? GlobalSearchModal.RightElement : undefined;

  useEventListener(
    'keydown',
    (e) => {
      if (shortcutMatcher(e)) {
        e.preventDefault();
        props.disclosure.onOpen?.();
      }
    },
    { target: window },
  );

  return (
    <Modal
      size="4xl"
      scrollBehavior="inside"
      {...props.disclosure}
      onClose={() => props.disclosure.onClose?.()}
    >
      <ModalOverlay />
      <ModalContent>
        <GlobalSearch {...props} input={{ rightElement, ...props.input }} />
      </ModalContent>
    </Modal>
  );
}

GlobalSearchModal.RightElement = function RightElement() {
  const combobox = useComboboxContext();

  if (combobox?.getState().value) {
    return null;
  }

  return (
    <Box pr="2" pointerEvents="none" userSelect="none">
      <Shortcut modifier={ShortcutModifier.Ctrl} keyName="K" />
    </Box>
  );
};

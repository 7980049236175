import { Outlet, Route } from '@tanstack/react-router';

import { authLayout } from '../login';

const SettingsPage = () => {
  return <Outlet />;
};

export const settingsRoute = new Route({
  getParentRoute: () => authLayout,
  path: '/settings',
  component: SettingsPage,
});

import { ThemeTypings } from '@chakra-ui/react';
import { Treatment_Plan_Enum } from '@main/graphql/types.generated';

export const RiskStatusColors = {
  Accepted: 'purple',
  Avoided: 'teal',
  Mitigated: 'green',
  Pending: 'gray',
  Transferred: 'orange',
} satisfies Record<Treatment_Plan_Enum, ThemeTypings['colorSchemes']>;

export const categoryColumnId = 'category';
export const RISKS_TABLE_FILTER_PARAM_NAME = 'risksFilter' as string;
export const RISKS_TABLE_SORT_PARAM_NAME = 'risksSort' as string;

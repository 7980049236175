import { Route } from '@tanstack/react-router';

import { RiskRegister } from '../../features/risks/risk-register';
import { risksRoute } from './risks';

export const riskIndexRoute = new Route({
  getParentRoute: () => risksRoute,
  path: '/',
  component: RiskRegister,
});

import {
  chakra,
  Editable,
  EditableInput as ChakraEditableInput,
  EditableInputProps as ChakraEditableInputProps,
  EditablePreview,
  EditablePreviewProps,
  EditableProps,
  forwardRef,
  Input,
  Text,
  useColorModeValue,
  useEditableContext,
  useEditableStyles,
  useFormControl,
} from '@chakra-ui/react';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { formatDate } from '../../utils/locale-date';

export interface EditableInputProps {
  editable?: EditableProps;
  input?: ChakraEditableInputProps;
}

const DEFAULT_HEIGHT = '38px';
export const EditableInput = forwardRef<EditableInputProps, 'div'>((props, ref) => {
  const { t } = useTranslation('ui');
  const hoverBgColor = useColorModeValue('gray.100', 'gray.600');
  const focusBgColor = useColorModeValue('gray.25', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'rgba(255,255,255,0.16)');
  const placeholderColor = useColorModeValue('gray.400', 'gray.500');
  const valueColor = useColorModeValue('gray.600', 'gray.600');
  const inputRef = useRef<HTMLInputElement>(null);
  const inputPreviewRef = useRef<HTMLElement>(null);
  const [isValid, setIsValid] = useState(false);
  const [value, setValue] = useState(props.editable?.defaultValue ?? '');
  const { id, readOnly, disabled, required } = useFormControl({});
  const isDisabled = props.editable?.isDisabled || disabled || readOnly;

  const revalidate = () => setIsValid(!!inputRef.current?.validity.valid);

  const onSubmit = (nextValue: string) => {
    if (isValid) {
      props.editable?.onSubmit?.(nextValue);
    } else {
      setValue(props.editable?.defaultValue ?? '');
    }
  };

  const handlePreviewClick = () => {
    if (inputPreviewRef.current) {
      inputPreviewRef.current.focus();
    }
  };

  // Handle label click to focus textarea
  useEffect(() => {
    const labelElement = document.querySelector(`label[for="${id}"]`);
    if (labelElement) {
      labelElement.addEventListener('click', handlePreviewClick);
    }
    return () => {
      if (labelElement) {
        labelElement.removeEventListener('click', handlePreviewClick);
      }
    };
  }, [id]);

  useEffect(() => revalidate(), []);

  return (
    <Editable
      isDisabled={isDisabled}
      placeholder={props.placeholder ?? t('editable.placeholder')}
      {...props.editable}
      value={value}
      onChange={(value) => setValue(value)}
      ref={ref}
      onSubmit={onSubmit}
      h={DEFAULT_HEIGHT}
    >
      <ChakraEditableInput
        {...props.input}
        as={Input}
        id={id}
        px={2}
        pt={2}
        pb={'9px'}
        w="full"
        h={'full'}
        fontSize={'xs'}
        isInvalid={!isValid}
        disabled={disabled}
        required={required}
        readOnly={readOnly}
        ref={inputRef}
        _focusVisible={{
          borderColor: borderColor,
          bgColor: focusBgColor,
        }}
        _focus={{
          boxShadow: 'none',
        }}
        _invalid={{
          borderColor: 'red.300',
        }}
        onChange={() => revalidate()}
        onFocus={() => revalidate()}
      />
      <EditableInputPreview
        type={props.input?.type}
        ref={inputPreviewRef}
        aria-labelledby={`${id}-label`}
        role="button"
        py={2}
        width={'full'}
        h={'full'}
        border="1px"
        borderColor={'transparent'}
        color={inputRef.current?.value ? valueColor : placeholderColor}
        _hover={isDisabled ? {} : { bg: hoverBgColor, borderColor: hoverBgColor }}
      />
    </Editable>
  );
});

interface EditableInputPreviewProps extends EditablePreviewProps {
  type?: string;
}

const EditableInputPreview = forwardRef<EditableInputPreviewProps, 'span'>(
  ({ type, ...rest }: EditableInputPreviewProps, ref) => {
    const { getPreviewProps, value } = useEditableContext();
    const styles = useEditableStyles();
    const previewProps = getPreviewProps(rest, ref);
    const isDate = type === 'date';
    const isCustomPreview = isDate;

    if (isCustomPreview) {
      return (
        <chakra.span
          {...previewProps}
          {...rest}
          ref={ref}
          __css={{
            ...styles['preview'],
          }}
          cursor={'text'}
          display={'flex'}
          alignContent={'center'}
          px={2}
        >
          {isDate && <EditableInputPreviewDate value={value} placeholder={previewProps.children} />}
        </chakra.span>
      );
    }

    return (
      <EditablePreview
        {...rest}
        ref={ref}
        display={'flex'}
        alignItems={'center'}
        fontSize={'xs'}
        px={2}
      />
    );
  },
);

interface EditableInputPreviewDateProps {
  value?: string;
  placeholder?: ReactNode;
}

const EditableInputPreviewDate = ({ value, placeholder }: EditableInputPreviewDateProps) => {
  const formattedDate = value ? formatDate(value) : placeholder;

  return (
    <Text fontSize={'xs'} lineHeight={'20px'}>
      {formattedDate}
    </Text>
  );
};

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type GetOrganizationControlsOnAlertQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['uuid']['input'];
}>;

export type GetOrganizationControlsOnAlertQuery = {
  __typename?: 'query_root';
  controls_aggregate: {
    __typename?: 'controls_aggregate';
    aggregate?: { __typename?: 'controls_aggregate_fields'; count: number };
  };
};

export const GetOrganizationControlsOnAlertDocument = `
    query GetOrganizationControlsOnAlert($organizationId: uuid!) {
  controls_aggregate(
    where: {organization_id: {_eq: $organizationId}, status: {_in: ["Failing", "At risk"]}}
  ) {
    aggregate {
      count
    }
  }
}
    ` as string &
  TypedDocumentNode<
    GetOrganizationControlsOnAlertQuery,
    GetOrganizationControlsOnAlertQueryVariables
  >;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetOrganizationControlsOnAlert: build.query<
      GetOrganizationControlsOnAlertQuery,
      GetOrganizationControlsOnAlertQueryVariables
    >({
      query: (variables) => ({ document: GetOrganizationControlsOnAlertDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useGetOrganizationControlsOnAlertQuery,
  useLazyGetOrganizationControlsOnAlertQuery,
} = injectedRtkApi;

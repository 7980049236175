import { Center, Skeleton } from '@chakra-ui/react';

export const TabsSkeleton = () => {
  return new Array(5).fill(0).map((_, index) => (
    <Center key={index} flexDirection={'column'} gap={1.5}>
      <Skeleton h={'35px'} w={'35px'} rounded={'6px'} />
      <Skeleton h={'8px'} w={'35px'} />
    </Center>
  ));
};

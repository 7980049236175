import { Outlet, Route } from '@tanstack/react-router';

import { organizationSettingsLayoutRoute } from '../layout';

const VendorsLayout = () => {
  return <Outlet />;
};

export const organizationVendorsSettingsLayoutRoute = new Route({
  getParentRoute: () => organizationSettingsLayoutRoute,
  path: 'vendors',
  component: VendorsLayout,
});

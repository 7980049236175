import { RiskAssessmentStatus, VendorAssessmentStatus } from '@main/graphql/client-scalars';

export const RISK_ASSESSMENT_STATUS_COLOR = {
  [RiskAssessmentStatus.PENDING]: 'gray',
  [RiskAssessmentStatus.REVIEWED]: 'green',
  [RiskAssessmentStatus.EXPIRING]: 'orange',
  [RiskAssessmentStatus.OVERDUE]: 'red',
};

export const VENDOR_ASSESSMENT_STATUS_COLOR = {
  [VendorAssessmentStatus.PENDING]: 'gray',
  [VendorAssessmentStatus.REVIEWED]: 'green',
  [VendorAssessmentStatus.EXPIRING]: 'orange',
  [VendorAssessmentStatus.OVERDUE]: 'red',
  [VendorAssessmentStatus.TERMINATED]: 'red',
};

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type GetReportByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
}>;

export type GetReportByIdQuery = {
  __typename?: 'query_root';
  reports_by_pk?: {
    __typename?: 'reports';
    id: string;
    created_at: string;
    created_by: string;
    description?: string;
    filters?: ClientTypes.ColumnFiltersState;
    sorting?: ClientTypes.SortingState;
    name?: string;
    organization_id: string;
    type: Types.Report_Types_Enum;
  };
};

export const GetReportByIdDocument = `
    query GetReportById($id: uuid!) {
  reports_by_pk(id: $id) {
    id
    created_at
    created_by
    description
    filters
    sorting
    name
    organization_id
    type
  }
}
    ` as string & TypedDocumentNode<GetReportByIdQuery, GetReportByIdQueryVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetReportById: build.query<GetReportByIdQuery, GetReportByIdQueryVariables>({
      query: (variables) => ({ document: GetReportByIdDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetReportByIdQuery, useLazyGetReportByIdQuery } = injectedRtkApi;

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type DeleteOrganizationInvitationMutationVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['uuid']['input']>;
}>;

export type DeleteOrganizationInvitationMutation = {
  __typename?: 'mutation_root';
  delete_invitations?: { __typename?: 'invitations_mutation_response'; affected_rows: number };
};

export const DeleteOrganizationInvitationDocument = `
    mutation DeleteOrganizationInvitation($id: uuid) {
  delete_invitations(where: {id: {_eq: $id}}) {
    affected_rows
  }
}
    ` as string &
  TypedDocumentNode<
    DeleteOrganizationInvitationMutation,
    DeleteOrganizationInvitationMutationVariables
  >;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    DeleteOrganizationInvitation: build.mutation<
      DeleteOrganizationInvitationMutation,
      DeleteOrganizationInvitationMutationVariables | void
    >({
      query: (variables) => ({ document: DeleteOrganizationInvitationDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useDeleteOrganizationInvitationMutation } = injectedRtkApi;

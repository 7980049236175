/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type InsertEvidenceVersionMutationVariables = Types.Exact<{
  evidenceId: Types.Scalars['uuid']['input'];
  evidenceVersion: Types.Evidence_Versions_Insert_Input;
}>;

export type InsertEvidenceVersionMutation = {
  __typename?: 'mutation_root';
  update_evidence_versions?: {
    __typename?: 'evidence_versions_mutation_response';
    affected_rows: number;
  };
  insert_evidence_versions_one?: { __typename?: 'evidence_versions'; id: string };
};

export const InsertEvidenceVersionDocument = `
    mutation InsertEvidenceVersion($evidenceId: uuid!, $evidenceVersion: evidence_versions_insert_input!) {
  update_evidence_versions(
    where: {evidence_id: {_eq: $evidenceId}, is_current: {_eq: true}}
    _set: {is_current: false}
  ) {
    affected_rows
  }
  insert_evidence_versions_one(object: $evidenceVersion) {
    id
  }
}
    ` as string &
  TypedDocumentNode<InsertEvidenceVersionMutation, InsertEvidenceVersionMutationVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    InsertEvidenceVersion: build.mutation<
      InsertEvidenceVersionMutation,
      InsertEvidenceVersionMutationVariables
    >({
      query: (variables) => ({ document: InsertEvidenceVersionDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useInsertEvidenceVersionMutation } = injectedRtkApi;

import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  Switch,
  Text,
} from '@chakra-ui/react';
import { Cog6ToothIcon } from '@heroicons/react/24/outline';
import { Table } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

export function TableSettings<TData>({ table }: { table: Table<TData> }) {
  const { t } = useTranslation('ui');
  const columns = table.getAllColumns();

  return (
    <Box>
      <Menu placement="bottom-end">
        <MenuButton
          aria-label={t('table.settings.buttonLabel')}
          variant="outline"
          w={12}
          color="gray.500"
          icon={<Cog6ToothIcon width="16px" />}
          as={IconButton}
        />
        <MenuList p={4} maxH="350px" overflow="auto">
          <Flex direction="column" gap={4}>
            <Text fontSize="xs" fontWeight="medium" color="gray.400">
              {t('table.settings.columnsHeading')}
            </Text>
            {columns.map((column) => (
              <FormControl key={column.id}>
                <Flex align="center" gap={2}>
                  <FormLabel m={0} flexGrow={1} fontSize="sm" fontWeight="normal">
                    {column.columnDef.meta?.name || ''}
                  </FormLabel>
                  <Switch
                    size="sm"
                    isChecked={column.getIsVisible()}
                    onChange={column.getToggleVisibilityHandler()}
                  />
                </Flex>
              </FormControl>
            ))}
          </Flex>
        </MenuList>
      </Menu>
    </Box>
  );
}

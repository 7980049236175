import { Box, Stack, useColorMode, useColorModeValue } from '@chakra-ui/react';
import { Global } from '@emotion/react';
import { InitialConfigType, LexicalComposer } from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';
import { createEmptyHistoryState, HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { TablePlugin } from '@lexical/react/LexicalTablePlugin';
import { EditorState, LexicalEditor } from 'lexical';
import { useState } from 'react';

import AutoLinkPlugin from '../lexical/plugins/auto-link';
import { CodeHighlightPlugin } from '../lexical/plugins/code-highlight';
import { FloatingLinkEditorPlugin } from '../lexical/plugins/floating-link-editor';
import TableCellResizerPlugin from '../lexical/plugins/table-cell-resizer';
import { TableContextMenuPlugin } from '../lexical/plugins/table-context-menu';
import { ToolbarPlugin } from '../lexical/plugins/toolbar';
import theme from '../lexical/themes/wysiwyg';
import styles from '../lexical/themes/wysiwyg/styles';
import { WysiwygNodes } from './nodes';

export interface WysiwygEditorProps {
  id?: string;
  placeholder?: string;
  config?: Partial<InitialConfigType>;
  onStateChange?(editorState: EditorState, editor: LexicalEditor, tags: Set<string>): void;
}

export function WysiwygEditor(props: WysiwygEditorProps) {
  return (
    <LexicalComposer
      initialConfig={{
        theme,
        namespace: `wysiwig-${props.id ?? 'editor'}`,
        onError: console.error,
        nodes: [...WysiwygNodes],
        ...props.config,
      }}
    >
      <Global styles={styles} />
      <WysiwygPlugins {...props} />
    </LexicalComposer>
  );
}

function WysiwygPlugins(props: WysiwygEditorProps) {
  const { colorMode } = useColorMode();
  const backgroundColor = useColorModeValue('white', 'gray.800');
  const historyState = createEmptyHistoryState();
  const [floatingAnchorElem, setFloatingAnchorElem] = useState<HTMLElement>();
  const tiggerContextMenu = TableContextMenuPlugin.useTriggerContextMenu();
  const onStateChange = props.onStateChange;

  return (
    <Box
      position="relative"
      w="auto"
      minW="full"
      ref={(elem) => elem && setFloatingAnchorElem(elem)}
    >
      <Stack
        spacing="0"
        className={`editor-mode-${colorMode}`}
        background={backgroundColor}
        border="1px"
        borderColor={useColorModeValue('gray.200', 'gray.700')}
        borderRadius="xl"
        style={{ contain: 'content' }}
      >
        <ToolbarPlugin position="sticky" top="0" p="3" background={backgroundColor} zIndex="1" />
        <Box className="editor-container" p="3" minH="initial" overflowX="auto">
          <RichTextPlugin
            placeholder={<div className="editor-placeholder">{props.placeholder}</div>}
            contentEditable={
              <ContentEditable className="editor-input" onContextMenu={tiggerContextMenu} />
            }
            ErrorBoundary={LexicalErrorBoundary}
          />
        </Box>
      </Stack>
      <HistoryPlugin externalHistoryState={historyState} />
      <CodeHighlightPlugin />
      <ListPlugin />
      <LinkPlugin />
      <AutoLinkPlugin />
      <FloatingLinkEditorPlugin anchorElem={floatingAnchorElem} />
      <TablePlugin hasCellMerge hasCellBackgroundColor />
      <TableContextMenuPlugin />
      <TableCellResizerPlugin />
      {onStateChange && <OnChangePlugin onChange={onStateChange} />}
    </Box>
  );
}

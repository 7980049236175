import {
  Flex,
  FlexProps,
  forwardRef,
  Icon as ChakraIcon,
  IconProps,
  Text,
  TextProps,
  useColorModeValue,
} from '@chakra-ui/react';

export const EmptyPlaceholder = (props: FlexProps) => (
  <Flex
    direction="column"
    alignItems="center"
    justifyContent={'center'}
    py={4}
    gap={4}
    height={'full'}
    {...props}
  />
);

export const Icon = forwardRef<IconProps, 'svg'>((props, ref) => {
  const outerBgColor = useColorModeValue('purple.50', 'purple.200');
  const innerBgColor = useColorModeValue('purple.100', 'purple.300');

  return (
    <Flex w={12} h={12} justify="center" alignItems="center" bgColor={outerBgColor} rounded="full">
      <Flex w={9} h={9} justify="center" alignItems="center" bgColor={innerBgColor} rounded="full">
        <ChakraIcon w={5} h={5} color="blue.600" ref={ref} {...props} />
      </Flex>
    </Flex>
  );
});

const Content = (props: FlexProps) => {
  return <Flex direction="column" alignItems="center" gap={1} px={16} {...props} />;
};

const Heading = (props: TextProps) => {
  return <Text fontSize="sm" fontWeight="semibold" {...props} />;
};

const Subheading = (props: TextProps) => {
  return (
    <Text color={'gray.400'} fontSize="xs" textAlign="center" whiteSpace="pre-line" {...props} />
  );
};

EmptyPlaceholder.Icon = Icon;
EmptyPlaceholder.Content = Content;
EmptyPlaceholder.Heading = Heading;
EmptyPlaceholder.Subheading = Subheading;

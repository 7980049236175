/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type GetReportsForOrganizationQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['uuid']['input'];
  updateAtOrder?: Types.InputMaybe<Types.Order_By>;
}>;

export type GetReportsForOrganizationQuery = {
  __typename?: 'query_root';
  reports: Array<{
    __typename?: 'reports';
    created_by: string;
    description?: string;
    id: string;
    name?: string;
    organization_id: string;
    updated_at: string;
    type: Types.Report_Types_Enum;
  }>;
};

export const GetReportsForOrganizationDocument = `
    query GetReportsForOrganization($organizationId: uuid!, $updateAtOrder: order_by = desc) {
  reports(
    order_by: {updated_at: $updateAtOrder}
    where: {organization_id: {_eq: $organizationId}}
  ) {
    created_by
    description
    id
    name
    organization_id
    updated_at
    type
  }
}
    ` as string &
  TypedDocumentNode<GetReportsForOrganizationQuery, GetReportsForOrganizationQueryVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetReportsForOrganization: build.query<
      GetReportsForOrganizationQuery,
      GetReportsForOrganizationQueryVariables
    >({
      query: (variables) => ({ document: GetReportsForOrganizationDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetReportsForOrganizationQuery, useLazyGetReportsForOrganizationQuery } =
  injectedRtkApi;

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type InsertEvidenceMutationVariables = Types.Exact<{
  object: Types.Evidences_Insert_Input;
}>;

export type InsertEvidenceMutation = {
  __typename?: 'mutation_root';
  insert_evidences_one?: { __typename?: 'evidences'; id: string };
};

export const InsertEvidenceDocument = `
    mutation InsertEvidence($object: evidences_insert_input!) {
  insert_evidences_one(object: $object) {
    id
  }
}
    ` as string & TypedDocumentNode<InsertEvidenceMutation, InsertEvidenceMutationVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    InsertEvidence: build.mutation<InsertEvidenceMutation, InsertEvidenceMutationVariables>({
      query: (variables) => ({ document: InsertEvidenceDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useInsertEvidenceMutation } = injectedRtkApi;

import type { GraphQLError } from 'graphql';

export type GQLResponse<T = unknown> = GQLResponseSuccess<T> | GQLResponseError;

export interface GQLResponseSuccess<T> {
  data: T;
  error: null;
}

export interface GQLResponseError {
  data: null;
  error: GraphQLError[] | ErrorPayload;
}

export interface ErrorPayload {
  error: string;
  status: number;
  message: string;
}

export function getResponseDataOrThrow<T>(response: GQLResponse<T>): T {
  throwIfError(response);
  return response.data;
}

export function throwIfError<T>(
  response: GQLResponse<T>,
): asserts response is GQLResponseSuccess<T> {
  if (response.error) {
    throw response.error;
  }
}

export const isGraphqlError = (error: unknown): error is GraphQLError[] => {
  if (error instanceof Array) {
    if ('extensions' in error[0]) {
      return true;
    }
  }

  return false;
};

export function toError(input: unknown): Error {
  if (input instanceof Error) {
    return input;
  }

  if (typeof input === 'string') {
    return new Error(input);
  }

  if (isErrorLike(input)) {
    return Object.assign(new Error(), input);
  }

  try {
    return new Error(JSON.stringify(input));
  } catch {
    return new Error(String(input));
  }
}

function isErrorLike(input: unknown): input is { message: string } {
  return (
    typeof input === 'object' &&
    input !== null &&
    'message' in input &&
    typeof input.message === 'string'
  );
}

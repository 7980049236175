import { HStack, Icon, IconButton, Input, Link, useColorModeValue } from '@chakra-ui/react';
import { CheckIcon, PencilSquareIcon, TrashIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { $createLinkNode, $isAutoLinkNode, $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $isAtNodeEnd } from '@lexical/selection';
import { TableSelection } from '@lexical/table';
import { $findMatchingParent, mergeRegister } from '@lexical/utils';
import {
  $getSelection,
  $isLineBreakNode,
  $isRangeSelection,
  BaseSelection,
  CLICK_COMMAND,
  COMMAND_PRIORITY_HIGH,
  COMMAND_PRIORITY_LOW,
  KEY_ESCAPE_COMMAND,
  RangeSelection,
  SELECTION_CHANGE_COMMAND,
} from 'lexical';
import { KeyboardEvent, SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

export interface FloatingLinkEditorPluginProps {
  anchorElem?: HTMLElement;
}

export function FloatingLinkEditorPlugin({
  anchorElem = document.body,
}: FloatingLinkEditorPluginProps) {
  return createPortal(<FloatingLinkEditor anchorElem={anchorElem} />, anchorElem);
}

function FloatingLinkEditor({ anchorElem }: { anchorElem: HTMLElement }) {
  const [editor] = useLexicalComposerContext();
  const editorRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [linkUrl, setLinkUrl] = useState('');
  const [editedLinkUrl, setEditedLinkUrl] = useState('https://');
  const [lastSelection, setLastSelection] = useState<BaseSelection | null>(null);
  const [isLinkEditMode, setIsLinkEditMode] = useState(false);
  const [isLink, setIsLink] = useState(false);

  const updateLink = useCallback((selection: RangeSelection) => {
    const focusNode = getSelectedNode(selection);
    const focusLinkNode = $findMatchingParent(focusNode, $isLinkNode);
    const focusAutoLinkNode = $findMatchingParent(focusNode, $isAutoLinkNode);
    if (!(focusLinkNode || focusAutoLinkNode)) {
      setIsLink(false);
      return;
    }
    const badNode = selection
      .getNodes()
      .filter((node) => !$isLineBreakNode(node))
      .find((node) => {
        const linkNode = $findMatchingParent(node, $isLinkNode);
        const autoLinkNode = $findMatchingParent(node, $isAutoLinkNode);
        return (
          (focusLinkNode && !focusLinkNode.is(linkNode)) ||
          (linkNode && !linkNode.is(focusLinkNode)) ||
          (focusAutoLinkNode && !focusAutoLinkNode.is(autoLinkNode)) ||
          (autoLinkNode && (!autoLinkNode.is(focusAutoLinkNode) || autoLinkNode.getIsUnlinked()))
        );
      });
    if (!badNode) {
      setIsLink(true);
    } else {
      setIsLink(false);
    }
  }, []);

  const updateLinkEditor = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      updateLink(selection);
      const node = getSelectedNode(selection);
      const linkParent = $findMatchingParent(node, $isLinkNode);

      if (linkParent) {
        setLinkUrl(linkParent.getURL());
      } else if ($isLinkNode(node)) {
        setLinkUrl(node.getURL());
      } else {
        setLinkUrl('');
      }
      if (isLinkEditMode) {
        setEditedLinkUrl(linkUrl);
      }
    }
    const editorElem = editorRef.current;
    const nativeSelection = window.getSelection();
    const activeElement = document.activeElement;

    if (editorElem === null) {
      return;
    }

    const rootElement = editor.getRootElement();

    if (
      selection !== null &&
      nativeSelection !== null &&
      rootElement !== null &&
      rootElement.contains(nativeSelection.anchorNode) &&
      editor.isEditable()
    ) {
      const domRect: DOMRect | undefined =
        nativeSelection.focusNode?.parentElement?.getBoundingClientRect();
      if (domRect) {
        domRect.y += 40;
        setFloatingElemPositionForLinkEditor(domRect, editorElem, anchorElem);
      }
      setLastSelection(selection);
    } else if (!activeElement || activeElement.className !== 'link-input') {
      if (rootElement !== null) {
        setFloatingElemPositionForLinkEditor(null, editorElem, anchorElem);
      }
      setLastSelection(null);
      setIsLinkEditMode(false);
      setLinkUrl('');
    }
  }, [editor, updateLink, isLinkEditMode, linkUrl, anchorElem]);

  useEffect(() => {
    const scrollerElem = anchorElem.parentElement;
    const update = () => editor.getEditorState().read(updateLinkEditor);

    window.addEventListener('resize', update);

    if (scrollerElem) {
      scrollerElem.addEventListener('scroll', update);
    }

    return () => {
      window.removeEventListener('resize', update);

      if (scrollerElem) {
        scrollerElem.removeEventListener('scroll', update);
      }
    };
  }, [anchorElem.parentElement, editor, updateLinkEditor]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => editorState.read(updateLinkEditor)),
      editor.registerCommand(
        CLICK_COMMAND,
        (payload) => {
          const selection = $getSelection();
          if ($isRangeSelection(selection)) {
            const node = getSelectedNode(selection);
            const linkNode = $findMatchingParent(node, $isLinkNode);
            if ($isLinkNode(linkNode) && (payload.metaKey || payload.ctrlKey)) {
              window.open(linkNode.getURL(), '_blank');
              return true;
            }
          }
          return false;
        },
        COMMAND_PRIORITY_LOW,
      ),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateLinkEditor();
          return true;
        },
        COMMAND_PRIORITY_LOW,
      ),
      editor.registerCommand(
        KEY_ESCAPE_COMMAND,
        () => {
          if (isLink) {
            setIsLink(false);
            return true;
          }
          return false;
        },
        COMMAND_PRIORITY_HIGH,
      ),
    );
  }, [editor, updateLinkEditor, isLink]);

  useEffect(() => editor.getEditorState().read(updateLinkEditor), [editor, updateLinkEditor]);

  useEffect(() => {
    if (isLinkEditMode) {
      inputRef.current?.focus();
    }
  }, [isLinkEditMode, isLink]);

  function monitorInputInteraction(event: KeyboardEvent<HTMLInputElement>) {
    switch (event.key) {
      case 'Enter':
        event.preventDefault();
        handleLinkSubmission();
        break;
      case 'Escape':
        event.preventDefault();
        setIsLinkEditMode(false);
        break;
    }
  }

  function handleLinkSubmission() {
    if (lastSelection === null) {
      return;
    }

    setEditedLinkUrl('https://');
    setIsLinkEditMode(false);

    if (linkUrl === '') {
      return;
    }

    editor.dispatchCommand(TOGGLE_LINK_COMMAND, editedLinkUrl);
    editor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        const parent = getSelectedNode(selection).getParent();
        if ($isAutoLinkNode(parent)) {
          const linkNode = $createLinkNode(parent.getURL(), {
            rel: parent.__rel,
            target: parent.__target,
            title: parent.__title,
          });
          parent.replace(linkNode, true);
        }
      }
    });
  }

  function preventDefault(event: SyntheticEvent) {
    event.preventDefault();
  }

  const backgroundColor = useColorModeValue('white', 'gray.700');
  const borderColor = useColorModeValue('gray.100', 'gray.600');

  if (!isLink) {
    return null;
  }

  return (
    <HStack
      ref={editorRef}
      position="absolute"
      top="0"
      left="0"
      w="fit-content"
      maxW="400px"
      p={2}
      background={backgroundColor}
      border="1px"
      borderColor={borderColor}
      boxShadow="lg"
      borderRadius="md"
      zIndex="popover"
      opacity="0"
    >
      {isLinkEditMode ? (
        <>
          <Input
            ref={inputRef}
            flexGrow="1"
            className="link-input"
            value={editedLinkUrl}
            onChange={(event) => setEditedLinkUrl(event.target.value)}
            onKeyDown={(event) => monitorInputInteraction(event)}
          />
          <HStack>
            <IconButton
              aria-label="Confirm"
              size="sm"
              icon={<Icon as={CheckIcon} />}
              onClick={handleLinkSubmission}
              onMouseDown={preventDefault}
            />
            <IconButton
              aria-label="Cancel"
              size="sm"
              icon={<Icon as={XMarkIcon} />}
              onClick={() => setIsLinkEditMode(false)}
              onMouseDown={preventDefault}
            />
          </HStack>
        </>
      ) : (
        <>
          <Link
            flexGrow="1"
            overflow="hidden"
            href={linkUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            {linkUrl}
          </Link>
          <HStack>
            <IconButton
              aria-label="Edit"
              size="sm"
              icon={<Icon as={PencilSquareIcon} />}
              onClick={() => {
                setEditedLinkUrl(linkUrl);
                setIsLinkEditMode(true);
              }}
              onMouseDown={preventDefault}
            />
            <IconButton
              aria-label="Unlink"
              size="sm"
              icon={<Icon as={TrashIcon} />}
              onClick={() => void editor.dispatchCommand(TOGGLE_LINK_COMMAND, null)}
              onMouseDown={preventDefault}
            />
          </HStack>
        </>
      )}
    </HStack>
  );
}

function setFloatingElemPositionForLinkEditor(
  targetRect: DOMRect | null,
  floatingElem: HTMLElement,
  anchorElem: HTMLElement,
  verticalGap = 20,
  horizontalOffset = 5,
): void {
  const scrollerElem = anchorElem.parentElement;

  if (targetRect === null || !scrollerElem) {
    floatingElem.style.opacity = '0';
    floatingElem.style.transform = 'translate(-10000px, -10000px)';
    return;
  }

  const floatingElemRect = floatingElem.getBoundingClientRect();
  const anchorElementRect = anchorElem.getBoundingClientRect();
  const editorScrollerRect = scrollerElem.getBoundingClientRect();

  let top = targetRect.top - verticalGap;
  let left = targetRect.left - horizontalOffset;

  if (top < editorScrollerRect.top) {
    top += floatingElemRect.height + targetRect.height + verticalGap * 2;
  }

  if (left + floatingElemRect.width > editorScrollerRect.right) {
    left = editorScrollerRect.right - floatingElemRect.width - horizontalOffset;
  }

  top -= anchorElementRect.top;
  left -= anchorElementRect.left;

  floatingElem.style.opacity = '1';
  floatingElem.style.transform = `translate(${left}px, ${top}px)`;
}

function getSelectedNode(selection: RangeSelection | TableSelection) {
  const anchor = selection.anchor;
  const focus = selection.focus;
  const anchorNode = selection.anchor.getNode();
  const focusNode = selection.focus.getNode();
  if (anchorNode === focusNode) {
    return anchorNode;
  }
  const isBackward = selection.isBackward();
  if (isBackward) {
    return $isAtNodeEnd(focus) ? anchorNode : focusNode;
  } else {
    return $isAtNodeEnd(anchor) ? focusNode : anchorNode;
  }
}

import { Field_Entities_Enum } from '@main/graphql/types.generated';
import { Route } from '@tanstack/react-router';

import { FieldConfigsCard } from '../../../../features/custom-fields/field-configs-card';
import { organizationPoliciesSettingsLayoutRoute } from './layout';

export const organizationPoliciesCustomFieldsSettingsRoute = new Route({
  getParentRoute: () => organizationPoliciesSettingsLayoutRoute,
  path: '/custom-fields',
  component: () => <FieldConfigsCard entityName={Field_Entities_Enum.Policy} />,
});

import {
  Button,
  Card,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ThemingProps,
  useDisclosure,
} from '@chakra-ui/react';
import { Policy_Approval_Statuses_Enum } from '@main/graphql/types.generated';
import { createColumnHelper, Table } from '@main/ui';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { COMPLYANCE_USER } from '../../utils/constants';
import { getCurrentOrgUsersMap } from '../user/slice';
import { POLICY_VERSION_STATUSES } from './constants';
import { useGetPolicyQuery } from './manage-policies.generated';
import { getApprovalRatio, getMappedPolicy } from './slice';

type PolicyApproval = ReturnType<typeof getMappedPolicy>['approvals'][number];

export const PolicyApprovalsTab = ({ policyId }: { policyId: string }) => {
  const { t } = useTranslation();
  const { isLoading } = useGetPolicyQuery({
    id: policyId,
  });
  const [selectedApprovalId, setSelectedApprovalId] = useState<string | undefined>(undefined);
  const { isOpen: isDialogOpen, onOpen: openDialog, onClose: closeDialog } = useDisclosure();

  const columns = useApprovalsTableColumns();
  const tableItemName = useMemo(() => {
    return {
      singular: t('entities.approval').toLowerCase(),
      plural: t('entities.plural.approvals').toLowerCase(),
    };
  }, [t]);
  const policy = useAppSelector((state) => getMappedPolicy(state, policyId));
  const openApproval = useCallback(
    (row: { original: PolicyApproval }) => {
      setSelectedApprovalId(row.original.id);
      openDialog();
    },
    [openDialog],
  );

  return (
    <Flex direction="column" justify="center" gap="8">
      <Card variant={'table-styles'}>
        <Table
          minW="400px"
          data={policy.approvals}
          isLoading={isLoading}
          columns={columns}
          itemName={tableItemName}
          onRowClick={openApproval}
        />
      </Card>
      <Modal isOpen={isDialogOpen} onClose={closeDialog} size="4xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('policies.approval.details.approvers')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {selectedApprovalId && (
              <ApprovalUsers policyId={policyId} approvalId={selectedApprovalId} />
            )}
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="gray" onClick={closeDialog}>
              {t('buttons.close')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

function useApprovalsTableColumns() {
  const { t } = useTranslation();
  const currentOrgUsers = useAppSelector(getCurrentOrgUsersMap);

  return useMemo(() => {
    const columnHelper = createColumnHelper<PolicyApproval>();
    return [
      columnHelper.columns.tag({
        id: 'versionId',
        header: t('policies.approval.tableColumns.version'),
        accessorFn: ({ version }) => {
          return {
            value: version.formattedVersionId,
            colorScheme: 'purple',
          };
        },
        size: 80,
      }),

      columnHelper.columns.status({
        id: 'status',
        header: t('policies.approval.tableColumns.status'),
        accessorFn: ({ version }) => {
          const statusMeta = POLICY_VERSION_STATUSES[version.status];
          return {
            value: t(`policies.versions.status.${statusMeta.value}`),
            colorScheme: statusMeta.colorScheme,
          };
        },
        size: 160,
      }),

      columnHelper.columns.date({
        id: 'dueDate',
        header: t('policies.approval.tableColumns.dueDate'),
        accessorFn: ({ due_date }) => due_date,
        size: 200,
      }),

      columnHelper.columns.text({
        id: 'approversCount',
        header: t('policies.approval.tableColumns.approvals'),
        accessorFn: ({ policy_approval_users }) => getApprovalRatio(policy_approval_users),
      }),

      columnHelper.columns.avatar({
        id: 'createdBy',
        header: t('policies.approval.tableColumns.createdBy'),
        accessorFn: ({ created_by }) => {
          if (!created_by) {
            return COMPLYANCE_USER;
          }

          const user = currentOrgUsers[created_by];
          if (!user) {
            return;
          }

          return {
            id: user.id,
            displayName: user.displayName,
          };
        },
        size: 100,
      }),
    ];
  }, [currentOrgUsers, t]);
}

const ApprovalUsers = ({ policyId, approvalId }: { policyId: string; approvalId: string }) => {
  const { t } = useTranslation();
  const policy = useAppSelector((state) => getMappedPolicy(state, policyId));
  const currentOrgUsers = useAppSelector(getCurrentOrgUsersMap);

  const approval = useMemo(
    () => policy.approvals.find((approval) => approval.id === approvalId),
    [approvalId, policy.approvals],
  );

  const columns = useMemo(() => {
    const columnHelper =
      createColumnHelper<NonNullable<typeof approval>['policy_approval_users'][number]>();
    return [
      columnHelper.columns.status({
        id: 'status',
        header: t('policies.approval.details.tableColumns.status'),
        accessorFn: ({
          approved_at,
        }): { value: string; colorScheme: ThemingProps['colorScheme'] } => {
          if (approval?.status === Policy_Approval_Statuses_Enum.Retired) {
            return {
              value: t('policies.approval.details.status.retired'),
              colorScheme: 'red',
            };
          }
          if (approved_at) {
            return {
              value: t('policies.approval.details.status.approved'),
              colorScheme: 'green',
            };
          }
          return {
            value: t('policies.approval.details.status.awaiting'),
            colorScheme: 'orange',
          };
        },
        size: 200,
      }),

      columnHelper.columns.avatar({
        id: 'user',
        header: t('policies.approval.details.tableColumns.user'),
        accessorFn: ({ user_id }) => {
          const user = currentOrgUsers[user_id];
          if (!user) {
            return;
          }

          return {
            id: user.id,
            displayName: user.displayName,
          };
        },
        size: 400,
        variant: 'detailed',
      }),

      columnHelper.columns.date({
        id: 'approvedAt',
        header: t('policies.approval.details.tableColumns.approvedAt'),
        accessorFn: ({ approved_at }) => approved_at,
      }),
    ];
  }, [approval?.status, currentOrgUsers, t]);

  const tableItemName = useMemo(() => {
    return {
      singular: t('entities.approval').toLowerCase(),
      plural: t('entities.plural.approvals').toLowerCase(),
    };
  }, [t]);

  return (
    <Card variant={'table-styles'}>
      <Table
        minW="400px"
        data={approval?.policy_approval_users || []}
        columns={columns}
        itemName={tableItemName}
      />
    </Card>
  );
};

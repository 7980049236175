import { CheckCircleIcon, ClockIcon, PlayCircleIcon, TrashIcon } from '@heroicons/react/24/outline';
import { TaskStatus } from '@main/graphql/client-scalars';
import { isNonNullable } from '@main/shared/utils';
import { DrawerActionsProps, MenuAction, PrimaryAction, SecondaryAction } from '@main/ui';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { getCurrentUserSelectedOrgRole } from '../user/slice';
import { useDeleteTask } from './delete-task';
import {
  useToggleTaskCompletionMutation,
  useUpdateTaskByIdMutation,
} from './manage-tasks.generated';
import { getMappedTask } from './slice';
import { useUpdateTaskHandler } from './use-update-task-handler';

type TaskActionsInput = {
  taskId: string;
  taskStatus?: TaskStatus;
};

export const useTaskDrawerActions = (taskId: string): DrawerActionsProps => {
  const task = useAppSelector((state) => getMappedTask(state, taskId));
  const markAsComplete = useMarkAsCompleteAction(taskId);
  const markAsPending = useMarkAsPendingAction(taskId);
  const markAsInProgressOrPending = useLazyInProgressOrPendingToggleMenuAction()({
    taskId,
    taskStatus: task?.status,
  });
  const deleteTask = useDeleteTaskAction(taskId);

  return {
    primaryAction: markAsComplete,
    secondaryActions: [markAsPending].filter(isNonNullable),
    menuActions: [markAsInProgressOrPending, deleteTask].filter(isNonNullable),
  };
};

function useMarkAsCompleteAction(taskId: string): PrimaryAction | undefined {
  const task = useAppSelector((state) => getMappedTask(state, taskId));
  const action = useLazyPendingOrCompleteToggleAction()({ taskId, taskStatus: task?.status });

  if (task?.status === TaskStatus.COMPLETED) {
    return;
  }

  return action;
}

function useMarkAsPendingAction(taskId: string): SecondaryAction | undefined {
  const task = useAppSelector((state) => getMappedTask(state, taskId));
  const action = useLazyPendingOrCompleteToggleAction()({ taskId, taskStatus: task?.status });

  if (task?.status !== TaskStatus.COMPLETED || !action) {
    return;
  }

  return {
    variant: 'outline',
    ...action,
  };
}

export function useLazyPendingOrCompleteToggleAction() {
  const { t } = useTranslation();
  const { permissionMap } = useAppSelector(getCurrentUserSelectedOrgRole);
  const [toggleTaskCompletion, { isLoading }] = useToggleTaskCompletionMutation();
  const updateTaskHandler = useUpdateTaskHandler();
  const canEditTask = permissionMap.write_tasks;

  return useCallback(
    ({ taskId, taskStatus }: TaskActionsInput) => {
      if (!canEditTask) {
        return undefined;
      }

      if (taskStatus === TaskStatus.COMPLETED) {
        return {
          isLoading,
          icon: <ClockIcon />,
          label: t('tasks.markAsPending'),
          onClick: () => {
            return updateTaskHandler(
              toggleTaskCompletion({
                status: TaskStatus.PENDING,
                taskId,
                completed_at: null,
              }),
            );
          },
        };
      }

      return {
        isLoading,
        icon: <CheckCircleIcon />,
        label: t('tasks.markAsComplete'),
        onClick: () => {
          return updateTaskHandler(
            toggleTaskCompletion({
              status: TaskStatus.COMPLETED,
              taskId,
              completed_at: 'now()',
            }),
          );
        },
      };
    },
    [canEditTask, isLoading, t, updateTaskHandler, toggleTaskCompletion],
  );
}

export function useLazyInProgressOrPendingToggleMenuAction() {
  const { t } = useTranslation();
  const { permissionMap } = useAppSelector(getCurrentUserSelectedOrgRole);
  const updateTaskHandler = useUpdateTaskHandler();
  const [updateTask, { isLoading }] = useUpdateTaskByIdMutation();
  const canEditTask = permissionMap.write_tasks;

  return useCallback(
    ({ taskId, taskStatus }: TaskActionsInput) => {
      if (
        !canEditTask ||
        (taskStatus !== TaskStatus.PENDING && taskStatus !== TaskStatus.IN_PROGRESS)
      ) {
        return undefined;
      }

      if (taskStatus === TaskStatus.PENDING) {
        return {
          icon: <PlayCircleIcon />,
          isLoading,
          label: t('tasks.markAsInProgress'),
          onClick: () => {
            return updateTaskHandler(
              updateTask({
                taskId,
                updatePayload: { status: TaskStatus.IN_PROGRESS },
              }),
            );
          },
        };
      }

      return {
        icon: <ClockIcon />,
        isLoading,
        label: t('tasks.markAsPending'),
        onClick: () => {
          return updateTaskHandler(
            updateTask({
              taskId,
              updatePayload: { status: TaskStatus.PENDING },
            }),
          );
        },
      };
    },
    [canEditTask, isLoading, t, updateTaskHandler, updateTask],
  );
}

function useDeleteTaskAction(taskId: string): MenuAction | undefined {
  const { t } = useTranslation();
  const deleteTask = useDeleteTask();

  if (!deleteTask) {
    return;
  }

  return {
    icon: <TrashIcon />,
    label: t('buttons.delete'),
    onClick: () => {
      deleteTask(taskId);
    },
  };
}

import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack,
  Textarea,
  useDisclosure,
} from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { TrashIcon } from '@heroicons/react/24/outline';
import { ArrowUpTrayIcon } from '@heroicons/react/24/solid';
import { zodResolver } from '@hookform/resolvers/zod';
import { Policy_Version_Statuses_Enum } from '@main/graphql/types.generated';
import { isNonNullable, toError } from '@main/shared/utils';
import {
  DrawerActionsProps,
  EditableMultiSelectAvatar,
  errorToast,
  MenuAction,
  PrimaryAction,
  SecondaryAction,
  successToast,
  useAlertDialog,
  useDrawer,
} from '@main/ui';
import dayjs from 'dayjs';
import { useCallback } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { getAssigneeOptions } from '../shared/get-assignee-options';
import {
  getCurrentOrgNonDisabledUsers,
  getCurrentUserId,
  getCurrentUserSelectedOrgRole,
} from '../user/slice';
import { ExportPolicyModal } from './export-policy-modal';
import {
  useAcknowledgePolicyAcknowledgementsMutation,
  useCreatePolicyApprovalMutation,
  useCreatePolicyVersionMutation,
  useDeletePolicyMutation,
  useUpdateApprovalUsersMutation,
} from './manage-policies.generated';
import {
  getCanCurrentUserAcknowledge,
  getCanCurrentUserApprove,
  getCanCurrentUserExportPolicy,
  getPolicyApprovers,
} from './slice';
import { usePreferredPolicyVersion } from './use-preferred-policy-version';

const formId = 'approval-form';

export const usePolicyDrawerActions = (policyId: string): DrawerActionsProps => {
  const deletePolicy = useDeletePolicyAction();
  const sendApprovalAction = useSendApprovalAction(policyId);
  const approveAction = useApproveAction(policyId);
  const createNewVersion = useCreateVersionAction(policyId);
  const exportPolicy = useExportPolicyAction(policyId);
  const acknowledgeAction = useAcknowledgeAction(policyId);

  return {
    primaryAction: sendApprovalAction ?? approveAction ?? acknowledgeAction,
    secondaryActions: [createNewVersion].filter(isNonNullable),
    menuActions: [exportPolicy, deletePolicy({ id: policyId })].filter(isNonNullable),
  };
};

function useAcknowledgeAction(policyId: string): PrimaryAction | undefined {
  const { t } = useTranslation();
  const { openDialog } = useAlertDialog();
  const currentUserId = useAppSelector(getCurrentUserId);
  const [acknowledgePolicy] = useAcknowledgePolicyAcknowledgementsMutation();
  const currentVersion = usePreferredPolicyVersion();
  const { canUserAcknowledge, acknowledgementIds } = useAppSelector((state) =>
    getCanCurrentUserAcknowledge(state, policyId, currentVersion?.id),
  );

  if (!canUserAcknowledge) {
    return;
  }

  const acknowledgeHandler = async () => {
    try {
      await acknowledgePolicy({
        userId: currentUserId,
        acknowledgementIds,
        acknowledged_at: new Date().toISOString(),
      });
      successToast(t('policies.acknowledgement.successfullyAcknowledged'));
    } catch (error) {
      errorToast(t('policies.acknowledgement.failedToAcknowledge'));
      datadogLogs.logger.error(
        'Failed to acknowledge policy acknowledgement',
        { currentUserId, acknowledgementIds },
        toError(error),
      );
    }
  };

  return {
    label: t('policies.acknowledgement.acknowledge'),
    onClick: () => {
      openDialog({
        dialogHeader: t('policies.acknowledgement.alert.acknowledge.header'),
        dialogContent: t('policies.acknowledgement.alert.acknowledge.content'),
        confirmAction: {
          colorScheme: 'blue',
          children: t('policies.acknowledgement.acknowledge'),
          onClick: acknowledgeHandler,
        },
      });
    },
  };
}

function useApproveAction(policyId: string): PrimaryAction | undefined {
  const { t } = useTranslation();
  const { openDialog } = useAlertDialog();
  const [approvePolicy] = useUpdateApprovalUsersMutation();
  const currentVersion = usePreferredPolicyVersion();
  const { canUserApprove, currentApprover } = useAppSelector((state) =>
    getCanCurrentUserApprove(state, policyId, currentVersion?.id),
  );

  if (!canUserApprove) {
    return;
  }

  const approveHandler = async () => {
    try {
      await approvePolicy({
        /* At this point we will have approver */
        id: currentApprover?.id as string,
        updatePayload: {
          approved_at: new Date().toISOString(),
        },
      });
      successToast(t('policies.approval.successfullyApproved'));
    } catch (error) {
      errorToast(t('policies.approval.failedToApprove'));
      datadogLogs.logger.error(
        'Failed to approve policy approval',
        { approvalId: currentApprover?.policy_approval_id },
        toError(error),
      );
    }
  };

  return {
    label: t('policies.approval.approve'),
    onClick: () => {
      openDialog({
        dialogHeader: t('policies.approval.alert.approve.header'),
        dialogContent: t('policies.approval.alert.approve.content'),
        confirmAction: {
          colorScheme: 'blue',
          children: t('policies.approval.approve'),
          onClick: approveHandler,
        },
      });
    },
  };
}

function useSendApprovalAction(policyId: string): PrimaryAction | undefined {
  const { t } = useTranslation();
  const { openDialog } = useAlertDialog();
  const { permissionMap } = useAppSelector(getCurrentUserSelectedOrgRole);
  const currentVersion = usePreferredPolicyVersion();
  const canEditPolicy = permissionMap.write_policies;

  if (
    !canEditPolicy ||
    !currentVersion ||
    currentVersion.status !== Policy_Version_Statuses_Enum.Draft
  ) {
    return;
  }

  return {
    label: t('policies.approval.sendForApproval'),
    isDisabled: !currentVersion.file && !currentVersion.policy_text,
    onClick: () => {
      openDialog({
        dialogHeader: t('policies.approval.form.header'),
        dialogContent: <PolicyApprovalForm policyId={policyId} versionId={currentVersion.id} />,
        confirmAction: {
          type: 'submit',
          form: formId,
          colorScheme: 'blue',
          children: t('buttons.send'),
        },
      });
    },
  };
}

const approvalSchema = z.object({
  approvers: z
    .array(
      z.object({
        id: z.string(),
        displayName: z.string(),
      }),
    )
    .min(1, { message: 'At least one approver should be selected' }),
  dueDate: z.coerce.date().transform((date) => date.toISOString()),
  messageTitle: z.string().min(1, { message: 'Title is required' }),
  messageDetails: z.string().trim().optional(),
});

type ApprovalSchema = z.infer<typeof approvalSchema>;

function PolicyApprovalForm({ policyId, versionId }: { policyId: string; versionId: string }) {
  const { t } = useTranslation();
  const { closeDialog } = useAlertDialog();
  const currentOrgNonDisabledUsers = useAppSelector(getCurrentOrgNonDisabledUsers);
  const approvers = useAppSelector((state) => getPolicyApprovers(state, policyId));
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ApprovalSchema>({
    shouldFocusError: false,
    resolver: zodResolver(approvalSchema),
    defaultValues: {
      approvers,
      messageTitle: t('policies.approval.defaultMessageTitle'),
      dueDate: dayjs().add(7, 'days').format('YYYY-MM-DD'),
    },
  });

  const [sendPolicyApproval] = useCreatePolicyApprovalMutation();

  const handler: SubmitHandler<ApprovalSchema> = async (data) => {
    try {
      await sendPolicyApproval({
        input: {
          policy_version_id: versionId,
          subject: data.messageTitle,
          message: data.messageDetails,
          due_date: data.dueDate,
          policy_approval_users: {
            data: data.approvers.map((approver) => ({ user_id: approver.id })),
          },
        },
      });
      closeDialog();
      successToast(t('policies.approval.successfullySent'));
    } catch (error) {
      errorToast(t('policies.approval.failedToSend'));
      datadogLogs.logger.error('Failed to send policy approval', { policyId }, toError(error));
    }
  };

  return (
    <form id={formId} onSubmit={handleSubmit(handler)}>
      <Stack spacing={6}>
        <FormControl isInvalid={!!errors.approvers}>
          <FormLabel>{t('policies.approval.form.props.approvers')}:</FormLabel>
          <Controller
            name="approvers"
            control={control}
            render={({ field }) => (
              <EditableMultiSelectAvatar
                options={getAssigneeOptions(currentOrgNonDisabledUsers)}
                value={field.value}
                placeholder={t('policies.placeholder.approvers')}
                onChange={(values) => {
                  field.onChange(values);
                }}
              />
            )}
          />

          <FormErrorMessage>{errors.approvers?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.dueDate}>
          <FormLabel>{t('policies.approval.form.props.dueDate')}:</FormLabel>
          <Input
            type="date"
            placeholder={t('policies.approval.form.placeholders.dueDate')}
            {...register('dueDate')}
          />
          <FormErrorMessage>{errors.dueDate?.message}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors.messageTitle}>
          <FormLabel>{t('policies.approval.form.props.messageTitle')}:</FormLabel>
          <Input
            placeholder={t('policies.approval.form.placeholders.messageTitle')}
            {...register('messageTitle')}
          />
          <FormErrorMessage>{errors.messageTitle?.message}</FormErrorMessage>
        </FormControl>
        <FormControl>
          <FormLabel>{t('policies.approval.form.props.messageDetails')}:</FormLabel>
          <Textarea
            placeholder={t('policies.approval.form.placeholders.messageDetails')}
            {...register('messageDetails')}
          />
        </FormControl>
      </Stack>
    </form>
  );
}

export function useCreateVersionAction(policyId: string): SecondaryAction | undefined {
  const { t } = useTranslation();
  const drawer = useDrawer();
  const [createVersion, { isLoading }] = useCreatePolicyVersionMutation();

  const currentVersion = usePreferredPolicyVersion();
  const { permissionMap } = useAppSelector(getCurrentUserSelectedOrgRole);
  const canEditPolicy = permissionMap.write_policies;

  if (!canEditPolicy) {
    return;
  }

  const createVersionHandler = async () => {
    try {
      const response = await createVersion({
        input: {
          policy_id: policyId,
          policy_text: currentVersion?.policy_text,
        },
      }).unwrap();

      if (!response.insert_policy_versions_one?.id) {
        throw new Error('Could not get new policy version id from response');
      }

      drawer.open({
        entity: 'policy-version',
        entityId: `${policyId}:${response.insert_policy_versions_one.id}`,
      });
      successToast(t('policies.versions.successfullyCreatedVersion'));
    } catch (error) {
      errorToast(t('policies.versions.failedToCreateVersion'));
      datadogLogs.logger.error('Failed to create new policy version', { policyId }, toError(error));
    }
  };

  return {
    isLoading,
    variant: 'outline',
    label: t('policies.versions.create'),
    onClick: createVersionHandler,
  };
}

export function useExportPolicyAction(policyId: string): MenuAction | null {
  const { t } = useTranslation();
  const modal = useDisclosure();
  const canExportPolicy = useAppSelector((state) => getCanCurrentUserExportPolicy(state, policyId));
  if (!canExportPolicy) {
    return null;
  }

  return {
    icon: <ArrowUpTrayIcon />,
    label: t('buttons.export'),
    onClick: modal.onOpen,
    children: (
      <ExportPolicyModal policyId={policyId} isOpen={modal.isOpen} onClose={modal.onClose} />
    ),
  };
}

export function useDeletePolicyAction() {
  const { t } = useTranslation();
  const drawer = useDrawer();
  const { openDialog } = useAlertDialog();
  const [deletePolicy] = useDeletePolicyMutation();

  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canDeletePolicy = userRole.permissionMap?.write_policies;

  return useCallback(
    ({ id }: { id: string }) => {
      if (!canDeletePolicy) {
        return null;
      }

      return {
        icon: <TrashIcon />,
        label: t('buttons.delete'),
        onClick: () =>
          openDialog({
            dialogHeader: t('policies.alert.delete.header'),
            dialogContent: t('policies.alert.delete.content'),
            confirmAction: {
              children: t('policies.alert.delete.confirm'),
              onClick: async () => {
                try {
                  await deletePolicy({ id });
                  successToast(
                    t('successMessages.deleteSucceeded', { entity: t('entities.policy') }),
                  );
                  drawer.close();
                } catch (error) {
                  errorToast(t('errorMessages.deleteFailed', { entity: t('entities.policy') }));
                  datadogLogs.logger.error(
                    'Policy delete failed',
                    { policyId: id },
                    toError(error),
                  );
                }
              },
            },
          }),
      };
    },
    [canDeletePolicy, deletePolicy, drawer, openDialog, t],
  );
}

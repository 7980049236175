/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type RiskLevelsGetRiskCategoriesQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['uuid']['input'];
}>;

export type RiskLevelsGetRiskCategoriesQuery = {
  __typename?: 'query_root';
  organization_risk_categories: Array<{
    __typename?: 'organization_risk_categories';
    id: string;
    name: string;
  }>;
};

export const RiskLevelsGetRiskCategoriesDocument = `
    query RiskLevelsGetRiskCategories($organizationId: uuid!) {
  organization_risk_categories(where: {organization_id: {_eq: $organizationId}}) {
    id
    name
  }
}
    ` as string &
  TypedDocumentNode<RiskLevelsGetRiskCategoriesQuery, RiskLevelsGetRiskCategoriesQueryVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    RiskLevelsGetRiskCategories: build.query<
      RiskLevelsGetRiskCategoriesQuery,
      RiskLevelsGetRiskCategoriesQueryVariables
    >({
      query: (variables) => ({ document: RiskLevelsGetRiskCategoriesDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useRiskLevelsGetRiskCategoriesQuery, useLazyRiskLevelsGetRiskCategoriesQuery } =
  injectedRtkApi;

import { Route } from '@tanstack/react-router';

import { EvidenceCenter } from '../../features/evidence/evidence-center';
import { authLayout } from '../login';

export const evidencesRoute = new Route({
  getParentRoute: () => authLayout,
  path: 'evidence',
  component: EvidenceCenter,
});

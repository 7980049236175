import { Outlet, Route } from '@tanstack/react-router';

import { organizationSettingsLayoutRoute } from '../layout';

const ControlsLayout = () => {
  return <Outlet />;
};

export const organizationControlsSettingsLayoutRoute = new Route({
  getParentRoute: () => organizationSettingsLayoutRoute,
  path: 'controls',
  component: ControlsLayout,
});

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type ToggleControlIgnoreFindingMutationVariables = Types.Exact<{
  findingId: Types.Scalars['uuid']['input'];
  ignored_at?: Types.InputMaybe<Types.Scalars['timestamptz']['input']>;
}>;

export type ToggleControlIgnoreFindingMutation = {
  __typename?: 'mutation_root';
  update_control_findings_by_pk?: { __typename?: 'control_findings'; id: string };
};

export type ToggleVqIgnoreFindingMutationVariables = Types.Exact<{
  questionnaireAiFindingInput: Types.Vendor_Questionnaire_Ai_Review_Findings_Insert_Input;
}>;

export type ToggleVqIgnoreFindingMutation = {
  __typename?: 'mutation_root';
  insert_vendor_questionnaire_ai_review_findings_one?: {
    __typename?: 'vendor_questionnaire_ai_review_findings';
    id: string;
  };
};

export const ToggleControlIgnoreFindingDocument = `
    mutation ToggleControlIgnoreFinding($findingId: uuid!, $ignored_at: timestamptz) {
  update_control_findings_by_pk(
    pk_columns: {id: $findingId}
    _set: {ignored_at: $ignored_at}
  ) {
    id
  }
}
    ` as string &
  TypedDocumentNode<
    ToggleControlIgnoreFindingMutation,
    ToggleControlIgnoreFindingMutationVariables
  >;
export const ToggleVqIgnoreFindingDocument = `
    mutation ToggleVqIgnoreFinding($questionnaireAiFindingInput: vendor_questionnaire_ai_review_findings_insert_input!) {
  insert_vendor_questionnaire_ai_review_findings_one(
    object: $questionnaireAiFindingInput
    on_conflict: {constraint: vendor_questionnaire_ai_revie_vendor_questionnaire_id_quest_key, update_columns: [ignored_at]}
  ) {
    id
  }
}
    ` as string &
  TypedDocumentNode<ToggleVqIgnoreFindingMutation, ToggleVqIgnoreFindingMutationVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    ToggleControlIgnoreFinding: build.mutation<
      ToggleControlIgnoreFindingMutation,
      ToggleControlIgnoreFindingMutationVariables
    >({
      query: (variables) => ({ document: ToggleControlIgnoreFindingDocument, variables }),
    }),
    ToggleVqIgnoreFinding: build.mutation<
      ToggleVqIgnoreFindingMutation,
      ToggleVqIgnoreFindingMutationVariables
    >({
      query: (variables) => ({ document: ToggleVqIgnoreFindingDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useToggleControlIgnoreFindingMutation, useToggleVqIgnoreFindingMutation } =
  injectedRtkApi;

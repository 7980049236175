import { Box, HStack } from '@chakra-ui/react';

import { getModifierKey, ShortcutModifier } from './shortcut-hint';

export interface ShortcutProps {
  modifier: ShortcutModifier;
  keyName: string;
  size?: 'sm' | 'md';
  isDark?: boolean;
}

export function Shortcut(props: ShortcutProps) {
  const color = props.isDark ? 'white' : 'gray.400';
  const bgColor = props.isDark ? 'gray.600' : 'gray.100';
  const spacing = props.size === 'sm' ? '2px' : '4px';
  const fontSize = props.size === 'sm' ? '10px' : 'lg';
  const lineHeight = props.size === 'sm' ? '15px' : '32px';
  const padding = props.size === 'sm' ? '3px' : '10px';
  const minWidth = props.size === 'sm' ? '15px' : '32px';
  const borderRadius = props.size === 'sm' ? '2px' : '4px';

  return (
    <HStack
      spacing={spacing}
      fontSize={fontSize}
      lineHeight={lineHeight}
      color={color}
      textAlign="center"
    >
      <Box px={padding} minW={minWidth} borderRadius={borderRadius} bgColor={bgColor}>
        {getModifierKey(props.modifier)}
      </Box>
      <Box px={padding} minW={minWidth} borderRadius={borderRadius} bgColor={bgColor}>
        {props.keyName}
      </Box>
    </HStack>
  );
}

import { HStack, Tag } from '@chakra-ui/react';
import {
  ArchiveBoxIcon,
  ChatBubbleLeftRightIcon,
  ClipboardDocumentCheckIcon,
  ClockIcon,
  DocumentCheckIcon,
  DocumentMagnifyingGlassIcon,
  ListBulletIcon,
} from '@heroicons/react/24/outline';
import { PolicyTabKeys } from '@main/shared/utils';
import { Drawer, EditableAutoResizeTextarea, getVisibleTabs, StatusTag } from '@main/ui';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { CommentsTab } from '../comments/comments-tab';
import { getCurrentUserSelectedOrgRole } from '../user/slice';
import { AcknowledgementsTab } from './acknowledgements-tab';
import { PolicyApprovalsTab } from './approvals-tab';
import { POLICY_VERSION_STATUSES } from './constants';
import { PolicyDetailsTab } from './details-tab';
import { PolicyHistoryTab } from './history-tab';
import { useGetPolicyQuery, useUpdatePolicyMutation } from './manage-policies.generated';
import { useGetPolicySubscription } from './policy-subscription';
import { PolicyTasksTab } from './tasks-tab';
import { usePolicyDrawerActions } from './use-drawer-actions';
import { usePreferredPolicyVersion } from './use-preferred-policy-version';
import { useUpdatePolicyHandler } from './use-update-policy-handler';
import { PolicyVersionsTab } from './versions-tab';

type DrawerProps = {
  policyId: string;
};

export const PolicyDrawer: FC<DrawerProps> = ({ policyId }) => {
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canViewPolicy = userRole.permissionMap?.read_policies;
  const { data, isLoading } = useGetPolicySubscription({
    id: policyId,
  });

  const { tabs } = usePolicyDrawerTabs(policyId);
  const actions = usePolicyDrawerActions(policyId);

  return (
    <Drawer.Layout isLoading={isLoading} canView={canViewPolicy} isNotFound={!data?.policies_by_pk}>
      <Drawer.Toolbar {...actions} />
      <Drawer.Tabs tabs={tabs}>
        <PolicyDrawerHeader policyId={policyId} />
      </Drawer.Tabs>
    </Drawer.Layout>
  );
};

const PolicyDrawerHeader = ({ policyId }: { policyId: string }) => {
  const { t } = useTranslation();
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canEditPolicy = userRole.permissionMap?.write_policies;
  const preferredVersionData = usePreferredPolicyVersion();

  const updatePolicyHandler = useUpdatePolicyHandler();
  const [updatePolicy] = useUpdatePolicyMutation();

  const { data } = useGetPolicyQuery({ id: policyId });
  const policy = data?.policies_by_pk;

  if (!policy || !preferredVersionData) {
    return null;
  }

  return (
    <>
      <HStack mb={4}>
        <Tag colorScheme="purple">
          {t('entities.policy')} / {policy.internal_id}
        </Tag>
        <Tag data-testid="policy-version-number" colorScheme="gray">
          {preferredVersionData.formattedVersionId}
        </Tag>
        <StatusTag
          data-testid="policy-version-status"
          size="sm"
          minW="auto"
          maxW="none"
          colorScheme={POLICY_VERSION_STATUSES[preferredVersionData.status].colorScheme}
        >
          {t(`policies.versions.status.${preferredVersionData.status}`)}
        </StatusTag>
      </HStack>

      <EditableAutoResizeTextarea
        isDisabled={!canEditPolicy}
        ml={-2}
        fontSize={'xl'}
        fontWeight={'bold'}
        defaultValue={policy.name}
        placeholder={t('policies.placeholder.name')}
        onSubmit={(value) => {
          updatePolicyHandler(
            updatePolicy({
              id: policyId,
              updatePayload: { name: value },
            }),
          );
        }}
      />
    </>
  );
};

const usePolicyDrawerTabs = (policyId: string) => {
  const { t } = useTranslation();

  const { comments, refetch } = useGetPolicyQuery(
    { id: policyId },
    {
      selectFromResult: ({ data }) => {
        return { comments: data?.policies_by_pk?.policies_comments ?? [] };
      },
    },
  );

  const refetchComments = useCallback(async () => {
    await refetch();
  }, [refetch]);

  const tabs = useMemo(() => {
    return getVisibleTabs<PolicyTabKeys>({
      details: {
        label: t('policies.tabs.details'),
        icon: ListBulletIcon,
        panel: <PolicyDetailsTab policyId={policyId} />,
        hideTabTitle: true,
      },
      approvals: {
        label: t('policies.tabs.approvals'),
        icon: DocumentCheckIcon,
        panel: <PolicyApprovalsTab policyId={policyId} />,
      },
      acknowledgement: {
        label: t('policies.tabs.acknowledgements'),
        icon: DocumentMagnifyingGlassIcon,
        panel: <AcknowledgementsTab policyId={policyId} />,
      },
      versions: {
        label: t('policies.tabs.versions'),
        icon: ArchiveBoxIcon,
        panel: <PolicyVersionsTab policyId={policyId} />,
      },
      comments: {
        label: t('policies.tabs.comments'),
        icon: ChatBubbleLeftRightIcon,
        panel: (
          <CommentsTab
            entity="policy"
            entityId={policyId}
            comments={comments}
            refetchComments={refetchComments}
          />
        ),
      },
      tasks: {
        label: t('policies.tabs.tasks'),
        icon: ClipboardDocumentCheckIcon,
        panel: <PolicyTasksTab policyId={policyId} />,
      },
      history: {
        label: t('policies.tabs.history'),
        icon: ClockIcon,
        panel: <PolicyHistoryTab policyId={policyId} />,
      },
    });
  }, [t, policyId, comments, refetchComments]);

  return { tabs };
};

import { Box, Menu, MenuDivider, MenuItem, MenuList, useDisclosure } from '@chakra-ui/react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  $deleteTableColumn__EXPERIMENTAL,
  $deleteTableRow__EXPERIMENTAL,
  $getTableCellNodeFromLexicalNode,
  $getTableNodeFromLexicalNodeOrThrow,
  $insertTableColumn__EXPERIMENTAL,
  $insertTableRow__EXPERIMENTAL,
  TableCellNode,
} from '@lexical/table';
import { mergeRegister } from '@lexical/utils';
import {
  $getSelection,
  $isRangeSelection,
  CLICK_COMMAND,
  COMMAND_PRIORITY_HIGH,
  COMMAND_PRIORITY_LOW,
  createCommand,
} from 'lexical';
import { MouseEvent, useCallback, useEffect, useState } from 'react';

export const TRIGGER_CONTEXT_MENU_COMMAND = createCommand<{ event: MouseEvent }>(
  'TRIGGER_CONTEXT_MENU',
);

TableContextMenuPlugin.useTriggerContextMenu = () => {
  const [editor] = useLexicalComposerContext();
  return (event: MouseEvent) => editor.dispatchCommand(TRIGGER_CONTEXT_MENU_COMMAND, { event });
};

export function TableContextMenuPlugin() {
  const [editor] = useLexicalComposerContext();
  const [tableCellNode, setTableMenuCellNode] = useState<TableCellNode>();
  const menuDisclosure = useDisclosure();
  const [menuTransform, setMenuTransform] = useState<string>('');

  const updateSelectedCell = useCallback(() => {
    const selection = $getSelection();
    const nativeSelection = window.getSelection();

    if (selection == null) {
      return setTableMenuCellNode(undefined);
    }

    const rootElement = editor.getRootElement();

    if (
      $isRangeSelection(selection) &&
      rootElement !== null &&
      nativeSelection !== null &&
      rootElement.contains(nativeSelection.anchorNode)
    ) {
      const tableCellNodeFromSelection = $getTableCellNodeFromLexicalNode(
        selection.anchor.getNode(),
      );

      if (tableCellNodeFromSelection == null) {
        return setTableMenuCellNode(undefined);
      }

      const tableCellNodeFromSelectionDOM = editor.getElementByKey(
        tableCellNodeFromSelection.getKey(),
      );

      if (tableCellNodeFromSelectionDOM == null) {
        return setTableMenuCellNode(undefined);
      }

      setTableMenuCellNode(tableCellNodeFromSelection);
    } else {
      setTableMenuCellNode(undefined);
    }
  }, [editor]);

  const updateContextMenu = useCallback(
    (event: MouseEvent) => {
      if (!tableCellNode) {
        return menuDisclosure.onClose();
      }

      event.preventDefault();
      setMenuTransform(`translate(${event.pageX}px, ${event.pageY}px)`);
      menuDisclosure.onOpen();
    },
    [menuDisclosure, tableCellNode],
  );

  useEffect(
    () =>
      mergeRegister(
        editor.registerUpdateListener(() => editor.getEditorState().read(updateSelectedCell)),
        editor.registerCommand(
          TRIGGER_CONTEXT_MENU_COMMAND,
          ({ event }) => {
            updateContextMenu(event);
            return true;
          },
          COMMAND_PRIORITY_HIGH,
        ),
        editor.registerCommand(
          CLICK_COMMAND,
          () => {
            menuDisclosure.onClose();
            return false;
          },
          COMMAND_PRIORITY_LOW,
        ),
      ),
    [editor, menuDisclosure, updateContextMenu, updateSelectedCell],
  );

  function insertRow(insertAfter?: boolean) {
    editor.update(() => $insertTableRow__EXPERIMENTAL(insertAfter));
  }

  function insertColumn(insertAfter?: boolean) {
    editor.update(() => $insertTableColumn__EXPERIMENTAL(insertAfter));
  }

  function deleteRow() {
    editor.update(() => $deleteTableRow__EXPERIMENTAL());
  }

  function deleteColumn() {
    editor.update(() => $deleteTableColumn__EXPERIMENTAL());
  }

  function deleteTable() {
    if (!tableCellNode) {
      return;
    }

    editor.update(() => {
      const tableNode = $getTableNodeFromLexicalNodeOrThrow(tableCellNode);
      tableNode.remove();
    });
  }

  if (!menuDisclosure.isOpen) {
    return null;
  }

  return (
    <Menu {...menuDisclosure}>
      <Box position="fixed" top="0" left="0" transform={menuTransform}>
        <MenuList>
          <MenuItem onClick={() => insertRow(false)}>Insert row above</MenuItem>
          <MenuItem onClick={() => insertRow(true)}>Insert row below</MenuItem>
          <MenuDivider />
          <MenuItem onClick={() => insertColumn(false)}>Insert column before</MenuItem>
          <MenuItem onClick={() => insertColumn(true)}>Insert column after</MenuItem>
          <MenuDivider />
          <MenuItem onClick={deleteRow}>Delete row</MenuItem>
          <MenuItem onClick={deleteColumn}>Delete column</MenuItem>
          <MenuItem onClick={deleteTable}>Delete table</MenuItem>
        </MenuList>
      </Box>
    </Menu>
  );
}

import { usePrevious } from '@chakra-ui/react';
import { useGetOrganizationEvidencesQuery } from '@main/graphql/queries/GetOrganizationEvidences.generated';
import { Order_By } from '@main/graphql/types.generated';
import { useServerPagination } from '@main/ui';
import { ColumnDef, ColumnFilter, SortingState, TableMeta } from '@tanstack/react-table';
import useDebounce from 'ahooks/es/useDebounce';
import equal from 'fast-deep-equal';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useLayoutEffect, useRef, useState } from 'react';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { getCurrentUserSelectedOrgId } from '../user/slice';
import { useGetEvidencePaginatedQuery } from './Evidence.generated';
import {
  getMappedEvidencePaginated,
  getMappedOrganizationEvidences,
  OrganizationEvidence,
} from './slice';

type UseOrgEvidenceTableQueryInput = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: ColumnDef<OrganizationEvidence, any>[];
  globalFilter: string;
  columnFilters: ColumnFilter[];
  sorting?: SortingState;
  forceClientSide?: boolean;
};

export function useOrgEvidenceTableQuery(input: UseOrgEvidenceTableQueryInput) {
  const isServerPaginationEnabledFlag = useFeatureFlagEnabled('evidence-sever-pagination');
  const isServerPaginationEnabled = input.forceClientSide ? false : isServerPaginationEnabledFlag;

  const clientPaginatedQuery = useClientPaginatedQuery(input);
  const serverPaginatedQuery = useServerPaginatedQuery(input);

  return isServerPaginationEnabled ? serverPaginatedQuery : clientPaginatedQuery;
}

const queryConfig = {
  pageSize: 15,
  filterDebounceMs: 500,
  queryOptions: {
    pollingInterval: 5000,
    refetchOnMountOrArgChange: true,
  },
};

function useClientPaginatedQuery(input: UseOrgEvidenceTableQueryInput) {
  const isServerPaginationEnabledFlag = useFeatureFlagEnabled('evidence-sever-pagination');
  const isServerPaginationEnabled = input.forceClientSide ? false : isServerPaginationEnabledFlag;
  const organizationId = useAppSelector(getCurrentUserSelectedOrgId);

  const variables = {
    organizationId,
  };
  const { isLoading } = useGetOrganizationEvidencesQuery(variables, {
    ...queryConfig.queryOptions,
    skip: isServerPaginationEnabled,
  });
  const data = useAppSelector((state) => getMappedOrganizationEvidences(state, organizationId));

  // Table meta is used only in callbacks. Using `useRef` to prevent table rerenders on query
  // varialbes change and not bother with `useMemo` dependency waterfall.
  const meta = useRef<TableMeta<OrganizationEvidence>>();
  meta.current = { query: { variables } };

  return {
    isLoading,
    data,
    pageSize: queryConfig.pageSize,
    meta: meta.current,
  };
}

function useServerPaginatedQuery({
  columns,
  globalFilter,
  columnFilters,
  sorting,
  forceClientSide,
}: UseOrgEvidenceTableQueryInput) {
  const isServerPaginationEnabledFlag = useFeatureFlagEnabled('evidence-sever-pagination');
  const isServerPaginationEnabled = forceClientSide ? false : isServerPaginationEnabledFlag;
  const organizationId = useAppSelector(getCurrentUserSelectedOrgId);

  const [pagination, tablePaginationProps] = useServerPagination({
    columns,
    pageSize: queryConfig.pageSize,
    globalFilter: useDebounce(globalFilter, { wait: queryConfig.filterDebounceMs }),
    columnFilters: useDebounce(columnFilters, {
      wait: queryConfig.filterDebounceMs,
      leading: true,
    }),
    sorting,
  });

  const variables = {
    where: {
      ...pagination.where,
      organization_id: { _eq: organizationId },
    },
    orderBy: pagination.orderBy ?? { internal_id: Order_By.Asc },
    offset: pagination.offset,
    limit: pagination.limit,
  };
  const prevVariables = usePrevious(variables);
  const areVariablesEqual = equal(prevVariables, variables);

  const query = useGetEvidencePaginatedQuery(variables, {
    ...queryConfig.queryOptions,
    skip: !isServerPaginationEnabled,
  });
  const data = useAppSelector((state) => getMappedEvidencePaginated(state, variables));

  const [isFilterLoading, setIsFilterLoading] = useState(false);

  useLayoutEffect(() => {
    if (!areVariablesEqual) {
      setIsFilterLoading(true);
    }
  }, [areVariablesEqual]);

  useLayoutEffect(() => {
    if (!query.isFetching) {
      setIsFilterLoading(false);
    }
  }, [query.isFetching]);

  return {
    ...tablePaginationProps,
    isLoading: query.isLoading || isFilterLoading,
    data,
    rowCount: query.data?.evidences_aggregate.aggregate?.count,
  };
}

import { SVGProps } from 'react';

export const IoGitBranchOutlineIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      {...props}
    >
      <g>
        <path
          id="Vector (Stroke)"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.5 3C6.67157 3 6 3.67157 6 4.5C6 5.32843 6.67157 6 7.5 6C8.32843 6 9 5.32843 9 4.5C9 3.67157 8.32843 3 7.5 3ZM4.5 4.5C4.5 2.84315 5.84315 1.5 7.5 1.5C9.15685 1.5 10.5 2.84315 10.5 4.5C10.5 6.15685 9.15685 7.5 7.5 7.5C5.84315 7.5 4.5 6.15685 4.5 4.5Z"
        />
        <path
          id="Vector (Stroke)_2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.5 18C6.67157 18 6 18.6716 6 19.5C6 20.3284 6.67157 21 7.5 21C8.32843 21 9 20.3284 9 19.5C9 18.6716 8.32843 18 7.5 18ZM4.5 19.5C4.5 17.8431 5.84315 16.5 7.5 16.5C9.15685 16.5 10.5 17.8431 10.5 19.5C10.5 21.1569 9.15685 22.5 7.5 22.5C5.84315 22.5 4.5 21.1569 4.5 19.5Z"
        />
        <path
          id="Vector (Stroke)_3"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.5 6C7.91421 6 8.25 6.33579 8.25 6.75V17.25C8.25 17.6642 7.91421 18 7.5 18C7.08579 18 6.75 17.6642 6.75 17.25V6.75C6.75 6.33579 7.08579 6 7.5 6Z"
        />
        <path
          id="Vector (Stroke)_4"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.5 6C15.6716 6 15 6.67157 15 7.5C15 8.32843 15.6716 9 16.5 9C17.3284 9 18 8.32843 18 7.5C18 6.67157 17.3284 6 16.5 6ZM13.5 7.5C13.5 5.84315 14.8431 4.5 16.5 4.5C18.1569 4.5 19.5 5.84315 19.5 7.5C19.5 9.15685 18.1569 10.5 16.5 10.5C14.8431 10.5 13.5 9.15685 13.5 7.5Z"
        />
        <path
          id="Vector (Stroke)_5"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.5 9C16.9142 9 17.25 9.33579 17.25 9.75C17.25 11.4816 16.5781 12.6119 15.5157 13.3349C14.5288 14.0065 13.2535 14.2862 12.153 14.5155C12.0932 14.5279 12.0339 14.5403 11.9751 14.5525C10.8776 14.7804 9.95737 14.9716 9.27738 15.3588C8.94056 15.5506 8.69575 15.7749 8.53044 16.0514C8.36578 16.3268 8.25 16.7041 8.25 17.25C8.25 17.6642 7.91421 18 7.5 18C7.08579 18 6.75 17.6642 6.75 17.25C6.75 16.4834 6.91547 15.8295 7.24299 15.2816C7.56987 14.7349 8.02819 14.344 8.53512 14.0553C9.43136 13.5449 10.5796 13.3083 11.5955 13.099C11.6803 13.0815 11.7643 13.0643 11.847 13.047C12.9965 12.8075 13.9712 12.5716 14.6718 12.0948C15.2969 11.6694 15.75 11.0184 15.75 9.75C15.75 9.33579 16.0858 9 16.5 9Z"
        />
      </g>
    </svg>
  );
};

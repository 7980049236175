/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type InsertTagMutationVariables = Types.Exact<{
  object: Types.Control_Tags_Insert_Input;
}>;

export type InsertTagMutation = {
  __typename?: 'mutation_root';
  insert_control_tags_one?: { __typename?: 'control_tags'; tag_id: string };
};

export const InsertTagDocument = `
    mutation InsertTag($object: control_tags_insert_input!) {
  insert_control_tags_one(object: $object) {
    tag_id
  }
}
    ` as string & TypedDocumentNode<InsertTagMutation, InsertTagMutationVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    InsertTag: build.mutation<InsertTagMutation, InsertTagMutationVariables>({
      query: (variables) => ({ document: InsertTagDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useInsertTagMutation } = injectedRtkApi;

import { Route } from '@tanstack/react-router';

import { RiskClassifications } from '../../../../features/settings/organization/risks/classifications/classifications-page';
import { organizationRisksSettingsLayoutRoute } from './layout';

export const organizationRisksClassificationsSettingsRoute = new Route({
  getParentRoute: () => organizationRisksSettingsLayoutRoute,
  path: 'classifications',
  component: RiskClassifications,
});

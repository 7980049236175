import { Box, SystemStyleObject, useColorModeValue } from '@chakra-ui/react';
import { flexRender, Header } from '@tanstack/react-table';
import { ForwardedRef, forwardRef, MouseEvent, TouchEvent, useCallback } from 'react';

export const TableColumnHeader = forwardRef(
  (
    {
      header,
      resizeHandleColor,
      styles,
      updateColumnSizing,
    }: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      header: Header<any, any>;
      resizeHandleColor: string;
      styles?: SystemStyleObject;
      updateColumnSizing(): Promise<void>;
    },
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const resizeBgColor = useColorModeValue('gray.100', 'gray.600');

    return (
      <Box
        key={header.id}
        role="columnheader"
        gridColumnEnd={`span ${header.colSpan}`}
        zIndex={header.column.getIsResizing() ? 1 : undefined}
        backgroundColor={header.column.getIsResizing() ? resizeBgColor : undefined}
        __css={styles}
        ref={ref}
      >
        {header.isPlaceholder
          ? null
          : flexRender(header.column.columnDef.header, header.getContext())}
        {header.column.getCanResize() && (
          <TableColumnResizeHandle
            color={resizeHandleColor}
            header={header}
            updateColumnSizing={updateColumnSizing}
          />
        )}
      </Box>
    );
  },
);

export function TableColumnResizeHandle<TData, TValue>({
  color,
  header,
  updateColumnSizing,
}: {
  color: string;
  header: Header<TData, TValue>;
  updateColumnSizing(): Promise<void>;
}) {
  const onResize = useCallback(
    async (event: MouseEvent | TouchEvent) => {
      await updateColumnSizing();
      header.getResizeHandler()(event);
    },
    [header, updateColumnSizing],
  );

  return (
    <Box
      className="why-table--resize-handle"
      position="absolute"
      top="0"
      right="0"
      bottom="0"
      width="3px"
      cursor="col-resize"
      backgroundColor={header.column.getIsResizing() ? color : undefined}
      transition="background-color 0.2s ease-out"
      zIndex="1"
      onMouseDown={onResize}
      onTouchStart={onResize}
    />
  );
}

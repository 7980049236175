/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type AddControlCriteriaTagMutationVariables = Types.Exact<{
  criteriaId: Types.Scalars['uuid']['input'];
  controlId: Types.Scalars['uuid']['input'];
}>;

export type AddControlCriteriaTagMutation = {
  __typename?: 'mutation_root';
  insert_control_criteria_one?: {
    __typename?: 'control_criteria';
    criteria_id: string;
    control_id: string;
  };
};

export const AddControlCriteriaTagDocument = `
    mutation AddControlCriteriaTag($criteriaId: uuid!, $controlId: uuid!) {
  insert_control_criteria_one(
    object: {criteria_id: $criteriaId, control_id: $controlId}
  ) {
    criteria_id
    control_id
  }
}
    ` as string &
  TypedDocumentNode<AddControlCriteriaTagMutation, AddControlCriteriaTagMutationVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    AddControlCriteriaTag: build.mutation<
      AddControlCriteriaTagMutation,
      AddControlCriteriaTagMutationVariables
    >({
      query: (variables) => ({ document: AddControlCriteriaTagDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useAddControlCriteriaTagMutation } = injectedRtkApi;

import { Route } from '@tanstack/react-router';

import { RiskDashboard } from '../../features/risks/dashboard';
import { risksRoute } from './risks';

export const riskDashboardRoute = new Route({
  getParentRoute: () => risksRoute,
  path: 'dashboard',
  component: RiskDashboard,
});

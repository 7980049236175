import { datadogLogs } from '@datadog/browser-logs';
import { useInsertEvidencesMutation } from '@main/graphql/mutations/InsertEvidences.generated';
import { isNonNullable, toError } from '@main/shared/utils';
import { errorToast, successToast } from '@main/ui';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../hooks/redux-toolkit-hooks';
import { removeExtentionFromFilename } from '../../../utils/string';
import { getCurrentUserSelectedOrg } from '../../user/slice';
import { EvidenceVersionModal } from './evidence-version-modal';
import { EvidenceVersionType } from './evidence-version-type';
import { useLazyGetPolicyNameQuery } from './evidence-versions.generated';
import { LinkEvidenceFormSchema, useLinkEvidenceForm } from './link-evidence-form';
import {
  MultipleFilesEvidenceFormSchema,
  useMultipleFilesEvidenceForm,
} from './multiple-files-evidence-form';
import { PolicyEvidenceFormSchema, usePolicyEvidenceForm } from './policy-evidence-form';

export const CreateEvidenceWithVersionsModal = ({
  controlId,
  isOpen,
  onCreate,
  onClose,
}: {
  controlId: string;
  isOpen: boolean;
  onCreate: () => void;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const { is_policies_module_enabled: isPoliciesModuleEnabled } =
    useAppSelector(getCurrentUserSelectedOrg);

  const handleErrors = useHandleErrors({ onCreate });
  const createPolicyEvidence = useCreatePolicyEvidence({ controlId });
  const createFileEvidence = useCreateFileEvidence({ controlId });
  const createLinkEvidence = useCreateLinkEvidence({ controlId });

  const policyEvidenceForm = usePolicyEvidenceForm({
    onSubmit(data) {
      return handleErrors(createPolicyEvidence(data));
    },
  });

  const forms = [
    {
      type: EvidenceVersionType.File,
      form: useMultipleFilesEvidenceForm({
        onSubmit(data) {
          return handleErrors(createFileEvidence(data));
        },
      }),
    },

    {
      type: EvidenceVersionType.Link,
      form: useLinkEvidenceForm({
        onSubmit(data) {
          return handleErrors(createLinkEvidence(data));
        },
      }),
    },
    isPoliciesModuleEnabled
      ? {
          type: EvidenceVersionType.Policy,
          form: policyEvidenceForm,
        }
      : null,
  ].filter(isNonNullable);

  return (
    <EvidenceVersionModal
      header={t('evidences.createModal.heading')}
      forms={forms}
      isOpen={isOpen}
      onClose={onClose}
    />
  );
};

function useHandleErrors({ onCreate }: { onCreate: () => void }) {
  const { t } = useTranslation();

  return async (promise: Promise<unknown>) => {
    try {
      await promise;
      successToast(t('successMessages.createSucceeded', { entity: t('entities.evidence') }));
      onCreate();
    } catch (error) {
      errorToast(t('errorMessages.createFailed', { entity: t('entities.evidence') }));
      datadogLogs.logger.error('Failed creating evidence', {}, toError(error));
    }
  };
}

function useCreatePolicyEvidence({ controlId }: { controlId: string }) {
  const organization = useAppSelector(getCurrentUserSelectedOrg);
  const [getPolicyName] = useLazyGetPolicyNameQuery();
  const [insertEvidence] = useInsertEvidencesMutation();

  return async ({
    policyId,
    withVersionsChapter,
    withRevisionDetails,
    withApprovalsChapter,
    withAcknowledgementsChapter,
  }: PolicyEvidenceFormSchema) => {
    if (!policyId) {
      return;
    }

    const getPolicyNameQuery = await getPolicyName({ policyId }).unwrap();

    await insertEvidence({
      objects: {
        name: getPolicyNameQuery.policies_by_pk?.name,
        organization_id: organization.id,
        control_evidences: {
          data: [
            {
              control_id: controlId,
            },
          ],
        },
        evidence_policy: {
          data: {
            policy_id: policyId,
            with_versions_chapter: withVersionsChapter,
            with_revision_details: withRevisionDetails,
            with_approvals_chapter: withApprovalsChapter,
            with_acknowledgements_chapter: withAcknowledgementsChapter,
          },
        },
      },
    }).unwrap();
  };
}

function useCreateFileEvidence({ controlId }: { controlId: string }) {
  const organization = useAppSelector(getCurrentUserSelectedOrg);
  const [insertEvidence] = useInsertEvidencesMutation();

  return async ({ validityStart, files }: MultipleFilesEvidenceFormSchema) => {
    await insertEvidence({
      objects: files.map((file) => ({
        name: removeExtentionFromFilename(file.name),
        organization_id: organization.id,
        control_evidences: {
          data: [
            {
              control_id: controlId,
            },
          ],
        },
        evidence_versions: {
          data: [
            {
              validity_start: validityStart,
              evidence_version_file: {
                data: {
                  file_id: file.id,
                },
              },
            },
          ],
        },
      })),
    }).unwrap();
  };
}

function useCreateLinkEvidence({ controlId }: { controlId: string }) {
  const organization = useAppSelector(getCurrentUserSelectedOrg);
  const [insertEvidence] = useInsertEvidencesMutation();

  return async ({ validityStart, url }: LinkEvidenceFormSchema) => {
    await insertEvidence({
      objects: {
        name: url,
        organization_id: organization.id,
        control_evidences: {
          data: [
            {
              control_id: controlId,
            },
          ],
        },
        evidence_versions: {
          data: [
            {
              validity_start: validityStart,
              url: url,
            },
          ],
        },
      },
    }).unwrap();
  };
}

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type RemoveControlCategoryTagMutationVariables = Types.Exact<{
  categoryId: Types.Scalars['uuid']['input'];
  controlId: Types.Scalars['uuid']['input'];
}>;

export type RemoveControlCategoryTagMutation = {
  __typename?: 'mutation_root';
  delete_control_categories_by_pk?: {
    __typename?: 'control_categories';
    category_id: string;
    control_id: string;
  };
};

export const RemoveControlCategoryTagDocument = `
    mutation RemoveControlCategoryTag($categoryId: uuid!, $controlId: uuid!) {
  delete_control_categories_by_pk(
    category_id: $categoryId
    control_id: $controlId
  ) {
    category_id
    control_id
  }
}
    ` as string &
  TypedDocumentNode<RemoveControlCategoryTagMutation, RemoveControlCategoryTagMutationVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    RemoveControlCategoryTag: build.mutation<
      RemoveControlCategoryTagMutation,
      RemoveControlCategoryTagMutationVariables
    >({
      query: (variables) => ({ document: RemoveControlCategoryTagDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useRemoveControlCategoryTagMutation } = injectedRtkApi;

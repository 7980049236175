import { Button, Flex, Icon, Stack, Text, useColorModeValue } from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import {
  CalendarDaysIcon,
  PlusIcon,
  UserIcon,
  UsersIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { Field_Entities_Enum, SearchEntitiesEnum } from '@main/graphql/types.generated';
import { groupBy, toError, typedObjectEntries } from '@main/shared/utils';
import {
  DrawerProperty,
  EditableAutoResizeTextarea,
  EditableAvatar,
  EditableInput,
  EmptyPlaceholder,
  errorToast,
  GlobalSearchTabs,
  successToast,
  useDrawer,
} from '@main/ui';
import { BarsIcon } from '@main/ui/icons';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { COMPLYANCE_USER } from '../../utils/constants';
import { serializeDate, toDatePart } from '../../utils/date';
import { CustomFieldInput } from '../custom-fields/custom-field-input';
import { useGetFieldConfigsQuery } from '../custom-fields/field-config';
import { AppGlobalSearchResult } from '../global-search/use-global-search';
import { EntitySearch } from '../link-entity/entity-search';
import { EntitySearchResults } from '../link-entity/entity-search-results';
import {
  useLinkTaskToControlMutation,
  useLinkTaskToPolicyMutation,
  useLinkTaskToRiskMutation,
  useLinkTaskToVendorMutation,
} from '../link-entity/link-entity.generated';
import { LinkEntityModal } from '../link-entity/link-entity-modal';
import { useEntitySearch } from '../link-entity/use-entity-search';
import { EntityReferenceCard } from '../shared/entity-reference-card';
import { getAssigneeOptions } from '../shared/get-assignee-options';
import {
  getCurrentOrgNonDisabledUsers,
  getCurrentOrgUsersMap,
  getCurrentUserSelectedOrgId,
  getCurrentUserSelectedOrgRole,
} from '../user/slice';
import {
  useCreateTaskOwnerMutation,
  useRemoveTaskOwnerMutation,
  useUpdateTaskByIdMutation,
} from './manage-tasks.generated';
import { getMappedTask, LinkedEntityObject } from './slice';
import { useUnlinkTask } from './unlink-task';
import { useUpdateTaskHandler } from './use-update-task-handler';

export const TaskDetailsTab = ({ taskId }: { taskId: string }) => {
  const { t } = useTranslation();
  const textColor = useColorModeValue('gray.600', 'gray.500');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canEditTask = userRole.permissionMap?.write_tasks;
  const currentOrgNonDisabledUsers = useAppSelector(getCurrentOrgNonDisabledUsers);
  const currentOrgUsers = useAppSelector(getCurrentOrgUsersMap);
  const drawer = useDrawer();
  const orgId = useAppSelector(getCurrentUserSelectedOrgId);

  const unlinkTask = useUnlinkTask();
  const updateTaskHandler = useUpdateTaskHandler();
  const [updateTask] = useUpdateTaskByIdMutation();
  const [createTaskOwner] = useCreateTaskOwnerMutation();
  const [removeTaskOwner] = useRemoveTaskOwnerMutation();
  const task = useAppSelector((state) => getMappedTask(state, taskId));
  const fieldConfigsQuery = useGetFieldConfigsQuery({
    orgId,
    entityName: Field_Entities_Enum.Task,
  });

  const entitySearch = useEntitySearch({
    entities: [
      SearchEntitiesEnum.Controls,
      SearchEntitiesEnum.Risks,
      SearchEntitiesEnum.Vendors,
      SearchEntitiesEnum.Policies,
    ],
  });
  const [linkTaskToControl, { isLoading: isLinkingControl }] = useLinkTaskToControlMutation();
  const [linkTaskToRisk, { isLoading: isLinkingRisk }] = useLinkTaskToRiskMutation();
  const [linkTaskToVendor, { isLoading: isLinkingVendor }] = useLinkTaskToVendorMutation();
  const [linkTaskToPolicy, { isLoading: isLinkingPolicy }] = useLinkTaskToPolicyMutation();
  const isLinkingReference =
    isLinkingControl || isLinkingRisk || isLinkingVendor || isLinkingPolicy;

  const linkTaskReferences = async () => {
    try {
      const selectedReferences = typedObjectEntries(
        groupBy(entitySearch.selectedResults, 'entity') as Record<
          SearchEntitiesEnum,
          AppGlobalSearchResult[]
        >,
      );

      const mutations = selectedReferences.map(([entity, references]) => {
        switch (entity) {
          case 'controls':
            return linkTaskToControl({
              input: references.map((reference) => ({
                control_id: reference.id,
                task_id: taskId,
              })),
            });

          case 'risks':
            return linkTaskToRisk({
              input: references.map((reference) => ({
                risk_id: reference.id,
                task_id: taskId,
              })),
            });

          case 'vendors':
            return linkTaskToVendor({
              input: references.map((reference) => ({
                vendor_id: reference.id,
                task_id: taskId,
              })),
            });

          case 'policies':
            return linkTaskToPolicy({
              input: references.map((reference) => ({
                policy_id: reference.id,
                task_id: taskId,
              })),
            });
          default:
            return [];
        }
      });

      await Promise.all(mutations);
      successToast(t('tasks.referenceSuccessMessage'));
    } catch (error) {
      errorToast(t('errorMessages.linkFailed', { entity: t('globalSearch.reference') }));
      datadogLogs.logger.error('Task reference linking failed', { taskId }, toError(error));
    } finally {
      entitySearch.disclosure.onClose?.();
    }
  };

  const getAssigneeValue = () => {
    // TODO: Add support for multiple assignees
    const userId = task?.task_owners?.[0]?.owner_id;
    if (!userId) {
      return;
    }

    const user = currentOrgUsers[userId];
    if (!user) {
      return;
    }

    return {
      id: userId,
      displayName: user.displayName,
    };
  };

  const getTaskOwner = () => {
    if (!task?.created_by) {
      return COMPLYANCE_USER;
    }

    const user = currentOrgUsers[task.created_by];

    if (!user) {
      return COMPLYANCE_USER;
    }

    return {
      id: user.id,
      displayName: user.displayName,
    };
  };

  if (!task) {
    return null;
  }

  return (
    <Stack spacing={[6, 3]}>
      <DrawerProperty isReadOnly={!canEditTask}>
        <DrawerProperty.Label icon={BarsIcon}>{t('tasks.description')}</DrawerProperty.Label>
        <DrawerProperty.Content>
          <EditableAutoResizeTextarea
            defaultValue={task.description}
            placeholder={t('tasks.placeholder.description')}
            color={textColor}
            onSubmit={(value) => {
              updateTaskHandler(
                updateTask({
                  taskId,
                  updatePayload: { description: value },
                }),
              );
            }}
          />
        </DrawerProperty.Content>
      </DrawerProperty>
      <DrawerProperty isReadOnly={!canEditTask}>
        <DrawerProperty.Label icon={UsersIcon}>{t('tasks.assignee.heading')}</DrawerProperty.Label>
        <DrawerProperty.Content>
          <EditableAvatar
            options={getAssigneeOptions(currentOrgNonDisabledUsers)}
            value={getAssigneeValue()}
            placeholder={t('tasks.placeholder.assignee')}
            onChange={(option) => {
              if (!option) {
                updateTaskHandler(
                  removeTaskOwner({
                    taskId,
                  }),
                );
              } else {
                updateTaskHandler(
                  createTaskOwner({
                    taskId,
                    ownerId: option.id,
                  }),
                );
              }
            }}
          />
        </DrawerProperty.Content>
      </DrawerProperty>

      <DrawerProperty isReadOnly={!canEditTask}>
        <DrawerProperty.Label icon={CalendarDaysIcon}>{t('tasks.dueDate')}</DrawerProperty.Label>
        <DrawerProperty.Content>
          <EditableInput
            input={{ type: 'date' }}
            placeholder={t('tasks.placeholder.dueDate')}
            editable={{
              defaultValue: task.due_date ? toDatePart(task.due_date) : undefined,
              onSubmit: (dueDate) =>
                updateTaskHandler(
                  updateTask({
                    taskId,
                    updatePayload: { due_date: serializeDate(dueDate) || null },
                  }),
                ),
            }}
          />
        </DrawerProperty.Content>
      </DrawerProperty>

      <DrawerProperty isReadOnly>
        <DrawerProperty.Label icon={UserIcon}>{t('tasks.owner')}</DrawerProperty.Label>
        <DrawerProperty.Content>
          <EditableAvatar value={getTaskOwner()} />
        </DrawerProperty.Content>
      </DrawerProperty>

      {fieldConfigsQuery.data?.field_configs.map((config) => (
        <CustomFieldInput
          key={config.id}
          entityId={task.id}
          config={config}
          values={task?.field_values}
          onChange={fieldConfigsQuery.refetch}
          isReadOnly={!canEditTask}
        />
      ))}

      <Flex direction={'column'} gap={2}>
        <Text fontSize={'xs'} fontWeight={'semibold'}>
          {t('tasks.references')}:
        </Text>
        {isEntityReferenceExists(task.linkedEntities) ? (
          typedObjectEntries(task.linkedEntities).map(([entityName, entities], index) => {
            return entities.map((entity) => (
              <EntityReferenceCard
                key={`${entity.id}`}
                onUnlink={() =>
                  unlinkTask({
                    taskId: task.id,
                    entityId: entity.id,
                    linkedEntity: entityName,
                  })?.onClick()
                }
              >
                <EntityReferenceCard.Tags
                  tags={[
                    {
                      label: `${t(`entities.${entityName}`)} / ${entity.internal_id}`,
                      color: 'purple',
                    },
                  ]}
                />
                <EntityReferenceCard.Reference
                  onClick={() => {
                    drawer.open({ entity: entityName, entityId: entity.id });
                  }}
                >
                  {entity.name}
                </EntityReferenceCard.Reference>
              </EntityReferenceCard>
            ));
          })
        ) : (
          <EmptyPlaceholder border={'1px'} rounded={8} borderColor={borderColor}>
            <EmptyPlaceholder.Icon as={XMarkIcon} />
            <EmptyPlaceholder.Content>
              <EmptyPlaceholder.Heading>{t('tasks.emptyReference.title')}</EmptyPlaceholder.Heading>
              <EmptyPlaceholder.Subheading>
                {t('tasks.emptyReference.subtitle')}
              </EmptyPlaceholder.Subheading>
            </EmptyPlaceholder.Content>
          </EmptyPlaceholder>
        )}
      </Flex>

      <Button
        variant={'outline'}
        leftIcon={<Icon as={PlusIcon} />}
        alignSelf={'end'}
        onClick={() => entitySearch.disclosure.onOpen?.()}
      >
        Link
      </Button>
      <LinkEntityModal
        isOpen={entitySearch.disclosure.isOpen}
        onClose={() => entitySearch.disclosure.onClose?.()}
        entityName={t('globalSearch.reference')}
        onLinkEntity={linkTaskReferences}
        isLinkingEntity={isLinkingReference}
      >
        <EntitySearch
          {...entitySearch}
          input={{
            placeholder: t('shared.linkModal.placeholder', {
              entity: t('globalSearch.reference').toLowerCase(),
            }),
          }}
        >
          <GlobalSearchTabs px={4} />
          <EntitySearchResults {...entitySearch.results} />
        </EntitySearch>
      </LinkEntityModal>
    </Stack>
  );
};

function isEntityReferenceExists(linkedEntities: LinkedEntityObject) {
  return Object.values(linkedEntities).some((entity) => entity.length > 0);
}

import { RouteApi } from '@tanstack/react-router';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { getCurrentUserSelectedOrgRole } from '../user/slice';
import { getFormattedPolicyVersionId } from './constants';
import { useGetPolicyQuery } from './manage-policies.generated';
import { getCurrentPolicyApproval, getCurrentPolicyVersion } from './slice';

const rootRouteApi = new RouteApi({ id: '/' });

export const usePreferredPolicyVersion = () => {
  const { drawerEntity, drawerEntityId } = rootRouteApi.useSearch({
    select: ({ drawerEntity, drawerEntityId }) => ({ drawerEntity, drawerEntityId }),
  });
  const [policyId, policyVersionId] = (drawerEntityId?.split(':') ?? []) as [string, string?];
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);

  const result = useGetPolicyQuery(
    {
      id: policyId,
    },
    {
      selectFromResult: ({ data }) => {
        const formattedVersions = data?.policies_by_pk?.policy_versions.map((version) => ({
          ...version,
          currentApproval: getCurrentPolicyApproval(version.policy_approvals),
          formattedVersionId: getFormattedPolicyVersionId(version.version_id),
        }));

        return {
          data: getCurrentPolicyVersion({
            versions: formattedVersions,
            preferredVersionId: policyVersionId,
            permissionMap: userRole.permissionMap,
          }),
        };
      },
      skip: drawerEntity !== 'policy' && drawerEntity !== 'policy-version',
    },
  );

  return result.data;
};

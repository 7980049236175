/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type InsertQuestionnaireMutationVariables = Types.Exact<{
  object: Types.Questionnaires_Insert_Input;
}>;

export type InsertQuestionnaireMutation = {
  __typename?: 'mutation_root';
  insert_questionnaires_one?: { __typename?: 'questionnaires'; id: string };
};

export const InsertQuestionnaireDocument = `
    mutation InsertQuestionnaire($object: questionnaires_insert_input!) {
  insert_questionnaires_one(object: $object) {
    id
  }
}
    ` as string &
  TypedDocumentNode<InsertQuestionnaireMutation, InsertQuestionnaireMutationVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    InsertQuestionnaire: build.mutation<
      InsertQuestionnaireMutation,
      InsertQuestionnaireMutationVariables
    >({
      query: (variables) => ({ document: InsertQuestionnaireDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useInsertQuestionnaireMutation } = injectedRtkApi;

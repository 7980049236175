import { useNavigate, useSearch } from '@tanstack/react-router';
import { useEffect, useState } from 'react';

/**
 * React hook which syncs table's global filter state to URL query search.
 * `searchParam` configures the search param name under which the state
 * will be persited in the URL.
 */
export function useTableSearchQuery({ searchParam }: { searchParam: string }) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const search = useSearch({ from: '' as any });
  const navigate = useNavigate();

  const [globalFilter, setGlobalFilter] = useState(() =>
    String(search[searchParam as keyof typeof search] ?? ''),
  );

  useEffect(() => {
    navigate({
      search: (old) => ({
        ...old,
        [searchParam]: globalFilter || undefined,
      }),
      replace: true,
    });
  }, [navigate, searchParam, globalFilter]);

  return [globalFilter, setGlobalFilter] as const;
}

import { useCallback } from 'react';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { getCurrentUserId, getCurrentUserSelectedOrgRole } from '../user/slice';

type EvidencePermissionCheck = {
  is_confidential: boolean;
  owner_id?: string;
  acl: { user_id: string }[];
};

export const useIsUserAuthorizedToChangeEvidence = (evidence?: EvidencePermissionCheck) => {
  return useLazyIsUserAuthorizedToChangeEvidence()(evidence);
};

export const useLazyIsUserAuthorizedToChangeEvidence = () => {
  const isOwnerOrGrantedAccess = useIsOwnerOrGrantedAccess();
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);

  return useCallback(
    (evidence?: EvidencePermissionCheck) => {
      if (evidence?.is_confidential) {
        return (
          isOwnerOrGrantedAccess(evidence) || !!userRole.permissionMap.write_confidential_evidence
        );
      }
      return !!userRole.permissionMap.write_evidence;
    },
    [isOwnerOrGrantedAccess, userRole.permissionMap],
  );
};

export const useIsUserAuthorizedToViewVersions = (evidence?: EvidencePermissionCheck) => {
  return useLazyIsUserAuthorizedToViewVersions()(evidence);
};

export const useLazyIsUserAuthorizedToViewVersions = () => {
  const isOwnerOrGrantedAccess = useIsOwnerOrGrantedAccess();
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);

  return useCallback(
    (evidence?: EvidencePermissionCheck) => {
      if (evidence?.is_confidential) {
        return (
          isOwnerOrGrantedAccess(evidence) || !!userRole.permissionMap.read_confidential_evidence
        );
      }
      return !!userRole.permissionMap.read_evidence;
    },
    [isOwnerOrGrantedAccess, userRole.permissionMap],
  );
};

export const useIsUserAuthorizedToChangeOwner = (evidence?: EvidencePermissionCheck) => {
  const isOwnerOrGrantedAccess = useIsOwnerOrGrantedAccess();
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);

  return isOwnerOrGrantedAccess(evidence) || userRole.permissionMap?.write_confidential_evidence;
};

function useIsOwnerOrGrantedAccess() {
  const currentUserId = useAppSelector(getCurrentUserId);

  return useCallback(
    (evidence?: EvidencePermissionCheck) => {
      return (
        evidence?.owner_id === currentUserId ||
        evidence?.acl.some((entry) => entry.user_id === currentUserId)
      );
    },
    [currentUserId],
  );
}

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type GetRiskMatrixQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['uuid']['input'];
}>;

export type GetRiskMatrixQuery = {
  __typename?: 'query_root';
  risk_inherent_levels: Array<{
    __typename?: 'risk_inherent_levels';
    id: string;
    level: Types.Risk_Levels_Enum;
    risk_impact: { __typename?: 'risk_impacts'; name: string };
    risk_likelihood: { __typename?: 'risk_likelihoods'; name: string };
  }>;
  risk_residual_levels: Array<{
    __typename?: 'risk_residual_levels';
    id: string;
    level: Types.Risk_Levels_Enum;
    risk_impact: { __typename?: 'risk_impacts'; name: string };
    risk_likelihood: { __typename?: 'risk_likelihoods'; name: string };
  }>;
};

export type UpdateRiskInherentLevelMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
  level: Types.Risk_Levels_Enum;
}>;

export type UpdateRiskInherentLevelMutation = {
  __typename?: 'mutation_root';
  update_risk_inherent_levels_by_pk?: {
    __typename?: 'risk_inherent_levels';
    level: Types.Risk_Levels_Enum;
  };
};

export type UpdateRiskResidualLevelMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
  level: Types.Risk_Levels_Enum;
}>;

export type UpdateRiskResidualLevelMutation = {
  __typename?: 'mutation_root';
  update_risk_residual_levels_by_pk?: {
    __typename?: 'risk_residual_levels';
    level: Types.Risk_Levels_Enum;
  };
};

export const GetRiskMatrixDocument = `
    query GetRiskMatrix($organizationId: uuid!) {
  risk_inherent_levels(
    where: {risk_impact: {organization_id: {_eq: $organizationId}}}
  ) {
    id
    level
    risk_impact {
      name
    }
    risk_likelihood {
      name
    }
  }
  risk_residual_levels(
    where: {risk_impact: {organization_id: {_eq: $organizationId}}}
  ) {
    id
    level
    risk_impact {
      name
    }
    risk_likelihood {
      name
    }
  }
}
    ` as string & TypedDocumentNode<GetRiskMatrixQuery, GetRiskMatrixQueryVariables>;
export const UpdateRiskInherentLevelDocument = `
    mutation UpdateRiskInherentLevel($id: uuid!, $level: risk_levels_enum!) {
  update_risk_inherent_levels_by_pk(pk_columns: {id: $id}, _set: {level: $level}) {
    level
  }
}
    ` as string &
  TypedDocumentNode<UpdateRiskInherentLevelMutation, UpdateRiskInherentLevelMutationVariables>;
export const UpdateRiskResidualLevelDocument = `
    mutation UpdateRiskResidualLevel($id: uuid!, $level: risk_levels_enum!) {
  update_risk_residual_levels_by_pk(pk_columns: {id: $id}, _set: {level: $level}) {
    level
  }
}
    ` as string &
  TypedDocumentNode<UpdateRiskResidualLevelMutation, UpdateRiskResidualLevelMutationVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetRiskMatrix: build.query<GetRiskMatrixQuery, GetRiskMatrixQueryVariables>({
      query: (variables) => ({ document: GetRiskMatrixDocument, variables }),
    }),
    UpdateRiskInherentLevel: build.mutation<
      UpdateRiskInherentLevelMutation,
      UpdateRiskInherentLevelMutationVariables
    >({
      query: (variables) => ({ document: UpdateRiskInherentLevelDocument, variables }),
    }),
    UpdateRiskResidualLevel: build.mutation<
      UpdateRiskResidualLevelMutation,
      UpdateRiskResidualLevelMutationVariables
    >({
      query: (variables) => ({ document: UpdateRiskResidualLevelDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useGetRiskMatrixQuery,
  useLazyGetRiskMatrixQuery,
  useUpdateRiskInherentLevelMutation,
  useUpdateRiskResidualLevelMutation,
} = injectedRtkApi;

import { FormControl, FormErrorMessage, FormLabel, Input, Stack } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Promisable, UseFormWithViewReturn } from '@main/shared/types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { serializeDate, toDatePart } from '../../../utils/date';

export type LinkEvidenceFormSchema = z.infer<typeof formSchema>;

const formSchema = z.object({
  validityStart: z.coerce.date().transform((date) => date.toISOString()),
  url: z.preprocess((input) => (input === '' ? undefined : input), z.string().trim().url()),
});

export function useLinkEvidenceForm({
  defaultValues,
  onSubmit,
}: {
  defaultValues?: Partial<LinkEvidenceFormSchema>;
  onSubmit: (data: LinkEvidenceFormSchema) => Promisable<void>;
}): UseFormWithViewReturn {
  const { t } = useTranslation();

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: normalizeDefaultValues(defaultValues),
  });

  const { register, formState } = form;

  const reset = () => {
    form.reset(normalizeDefaultValues(defaultValues));
  };

  const handleSubmit = form.handleSubmit(async (data) => {
    await onSubmit({
      validityStart: serializeDate(data.validityStart),
      url: data.url,
    });
  });

  const view = (
    <Stack spacing={6}>
      <FormControl isInvalid={!!formState.errors.validityStart}>
        <FormLabel>{t('evidences.version.label.validityStart')}:</FormLabel>
        <Input type="date" {...register('validityStart')} />
        <FormErrorMessage>{formState.errors.validityStart?.message}</FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={!!formState.errors.url}>
        <FormLabel>{t('evidences.version.label.url')}:</FormLabel>
        <Input
          type="text"
          placeholder={t('evidences.version.placeholder.url')}
          {...register('url')}
        />
        <FormErrorMessage>{formState.errors.url?.message}</FormErrorMessage>
      </FormControl>
    </Stack>
  );

  return {
    formState,
    view,
    reset,
    handleSubmit,
  };
}

function normalizeDefaultValues(
  defaultValues?: Partial<LinkEvidenceFormSchema>,
): LinkEvidenceFormSchema {
  return {
    validityStart: toDatePart(defaultValues?.validityStart),
    url: defaultValues?.url ?? '',
  };
}

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $createParagraphNode, $createTextNode, $getRoot, $getSelection } from 'lexical';
import { useCallback } from 'react';

export const useMentions = () => {
  const [editor] = useLexicalComposerContext();

  return useCallback(
    () =>
      editor.update(() => {
        const mentionSymbol = '@';
        const selection = $getSelection();

        if (selection) {
          selection.insertText(mentionSymbol);
          return;
        }

        const root = $getRoot();
        const p = $createParagraphNode();

        p.append($createTextNode(mentionSymbol));
        root.append(p);

        return;
      }),
    [editor],
  );
};

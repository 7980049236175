import { datadogLogs } from '@datadog/browser-logs';
import {
  GetFieldConfigsQuery,
  useDeleteFieldConfigMutation,
  useInsertFieldConfigMutation,
  useUpdateFieldConfigMutation,
  useUpdateFieldConfigsMutation,
  useUpdateSelectFieldConfigMutation,
} from '@main/graphql/features/CustomFields.generated';
import {
  Field_Configs_Set_Input,
  Field_Entities_Enum,
  Field_Types_Enum,
} from '@main/graphql/types.generated';
import { toError } from '@main/shared/utils';
import { errorToast, useAlertDialog } from '@main/ui';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { getCurrentUserSelectedOrgId, getCurrentUserSelectedOrgRole } from '../user/slice';
import { FieldSchema } from './field-config-modal';

type FieldConfig = GetFieldConfigsQuery['field_configs'][number];

export function useCanEditFieldConfigs(entityName: Field_Entities_Enum) {
  const { permissionMap } = useAppSelector(getCurrentUserSelectedOrgRole);
  return !!permissionMap[`write_${entityName}_field_configs`];
}

export function useCreateFieldConfig({ entityName }: { entityName: Field_Entities_Enum }) {
  const { t } = useTranslation();
  const orgId = useAppSelector(getCurrentUserSelectedOrgId);

  const [insertFieldConfig] = useInsertFieldConfigMutation();

  return async (data: FieldSchema) => {
    switch (data.fieldType) {
      case Field_Types_Enum.Text: {
        try {
          await insertFieldConfig({
            fieldConfig: {
              organization_id: orgId,
              entity_name: entityName,
              field_type: data.fieldType,
              name: data.name,
            },
          }).unwrap();
        } catch (error) {
          errorToast(t('errorMessages.createFailed', { entity: t('entities.customField') }));
          datadogLogs.logger.error('Text field config create failed', {}, toError(error));
        }
        break;
      }

      case Field_Types_Enum.Select: {
        try {
          await insertFieldConfig({
            fieldConfig: {
              organization_id: orgId,
              entity_name: entityName,
              field_type: data.fieldType,
              name: data.name,
              select_field_config: {
                data: {
                  is_multi: data.isMulti,
                  is_creatable: data.isCreatable,
                  select_field_options: {
                    data: data.options.map((value) => ({ value })),
                  },
                },
              },
            },
          }).unwrap();
        } catch (error) {
          errorToast(t('errorMessages.createFailed', { entity: t('entities.customField') }));
          datadogLogs.logger.error('Select field config create failed', {}, toError(error));
        }
        break;
      }
    }
  };
}

export function useUpdateFieldConfig() {
  const { t } = useTranslation();
  const [updateFieldConfig] = useUpdateFieldConfigMutation();

  return async ({ id, ...fieldConfig }: Field_Configs_Set_Input & { id: string }) => {
    try {
      return await updateFieldConfig({ id, fieldConfig }).unwrap();
    } catch (error) {
      errorToast(t('errorMessages.updateFailed', { entity: t('entities.customField') }));
      datadogLogs.logger.error('Field config update failed', {}, toError(error));
      return null;
    }
  };
}

export function useUpdateFieldConfigByType() {
  const { t } = useTranslation();
  const updateFieldConfig = useUpdateFieldConfig();
  const [updateSelectFieldConfig] = useUpdateSelectFieldConfigMutation();

  return async (editingFieldConfig: FieldConfig, data: FieldSchema) => {
    switch (data.fieldType) {
      case Field_Types_Enum.Text: {
        await updateFieldConfig({
          id: editingFieldConfig.id,
          name: data.name,
        });
        break;
      }

      case Field_Types_Enum.Select: {
        try {
          await updateSelectFieldConfig({
            fieldConfigId: editingFieldConfig.id,
            setFieldConfig: {
              name: data.name,
            },
            setSelectFieldConfig: {
              is_multi: data.isMulti,
              is_creatable: data.isCreatable,
            },
            deleteOptionIds: getOptionIdsToDelete(editingFieldConfig, data.options),
            insertOptions: getNewOptions(editingFieldConfig, data.options),
          }).unwrap();
        } catch (error) {
          errorToast(t('errorMessages.updateFailed', { entity: t('entities.customField') }));
          datadogLogs.logger.error('Select field config update failed', {}, toError(error));
        }
      }
    }
  };
}

function getOptionIdsToDelete(fieldConfig: FieldConfig, nextOptions: string[]) {
  if (!fieldConfig.select_field_config) {
    return [];
  }

  return fieldConfig.select_field_config.select_field_options
    .filter(({ value }) => !nextOptions.includes(value))
    .map(({ id }) => id);
}

function getNewOptions(fieldConfig: FieldConfig, nextOptions: string[]) {
  const prevOptions =
    fieldConfig.select_field_config?.select_field_options.map(({ value }) => value) || [];

  return nextOptions
    .filter((option) => !prevOptions.includes(option))
    .map((value) => ({ field_config_id: fieldConfig.id, value }));
}

export function useDeleteFieldConfig({
  onDelete,
  entityName,
}: {
  onDelete: () => Promise<void>;
  entityName: Field_Entities_Enum;
}) {
  const { t } = useTranslation();
  const { openDialog } = useAlertDialog();

  const [deleteFieldConfig] = useDeleteFieldConfigMutation();

  return (id: string) => {
    openDialog({
      dialogHeader: t('customFields.dialogs.delete.header'),
      dialogContent: t('customFields.dialogs.delete.content', { entityName }),
      confirmAction: {
        children: t('customFields.dialogs.delete.confirm'),
        onClick: async () => {
          await deleteFieldConfig({ id });
          await onDelete();
        },
      },
    });
  };
}

export function useUpdateFieldConfigsOrder() {
  const { t } = useTranslation();
  const [updateFieldConfigs] = useUpdateFieldConfigsMutation();

  return async (fieldOrders: Pick<FieldConfig, 'id' | 'order'>[]) => {
    try {
      await updateFieldConfigs({
        updates: fieldOrders.map((fieldConfig) => ({
          where: { id: { _eq: fieldConfig.id } },
          _set: { order: fieldConfig.order },
        })),
      }).unwrap();
    } catch (error) {
      errorToast(t('errorMessages.updateFailed', { entity: t('entities.customField') }));
      datadogLogs.logger.error(
        'Reordering of custom fields failed',
        { fieldOrders },
        toError(error),
      );
      throw error;
    }
  };
}

import { FormControl, FormControlProps } from '@chakra-ui/react';
import { memo, ReactNode, useMemo } from 'react';
import { useFormState } from 'react-hook-form';

import {
  AnyDynamicFormComponentRegistry,
  DynamicFormComponentProps,
  DynamicFormFieldConfig,
} from './field-registry';

export interface DynamicFieldProps<
  TRegistry extends AnyDynamicFormComponentRegistry,
  TConfig extends DynamicFormFieldConfig<TRegistry>,
  TProps extends object = object,
> extends DynamicFormComponentProps<TConfig> {
  componentRegistry: TRegistry;
  formControl?: FormControlProps;
  wrapper?: DynamicFieldWrapper<TRegistry, TProps>;
  wrapperProps?: TProps;
}

export interface DynamicFieldWrapper<
  TRegistry extends AnyDynamicFormComponentRegistry,
  TProps extends object = object,
> extends Function {
  (props: DynamicFieldWrapperProps<TRegistry> & TProps): ReactNode;
  resolveFormControl?(
    config: DynamicFormFieldConfig<TRegistry>,
    formControl?: FormControlProps,
    props?: TProps,
  ): FormControlProps | undefined;
}

export interface DynamicFieldWrapperProps<TRegistry extends AnyDynamicFormComponentRegistry> {
  config: DynamicFormFieldConfig<TRegistry>;
  children: ReactNode;
}

export function _DynamicField<
  TRegistry extends AnyDynamicFormComponentRegistry,
  TConfig extends DynamicFormFieldConfig<TRegistry>,
  TProps extends object = object,
>(props: DynamicFieldProps<TRegistry, TConfig, TProps>) {
  const { errors } = useFormState();

  const FieldComponent = props.componentRegistry.get(props.config.kind);

  const Wrapper: DynamicFieldWrapper<TRegistry, TProps> = useMemo(
    () => props.wrapper ?? (({ children }) => children),
    [props.wrapper],
  );

  const resolveFormControl = Wrapper.resolveFormControl ?? (() => props.formControl);
  const formControl = resolveFormControl(props.config, props.formControl, props.wrapperProps);

  const field = useMemo(() => {
    return <FieldComponent config={props.config} />;
  }, [FieldComponent, props.config]);

  return (
    <FormControl isInvalid={!!errors[props.config.name]} {...formControl}>
      {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
      <Wrapper {...props.wrapperProps!} config={props.config}>
        {field}
      </Wrapper>
    </FormControl>
  );
}

export const DynamicField = memo(_DynamicField) as typeof _DynamicField;

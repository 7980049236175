/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
export type RoleDetailsFragment = {
  __typename?: 'roles';
  id: string;
  name: string;
  system_role?: Types.System_Roles_Enum;
  organization_id: string;
};

export const RoleDetailsFragmentDoc = `
    fragment RoleDetails on roles {
  id
  name
  system_role
  organization_id
}
    `;

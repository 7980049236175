import { Box, Flex, Skeleton, Stack, Text } from '@chakra-ui/react';
import { ChartBarIcon } from '@heroicons/react/24/outline';
import { Key, ReactNode } from 'react';

import { getHashedColor } from '../../utils';
import { EmptyPlaceholder } from '../placeholder';
import { DashboardCard, DashboardCardProps } from './dashboard-card';

export type HBarChartProps = DashboardCardProps & {
  heading: ReactNode;
  subheading?: ReactNode;
  isLoading?: boolean;
  bars?: HBarProps[];
  loadingState: HBarChartLoadingProps;
  emptyState: HBarChartEmptyProps;
};

export const HBarChart = ({
  heading,
  subheading,
  isLoading,
  bars,
  loadingState,
  emptyState,
  ...props
}: HBarChartProps) => {
  const renderContents = () => {
    if (isLoading) {
      return <HBarChartLoading {...loadingState} />;
    }
    if (!bars || !bars.length) {
      return <HBarChartEmpty {...emptyState} />;
    }
    return (
      <DashboardCard.Body overflowY="auto">
        <Stack spacing={4}>
          {bars.map((bar) => (
            <HBar {...bar} />
          ))}
        </Stack>
      </DashboardCard.Body>
    );
  };

  return (
    <DashboardCard {...props}>
      <DashboardCard.Header heading={heading} subheading={subheading} />
      {renderContents()}
    </DashboardCard>
  );
};

export type HBarProps = {
  key: Key;
  label: string;
  value: number;
  color?: string;
};

const HBar = ({ value, label, color = `${getHashedColor(label)}.100` }: HBarProps) => {
  const normValue = Math.max(0, Math.min(value, 1));
  const pctValue = Math.round(normValue * 100);

  return (
    <Stack spacing={1}>
      <Flex
        justify="space-between"
        fontSize="xs"
        lineHeight={4}
        color="gray.500"
        _dark={{ color: 'gray.200' }}
      >
        <Text>{label}</Text>
        <Text>{pctValue}%</Text>
      </Flex>
      <Box borderRadius="lg" overflow="hidden" bg="#fafcfd" _dark={{ bg: 'gray.700' }}>
        <Box w={`${pctValue}%`} h={6} bg={color} />
      </Box>
    </Stack>
  );
};

type HBarChartLoadingProps = {
  barsCount: number;
};

const HBarChartLoading = ({ barsCount }: HBarChartLoadingProps) => {
  return (
    <DashboardCard.Body overflowY="auto">
      <Stack spacing={4}>
        {[...Array(barsCount).keys()].map((key) => (
          <Skeleton key={key} h="44px" />
        ))}
      </Stack>
    </DashboardCard.Body>
  );
};

type HBarChartEmptyProps = {
  heading: ReactNode;
  subheading?: ReactNode;
};

const HBarChartEmpty = ({ heading, subheading }: HBarChartEmptyProps) => {
  return (
    <DashboardCard.Body display="flex" justifyContent="center" alignItems="center">
      <EmptyPlaceholder>
        <EmptyPlaceholder.Icon as={ChartBarIcon} />
        <EmptyPlaceholder.Content>
          <EmptyPlaceholder.Heading>{heading}</EmptyPlaceholder.Heading>
          <EmptyPlaceholder.Subheading>{subheading}</EmptyPlaceholder.Subheading>
        </EmptyPlaceholder.Content>
      </EmptyPlaceholder>
    </DashboardCard.Body>
  );
};

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type GetRolePermissionsQueryVariables = Types.Exact<{
  roleId: Types.Scalars['uuid']['input'];
}>;

export type GetRolePermissionsQuery = {
  __typename?: 'query_root';
  role_permissions: Array<{
    __typename?: 'role_permissions';
    id: string;
    permission: Types.Permissions_Enum;
  }>;
};

export const GetRolePermissionsDocument = `
    query GetRolePermissions($roleId: uuid!) {
  role_permissions(where: {role_id: {_eq: $roleId}}) {
    id
    permission
  }
}
    ` as string & TypedDocumentNode<GetRolePermissionsQuery, GetRolePermissionsQueryVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetRolePermissions: build.query<GetRolePermissionsQuery, GetRolePermissionsQueryVariables>({
      query: (variables) => ({ document: GetRolePermissionsDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetRolePermissionsQuery, useLazyGetRolePermissionsQuery } = injectedRtkApi;

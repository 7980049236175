import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Heading,
  HStack,
  Icon,
  Stack,
  Text,
} from '@chakra-ui/react';
import { ArrowUpTrayIcon } from '@heroicons/react/24/outline';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

export const ReportCardBase = ({
  heading,
  subheading,
  children,
  extraHeaderActions,
  onExportCSV,
}: {
  heading: string;
  subheading: string;
  children: ReactNode;
  extraHeaderActions?: ReactNode;
  onExportCSV?: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <Card variant="table-styles">
      <CardHeader>
        <Stack spacing={1}>
          <Heading size="md">{heading}</Heading>
          <Text fontSize="sm" color="gray.500">
            {subheading}
          </Text>
        </Stack>

        <HStack>
          {extraHeaderActions}
          <Button
            leftIcon={<Icon w={4} h={4} as={ArrowUpTrayIcon} />}
            variant="outline"
            height={'40px'}
            p={'0px 16px'}
            size={'md'}
            gap={'8px'}
            onClick={onExportCSV}
            isDisabled={!onExportCSV}
          >
            {t('buttons.exportSmth', { entity: '.csv' })}
          </Button>
        </HStack>
      </CardHeader>

      <CardBody>{children}</CardBody>
    </Card>
  );
};

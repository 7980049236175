import { SimpleGrid } from '@chakra-ui/react';
import {
  GetOrganizationSummaryQuery,
  useGetOrganizationSummaryQuery,
} from '@main/graphql/queries/GetOrganizationSummary.generated';
import { getToday } from '@main/shared/utils';
import { StatsCard, StatsCardProgressCircle, StatsChange } from '@main/ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { getCurrentUserId, getCurrentUserSelectedOrgId } from '../user/slice';
import { AlertsCount } from './alerts-count';

const weekTimeOffset = 7 * 24 * 60 * 60 * 1000;

export const OrgSummary = () => {
  const { t } = useTranslation();

  const todayTime = getToday().getTime();
  const lastWeek = useMemo(() => new Date(todayTime - weekTimeOffset).toISOString(), [todayTime]);
  const organizationId = useAppSelector(getCurrentUserSelectedOrgId);
  const userId = useAppSelector(getCurrentUserId);
  const { data, isLoading } = useGetOrganizationSummaryQuery(
    {
      organizationId,
      userId,
      statsDate: lastWeek,
    },
    { refetchOnMountOrArgChange: true },
  );
  const orgControlsTotal = data?.controls.aggregate?.count || 0;
  const orgControlsValid = data?.validControls.aggregate?.count || 0;
  const orgControlsPercentage = orgControlsTotal
    ? Math.round((orgControlsValid / orgControlsTotal) * 100)
    : 0;
  const orgControlsChange = getStatsPercentageChange(data?.controlsStats[0], orgControlsPercentage);

  const myControlsTotal = data?.myControls.aggregate?.count || 0;
  const myControlsValid = data?.myValidControls.aggregate?.count || 0;
  const myControlsPercentage = myControlsTotal
    ? Math.round((myControlsValid / myControlsTotal) * 100)
    : 0;
  const myControlsChange = getStatsPercentageChange(data?.myControlsStats[0], myControlsPercentage);

  return (
    <SimpleGrid columns={{ base: 1, lg: 2, xl: 3 }} spacing={{ base: 5, lg: 6 }}>
      <StatsCard
        title={t('dashboard.orgControls.label')}
        stat={`${orgControlsValid}/${orgControlsTotal}`}
        isLoading={isLoading}
        change={orgControlsChange}
      >
        <StatsCardProgressCircle percent={orgControlsPercentage} isLoading={isLoading} />
      </StatsCard>
      <StatsCard
        title={t('dashboard.myControls.label')}
        stat={`${myControlsValid}/${myControlsTotal}`}
        isLoading={isLoading}
        change={myControlsChange}
      >
        <StatsCardProgressCircle percent={myControlsPercentage} isLoading={isLoading} />
      </StatsCard>
      <AlertsCount statsDate={lastWeek} period={7} />
    </SimpleGrid>
  );
};

function getStatsPercentageChange(
  lastStats:
    | GetOrganizationSummaryQuery['controlsStats'][number]
    | GetOrganizationSummaryQuery['myControlsStats'][number]
    | undefined,
  currnetPercentage: number,
): StatsChange {
  const lastStatsTotal =
    (lastStats?.total_valid_count ?? 0) +
    (lastStats?.total_at_risk_count ?? 0) +
    (lastStats?.total_pending_count ?? 0) +
    (lastStats?.total_failing_count ?? 0);
  const lastStatsValid =
    (lastStats?.total_valid_count ?? 0) + (lastStats?.total_at_risk_count ?? 0);
  const lastStatsPercentage = lastStatsTotal
    ? Math.round((lastStatsValid / lastStatsTotal) * 100)
    : undefined;

  return {
    percentage: lastStatsPercentage ? currnetPercentage - lastStatsPercentage : 0,
    period: 7,
  };
}

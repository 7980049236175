import { SVGProps } from 'react';

export const CornerDownLeftIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    {...props}
  >
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.08926 7.74408C8.41469 8.06951 8.41469 8.59715 8.08926 8.92259L4.51184 12.5L8.08926 16.0774C8.41469 16.4028 8.41469 16.9305 8.08926 17.2559C7.76382 17.5814 7.23618 17.5814 6.91074 17.2559L2.74408 13.0893C2.41864 12.7638 2.41864 12.2362 2.74408 11.9107L6.91074 7.74408C7.23618 7.41864 7.76382 7.41864 8.08926 7.74408Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.6667 2.5C17.1269 2.5 17.5 2.8731 17.5 3.33333V9.16667C17.5 10.2717 17.061 11.3315 16.2796 12.1129C15.4982 12.8943 14.4384 13.3333 13.3333 13.3333H3.33333C2.8731 13.3333 2.5 12.9602 2.5 12.5C2.5 12.0398 2.8731 11.6667 3.33333 11.6667H13.3333C13.9964 11.6667 14.6323 11.4033 15.1011 10.9344C15.5699 10.4656 15.8333 9.82971 15.8333 9.16667V3.33333C15.8333 2.8731 16.2064 2.5 16.6667 2.5Z"
      />
    </g>
  </svg>
);

import { Icon, IconButton } from '@chakra-ui/react';
import { PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
import { useUpdateQuestionnaireMutation } from '@main/graphql/mutations/UpdateQuestionnaire.generated';
import {
  GetQuestionnairesQuery,
  useGetQuestionnairesQuery,
} from '@main/graphql/queries/GetQuestionnaires.generated';
import {
  formatDate,
  getQuestionnaireType,
  isNonNullable,
  QUESTIONNAIRE_TYPES,
} from '@main/shared/utils';
import {
  createColumnHelper,
  Table,
  useDrawer,
  useTableFiltersQuery,
  useTableSearchQuery,
  useTableSortQuery,
} from '@main/ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import {
  getCurrentOrgNonDisabledUsers,
  getCurrentOrgUsersMap,
  getCurrentUserSelectedOrg,
  getCurrentUserSelectedOrgRole,
} from '../user/slice';
import { useDeleteQuestionnaire } from './delete-questionnaire';
import { useQuestionnairesTableSettings } from './table-settings';
import { useUpdateQuestionnaireHandler } from './use-update-questionnaire-handler';

type Questionnaire = GetQuestionnairesQuery['questionnaires'][number];

export const QuestionnairesTable = () => {
  const { t } = useTranslation();
  const organization = useAppSelector(getCurrentUserSelectedOrg);

  const { data, isLoading: isQuestionnaireLoading } = useGetQuestionnairesQuery({
    organization_id: organization.id,
  });

  const { columnVisibility, setColumnVisibility } = useQuestionnairesTableSettings(
    'questionnaires:table:column-visibility',
  );
  const columns = useQuestionnairesTableColumns();
  const [columnFilters, setColumnFilters] = useTableFiltersQuery({
    columns,
    searchParam: 'questionnairesFilter',
  });
  const [globalFilter, setGlobalFilter] = useTableSearchQuery({ searchParam: 'search' });
  const [sorting, setSorting] = useTableSortQuery({ searchParam: 'questionnairesSort' });

  const tableItemName = useMemo(() => {
    return {
      singular: t('entities.questionnaire').toLowerCase(),
      plural: t('entities.plural.questionnaire').toLowerCase(),
    };
  }, [t]);

  return (
    <Table
      minW="900px"
      entity="questionnaire"
      data={data?.questionnaires || []}
      isLoading={isQuestionnaireLoading}
      columns={columns}
      itemName={tableItemName}
      pageSize={15}
      columnFilters={columnFilters}
      onColumnFiltersChange={setColumnFilters}
      globalFilter={globalFilter}
      columnVisibility={columnVisibility}
      onColumnVisibilityChange={setColumnVisibility}
      onGlobalFilterChange={setGlobalFilter}
      sorting={sorting}
      onSortingChange={setSorting}
    />
  );
};

function useQuestionnairesTableColumns() {
  const { t } = useTranslation();
  const drawer = useDrawer();
  const currentOrgUsers = useAppSelector(getCurrentOrgUsersMap);
  const { id: organizationId } = useAppSelector(getCurrentUserSelectedOrg);
  const currentOrgNonDisabledUsers = useAppSelector(getCurrentOrgNonDisabledUsers);

  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canCreateVendors = userRole.permissionMap?.write_vendors;

  const [updateQuestionnaire] = useUpdateQuestionnaireMutation();
  const updateQuestionnaireHandler = useUpdateQuestionnaireHandler({
    organizationId,
  });
  const deleteQuestionnaire = useDeleteQuestionnaire();

  return useMemo(() => {
    const columnHelper = createColumnHelper<Questionnaire>();
    const openQuestionDrawer = (questionnaireId: string) =>
      drawer.open({ entity: 'questionnaire', entityId: questionnaireId });

    return [
      columnHelper.columns.tag({
        id: 'type',
        header: t('questionnaires.tableColumns.type'),
        accessorFn: (data) => {
          const metaType = QUESTIONNAIRE_TYPES[getQuestionnaireType(data)];

          return {
            value: t(metaType.value),
            colorScheme: metaType.colorScheme,
          };
        },
        enableSorting: true,
        size: 120,
      }),
      columnHelper.columns.text({
        id: 'name',
        header: t('questionnaires.tableColumns.name'),
        accessorFn: ({ name }) => name,
        enableGlobalFilter: true,
        enableSorting: true,
        meta: {
          cell: {
            onClick: (cell) => openQuestionDrawer(cell.row.original.id),
          },
        },
      }),
      columnHelper.columns.text({
        id: 'description',
        header: t('questionnaires.tableColumns.description'),
        accessorFn: ({ description }) => description,
        enableGlobalFilter: true,
        enableColumnFilter: true,
        enableSorting: true,
      }),
      columnHelper.columns.text({
        id: 'createdDate',
        header: t('questionnaires.tableColumns.createdDate'),
        accessorFn: ({ created_at }) => formatDate(created_at),
        enableSorting: true,
        size: 160,
        meta: {
          cell: {
            fontSize: 'xs',
            color: 'gray.500',
          },
        },
      }),
      columnHelper.columns.avatar({
        id: 'owner',
        header: t('questionnaires.tableColumns.owner'),
        accessorFn: ({ owner_id }) => {
          if (!owner_id) {
            return;
          }

          const owner = currentOrgUsers[owner_id];
          if (!owner) {
            return;
          }

          return {
            id: owner.id,
            displayName: owner.displayName,
          };
        },
        size: 80,
        enableColumnFilter: true,
        enableSorting: true,
        edit: canCreateVendors
          ? {
              options: currentOrgNonDisabledUsers.map((user) => ({
                id: user.id,
                displayName: user.displayName,
              })),
              onChange: (row, user) => {
                return updateQuestionnaireHandler(
                  updateQuestionnaire({
                    id: row.original.id,
                    updatePayload: {
                      owner_id: user?.id ?? null,
                    },
                  }),
                );
              },
            }
          : undefined,
      }),
      columnHelper.columns.actions({
        id: 'actions',
        size: 75,
        PrimaryAction: (context) => (
          <IconButton
            minW={4}
            variant="link"
            aria-label={t('questionnaires.actions.edit')}
            icon={<PencilIcon />}
            onClick={() => openQuestionDrawer(context.row.original.id)}
          />
        ),
        secondaryActions: ({ row }) =>
          [
            deleteQuestionnaire && {
              icon: <Icon as={TrashIcon} />,
              label: t('buttons.delete'),
              onClick: () => {
                deleteQuestionnaire(row.original);
              },
            },
          ].filter(isNonNullable),
      }),
    ];
  }, [
    t,
    canCreateVendors,
    drawer,
    currentOrgUsers,
    deleteQuestionnaire,
    currentOrgNonDisabledUsers,
    updateQuestionnaire,
    updateQuestionnaireHandler,
  ]);
}

import { FormConfig } from '@main/graphql/client-scalars';
import {
  useAppendFormItemMutation,
  useUpdateFormConfigMutation,
} from '@main/graphql/features/Form.generated';

import { FormFieldSchema } from './field-config-modal';

export function useCreateFormFieldConfig() {
  const [updateForm] = useAppendFormItemMutation();

  return (formId: string, data: FormFieldSchema) =>
    updateForm({
      form_id: formId,
      config: data,
    });
}

export function useUpdateFormConfig() {
  const [updateFormConfig] = useUpdateFormConfigMutation();

  return (formId: string, config: FormConfig) => updateFormConfig({ form_id: formId, config });
}

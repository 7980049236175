import { isNonNullable } from '@main/shared/utils';
import { ColumnDef, ColumnFilter, SortingState } from '@tanstack/react-table';
import deepmerge from 'deepmerge';
import { useState } from 'react';

import { FilterValueBox } from './filter-helper';

export type HasuraFilter = {
  _or?: object[] | null;
};

export function useServerPagination<TData, TFilter extends HasuraFilter, TSort extends {}>({
  columns,
  pageIndex = 0,
  pageSize,
  globalFilter,
  columnFilters,
  sorting,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: ColumnDef<TData, any>[];
  pageIndex?: number;
  pageSize: number;
  globalFilter: string;
  columnFilters: ColumnFilter[];
  sorting?: SortingState;
}) {
  const [pagination, onPaginationChange] = useState({
    pageIndex,
    pageSize,
  });

  const queryProps = {
    where: createFilter<TData, TFilter>(columns, globalFilter, columnFilters),
    orderBy: createSort<TData, TSort>(columns, sorting),
    offset: pagination.pageIndex * pagination.pageSize,
    limit: pagination.pageSize,
  };

  const tableProps = {
    pagination,
    onPaginationChange,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
  };

  return [queryProps, tableProps] as const;
}

function createFilter<TData, TFilter extends HasuraFilter>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: ColumnDef<TData, any>[],
  globalFilter: string,
  columnFilters: ColumnFilter[],
) {
  let filter = {} as TFilter;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const columnsById = new Map<string, ColumnDef<TData, any>>();

  for (const column of columns) {
    if (column.id) {
      columnsById.set(column.id, column);
    }

    if (globalFilter && column.enableGlobalFilter && column.meta?.getGlobalFilterCondition) {
      const condition = column.meta.getGlobalFilterCondition<TFilter>(globalFilter);

      if (!condition) {
        continue;
      }

      filter._or = filter._or || [];
      filter._or.push(condition);
    }
  }

  for (const columnFilter of columnFilters) {
    const column = columnsById.get(columnFilter.id);
    if (column?.enableColumnFilter && column.meta?.getColumnFilterCondition) {
      const { mode, value } = FilterValueBox.from(columnFilter.value);
      if (value != null) {
        const condition = column.meta.getColumnFilterCondition<TFilter>(mode, value);
        if (!condition) {
          continue;
        }

        filter = deepmerge<TFilter>(filter, condition);
      }
    }
  }

  return filter;
}

function createSort<TData, TSort>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: ColumnDef<TData, any>[],
  sorting?: SortingState,
): TSort[] | undefined {
  const orderBy = sorting
    ?.map((sort) => {
      const column = columns.find((column) => column.id === sort.id);
      return column?.meta?.getColumnSort?.<TSort>(sort.desc ? 'desc' : 'asc');
    })
    .filter(isNonNullable);
  return orderBy?.length ? orderBy : undefined;
}

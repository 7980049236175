/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type GetRiskTagsQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['uuid']['input'];
  nameFilter?: Types.InputMaybe<Types.String_Comparison_Exp>;
}>;

export type GetRiskTagsQuery = {
  __typename?: 'query_root';
  organization_risk_tags: Array<{
    __typename?: 'organization_risk_tags';
    id: string;
    name: string;
  }>;
};

export type CreateRiskTagMutationVariables = Types.Exact<{
  object: Types.Risk_Tags_Insert_Input;
}>;

export type CreateRiskTagMutation = {
  __typename?: 'mutation_root';
  insert_risk_tags_one?: { __typename?: 'risk_tags'; tag_id: string };
};

export type AddRiskTagMutationVariables = Types.Exact<{
  tagId: Types.Scalars['uuid']['input'];
  riskId: Types.Scalars['uuid']['input'];
}>;

export type AddRiskTagMutation = {
  __typename?: 'mutation_root';
  insert_risk_tags_one?: { __typename?: 'risk_tags'; tag_id: string; risk_id: string };
};

export type RemoveRiskTagMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
}>;

export type RemoveRiskTagMutation = {
  __typename?: 'mutation_root';
  delete_risk_tags_by_pk?: { __typename?: 'risk_tags'; tag_id: string };
};

export const GetRiskTagsDocument = `
    query GetRiskTags($organizationId: uuid!, $nameFilter: String_comparison_exp = {}) {
  organization_risk_tags(
    order_by: {name: asc}
    where: {organization_id: {_eq: $organizationId}, name: $nameFilter}
  ) {
    id
    name
  }
}
    ` as string & TypedDocumentNode<GetRiskTagsQuery, GetRiskTagsQueryVariables>;
export const CreateRiskTagDocument = `
    mutation CreateRiskTag($object: risk_tags_insert_input!) {
  insert_risk_tags_one(object: $object) {
    tag_id
  }
}
    ` as string & TypedDocumentNode<CreateRiskTagMutation, CreateRiskTagMutationVariables>;
export const AddRiskTagDocument = `
    mutation AddRiskTag($tagId: uuid!, $riskId: uuid!) {
  insert_risk_tags_one(object: {tag_id: $tagId, risk_id: $riskId}) {
    tag_id
    risk_id
  }
}
    ` as string & TypedDocumentNode<AddRiskTagMutation, AddRiskTagMutationVariables>;
export const RemoveRiskTagDocument = `
    mutation RemoveRiskTag($id: uuid!) {
  delete_risk_tags_by_pk(id: $id) {
    tag_id
  }
}
    ` as string & TypedDocumentNode<RemoveRiskTagMutation, RemoveRiskTagMutationVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetRiskTags: build.query<GetRiskTagsQuery, GetRiskTagsQueryVariables>({
      query: (variables) => ({ document: GetRiskTagsDocument, variables }),
    }),
    CreateRiskTag: build.mutation<CreateRiskTagMutation, CreateRiskTagMutationVariables>({
      query: (variables) => ({ document: CreateRiskTagDocument, variables }),
    }),
    AddRiskTag: build.mutation<AddRiskTagMutation, AddRiskTagMutationVariables>({
      query: (variables) => ({ document: AddRiskTagDocument, variables }),
    }),
    RemoveRiskTag: build.mutation<RemoveRiskTagMutation, RemoveRiskTagMutationVariables>({
      query: (variables) => ({ document: RemoveRiskTagDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useGetRiskTagsQuery,
  useLazyGetRiskTagsQuery,
  useCreateRiskTagMutation,
  useAddRiskTagMutation,
  useRemoveRiskTagMutation,
} = injectedRtkApi;

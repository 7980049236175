import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Heading,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { PlusIcon } from '@heroicons/react/24/outline';
import { useGetReportsForOrganizationQuery } from '@main/graphql/queries/GetReportsForOrganization.generated';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { getCurrentUserSelectedOrg, getCurrentUserSelectedOrgRole } from '../user/slice';
import { CreateReport } from './create-report';
import { ReportsTable } from './reports-table';

export const Reports = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();
  const initialRef = useRef(null);
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canWriteReports = userRole.permissionMap?.write_reports;
  const organization = useAppSelector(getCurrentUserSelectedOrg);
  useGetReportsForOrganizationQuery(
    { organizationId: organization.id },
    { refetchOnMountOrArgChange: true },
  );

  return (
    <>
      <Card variant="table-styles" my="6">
        <CardHeader>
          <Box>
            <Heading size="md">{t('reports.heading')}</Heading>
            <Text fontSize="sm" color="gray.500">
              {t('reports.subheading')}
            </Text>
          </Box>
          {canWriteReports && (
            <Button
              leftIcon={
                <Icon color={'white'} _dark={{ color: 'black' }} w={4} h={4} as={PlusIcon} />
              }
              colorScheme="blue"
              variant="solid"
              height={'40px'}
              onClick={onOpen}
            >
              {t('reports.newReport')}
            </Button>
          )}
        </CardHeader>

        <CardBody>
          <ReportsTable />
        </CardBody>
      </Card>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        initialFocusRef={initialRef}
        isCentered
        size="xl"
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('reports.form.headingCreate')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody p={0}>
            <CreateReport closeModal={onClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

import { Tasks_Set_Input } from '@main/graphql/types.generated';
import { useUpdateAllQueryData } from '@main/shared/utils';
import { useMemo } from 'react';

import { api as tasksApi } from './get-task.generated';

export const useTaskApi = () => {
  const updateAllQueryData = useUpdateAllQueryData();

  return useMemo(() => {
    return {
      optimisticDeleteFromLinkedTaskTable: ({ taskId }: { taskId: string }) =>
        updateAllQueryData(tasksApi, 'GetTasks', (draft) => {
          draft.tasks = draft.tasks.filter((task) => task.id !== taskId);
        }),
      optimisticUpdateOnLinkedTaskTable: ({
        taskId,
        updateInput,
      }: {
        taskId: string;
        updateInput: Tasks_Set_Input;
      }) =>
        updateAllQueryData(tasksApi, 'GetTasks', (draft) => {
          for (const task of draft.tasks) {
            if (task.id === taskId) {
              Object.assign(task, updateInput);
            }
          }
        }),
    };
  }, [updateAllQueryData]);
};

import { FieldConfigFragment } from '@main/graphql/fragments/FieldConfigFragments.generated';
import { FieldValueFragment } from '@main/graphql/fragments/FieldValueFragments.generated';

import { getFieldValue } from './field-value';

export function serializeCustomFields(
  configs: FieldConfigFragment[],
  values: FieldValueFragment[],
) {
  return configs.reduce((acc: Record<string, string | string[]>, config) => {
    acc[config.name] = getFieldValue(config, values);
    return acc;
  }, {});
}

import { AvatarOption } from '@main/ui';

import { getCurrentOrgNonDisabledUsers } from '../user/slice';

export function getAssigneeOptions(
  currentOrgNonDisabledUsers: ReturnType<typeof getCurrentOrgNonDisabledUsers>,
) {
  const assigneeOptions: AvatarOption[] = currentOrgNonDisabledUsers.map((user) => ({
    id: user.id,
    displayName: user.displayName,
  }));

  return assigneeOptions;
}

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type GetAiSuggestionsForControlEvidencesQueryVariables = Types.Exact<{
  input: Types.AiControlEvidenceSuggestionsInput;
}>;

export type GetAiSuggestionsForControlEvidencesQuery = {
  __typename?: 'query_root';
  ai_evidence_suggestions_for_control?: {
    __typename?: 'AiControlEvidenceSuggestionsOutput';
    evidences?: Array<{
      __typename?: 'AiControlEvidenceSuggestion';
      id: string;
      name?: string;
      description?: string;
      internal_id?: string;
      status?: string;
      similarity?: number;
    }>;
  };
};

export const GetAiSuggestionsForControlEvidencesDocument = `
    query GetAiSuggestionsForControlEvidences($input: AiControlEvidenceSuggestionsInput!) {
  ai_evidence_suggestions_for_control(input: $input) {
    evidences {
      id
      name
      description
      internal_id
      status
      similarity
    }
  }
}
    ` as string &
  TypedDocumentNode<
    GetAiSuggestionsForControlEvidencesQuery,
    GetAiSuggestionsForControlEvidencesQueryVariables
  >;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetAiSuggestionsForControlEvidences: build.query<
      GetAiSuggestionsForControlEvidencesQuery,
      GetAiSuggestionsForControlEvidencesQueryVariables
    >({
      query: (variables) => ({ document: GetAiSuggestionsForControlEvidencesDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useGetAiSuggestionsForControlEvidencesQuery,
  useLazyGetAiSuggestionsForControlEvidencesQuery,
} = injectedRtkApi;

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type UpdateOrganizationMemberMutationVariables = Types.Exact<{
  organization_id: Types.Scalars['uuid']['input'];
  user_id: Types.Scalars['uuid']['input'];
  organization_users_set_input?: Types.InputMaybe<Types.Organization_Users_Set_Input>;
}>;

export type UpdateOrganizationMemberMutation = {
  __typename?: 'mutation_root';
  update_organization_users?: {
    __typename?: 'organization_users_mutation_response';
    affected_rows: number;
  };
};

export const UpdateOrganizationMemberDocument = `
    mutation UpdateOrganizationMember($organization_id: uuid!, $user_id: uuid!, $organization_users_set_input: organization_users_set_input) {
  update_organization_users(
    where: {organization_id: {_eq: $organization_id}, user_id: {_eq: $user_id}}
    _set: $organization_users_set_input
  ) {
    affected_rows
  }
}
    ` as string &
  TypedDocumentNode<UpdateOrganizationMemberMutation, UpdateOrganizationMemberMutationVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    UpdateOrganizationMember: build.mutation<
      UpdateOrganizationMemberMutation,
      UpdateOrganizationMemberMutationVariables
    >({
      query: (variables) => ({ document: UpdateOrganizationMemberDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useUpdateOrganizationMemberMutation } = injectedRtkApi;

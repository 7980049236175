import {
  Button,
  ButtonGroup,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Text,
  Textarea,
  useColorModeValue,
} from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { zodResolver } from '@hookform/resolvers/zod';
import { useCreateProgramMutation } from '@main/graphql/mutations/CreateProgram.generated';
import { useInsertFrameworkMutation } from '@main/graphql/mutations/InsertFramework.generated';
import { FrameworkType } from '@main/graphql/types.generated';
import { toError } from '@main/shared/utils';
import { errorToast, successToast } from '@main/ui';
import { useNavigate } from '@tanstack/react-router';
import { FC } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { getCurrentUserSelectedOrg } from '../user/slice';

type CreateCustomFrameworkProps = {
  closeModal: () => void;
};

const frameworkAndProgramSchema = z.object({
  frameworkName: z.string().min(1),
  programName: z.string().min(1),
  programDescription: z.string().optional(),
});

type FrameworkAndProgramSchema = z.infer<typeof frameworkAndProgramSchema>;

export const CreateFrameworkAndProgram: FC<CreateCustomFrameworkProps> = ({ closeModal }) => {
  const { t } = useTranslation();
  const navigate = useNavigate({ from: '/programs/$programId' });

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<FrameworkAndProgramSchema>({
    resolver: zodResolver(frameworkAndProgramSchema),
    shouldFocusError: false,
  });
  const organization = useAppSelector(getCurrentUserSelectedOrg);

  const [createFramework] = useInsertFrameworkMutation();
  const [createProgram] = useCreateProgramMutation();

  const onSubmit: SubmitHandler<FrameworkAndProgramSchema> = async (data) => {
    try {
      const { insert_frameworks_one: newFramework } = await createFramework({
        input_payload: {
          name: data.frameworkName,
          organization_id: organization.id,
          active: true,
        },
      }).unwrap();

      const { create_program: newProgram } = await createProgram({
        programInput: {
          name: data.programName,
          description: data.programDescription,
          organization_id: organization.id,
          frameworkType: FrameworkType.Custom,
          framework_id: newFramework?.id as string,
        },
      }).unwrap();

      successToast(t('successMessages.createFrameworkAndProgramSucceeded'));
      closeModal();
      newProgram?.id &&
        navigate({
          to: `/programs/$programId`,
          params: { programId: newProgram.id },
        });
    } catch (error) {
      errorToast(t('errorMessages.createFailed', { entity: 'Framework or Program' }));
      datadogLogs.logger.error('Create custom framework and program failed', {}, toError(error));
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
      <Stack spacing={6} pb={4}>
        <Text fontSize="lg" fontWeight="bold">
          {t('programs.customFramework.heading')}
        </Text>
        <FormControl>
          <FormLabel>{t('programs.customFramework.form.framework.label')}</FormLabel>
          <Input
            placeholder={t('programs.customFramework.form.framework.placeholder')}
            {...register('frameworkName')}
            isInvalid={!!errors.frameworkName}
          ></Input>
        </FormControl>
        <FormControl>
          <FormLabel>{t('programs.customFramework.form.program.label')}</FormLabel>
          <Input
            placeholder={t('programs.customFramework.form.program.placeholder')}
            {...register('programName')}
            isInvalid={!!errors.programName}
          ></Input>
        </FormControl>
        <FormControl>
          <FormLabel>{t('programs.customFramework.form.description.label')}</FormLabel>
          <Textarea
            maxLength={5000}
            placeholder={t('programs.customFramework.form.description.placeholder')}
            {...register('programDescription')}
            isInvalid={!!errors.programDescription}
          ></Textarea>
        </FormControl>
        <ButtonGroup colorScheme={useColorModeValue('blue', 'white')} justifyContent="end">
          <Button
            colorScheme="gray"
            onClick={() => {
              closeModal();
            }}
          >
            {t('buttons.cancel')}
          </Button>
          <Button isLoading={isSubmitting} type="submit">
            {t('buttons.submit')}
          </Button>
        </ButtonGroup>
      </Stack>
    </form>
  );
};

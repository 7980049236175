import { useDisclosure } from '@chakra-ui/react';
import { SearchEntitiesEnum } from '@main/graphql/types.generated';
import { dedupeArrays } from '@main/shared/utils';
import { GlobalSearchModalProps, GlobalSearchResult, GlobalSearchState, useDrawer } from '@main/ui';
import useDebounceFn from 'ahooks/es/useDebounceFn';
import dayjs from 'dayjs';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import {
  getCurrentUserSelectedOrg,
  getCurrentUserSelectedOrgId,
  getCurrentUserSelectedOrgRole,
} from '../user/slice';
import { entityToCategory, entityToDrawer } from './categories';
import { AppGlobalSearchResultCard } from './result-card';
import { useLazyUseGlobalSearchQuery } from './use-global-search.generated';

export interface AppGlobalSearchResult extends GlobalSearchResult {
  internalId: string;
  entity: SearchEntitiesEnum;
}

const allEntities = Object.values(SearchEntitiesEnum);

export interface UseGlobalSearchProps {
  entities?: SearchEntitiesEnum[];
  pageSize?: number;
  debounce?: number;
}

export function useGlobalSearch({
  entities: outerEntities = allEntities,
  pageSize = 30,
  debounce = 300,
}: UseGlobalSearchProps = {}): GlobalSearchModalProps<AppGlobalSearchResult> {
  const { t } = useTranslation();
  const drawer = useDrawer();
  const organization_id = useAppSelector(getCurrentUserSelectedOrgId);
  const organization = useAppSelector(getCurrentUserSelectedOrg);
  const { permissionMap } = useAppSelector(getCurrentUserSelectedOrgRole);

  const disclosure = useDisclosure();
  const [search, { data, isSearching }] = useLazyUseGlobalSearchQuery({
    selectFromResult: (result) => ({
      data: result.data?.global_search?.data,
      isSearching: result.isFetching,
    }),
  });
  const prevSearch = useRef<string>();
  const searchDebounced = useDebounceFn(search, { wait: debounce });
  const [results, setResults] = useState<AppGlobalSearchResult[]>();
  const prevResults = useRef<AppGlobalSearchResult[]>();
  const pageRef = useRef(0);
  const canLoadMoreRef = useRef(true);
  const disabledEntities: Record<string, boolean> = useMemo(
    () => ({
      [SearchEntitiesEnum.Controls]:
        !organization?.is_controls_module_enabled || !permissionMap?.read_controls,
      [SearchEntitiesEnum.Evidences]:
        !organization?.is_controls_module_enabled || !permissionMap?.read_evidence,
      [SearchEntitiesEnum.Risks]:
        !organization?.is_risks_module_enabled || !permissionMap?.read_risks,
      [SearchEntitiesEnum.Vendors]:
        !organization?.is_vendors_module_enabled || !permissionMap?.read_vendors,
      [SearchEntitiesEnum.Tasks]: !permissionMap?.read_tasks,
      [SearchEntitiesEnum.Policies]:
        !organization?.is_policies_module_enabled || !permissionMap?.read_policies,
    }),
    [
      organization?.is_controls_module_enabled,
      organization?.is_risks_module_enabled,
      organization?.is_vendors_module_enabled,
      organization?.is_policies_module_enabled,
      permissionMap?.read_controls,
      permissionMap?.read_evidence,
      permissionMap?.read_policies,
      permissionMap?.read_risks,
      permissionMap?.read_tasks,
      permissionMap?.read_vendors,
    ],
  );
  const entities = useMemo(
    () =>
      outerEntities.filter(
        (entity) => entity in disabledEntities === false || !disabledEntities[entity],
      ),
    [disabledEntities, outerEntities],
  );
  const categories = useMemo(() => entities.map(entityToCategory), [entities]);

  useEffect(() => {
    setResults((res) => {
      canLoadMoreRef.current = !!data && data.length >= pageSize;

      const newData = data?.map(
        (result) =>
          ({
            id: result.id,
            internalId: result.internal_id ?? result.id,
            title: result.name ?? result.id,
            entity: result.entity,
            category: t(`entities.${entityToDrawer(result.entity)}`),
            updatedAt: t('globalSearch.lastUpdated', { date: dayjs(result.updated_at).fromNow() }),
          } satisfies AppGlobalSearchResult),
      );

      return res ? dedupeArrays([newData ?? [], res], (res) => res.id) : newData;
    });
  }, [data, pageSize, t]);

  function onSearch({ search }: GlobalSearchState) {
    if (!search) {
      prevSearch.current = undefined;
      prevResults.current = undefined;
      canLoadMoreRef.current = true;
      setResults(undefined);
      return;
    }

    if (search === prevSearch.current) {
      pageRef.current++;
    } else {
      pageRef.current = 0;
      canLoadMoreRef.current = true;
    }

    prevSearch.current = search;

    if (!canLoadMoreRef.current) {
      return;
    }

    if (pageRef.current === 0 && results) {
      prevResults.current = results;
      setResults(undefined);
    }

    searchDebounced.run({
      input: {
        text: search,
        organization_id,
        entities,
        limit: pageSize,
        offset: pageRef.current * pageSize,
      },
    });
  }

  function onResultSelect(result: AppGlobalSearchResult) {
    const drawerEntity = entityToDrawer(result.entity);

    drawer.open({ entity: drawerEntity, entityId: result.id });
    disclosure.onClose();
  }

  return {
    disclosure,
    provider: {
      initialSearch: prevSearch.current,
      results: results ?? prevResults.current,
      isSearching,
      categories,
      onSearch,
      onResultSelect,
    },
    results: {
      renderResult: AppGlobalSearchResultCard,
    },
  };
}

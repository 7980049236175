import { z } from 'zod';

export const ControlTabKeys = [
  'details',
  'evidence',
  'findings',
  'comments',
  'tasks',
  'history',
] as const;
export const EvidenceTabKeys = ['versions', 'details', 'controls', 'integrations'] as const;
export const RiskTabKeys = [
  'details',
  'riskLevel',
  'documents',
  'controls',
  'tasks',
  'history',
  'comments',
] as const;
export const VendorTabKeys = [
  'details',
  'documents',
  'questionnaires',
  'risks',
  'tasks',
  'comments',
  'history',
] as const;
export const QuestionnaireTabKeys = ['details', 'questions', 'vendors'] as const;
export const VendorQuestionnairesTabKeys = ['details', 'submissions', 'findings'] as const;
export const UserTabKeys = ['users', 'invitations'] as const;
export const AuditorTabKeys = ['auditors', 'invitations'] as const;
export const RoleTabKeys = ['permissions', 'users'] as const;
export const TaskTabKeys = ['details', 'comments'] as const;
export const PolicyTabKeys = [
  'details',
  'approvals',
  'acknowledgement',
  'versions',
  'tasks',
  'comments',
  'history',
] as const;

export type ControlTabKeys = (typeof ControlTabKeys)[number];
export type EvidenceTabKeys = (typeof EvidenceTabKeys)[number];
export type RiskTabKeys = (typeof RiskTabKeys)[number];
export type VendorTabKeys = (typeof VendorTabKeys)[number];
export type QuestionnaireTabKeys = (typeof QuestionnaireTabKeys)[number];
export type VendorQuestionnairesTabKeys = (typeof VendorQuestionnairesTabKeys)[number];
export type UserTabKeys = (typeof UserTabKeys)[number];
export type AuditorTabKeys = (typeof AuditorTabKeys)[number];
export type RoleTabKeys = (typeof RoleTabKeys)[number];
export type TaskTabKeys = (typeof TaskTabKeys)[number];
export type PolicyTabKeys = (typeof PolicyTabKeys)[number];

export const appDrawerSchema = z.object({
  drawerEntity: z
    .union([
      z.literal('control'),
      z.literal('task'),
      z.literal('evidence'),
      z.literal('risk'),
      z.literal('vendor'),
      z.literal('questionnaire'),
      z.literal('vendor-questionnaire'),
      z.literal('policy'),
      z.literal('policy-version'),
    ])
    .optional()
    .catch('control'),
  activeTab: z
    .union([
      z.enum(ControlTabKeys),
      z.enum(EvidenceTabKeys),
      z.enum(RiskTabKeys),
      z.enum(VendorTabKeys),
      z.enum(QuestionnaireTabKeys),
      z.enum(VendorQuestionnairesTabKeys),
      z.enum(UserTabKeys),
      z.enum(AuditorTabKeys),
      z.enum(RoleTabKeys),
      z.enum(TaskTabKeys),
      z.enum(PolicyTabKeys),
    ])
    .optional()
    .catch('details'),
  drawerEntityId: z.string().optional(),
});

export type AppDrawerType = z.infer<typeof appDrawerSchema>;

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type SearchPoliciesQueryVariables = Types.Exact<{
  orgId: Types.Scalars['uuid']['input'];
  nameFilter: Types.String_Comparison_Exp;
  statusFilter?: Types.InputMaybe<Types.Policy_Statuses_Enum_Comparison_Exp>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type SearchPoliciesQuery = {
  __typename?: 'query_root';
  policies: Array<{
    __typename?: 'policies';
    id: string;
    type: Types.Policy_Types_Enum;
    name?: string;
    internal_id?: string;
    policy_versions: Array<{
      __typename?: 'policy_versions';
      id: string;
      status: Types.Policy_Version_Statuses_Enum;
      validity_start?: string;
    }>;
  }>;
};

export const SearchPoliciesDocument = `
    query SearchPolicies($orgId: uuid!, $nameFilter: String_comparison_exp!, $statusFilter: policy_statuses_enum_comparison_exp = {}, $limit: Int) {
  policies(
    where: {organization_id: {_eq: $orgId}, name: $nameFilter, status: $statusFilter}
    order_by: {name: asc}
    limit: $limit
  ) {
    id
    type
    name
    internal_id
    policy_versions {
      id
      status
      validity_start
    }
  }
}
    ` as string & TypedDocumentNode<SearchPoliciesQuery, SearchPoliciesQueryVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    SearchPolicies: build.query<SearchPoliciesQuery, SearchPoliciesQueryVariables>({
      query: (variables) => ({ document: SearchPoliciesDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useSearchPoliciesQuery, useLazySearchPoliciesQuery } = injectedRtkApi;

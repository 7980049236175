import {
  Box,
  Button,
  ButtonGroup,
  FormLabel,
  Input,
  Stack,
  Text,
  Textarea,
} from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { zodResolver } from '@hookform/resolvers/zod';
import { useCreateFrameworkRequestMutation } from '@main/graphql/mutations/CreateFrameworkRequest.generated';
import { toError } from '@main/shared/utils';
import { errorToast, successToast } from '@main/ui';
import { useNavigate } from '@tanstack/react-router';
import { FC } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { getCurrentUserSelectedOrg } from '../user/slice';

type CreateCustomFrameworkProps = {
  closeDrawer: () => void;
};

const frameworkAndProgramSchema = z.object({
  frameworkName: z.string().min(1),
  additionalComments: z.string().min(1),
});

type FrameworkAndProgramSchema = z.infer<typeof frameworkAndProgramSchema>;

export const CreateFrameworkRequest: FC<CreateCustomFrameworkProps> = ({ closeDrawer }) => {
  const { t } = useTranslation();
  const navigate = useNavigate({ from: '/programs/$programId' });

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<FrameworkAndProgramSchema>({
    resolver: zodResolver(frameworkAndProgramSchema),
    shouldFocusError: false,
  });
  const organization = useAppSelector(getCurrentUserSelectedOrg);

  const [createFrameworkRequest] = useCreateFrameworkRequestMutation();

  const onSubmit: SubmitHandler<FrameworkAndProgramSchema> = async (data) => {
    try {
      await createFrameworkRequest({
        framework_input: {
          frameworkName: data.frameworkName,
          additionalComments: data.additionalComments,
          organizationId: organization.id,
        },
      }).unwrap();

      successToast(t('successMessages.frameworkRequestSucceeded'));
      closeDrawer();
      navigate({
        to: '/dashboard',
      });
    } catch (error) {
      errorToast(t('errorMessages.requestFailed'));
      datadogLogs.logger.error('Request new framework failed', {}, toError(error));
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
      <Stack spacing={6} pb={4}>
        <Text fontSize="lg" fontWeight="bold">
          {t('programs.requestTemplateFramework.heading')}
        </Text>
        <Box>
          <FormLabel htmlFor="framework name">
            {t('programs.requestTemplateFramework.form.framework.label')}
          </FormLabel>
          <Input
            placeholder={t('programs.requestTemplateFramework.form.framework.placeholder')}
            {...register('frameworkName')}
            isInvalid={!!errors.frameworkName}
          ></Input>
        </Box>
        <Box>
          <FormLabel htmlFor="custom framework description">
            {t('programs.requestTemplateFramework.form.comments.label')}
          </FormLabel>
          <Textarea
            maxLength={5000}
            placeholder={t('programs.requestTemplateFramework.form.comments.placeholder')}
            {...register('additionalComments')}
            isInvalid={!!errors.additionalComments}
          ></Textarea>
        </Box>
        <ButtonGroup justifyContent="end">
          <Button
            colorScheme="gray"
            onClick={() => {
              closeDrawer();
            }}
          >
            {t('buttons.cancel')}
          </Button>
          <Button colorScheme="blue" isLoading={isSubmitting} type="submit">
            {t('buttons.submit')}
          </Button>
        </ButtonGroup>
      </Stack>
    </form>
  );
};

import { Field_Entities_Enum } from '@main/graphql/types.generated';

import { useCustomFieldsTableSettings } from '../../utils/table-settings';

const defaultColumnVisibility = {
  internalId: false,
  description: false,
  criteria: false,
  category: false,
  custom_tags: false,
  frequency: false,
};

export function useControlsTableSettings(storageKey: string) {
  return useCustomFieldsTableSettings({
    entityName: Field_Entities_Enum.Control,
    storageKey,
    defaultColumnVisibility,
  });
}

import { Route } from '@tanstack/react-router';

import { DashboardPage } from '../../features/dashboard';
import { authLayout } from '../login';

export const dashboardRoute = new Route({
  getParentRoute: () => authLayout,
  path: 'dashboard',
  component: DashboardPage,
});

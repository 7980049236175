/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type DeleteEvidencePolicyByIdMutationVariables = Types.Exact<{
  evidencePolicyId: Types.Scalars['uuid']['input'];
}>;

export type DeleteEvidencePolicyByIdMutation = {
  __typename?: 'mutation_root';
  delete_evidence_policies_by_pk?: { __typename?: 'evidence_policies'; id: string };
};

export const DeleteEvidencePolicyByIdDocument = `
    mutation DeleteEvidencePolicyById($evidencePolicyId: uuid!) {
  delete_evidence_policies_by_pk(id: $evidencePolicyId) {
    id
  }
}
    ` as string &
  TypedDocumentNode<DeleteEvidencePolicyByIdMutation, DeleteEvidencePolicyByIdMutationVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    DeleteEvidencePolicyById: build.mutation<
      DeleteEvidencePolicyByIdMutation,
      DeleteEvidencePolicyByIdMutationVariables
    >({
      query: (variables) => ({ document: DeleteEvidencePolicyByIdDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useDeleteEvidencePolicyByIdMutation } = injectedRtkApi;

import { Tab, TabList } from '@ariakit/react';
import { Box, BoxProps, HStack, useColorModeValue } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';

import { useGlobalSearchContext } from './global-search-provider';

export function GlobalSearchTabs(props: BoxProps) {
  const globalSearch = useGlobalSearchContext();

  if (globalSearch.tabs.length <= 1 || (!globalSearch.hasResults && !globalSearch.isNoResults)) {
    return;
  }

  return (
    <Box {...props}>
      <GlobalSearchTablist>
        {globalSearch.tabs.map((tab) => (
          <GlobalSearchTab key={tab} tab={`${globalSearch.prefix}${tab}`}>
            {tab}
          </GlobalSearchTab>
        ))}
      </GlobalSearchTablist>
    </Box>
  );
}

export function GlobalSearchTablist(props: PropsWithChildren) {
  return (
    <HStack
      spacing="0"
      as={TabList}
      width="full"
      borderBottom="1px"
      borderColor={useColorModeValue('gray.200', 'gray.600')}
      overflowY="auto"
    >
      {props.children}
    </HStack>
  );
}

export function GlobalSearchTab(props: PropsWithChildren<{ tab: string }>) {
  return (
    <Box
      as={Tab}
      id={props.tab}
      px="3"
      py="2"
      color={useColorModeValue('gray.400', 'gray.400')}
      fontSize="sm"
      whiteSpace="nowrap"
      borderBottomWidth="2px"
      borderColor="transparent"
      _selected={{
        color: useColorModeValue('gray.700', 'gray.200'),
        borderColor: useColorModeValue('blue.500', 'blue.200'),
      }}
      __css={{
        '&[data-active-item]': { backgroundColor: useColorModeValue('gray.100', 'gray.600') },
      }}
    >
      {props.children}
    </Box>
  );
}

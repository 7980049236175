/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type GetOrganizationEvidencesForBulkDownloadQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['uuid']['input'];
}>;

export type GetOrganizationEvidencesForBulkDownloadQuery = {
  __typename?: 'query_root';
  evidences: Array<{
    __typename?: 'evidences';
    id: string;
    name?: string;
    internal_id?: string;
    evidence_versions: Array<{
      __typename?: 'evidence_versions';
      url?: string;
      evidence_version_file?: {
        __typename?: 'evidence_version_file';
        file: { __typename?: 'files'; id: string; name?: string; mimeType?: string; size?: number };
      };
    }>;
  }>;
};

export type EvidenceForDownloadFragment = {
  __typename?: 'evidences';
  id: string;
  name?: string;
  internal_id?: string;
  evidence_versions: Array<{
    __typename?: 'evidence_versions';
    url?: string;
    evidence_version_file?: {
      __typename?: 'evidence_version_file';
      file: { __typename?: 'files'; id: string; name?: string; mimeType?: string; size?: number };
    };
  }>;
};

export const EvidenceForDownloadFragmentDoc = `
    fragment EvidenceForDownload on evidences {
  id
  name
  internal_id
  evidence_versions(where: {is_current: {_eq: true}}) {
    url
    evidence_version_file {
      file {
        id
        name
        mimeType
        size
      }
    }
  }
}
    `;
export const GetOrganizationEvidencesForBulkDownloadDocument = `
    query GetOrganizationEvidencesForBulkDownload($organizationId: uuid!) {
  evidences(
    where: {organization_id: {_eq: $organizationId}}
    order_by: {internal_id: asc}
  ) {
    ...EvidenceForDownload
  }
}
    ${EvidenceForDownloadFragmentDoc}` as string &
  TypedDocumentNode<
    GetOrganizationEvidencesForBulkDownloadQuery,
    GetOrganizationEvidencesForBulkDownloadQueryVariables
  >;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetOrganizationEvidencesForBulkDownload: build.query<
      GetOrganizationEvidencesForBulkDownloadQuery,
      GetOrganizationEvidencesForBulkDownloadQueryVariables
    >({
      query: (variables) => ({
        document: GetOrganizationEvidencesForBulkDownloadDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useGetOrganizationEvidencesForBulkDownloadQuery,
  useLazyGetOrganizationEvidencesForBulkDownloadQuery,
} = injectedRtkApi;

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { FieldValueFragmentDoc } from './FieldValueFragments.generated';
export type ControlDetailsFragment = {
  __typename?: 'controls';
  id: string;
  name?: string;
  description?: string;
  status: ClientTypes.ControlStatus;
  internal_id?: string;
  assignee_id?: string;
  frequency: Types.Control_Frequencies_Enum;
  priority?: Types.Control_Priorities_Enum;
  irrelevant: boolean;
  organization_id: string;
  programs: Array<{ __typename?: 'program_controls'; program_id: string }>;
  groups: Array<{
    __typename?: 'control_groups';
    group: { __typename?: 'groups'; id: string; name: string };
  }>;
  criterias: Array<{
    __typename?: 'control_criteria';
    criteria: { __typename?: 'criteria'; id: string; name: string };
  }>;
  custom_tags: Array<{
    __typename?: 'control_tags';
    tag: { __typename?: 'tags'; id: string; name: string };
  }>;
  categories: Array<{
    __typename?: 'control_categories';
    category: { __typename?: 'categories'; id: string; name: string };
  }>;
  field_values: Array<{
    __typename?: 'field_values';
    field_config_id: string;
    entity_name: Types.Field_Entities_Enum;
    field_type: Types.Field_Types_Enum;
    text_field_value?: { __typename?: 'text_field_values'; field_value_id: string; value: string };
    select_field_value?: {
      __typename?: 'select_field_values';
      field_value_id: string;
      select_field_option_id: string;
    };
  }>;
};

export const ControlDetailsFragmentDoc = `
    fragment ControlDetails on controls {
  id
  name
  description
  status
  internal_id
  assignee_id
  frequency
  priority
  irrelevant
  organization_id
  programs: program_controls {
    program_id
  }
  groups {
    group {
      id
      name
    }
  }
  criterias {
    criteria {
      id
      name
    }
  }
  custom_tags {
    tag {
      id
      name
    }
  }
  categories {
    category {
      id
      name
    }
  }
  field_values {
    ...FieldValue
  }
}
    `;

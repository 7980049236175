import { Flex, Icon, Stack, Text } from '@chakra-ui/react';
import { LockClosedIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';

export const ConfidentialBanner = () => {
  const { t } = useTranslation();

  return (
    <Flex py={6} direction="column" justifyContent="center" alignItems="center">
      <Flex alignItems="center" justifyContent="center" w={12} h={12} bg="blue.50" rounded="full">
        <Icon
          as={LockClosedIcon}
          color="blue.600"
          strokeWidth={2}
          boxSize={9}
          p={2}
          bg="blue.100"
          rounded="full"
        />
      </Flex>
      <Stack mt={4} as={Flex} spacing={1} alignItems="center">
        <Text fontWeight="semibold">{t('evidences.confidential.upperText')}</Text>
        <Text fontSize="sm" color="gray.500" w={'300px'} align="center">
          {t('evidences.confidential.lowerText')}
        </Text>
      </Stack>
    </Flex>
  );
};

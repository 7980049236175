import { Stack } from '@chakra-ui/react';
import { useGetRiskClassificationsQuery } from '@main/graphql/features/RiskClassifications.generated';
import { useUpdateRiskMutation } from '@main/graphql/mutations/UpdateRisk.generated';
import { createSorter } from '@main/shared/utils';
import { DrawerProperty, EditableTag } from '@main/ui';
import { TagIcon } from '@main/ui/icons';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import type { Classification } from '../settings/organization/risks/classifications/table';
import { getCurrentUserSelectedOrgId, getCurrentUserSelectedOrgRole } from '../user/slice';
import { useGetRiskQuery } from './get-risk.generated';
import { useUpdateRiskHandler } from './use-update-risk-handler';

function getAvailableOptions(options: Classification[]) {
  return options.map((option) => ({
    value: option.id,
    label: option.name,
    colorScheme: 'purple',
  }));
}

function getCurrentOption(option: string) {
  return {
    value: option,
    label: option,
    colorScheme: 'purple',
  };
}

export const RiskLevelsTab = ({ riskId }: { riskId: string }) => {
  const { t } = useTranslation();
  const organizationId = useAppSelector(getCurrentUserSelectedOrgId);
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canUserUpdateRisks = userRole.permissionMap?.write_risks;

  const [updateRisk] = useUpdateRiskMutation();
  const updateRiskHandler = useUpdateRiskHandler(riskId);

  const sorter = useMemo(() => createSorter(), []);
  const { impacts, likelihoods } = useGetRiskClassificationsQuery(
    { organizationId },
    {
      selectFromResult: ({ data }) => ({
        impacts: data?.impacts.toSorted(sorter) ?? [],
        likelihoods: data?.likelihoods.toSorted(sorter) ?? [],
      }),
    },
  );
  const { data: riskData } = useGetRiskQuery({ risk_id: riskId });

  const risk = riskData?.risks_by_pk;

  if (!risk) {
    return null;
  }

  return (
    <Stack spacing={2.5}>
      <DrawerProperty isReadOnly={!canUserUpdateRisks}>
        <DrawerProperty.Label icon={TagIcon}>
          {t('risks.levels.inherentRiskImpact')}
        </DrawerProperty.Label>
        <DrawerProperty.Content>
          <EditableTag
            value={risk.inherent_impact && getCurrentOption(risk.inherent_impact.name)}
            options={getAvailableOptions(impacts)}
            placeholder={t('risks.details.placeholder.inherentImpact')}
            onChange={(option) =>
              updateRiskHandler(
                updateRisk({
                  id: risk.id,
                  risk_input: {
                    inherent_impact_id: option ? option.value : null,
                  },
                }),
              )
            }
          />
        </DrawerProperty.Content>
      </DrawerProperty>

      <DrawerProperty isReadOnly={!canUserUpdateRisks}>
        <DrawerProperty.Label icon={TagIcon}>
          {t('risks.levels.inherentRiskLikelihood')}
        </DrawerProperty.Label>
        <DrawerProperty.Content>
          <EditableTag
            value={risk.inherent_likelihood && getCurrentOption(risk.inherent_likelihood.name)}
            options={getAvailableOptions(likelihoods)}
            placeholder={t('risks.details.placeholder.inherentLikelihood')}
            onChange={(option) =>
              updateRiskHandler(
                updateRisk({
                  id: risk.id,
                  risk_input: {
                    inherent_likelihood_id: option ? option.value : null,
                  },
                }),
              )
            }
          />
        </DrawerProperty.Content>
      </DrawerProperty>
      <DrawerProperty isReadOnly={!canUserUpdateRisks}>
        <DrawerProperty.Label icon={TagIcon}>
          {t('risks.levels.residualRiskImpact')}
        </DrawerProperty.Label>
        <DrawerProperty.Content>
          <EditableTag
            value={risk.residual_impact && getCurrentOption(risk.residual_impact.name)}
            options={getAvailableOptions(impacts)}
            placeholder={t('risks.details.placeholder.residualImpact')}
            onChange={(option) =>
              updateRiskHandler(
                updateRisk({
                  id: risk.id,
                  risk_input: {
                    residual_impact_id: option ? option.value : null,
                  },
                }),
              )
            }
          />
        </DrawerProperty.Content>
      </DrawerProperty>
      <DrawerProperty isReadOnly={!canUserUpdateRisks}>
        <DrawerProperty.Label icon={TagIcon}>
          {t('risks.levels.residualRiskLikelihood')}
        </DrawerProperty.Label>
        <DrawerProperty.Content>
          <EditableTag
            value={risk.residual_likelihood && getCurrentOption(risk.residual_likelihood.name)}
            options={getAvailableOptions(likelihoods)}
            placeholder={t('risks.details.placeholder.residualLikelihood')}
            onChange={(option) =>
              updateRiskHandler(
                updateRisk({
                  id: risk.id,
                  risk_input: {
                    residual_likelihood_id: option ? option.value : null,
                  },
                }),
              )
            }
          />
        </DrawerProperty.Content>
      </DrawerProperty>
    </Stack>
  );
};

import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  usePrevious,
} from '@chakra-ui/react';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

interface BreakingChange {
  breakingChangeCounter: number;
}

export const breakingChangeApi = createApi({
  reducerPath: 'breakingChangeApi',
  baseQuery: fetchBaseQuery({ baseUrl: '/' }),
  endpoints: (builder) => ({
    getBreakingChangeCounter: builder.query<BreakingChange, { path: string }>({
      queryFn: async ({ path }) => {
        try {
          const res = await fetch(path);
          const result = (await res.json()) as BreakingChange;

          if (!res.ok) {
            const errorData = await res.text();
            throw new Error(errorData);
          }
          return { data: result };
        } catch (error) {
          const typedError = error as Error;
          throw typedError;
        }
      },
    }),
  }),
});

const { useGetBreakingChangeCounterQuery } = breakingChangeApi;

const useBreakingChangeDetected = (path: string) => {
  const { data } = useGetBreakingChangeCounterQuery(
    { path },
    {
      pollingInterval: 10_000,
    },
  );
  const breakingChangeCounter = data?.breakingChangeCounter;
  const previousBreakingChangeCounter = usePrevious(data?.breakingChangeCounter);
  const isBreakingChange =
    breakingChangeCounter !== undefined &&
    previousBreakingChangeCounter !== undefined &&
    breakingChangeCounter > previousBreakingChangeCounter;

  return { isBreakingChange };
};

export const BreakingChangeDetector = ({ path }: { path: string }) => {
  const { isBreakingChange } = useBreakingChangeDetected(path);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();

  useEffect(() => {
    if (isBreakingChange) {
      onOpen();
    }
  }, [isBreakingChange, onOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnEsc={false} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="lg" color="gray.700">
          {t('breakingChanges.heading')}
        </ModalHeader>
        <ModalBody>{t('breakingChanges.body')}</ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" onClick={() => window.location.reload()}>
            {t('breakingChanges.refreshBtn')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

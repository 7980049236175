import { isNonNullable } from '@main/shared/utils';
import { HBarChart, HBarProps } from '@main/ui';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { getCurrentUserSelectedOrgId } from '../user/slice';
import { DashboardStatsQuery, useDashboardStatsQuery } from './dashboard.generated';

export const ValidControlsByOwner = () => {
  const { t } = useTranslation();
  const orgId = useAppSelector(getCurrentUserSelectedOrgId);
  const { data, isLoading } = useDashboardStatsQuery(
    { orgId },
    { refetchOnMountOrArgChange: true },
  );
  const bars = data?.user_controls_stats_view.map(decodeStat).filter(isNonNullable) || [];

  return (
    <HBarChart
      h="340px"
      heading={t('dashboard.validControlsByOwner.heading')}
      isLoading={isLoading}
      bars={bars}
      loadingState={{
        barsCount: 4,
      }}
      emptyState={{
        heading: t('dashboard.validControlsByOwner.emptyState.heading'),
        subheading: t('dashboard.validControlsByOwner.emptyState.subheading'),
      }}
    />
  );
};

function decodeStat(
  stat: DashboardStatsQuery['user_controls_stats_view'][number],
): HBarProps | null {
  if (!stat.user) {
    return null;
  }

  return {
    key: stat.user.id,
    label: stat.user.displayName,
    value: stat.valid_controls_ratio || 0,
  };
}

import { EvidenceIntegrationType } from '@main/graphql/client-scalars';
import { Integration_Names_Enum } from '@main/graphql/types.generated';
import { IntegrationConfigSchemas } from '@main/integrations/shared';
import { z } from 'zod';

export const JiraConfigSchemas: IntegrationConfigSchemas<Integration_Names_Enum.Jira> = {
  name: Integration_Names_Enum.Jira,

  orgConfig: z.object({
    url: z.string().url().describe('API Base URL'),
    email: z.string().email().describe('Client Email'),
    apiToken: z.string().min(1).describe('API Token'),
  }),

  evidenceConfig: z.discriminatedUnion('type', [
    z.object({
      type: z.literal(EvidenceIntegrationType.IssuesExport),
      jqlQuery: z.string(),
    }),
  ]),
};

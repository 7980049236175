import { EvidenceIntegrationType } from '@main/graphql/client-scalars';
import { Integration_Names_Enum } from '@main/graphql/types.generated';
import { IntegrationConfigSchemas } from '@main/integrations/shared';
import { z } from 'zod';

export const CrowdstrikeConfigSchemas: IntegrationConfigSchemas<Integration_Names_Enum.Crowdstrike> =
  {
    name: Integration_Names_Enum.Crowdstrike,

    orgConfig: z.object({
      baseUrl: z.string().url().describe('API Base URL'),
      clientId: z.string().min(1).describe('Client ID'),
      clientSecret: z.string().min(1).describe('Client Secret'),
    }),

    evidenceConfig: z.discriminatedUnion('type', [
      z.object({
        type: z.literal(EvidenceIntegrationType.AntiMalware),
      }),
    ]),
  };

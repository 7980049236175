/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type AddVendorDocumentsMutationVariables = Types.Exact<{
  documents_input: Array<Types.Vendor_Documents_Insert_Input> | Types.Vendor_Documents_Insert_Input;
}>;

export type AddVendorDocumentsMutation = {
  __typename?: 'mutation_root';
  insert_vendor_documents?: {
    __typename?: 'vendor_documents_mutation_response';
    affected_rows: number;
  };
};

export const AddVendorDocumentsDocument = `
    mutation AddVendorDocuments($documents_input: [vendor_documents_insert_input!]!) {
  insert_vendor_documents(
    objects: $documents_input
    on_conflict: {constraint: vendor_documents_pkey, update_columns: []}
  ) {
    affected_rows
  }
}
    ` as string &
  TypedDocumentNode<AddVendorDocumentsMutation, AddVendorDocumentsMutationVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    AddVendorDocuments: build.mutation<
      AddVendorDocumentsMutation,
      AddVendorDocumentsMutationVariables
    >({
      query: (variables) => ({ document: AddVendorDocumentsDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useAddVendorDocumentsMutation } = injectedRtkApi;

import { ToastId, useToast } from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { useUpdateReportMutation } from '@main/graphql/mutations/UpdateReport.generated';
import { useGetReportByIdQuery } from '@main/graphql/queries/GetReportById.generated';
import { useGetReportsForOrganizationQuery } from '@main/graphql/queries/GetReportsForOrganization.generated';
import { toError } from '@main/shared/utils';
import { errorToast, isValidColumnFilter } from '@main/ui';
import { useParams } from '@tanstack/react-router';
import { ColumnDef } from '@tanstack/react-table';
import useDebounce from 'ahooks/es/useDebounce';
import useUpdateEffect from 'ahooks/es/useUpdateEffect';
import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { getCurrentUserSelectedOrg } from '../user/slice';
import { getReportById } from './slice';

/**
 * Hook that save filter change to db and showing success toast
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useUpdateReportHandler<TData>({ columns }: { columns: ColumnDef<TData, any>[] }) {
  const { t } = useTranslation();
  const [updateReport] = useUpdateReportMutation();
  const organization = useAppSelector(getCurrentUserSelectedOrg);
  const { refetch: refetchReportsList } = useGetReportsForOrganizationQuery({
    organizationId: organization.id,
  });
  const params = useParams({ from: '/reports/$reportId' });
  const reportId = params.reportId;
  const { refetch: refetchReport } = useGetReportByIdQuery({
    id: reportId,
  });
  const successToastIdRef = useRef<ToastId>();
  const toast = useToast();

  const reportData = useAppSelector((state) => getReportById(state, reportId));
  const [columnFilters, setColumnFilters] = useState(
    () =>
      reportData?.filters.filter((columnFilter) => isValidColumnFilter(columns, columnFilter)) ||
      [],
  );
  const debouncedColumnFilters = useDebounce(columnFilters, { wait: 500, leading: true });
  const [sorting, setSorting] = useState(reportData?.sorting || []);

  // Ref to track whether the current update is due to column changes
  const isColumnUpdateRef = useRef(false);

  const updateReportFilters = useCallback(
    async (newFilters: typeof columnFilters, newSorting: typeof sorting, reportId: string) => {
      try {
        await updateReport({
          id: reportId,
          reportInput: {
            filters: newFilters,
            sorting: newSorting,
          },
        }).unwrap();
        successToastIdRef.current && toast.close(successToastIdRef.current);
        successToastIdRef.current = toast({
          title: t('reports.updateToast.success'),
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        await Promise.all([refetchReport(), refetchReportsList()]);
      } catch (error) {
        errorToast(t('reports.updateToast.failure'));
        datadogLogs.logger.error('Report update failed', {}, toError(error));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updateReport, toast, refetchReportsList],
  );

  useUpdateEffect(() => {
    if (isColumnUpdateRef.current) {
      // Skip the first update caused by the columns change
      isColumnUpdateRef.current = false;
      return;
    }

    updateReportFilters(debouncedColumnFilters, sorting, reportId);
  }, [debouncedColumnFilters, sorting, reportId, updateReportFilters]);

  // when columns update because of custom fields bring new filters at later stage in each report card type component,
  // initial filter would not have all the columns
  useUpdateEffect(() => {
    setColumnFilters(
      reportData?.filters.filter((columnFilter) => isValidColumnFilter(columns, columnFilter)) ||
        [],
    );
    setSorting(reportData?.sorting || []);

    isColumnUpdateRef.current = true;
  }, [columns]);

  return {
    filters: [columnFilters, setColumnFilters],
    sorting: [sorting, setSorting],
  } as const;
}

import { datadogLogs } from '@datadog/browser-logs';
import { useSetSelectFieldValuesMutation } from '@main/graphql/features/CustomFields.generated';
import { SetSelectFieldValuesInput } from '@main/graphql/types.generated';
import { toError } from '@main/shared/utils';
import { errorToast } from '@main/ui';
import { useTranslation } from 'react-i18next';

export function useSetSelectFieldValues() {
  const { t } = useTranslation();
  const [setSelectFieldValues] = useSetSelectFieldValuesMutation();

  return async (input: SetSelectFieldValuesInput) => {
    try {
      await setSelectFieldValues({ input }).unwrap();
    } catch (error) {
      errorToast(t('errorMessages.updateFailed', { entity: t('entities.customField') }));
      datadogLogs.logger.error('Select field value update failed', {}, toError(error));
    }
  };
}

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type RemoveControlGroupTagMutationVariables = Types.Exact<{
  groupId: Types.Scalars['uuid']['input'];
  controlId: Types.Scalars['uuid']['input'];
}>;

export type RemoveControlGroupTagMutation = {
  __typename?: 'mutation_root';
  delete_control_groups_by_pk?: {
    __typename?: 'control_groups';
    group_id: string;
    control_id: string;
  };
};

export const RemoveControlGroupTagDocument = `
    mutation RemoveControlGroupTag($groupId: uuid!, $controlId: uuid!) {
  delete_control_groups_by_pk(group_id: $groupId, control_id: $controlId) {
    group_id
    control_id
  }
}
    ` as string &
  TypedDocumentNode<RemoveControlGroupTagMutation, RemoveControlGroupTagMutationVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    RemoveControlGroupTag: build.mutation<
      RemoveControlGroupTagMutation,
      RemoveControlGroupTagMutationVariables
    >({
      query: (variables) => ({ document: RemoveControlGroupTagDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useRemoveControlGroupTagMutation } = injectedRtkApi;

import { Route } from '@tanstack/react-router';

import { Mfa } from '../../../features/user/mfa';
import { accountSettingsLayoutRoute } from './layout';

const Profile = () => {
  return <Mfa />;
};

export const account2faSettingsRoute = new Route({
  getParentRoute: () => accountSettingsLayoutRoute,
  path: '/2fa',
  component: Profile,
});

import { Flex, FlexProps, forwardRef } from '@chakra-ui/react';

import { whenNotSelecting } from '../../../utils/selection';

export const CellContainer = forwardRef<FlexProps, 'div'>(({ onClick, ...props }, ref) => {
  return (
    <Flex
      ref={ref}
      minH={12}
      px={4}
      py={2}
      gap={2}
      align="center"
      overflowX="hidden"
      cursor={onClick && 'pointer'}
      onClick={onClick && whenNotSelecting(onClick)}
      w={'full'}
      {...props}
    />
  );
});

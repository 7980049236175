import { Card, CardBody, Flex } from '@chakra-ui/react';
import { Integration_Names_Enum } from '@main/graphql/types.generated';
import { useTranslation } from 'react-i18next';

import { IntegrationDetails } from './integration-details';
import { IntegrationLogo } from './integration-logo';

export type IntegrationCardProps = {
  name?: Integration_Names_Enum;
  disabled?: boolean;
};

export const IntegrationCard = ({ name, disabled }: IntegrationCardProps) => {
  const { t } = useTranslation('integrations');
  const helpMessage = disabled ? t('disabledMessage') : t('enabledMessage');

  if (!name) {
    return null;
  }

  return (
    <Card>
      <CardBody>
        <Flex align="start" gap={4}>
          <IntegrationLogo name={name} />
          <IntegrationDetails name={name} helpMessage={helpMessage} />
        </Flex>
      </CardBody>
    </Card>
  );
};

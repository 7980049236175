import { CodeHighlightNode, CodeNode } from '@lexical/code';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { ListItemNode, ListNode } from '@lexical/list';
import { HorizontalRuleNode } from '@lexical/react/LexicalHorizontalRuleNode';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';

export const WysiwygNodes = [
  CodeHighlightNode,
  CodeNode,
  AutoLinkNode,
  LinkNode,
  ListItemNode,
  ListNode,
  HorizontalRuleNode,
  HeadingNode,
  QuoteNode,
  TableNode,
  TableRowNode,
  TableCellNode,
];

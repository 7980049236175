/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { EvidenceProgramFragmentDoc } from '../fragments/EvidenceProgramFragment.generated';
import { api } from '@main/graphql/shared-api';
export type GetEvidenceByIdQueryVariables = Types.Exact<{
  evidence_id: Types.Scalars['uuid']['input'];
}>;

export type GetEvidenceByIdQuery = {
  __typename?: 'query_root';
  evidences_by_pk?: {
    __typename?: 'evidences';
    id: string;
    is_confidential: boolean;
    name?: string;
    description?: string;
    created_at: string;
    updated_at: string;
    internal_id?: string;
    organization_id: string;
    owner_id?: string;
    owner?: { __typename?: 'users'; id: string; displayName: string; avatarUrl: string };
    evidence_versions: Array<{
      __typename?: 'evidence_versions';
      id: string;
      evidence_id: string;
      created_by?: string;
      url?: string;
      validity_start: string;
      evidence_version_file?: {
        __typename?: 'evidence_version_file';
        evidence_version_id: string;
        file_id: string;
      };
    }>;
    tags: Array<{
      __typename?: 'evidence_tags';
      id: string;
      tag: { __typename?: 'organization_evidence_tags'; id: string; name: string };
    }>;
    evidence_integration?: {
      __typename?: 'evidence_integrations';
      id: string;
      organization_integration: {
        __typename?: 'organization_integrations';
        id: string;
        disabled: boolean;
        integration: {
          __typename?: 'integrations';
          id: string;
          disabled: boolean;
          name: Types.Integration_Names_Enum;
        };
      };
    };
    evidence_policy?: {
      __typename?: 'evidence_policies';
      id: string;
      policy: { __typename?: 'policies'; id: string; name?: string };
    };
    acl: Array<{ __typename?: 'evidence_acl'; id: string; user_id: string }>;
    control_evidences: Array<{
      __typename?: 'control_evidences';
      status: ClientTypes.ControlEvidenceStatus;
      control: {
        __typename?: 'controls';
        programs: Array<{ __typename?: 'program_controls'; program_id: string }>;
      };
    }>;
  };
};

export const GetEvidenceByIdDocument = `
    query GetEvidenceById($evidence_id: uuid!) {
  evidences_by_pk(id: $evidence_id) {
    id
    is_confidential
    name
    description
    created_at
    updated_at
    internal_id
    organization_id
    owner_id
    owner: user {
      id
      displayName
      avatarUrl
    }
    evidence_versions {
      id
      evidence_id
      created_by
      url
      validity_start
      evidence_version_file {
        evidence_version_id
        file_id
      }
    }
    tags {
      id
      tag {
        id
        name
      }
    }
    evidence_integration {
      id
      organization_integration {
        id
        disabled
        integration {
          id
          disabled
          name
        }
      }
    }
    evidence_policy {
      id
      policy {
        id
        name
      }
    }
    acl(order_by: {created_at: asc}) {
      id
      user_id
    }
    ...EvidenceProgram
  }
}
    ${EvidenceProgramFragmentDoc}` as string &
  TypedDocumentNode<GetEvidenceByIdQuery, GetEvidenceByIdQueryVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetEvidenceById: build.query<GetEvidenceByIdQuery, GetEvidenceByIdQueryVariables>({
      query: (variables) => ({ document: GetEvidenceByIdDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetEvidenceByIdQuery, useLazyGetEvidenceByIdQuery } = injectedRtkApi;

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type GetOrganizationInvitationsQueryVariables = Types.Exact<{
  orgId: Types.Scalars['uuid']['input'];
}>;

export type GetOrganizationInvitationsQuery = {
  __typename?: 'query_root';
  invitations: Array<{
    __typename?: 'invitations';
    id: string;
    email: string;
    role: { __typename?: 'roles'; id: string; name: string; system_role?: Types.System_Roles_Enum };
  }>;
};

export const GetOrganizationInvitationsDocument = `
    query GetOrganizationInvitations($orgId: uuid!) {
  invitations(where: {is_joined: {_eq: false}, organization_id: {_eq: $orgId}}) {
    id
    email
    role {
      id
      name
      system_role
    }
  }
}
    ` as string &
  TypedDocumentNode<GetOrganizationInvitationsQuery, GetOrganizationInvitationsQueryVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetOrganizationInvitations: build.query<
      GetOrganizationInvitationsQuery,
      GetOrganizationInvitationsQueryVariables
    >({
      query: (variables) => ({ document: GetOrganizationInvitationsDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetOrganizationInvitationsQuery, useLazyGetOrganizationInvitationsQuery } =
  injectedRtkApi;

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type VendorSummaryQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['uuid']['input'];
}>;

export type VendorSummaryQuery = {
  __typename?: 'query_root';
  vendors_summary_view: Array<{
    __typename?: 'vendors_summary_view';
    pending_count?: number;
    accepted_count?: number;
    rejected_count?: number;
  }>;
};

export const VendorSummaryDocument = `
    query VendorSummary($organizationId: uuid!) {
  vendors_summary_view(where: {organization_id: {_eq: $organizationId}}) {
    pending_count
    accepted_count
    rejected_count
  }
}
    ` as string & TypedDocumentNode<VendorSummaryQuery, VendorSummaryQueryVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    VendorSummary: build.query<VendorSummaryQuery, VendorSummaryQueryVariables>({
      query: (variables) => ({ document: VendorSummaryDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useVendorSummaryQuery, useLazyVendorSummaryQuery } = injectedRtkApi;

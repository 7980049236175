import { datadogLogs } from '@datadog/browser-logs';
import {
  BulkDownloaderStatus,
  createSWStreamSink,
  DownloadFile,
  FileStreamDownloader,
  NhostStreamDownloader,
  ServiceWorkerStreamSink,
  StreamSink,
  useBulkDownloader,
} from '@main/bulk-downloader';
import { toError } from '@main/shared/utils';
import { useNhostClient } from '@nhost/react';
import { ReactNode, useEffect, useMemo } from 'react';

import { AppDownloadFile, AppStreamDownloader } from './app-stream-downloader';

/** Defined in the App's vite config */
declare const __SW_DOWNLOADER_URL__: { url: string; type: WorkerType };

export interface AppDownloaderOptions {
  downloadId?: string;
  title?: ReactNode;
  progressTitle?(progress: number): ReactNode;
  successTitle?: ReactNode;
  errorTitle?: ReactNode;
}

export interface AppFilesFetcher {
  fetchFiles(): Promise<DownloadFile<AppDownloadFile>[]>;
}

export function useAppDownloader({
  downloadId,
  title,
  progressTitle,
  successTitle,
  errorTitle,
}: AppDownloaderOptions) {
  const nhost = useNhostClient();
  const streamDownloader = useMemo(
    () => new AppStreamDownloader(new NhostStreamDownloader(nhost), new FileStreamDownloader()),
    [nhost],
  );
  const logger = useMemo(
    () =>
      datadogLogs.createLogger(`bulk-download-${downloadId}`, {
        context: { id: downloadId },
        level: 'debug',
        handler: import.meta.env.PROD ? 'http' : 'console',
      }),
    [downloadId],
  );
  const bulkDownloader = useBulkDownloader({
    downloadId,
    streamDownloader,
    title,
    progressTitle,
    successTitle,
    errorTitle,
    logger,
  });
  const isDownloading = useMemo(
    () =>
      bulkDownloader.status === BulkDownloaderStatus.Downloading ||
      bulkDownloader.status === BulkDownloaderStatus.Paused,
    [bulkDownloader.status],
  );

  useEffect(() => logger.setContext({ id: bulkDownloader.id }), [bulkDownloader.id, logger]);

  return {
    ...bulkDownloader,
    isDownloading,
    async start(fileName: string, filesFetcher: AppFilesFetcher) {
      if (isDownloading) {
        return;
      }

      let streamSink: StreamSink | undefined;

      try {
        const filesRequest = filesFetcher.fetchFiles();
        const streamSinkRequest = createSWStreamSink({
          ...__SW_DOWNLOADER_URL__,
          scope: '/sw-downloader/',
          fileName,
          logger: bulkDownloader.logger,
        });

        const files = await filesRequest;
        const filesCount = files.length;
        streamSink = await streamSinkRequest;

        bulkDownloader.logger.log('Starting bulk download', {
          filesCount,
        });
        const startMark = performance.mark('bulk-download-start');

        const download = await bulkDownloader.start(files, streamSink);
        await download.whenDone();

        if (streamSink instanceof ServiceWorkerStreamSink) {
          await streamSink.finalize();
        }

        const endMark = performance.mark('bulk-download-end');
        const duration = performance.measure('bulk-download', {
          start: startMark.startTime,
          end: endMark.startTime,
        });
        bulkDownloader.logger.log('Bulk download completed', { duration: duration.duration });
      } catch (error) {
        if (
          (error instanceof DOMException && error.name === 'AbortError') ||
          (error instanceof Error &&
            [BulkDownloaderStatus.Canceled, BulkDownloaderStatus.Paused].includes(
              error.message as BulkDownloaderStatus,
            ))
        ) {
          return;
        }

        bulkDownloader.logger.error(
          'Bulk download failed',
          { id: bulkDownloader.id },
          toError(error),
        );

        try {
          await bulkDownloader.downloader?.cancel(error);
        } catch {
          /* empty */
        }
      } finally {
        if (streamSink instanceof ServiceWorkerStreamSink) {
          await streamSink.dispose();
        }
      }
    },
  };
}

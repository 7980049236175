/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type GetVendorTiersQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['uuid']['input'];
  nameFilter?: Types.InputMaybe<Types.String_Comparison_Exp>;
}>;

export type GetVendorTiersQuery = {
  __typename?: 'query_root';
  organization_vendor_tiers: Array<{
    __typename?: 'organization_vendor_tiers';
    id: string;
    name: string;
  }>;
};

export type CreateVendorTierMutationVariables = Types.Exact<{
  input: Types.Organization_Vendor_Tiers_Insert_Input;
}>;

export type CreateVendorTierMutation = {
  __typename?: 'mutation_root';
  insert_organization_vendor_tiers_one?: { __typename?: 'organization_vendor_tiers'; id: string };
};

export const GetVendorTiersDocument = `
    query GetVendorTiers($organizationId: uuid!, $nameFilter: String_comparison_exp = {}) {
  organization_vendor_tiers(
    order_by: {name: asc}
    where: {organization_id: {_eq: $organizationId}, name: $nameFilter}
  ) {
    id
    name
  }
}
    ` as string & TypedDocumentNode<GetVendorTiersQuery, GetVendorTiersQueryVariables>;
export const CreateVendorTierDocument = `
    mutation CreateVendorTier($input: organization_vendor_tiers_insert_input!) {
  insert_organization_vendor_tiers_one(object: $input) {
    id
  }
}
    ` as string & TypedDocumentNode<CreateVendorTierMutation, CreateVendorTierMutationVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetVendorTiers: build.query<GetVendorTiersQuery, GetVendorTiersQueryVariables>({
      query: (variables) => ({ document: GetVendorTiersDocument, variables }),
    }),
    CreateVendorTier: build.mutation<CreateVendorTierMutation, CreateVendorTierMutationVariables>({
      query: (variables) => ({ document: CreateVendorTierDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetVendorTiersQuery, useLazyGetVendorTiersQuery, useCreateVendorTierMutation } =
  injectedRtkApi;

import { Route } from '@tanstack/react-router';

import { UserRolesPage } from '../../../../features/settings/organization/roles/user-roles-page';
import { organizationRolesSettingsLayoutRoute } from './layout';

export const organizationRolesIndexSettingsRoute = new Route({
  getParentRoute: () => organizationRolesSettingsLayoutRoute,
  path: '/',
  component: UserRolesPage,
});

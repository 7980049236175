import { Alert, AlertIcon } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import {
  getCurrentUserOrgs,
  getCurrentUserSelectedOrgRole,
  getIsCurrentUserOrgMainOrg,
} from '../user/slice';

export const MainOrgBanner = ({ type }: { type: 'users' | 'auditors' }) => {
  const isCurrentUserOrgMainOrg = useAppSelector(getIsCurrentUserOrgMainOrg);
  const userOrgs = useAppSelector(getCurrentUserOrgs);
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canEditRole = userRole.permissionMap?.write_roles;
  const { t } = useTranslation();

  const showBannerForMainOrg = userOrgs.length > 1 && canEditRole && isCurrentUserOrgMainOrg;

  if (showBannerForMainOrg) {
    return (
      <Alert status="warning" variant="left-accent" marginBottom={10}>
        <AlertIcon />
        {type === 'users' ? t('users.bannerForMainOrg') : t('auditors.bannerForMainOrg')}
      </Alert>
    );
  }

  return null;
};

import { Flex, Skeleton, Text } from '@chakra-ui/react';
import { ChartBarIcon } from '@heroicons/react/24/outline';
import { DashboardCard, EmptyPlaceholder, PieChart, PieDatum } from '@main/ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { getCurrentUserSelectedOrgId } from '../user/slice';
import { useVendorSummaryQuery } from './vendor-summary.generated';

export interface VendorSummaryProps {
  data?: VendorSummaryData;
}

export function VendorSummary(props: VendorSummaryProps) {
  const { t } = useTranslation();

  const isLoading = props.data === undefined;
  const isEmpty = useMemo(
    () => (props.data ? Object.values(props.data).every((v: number) => v === 0) : false),
    [props.data],
  );
  const data = useMemo(() => props.data ?? { accepted: 0, pending: 0, rejected: 0 }, [props.data]);
  const total = useMemo(() => Object.values(data).reduce((acc, v: number) => acc + v, 0), [data]);

  const pieData = useMemo(
    () =>
      [
        {
          label: t('dashboard.vendorSummary.chart.pending'),
          value: data.pending,
          color: 'gray',
        },
        {
          label: t('dashboard.vendorSummary.chart.rejected'),
          value: data.rejected,
          color: 'red',
        },
        {
          label: t('dashboard.vendorSummary.chart.accepted'),
          value: data.accepted,
          color: 'green',
        },
      ] satisfies PieDatum[],
    [data, t],
  );

  return (
    <DashboardCard>
      <DashboardCard.Header heading={t('dashboard.vendorSummary.heading')} />
      <DashboardCard.Body minH={240}>
        <Skeleton
          isLoaded={!isLoading}
          h="full"
          w="full"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {isEmpty ? (
            <VendorSummaryEmpty />
          ) : (
            <PieChart data={pieData} halfMode>
              <Flex direction="column" alignItems={'center'} h="full" w="full" gap={5}>
                <PieChart.Graph>
                  <Text fontSize={'5xl'} fontWeight={700}>
                    {total}
                  </Text>
                </PieChart.Graph>
                <PieChart.Legend />
              </Flex>
            </PieChart>
          )}
        </Skeleton>
      </DashboardCard.Body>
    </DashboardCard>
  );
}

export interface VendorSummaryData {
  accepted: number;
  pending: number;
  rejected: number;
}

export function useVendorSummary(): VendorSummaryProps {
  const organizationId = useAppSelector(getCurrentUserSelectedOrgId);
  const { data, isFetching } = useVendorSummaryQuery({ organizationId });

  if (isFetching) {
    return {};
  }

  const summary = data?.vendors_summary_view[0];

  return {
    data: {
      pending: summary?.pending_count ?? 0,
      accepted: summary?.accepted_count ?? 0,
      rejected: summary?.rejected_count ?? 0,
    },
  };
}

function VendorSummaryEmpty() {
  const { t } = useTranslation();

  return (
    <EmptyPlaceholder>
      <EmptyPlaceholder.Icon as={ChartBarIcon} />
      <EmptyPlaceholder.Content>
        <EmptyPlaceholder.Heading>
          {t('dashboard.vendorSummary.emptyState.heading')}
        </EmptyPlaceholder.Heading>
        <EmptyPlaceholder.Subheading>
          {t('dashboard.vendorSummary.emptyState.subheading')}
        </EmptyPlaceholder.Subheading>
      </EmptyPlaceholder.Content>
    </EmptyPlaceholder>
  );
}

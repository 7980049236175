/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type GetProgramSummaryQueryVariables = Types.Exact<{
  programId: Types.Scalars['uuid']['input'];
  userId: Types.Scalars['uuid']['input'];
}>;

export type GetProgramSummaryQuery = {
  __typename?: 'query_root';
  countControls: {
    __typename?: 'controls_aggregate';
    aggregate?: { __typename?: 'controls_aggregate_fields'; count: number };
  };
  countValidControls: {
    __typename?: 'controls_aggregate';
    aggregate?: { __typename?: 'controls_aggregate_fields'; count: number };
  };
  myControls: {
    __typename?: 'controls_aggregate';
    aggregate?: { __typename?: 'controls_aggregate_fields'; count: number };
  };
  myValidControls: {
    __typename?: 'controls_aggregate';
    aggregate?: { __typename?: 'controls_aggregate_fields'; count: number };
  };
};

export const GetProgramSummaryDocument = `
    query GetProgramSummary($programId: uuid!, $userId: uuid!) {
  countControls: controls_aggregate(
    where: {_and: [{program_controls: {program_id: {_eq: $programId}}}, {irrelevant: {_neq: true}}]}
  ) {
    aggregate {
      count
    }
  }
  countValidControls: controls_aggregate(
    where: {_and: [{program_controls: {program_id: {_eq: $programId}}}, {_and: [{status: {_eq: "Valid"}}, {irrelevant: {_neq: true}}]}]}
  ) {
    aggregate {
      count
    }
  }
  myControls: controls_aggregate(
    where: {program_controls: {program_id: {_eq: $programId}}, irrelevant: {_neq: true}, assignee_id: {_eq: $userId}}
  ) {
    aggregate {
      count
    }
  }
  myValidControls: controls_aggregate(
    where: {program_controls: {program_id: {_eq: $programId}}, irrelevant: {_neq: true}, status: {_eq: "Valid"}, assignee_id: {_eq: $userId}}
  ) {
    aggregate {
      count
    }
  }
}
    ` as string & TypedDocumentNode<GetProgramSummaryQuery, GetProgramSummaryQueryVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetProgramSummary: build.query<GetProgramSummaryQuery, GetProgramSummaryQueryVariables>({
      query: (variables) => ({ document: GetProgramSummaryDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetProgramSummaryQuery, useLazyGetProgramSummaryQuery } = injectedRtkApi;

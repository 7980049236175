import { createGqlSubscriptionApi } from '@main/graphql/create-subscription-api';

import { api, GetRiskDocument } from './get-risk.generated';

export const { useGetRiskQuery: useGetRiskSubscription } = createGqlSubscriptionApi(
  api,
  'GetRisk',
  GetRiskDocument,
);

export * from './get-risk.generated';

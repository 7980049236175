/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { CommentsFragmentFragmentDoc } from '../comments/comments.fragment.generated';
import { UserNameEmailFragmentFragmentDoc } from '../../../../../libs/graphql/src/fragments/UserNameEmailFragment.generated';
import { FileInfoFragmentFragmentDoc } from '../../../../../libs/graphql/src/fragments/FileInfoFragment.generated';
import { FieldValueFragmentDoc } from '../../../../../libs/graphql/src/fragments/FieldValueFragments.generated';
import { api } from '@main/graphql/shared-api';
export type GetVendorQueryVariables = Types.Exact<{
  vendor_id: Types.Scalars['uuid']['input'];
}>;

export type GetVendorQuery = {
  __typename?: 'query_root';
  vendors_by_pk?: {
    __typename?: 'vendors';
    last_reviewed_date?: string;
    id: string;
    name?: string;
    approval_status?: Types.Vendor_Approval_Statuses_Enum;
    description?: string;
    organization_id: string;
    contact_email?: string;
    internal_id?: string;
    terminated_at?: string;
    review_frequency: Types.Frequencies_Enum;
    assessment_status: ClientTypes.VendorAssessmentStatus;
    vendors_comments: Array<{
      __typename?: 'comments';
      id: string;
      text: string;
      created_at: string;
      updated_at: string;
      user_id: string;
      comments_comment_uploads: Array<{
        __typename?: 'comment_uploads';
        id: string;
        comment_uploads_file: {
          __typename?: 'files';
          id: string;
          name?: string;
          size?: number;
          createdAt: string;
        };
      }>;
    }>;
    owner?: {
      __typename?: 'users';
      avatarUrl: string;
      id: string;
      email?: string;
      displayName: string;
    };
    documents: Array<{
      __typename?: 'vendor_documents';
      file?: { __typename?: 'files'; id: string; bucketId: string; name?: string; size?: number };
    }>;
    risk_level?: { __typename?: 'organization_vendor_risk_levels'; id: string; name: string };
    tier?: { __typename?: 'organization_vendor_tiers'; id: string; name: string };
    field_values: Array<{
      __typename?: 'field_values';
      field_config_id: string;
      entity_name: Types.Field_Entities_Enum;
      field_type: Types.Field_Types_Enum;
      text_field_value?: {
        __typename?: 'text_field_values';
        field_value_id: string;
        value: string;
      };
      select_field_value?: {
        __typename?: 'select_field_values';
        field_value_id: string;
        select_field_option_id: string;
      };
    }>;
  };
};

export type GetVendorActivityHistoryQueryVariables = Types.Exact<{
  vendor_id: Types.Scalars['uuid']['input'];
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type GetVendorActivityHistoryQuery = {
  __typename?: 'query_root';
  audit_vendor_history_view: Array<{
    __typename?: 'audit_vendor_history_view';
    user_id?: string;
    action_timestamp?: string;
    action: ClientTypes.AuditTableActions;
    table_name: ClientTypes.VendorHistoryTables;
    hasura_user: ClientTypes.AuditTableHasuraUser;
    changed_fields?: ClientTypes.VendorChangedFields;
    row_data: ClientTypes.VendorRowData;
  }>;
};

export const GetVendorDocument = `
    query GetVendor($vendor_id: uuid!) {
  vendors_by_pk(id: $vendor_id) {
    last_reviewed_date
    id
    name
    approval_status
    description
    organization_id
    contact_email
    internal_id
    terminated_at
    review_frequency
    assessment_status
    vendors_comments(order_by: {created_at: asc}) {
      ...CommentsFragment
    }
    owner {
      ...UserNameEmailFragment
      avatarUrl
    }
    documents {
      file {
        ...FileInfoFragment
      }
    }
    risk_level {
      id
      name
    }
    tier {
      id
      name
    }
    field_values {
      ...FieldValue
    }
  }
}
    ${CommentsFragmentFragmentDoc}
${UserNameEmailFragmentFragmentDoc}
${FileInfoFragmentFragmentDoc}
${FieldValueFragmentDoc}` as string & TypedDocumentNode<GetVendorQuery, GetVendorQueryVariables>;
export const GetVendorActivityHistoryDocument = `
    query GetVendorActivityHistory($vendor_id: uuid!, $limit: Int = 10, $offset: Int = 0) {
  audit_vendor_history_view(
    limit: $limit
    offset: $offset
    where: {vendor_id: {_eq: $vendor_id}}
    order_by: {action_timestamp: desc}
  ) {
    user_id
    action_timestamp
    action
    table_name
    hasura_user
    changed_fields
    row_data
  }
}
    ` as string &
  TypedDocumentNode<GetVendorActivityHistoryQuery, GetVendorActivityHistoryQueryVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetVendor: build.query<GetVendorQuery, GetVendorQueryVariables>({
      query: (variables) => ({ document: GetVendorDocument, variables }),
    }),
    GetVendorActivityHistory: build.query<
      GetVendorActivityHistoryQuery,
      GetVendorActivityHistoryQueryVariables
    >({
      query: (variables) => ({ document: GetVendorActivityHistoryDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useGetVendorQuery,
  useLazyGetVendorQuery,
  useGetVendorActivityHistoryQuery,
  useLazyGetVendorActivityHistoryQuery,
} = injectedRtkApi;

import { FormControl, FormLabel, Text } from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { useDeleteRiskDocumentsMutation } from '@main/graphql/mutations/DeleteRiskDocuments.generated';
import { useAddRiskDocumentsMutation } from '@main/graphql/mutations/InsertRiskDocuments.generated';
import { isNonNullable, toError } from '@main/shared/utils';
import {
  errorToast,
  FileUpload,
  useDownloadStorageFile,
  useEagerMultipleFilesUpload,
} from '@main/ui';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { getCurrentUserSelectedOrgRole } from '../user/slice';
import { useGetRiskQuery } from './get-risk.generated';

export const RiskDocumentsTab = ({
  riskId,
  onFilesChange,
}: {
  riskId: string;
  onFilesChange?: () => Promise<void>;
}) => {
  const { t } = useTranslation();
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canUpdateRisks = userRole.permissionMap?.write_risks;

  const [deleteDocument] = useDeleteRiskDocumentsMutation();
  const [uploadDocuments] = useAddRiskDocumentsMutation();

  const { data: riskData } = useGetRiskQuery({ risk_id: riskId });
  const risk = riskData?.risks_by_pk;

  const fileUpload = useEagerMultipleFilesUpload({
    files: risk?.documents.map(({ file }) => file).filter(isNonNullable) || [],
    onFileDownload: useDownloadStorageFile(),
    onFileDelete: async (fileId) => {
      try {
        await deleteDocument({ fileId, riskId }).unwrap();
        await onFilesChange?.();
      } catch (error) {
        errorToast(t('errorMessages.deleteFailed', { entity: t('entities.riskDocument') }));
        datadogLogs.logger.error(
          'Failed deleting risk document',
          { riskId, fileId },
          toError(error),
        );
      }
    },
    onFilesUpload: async ({ errors, files }) => {
      if (errors.length) {
        errorToast(t('errorMessages.uploadFailed', { entity: t('entities.riskDocument') }));
        for (const error of errors) {
          datadogLogs.logger.error(
            'Failed uploading risk document files',
            { riskId },
            toError(error),
          );
        }
        return;
      }

      const documents = files.map((file) => ({
        file_id: file.id,
        risk_id: riskId,
      }));
      try {
        await uploadDocuments({ documents_input: documents }).unwrap();
        await onFilesChange?.();
      } catch (e) {
        errorToast(t('errorMessages.uploadFailed', { entity: t('entities.riskDocument') }));
        datadogLogs.logger.error('Failed uploading risk documents', { documents }, toError(e));
      }
    },
  });

  return (
    <FormControl isReadOnly={!canUpdateRisks}>
      <FormLabel margin="0">{t('risks.documents.heading')}</FormLabel>
      <Text fontSize="xs" mb={2}>
        {t('risks.documents.subheading')}
      </Text>
      <FileUpload {...fileUpload}>
        <FileUpload.Dropzone />
      </FileUpload>
    </FormControl>
  );
};

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type CreateCriteriaTagMutationVariables = Types.Exact<{
  object: Types.Control_Criteria_Insert_Input;
}>;

export type CreateCriteriaTagMutation = {
  __typename?: 'mutation_root';
  insert_control_criteria_one?: { __typename?: 'control_criteria'; criteria_id: string };
};

export const CreateCriteriaTagDocument = `
    mutation CreateCriteriaTag($object: control_criteria_insert_input!) {
  insert_control_criteria_one(object: $object) {
    criteria_id
  }
}
    ` as string & TypedDocumentNode<CreateCriteriaTagMutation, CreateCriteriaTagMutationVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    CreateCriteriaTag: build.mutation<
      CreateCriteriaTagMutation,
      CreateCriteriaTagMutationVariables
    >({
      query: (variables) => ({ document: CreateCriteriaTagDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useCreateCriteriaTagMutation } = injectedRtkApi;

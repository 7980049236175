import { Box, Button, Flex, Stack, Text, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { useAiCreateEntityMutation } from '@main/graphql/features/AiCreateEntity.generated';
import { isGraphqlError, severityColorScheme, toError } from '@main/shared/utils';
import { errorToast, FindingCard, StatusTag, useDrawer, useMultiStateToast } from '@main/ui';
import { Trans, useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { CreateEvidenceVersionModal } from '../evidence/evidence-versions';
import { useIsUserAuthorizedToChangeEvidence } from '../evidence/utils';
import { CreateFindingRiskButton } from '../findings/create-risk';
import { CreateFindingTaskButton } from '../findings/create-task';
import { useToggleControlIgnoreFindingMutation } from '../findings/ManageFindings.generated';
import { ToggleFindingIgnoreStateButton } from '../findings/toggle-ignore-state';
import { getCurrentUserSelectedOrgRole } from '../user/slice';
import { api as getControlApi } from './get-control.generated';
import { ControlFinding, getControlFindings } from './slice';

export const ControlFindingsTab = ({ controlId }: { controlId: string }) => {
  const { t } = useTranslation();
  const drawer = useDrawer();
  const headingColor = useColorModeValue('gray.700', 'gray.300');
  const tipBgColor = useColorModeValue('white', 'gray.700');
  const { findings } = useAppSelector((state) => getControlFindings(state, controlId));
  const { toggleIgnoreState, createRisk, createTask } = useFindingActionsHandlers(controlId);
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canUpdateControl = userRole.permissionMap?.update_controls;
  const canCreateRisk = userRole.permissionMap?.write_risks;

  return (
    <Box>
      <Flex direction={'column'} gap={6}>
        <Text color={headingColor}>{t('controls.findings.description')}</Text>
        <Stack>
          {findings.map((controlFinding, index) => {
            const isIgnored = !!controlFinding.finding.ignored_at;
            const riskId = controlFinding.finding.risk_id;
            const taskId = controlFinding.finding.task_id;

            return (
              <FindingCard key={controlFinding.finding.id} isIgnored={isIgnored}>
                <FindingCard.Header
                  heading={t('controls.findings.finding', {
                    counter: index + 1,
                    total: findings.length,
                  })}
                >
                  <StatusTag colorScheme={severityColorScheme(controlFinding.severity)} size="sm">
                    {t(`controls.findings.type.${controlFinding.type}`)}
                  </StatusTag>
                </FindingCard.Header>

                <FindingCard.Actions>
                  {canCreateRisk && (
                    <CreateFindingRiskButton
                      onCreate={() => createRisk({ findingId: controlFinding.finding.id })}
                      onOpen={
                        riskId
                          ? () =>
                              drawer.open({
                                entity: 'risk',
                                entityId: riskId,
                              })
                          : undefined
                      }
                    />
                  )}
                  {canUpdateControl && (
                    <CreateFindingTaskButton
                      onCreate={() => createTask({ findingId: controlFinding.finding.id })}
                      onOpen={
                        taskId
                          ? () =>
                              drawer.open({
                                entity: 'task',
                                entityId: taskId,
                              })
                          : undefined
                      }
                    />
                  )}
                  {canUpdateControl && (
                    <ToggleFindingIgnoreStateButton
                      onToggle={() =>
                        toggleIgnoreState({ findingId: controlFinding.finding.id, isIgnored })
                      }
                    />
                  )}
                </FindingCard.Actions>

                <FindingCard.Body>
                  <ControlFindingContent finding={controlFinding} />
                </FindingCard.Body>
              </FindingCard>
            );
          })}
        </Stack>
      </Flex>
      <Flex position="fixed" bottom={0} bg={tipBgColor} pr={10} pt={2} pb={6} width="100%">
        <Text fontSize={'xs'} color="gray.400">
          {t('controls.findings.tip')}
        </Text>
      </Flex>
    </Box>
  );
};

const ControlFindingContent = ({ finding }: { finding: ControlFinding }) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const permission = useIsUserAuthorizedToChangeEvidence(
    finding.type === 'atRiskEvidence' || finding.type === 'expiredEvidence'
      ? finding.evidence
      : undefined,
  )
    ? 'write'
    : 'read';

  const headingColor = useColorModeValue('gray.700', 'gray.300');
  const descriptionColor = useColorModeValue('gray.600', 'gray.400');
  const linkColor = useColorModeValue('blue.600', 'blue.200');

  if (finding.type === 'aiRecommendation') {
    return (
      <>
        <Text color={headingColor} fontSize={'sm'} fontWeight={'semibold'}>
          {finding.finding.control_ai_review_assessment?.criteria_heading}
        </Text>
        <Text color={descriptionColor} fontSize={'sm'}>
          {finding.finding.control_ai_review_assessment?.criteria_suggestion}
        </Text>
      </>
    );
  }

  return (
    <>
      <Text color={headingColor} fontSize={'sm'} fontWeight={'semibold'}>
        {t(`controls.findings.title.${finding.type}`)}
      </Text>
      <Text fontSize={'sm'} color={descriptionColor}>
        <Trans
          i18nKey={`controls.findings.reason.${finding.type}.${permission}`}
          components={{
            a: (
              <Button
                fontSize={'sm'}
                fontWeight={'normal'}
                variant={'link'}
                color={linkColor}
                onClick={onOpen}
              />
            ),
          }}
          values={{
            evidenceName: finding.evidence.name,
          }}
        />
      </Text>
      <CreateEvidenceVersionModal
        evidenceId={finding.evidence.id}
        isOpen={isOpen}
        onCreate={() => {
          onClose();
        }}
        onClose={onClose}
      />
    </>
  );
};

const useFindingActionsHandlers = (controlId: string) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const toast = useMultiStateToast();
  const drawer = useDrawer();

  const [toggleIgnoreFinding] = useToggleControlIgnoreFindingMutation();
  const [aiCreateEntity] = useAiCreateEntityMutation();

  const toastTextColor = useColorModeValue('green.800', 'green.600');

  const toggleIgnoreState = async ({
    findingId,
    isIgnored,
  }: {
    findingId: string;
    isIgnored: boolean;
  }) => {
    const patchResult = dispatch(
      getControlApi.util.updateQueryData('GetControl', { controlId }, (draft) => {
        for (const draftFinding of draft.controls_by_pk?.findings ?? []) {
          if (draftFinding.id === findingId) {
            draftFinding.ignored_at = isIgnored ? undefined : new Date().toISOString();
          }
        }
      }),
    );
    try {
      await toggleIgnoreFinding({
        findingId: findingId,
        ignored_at: isIgnored ? null : 'now()',
      }).unwrap();
    } catch (error) {
      errorToast(t('errorMessages.updateFailed', { entity: t('entities.finding') }));
      datadogLogs.logger.error('Toggling ignore state of the finding failed', {}, toError(error));
      patchResult.undo();
    }
  };

  const createRisk = async ({ findingId }: { findingId: string }) => {
    toast.loading({ title: t('controls.findings.riskIsBeingCreated') });

    try {
      const { ai_create_entity } = await aiCreateEntity({
        input: { entity: 'risk-control-finding', findingId },
      }).unwrap();

      toast.success({
        title: (
          <Trans
            i18nKey="controls.findings.newRiskCreated"
            values={{
              internalId: ai_create_entity?.internalId,
            }}
            components={{
              p: <Text display={'inline'} color={toastTextColor} />,
              a: (
                <Button
                  variant="link"
                  color={toastTextColor}
                  onClick={() => {
                    drawer.open({
                      entity: 'risk',
                      entityId: ai_create_entity?.entityId ?? '',
                    });
                    toast.close();
                  }}
                />
              ),
            }}
          />
        ),
      });
    } catch (error) {
      if (isGraphqlError(error)) {
        switch (error[0]?.extensions.code) {
          case 'NOT_FOUND':
            datadogLogs.logger.error(
              'Control of the finding was not found',
              { findingId },
              toError(error),
            );
            return;
          default:
            datadogLogs.logger.error(
              'Either creating risk or linking it to the finding failed',
              { findingId },
              toError(error),
            );
            break;
        }
      }
      toast.error({ title: t('errorMessages.createFailed', { entity: t('entities.risk') }) });
    }
  };

  const createTask = async ({ findingId }: { findingId: string }) => {
    toast.loading({ title: t('controls.findings.taskIsBeingCreated') });

    try {
      const { ai_create_entity } = await aiCreateEntity({
        input: { entity: 'task-control-finding', findingId },
      }).unwrap();

      toast.success({
        title: (
          <Trans
            i18nKey="controls.findings.newTaskCreated"
            values={{
              internalId: ai_create_entity?.internalId,
            }}
            components={{
              p: <Text display={'inline'} color={toastTextColor} />,
              a: (
                <Button
                  variant="link"
                  color={toastTextColor}
                  onClick={() => {
                    drawer.open({
                      entity: 'task',
                      entityId: ai_create_entity?.entityId ?? '',
                    });
                    toast.close();
                  }}
                />
              ),
            }}
          />
        ),
      });
    } catch (error) {
      if (isGraphqlError(error)) {
        switch (error[0]?.extensions.code) {
          case 'NOT_FOUND':
            datadogLogs.logger.error(
              'Control of the finding was not found, it is used for task creation',
              { findingId },
              toError(error),
            );
            return;
          default:
            datadogLogs.logger.error(
              'Either creating task or linking it to the finding failed',
              { findingId },
              toError(error),
            );
            break;
        }
      }
      toast.error({ title: t('errorMessages.createFailed', { entity: t('entities.task') }) });
    }
  };

  return {
    toggleIgnoreState,
    createRisk,
    createTask,
  };
};

import {
  FileContent,
  FileStreamDownloader,
  NhostFile,
  NhostStreamDownloader,
  StreamDownloader,
} from '@main/bulk-downloader';

export type AppDownloadFile = NhostFile | FileContent;

export class AppStreamDownloader implements StreamDownloader<AppDownloadFile> {
  protected readonly encoder = new TextEncoder();

  constructor(
    protected readonly nhostDownloader: NhostStreamDownloader,
    protected readonly fileDownloader: FileStreamDownloader,
  ) {}

  async downloadFile(
    file: AppDownloadFile,
    options?: { signal: AbortSignal },
  ): Promise<ReadableStream> {
    if ('fileId' in file) {
      return this.nhostDownloader.downloadFile(file, options);
    }

    if ('content' in file) {
      return this.fileDownloader.downloadFile(file, options);
    }

    throw new Error(`Unsupported download file: ${file}`);
  }
}

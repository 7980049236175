import { createGqlSubscriptionApi } from '@main/graphql/create-subscription-api';

import { api, GetVendorDocument } from './get-vendor.generated';

export const { useGetVendorQuery: useGetVendorSubscription } = createGqlSubscriptionApi(
  api,
  'GetVendor',
  GetVendorDocument,
);

export * from './get-vendor.generated';

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type CreatePolicyTaskMutationVariables = Types.Exact<{
  input: Types.Policy_Tasks_Insert_Input;
}>;

export type CreatePolicyTaskMutation = {
  __typename?: 'mutation_root';
  insert_policy_tasks_one?: { __typename?: 'policy_tasks'; task_id: string };
};

export type UnlinkPolicyTaskMutationVariables = Types.Exact<{
  taskId: Types.Scalars['uuid']['input'];
  policyId: Types.Scalars['uuid']['input'];
}>;

export type UnlinkPolicyTaskMutation = {
  __typename?: 'mutation_root';
  delete_policy_tasks?: { __typename?: 'policy_tasks_mutation_response'; affected_rows: number };
};

export const CreatePolicyTaskDocument = `
    mutation CreatePolicyTask($input: policy_tasks_insert_input!) {
  insert_policy_tasks_one(object: $input) {
    task_id
  }
}
    ` as string & TypedDocumentNode<CreatePolicyTaskMutation, CreatePolicyTaskMutationVariables>;
export const UnlinkPolicyTaskDocument = `
    mutation UnlinkPolicyTask($taskId: uuid!, $policyId: uuid!) {
  delete_policy_tasks(
    where: {task_id: {_eq: $taskId}, policy_id: {_eq: $policyId}}
  ) {
    affected_rows
  }
}
    ` as string & TypedDocumentNode<UnlinkPolicyTaskMutation, UnlinkPolicyTaskMutationVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    CreatePolicyTask: build.mutation<CreatePolicyTaskMutation, CreatePolicyTaskMutationVariables>({
      query: (variables) => ({ document: CreatePolicyTaskDocument, variables }),
    }),
    UnlinkPolicyTask: build.mutation<UnlinkPolicyTaskMutation, UnlinkPolicyTaskMutationVariables>({
      query: (variables) => ({ document: UnlinkPolicyTaskDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useCreatePolicyTaskMutation, useUnlinkPolicyTaskMutation } = injectedRtkApi;

import { createGqlSubscriptionApi } from '@main/graphql/create-subscription-api';

import {
  api,
  GetVendorQuestionnairesByIdDocument,
  GetVendorQuestionnairesForViewDocument,
} from './vendor-questionnaires.generated';

export const {
  useGetVendorQuestionnairesForViewQuery: useGetVendorQuestionnairesForViewSubscription,
} = createGqlSubscriptionApi(
  api,
  'GetVendorQuestionnairesForView',
  GetVendorQuestionnairesForViewDocument,
);

export const { useGetVendorQuestionnairesByIdQuery: useGetVendorQuestionnairesByIdSubscription } =
  createGqlSubscriptionApi(api, 'GetVendorQuestionnairesById', GetVendorQuestionnairesByIdDocument);

export * from './vendor-questionnaires.generated';

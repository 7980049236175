import type { IntegrationConfig, IntegrationType } from '@main/graphql/client-scalars';
import type { Integration_Names_Enum } from '@main/graphql/types.generated';
import type { IntegrationConfigSchemas } from '@main/integrations/shared';

import * as integrationConfigs from './all-integration-configs';

const IntegrationConfigsRegistry: Partial<
  Record<Integration_Names_Enum, IntegrationConfigSchemas>
> = {};

function registerIntegrationConfig<T extends Integration_Names_Enum>(
  schemas: IntegrationConfigSchemas<T>,
) {
  IntegrationConfigsRegistry[schemas.name] = schemas;
}

Object.values(integrationConfigs).forEach(registerIntegrationConfig);

export function getIntegrationConfigSchema(name: Integration_Names_Enum) {
  const schemas = IntegrationConfigsRegistry[name];
  if (!schemas) {
    throw new Error(`No integration config schemas available for integration "${name}".
Please make sure the config schema is imported in the "all-integration-configs.ts" file.`);
  }
  return schemas;
}

export function parseIntegrationConfig<T extends IntegrationType>(
  integrationType: T,
  input: {
    orgConfig: unknown;
    evidenceConfig: unknown;
  },
) {
  const schemas = getIntegrationConfigSchema(integrationType.name);
  const orgConfig = schemas.orgConfig.parse(input.orgConfig);
  const evidenceConfig = schemas.evidenceConfig.parse(input.evidenceConfig);
  if (evidenceConfig.type !== integrationType.evidenceType) {
    throw new Error(
      `Evidence integration type mismatch: expected - ${integrationType.evidenceType}, actual - ${evidenceConfig.type}`,
    );
  }

  return {
    name: integrationType.name,
    orgConfig,
    evidenceConfig,
  } as IntegrationConfig<T['name'], T['evidenceType']>;
}

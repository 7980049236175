/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type GetOrganizationSummaryQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['uuid']['input'];
  userId: Types.Scalars['uuid']['input'];
  statsDate: Types.Scalars['timestamptz']['input'];
}>;

export type GetOrganizationSummaryQuery = {
  __typename?: 'query_root';
  controls: {
    __typename?: 'controls_aggregate';
    aggregate?: { __typename?: 'controls_aggregate_fields'; count: number };
  };
  validControls: {
    __typename?: 'controls_aggregate';
    aggregate?: { __typename?: 'controls_aggregate_fields'; count: number };
  };
  myControls: {
    __typename?: 'controls_aggregate';
    aggregate?: { __typename?: 'controls_aggregate_fields'; count: number };
  };
  myValidControls: {
    __typename?: 'controls_aggregate';
    aggregate?: { __typename?: 'controls_aggregate_fields'; count: number };
  };
  controlsStats: Array<{
    __typename?: 'daily_control_stats_view';
    total_valid_count?: number;
    total_at_risk_count?: number;
    total_failing_count?: number;
    total_pending_count?: number;
  }>;
  myControlsStats: Array<{
    __typename?: 'daily_control_user_stats_view';
    total_valid_count?: number;
    total_at_risk_count?: number;
    total_failing_count?: number;
    total_pending_count?: number;
  }>;
};

export const GetOrganizationSummaryDocument = `
    query GetOrganizationSummary($organizationId: uuid!, $userId: uuid!, $statsDate: timestamptz!) {
  controls: controls_aggregate(
    where: {organization_id: {_eq: $organizationId}, irrelevant: {_neq: true}}
  ) {
    aggregate {
      count
    }
  }
  validControls: controls_aggregate(
    where: {organization_id: {_eq: $organizationId}, status: {_eq: "Valid"}, irrelevant: {_neq: true}}
  ) {
    aggregate {
      count
    }
  }
  myControls: controls_aggregate(
    where: {assignee_id: {_eq: $userId}, organization_id: {_eq: $organizationId}, irrelevant: {_neq: true}}
  ) {
    aggregate {
      count
    }
  }
  myValidControls: controls_aggregate(
    where: {assignee_id: {_eq: $userId}, organization_id: {_eq: $organizationId}, status: {_eq: "Valid"}, irrelevant: {_neq: true}}
  ) {
    aggregate {
      count
    }
  }
  controlsStats: daily_control_stats_view(
    where: {organization_id: {_eq: $organizationId}, created_at: {_gte: $statsDate}}
    order_by: {created_at: asc}
    limit: 1
  ) {
    total_valid_count
    total_at_risk_count
    total_failing_count
    total_pending_count
  }
  myControlsStats: daily_control_user_stats_view(
    where: {organization_id: {_eq: $organizationId}, assignee_id: {_eq: $userId}, created_at: {_gte: $statsDate}}
    order_by: {created_at: asc}
    limit: 1
  ) {
    total_valid_count
    total_at_risk_count
    total_failing_count
    total_pending_count
  }
}
    ` as string &
  TypedDocumentNode<GetOrganizationSummaryQuery, GetOrganizationSummaryQueryVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetOrganizationSummary: build.query<
      GetOrganizationSummaryQuery,
      GetOrganizationSummaryQueryVariables
    >({
      query: (variables) => ({ document: GetOrganizationSummaryDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetOrganizationSummaryQuery, useLazyGetOrganizationSummaryQuery } =
  injectedRtkApi;

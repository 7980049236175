import { Route } from '@tanstack/react-router';

import { VendorManagement } from '../../features/vendors/vendor-management';
import { authLayout } from '../login';

export const vendorRoute = new Route({
  getParentRoute: () => authLayout,
  path: 'vendors',
  component: VendorManagement,
});

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type GetVendorRiskLevelsQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['uuid']['input'];
  nameFilter?: Types.InputMaybe<Types.String_Comparison_Exp>;
}>;

export type GetVendorRiskLevelsQuery = {
  __typename?: 'query_root';
  organization_vendor_risk_levels: Array<{
    __typename?: 'organization_vendor_risk_levels';
    id: string;
    name: string;
  }>;
};

export type CreateVendorRiskLevelMutationVariables = Types.Exact<{
  input: Types.Organization_Vendor_Risk_Levels_Insert_Input;
}>;

export type CreateVendorRiskLevelMutation = {
  __typename?: 'mutation_root';
  insert_organization_vendor_risk_levels_one?: {
    __typename?: 'organization_vendor_risk_levels';
    id: string;
  };
};

export const GetVendorRiskLevelsDocument = `
    query GetVendorRiskLevels($organizationId: uuid!, $nameFilter: String_comparison_exp = {}) {
  organization_vendor_risk_levels(
    order_by: {name: asc}
    where: {organization_id: {_eq: $organizationId}, name: $nameFilter}
  ) {
    id
    name
  }
}
    ` as string & TypedDocumentNode<GetVendorRiskLevelsQuery, GetVendorRiskLevelsQueryVariables>;
export const CreateVendorRiskLevelDocument = `
    mutation CreateVendorRiskLevel($input: organization_vendor_risk_levels_insert_input!) {
  insert_organization_vendor_risk_levels_one(object: $input) {
    id
  }
}
    ` as string &
  TypedDocumentNode<CreateVendorRiskLevelMutation, CreateVendorRiskLevelMutationVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetVendorRiskLevels: build.query<GetVendorRiskLevelsQuery, GetVendorRiskLevelsQueryVariables>({
      query: (variables) => ({ document: GetVendorRiskLevelsDocument, variables }),
    }),
    CreateVendorRiskLevel: build.mutation<
      CreateVendorRiskLevelMutation,
      CreateVendorRiskLevelMutationVariables
    >({
      query: (variables) => ({ document: CreateVendorRiskLevelDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useGetVendorRiskLevelsQuery,
  useLazyGetVendorRiskLevelsQuery,
  useCreateVendorRiskLevelMutation,
} = injectedRtkApi;

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type CreateCustomReportMutationVariables = Types.Exact<{
  reportObject: Types.Reports_Insert_Input;
}>;

export type CreateCustomReportMutation = {
  __typename?: 'mutation_root';
  insert_reports_one?: { __typename?: 'reports'; id: string; name?: string };
};

export const CreateCustomReportDocument = `
    mutation CreateCustomReport($reportObject: reports_insert_input!) {
  insert_reports_one(object: $reportObject) {
    id
    name
  }
}
    ` as string &
  TypedDocumentNode<CreateCustomReportMutation, CreateCustomReportMutationVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    CreateCustomReport: build.mutation<
      CreateCustomReportMutation,
      CreateCustomReportMutationVariables
    >({
      query: (variables) => ({ document: CreateCustomReportDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useCreateCustomReportMutation } = injectedRtkApi;

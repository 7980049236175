import { Box, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import { Select } from '@main/ui';
import { components, OptionProps, SingleValueProps } from 'chakra-react-select';

export type EntitySearchInputProps<T extends EntityOption> = {
  placeholder?: string;
  value?: T;
  searchEntities: (input: string) => Promise<T[]>;
  onChange: (value: T | null) => void;
};

export type EntityOption = {
  value: string;
  label?: string;
  internalId?: string;
};

export const EntitySearchInput = <T extends EntityOption>({
  placeholder,
  value,
  searchEntities,
  onChange,
}: EntitySearchInputProps<T>) => {
  return (
    <Select
      isMulti={false}
      isClearable={true}
      useBasicStyles
      placeholder={placeholder}
      debounceMs={500}
      loadOptions={searchEntities}
      value={value}
      styles={{
        option: (base) => ({
          ...base,
          backgroundColor: 'transparent',
        }),
      }}
      components={{
        SingleValue: SingleValueComponent,
        Option: OptionComponent,
      }}
      onChange={onChange}
    />
  );
};

const SingleValueComponent = <T extends EntityOption>(props: SingleValueProps<T>) => {
  const optionTextColor = useColorModeValue('gray.700', 'gray.300');
  return (
    <components.SingleValue {...props}>
      <Flex alignItems="center" color={optionTextColor}>
        {props.data.internalId && (
          <>
            <Text>{props.data.internalId}</Text>
            <Box ml={2} mr={1}>
              -
            </Box>
          </>
        )}
        {props.children}
      </Flex>
    </components.SingleValue>
  );
};

const OptionComponent = <T extends EntityOption>(props: OptionProps<T>) => {
  const optionBgHoverColor = useColorModeValue('gray.100', 'gray.600');
  const optionTextColor = useColorModeValue('gray.600', 'gray.300');

  return (
    <Flex _hover={{ bg: optionBgHoverColor }}>
      <components.Option {...props}>
        <Flex
          alignItems="center"
          color={optionTextColor}
          fontSize="sm"
          _active={{ color: 'black' }}
        >
          {props.data.internalId && (
            <>
              <Text>{props.data.internalId}</Text>
              <Box ml={2} mr={1}>
                -
              </Box>
            </>
          )}
          <Text>{props.children}</Text>
        </Flex>
      </components.Option>
    </Flex>
  );
};

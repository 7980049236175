/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type GetRiskHistoryQueryVariables = Types.Exact<{
  risk_id: Types.Scalars['uuid']['input'];
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type GetRiskHistoryQuery = {
  __typename?: 'query_root';
  audit_risk_history_view: Array<{
    __typename?: 'audit_risk_history_view';
    user_id?: string;
    action_timestamp?: string;
    action: ClientTypes.AuditTableActions;
    table_name: ClientTypes.RiskHistoryTables;
    hasura_user: ClientTypes.AuditTableHasuraUser;
    changed_fields?: ClientTypes.RiskChangedFields;
    row_data: ClientTypes.RiskRowData;
  }>;
};

export const GetRiskHistoryDocument = `
    query GetRiskHistory($risk_id: uuid!, $limit: Int = 10, $offset: Int = 0) {
  audit_risk_history_view(
    limit: $limit
    offset: $offset
    where: {risk_id: {_eq: $risk_id}}
    order_by: {action_timestamp: desc}
  ) {
    user_id
    action_timestamp
    action
    table_name
    hasura_user
    changed_fields
    row_data
  }
}
    ` as string & TypedDocumentNode<GetRiskHistoryQuery, GetRiskHistoryQueryVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetRiskHistory: build.query<GetRiskHistoryQuery, GetRiskHistoryQueryVariables>({
      query: (variables) => ({ document: GetRiskHistoryDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetRiskHistoryQuery, useLazyGetRiskHistoryQuery } = injectedRtkApi;

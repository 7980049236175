import {
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from '@chakra-ui/react';
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Column, Row, Table } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

export function GlobalFilter<TData>({ table }: { table: Table<TData> }) {
  const { t } = useTranslation('ui');
  const { globalFilter = '' } = table.getState();

  return (
    <InputGroup>
      <InputLeftElement pointerEvents="none">
        <Icon color="gray.400" as={MagnifyingGlassIcon} />
      </InputLeftElement>

      <Input
        placeholder={t('table.search.placeholder')}
        value={globalFilter}
        onChange={(event) => table.setGlobalFilter(event.target.value)}
      />

      {!!globalFilter && (
        <InputRightElement onClick={() => table.setGlobalFilter('')}>
          <IconButton
            aria-label={t('table.search.clear')}
            variant="link"
            color="gray.400"
            icon={<Icon as={XMarkIcon} />}
          />
        </InputRightElement>
      )}
    </InputGroup>
  );
}

export function getColumnCanGlobalFilter<TData>(column: Column<TData, unknown>): boolean {
  return !!column.columnDef.meta?.globalFilterFn;
}

export function globalFilterFn<TData>(
  row: Row<TData>,
  columnId: string,
  filterValue: string,
): boolean {
  const cell = row.getAllCells().find((cell) => cell.column.id === columnId);
  const columnGlobalFilterFn = cell?.column?.columnDef.meta?.globalFilterFn;
  if (!columnGlobalFilterFn) {
    return true;
  }
  return columnGlobalFilterFn(row.getValue(columnId), filterValue);
}

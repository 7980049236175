import { Avatar, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import { MultiValue } from 'chakra-react-select';

import { getHashedAvatarColor } from '../../utils';
import { AvatarOptionProps } from './editable-multiselect-avatar';

export const AvatarTooltip = ({ values }: { values: MultiValue<AvatarOptionProps> }) => {
  const textColor = useColorModeValue('gray.300', 'gray.700');

  if (!values || values.length === 0) return null;

  return (
    <Flex gap={3} flexWrap={'wrap'}>
      {values.map((value) => (
        <Flex key={value.id} gap={2} alignItems={'center'}>
          <Avatar
            size={'xs'}
            name={value.displayName}
            {...getHashedAvatarColor(value.displayName)}
          />
          <Text fontSize={'xs'} fontWeight={'medium'} color={textColor}>
            {value.displayName}
          </Text>
        </Flex>
      ))}
    </Flex>
  );
};

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type GetProgramQueryVariables = Types.Exact<{
  programId: Types.Scalars['uuid']['input'];
}>;

export type GetProgramQuery = {
  __typename?: 'query_root';
  programs_by_pk?: {
    __typename?: 'programs';
    name: string;
    description?: string;
    organization_id: string;
    framework?: { __typename: 'frameworks'; id: string; name: string };
  };
};

export const GetProgramDocument = `
    query GetProgram($programId: uuid!) {
  programs_by_pk(id: $programId) {
    name
    description
    framework {
      id
      name
      __typename
    }
    organization_id
  }
}
    ` as string & TypedDocumentNode<GetProgramQuery, GetProgramQueryVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetProgram: build.query<GetProgramQuery, GetProgramQueryVariables>({
      query: (variables) => ({ document: GetProgramDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetProgramQuery, useLazyGetProgramQuery } = injectedRtkApi;

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type JoinOrganizationMutationVariables = Types.Exact<{
  invitationId: Types.Scalars['String']['input'];
}>;

export type JoinOrganizationMutation = {
  __typename?: 'mutation_root';
  join_organization?: { __typename?: 'GeneralActionOutput'; message: string; status: number };
};

export const JoinOrganizationDocument = `
    mutation JoinOrganization($invitationId: String!) {
  join_organization(object: {invitationId: $invitationId}) {
    message
    status
  }
}
    ` as string & TypedDocumentNode<JoinOrganizationMutation, JoinOrganizationMutationVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    JoinOrganization: build.mutation<JoinOrganizationMutation, JoinOrganizationMutationVariables>({
      query: (variables) => ({ document: JoinOrganizationDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useJoinOrganizationMutation } = injectedRtkApi;

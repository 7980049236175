import { createGqlSubscriptionApi } from '../create-subscription-api';
import {
  api as getQuestionnaireApi,
  GetQuestionnaireDocument,
} from '../queries/GetQuestionnaire.generated';

export const api = createGqlSubscriptionApi(
  getQuestionnaireApi,
  'GetQuestionnaire',
  GetQuestionnaireDocument,
);

export const { useGetQuestionnaireQuery: useGetQuestionnaireSubscription } = api;

import {
  Button,
  HStack,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Stack,
} from '@chakra-ui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { Fragment, JSXElementConstructor, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { CellContainer } from '../shared/cell';

type ActionsColumnConfig<TData> = {
  PrimaryAction?: JSXElementConstructor<CellContext<TData, unknown>>;
  secondaryActions?: (context: CellContext<TData, unknown>) => SecondaryActionConfig[];
};

export type SecondaryActionConfig = {
  icon: ReactElement;
  label: string;
  onClick: () => void;
  children?: ReactElement;
};

export function actions<TData>() {
  return ({
    PrimaryAction,
    secondaryActions,
    ...columnDef
  }: ColumnDef<TData, unknown> & ActionsColumnConfig<TData>): ColumnDef<TData, unknown> => {
    return {
      enableResizing: false,
      ...columnDef,

      cell: (context) => (
        <ActionsCell
          context={context}
          PrimaryAction={PrimaryAction}
          secondaryActions={secondaryActions}
        />
      ),

      meta: {
        ...columnDef.meta,
        name: 'Actions',
      },
    };
  };
}

type ActionsCellProps<T> = {
  context: CellContext<T, unknown>;
  PrimaryAction?: JSXElementConstructor<CellContext<T, unknown>>;
  secondaryActions?: (context: CellContext<T, unknown>) => SecondaryActionConfig[];
};

function ActionsCell<T>({ context, PrimaryAction, secondaryActions }: ActionsCellProps<T>) {
  const { t } = useTranslation('ui');
  const resolvedSecondaryActions = secondaryActions?.(context) || [];

  return (
    <CellContainer
      justify="end"
      cursor={'default'}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <HStack spacing={0.5}>
        {PrimaryAction && <PrimaryAction {...context} />}
        {resolvedSecondaryActions.length !== 0 && (
          <Popover>
            {({ onClose }) => (
              <>
                <PopoverTrigger>
                  <IconButton
                    minW={5}
                    variant="link"
                    aria-label={t('table.column.actions.moreActions')}
                    icon={<EllipsisVerticalIcon />}
                  />
                </PopoverTrigger>
                <Portal>
                  <PopoverContent width="fit-content">
                    <PopoverArrow />
                    <PopoverBody>
                      <Stack spacing={0}>
                        {resolvedSecondaryActions.map((action, index) => (
                          <Fragment key={index}>
                            <Button
                              variant="ghost"
                              justifyContent="start"
                              fontWeight="normal"
                              onClick={() => {
                                action.onClick();
                                onClose();
                              }}
                              size="sm"
                              leftIcon={action.icon}
                            >
                              {action.label}
                            </Button>
                            {action.children}
                          </Fragment>
                        ))}
                      </Stack>
                    </PopoverBody>
                  </PopoverContent>
                </Portal>
              </>
            )}
          </Popover>
        )}
      </HStack>
    </CellContainer>
  );
}

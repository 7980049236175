import { Box, ChakraProps, Flex, useColorModeValue } from '@chakra-ui/react';
import { LinkProps } from '@tanstack/react-router';

export interface SidebarNavGroupTitleProps extends LinkProps {
  chakraProps?: ChakraProps;
  isSidebarCollapsed?: boolean;
  children: React.ReactNode;
}

export const SidebarNavGroupTitle = ({
  children,
  chakraProps,
  isSidebarCollapsed,
}: SidebarNavGroupTitleProps) => {
  const textColor = useColorModeValue('gray.400', 'gray.300');

  return (
    <Box mt="4" w="full" overflow="hidden" cursor="default">
      <Flex
        align="center"
        px="3"
        h="7"
        mt="4"
        role="group"
        color={textColor}
        fontSize={'xs'}
        transition={'0.7s cubic-bezier(0.22, 0.61, 0.36, 1)'}
        opacity={isSidebarCollapsed ? 0 : 1}
        {...chakraProps}
      >
        {children}
      </Flex>
    </Box>
  );
};

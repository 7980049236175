import { datadogLogs } from '@datadog/browser-logs';
import { Policies_Set_Input } from '@main/graphql/types.generated';
import { toError } from '@main/shared/utils';
import { errorToast } from '@main/ui';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { getCurrentUserSelectedOrgId } from '../user/slice';
import {
  api as policyApi,
  useAddPolicyApproverMutation,
  useDeletePolicyApproverMutation,
  useUpdatePolicyMutation,
} from './manage-policies.generated';

export function useUpdatePolicyHandler() {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const orgId = useAppSelector(getCurrentUserSelectedOrgId);
  const refetchPolicyQueries = useRefetchPolicyQueries();

  return useCallback(
    async (
      resultPromise: ReturnType<
        ReturnType<
          | typeof useUpdatePolicyMutation
          | typeof useAddPolicyApproverMutation
          | typeof useDeletePolicyApproverMutation
        >[0]
      >,
    ) => {
      let policyIdUpdated: string | undefined;
      let updatePayload: Policies_Set_Input;

      if ('updatePayload' in resultPromise.arg.originalArgs) {
        policyIdUpdated = resultPromise.arg.originalArgs.id;
        updatePayload = resultPromise.arg.originalArgs.updatePayload;
      }

      dispatch(
        policyApi.util.updateQueryData('GetPolicies', { orgId }, (draft) => {
          for (const policy of draft.policies) {
            if (policy.id === policyIdUpdated) {
              Object.assign(policy, updatePayload);
            }
          }
        }),
      );
      try {
        await resultPromise.unwrap();
      } catch (error) {
        errorToast(t('errorMessages.updateFailed', { entity: t('entities.policy') }));
        datadogLogs.logger.error('Policy update failed', {}, toError(error));
      } finally {
        await refetchPolicyQueries(policyIdUpdated);
      }
    },
    [dispatch, orgId, refetchPolicyQueries, t],
  );
}

function useRefetchPolicyQueries() {
  const dispatch = useAppDispatch();
  const orgId = useAppSelector(getCurrentUserSelectedOrgId);

  return useCallback(
    async (policyId?: string) => {
      try {
        await Promise.all([
          dispatch(
            policyApi.endpoints.GetPolicies.initiate(
              { orgId },
              { subscribe: false, forceRefetch: true },
            ),
          ),
          policyId &&
            dispatch(
              policyApi.endpoints.GetPolicy.initiate(
                { id: policyId },
                { subscribe: false, forceRefetch: true },
              ),
            ),
        ]);
      } catch (error) {
        datadogLogs.logger.error('Policy list refetch failed', {}, toError(error));
      }
    },
    [dispatch, orgId],
  );
}

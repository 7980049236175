import { Outlet, Route } from '@tanstack/react-router';

import { organizationSettingsLayoutRoute } from '../layout';

const TasksLayout = () => {
  return <Outlet />;
};

export const organizationTasksSettingsLayoutRoute = new Route({
  getParentRoute: () => organizationSettingsLayoutRoute,
  path: 'tasks',
  component: TasksLayout,
});

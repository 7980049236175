/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type SendQuestionnaireModalToVendorsMutationVariables = Types.Exact<{
  input: Types.SendVendorQuestionnaireInput;
}>;

export type SendQuestionnaireModalToVendorsMutation = {
  __typename?: 'mutation_root';
  sendVendorQuestionnaire?: { __typename?: 'SendVendorQuestionnaireOutput'; workflowId: string };
};

export const SendQuestionnaireModalToVendorsDocument = `
    mutation SendQuestionnaireModalToVendors($input: SendVendorQuestionnaireInput!) {
  sendVendorQuestionnaire(input: $input) {
    workflowId
  }
}
    ` as string &
  TypedDocumentNode<
    SendQuestionnaireModalToVendorsMutation,
    SendQuestionnaireModalToVendorsMutationVariables
  >;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    SendQuestionnaireModalToVendors: build.mutation<
      SendQuestionnaireModalToVendorsMutation,
      SendQuestionnaireModalToVendorsMutationVariables
    >({
      query: (variables) => ({ document: SendQuestionnaireModalToVendorsDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useSendQuestionnaireModalToVendorsMutation } = injectedRtkApi;

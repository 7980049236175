import { GetProgramsQuery } from '@main/graphql/queries/GetPrograms.generated';
import { api as getProgramsSubscriptionApi } from '@main/graphql/subscriptions/GetProgramsSubscription';
import { getHashedColor } from '@main/ui';
import { createAction, createSelector } from '@reduxjs/toolkit';

import { AppRootState } from '../../store';
import { getCurrentUserSelectedOrgId } from '../user/slice';

/* => Actions <= */
export const programIdPageLoaded = createAction<{ programId: string }>(
  '[programId page] page loaded',
);
export const homepageLoaded = createAction('[home page] home page loaded');

const emptyArray: never[] = [];

const getOrgProgramsApiData = createSelector(
  [(state: AppRootState) => state, getCurrentUserSelectedOrgId],
  (state, orgId) => {
    return (
      getProgramsSubscriptionApi.endpoints.GetPrograms.select({
        orgId,
      })(state).data?.programs || emptyArray
    );
  },
);

export type OrganizationProgram = GetProgramsQuery['programs'][number] & {
  colorScheme?: string;
};

export const getOrgProgramsMap = createSelector([getOrgProgramsApiData], (programs) => {
  return programs.reduce((acc, program) => {
    acc.set(program.id, {
      ...program,
      colorScheme: getHashedColor(program.name),
    });
    return acc;
  }, new Map<string, OrganizationProgram>());
});

export const getMappedOrgPrograms = createSelector([getOrgProgramsMap], (programs) => {
  return Array.from(programs.values());
});

import { HStack, Tag, Text } from '@chakra-ui/react';
import {
  ClipboardDocumentListIcon,
  ListBulletIcon,
  SparklesIcon,
} from '@heroicons/react/24/outline';
import { Vendor_Questionnaire_Statuses_Enum } from '@main/graphql/types.generated';
import { QUESTIONNAIRE_STATUSES } from '@main/shared/types';
import {
  getQuestionnaireType,
  QuestionnaireType,
  Severity,
  VendorQuestionnairesTabKeys,
} from '@main/shared/utils';
import { Drawer, getVisibleTabs, StatusTag } from '@main/ui';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../hooks/redux-toolkit-hooks';
import { getCurrentUserSelectedOrgRole } from '../../user/slice';
import { getAiReviewImprovements } from '../slice';
import { AIReviewTab } from './ai-review-tab';
import { VendorQuestionnaireDetailsTab } from './details-tab';
import { SubmissionsTab } from './submissions-tab';
import { useVendorQuestionnaireDrawerActions } from './use-drawer-actions';
import { useGetVendorQuestionnaireData } from './use-vendor-questionnaire-data';

export const VendorQuestionnaireDrawer = ({ vqId }: { vqId: string }) => {
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canViewVq = userRole.permissionMap?.read_vendors;

  const { vq, isLoading: isVqLoading } = useGetVendorQuestionnaireData(vqId);
  const { tabs } = useVendorQuestionnaireDrawerTabs(vqId);
  const { menuActions } = useVendorQuestionnaireDrawerActions(vqId);

  return (
    <Drawer.Layout isLoading={isVqLoading} canView={canViewVq} isNotFound={!vq}>
      <Drawer.Toolbar menuActions={menuActions} />
      <Drawer.Tabs tabs={tabs}>
        <VendorQuestionnaireDrawerHeader vqId={vqId} />
      </Drawer.Tabs>
    </Drawer.Layout>
  );
};

const VendorQuestionnaireDrawerHeader = ({ vqId }: { vqId: string }) => {
  const { t } = useTranslation();
  const { vq, questionnaire } = useGetVendorQuestionnaireData(vqId);

  if (!vq || !questionnaire) {
    return null;
  }

  return (
    <>
      <HStack mb={4}>
        <Tag colorScheme="purple">
          {t('entities.vendorQuestionnaire')} / {questionnaire.internal_id}
        </Tag>
        <StatusTag
          size="sm"
          minW="auto"
          maxW="none"
          colorScheme={QUESTIONNAIRE_STATUSES[vq.status].colorScheme}
          data-testid="vendor-questionnaire-status"
        >
          {t(QUESTIONNAIRE_STATUSES[vq.status].value)}
        </StatusTag>
      </HStack>

      <Text fontSize={'lg'} fontWeight={'bold'}>
        {questionnaire.name}
      </Text>
    </>
  );
};

const useVendorQuestionnaireDrawerTabs = (vqId: string) => {
  const { t } = useTranslation();
  const isAIReviewFeatureEnabled = useFeatureFlagEnabled('vendor-questionnaire-ai-review');
  const { vq, questionnaire } = useGetVendorQuestionnaireData(vqId);
  const aiFindings = useAiFindings(vqId);
  const type = questionnaire && getQuestionnaireType(questionnaire);
  const isSubmitted = vq?.status === Vendor_Questionnaire_Statuses_Enum.Submitted;

  const tabs = useMemo(
    () =>
      getVisibleTabs<VendorQuestionnairesTabKeys>({
        details: {
          label: t('questionnaires.drawer.tabs.details'),
          icon: ListBulletIcon,
          panel: <VendorQuestionnaireDetailsTab vqId={vqId} />,
          hideTabTitle: true,
        },
        submissions: {
          label: t('questionnaires.drawer.tabs.submissions'),
          icon: ClipboardDocumentListIcon,
          panel: <SubmissionsTab vqId={vqId} />,
          isHidden: !isSubmitted,
        },
        findings: {
          label: t('questionnaires.drawer.tabs.findings'),
          icon: SparklesIcon,
          panel: <AIReviewTab vendorQuestionnaireId={vqId} />,
          badge: {
            count: aiFindings,
            severity: Severity.RECOMMENDATION,
          },
          isHidden: !(isSubmitted && type === QuestionnaireType.Form && isAIReviewFeatureEnabled),
        },
      }),
    [aiFindings, isAIReviewFeatureEnabled, isSubmitted, t, type, vqId],
  );

  return { tabs };
};

const useAiFindings = (vqId: string) => {
  const { findings } = useGetVendorQuestionnaireData(vqId);
  const reviewImprovements = useAppSelector((state) => getAiReviewImprovements(state, vqId));

  const reviewImprovementsWithoutIgnored = useMemo(() => {
    return reviewImprovements?.filter((improvement) => {
      const findingEntry = findings?.find(
        (finding) => finding.question_name_id === improvement.name,
      );
      if (!findingEntry) {
        return true;
      }
      return !findingEntry.ignored_at;
    });
  }, [findings, reviewImprovements]);

  return reviewImprovementsWithoutIgnored.length;
};

import { Button, Icon, IconButton, Tooltip, useColorModeValue } from '@chakra-ui/react';
import { ArrowTopRightOnSquareIcon, TableCellsIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { getCurrentUserSelectedOrg, getCurrentUserSelectedOrgRole } from '../user/slice';

export const CreateFindingRiskButton = ({
  onOpen,
  onCreate,
}: {
  onOpen: (() => Promise<void>) | undefined;
  onCreate: () => Promise<void>;
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const actionBtnColor = useColorModeValue('gray.500', 'gray.300');
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canViewRisk = userRole.permissionMap?.read_risks;
  const { is_risks_module_enabled: isRisksModuleEnabled } =
    useAppSelector(getCurrentUserSelectedOrg);

  if (!isRisksModuleEnabled) {
    return null;
  }

  if (canViewRisk && onOpen) {
    return (
      <Tooltip
        label={t('controls.findings.tooltips.openRisk')}
        rounded="md"
        hasArrow
        placement="top"
      >
        <Button
          size="sm"
          variant="outline"
          color={actionBtnColor}
          onClick={onOpen}
          rightIcon={<Icon as={ArrowTopRightOnSquareIcon} w={4} h={4} color={actionBtnColor} />}
        >
          {t('entities.risk')}
        </Button>
      </Tooltip>
    );
  }

  return (
    <Tooltip
      label={t('controls.findings.tooltips.createRisk')}
      rounded="md"
      hasArrow
      placement="top"
    >
      <IconButton
        onClick={async () => {
          setIsLoading(true);
          await onCreate();
          setIsLoading(false);
        }}
        variant="outline"
        aria-label="Create risk"
        size="sm"
        isLoading={isLoading}
        icon={<Icon as={TableCellsIcon} w={4} h={4} color={actionBtnColor} />}
      />
    </Tooltip>
  );
};

import { Button, Text, useColorModeValue, VStack } from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { useAiCreateEntityMutation } from '@main/graphql/features/AiCreateEntity.generated';
import { Vendor_Questionnaire_Ai_Review_Status_Enum } from '@main/graphql/types.generated';
import { formatDate, isGraphqlError, toError } from '@main/shared/utils';
import { errorToast, FindingCard, StatusTag, useDrawer, useMultiStateToast } from '@main/ui';
import { Trans, useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../hooks/redux-toolkit-hooks';
import { CreateFindingRiskButton } from '../../findings/create-risk';
import { CreateFindingTaskButton } from '../../findings/create-task';
import { useToggleVqIgnoreFindingMutation } from '../../findings/ManageFindings.generated';
import { ToggleFindingIgnoreStateButton } from '../../findings/toggle-ignore-state';
import { getCurrentUserSelectedOrgRole } from '../../user/slice';
import { useCreateVendorQuestionnaireAiReviewEntryMutation } from '../CreateVQAIReviewEntry.generated';
import { getAiReviewImprovements, getLatestAiReviewForVendorQuestionnaire } from '../slice';
import {
  api as getVendorQuestionnaireApi,
  useGetVendorQuestionnairesByIdQuery,
} from './vendor-questionnaires.generated';

export const AIReviewTab = ({ vendorQuestionnaireId }: { vendorQuestionnaireId: string }) => {
  const { t } = useTranslation();
  const [createAiReviewEntry] = useCreateVendorQuestionnaireAiReviewEntryMutation();
  const descriptionColor = useColorModeValue('gray.600', 'gray.300');
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canUpdateVendor = userRole.permissionMap?.write_vendors;
  const canLinkVendorToRisk = userRole.permissionMap?.link_risks_vendors;
  const drawer = useDrawer();
  const { toggleIgnoreState, createRisk, createTask } = useFindingActionsHandlers();
  const { data: questionnaireData } = useGetVendorQuestionnairesByIdQuery({
    vqId: vendorQuestionnaireId,
  });
  const questionnaireFindings =
    questionnaireData?.vendor_questionnaires_by_pk?.vendor_questionnaire_ai_review_findings;

  const latestAiReview = useAppSelector((state) =>
    getLatestAiReviewForVendorQuestionnaire(state, vendorQuestionnaireId),
  );
  const reviewImprovements = useAppSelector((state) =>
    getAiReviewImprovements(state, vendorQuestionnaireId),
  );

  const handleCreateAiReview = async () => {
    try {
      await createAiReviewEntry({ vendorQuestionnaireId }).unwrap();
    } catch (error) {
      errorToast(t('errorMessages.createFailed', { entity: t('entities.aiReview') }));
      datadogLogs.logger.error('Error in creating an entry in ai review', {}, toError(error));
    }
  };

  if (!latestAiReview) {
    return (
      <VStack>
        <VStack justifyContent="center" height="100%" paddingTop="2rem">
          <Text align="center" fontWeight="bold">
            {t('vendors.questionnaires.aiReview.emptyTitle')}
          </Text>
          <Text align="center" padding="0 94px" paddingBottom="6">
            {t('vendors.questionnaires.aiReview.emptySubtitle')}
          </Text>
          <Button colorScheme="blue" variant="outline" onClick={handleCreateAiReview}>
            {t('vendors.questionnaires.aiReview.cta')}
          </Button>
        </VStack>
        <Text align="center" color="gray.500">
          {t('vendors.questionnaires.aiReview.footerNoResult')}
        </Text>
      </VStack>
    );
  }

  if (latestAiReview?.status === Vendor_Questionnaire_Ai_Review_Status_Enum.Pending) {
    return (
      <VStack paddingBottom={4}>
        <VStack justifyContent="center" height="100%" paddingTop="2rem">
          <Text align="center" fontWeight="bold">
            {t('vendors.questionnaires.aiReview.processingTitle')}
          </Text>
          <Text align="center" padding="0 94px" paddingBottom="6">
            {t('vendors.questionnaires.aiReview.processingSubtitle')}
          </Text>
          <Button colorScheme="blue" variant="outline" isDisabled>
            {t('vendors.questionnaires.aiReview.ctaProcessing')}
          </Button>
        </VStack>
        <Text align="center" color="gray.500">
          {t('vendors.questionnaires.aiReview.footerNoResult')}
        </Text>
      </VStack>
    );
  }

  if (
    latestAiReview?.status === Vendor_Questionnaire_Ai_Review_Status_Enum.Succeeded &&
    reviewImprovements?.length > 0
  ) {
    return (
      <VStack alignItems="flex-start">
        <Text fontSize="xs" color="gray.400">
          {t('vendors.questionnaires.aiReview.hasReviewsTitle', {
            date: formatDate(latestAiReview.updated_at),
          })}
        </Text>
        <Text paddingBottom="3">{t('vendors.questionnaires.aiReview.hasReviewsSubTitle')}</Text>
        {reviewImprovements?.map((entry, idx) => {
          const questionnaireFindingForThisQuestion = questionnaireFindings?.find(
            (finding) => finding.question_name_id === entry.name,
          );
          const riskId = questionnaireFindingForThisQuestion?.risk_id;
          const taskId = questionnaireFindingForThisQuestion?.task_id;

          return (
            <FindingCard
              isIgnored={!!questionnaireFindingForThisQuestion?.ignored_at}
              key={entry.questionIdx}
            >
              <FindingCard.Header
                heading={t('vendors.questionnaires.aiReview.findingsNo', {
                  number: idx + 1,
                  total: reviewImprovements.length,
                })}
                subheading={`${t('vendors.questionnaires.aiReview.question')} ${entry.questionIdx}`}
              >
                <StatusTag size="sm" colorScheme={'teal'}>
                  {t('vendors.questionnaires.aiReview.recommendation')}
                </StatusTag>
              </FindingCard.Header>
              <FindingCard.Actions>
                {canLinkVendorToRisk && (
                  <CreateFindingRiskButton
                    onCreate={() =>
                      createRisk({
                        historyReviewId: latestAiReview.id,
                        questionId: entry.name ?? '',
                      })
                    }
                    onOpen={
                      riskId
                        ? () =>
                            drawer.open({
                              entity: 'risk',
                              entityId: riskId,
                            })
                        : undefined
                    }
                  />
                )}
                {canUpdateVendor && (
                  <CreateFindingTaskButton
                    onCreate={() =>
                      createTask({
                        historyReviewId: latestAiReview.id,
                        questionId: entry.name ?? '',
                      })
                    }
                    onOpen={
                      taskId
                        ? () =>
                            drawer.open({
                              entity: 'task',
                              entityId: taskId,
                            })
                        : undefined
                    }
                  />
                )}
                {canUpdateVendor && (
                  <ToggleFindingIgnoreStateButton
                    onToggle={() =>
                      toggleIgnoreState({
                        findingId: questionnaireFindingForThisQuestion?.id,
                        vendorQuestionnaireId,
                        questionNameId: entry.name ?? '',
                        isIgnored: !!questionnaireFindingForThisQuestion?.ignored_at,
                      })
                    }
                  />
                )}
              </FindingCard.Actions>
              <FindingCard.Body>
                <Text fontSize={'sm'} fontWeight={'semibold'}>
                  {entry.message}
                </Text>
                <Text fontSize={'sm'} color={descriptionColor}>
                  {entry?.assessment?.rationale ?? entry.criteria}
                </Text>
              </FindingCard.Body>
            </FindingCard>
          );
        })}
        <Text align="center" color="gray.500" paddingBottom="3">
          {t('vendors.questionnaires.aiReview.footer')}
        </Text>
      </VStack>
    );
  }

  if (latestAiReview?.status === Vendor_Questionnaire_Ai_Review_Status_Enum.Succeeded) {
    return (
      <VStack>
        <Text paddingBottom="3">{t('vendors.questionnaires.aiReview.noImprovementsFound')}</Text>

        <Text align="center" color="gray.500">
          {t('vendors.questionnaires.aiReview.footer')}
        </Text>
      </VStack>
    );
  }

  return (
    <VStack>
      <VStack justifyContent="center" height="100%" paddingTop="2rem">
        <Text align="center" fontWeight="bold">
          {t('vendors.questionnaires.aiReview.errorTitle')}
        </Text>
        <Text align="center" padding="0 94px" paddingBottom="6">
          {t('vendors.questionnaires.aiReview.errorSubtitle')}
        </Text>
        <Button colorScheme="blue" variant="outline" onClick={handleCreateAiReview}>
          {t('vendors.questionnaires.aiReview.cta')}
        </Button>
      </VStack>
      <Text align="center" color="gray.500">
        {t('vendors.questionnaires.aiReview.footerNoResult')}
      </Text>
    </VStack>
  );
};

const useFindingActionsHandlers = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const toast = useMultiStateToast();
  const drawer = useDrawer();

  const [toggleIgnoreFinding] = useToggleVqIgnoreFindingMutation();
  const [aiCreateEntity] = useAiCreateEntityMutation();

  const toastTextColor = useColorModeValue('green.800', 'green.600');

  const toggleIgnoreState = async ({
    findingId,
    questionNameId,
    vendorQuestionnaireId,
    isIgnored,
  }: {
    findingId?: string;
    questionNameId: string;
    vendorQuestionnaireId: string;
    isIgnored: boolean;
  }) => {
    const patchResult = dispatch(
      getVendorQuestionnaireApi.util.updateQueryData(
        'GetVendorQuestionnairesById',
        { vqId: vendorQuestionnaireId },
        (draft) => {
          if (!findingId) {
            draft.vendor_questionnaires_by_pk?.vendor_questionnaire_ai_review_findings.push({
              id: 'new',
              question_name_id: questionNameId,
              ignored_at: isIgnored ? undefined : new Date().toISOString(),
            });
          }
          for (const draftFinding of draft.vendor_questionnaires_by_pk
            ?.vendor_questionnaire_ai_review_findings ?? []) {
            if (draftFinding.id === findingId) {
              draftFinding.ignored_at = isIgnored ? undefined : new Date().toISOString();
            }
          }
        },
      ),
    );
    try {
      await toggleIgnoreFinding({
        questionnaireAiFindingInput: {
          question_name_id: questionNameId,
          vendor_questionnaire_id: vendorQuestionnaireId,
          ignored_at: isIgnored ? null : 'now()',
        },
      }).unwrap();
    } catch (error) {
      errorToast(t('errorMessages.updateFailed', { entity: t('entities.finding') }));
      datadogLogs.logger.error('Toggling ignore state of the finding failed', {}, toError(error));
      patchResult.undo();
    }
  };

  const createRisk = async ({
    questionId,
    historyReviewId,
  }: {
    questionId: string;
    historyReviewId: string;
  }) => {
    toast.loading({ title: t('vendors.questionnaires.aiReview.findings.riskIsBeingCreated') });

    try {
      const { ai_create_entity } = await aiCreateEntity({
        input: {
          entity: 'risk-vq-finding',
          questionNameId: questionId,
          vqReviewHistoryId: historyReviewId,
        },
      }).unwrap();

      toast.success({
        title: (
          <Trans
            i18nKey="vendors.questionnaires.aiReview.findings.newRiskCreated"
            values={{ internalId: ai_create_entity?.internalId }}
            components={{
              p: <Text display={'inline'} color={toastTextColor} />,
              a: (
                <Button
                  variant="link"
                  color={toastTextColor}
                  onClick={() => {
                    drawer.open({ entity: 'risk', entityId: ai_create_entity?.entityId ?? '' });
                    toast.close();
                  }}
                />
              ),
            }}
          />
        ),
      });
    } catch (error) {
      if (isGraphqlError(error)) {
        switch (error[0]?.extensions.code) {
          case 'NOT_FOUND':
            datadogLogs.logger.error(
              'Question for the finding was not found',
              { historyReviewId },
              toError(error),
            );
            return;
          default:
            datadogLogs.logger.error(
              'Either creating risk or linking it to the finding failed',
              { historyReviewId },
              toError(error),
            );
            break;
        }
      }
      toast.error({ title: t('errorMessages.createFailed', { entity: t('entities.risk') }) });
    }
  };

  const createTask = async ({
    questionId,
    historyReviewId,
  }: {
    questionId: string;
    historyReviewId: string;
  }) => {
    toast.loading({ title: t('vendors.questionnaires.aiReview.findings.taskIsBeingCreated') });

    try {
      const { ai_create_entity } = await aiCreateEntity({
        input: {
          entity: 'task-vq-finding',
          questionNameId: questionId,
          vqReviewHistoryId: historyReviewId,
        },
      }).unwrap();

      toast.success({
        title: (
          <Trans
            i18nKey="vendors.questionnaires.aiReview.findings.newTaskCreated"
            values={{ internalId: ai_create_entity?.internalId }}
            components={{
              p: <Text display={'inline'} color={toastTextColor} />,
              a: (
                <Button
                  variant="link"
                  color={toastTextColor}
                  onClick={() => {
                    drawer.open({ entity: 'task', entityId: ai_create_entity?.entityId ?? '' });
                    toast.close();
                  }}
                />
              ),
            }}
          />
        ),
      });
    } catch (error) {
      if (isGraphqlError(error)) {
        switch (error[0]?.extensions.code) {
          case 'NOT_FOUND':
            datadogLogs.logger.error(
              'Question was not found, it is used for task creation',
              { historyReviewId },
              toError(error),
            );
            return;
          default:
            datadogLogs.logger.error(
              'Either creating task or linking it to the finding failed',
              { historyReviewId },
              toError(error),
            );
            break;
        }
      }
      toast.error({ title: t('errorMessages.createFailed', { entity: t('entities.task') }) });
    }
  };

  return {
    toggleIgnoreState,
    createRisk,
    createTask,
  };
};

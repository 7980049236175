/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type UpdateReportMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
  reportInput: Types.Reports_Set_Input;
}>;

export type UpdateReportMutation = {
  __typename?: 'mutation_root';
  update_reports_by_pk?: { __typename?: 'reports'; id: string };
};

export const UpdateReportDocument = `
    mutation UpdateReport($id: uuid!, $reportInput: reports_set_input!) {
  update_reports_by_pk(pk_columns: {id: $id}, _set: $reportInput) {
    id
  }
}
    ` as string & TypedDocumentNode<UpdateReportMutation, UpdateReportMutationVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    UpdateReport: build.mutation<UpdateReportMutation, UpdateReportMutationVariables>({
      query: (variables) => ({ document: UpdateReportDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useUpdateReportMutation } = injectedRtkApi;

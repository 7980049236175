import {
  chakra,
  Flex,
  FlexProps,
  SystemStyleObject,
  Tag,
  TagLabel,
  Text,
  ThemeTypings,
} from '@chakra-ui/react';
import { Group } from '@visx/group';
import { LegendOrdinal } from '@visx/legend';
import { FormattedLabel } from '@visx/legend/lib/types';
import { withParentSize } from '@visx/responsive';
import {
  WithParentSizeProps,
  WithParentSizeProvidedProps,
} from '@visx/responsive/lib/enhancers/withParentSize';
import { scaleOrdinal } from '@visx/scale';
import { Pie } from '@visx/shape';
import { createContext, ReactNode, useContext, useMemo } from 'react';

import {
  getPadAngle,
  getPieArcColor,
  getPieColor,
  getPieLabel,
  getPieValue,
  indexComparator,
  itemWithIndex,
} from './utils';

export interface PieDatum {
  label: string;
  value: number;
  color: ThemeTypings['colorSchemes'];
}

interface PieChartProps {
  data: PieDatum[];
  halfMode?: boolean;
  donutThickness?: number;
  children: React.ReactNode;
}
const Context = createContext<PieChartProps | null>(null);

function usePieChartContext() {
  const context = useContext(Context);

  if (!context) {
    throw new Error('Graph and Legend components should be wrapped in <PieChart />');
  }

  return { context };
}

export const PieChart = (props: PieChartProps) => {
  return <Context.Provider value={props}>{props.children}</Context.Provider>;
};

type GraphProps = { children?: ReactNode } & WithParentSizeProvidedProps & WithParentSizeProps;
function usePieChartConfig(props: GraphProps) {
  const { context } = usePieChartContext();
  return useMemo(() => {
    const width = props.parentWidth ?? 0;
    const height = props.parentHeight ?? 0;

    const innerWidth = width;
    const innerHeight = height * (context.halfMode ? 2 : 1);

    return {
      width,
      height,
      innerWidth,
      innerHeight,
      radius: Math.min(innerWidth, innerHeight) / 2,
      centerY: innerHeight / 2,
      centerX: innerWidth / 2,
      donutThickness: context.donutThickness ?? 70,
      startAngle: context.halfMode ? Math.PI * 2.5 : Math.PI * 2,
      endAngle: context.halfMode ? Math.PI * 1.5 : 0,
    };
  }, [context.donutThickness, context.halfMode, props.parentHeight, props.parentWidth]);
}

function Graph(props: GraphProps) {
  const { context } = usePieChartContext();
  const config = usePieChartConfig(props);

  const svgStyles = useMemo<SystemStyleObject>(
    () => ({
      width: `${config.width}px`,
      height: `${config.height}px`,
      foreignObject: {
        width: '100%',
        height: '100%',
        x: '-50%',
        y: context.halfMode ? '-100%' : '-50%',
      },
    }),
    [config.width, config.height, context.halfMode],
  );
  const data = useMemo(() => context.data.map(itemWithIndex), [context.data]);

  return (
    <chakra.svg sx={svgStyles}>
      <Group top={config.centerY} left={config.centerX}>
        <Pie
          data={data}
          pieValue={getPieValue}
          fill={getPieArcColor}
          pieSort={indexComparator}
          outerRadius={config.radius}
          innerRadius={config.radius - config.donutThickness}
          cornerRadius={0}
          padAngle={getPadAngle}
          startAngle={config.startAngle}
          endAngle={config.endAngle}
        />
        <foreignObject>
          <Flex
            height={'full'}
            justifyContent={'center'}
            alignItems={context.halfMode ? 'end' : 'center'}
          >
            {props.children}
          </Flex>
        </foreignObject>
      </Group>
    </chakra.svg>
  );
}

type LegendProps = FlexProps & {
  render?: (labels: FormattedLabel<PieDatum, string>[]) => ReactNode;
};
const Legend = ({ render, ...props }: LegendProps) => {
  const { context } = usePieChartContext();

  const legendColorScale = useMemo(
    () =>
      scaleOrdinal({
        domain: context.data,
        range: context.data.map(getPieColor),
      }),
    [context.data],
  );

  return (
    <LegendOrdinal
      scale={legendColorScale}
      labelFormat={getPieLabel}
      children={
        render
          ? render
          : (labels) => {
              return (
                <Flex gap={4} {...props}>
                  {labels.map((label) => {
                    return <LegendShape key={label.index} label={label} />;
                  })}
                </Flex>
              );
            }
      }
    />
  );
};

function LegendShape({ label }: { label: FormattedLabel<PieDatum, string> }) {
  return (
    <Flex key={label.text} gap={1} alignItems={'center'}>
      <Tag colorScheme={label.datum.color}>
        <TagLabel>{label.datum.value}</TagLabel>
      </Tag>
      <Text width={'max-content'} fontSize={'xs'}>
        {label.text}
      </Text>
    </Flex>
  );
}

PieChart.Graph = withParentSize(Graph);
PieChart.Legend = Legend;

import { SimpleGrid, Stack, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import {
  getCurrentUserDetails,
  getCurrentUserSelectedOrg,
  getCurrentUserSelectedOrgRole,
} from '../user/slice';
import { CompletedTasksByOwner } from './completed-tasks-by-owner';
import { ComplianceHealth, useComplianceHealth } from './compliance-health';
import { MyTasks } from './my-tasks';
import { OrgSummary } from './org-summary';
import { ProgramSummary } from './program-summary';
import { RiskHeatMap, useRiskHeatMap } from './risk-heat-map';
import { RiskLevels, useRiskLevels } from './risk-levels';
import { ValidControlsByOwner } from './valid-controls-by-owner';
import { useVendorSummary, VendorSummary } from './vendor-summary';

export const DashboardPage = () => {
  const { t } = useTranslation();
  const userData = useAppSelector(getCurrentUserDetails);
  const {
    is_risks_module_enabled: isRisksModuleEnabled,
    is_vendors_module_enabled: isVendorsModuleEnabled,
    is_controls_module_enabled: isControlsModuleEnabled,
  } = useAppSelector(getCurrentUserSelectedOrg);
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canViewTask = userRole.permissionMap?.read_tasks;
  const complianceHealth = useComplianceHealth();
  const vendorSummary = useVendorSummary();
  const riskLevels = useRiskLevels();
  const riskHeatMap = useRiskHeatMap();

  return (
    <Stack spacing={6}>
      <Text fontSize={'3xl'} fontWeight={'semibold'}>
        {t('dashboard.subheading', { userName: userData?.user?.displayName })}
      </Text>

      <Stack spacing={6}>
        {isControlsModuleEnabled && <OrgSummary />}
        {isControlsModuleEnabled && <ProgramSummary />}
        <SimpleGrid columns={{ base: 1, xl: 2 }} spacing={6}>
          {isControlsModuleEnabled && <ComplianceHealth {...complianceHealth} />}
          {isVendorsModuleEnabled && <VendorSummary {...vendorSummary} />}
          {isRisksModuleEnabled && <RiskHeatMap {...riskHeatMap} />}
          {isRisksModuleEnabled && <RiskLevels {...riskLevels} />}
          {isControlsModuleEnabled && <ValidControlsByOwner />}
          <CompletedTasksByOwner />
        </SimpleGrid>
        {canViewTask && <MyTasks />}
      </Stack>
    </Stack>
  );
};

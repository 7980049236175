/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type RemoveControlMutationVariables = Types.Exact<{
  program_id: Types.Scalars['uuid']['input'];
  control_id: Types.Scalars['uuid']['input'];
}>;

export type RemoveControlMutation = {
  __typename?: 'mutation_root';
  delete_program_controls_by_pk?: { __typename?: 'program_controls'; control_id: string };
};

export const RemoveControlDocument = `
    mutation RemoveControl($program_id: uuid!, $control_id: uuid!) {
  delete_program_controls_by_pk(control_id: $control_id, program_id: $program_id) {
    control_id
  }
}
    ` as string & TypedDocumentNode<RemoveControlMutation, RemoveControlMutationVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    RemoveControl: build.mutation<RemoveControlMutation, RemoveControlMutationVariables>({
      query: (variables) => ({ document: RemoveControlDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useRemoveControlMutation } = injectedRtkApi;

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { FieldValueFragmentDoc } from '../fragments/FieldValueFragments.generated';
import { api } from '@main/graphql/shared-api';
export type GetRisksQueryVariables = Types.Exact<{
  organization_id: Types.Scalars['uuid']['input'];
}>;

export type GetRisksQuery = {
  __typename?: 'query_root';
  risks: Array<{
    __typename?: 'risks';
    id: string;
    name?: string;
    internal_id?: string;
    description?: string;
    assessment_status: ClientTypes.RiskAssessmentStatus;
    organization_id: string;
    review_frequency: Types.Frequencies_Enum;
    last_reviewed_date?: string;
    treatment_plan: Types.Treatment_Plan_Enum;
    treatment_details?: string;
    assignee?: { __typename?: 'users'; id: string; avatarUrl: string; displayName: string };
    risk_controls: Array<{
      __typename?: 'risk_controls';
      is_control_primary: boolean;
      control: { __typename?: 'controls'; status: ClientTypes.ControlStatus };
    }>;
    tags: Array<{
      __typename?: 'risk_tags';
      id: string;
      tag: { __typename?: 'organization_risk_tags'; id: string; name: string };
    }>;
    categories: Array<{
      __typename?: 'risk_categories';
      id: string;
      category: { __typename?: 'organization_risk_categories'; id: string; name: string };
    }>;
    inherent_impact?: { __typename?: 'risk_impacts'; name: string };
    inherent_likelihood?: { __typename?: 'risk_likelihoods'; name: string };
    residual_impact?: { __typename?: 'risk_impacts'; name: string };
    residual_likelihood?: { __typename?: 'risk_likelihoods'; name: string };
    field_values: Array<{
      __typename?: 'field_values';
      field_config_id: string;
      entity_name: Types.Field_Entities_Enum;
      field_type: Types.Field_Types_Enum;
      text_field_value?: {
        __typename?: 'text_field_values';
        field_value_id: string;
        value: string;
      };
      select_field_value?: {
        __typename?: 'select_field_values';
        field_value_id: string;
        select_field_option_id: string;
      };
    }>;
  }>;
};

export const GetRisksDocument = `
    query GetRisks($organization_id: uuid!) {
  risks(
    order_by: {internal_id: asc}
    where: {organization_id: {_eq: $organization_id}}
  ) {
    id
    name
    internal_id
    description
    assignee {
      id
      avatarUrl
      displayName
    }
    assessment_status
    organization_id
    review_frequency
    last_reviewed_date
    risk_controls {
      is_control_primary
      control {
        status
      }
    }
    tags {
      id
      tag {
        id
        name
      }
    }
    categories {
      id
      category {
        id
        name
      }
    }
    inherent_impact {
      name
    }
    inherent_likelihood {
      name
    }
    residual_impact {
      name
    }
    residual_likelihood {
      name
    }
    treatment_plan
    treatment_details
    field_values {
      ...FieldValue
    }
  }
}
    ${FieldValueFragmentDoc}` as string & TypedDocumentNode<GetRisksQuery, GetRisksQueryVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetRisks: build.query<GetRisksQuery, GetRisksQueryVariables>({
      query: (variables) => ({ document: GetRisksDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetRisksQuery, useLazyGetRisksQuery } = injectedRtkApi;

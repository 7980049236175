/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type InviteUserMutationVariables = Types.Exact<{
  object: Types.InviteUserInput;
}>;

export type InviteUserMutation = {
  __typename?: 'mutation_root';
  invite_user?: {
    __typename?: 'InviteUserOutput';
    message: string;
    status: number;
    type?: Types.UserInviteResult;
  };
};

export const InviteUserDocument = `
    mutation InviteUser($object: InviteUserInput!) {
  invite_user(object: $object) {
    message
    status
    type
  }
}
    ` as string & TypedDocumentNode<InviteUserMutation, InviteUserMutationVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    InviteUser: build.mutation<InviteUserMutation, InviteUserMutationVariables>({
      query: (variables) => ({ document: InviteUserDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useInviteUserMutation } = injectedRtkApi;

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { FieldValueFragmentDoc } from '../fragments/FieldValueFragments.generated';
import { api } from '@main/graphql/shared-api';
export type GetVendorsQueryVariables = Types.Exact<{
  organization_id: Types.Scalars['uuid']['input'];
}>;

export type GetVendorsQuery = {
  __typename?: 'query_root';
  vendors: Array<{
    __typename?: 'vendors';
    last_reviewed_date?: string;
    id: string;
    name?: string;
    description?: string;
    internal_id?: string;
    terminated_at?: string;
    approval_status?: Types.Vendor_Approval_Statuses_Enum;
    review_frequency: Types.Frequencies_Enum;
    assessment_status: ClientTypes.VendorAssessmentStatus;
    contact_email?: string;
    organization_id: string;
    risk_level?: { __typename?: 'organization_vendor_risk_levels'; id: string; name: string };
    tier?: { __typename?: 'organization_vendor_tiers'; id: string; name: string };
    owner?: { __typename?: 'users'; displayName: string; avatarUrl: string; id: string };
    questionnaires: Array<{
      __typename?: 'vendor_questionnaires';
      status: Types.Vendor_Questionnaire_Statuses_Enum;
    }>;
    field_values: Array<{
      __typename?: 'field_values';
      field_config_id: string;
      entity_name: Types.Field_Entities_Enum;
      field_type: Types.Field_Types_Enum;
      text_field_value?: {
        __typename?: 'text_field_values';
        field_value_id: string;
        value: string;
      };
      select_field_value?: {
        __typename?: 'select_field_values';
        field_value_id: string;
        select_field_option_id: string;
      };
    }>;
  }>;
};

export const GetVendorsDocument = `
    query GetVendors($organization_id: uuid!) {
  vendors(
    order_by: {internal_id: asc}
    where: {organization_id: {_eq: $organization_id}}
  ) {
    last_reviewed_date
    id
    name
    description
    internal_id
    terminated_at
    approval_status
    review_frequency
    assessment_status
    contact_email
    risk_level {
      id
      name
    }
    tier {
      id
      name
    }
    owner {
      displayName
      avatarUrl
      id
    }
    questionnaires {
      status
    }
    organization_id
    field_values {
      ...FieldValue
    }
  }
}
    ${FieldValueFragmentDoc}` as string &
  TypedDocumentNode<GetVendorsQuery, GetVendorsQueryVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetVendors: build.query<GetVendorsQuery, GetVendorsQueryVariables>({
      query: (variables) => ({ document: GetVendorsDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetVendorsQuery, useLazyGetVendorsQuery } = injectedRtkApi;

import { EvidenceIntegrationType } from '@main/graphql/client-scalars';
import { Integration_Names_Enum } from '@main/graphql/types.generated';
import { IntegrationConfigSchemas } from '@main/integrations/shared';
import { z } from 'zod';

export const OneloginConfigSchemas: IntegrationConfigSchemas<Integration_Names_Enum.Onelogin> = {
  name: Integration_Names_Enum.Onelogin,

  orgConfig: z.object({
    baseUrl: z.string().url().describe('API Base URL'),
    clientId: z.string().min(1).describe('Client ID'),
    clientSecret: z.string().min(1).describe('Client Secret'),
  }),

  evidenceConfig: z.discriminatedUnion('type', [
    z.object({
      type: z.literal(EvidenceIntegrationType.SuperUsers),
    }),

    z.object({
      type: z.literal(EvidenceIntegrationType.Mfa),
    }),
  ]),
};

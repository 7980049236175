/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type GetEvidenceByIdForOwnerChangeQueryVariables = Types.Exact<{
  evidence_id: Types.Scalars['uuid']['input'];
}>;

export type GetEvidenceByIdForOwnerChangeQuery = {
  __typename?: 'query_root';
  evidences_by_pk?: {
    __typename?: 'evidences';
    organization_id: string;
    owner_id?: string;
    acl: Array<{ __typename?: 'evidence_acl'; user_id: string }>;
  };
};

export type ChangeEvidenceOwnerMutationVariables = Types.Exact<{
  evidenceId: Types.Scalars['String']['input'];
  newOwnerId: Types.Scalars['String']['input'];
}>;

export type ChangeEvidenceOwnerMutation = {
  __typename?: 'mutation_root';
  change_evidence_owner?: { __typename?: 'GeneralActionOutput'; message: string };
};

export const GetEvidenceByIdForOwnerChangeDocument = `
    query GetEvidenceByIdForOwnerChange($evidence_id: uuid!) {
  evidences_by_pk(id: $evidence_id) {
    organization_id
    owner_id
    acl {
      user_id
    }
  }
}
    ` as string &
  TypedDocumentNode<
    GetEvidenceByIdForOwnerChangeQuery,
    GetEvidenceByIdForOwnerChangeQueryVariables
  >;
export const ChangeEvidenceOwnerDocument = `
    mutation ChangeEvidenceOwner($evidenceId: String!, $newOwnerId: String!) {
  change_evidence_owner(
    object: {evidence_id: $evidenceId, new_owner_id: $newOwnerId}
  ) {
    message
  }
}
    ` as string &
  TypedDocumentNode<ChangeEvidenceOwnerMutation, ChangeEvidenceOwnerMutationVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetEvidenceByIdForOwnerChange: build.query<
      GetEvidenceByIdForOwnerChangeQuery,
      GetEvidenceByIdForOwnerChangeQueryVariables
    >({
      query: (variables) => ({ document: GetEvidenceByIdForOwnerChangeDocument, variables }),
    }),
    ChangeEvidenceOwner: build.mutation<
      ChangeEvidenceOwnerMutation,
      ChangeEvidenceOwnerMutationVariables
    >({
      query: (variables) => ({ document: ChangeEvidenceOwnerDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useGetEvidenceByIdForOwnerChangeQuery,
  useLazyGetEvidenceByIdForOwnerChangeQuery,
  useChangeEvidenceOwnerMutation,
} = injectedRtkApi;

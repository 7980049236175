/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type CreateCustomProgramRequestMutationVariables = Types.Exact<{
  payload: Types.CreateCustomProgramRequestInput;
}>;

export type CreateCustomProgramRequestMutation = {
  __typename?: 'mutation_root';
  create_custom_program_request?: {
    __typename?: 'CreateCustomProgramRequestOutput';
    message: string;
    status: number;
  };
};

export const CreateCustomProgramRequestDocument = `
    mutation CreateCustomProgramRequest($payload: CreateCustomProgramRequestInput!) {
  create_custom_program_request(payload: $payload) {
    message
    status
  }
}
    ` as string &
  TypedDocumentNode<
    CreateCustomProgramRequestMutation,
    CreateCustomProgramRequestMutationVariables
  >;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    CreateCustomProgramRequest: build.mutation<
      CreateCustomProgramRequestMutation,
      CreateCustomProgramRequestMutationVariables
    >({
      query: (variables) => ({ document: CreateCustomProgramRequestDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useCreateCustomProgramRequestMutation } = injectedRtkApi;

import { Outlet, Route } from '@tanstack/react-router';

import { authLayout } from '../login';

const RisksLayoutPage = () => {
  return <Outlet />;
};

export const risksRoute = new Route({
  getParentRoute: () => authLayout,
  path: 'risks',
  component: RisksLayoutPage,
});

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type CreateRiskTaskMutationVariables = Types.Exact<{
  input: Types.Risk_Tasks_Insert_Input;
}>;

export type CreateRiskTaskMutation = {
  __typename?: 'mutation_root';
  insert_risk_tasks_one?: { __typename?: 'risk_tasks'; task_id: string };
};

export type UnlinkRiskTaskMutationVariables = Types.Exact<{
  taskId: Types.Scalars['uuid']['input'];
  riskId: Types.Scalars['uuid']['input'];
}>;

export type UnlinkRiskTaskMutation = {
  __typename?: 'mutation_root';
  delete_risk_tasks?: { __typename?: 'risk_tasks_mutation_response'; affected_rows: number };
};

export const CreateRiskTaskDocument = `
    mutation CreateRiskTask($input: risk_tasks_insert_input!) {
  insert_risk_tasks_one(object: $input) {
    task_id
  }
}
    ` as string & TypedDocumentNode<CreateRiskTaskMutation, CreateRiskTaskMutationVariables>;
export const UnlinkRiskTaskDocument = `
    mutation UnlinkRiskTask($taskId: uuid!, $riskId: uuid!) {
  delete_risk_tasks(where: {task_id: {_eq: $taskId}, risk_id: {_eq: $riskId}}) {
    affected_rows
  }
}
    ` as string & TypedDocumentNode<UnlinkRiskTaskMutation, UnlinkRiskTaskMutationVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    CreateRiskTask: build.mutation<CreateRiskTaskMutation, CreateRiskTaskMutationVariables>({
      query: (variables) => ({ document: CreateRiskTaskDocument, variables }),
    }),
    UnlinkRiskTask: build.mutation<UnlinkRiskTaskMutation, UnlinkRiskTaskMutationVariables>({
      query: (variables) => ({ document: UnlinkRiskTaskDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useCreateRiskTaskMutation, useUnlinkRiskTaskMutation } = injectedRtkApi;

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type UnlinkControlTaskMutationVariables = Types.Exact<{
  taskId: Types.Scalars['uuid']['input'];
  controlId: Types.Scalars['uuid']['input'];
}>;

export type UnlinkControlTaskMutation = {
  __typename?: 'mutation_root';
  delete_control_tasks?: { __typename?: 'control_tasks_mutation_response'; affected_rows: number };
};

export const UnlinkControlTaskDocument = `
    mutation UnlinkControlTask($taskId: uuid!, $controlId: uuid!) {
  delete_control_tasks(
    where: {task_id: {_eq: $taskId}, control_id: {_eq: $controlId}}
  ) {
    affected_rows
  }
}
    ` as string & TypedDocumentNode<UnlinkControlTaskMutation, UnlinkControlTaskMutationVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    UnlinkControlTask: build.mutation<
      UnlinkControlTaskMutation,
      UnlinkControlTaskMutationVariables
    >({
      query: (variables) => ({ document: UnlinkControlTaskDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useUnlinkControlTaskMutation } = injectedRtkApi;

import { Flex } from '@chakra-ui/react';
import { Route } from '@tanstack/react-router';

import { SSO } from '../features/user/sso';
import { rootRoute } from './__root';

const SSOPage = () => {
  return (
    <Flex py="12" minH={'100vh'} align={'center'} justify={'center'}>
      <SSO />
    </Flex>
  );
};

export const ssoRoute = new Route({
  getParentRoute: () => rootRoute,
  path: 'sso',
  component: SSOPage,
});

import { DBIntegrationCheck, EvidenceIntegrationType } from '@main/graphql/client-scalars';
import { Integration_Names_Enum } from '@main/graphql/types.generated';
import { IntegrationConfigSchemas } from '@main/integrations/shared';
import { z } from 'zod';

export const GcpConfigSchemas: IntegrationConfigSchemas<Integration_Names_Enum.Gcp> = {
  name: Integration_Names_Enum.Gcp,

  orgConfig: z.object({
    projectId: z.string().min(1).describe('Project ID'),
    clientEmail: z.string().email().describe('Client Email'),
    privateKey: z.string().min(1).describe('Private Key'),
  }),

  evidenceConfig: z.discriminatedUnion('type', [
    z.object({
      type: z.literal(EvidenceIntegrationType.Database),
      dbInstanceNames: z.array(z.string()),
      checks: z.array(z.nativeEnum(DBIntegrationCheck)),
    }),

    z.object({
      type: z.literal(EvidenceIntegrationType.FileSync),
      fileId: z.string().min(1),
    }),
  ]),
};

import { StreamDownloader } from './stream-downloader';

export interface FileContent {
  content: string;
}

export class FileStreamDownloader implements StreamDownloader<FileContent> {
  constructor(protected readonly encoder = new TextEncoder()) {}

  async downloadFile(
    file: FileContent,
    options?: { signal: AbortSignal } | undefined,
  ): Promise<ReadableStream<Uint8Array>> {
    if (options?.signal?.aborted) {
      throw options.signal.reason;
    }

    const getContent = () => this.encoder.encode(file.content);

    return new ReadableStream({
      start(controller) {
        if (options?.signal?.aborted) {
          controller.error(options.signal.reason);
        }

        controller.enqueue(getContent());
        controller.close();
      },
    });
  }
}

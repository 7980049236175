import { api as getReportByIdApi } from '@main/graphql/queries/GetReportById.generated';
import { api as getReportsForOrganizationApi } from '@main/graphql/queries/GetReportsForOrganization.generated';
import { formatDate } from '@main/shared/utils';
import { isColumnFilter, isColumnSort } from '@main/ui';
import { createAction, createSelector } from '@reduxjs/toolkit';

import { AppRootState } from '../../store';

export const reportIdPageLoaded = createAction<{ reportId: string }>('[reportId page] page loaded');

const getOrganizationReportsApiData = createSelector(
  (state: AppRootState, organizationId: string) => ({ organizationId, state }),
  ({ state, organizationId }) => {
    return (
      getReportsForOrganizationApi.endpoints.GetReportsForOrganization.select({
        organizationId,
      })(state).data?.reports || []
    );
  },
);

const getOrganizationReportApiData = createSelector(
  (state: AppRootState, reportId: string) => ({ reportId, state }),
  ({ state, reportId }) => {
    return getReportByIdApi.endpoints.GetReportById.select({
      id: reportId,
    })(state).data?.reports_by_pk;
  },
);

export const getOrganizationReportsList = createSelector(
  [getOrganizationReportsApiData],
  (reports) => {
    return (
      reports.map((item) => {
        return {
          id: item.id,
          type: item.type,
          name: item.name,
          description: item.description,
          createdBy: item.created_by,
          updatedAt: formatDate(item.updated_at),
          organizationId: item.organization_id,
        };
      }) || []
    );
  },
);

export type OrganizationReport = ReturnType<typeof getOrganizationReportsList>[number];

export const getReportById = createSelector([getOrganizationReportApiData], (report) => {
  if (!report) {
    return undefined;
  }

  return {
    ...report,
    filters: report.filters?.filter(isColumnFilter) ?? [],
    sorting: report.sorting?.filter(isColumnSort) ?? [],
  };
});

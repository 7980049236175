import { Card, CardBody, CardHeader, Heading, Stack, Text } from '@chakra-ui/react';
import { ControlStatus } from '@main/graphql/client-scalars';
import { Table, useTableFiltersQuery, useTableSearchQuery, useTableSortQuery } from '@main/ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { useGetOrganizationControls } from '../controls/org-controls-query';
import { useControlsTableSettings } from '../controls/table-settings';
import { useControlsTableColumns } from '../controls/use-table-columns';
import { getCurrentUserSelectedOrgId } from '../user/slice';
import {
  ALERT_CENTER_TABLE_FILTER_PARAM_NAME,
  ALERT_CENTER_TABLE_SORT_PARAM_NAME,
} from './constants';

export const AlertCenter = () => {
  const { t } = useTranslation();

  const organizationId = useAppSelector(getCurrentUserSelectedOrgId);

  const { controls, isLoading: isLoadingControls } = useGetOrganizationControls(organizationId);
  const alerts = useMemo(() => {
    return (
      controls.filter((control) => {
        return control.status === ControlStatus.AT_RISK || control.status === ControlStatus.FAILING;
      }) || []
    );
  }, [controls]);

  const tableItemName = useMemo(() => {
    return {
      singular: t('alerts.heading').toLowerCase(),
      plural: t('alerts.heading').toLowerCase(),
      hideSubheading: true,
    };
  }, [t]);

  const { columnVisibility, setColumnVisibility } = useControlsTableSettings(
    'alerts:controls-table:column-visibility',
  );
  const columns = useControlsTableColumns({
    page: 'alerts-page',
  });
  const [columnFilters, setColumnFilters] = useTableFiltersQuery({
    columns,
    searchParam: ALERT_CENTER_TABLE_FILTER_PARAM_NAME,
  });
  const [globalFilter, setGlobalFilter] = useTableSearchQuery({ searchParam: 'search' });
  const [sorting, setSorting] = useTableSortQuery({
    searchParam: ALERT_CENTER_TABLE_SORT_PARAM_NAME,
  });

  return (
    <Stack spacing={6}>
      <Text fontSize={'3xl'} fontWeight={'semibold'}>
        {t('alerts.breadcrumbTitle')}
      </Text>

      <Card variant="table-styles">
        <CardHeader>
          <Heading size="md">{t('alerts.heading')}</Heading>
        </CardHeader>

        <CardBody>
          <Table
            minW="900px"
            entity="control"
            isLoading={isLoadingControls}
            data={alerts}
            columns={columns}
            itemName={tableItemName}
            pageSize={15}
            columnVisibility={columnVisibility}
            onColumnVisibilityChange={setColumnVisibility}
            columnFilters={columnFilters}
            onColumnFiltersChange={setColumnFilters}
            globalFilter={globalFilter}
            onGlobalFilterChange={setGlobalFilter}
            sorting={sorting}
            onSortingChange={setSorting}
          />
        </CardBody>
      </Card>
    </Stack>
  );
};

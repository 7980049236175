import { Box, Divider, Flex, Heading, Stack, Text, useColorModeValue } from '@chakra-ui/react';
import { SidebarMenu } from '@main/ui';
import { Outlet, Route } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

import { useAccountSettingsMenu } from '../../../features/layout/authenticated-menu';
import { settingsRoute } from '../';

const AccountSettingsLayout = () => {
  const { t } = useTranslation();
  const cardBorderColor = useColorModeValue('gray.200', 'gray.700');
  const cardbgColor = useColorModeValue('white', 'gray.800');
  const { menuItems } = useAccountSettingsMenu();
  return (
    <Stack spacing={6}>
      <Text fontSize={'3xl'} fontWeight={'semibold'}>
        {t('settings.account.heading')}
      </Text>
      <Flex
        mb="14"
        bg={cardbgColor}
        borderColor={cardBorderColor}
        borderRadius="lg"
        borderWidth={'1px'}
        direction={{ base: 'column', lg: 'row' }}
      >
        <Box
          w={{ base: 'full', md: 64 }}
          borderColor={{ base: 'none', lg: cardBorderColor }}
          borderRightWidth={{ base: '0', lg: '1px' }}
          px={3}
          py={6}
        >
          <SidebarMenu items={menuItems} />
        </Box>
        <Flex direction="column" gap="0" justifyContent="flex-start" grow={1} overflow={'auto'}>
          <Box py="6" px={[4, 4, 8]}>
            <Heading as="h2" size="md">
              {t('settings.account.profile.personalInformation.heading')}
            </Heading>
            <Text fontSize="14" color={'gray.500'}>
              {t('settings.account.profile.personalInformation.subheading')}
            </Text>
          </Box>

          <Divider orientation="horizontal" />
          <Box w="100%" py="6" px={[4, 4, 8]}>
            <Outlet />
          </Box>
        </Flex>
      </Flex>
    </Stack>
  );
};

export const accountSettingsLayoutRoute = new Route({
  getParentRoute: () => settingsRoute,
  path: 'account',
  component: AccountSettingsLayout,
});

import { usePrevious } from '@chakra-ui/react';
import { isNonNullable } from '@main/shared/utils';
import { HBarChart, HBarProps } from '@main/ui';
import { RouteApi } from '@tanstack/react-router';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { getCurrentUserSelectedOrgId } from '../user/slice';
import { DashboardStatsQuery, useDashboardStatsQuery } from './dashboard.generated';

const dashboardRouteApi = new RouteApi({ id: '/dashboard' });

export const CompletedTasksByOwner = () => {
  const { t } = useTranslation();
  const orgId = useAppSelector(getCurrentUserSelectedOrgId);
  const { drawerEntity } = dashboardRouteApi.useSearch({
    select: ({ drawerEntity }) => ({ drawerEntity }),
  });
  const previousEntity = usePrevious(drawerEntity);
  const { data, isLoading, refetch } = useDashboardStatsQuery(
    { orgId },
    { refetchOnMountOrArgChange: true },
  );
  const bars = data?.user_tasks_stats_view.map(decodeStat).filter(isNonNullable) || [];

  useEffect(() => {
    if (!drawerEntity && previousEntity === 'task') {
      refetch();
    }
  }, [drawerEntity, previousEntity, refetch]);

  return (
    <HBarChart
      h="340px"
      heading={t('dashboard.completedTasksByOwner.heading')}
      isLoading={isLoading}
      bars={bars}
      loadingState={{
        barsCount: 4,
      }}
      emptyState={{
        heading: t('dashboard.completedTasksByOwner.emptyState.heading'),
        subheading: t('dashboard.completedTasksByOwner.emptyState.subheading'),
      }}
    />
  );
};

function decodeStat(stat: DashboardStatsQuery['user_tasks_stats_view'][number]): HBarProps | null {
  if (!stat.user) {
    return null;
  }

  return {
    key: stat.user.id,
    label: stat.user.displayName,
    value: stat.completed_tasks_ratio || 0,
  };
}

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type GetProgramsQueryVariables = Types.Exact<{
  orgId: Types.Scalars['uuid']['input'];
}>;

export type GetProgramsQuery = {
  __typename?: 'query_root';
  programs: Array<{
    __typename?: 'programs';
    id: string;
    name: string;
    created_at?: string;
    framework?: { __typename: 'frameworks'; name: string };
    template_framework?: { __typename: 'template_frameworks'; name: string };
  }>;
};

export const GetProgramsDocument = `
    query GetPrograms($orgId: uuid!) {
  programs(where: {organization_id: {_eq: $orgId}}, order_by: {created_at: asc}) {
    id
    name
    created_at
    framework {
      name
      __typename
    }
    template_framework {
      name
      __typename
    }
  }
}
    ` as string & TypedDocumentNode<GetProgramsQuery, GetProgramsQueryVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetPrograms: build.query<GetProgramsQuery, GetProgramsQueryVariables>({
      query: (variables) => ({ document: GetProgramsDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetProgramsQuery, useLazyGetProgramsQuery } = injectedRtkApi;

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type GetRiskClassificationsQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['uuid']['input'];
}>;

export type GetRiskClassificationsQuery = {
  __typename?: 'query_root';
  impacts: Array<{
    __typename?: 'risk_impacts';
    id: string;
    name: string;
    description: string;
    order?: string;
  }>;
  likelihoods: Array<{
    __typename?: 'risk_likelihoods';
    id: string;
    name: string;
    description: string;
    order?: string;
  }>;
};

export type GetRiskLikelihoodsQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['uuid']['input'];
}>;

export type GetRiskLikelihoodsQuery = {
  __typename?: 'query_root';
  likelihoods: Array<{
    __typename?: 'risk_likelihoods';
    id: string;
    name: string;
    description: string;
    order?: string;
  }>;
};

export type GetRiskImpactsQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['uuid']['input'];
}>;

export type GetRiskImpactsQuery = {
  __typename?: 'query_root';
  impacts: Array<{
    __typename?: 'risk_impacts';
    id: string;
    name: string;
    description: string;
    order?: string;
  }>;
};

export type CreateRiskImpactMutationVariables = Types.Exact<{
  object: Types.Risk_Impacts_Insert_Input;
}>;

export type CreateRiskImpactMutation = {
  __typename?: 'mutation_root';
  insert_risk_impacts_one?: { __typename?: 'risk_impacts'; id: string };
};

export type CreateRiskLikelihoodMutationVariables = Types.Exact<{
  object: Types.Risk_Likelihoods_Insert_Input;
}>;

export type CreateRiskLikelihoodMutation = {
  __typename?: 'mutation_root';
  insert_risk_likelihoods_one?: { __typename?: 'risk_likelihoods'; id: string };
};

export type DeleteRiskImpactMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
}>;

export type DeleteRiskImpactMutation = {
  __typename?: 'mutation_root';
  delete_risk_impacts_by_pk?: { __typename?: 'risk_impacts'; name: string };
};

export type DeleteRiskLikelihoodMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
}>;

export type DeleteRiskLikelihoodMutation = {
  __typename?: 'mutation_root';
  delete_risk_likelihoods_by_pk?: { __typename?: 'risk_likelihoods'; name: string };
};

export type UpdateRiskImpactMutationVariables = Types.Exact<{
  input: Types.Risk_Impacts_Set_Input;
  id: Types.Scalars['uuid']['input'];
}>;

export type UpdateRiskImpactMutation = {
  __typename?: 'mutation_root';
  update_risk_impacts_by_pk?: {
    __typename?: 'risk_impacts';
    id: string;
    name: string;
    description: string;
    order?: string;
  };
};

export type UpdateRiskImpactsMutationVariables = Types.Exact<{
  updates: Array<Types.Risk_Impacts_Updates> | Types.Risk_Impacts_Updates;
}>;

export type UpdateRiskImpactsMutation = {
  __typename?: 'mutation_root';
  update_risk_impacts_many?: Array<{
    __typename?: 'risk_impacts_mutation_response';
    returning: Array<{ __typename?: 'risk_impacts'; id: string }>;
  }>;
};

export type UpdateRiskLikelihoodMutationVariables = Types.Exact<{
  input: Types.Risk_Likelihoods_Set_Input;
  id: Types.Scalars['uuid']['input'];
}>;

export type UpdateRiskLikelihoodMutation = {
  __typename?: 'mutation_root';
  update_risk_likelihoods_by_pk?: {
    __typename?: 'risk_likelihoods';
    id: string;
    name: string;
    description: string;
    order?: string;
  };
};

export type UpdateRiskLikelihoodsMutationVariables = Types.Exact<{
  updates: Array<Types.Risk_Likelihoods_Updates> | Types.Risk_Likelihoods_Updates;
}>;

export type UpdateRiskLikelihoodsMutation = {
  __typename?: 'mutation_root';
  update_risk_likelihoods_many?: Array<{
    __typename?: 'risk_likelihoods_mutation_response';
    returning: Array<{ __typename?: 'risk_likelihoods'; id: string }>;
  }>;
};

export type RiskImpactFragment = {
  __typename?: 'risk_impacts';
  id: string;
  name: string;
  description: string;
  order?: string;
};

export type RiskLikelihoodFragment = {
  __typename?: 'risk_likelihoods';
  id: string;
  name: string;
  description: string;
  order?: string;
};

export const RiskImpactFragmentDoc = `
    fragment RiskImpact on risk_impacts {
  id
  name
  description
  order
}
    `;
export const RiskLikelihoodFragmentDoc = `
    fragment RiskLikelihood on risk_likelihoods {
  id
  name
  description
  order
}
    `;
export const GetRiskClassificationsDocument = `
    query GetRiskClassifications($organizationId: uuid!) {
  impacts: risk_impacts(
    where: {organization_id: {_eq: $organizationId}}
    order_by: [{order: asc_nulls_last}, {created_at: asc}]
  ) {
    ...RiskImpact
  }
  likelihoods: risk_likelihoods(
    where: {organization_id: {_eq: $organizationId}}
    order_by: {order: asc_nulls_last, created_at: asc}
  ) {
    ...RiskLikelihood
  }
}
    ${RiskImpactFragmentDoc}
${RiskLikelihoodFragmentDoc}` as string &
  TypedDocumentNode<GetRiskClassificationsQuery, GetRiskClassificationsQueryVariables>;
export const GetRiskLikelihoodsDocument = `
    query GetRiskLikelihoods($organizationId: uuid!) {
  likelihoods: risk_likelihoods(
    where: {organization_id: {_eq: $organizationId}}
    order_by: {order: asc}
  ) {
    ...RiskLikelihood
  }
}
    ${RiskLikelihoodFragmentDoc}` as string &
  TypedDocumentNode<GetRiskLikelihoodsQuery, GetRiskLikelihoodsQueryVariables>;
export const GetRiskImpactsDocument = `
    query GetRiskImpacts($organizationId: uuid!) {
  impacts: risk_impacts(
    where: {organization_id: {_eq: $organizationId}}
    order_by: {order: asc}
  ) {
    ...RiskImpact
  }
}
    ${RiskImpactFragmentDoc}` as string &
  TypedDocumentNode<GetRiskImpactsQuery, GetRiskImpactsQueryVariables>;
export const CreateRiskImpactDocument = `
    mutation CreateRiskImpact($object: risk_impacts_insert_input!) {
  insert_risk_impacts_one(object: $object) {
    id
  }
}
    ` as string & TypedDocumentNode<CreateRiskImpactMutation, CreateRiskImpactMutationVariables>;
export const CreateRiskLikelihoodDocument = `
    mutation CreateRiskLikelihood($object: risk_likelihoods_insert_input!) {
  insert_risk_likelihoods_one(object: $object) {
    id
  }
}
    ` as string &
  TypedDocumentNode<CreateRiskLikelihoodMutation, CreateRiskLikelihoodMutationVariables>;
export const DeleteRiskImpactDocument = `
    mutation DeleteRiskImpact($id: uuid!) {
  delete_risk_impacts_by_pk(id: $id) {
    name
  }
}
    ` as string & TypedDocumentNode<DeleteRiskImpactMutation, DeleteRiskImpactMutationVariables>;
export const DeleteRiskLikelihoodDocument = `
    mutation DeleteRiskLikelihood($id: uuid!) {
  delete_risk_likelihoods_by_pk(id: $id) {
    name
  }
}
    ` as string &
  TypedDocumentNode<DeleteRiskLikelihoodMutation, DeleteRiskLikelihoodMutationVariables>;
export const UpdateRiskImpactDocument = `
    mutation UpdateRiskImpact($input: risk_impacts_set_input!, $id: uuid!) {
  update_risk_impacts_by_pk(pk_columns: {id: $id}, _set: $input) {
    ...RiskImpact
  }
}
    ${RiskImpactFragmentDoc}` as string &
  TypedDocumentNode<UpdateRiskImpactMutation, UpdateRiskImpactMutationVariables>;
export const UpdateRiskImpactsDocument = `
    mutation UpdateRiskImpacts($updates: [risk_impacts_updates!]!) {
  update_risk_impacts_many(updates: $updates) {
    returning {
      id
    }
  }
}
    ` as string & TypedDocumentNode<UpdateRiskImpactsMutation, UpdateRiskImpactsMutationVariables>;
export const UpdateRiskLikelihoodDocument = `
    mutation UpdateRiskLikelihood($input: risk_likelihoods_set_input!, $id: uuid!) {
  update_risk_likelihoods_by_pk(pk_columns: {id: $id}, _set: $input) {
    ...RiskLikelihood
  }
}
    ${RiskLikelihoodFragmentDoc}` as string &
  TypedDocumentNode<UpdateRiskLikelihoodMutation, UpdateRiskLikelihoodMutationVariables>;
export const UpdateRiskLikelihoodsDocument = `
    mutation UpdateRiskLikelihoods($updates: [risk_likelihoods_updates!]!) {
  update_risk_likelihoods_many(updates: $updates) {
    returning {
      id
    }
  }
}
    ` as string &
  TypedDocumentNode<UpdateRiskLikelihoodsMutation, UpdateRiskLikelihoodsMutationVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetRiskClassifications: build.query<
      GetRiskClassificationsQuery,
      GetRiskClassificationsQueryVariables
    >({
      query: (variables) => ({ document: GetRiskClassificationsDocument, variables }),
    }),
    GetRiskLikelihoods: build.query<GetRiskLikelihoodsQuery, GetRiskLikelihoodsQueryVariables>({
      query: (variables) => ({ document: GetRiskLikelihoodsDocument, variables }),
    }),
    GetRiskImpacts: build.query<GetRiskImpactsQuery, GetRiskImpactsQueryVariables>({
      query: (variables) => ({ document: GetRiskImpactsDocument, variables }),
    }),
    CreateRiskImpact: build.mutation<CreateRiskImpactMutation, CreateRiskImpactMutationVariables>({
      query: (variables) => ({ document: CreateRiskImpactDocument, variables }),
    }),
    CreateRiskLikelihood: build.mutation<
      CreateRiskLikelihoodMutation,
      CreateRiskLikelihoodMutationVariables
    >({
      query: (variables) => ({ document: CreateRiskLikelihoodDocument, variables }),
    }),
    DeleteRiskImpact: build.mutation<DeleteRiskImpactMutation, DeleteRiskImpactMutationVariables>({
      query: (variables) => ({ document: DeleteRiskImpactDocument, variables }),
    }),
    DeleteRiskLikelihood: build.mutation<
      DeleteRiskLikelihoodMutation,
      DeleteRiskLikelihoodMutationVariables
    >({
      query: (variables) => ({ document: DeleteRiskLikelihoodDocument, variables }),
    }),
    UpdateRiskImpact: build.mutation<UpdateRiskImpactMutation, UpdateRiskImpactMutationVariables>({
      query: (variables) => ({ document: UpdateRiskImpactDocument, variables }),
    }),
    UpdateRiskImpacts: build.mutation<
      UpdateRiskImpactsMutation,
      UpdateRiskImpactsMutationVariables
    >({
      query: (variables) => ({ document: UpdateRiskImpactsDocument, variables }),
    }),
    UpdateRiskLikelihood: build.mutation<
      UpdateRiskLikelihoodMutation,
      UpdateRiskLikelihoodMutationVariables
    >({
      query: (variables) => ({ document: UpdateRiskLikelihoodDocument, variables }),
    }),
    UpdateRiskLikelihoods: build.mutation<
      UpdateRiskLikelihoodsMutation,
      UpdateRiskLikelihoodsMutationVariables
    >({
      query: (variables) => ({ document: UpdateRiskLikelihoodsDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useGetRiskClassificationsQuery,
  useLazyGetRiskClassificationsQuery,
  useGetRiskLikelihoodsQuery,
  useLazyGetRiskLikelihoodsQuery,
  useGetRiskImpactsQuery,
  useLazyGetRiskImpactsQuery,
  useCreateRiskImpactMutation,
  useCreateRiskLikelihoodMutation,
  useDeleteRiskImpactMutation,
  useDeleteRiskLikelihoodMutation,
  useUpdateRiskImpactMutation,
  useUpdateRiskImpactsMutation,
  useUpdateRiskLikelihoodMutation,
  useUpdateRiskLikelihoodsMutation,
} = injectedRtkApi;

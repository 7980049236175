import { Box, Flex, TabPanel, Text, useBreakpointValue, useColorModeValue } from '@chakra-ui/react';
import { createContext, ReactNode, useContext, useEffect, useState } from 'react';

import { Page404 } from '../error-page';
import { NoPermissionPlaceholder } from '../placeholder';
import { DrawerSkeleton } from '../skeleton';
import { TabId, TabItem, Tabs } from '../tabs';
import { DrawerActions } from './drawer-actions';
import { useDrawer } from './use-drawer';

type QueryParams = {
  entityId?: string;
  entity?: string;
};
type ContextProps = {
  isLoading: boolean;
} & QueryParams;

const Context = createContext<ContextProps | null>(null);
const useDrawerContext = () => {
  const context = useContext(Context);
  if (!context) {
    throw new Error(
      'Drawer.Tabs and Drawer.Toolbar components should be used within a Drawer.Layout',
    );
  }
  return context;
};

export type DrawerLayoutProps = {
  children: ReactNode;
  isLoading: boolean;
  canView: boolean | undefined;
  isNotFound: boolean;
};

const Layout = ({ children, isLoading, isNotFound = false, canView = true }: DrawerLayoutProps) => {
  const drawer = useDrawer();
  const [queryParams, setQueryParams] = useState<QueryParams>({
    entityId: undefined,
    entity: undefined,
  });

  useEffect(() => {
    const listener = drawer.onDrawer((data) => {
      setQueryParams({ entityId: data?.entityId, entity: data?.entity });
    });

    return () => listener();
  }, [drawer]);

  if (!canView) {
    return <NoPermissionPlaceholder />;
  }

  if (!isLoading && queryParams.entityId && isNotFound) {
    return <Page404 />;
  }

  return (
    <Context.Provider
      value={{ isLoading, entityId: queryParams.entityId, entity: queryParams.entity }}
    >
      {children}
    </Context.Provider>
  );
};

const DrawerTabs = <TId extends TabId = TabId>({
  tabs,
  children,
  isLazy = true,
}: {
  tabs: TabItem<TId>[];
  children?: ReactNode;
  isLazy?: boolean;
}) => {
  const { isLoading, entityId, entity } = useDrawerContext();
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: 'md' });
  const isDrawerLoading = isLoading || (entity && !entityId);

  return (
    <Tabs tabs={tabs} isLazy={isLazy}>
      <Flex flexDirection={'row-reverse'}>
        <Tabs.Sidebar />

        {isDrawerLoading ? (
          <DrawerSkeleton />
        ) : (
          <Box
            flexGrow={1}
            py={6}
            px={isMobile ? 6 : 9}
            h={'calc(100vh - 48px)'}
            overflowX={'hidden'}
            overflowY={'scroll'}
          >
            {children}
            <Tabs.Panels
              renderPanel={(tab, content) => {
                return (
                  <TabPanel key={tab.id} px={0} display={'flex'} flexDirection={'column'} gap={6}>
                    <PanelTitle tab={tab} />
                    {content}
                  </TabPanel>
                );
              }}
            />
          </Box>
        )}
      </Flex>
    </Tabs>
  );
};

const PanelTitle = ({ tab }: { tab: TabItem<TabId> }) => {
  const panelTitleColor = useColorModeValue('gray.800', 'gray.100');

  if (tab.hideTabTitle) {
    return null;
  }

  return (
    <Text fontWeight={'semibold'} color={panelTitleColor}>
      {tab.label}
    </Text>
  );
};

export const Drawer = {
  Layout,
  Tabs: DrawerTabs,
  Toolbar: DrawerActions,
};

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
export type OrganizationUserDetailsFragment = {
  __typename?: 'organization_users';
  user_id: string;
  disabled: boolean;
  user: {
    __typename?: 'users';
    id: string;
    displayName: string;
    avatarUrl: string;
    email?: string;
  };
  role: { __typename?: 'roles'; id: string; name: string; system_role?: Types.System_Roles_Enum };
};

export const OrganizationUserDetailsFragmentDoc = `
    fragment OrganizationUserDetails on organization_users {
  user_id
  disabled
  user {
    id
    displayName
    avatarUrl
    email
  }
  role {
    id
    name
    system_role
  }
}
    `;

import { Box } from '@chakra-ui/react';

import { GlobalSearchInput, GlobalSearchInputProps } from './global-search-input';
import {
  GlobalSearchProvider,
  GlobalSearchProviderProps,
  GlobalSearchResult,
} from './global-search-provider';
import {
  GlobalSearchNoResults,
  GlobalSearchResults,
  GlobalSearchResultsContainer,
  GlobalSearchResultsProps,
} from './global-search-results';
import { GlobalSearchTabs } from './global-search-tabs';

export type { GlobalSearchResult };

export interface GlobalSearchProps<TResult extends GlobalSearchResult> {
  provider: GlobalSearchProviderProps<TResult>;
  results: GlobalSearchResultsProps<TResult>;
  input?: GlobalSearchInputProps;
}

export function GlobalSearch<TResult extends GlobalSearchResult>(
  props: GlobalSearchProps<TResult>,
) {
  return (
    <GlobalSearchProvider {...props.provider}>
      <Box px="2" py="3">
        <GlobalSearchInput autoSelect="always" {...props.input} />
      </Box>
      <GlobalSearchTabs px="4" pb="2" />
      <GlobalSearchResultsContainer>
        <GlobalSearchResults p="4" {...props.results} />
        <GlobalSearchNoResults />
      </GlobalSearchResultsContainer>
    </GlobalSearchProvider>
  );
}

import {
  Button,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  useColorModeValue,
} from '@chakra-ui/react';
import { ArrowDownIcon, ArrowsUpDownIcon, ArrowUpIcon } from '@heroicons/react/24/outline';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { TaskSidebarDataFragment as Task } from './tasks.fragment.generated';

export type SortCriteria = {
  property: Extract<keyof Task, 'created_at' | 'updated_at' | 'due_date' | 'status'>;
  isAscending: boolean;
};

const sortValues: SortCriteria['property'][] = ['created_at', 'updated_at', 'due_date', 'status'];

export const SidebarSort = ({
  sorts,
  setSorts,
}: {
  sorts: SortCriteria;
  setSorts: Dispatch<SetStateAction<SortCriteria>>;
}) => {
  const { t } = useTranslation();
  const sortBtnColor = useColorModeValue('gray.400', 'gray.500');
  const optionColor = useColorModeValue('gray.700', 'gray.300');
  const isSortActive = !(sorts.property === 'created_at' && sorts.isAscending);

  return (
    <Menu closeOnSelect={false}>
      <MenuButton
        variant={'outline'}
        rounded={36}
        size={'xs'}
        as={Button}
        leftIcon={<ArrowsUpDownIcon />}
        color={isSortActive ? undefined : sortBtnColor}
        colorScheme={isSortActive ? 'blue' : 'gray'}
      >
        {t('tasks.sidebar.sort')}
      </MenuButton>
      <MenuList fontSize={'xs'}>
        <MenuOptionGroup type="radio" defaultValue={'created_at'}>
          {sortValues.map((property) => (
            <MenuItemOption
              key={property}
              value={property}
              color={optionColor}
              icon={sorts.isAscending ? <ArrowUpIcon /> : <ArrowDownIcon />}
              onClick={(e) => {
                if (e.currentTarget.ariaChecked === 'true') {
                  return setSorts((prev) => ({ ...prev, isAscending: !prev.isAscending }));
                }
                setSorts({
                  property,
                  isAscending: true,
                });
              }}
            >
              {t(`tasks.sidebar.sortValues.${property}`)}
            </MenuItemOption>
          ))}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
};

export function taskSort(taskA: Task, taskB: Task, criteria: SortCriteria) {
  const result = () => {
    const valueA = taskA[criteria.property];
    const valueB = taskB[criteria.property];

    if (!valueA || !valueB) {
      return 0;
    }

    if (valueA > valueB) {
      return 1;
    } else if (valueA < valueB) {
      return -1;
    } else {
      return 0;
    }
  };

  return criteria.isAscending ? result() : result() * -1;
}

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend, { HttpBackendOptions } from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init<HttpBackendOptions>({
    supportedLngs: ['en-US'],
    ns: ['translation', 'ui', 'integrations'],
    fallbackLng: {
      default: ['en-US'],
    },
    debug: false,
    returnNull: false,
    backend: {
      loadPath: (languages, namespaces) => `${languages[0]}/${namespaces[0]}`,
      async request(options, url, payload, callback) {
        const [lng, ns] = url.split('/');
        const data = await import(`./locales/${lng}/${ns}.json`);
        callback(null, { status: 200, data: data.default });
      },
    },
  });

export default i18n;

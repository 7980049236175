import { Flex, Spinner } from '@chakra-ui/react';
import { Route, RouteApi } from '@tanstack/react-router';
import { useEffect } from 'react';
import { z } from 'zod';

import { impersonatePageLoaded } from '../features/user/slice';
import { useAppDispatch } from '../hooks/redux-toolkit-hooks';
import { rootRoute } from './__root';

const impersonateRouteApi = new RouteApi({ id: '/impersonate' });

const ImpersonatePage = () => {
  const { token } = impersonateRouteApi.useSearch();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(impersonatePageLoaded({ token }));
  }, [dispatch, token]);

  return (
    <Flex py="12" minH="100vh" alignItems={'center'} justifyContent={'center'}>
      <Spinner />
    </Flex>
  );
};

export const impersonateRoute = new Route({
  getParentRoute: () => rootRoute,
  path: 'impersonate',
  validateSearch: z.object({
    token: z.string(),
  }),
  component: ImpersonatePage,
});

import { Text, useColorModeValue, VStack } from '@chakra-ui/react';
import { GlobalSearchResultCard, GlobalSearchResultProps } from '@main/ui';

import { AppGlobalSearchResult } from '../global-search/use-global-search';

export function EntitySearchResultCard(props: GlobalSearchResultProps<AppGlobalSearchResult>) {
  const subtitleColor = useColorModeValue('gray.500', 'gray.400');

  return (
    <GlobalSearchResultCard
      _selected={{ backgroundColor: useColorModeValue('gray.200', 'gray.500') }}
      {...props}
    >
      <VStack
        align="stretch"
        spacing="0"
        flexGrow="1"
        color={useColorModeValue('gray.600', 'gray.300')}
      >
        <Text fontSize="sm" fontWeight={'medium'} noOfLines={1}>
          {props.result.title}
        </Text>

        <Text fontSize="xs" fontWeight="400" color={subtitleColor}>
          {props.result.internalId}
          {props.result.internalId && props.result.updatedAt && ' • '}
          {props.result.updatedAt}
        </Text>
      </VStack>
    </GlobalSearchResultCard>
  );
}

import { datadogLogs } from '@datadog/browser-logs';
import { useInsertEvidenceVersionMutation } from '@main/graphql/mutations/InsertEvidenceVersion.generated';
import { isNonNullable, toError } from '@main/shared/utils';
import { errorToast, successToast } from '@main/ui';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../hooks/redux-toolkit-hooks';
import { getCurrentUserSelectedOrg } from '../../user/slice';
import { EvidenceVersionModal } from './evidence-version-modal';
import { EvidenceVersionType } from './evidence-version-type';
import { useInsertEvidencePolicyMutation } from './evidence-versions.generated';
import { LinkEvidenceFormSchema, useLinkEvidenceForm } from './link-evidence-form';
import { PolicyEvidenceFormSchema, usePolicyEvidenceForm } from './policy-evidence-form';
import {
  SingleFileEvidenceFormSchema,
  useSingleFileEvidenceForm,
} from './single-file-evidence-form';

export const CreateEvidenceVersionModal = ({
  evidenceId,
  isOpen,
  onCreate,
  onClose,
}: {
  evidenceId: string;
  isOpen: boolean;
  onCreate: () => void;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const { is_policies_module_enabled: isPoliciesModuleEnabled } =
    useAppSelector(getCurrentUserSelectedOrg);

  const handleErrors = useHandleErrors({ onCreate });
  const createPolicyEvidenceVersion = useCreatePolicyEvidenceVersion({ evidenceId });
  const createFileEvidenceVersion = useCreateFileEvidenceVersion({ evidenceId });
  const createLinkEvidenceVersion = useCreateLinkEvidenceVersion({ evidenceId });

  const policyEvidenceForm = usePolicyEvidenceForm({
    onSubmit(data) {
      return handleErrors(createPolicyEvidenceVersion(data));
    },
  });

  const forms = [
    {
      type: EvidenceVersionType.File,
      form: useSingleFileEvidenceForm({
        onSubmit(data) {
          return handleErrors(createFileEvidenceVersion(data));
        },
      }),
    },

    {
      type: EvidenceVersionType.Link,
      form: useLinkEvidenceForm({
        onSubmit(data) {
          return handleErrors(createLinkEvidenceVersion(data));
        },
      }),
    },
    isPoliciesModuleEnabled
      ? {
          type: EvidenceVersionType.Policy,
          form: policyEvidenceForm,
        }
      : null,
  ].filter(isNonNullable);

  return (
    <EvidenceVersionModal
      header={t('evidences.version.newVersionHeader')}
      forms={forms}
      isOpen={isOpen}
      onClose={onClose}
    />
  );
};

function useHandleErrors({ onCreate }: { onCreate: () => void }) {
  const { t } = useTranslation();

  return async (promise: Promise<unknown>) => {
    try {
      await promise;
      successToast(
        t('successMessages.createSucceeded', { entity: t('entities.evidence_version') }),
      );
      onCreate();
    } catch (error) {
      errorToast(t('errorMessages.createFailed', { entity: t('entities.evidence_version') }));
      datadogLogs.logger.error('Failed creating evidence version', {}, toError(error));
    }
  };
}

function useCreatePolicyEvidenceVersion({ evidenceId }: { evidenceId: string }) {
  const [insertEvidencePolicy] = useInsertEvidencePolicyMutation();

  return async ({
    policyId,
    withVersionsChapter,
    withRevisionDetails,
    withApprovalsChapter,
    withAcknowledgementsChapter,
  }: PolicyEvidenceFormSchema) => {
    await insertEvidencePolicy({
      evidencePolicy: {
        evidence_id: evidenceId,
        policy_id: policyId,
        with_versions_chapter: withVersionsChapter,
        with_revision_details: withRevisionDetails,
        with_approvals_chapter: withApprovalsChapter,
        with_acknowledgements_chapter: withAcknowledgementsChapter,
      },
    }).unwrap();
  };
}

function useCreateFileEvidenceVersion({ evidenceId }: { evidenceId: string }) {
  const [insertEvidenceVersion] = useInsertEvidenceVersionMutation();

  return async ({ validityStart, fileId }: SingleFileEvidenceFormSchema) => {
    await insertEvidenceVersion({
      evidenceId,
      evidenceVersion: {
        evidence_id: evidenceId,
        validity_start: validityStart,
        evidence_version_file: {
          data: {
            file_id: fileId,
          },
        },
      },
    }).unwrap();
  };
}

function useCreateLinkEvidenceVersion({ evidenceId }: { evidenceId: string }) {
  const [insertEvidenceVersion] = useInsertEvidenceVersionMutation();

  return async ({ validityStart, url }: LinkEvidenceFormSchema) => {
    await insertEvidenceVersion({
      evidenceId,
      evidenceVersion: {
        evidence_id: evidenceId,
        validity_start: validityStart,
        url,
      },
    }).unwrap();
  };
}

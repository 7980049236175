/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type AlertsCountStatsQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['uuid']['input'];
  statsDate: Types.Scalars['timestamptz']['input'];
}>;

export type AlertsCountStatsQuery = {
  __typename?: 'query_root';
  alerts: {
    __typename?: 'controls_aggregate';
    aggregate?: { __typename?: 'controls_aggregate_fields'; count: number };
  };
  stats: Array<{
    __typename?: 'daily_control_stats_view';
    created_at?: string;
    total_at_risk_count?: number;
    total_failing_count?: number;
  }>;
};

export const AlertsCountStatsDocument = `
    query AlertsCountStats($organizationId: uuid!, $statsDate: timestamptz!) {
  alerts: controls_aggregate(
    where: {organization_id: {_eq: $organizationId}, status: {_in: ["Failing", "At risk"]}}
  ) {
    aggregate {
      count
    }
  }
  stats: daily_control_stats_view(
    where: {organization_id: {_eq: $organizationId}, created_at: {_gte: $statsDate}}
    order_by: {created_at: asc}
    limit: 7
  ) {
    created_at
    total_at_risk_count
    total_failing_count
  }
}
    ` as string & TypedDocumentNode<AlertsCountStatsQuery, AlertsCountStatsQueryVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    AlertsCountStats: build.query<AlertsCountStatsQuery, AlertsCountStatsQueryVariables>({
      query: (variables) => ({ document: AlertsCountStatsDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useAlertsCountStatsQuery, useLazyAlertsCountStatsQuery } = injectedRtkApi;

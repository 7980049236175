import {
  Box,
  Button,
  Card,
  Divider,
  Flex,
  Heading,
  Icon,
  Text,
  useCallbackRef,
  useDisclosure,
} from '@chakra-ui/react';
import { PlusIcon } from '@heroicons/react/24/outline';
import { GetFieldConfigsQuery } from '@main/graphql/features/CustomFields.generated';
import { Field_Entities_Enum, Field_Types_Enum } from '@main/graphql/types.generated';
import { createSorter, hashArrayIndexesById, useUpdateAllQueryData } from '@main/shared/utils';
import { successToast } from '@main/ui';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { getCurrentUserSelectedOrgId } from '../user/slice';
import { useGetFieldConfigsQuery } from './field-config';
import { FieldConfigModal, FieldSchema } from './field-config-modal';
import {
  useCanEditFieldConfigs,
  useCreateFieldConfig,
  useDeleteFieldConfig,
  useUpdateFieldConfig,
  useUpdateFieldConfigByType,
  useUpdateFieldConfigsOrder,
} from './field-config-mutations';
import { FieldConfigsTable } from './field-configs-table';

type FieldConfig = GetFieldConfigsQuery['field_configs'][number];

export const FieldConfigsCard = ({ entityName }: { entityName: Field_Entities_Enum }) => {
  const translations = useTranslations(entityName);

  const orgId = useAppSelector(getCurrentUserSelectedOrgId);
  const updateAllQueryData = useUpdateAllQueryData();
  const fieldConfigsQuery = useGetFieldConfigsQuery({
    orgId,
    entityName,
    includeHidden: true,
  });

  const [editingFieldSchema, setEditingFieldSchema] = useState<FieldSchema & { id: string }>();
  const canEditFieldConfigs = useCanEditFieldConfigs(entityName);

  const configModal = useDisclosure({
    onClose: () => {
      setEditingFieldSchema(undefined);
    },
  });

  const findFieldConfigById = (id: string) => {
    return fieldConfigsQuery.data?.field_configs?.find((fieldConfig) => fieldConfig.id === id);
  };

  const updateFieldConfig = useUpdateFieldConfig();

  const handleFieldVisibilityChange = useCallbackRef(async (id: string, hidden: boolean) => {
    const result = await updateFieldConfig({ id, hidden });
    await fieldConfigsQuery.refetch();
    if (result) {
      successToast(translations.visibilityToggleMsg(hidden));
    }
  });

  const handleFieldConfigEdit = useCallbackRef((id: string) => {
    const fieldConfig = findFieldConfigById(id);
    if (!fieldConfig) {
      return;
    }

    const fieldSchema = fieldConfigToSchema(fieldConfig);
    if (!fieldSchema) {
      return;
    }

    setEditingFieldSchema({ id, ...fieldSchema });
    configModal.onOpen();
  });

  const handleFieldConfigDelete = useCallbackRef(
    useDeleteFieldConfig({
      onDelete: async () => {
        await fieldConfigsQuery.refetch();
      },
      entityName,
    }),
  );

  const createFieldConfig = useCreateFieldConfig({ entityName });
  const updateFieldConfigByType = useUpdateFieldConfigByType();
  const updateFieldConfigsOrder = useUpdateFieldConfigsOrder();

  const handleSubmit = async (data: FieldSchema) => {
    if (editingFieldSchema) {
      const editingFieldConfig = findFieldConfigById(editingFieldSchema.id);
      if (!editingFieldConfig) {
        return;
      }

      await updateFieldConfigByType(editingFieldConfig, data);
    } else {
      await createFieldConfig(data);
    }

    await fieldConfigsQuery.refetch();
    configModal.onClose();
  };

  const sorter = useMemo(() => createSorter(), []);
  const handleFieldConfigsOrderUpdate = async (updatedFiledConfigs: FieldConfig[]) => {
    const undoUpdate = updateAllQueryData(
      fieldConfigsQuery.getFieldConfigsApi,
      'GetFieldConfigs',
      (draft) => {
        const fieldConfigs = hashArrayIndexesById(draft.field_configs);
        updatedFiledConfigs.forEach((updatedFiledConfig) => {
          const idx = fieldConfigs[updatedFiledConfig.id];
          if (idx !== undefined) {
            draft.field_configs[idx] = updatedFiledConfig;
          }
        });
        draft.field_configs.sort(sorter);
      },
    );

    try {
      await updateFieldConfigsOrder(updatedFiledConfigs);
      await fieldConfigsQuery.refetch();
    } catch (e) {
      undoUpdate();
    }
  };

  return (
    <>
      <Flex py="6" px={[4, 4, 8]} justifyContent="space-between" alignItems={'center'}>
        <Box>
          <Heading size="md">{translations.heading}</Heading>
          <Text variant="subheading">{translations.subheading}</Text>
        </Box>
        {canEditFieldConfigs && (
          <Button colorScheme="blue" leftIcon={<Icon as={PlusIcon} />} onClick={configModal.onOpen}>
            {translations.newBtnLabel}
          </Button>
        )}
      </Flex>
      <Divider orientation="horizontal" />
      <Box w="100%" py="6" px={[4, 4, 8]}>
        <Card variant="table-styles">
          <FieldConfigsTable
            itemName={translations.itemName}
            isLoading={fieldConfigsQuery.isLoading}
            fieldConfigs={fieldConfigsQuery.data?.field_configs ?? []}
            canEditFieldConfigs={canEditFieldConfigs}
            onFieldVisibilityChange={handleFieldVisibilityChange}
            onFieldConfigEdit={handleFieldConfigEdit}
            onFieldConfigDelete={handleFieldConfigDelete}
            onFieldConfigsOrderUpdate={handleFieldConfigsOrderUpdate}
          />
        </Card>

        <FieldConfigModal
          key={editingFieldSchema?.id}
          editingFieldSchema={editingFieldSchema}
          isOpen={configModal.isOpen}
          onClose={configModal.onClose}
          onSubmit={handleSubmit}
        />
      </Box>
    </>
  );
};

function useTranslations(entityName: Field_Entities_Enum) {
  const { t } = useTranslation();
  const canEditFieldConfigs = useCanEditFieldConfigs(entityName);

  const entity = t(`entities.${entityName}`);
  const customField = t('entities.customField');
  const customFields = t('entities.plural.customFields');

  // memo for table
  const itemName = useMemo(() => {
    return {
      singular: `${entity} ${customField}`.toLowerCase(),
      plural: `${entity} ${customFields}`.toLowerCase(),
      hideSubheading: !canEditFieldConfigs,
    };
  }, [entity, customField, customFields, canEditFieldConfigs]);

  return {
    heading: t('customFields.card.heading'),
    subheading: t('customFields.card.subheading', { entity: entity.toLowerCase() }),
    newBtnLabel: t('customFields.card.newBtnLabel'),
    itemName,
    visibilityToggleMsg: (hidden: boolean) => {
      const tKey = hidden ? 'hidden' : 'visible';
      return t(`customFields.card.visibility.${tKey}.successMsg`);
    },
  };
}

function fieldConfigToSchema(fieldConfig: FieldConfig): FieldSchema | undefined {
  switch (fieldConfig.field_type) {
    case Field_Types_Enum.Text: {
      return {
        fieldType: fieldConfig.field_type,
        name: fieldConfig.name,
      };
    }

    case Field_Types_Enum.Select: {
      const selectFieldConfig = fieldConfig.select_field_config;
      if (!selectFieldConfig) {
        return;
      }

      return {
        fieldType: fieldConfig.field_type,
        name: fieldConfig.name,
        isMulti: selectFieldConfig.is_multi,
        isCreatable: selectFieldConfig.is_creatable,
        options: selectFieldConfig.select_field_options.map(({ value }) => value),
      };
    }
  }
}

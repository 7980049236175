/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import {
  TaskSidebarDataFragmentDoc,
  TaskLinkedEntityFragmentDoc,
} from './tasks.fragment.generated';
import { api } from '@main/graphql/shared-api';
export type GetAssignedToMeTasksQueryVariables = Types.Exact<{
  userId: Types.Scalars['uuid']['input'];
}>;

export type GetAssignedToMeTasksQuery = {
  __typename?: 'query_root';
  tasks: Array<{
    __typename?: 'tasks';
    id: string;
    name?: string;
    status: ClientTypes.TaskStatus;
    due_date?: string;
    updated_at: string;
    created_at: string;
    task_owners: Array<{ __typename?: 'task_owners'; owner_id: string }>;
    controls: Array<{
      __typename?: 'control_tasks';
      control: { __typename?: 'controls'; id: string; name?: string; internal_id?: string };
    }>;
    risks: Array<{
      __typename?: 'risk_tasks';
      risk: { __typename?: 'risks'; id: string; name?: string; internal_id?: string };
    }>;
    vendors: Array<{
      __typename?: 'vendor_tasks';
      vendor: { __typename?: 'vendors'; id: string; name?: string; internal_id?: string };
    }>;
    tasks_policy_tasks: Array<{
      __typename?: 'policy_tasks';
      policy_tasks_policy: {
        __typename?: 'policies';
        id: string;
        name?: string;
        internal_id?: string;
      };
    }>;
  }>;
};

export type GetCreatedByMeTasksQueryVariables = Types.Exact<{
  creatorId: Types.Scalars['uuid']['input'];
}>;

export type GetCreatedByMeTasksQuery = {
  __typename?: 'query_root';
  tasks: Array<{
    __typename?: 'tasks';
    id: string;
    name?: string;
    status: ClientTypes.TaskStatus;
    due_date?: string;
    updated_at: string;
    created_at: string;
    task_owners: Array<{ __typename?: 'task_owners'; owner_id: string }>;
    controls: Array<{
      __typename?: 'control_tasks';
      control: { __typename?: 'controls'; id: string; name?: string; internal_id?: string };
    }>;
    risks: Array<{
      __typename?: 'risk_tasks';
      risk: { __typename?: 'risks'; id: string; name?: string; internal_id?: string };
    }>;
    vendors: Array<{
      __typename?: 'vendor_tasks';
      vendor: { __typename?: 'vendors'; id: string; name?: string; internal_id?: string };
    }>;
    tasks_policy_tasks: Array<{
      __typename?: 'policy_tasks';
      policy_tasks_policy: {
        __typename?: 'policies';
        id: string;
        name?: string;
        internal_id?: string;
      };
    }>;
  }>;
};

export type UpdateStatusForTasksMutationVariables = Types.Exact<{
  taskIds: Array<Types.Scalars['uuid']['input']> | Types.Scalars['uuid']['input'];
  status: Types.Scalars['String']['input'];
  completed_at?: Types.InputMaybe<Types.Scalars['timestamptz']['input']>;
}>;

export type UpdateStatusForTasksMutation = {
  __typename?: 'mutation_root';
  update_tasks?: { __typename?: 'tasks_mutation_response'; affected_rows: number };
  update_task_owners?: { __typename?: 'task_owners_mutation_response'; affected_rows: number };
};

export const GetAssignedToMeTasksDocument = `
    query GetAssignedToMeTasks($userId: uuid!) {
  tasks(
    where: {task_owners: {owner_id: {_eq: $userId}}}
    order_by: {created_at: asc}
  ) {
    ...TaskSidebarData
  }
}
    ${TaskSidebarDataFragmentDoc}
${TaskLinkedEntityFragmentDoc}` as string &
  TypedDocumentNode<GetAssignedToMeTasksQuery, GetAssignedToMeTasksQueryVariables>;
export const GetCreatedByMeTasksDocument = `
    query GetCreatedByMeTasks($creatorId: uuid!) {
  tasks(where: {created_by: {_eq: $creatorId}}, order_by: {created_at: asc}) {
    ...TaskSidebarData
  }
}
    ${TaskSidebarDataFragmentDoc}
${TaskLinkedEntityFragmentDoc}` as string &
  TypedDocumentNode<GetCreatedByMeTasksQuery, GetCreatedByMeTasksQueryVariables>;
export const UpdateStatusForTasksDocument = `
    mutation UpdateStatusForTasks($taskIds: [uuid!]!, $status: String!, $completed_at: timestamptz) {
  update_tasks(where: {id: {_in: $taskIds}}, _set: {status: $status}) {
    affected_rows
  }
  update_task_owners(
    where: {task_id: {_in: $taskIds}}
    _set: {completed_at: $completed_at}
  ) {
    affected_rows
  }
}
    ` as string &
  TypedDocumentNode<UpdateStatusForTasksMutation, UpdateStatusForTasksMutationVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetAssignedToMeTasks: build.query<
      GetAssignedToMeTasksQuery,
      GetAssignedToMeTasksQueryVariables
    >({
      query: (variables) => ({ document: GetAssignedToMeTasksDocument, variables }),
    }),
    GetCreatedByMeTasks: build.query<GetCreatedByMeTasksQuery, GetCreatedByMeTasksQueryVariables>({
      query: (variables) => ({ document: GetCreatedByMeTasksDocument, variables }),
    }),
    UpdateStatusForTasks: build.mutation<
      UpdateStatusForTasksMutation,
      UpdateStatusForTasksMutationVariables
    >({
      query: (variables) => ({ document: UpdateStatusForTasksDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useGetAssignedToMeTasksQuery,
  useLazyGetAssignedToMeTasksQuery,
  useGetCreatedByMeTasksQuery,
  useLazyGetCreatedByMeTasksQuery,
  useUpdateStatusForTasksMutation,
} = injectedRtkApi;

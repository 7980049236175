import { Flex, Text, useColorModeValue, useFormControl } from '@chakra-ui/react';
import { ArrowUpOnSquareStackIcon } from '@heroicons/react/24/outline';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { EmptyPlaceholder } from '../placeholder';

export type DropzoneProps = {
  onChange: (files: File[]) => void;
  onDragLeave?: () => void;
  isMulti?: boolean;
  topLabelText?: string;
  bottomLabelText?: string;
  constraintText?: string;
};

export const Dropzone: React.FC<DropzoneProps> = ({
  onChange,
  onDragLeave,
  topLabelText,
  bottomLabelText,
  constraintText,
  isMulti,
}) => {
  const { t } = useTranslation('ui');
  const { readOnly, id } = useFormControl({});

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onChange,
    multiple: isMulti,
    disabled: !!readOnly,
  });

  const borderColor = useColorModeValue('#EBEBEB', 'gray.600');
  const textColor = useColorModeValue('gray.900', 'gray.200');
  const secondaryTextColor = useColorModeValue('gray.500', 'gray.400');

  return (
    <Flex width="full" direction="column" height="full" minH={'185px'}>
      <Flex
        direction={'column'}
        width="full"
        height="full"
        padding={8}
        border={'1px'}
        borderColor={isDragActive ? 'blue.500' : borderColor}
        borderStyle="dashed"
        borderRadius={'8px'}
        textColor={textColor}
        justifyContent={'center'}
        alignItems={'center'}
        cursor={'pointer'}
        {...getRootProps()}
        position={'relative'}
      >
        <input
          {...getInputProps()}
          id={id}
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            left: 0,
            top: 0,
            opacity: '0',
          }}
          title=""
          onDragLeave={() => onDragLeave && onDragLeave()}
        />
        <EmptyPlaceholder py={0}>
          <EmptyPlaceholder.Icon as={ArrowUpOnSquareStackIcon} />
          <EmptyPlaceholder.Content>
            <EmptyPlaceholder.Heading fontSize={'sm'}>
              {topLabelText || t('upload.dropzone.topLabel')}
            </EmptyPlaceholder.Heading>
            <EmptyPlaceholder.Subheading fontSize={'xs'}>
              {bottomLabelText || t('upload.dropzone.bottomLabel')}
            </EmptyPlaceholder.Subheading>
          </EmptyPlaceholder.Content>
        </EmptyPlaceholder>
      </Flex>

      <Text mt={2} fontSize={'xs'} color={secondaryTextColor}>
        {constraintText || t('upload.dropzone.constraintLabel')}
      </Text>
    </Flex>
  );
};

import { TaskStatus } from '@main/graphql/client-scalars';

export const TASKS_TABLE_FILTER_PARAM_NAME = 'tasksFilter' as string;
export const TASKS_TABLE_SORT_PARAM_NAME = 'tasksSort' as string;

export const TASK_STATUSES = {
  [TaskStatus.PENDING]: { value: TaskStatus.PENDING, colorScheme: 'gray' },
  [TaskStatus.IN_PROGRESS]: { value: TaskStatus.IN_PROGRESS, colorScheme: 'orange' },
  [TaskStatus.COMPLETED]: { value: TaskStatus.COMPLETED, colorScheme: 'green' },
  [TaskStatus.OVERDUE]: { value: TaskStatus.OVERDUE, colorScheme: 'red' },
};

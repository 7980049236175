import type { EditorThemeClasses } from 'lexical';

const theme: EditorThemeClasses = {
  root: 'WysiwygEditorTheme',
  ltr: 'ltr',
  rtl: 'rtl',
  placeholder: 'editor-placeholder',
  paragraph: 'editor-paragraph',
  heading: {
    h1: 'WysiwygEditorTheme__h1',
    h2: 'WysiwygEditorTheme__h2',
    h3: 'WysiwygEditorTheme__h3',
    h4: 'WysiwygEditorTheme__h4',
    h5: 'WysiwygEditorTheme__h5',
    h6: 'WysiwygEditorTheme__h6',
  },
  code: 'WysiwygEditorTheme__code',
  textCode: 'WysiwygEditorTheme__textCode',
  quote: 'WysiwygEditorTheme__quote',
  link: 'WysiwygEditorTheme__link',
  text: {
    bold: 'WysiwygEditorTheme__textBold',
    code: 'WysiwygEditorTheme__textCode',
    italic: 'WysiwygEditorTheme__textItalic',
    strikethrough: 'WysiwygEditorTheme__textStrikethrough',
    subscript: 'WysiwygEditorTheme__textSubscript',
    superscript: 'WysiwygEditorTheme__textSuperscript',
    underline: 'WysiwygEditorTheme__textUnderline',
    underlineStrikethrough: 'WysiwygEditorTheme__textUnderlineStrikethrough',
  },
  list: {
    listitem: 'WysiwygEditorTheme__listItem',
    listitemChecked: 'WysiwygEditorTheme__listItemChecked',
    listitemUnchecked: 'WysiwygEditorTheme__listItemUnchecked',
    nested: {
      listitem: 'WysiwygEditorTheme__nestedListItem',
    },
    olDepth: [
      'WysiwygEditorTheme__ol1',
      'WysiwygEditorTheme__ol2',
      'WysiwygEditorTheme__ol3',
      'WysiwygEditorTheme__ol4',
      'WysiwygEditorTheme__ol5',
    ],
    ul: 'WysiwygEditorTheme__ul',
  },
  codeHighlight: {
    atrule: 'WysiwygEditorTheme_code__tokenAttr',
    attr: 'WysiwygEditorTheme_code__tokenAttr',
    boolean: 'WysiwygEditorTheme_code__tokenProperty',
    builtin: 'WysiwygEditorTheme_code__tokenSelector',
    cdata: 'WysiwygEditorTheme_code__tokenComment',
    char: 'WysiwygEditorTheme_code__tokenSelector',
    class: 'WysiwygEditorTheme_code__tokenFunction',
    'class-name': 'WysiwygEditorTheme_code__tokenFunction',
    comment: 'WysiwygEditorTheme_code__tokenComment',
    constant: 'WysiwygEditorTheme_code__tokenProperty',
    deleted: 'WysiwygEditorTheme_code__tokenProperty',
    doctype: 'WysiwygEditorTheme_code__tokenComment',
    entity: 'WysiwygEditorTheme_code__tokenOperator',
    function: 'WysiwygEditorTheme_code__tokenFunction',
    important: 'WysiwygEditorTheme_code__tokenVariable',
    inserted: 'WysiwygEditorTheme_code__tokenSelector',
    keyword: 'WysiwygEditorTheme_code__tokenAttr',
    namespace: 'WysiwygEditorTheme_code__tokenVariable',
    number: 'WysiwygEditorTheme_code__tokenProperty',
    operator: 'WysiwygEditorTheme_code__tokenOperator',
    prolog: 'WysiwygEditorTheme_code__tokenComment',
    property: 'WysiwygEditorTheme_code__tokenProperty',
    punctuation: 'WysiwygEditorTheme_code__tokenPunctuation',
    regex: 'WysiwygEditorTheme_code__tokenVariable',
    selector: 'WysiwygEditorTheme_code__tokenSelector',
    string: 'WysiwygEditorTheme_code__tokenSelector',
    symbol: 'WysiwygEditorTheme_code__tokenProperty',
    tag: 'WysiwygEditorTheme_code__tokenProperty',
    url: 'WysiwygEditorTheme_code__tokenOperator',
    variable: 'WysiwygEditorTheme_code__tokenVariable',
  },
  table: 'WysiwygEditorTheme__table',
  tableCell: 'WysiwygEditorTheme__tableCell',
  tableCellActionButton: 'WysiwygEditorTheme__tableCellActionButton',
  tableCellActionButtonContainer: 'WysiwygEditorTheme__tableCellActionButtonContainer',
  tableCellEditing: 'WysiwygEditorTheme__tableCellEditing',
  tableCellHeader: 'WysiwygEditorTheme__tableCellHeader',
  tableCellPrimarySelected: 'WysiwygEditorTheme__tableCellPrimarySelected',
  tableCellResizer: 'WysiwygEditorTheme__tableCellResizer',
  tableCellSelected: 'WysiwygEditorTheme__tableCellSelected',
  tableCellSortedIndicator: 'WysiwygEditorTheme__tableCellSortedIndicator',
  tableResizeRuler: 'WysiwygEditorTheme__tableCellResizeRuler',
  tableSelected: 'WysiwygEditorTheme__tableSelected',
  tableSelection: 'WysiwygEditorTheme__tableSelection',
};

export default theme;

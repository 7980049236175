import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Text,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { EmptyPlaceholder } from '@main/ui';
import { useChangePassword } from '@nhost/react';
import { Route } from '@tanstack/react-router';
import { FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getCurrentUserDetails } from '../../../features/user/slice';
import { useAppSelector } from '../../../hooks/redux-toolkit-hooks';
import { PASSWORD_PATTERN } from '../../../utils/constants';
import { accountSettingsLayoutRoute } from './layout';

const Profile = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const { changePassword } = useChangePassword();
  const userResult = useAppSelector(getCurrentUserDetails);
  const tipColor = useColorModeValue('gray.400', 'gray.200');
  const isPasswordFormValid = password !== '' && password === confirmPassword;

  const updatePassword = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { isError, isSuccess } = await changePassword(password);

    if (isError) {
      toast({
        id: 'updateProfile',
        title: 'Unable to update password',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } else if (isSuccess) {
      toast({
        id: 'updateProfile',
        title: 'Updated successfully',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      setPassword('');
      setConfirmPassword('');
    }
  };

  if (userResult?.user?.joined_with_sso) {
    return (
      <EmptyPlaceholder>
        <EmptyPlaceholder.Icon as={XMarkIcon} />
        <EmptyPlaceholder.Content>
          <EmptyPlaceholder.Heading>
            {t('errorMessages.noPermissionHeading')}
          </EmptyPlaceholder.Heading>
          <EmptyPlaceholder.Subheading>
            {t('profile.password.restrictedSSOUser')}
          </EmptyPlaceholder.Subheading>
        </EmptyPlaceholder.Content>
      </EmptyPlaceholder>
    );
  }

  return (
    <form onSubmit={updatePassword}>
      <Box>
        <Flex direction={{ base: 'column', lg: 'row' }} gap="4" wrap="wrap">
          <FormControl width={{ base: '100%', lg: 'xl' }}>
            <FormLabel>{t('profile.password.form.newPassword')}</FormLabel>
            <Input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              title={t('signup.passwordFormat')}
              pattern={PASSWORD_PATTERN}
            />
            <Text fontSize={'xs'} color={tipColor} mb="3">
              {t('signup.passwordHint')}
            </Text>
          </FormControl>
          <FormControl width={{ base: '100%', lg: 'xl' }}>
            <FormLabel>{t('profile.password.form.confirmPassword')}</FormLabel>
            <Input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </FormControl>
        </Flex>
      </Box>

      <Flex justifyContent="flex-end" py="8">
        <Button type="submit" isDisabled={!isPasswordFormValid} colorScheme={'blue'} size={'md'}>
          {t('buttons.changePassword')}
        </Button>
      </Flex>
    </form>
  );
};

export const accountPasswordSettingsRoute = new Route({
  getParentRoute: () => accountSettingsLayoutRoute,
  path: '/password',
  component: Profile,
});

import { Center, Spinner } from '@chakra-ui/react';
import { useGetUserByIdSubscription } from '@main/graphql/subscriptions/GetUserByIdSubscription';
import { appDrawerSchema, isGraphqlError, useEffectOnce } from '@main/shared/utils';
import { errorToast } from '@main/ui';
import { useAuthenticationStatus, useSignOut, useUserId } from '@nhost/react';
import { Outlet, Route } from '@tanstack/react-router';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AuthenticatedLayout } from '../features/layout/authenticated';
import Login from '../features/user/login';
import {
  authenticatedUserIdReceived,
  getAppReadyToUseState,
  getCurrentUserSelectedOrg,
} from '../features/user/slice';
import { useAppDispatch, useAppSelector } from '../hooks/redux-toolkit-hooks';
import { rootRoute } from './__root';

function useErrorInRedirectFromAuthService() {
  const { t } = useTranslation();
  const urlParams = new URLSearchParams(window.location.search);
  const errorFromAuthService = urlParams.get('error');

  useEffectOnce(() => {
    if (errorFromAuthService === 'invalid-ticket') {
      errorToast(t('login.errors.invalidTicket'));
    }
  });
}

function useUserDataForBootstrap() {
  const { t } = useTranslation();
  const userId = useUserId();
  const { data: userData, error: userDataError } = useGetUserByIdSubscription(
    { id: userId || '' },
    { skip: !userId },
  );
  const accessDeniedError =
    userDataError &&
    isGraphqlError(userDataError) &&
    userDataError?.[0]?.extensions?.code === 'access-denied';

  const { signOut } = useSignOut();

  useEffect(() => {
    if (accessDeniedError) {
      errorToast(t('login.errors.accessDenied'));
      signOut();
    }
  }, [accessDeniedError, t, signOut]);

  return { userData };
}

function Auth() {
  const dispatch = useAppDispatch();
  const { isLoading, isAuthenticated } = useAuthenticationStatus();
  const appReadyToUseState = useAppSelector(getAppReadyToUseState);
  const userOrg = useAppSelector(getCurrentUserSelectedOrg);
  const { userData } = useUserDataForBootstrap();

  useErrorInRedirectFromAuthService();

  useEffect(
    function bootstrap() {
      if (isAuthenticated && userData?.user?.id && appReadyToUseState === 'pending') {
        dispatch(authenticatedUserIdReceived({ userId: userData?.user?.id }));
      }
    },
    [dispatch, isAuthenticated, appReadyToUseState, userData?.user?.id],
  );

  if (!isLoading && !isAuthenticated) {
    return <Login />;
  }

  if (appReadyToUseState === 'finished' && userOrg) {
    return (
      <AuthenticatedLayout>
        <Outlet />
      </AuthenticatedLayout>
    );
  }

  return (
    <Center h="100vh">
      <Spinner size="xl" thickness="4px" color="blue.500" />
    </Center>
  );
}

export const authLayout = new Route({
  getParentRoute: () => rootRoute,
  path: '/',
  component: Auth,
  validateSearch: appDrawerSchema,
});

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
export type CommentsFragmentFragment = {
  __typename?: 'comments';
  id: string;
  text: string;
  created_at: string;
  updated_at: string;
  user_id: string;
  comments_comment_uploads: Array<{
    __typename?: 'comment_uploads';
    id: string;
    comment_uploads_file: {
      __typename?: 'files';
      id: string;
      name?: string;
      size?: number;
      createdAt: string;
    };
  }>;
};

export const CommentsFragmentFragmentDoc = `
    fragment CommentsFragment on comments {
  id
  text
  created_at
  updated_at
  user_id
  comments_comment_uploads(order_by: {comment_uploads_file: {createdAt: asc}}) {
    id
    comment_uploads_file {
      id
      name
      size
      createdAt
    }
  }
}
    `;

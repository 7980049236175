import { createStandaloneToast, theme } from '@chakra-ui/react';
import { createListenerMiddleware, TypedStartListening } from '@reduxjs/toolkit';

import { BulkDownloader, BulkDownloaderStatus } from './bulk-downloader';
import {
  bulkDownloadCompleted,
  BulkDownloaderSlice,
  bulkDownloadStarted,
  bulkDownloadUpdated,
  getBulkDownload,
  startBulkDownload,
} from './slice';

const { toast } = createStandaloneToast({ theme });
export const bulkDownloaderListenerMiddleware = createListenerMiddleware();

const startListening =
  bulkDownloaderListenerMiddleware.startListening as TypedStartListening<BulkDownloaderSlice>;

startListening({
  actionCreator: startBulkDownload,
  effect: ({ payload }, { dispatch, getState }) => {
    const downloadId = payload.downloadId;

    if (getBulkDownload(getState(), downloadId)) {
      return;
    }

    const downloader = new BulkDownloader(payload.downloader, payload.sink, payload.logger).start(
      payload.files,
    );

    const title = payload.title ?? 'Downloading...';
    const description = payload.progressTitle ?? downloadToastDescription;
    const toastId = toast({
      status: 'loading',
      title,
      description: description(0),
      duration: null,
      isClosable: true,
      onCloseComplete: () => downloader.cancel(),
    });

    const cancellables = [
      downloader.onProgressChange((progress) => {
        dispatch(bulkDownloadUpdated({ downloadId, progress }));

        toast.update(toastId, {
          status: 'loading',
          title,
          description: description(progress),
          duration: null,
          isClosable: true,
        });
      }),
      downloader.onStatusChange((status) => {
        dispatch(bulkDownloadUpdated({ downloadId, status }));

        if (status !== BulkDownloaderStatus.Completed && status !== BulkDownloaderStatus.Canceled) {
          return;
        }

        for (let cancel = cancellables.pop(); cancel; cancel = cancellables.pop()) {
          cancel();
        }
        dispatch(bulkDownloadCompleted({ downloadId }));

        if (status === BulkDownloaderStatus.Completed) {
          toast.update(toastId, {
            status: 'success',
            title: payload.successTitle ?? 'Download complete!',
          });
        } else {
          toast.update(toastId, {
            status: 'error',
            title: payload.errorTitle ?? 'Download failed!',
          });
        }
      }),
    ];

    dispatch(bulkDownloadStarted({ downloadId, downloader }));
  },
});

function downloadToastDescription(progress: number) {
  return `Downloading ${progress.toFixed(0)}%...`;
}

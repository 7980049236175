/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { RoleDetailsFragmentDoc } from '../fragments/RoleDetailsFragment.generated';
import { api } from '@main/graphql/shared-api';
export type GetOrganizationRolesQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['uuid']['input'];
}>;

export type GetOrganizationRolesQuery = {
  __typename?: 'query_root';
  roles: Array<{
    __typename?: 'roles';
    id: string;
    name: string;
    system_role?: Types.System_Roles_Enum;
    organization_id: string;
  }>;
};

export const GetOrganizationRolesDocument = `
    query GetOrganizationRoles($organizationId: uuid!) {
  roles(where: {organization_id: {_eq: $organizationId}}) {
    ...RoleDetails
  }
}
    ${RoleDetailsFragmentDoc}` as string &
  TypedDocumentNode<GetOrganizationRolesQuery, GetOrganizationRolesQueryVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetOrganizationRoles: build.query<
      GetOrganizationRolesQuery,
      GetOrganizationRolesQueryVariables
    >({
      query: (variables) => ({ document: GetOrganizationRolesDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetOrganizationRolesQuery, useLazyGetOrganizationRolesQuery } = injectedRtkApi;

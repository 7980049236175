import { Outlet, Route } from '@tanstack/react-router';

import { authLayout } from '../login';

const ReportsPage = () => {
  return <Outlet />;
};

export const reportRoute = new Route({
  getParentRoute: () => authLayout,
  path: 'reports',
  component: ReportsPage,
});

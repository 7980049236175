import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { COMMAND_PRIORITY_NORMAL, KEY_ENTER_COMMAND } from 'lexical';
import { FC, RefObject, useEffect } from 'react';

type CommentPluginProps = {
  onSubmit: () => void;
  typeaheadRef: RefObject<HTMLDivElement>;
};

export const CommentPlugin: FC<CommentPluginProps> = ({ onSubmit, typeaheadRef }) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    return editor.registerCommand(
      KEY_ENTER_COMMAND,
      (event) => {
        if (event?.shiftKey || typeaheadRef.current) {
          return false;
        } else {
          event?.preventDefault();
          onSubmit();
          return true;
        }
      },
      COMMAND_PRIORITY_NORMAL,
    );
  }, [editor, onSubmit, typeaheadRef]);

  return null;
};

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type GetProgramControlsOnAlertQueryVariables = Types.Exact<{
  programId: Types.Scalars['uuid']['input'];
}>;

export type GetProgramControlsOnAlertQuery = {
  __typename?: 'query_root';
  program_controls_aggregate: {
    __typename?: 'controls_aggregate';
    aggregate?: { __typename?: 'controls_aggregate_fields'; count: number };
  };
};

export const GetProgramControlsOnAlertDocument = `
    query GetProgramControlsOnAlert($programId: uuid!) {
  program_controls_aggregate: controls_aggregate(
    where: {status: {_in: ["Failing", "At risk"]}, program_controls: {program_id: {_eq: $programId}}}
  ) {
    aggregate {
      count
    }
  }
}
    ` as string &
  TypedDocumentNode<GetProgramControlsOnAlertQuery, GetProgramControlsOnAlertQueryVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetProgramControlsOnAlert: build.query<
      GetProgramControlsOnAlertQuery,
      GetProgramControlsOnAlertQueryVariables
    >({
      query: (variables) => ({ document: GetProgramControlsOnAlertDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetProgramControlsOnAlertQuery, useLazyGetProgramControlsOnAlertQuery } =
  injectedRtkApi;

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type InsertEvidencesMutationVariables = Types.Exact<{
  objects: Array<Types.Evidences_Insert_Input> | Types.Evidences_Insert_Input;
}>;

export type InsertEvidencesMutation = {
  __typename?: 'mutation_root';
  insert_evidences?: { __typename?: 'evidences_mutation_response'; affected_rows: number };
};

export const InsertEvidencesDocument = `
    mutation InsertEvidences($objects: [evidences_insert_input!]!) {
  insert_evidences(objects: $objects) {
    affected_rows
  }
}
    ` as string & TypedDocumentNode<InsertEvidencesMutation, InsertEvidencesMutationVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    InsertEvidences: build.mutation<InsertEvidencesMutation, InsertEvidencesMutationVariables>({
      query: (variables) => ({ document: InsertEvidencesDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useInsertEvidencesMutation } = injectedRtkApi;

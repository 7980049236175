/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type DashboardStatsQueryVariables = Types.Exact<{
  orgId: Types.Scalars['uuid']['input'];
}>;

export type DashboardStatsQuery = {
  __typename?: 'query_root';
  user_controls_stats_view: Array<{
    __typename?: 'user_controls_stats_view';
    valid_controls_ratio?: number;
    user?: { __typename?: 'users'; id: string; displayName: string };
  }>;
  user_tasks_stats_view: Array<{
    __typename?: 'user_tasks_stats_view';
    completed_tasks_ratio?: number;
    user?: { __typename?: 'users'; id: string; displayName: string };
  }>;
};

export const DashboardStatsDocument = `
    query DashboardStats($orgId: uuid!) {
  user_controls_stats_view(
    where: {organization_id: {_eq: $orgId}, controls_count: {_gt: 0}}
    order_by: {valid_controls_ratio: desc}
  ) {
    valid_controls_ratio
    user {
      id
      displayName
    }
  }
  user_tasks_stats_view(
    where: {organization_id: {_eq: $orgId}, tasks_count: {_gt: 0}}
    order_by: {completed_tasks_ratio: desc}
  ) {
    completed_tasks_ratio
    user {
      id
      displayName
    }
  }
}
    ` as string & TypedDocumentNode<DashboardStatsQuery, DashboardStatsQueryVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    DashboardStats: build.query<DashboardStatsQuery, DashboardStatsQueryVariables>({
      query: (variables) => ({ document: DashboardStatsDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useDashboardStatsQuery, useLazyDashboardStatsQuery } = injectedRtkApi;

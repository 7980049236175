import { datadogLogs } from '@datadog/browser-logs';
import { toError } from '@main/shared/utils';
import { errorToast, successToast, useAlertDialog, useDrawer } from '@main/ui';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { useDeleteTaskMutation } from '../tasks/manage-tasks.generated';
import { getCurrentUserSelectedOrgRole } from '../user/slice';

export function useDeleteTask() {
  const { t } = useTranslation();
  const { openDialog } = useAlertDialog();
  const drawer = useDrawer();
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canDeleteTask = !!userRole.permissionMap?.write_tasks;

  const [deleteTaskById] = useDeleteTaskMutation();

  const deleteTask = useCallback(
    (taskId: string, callback?: () => void) => {
      openDialog({
        dialogHeader: t('tasks.alert.delete.header'),
        dialogContent: t('tasks.alert.delete.content'),
        confirmAction: {
          children: t('tasks.alert.delete.confirm'),
          onClick: async () => {
            try {
              await deleteTaskById({ taskId });
              successToast(t('successMessages.deleteSucceeded', { entity: t('entities.task') }));

              const currentDrawer = drawer.getCurrentData();
              currentDrawer?.entity === 'task' && drawer.close();

              callback?.();
            } catch (error) {
              errorToast(t('errorMessages.deleteFailed', { entity: t('entities.task') }));
              datadogLogs.logger.error('Task delete failed', { taskId }, toError(error));
            }
          },
        },
      });
    },
    [openDialog, t, deleteTaskById, drawer],
  );

  return canDeleteTask ? deleteTask : undefined;
}

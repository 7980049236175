import {
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
} from '@chakra-ui/react';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

type LinkEntityModal = ModalProps & {
  children: ReactNode;
  entityName: string;
  onLinkEntity: () => void;
  isLinkingEntity?: boolean;
};

export const LinkEntityModal = ({
  isOpen,
  entityName,
  onClose,
  children,
  onLinkEntity,
  isLinkingEntity,
}: LinkEntityModal) => {
  const { t } = useTranslation();

  return (
    <Modal size={'2xl'} scrollBehavior="inside" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize={'lg'} fontWeight={'bold'} px={4}>
          {t('shared.linkModal.heading', { entity: entityName.toLowerCase() })}
        </ModalHeader>

        {children}

        <ModalFooter px={4}>
          <Button variant="outline" mr={3} onClick={onClose}>
            {t('buttons.close')}
          </Button>
          <Button colorScheme="blue" isLoading={isLinkingEntity} onClick={onLinkEntity}>
            {t('buttons.link')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

import { Box, HStack, VStack } from '@chakra-ui/react';
import { curveLinear, curveMonotoneX } from '@visx/curve';
import { localPoint } from '@visx/event';
import { LinearGradient } from '@visx/gradient';
import { GridColumns, GridRows } from '@visx/grid';
import { withParentSize } from '@visx/responsive';
import {
  WithParentSizeProps,
  WithParentSizeProvidedProps,
} from '@visx/responsive/lib/enhancers/withParentSize';
import { ContinuousDomain, scaleLinear, scaleTime, TimeDomain } from '@visx/scale';
import { AreaClosed, Bar, LinePath } from '@visx/shape';
import { defaultStyles, TooltipWithBounds, withTooltip } from '@visx/tooltip';
import { WithTooltipProvidedProps } from '@visx/tooltip/lib/enhancers/withTooltip';
import { bisector, extent, max } from '@visx/vendor/d3-array';
import { CSSProperties, MouseEvent, ReactNode, TouchEvent, useCallback, useMemo } from 'react';

export enum ChartScaleType {
  Linear = 'linear',
  Time = 'time',
}

export enum ChartCurveType {
  Linear = 'linear',
  Smooth = 'smooth',
}

export interface AreaChartProps<T extends object> {
  data: T[];
  xAccessor: ChartAccessor<T>;
  yAccessor: ChartAccessor<T>;
  curveType?: ChartCurveType;
  tooltip?: ChartDataAccessor<T, ReactNode>;
  noTooltip?: boolean;
  margin?: { top?: number; right?: number; bottom?: number; left?: number };
  tooltipStyles?: CSSProperties;
  backgroundColor1?: string;
  backgroundColor2?: string;
  lineColor?: string;
  axisStyles?: CSSProperties;
  noAxis?: boolean;
  borderRadius?: number;
  verticalGridColor?: string;
  horizontalGridColor?: string;
}

export const AreaChart = withParentSize(withTooltip(AreaChartComponent)) as unknown as <
  T extends object,
>(
  props: AreaChartProps<T>,
) => JSX.Element;

function AreaChartComponent<T extends object>({
  data,
  xAccessor,
  yAccessor,
  curveType,
  tooltip,
  noTooltip,
  parentWidth = 0,
  parentHeight = 0,
  margin = {},
  showTooltip,
  hideTooltip,
  tooltipStyles,
  tooltipData,
  tooltipTop = 0,
  tooltipLeft = 0,
  borderRadius = 14,
  backgroundColor1 = 'transparent',
  backgroundColor2 = 'transparent',
  lineColor = '#6B46C1',
  axisStyles,
  noAxis,
  verticalGridColor = 'rgba(203, 213, 224, 0)',
  horizontalGridColor = 'rgba(203, 213, 224, 0.40)',
}: AreaChartProps<T> &
  WithTooltipProvidedProps<T> &
  WithParentSizeProvidedProps &
  WithParentSizeProps) {
  const {
    top: marginTop = 0,
    right: marginRight = 0,
    bottom: marginBottom = 0,
    left: marginLeft = 0,
  } = margin;
  const axisHeight = axisStyles?.height ? parseInt(String(axisStyles.height), 10) : 18;
  const width = parentWidth ?? 0;
  const height = (parentHeight ?? 0) - axisHeight;

  const fullTooltipStyles = useMemo(
    () => ({
      ...defaultStyles,
      background: 'rgba(0, 0, 0, 0.64)',
      borderRadius: '8px',
      color: 'white',
      fontFamily: 'Inter, arial, sans-serif',
      fontSize: '10px',
      fontWeight: 400,
      ...tooltipStyles,
    }),
    [tooltipStyles],
  );

  const curve = useMemo(
    () => (curveType === ChartCurveType.Smooth ? curveMonotoneX : curveLinear),
    [curveType],
  );

  // bounds
  const innerWidth = width - marginLeft - marginRight;
  const innerHeight = height - marginTop - marginBottom;

  const bisectX = useMemo(
    () => bisector((d: T) => xAccessor.accessor(d).valueOf()).left,
    [xAccessor],
  );

  const createScaleFor = useCallback(
    (accessor: ChartAccessor<T>, range: number[], linearLimit = 0) => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      return accessor.type === ChartScaleType.Time
        ? scaleTime({
            domain: accessor.domain ?? (extent(data, accessor.accessor) as [Date, Date]),
            range,
          })
        : scaleLinear({
            domain: accessor.domain ?? [0, (max(data, accessor.accessor) || 0) + linearLimit],
            range,
            round: true,
          });
    },
    [data],
  );

  // scales
  const xScale = useMemo(
    () => createScaleFor(xAccessor, [marginLeft, innerWidth + marginLeft]),
    [createScaleFor, innerWidth, marginLeft, xAccessor],
  );
  const yScale = useMemo(
    () => createScaleFor(yAccessor, [innerHeight + marginTop, marginTop], innerHeight * 0.1),
    [createScaleFor, innerHeight, marginTop, yAccessor],
  );

  // tooltip handler
  const handleTooltip = useCallback(
    (event: TouchEvent<SVGRectElement> | MouseEvent<SVGRectElement>) => {
      const { x = 0 } = localPoint(event) ?? {};
      const x0 = xScale.invert(x);
      const index = bisectX(data, x0, 1);
      const d0 = data[index - 1];
      const d1 = data[index];
      let d = d1;
      if (d0 && d1 && xAccessor.accessor(d1)) {
        d =
          x0.valueOf() - xAccessor.accessor(d0).valueOf() >
          xAccessor.accessor(d1).valueOf() - x0.valueOf()
            ? d1
            : d0;
      }
      if (!d) {
        return;
      }

      showTooltip({
        tooltipData: d,
        tooltipLeft: xScale(xAccessor.accessor(d)),
        tooltipTop: yScale(yAccessor.accessor(d)),
      });
    },
    [xScale, bisectX, data, xAccessor, showTooltip, yScale, yAccessor],
  );

  const getTooltip = useMemo(
    () =>
      tooltip ??
      ((data: T) => (
        <VStack alignItems="end" spacing={0}>
          <Box>
            {xAccessor.tooltip?.(xAccessor.accessor(data) as number & Date) ??
              String(xAccessor.accessor(data))}
          </Box>
          <Box>
            <HStack spacing={4} justifyItems="end">
              <Box>
                <Box w="8px" h="8px" bg={lineColor} />
              </Box>
              <Box>
                {yAccessor.tooltip?.(yAccessor.accessor(data) as number & Date) ??
                  String(yAccessor.accessor(data))}
              </Box>
            </HStack>
          </Box>
        </VStack>
      )),
    [lineColor, tooltip, xAccessor, yAccessor],
  );

  const axis = useMemo(() => {
    return (
      <>
        {data.length > 0 && (
          <div>
            {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              xAccessor.tooltip?.(xAccessor.accessor(data[0]!) as number & Date) ??
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                String(xAccessor.accessor(data[0]!))
            }
          </div>
        )}
        {data.length > 1 && (
          <div>
            {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              xAccessor.tooltip?.(xAccessor.accessor(data[data.length - 1]!) as number & Date) ??
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                String(xAccessor.accessor(data[data.length - 1]!))
            }
          </div>
        )}
      </>
    );
  }, [data, xAccessor]);

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <svg width={width} height={height}>
          <rect
            x={0}
            y={0}
            width={width}
            height={height}
            fill="url(#area-background-gradient)"
            rx={borderRadius}
          />
          <LinearGradient
            id="area-background-gradient"
            from={backgroundColor1}
            to={backgroundColor2}
          />
          <LinearGradient
            id="area-gradient"
            from={lineColor}
            to={lineColor}
            fromOpacity={0.12}
            toOpacity={0}
          />
          <GridRows
            left={marginLeft}
            scale={yScale}
            width={innerWidth}
            strokeDasharray="1,3"
            stroke={horizontalGridColor}
            pointerEvents="none"
          />
          <GridColumns
            top={marginTop}
            scale={xScale}
            height={innerHeight}
            strokeDasharray="1,3"
            stroke={verticalGridColor}
            pointerEvents="none"
          />
          <AreaClosed<T>
            data={data}
            curve={curve}
            x={(d) => xScale(xAccessor.accessor(d))}
            y={(d) => yScale(yAccessor.accessor(d))}
            yScale={yScale}
            fill="url(#area-gradient)"
          />
          <LinePath<T>
            data={data}
            curve={curve}
            x={(d) => xScale(xAccessor.accessor(d))}
            y={(d) => yScale(yAccessor.accessor(d))}
            stroke={lineColor}
            strokeWidth={2}
            shapeRendering="geometricPrecision"
          />
          <Bar
            x={marginLeft}
            y={marginTop}
            width={innerWidth}
            height={innerHeight}
            fill="transparent"
            rx={14}
            onTouchStart={handleTooltip}
            onTouchMove={handleTooltip}
            onMouseMove={handleTooltip}
            onMouseLeave={() => hideTooltip()}
          />
          {tooltipData && !noTooltip && (
            <g>
              <circle
                cx={tooltipLeft}
                cy={tooltipTop}
                r={4}
                fill={lineColor}
                pointerEvents="none"
              />
            </g>
          )}
        </svg>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            color: '#718096',
            fontFamily: 'Inter, arial, sans-serif',
            fontSize: '12px',
            fontWeight: 400,
            height: axisHeight,
            ...axisStyles,
          }}
        >
          {!noAxis && axis}
        </div>
      </div>
      {tooltipData && !noTooltip && (
        <TooltipWithBounds
          top={tooltipTop > innerHeight + marginTop - 50 ? tooltipTop - 50 : tooltipTop}
          left={tooltipLeft}
          style={fullTooltipStyles}
        >
          {getTooltip(tooltipData)}
        </TooltipWithBounds>
      )}
    </div>
  );
}

export type ChartAccessor<T extends object> =
  | BaseChartAccessor<ChartScaleType.Linear, T, number>
  | BaseChartAccessor<ChartScaleType.Time, T, Date>;

export interface BaseChartAccessor<K extends ChartScaleType, T extends object, R = unknown> {
  type: K;
  accessor: ChartDataAccessor<T, R>;
  domain?: ContinuousDomain | TimeDomain;
  tooltip?: ChartDataAccessor<R, ReactNode>;
}

export type ChartDataAccessor<T, R = unknown> = (data: T) => R;

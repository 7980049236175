import { createGqlSubscriptionApi } from '../create-subscription-api';
import {
  api as getUserOrganizationsApi,
  GetUserOrganizationsDocument,
} from '../queries/GetUserOrganizations.generated';

export const getUserOrganizationsSubcriptionApi = createGqlSubscriptionApi(
  getUserOrganizationsApi,
  'GetUserOrganizations',
  GetUserOrganizationsDocument,
);

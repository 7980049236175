import { FieldConfigFragment } from '@main/graphql/fragments/FieldConfigFragments.generated';
import { FieldValueFragment } from '@main/graphql/fragments/FieldValueFragments.generated';
import { Field_Types_Enum } from '@main/graphql/types.generated';

export function getFieldValue(config: FieldConfigFragment, values: FieldValueFragment[]) {
  switch (config.field_type) {
    case Field_Types_Enum.Text:
      return getTextFieldValue(config, values)?.value || '';
    case Field_Types_Enum.Select:
      return getSelectFieldValues(config, values).map((option) => option.value);
  }
}

export function getTextFieldValue(
  fieldConfig: FieldConfigFragment,
  fieldValues: FieldValueFragment[],
) {
  const fieldValue = fieldValues.find(
    (fieldValue) => fieldValue.field_config_id === fieldConfig.id,
  );
  return fieldValue?.text_field_value;
}

export function getSelectFieldValues(
  fieldConfig: FieldConfigFragment,
  fieldValues: FieldValueFragment[],
) {
  const selectedOptionIds = fieldValues
    .filter((fieldValue) => fieldValue.field_config_id === fieldConfig.id)
    .map((fieldValue) => fieldValue.select_field_value?.select_field_option_id);
  return (
    fieldConfig.select_field_config?.select_field_options.filter((option) =>
      selectedOptionIds.includes(option.id),
    ) || []
  );
}

import { Field_Entities_Enum } from '@main/graphql/types.generated';
import { useLocalStorageState } from '@main/ui';
import { VisibilityState } from '@tanstack/react-table';
import { useEffect, useMemo } from 'react';

import { hideCustomFieldColumns } from '../features/custom-fields/custom-field-column';
import { useGetFieldConfigsQuery } from '../features/custom-fields/field-config';
import { getCurrentUserSelectedOrgId } from '../features/user/slice';
import { useAppSelector } from '../hooks/redux-toolkit-hooks';

export function useTableSettings({
  storageKey,
  defaultColumnVisibility,
}: {
  storageKey: string;
  defaultColumnVisibility: VisibilityState;
}) {
  const [columnVisibility, setColumnVisibility] = useLocalStorageState(storageKey, {
    defaultValue: defaultColumnVisibility,
  });

  const isColumnVisibilityModified = Object.entries(columnVisibility).some(
    ([columnId, isVisible]) => {
      return isVisible && columnId in defaultColumnVisibility;
    },
  );

  return {
    columnVisibility,
    setColumnVisibility,
    isColumnVisibilityModified,
  };
}

export function useCustomFieldsTableSettings({
  entityName,
  storageKey,
  defaultColumnVisibility,
}: {
  entityName: Field_Entities_Enum;
  storageKey: string;
  defaultColumnVisibility: VisibilityState;
}) {
  const [columnVisibility, setColumnVisibility] = useLocalStorageState(storageKey, {
    defaultValue: defaultColumnVisibility,
  });

  const orgId = useAppSelector(getCurrentUserSelectedOrgId);
  const fieldConfigsQuery = useGetFieldConfigsQuery({ orgId, entityName });
  const fieldConfigs = fieldConfigsQuery.data?.field_configs;

  useEffect(() => {
    setColumnVisibility((columnVisibility) => ({
      ...hideCustomFieldColumns(fieldConfigs),
      ...columnVisibility,
    }));
  }, [fieldConfigs, setColumnVisibility]);

  const isColumnVisibilityModified = useMemo(() => {
    const defaultInvisibleColumnIds = [
      ...Object.keys(defaultColumnVisibility),
      ...(fieldConfigs || []).map(({ name }) => name),
    ];
    return Object.entries(columnVisibility).some(([columnId, isVisible]) => {
      return isVisible && defaultInvisibleColumnIds.includes(columnId);
    });
  }, [columnVisibility, defaultColumnVisibility, fieldConfigs]);

  return {
    columnVisibility,
    setColumnVisibility,
    isColumnVisibilityModified,
  };
}

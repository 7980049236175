import { FormControl, FormLabel, Text } from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { useDeleteVendorDocumentsMutation } from '@main/graphql/mutations/DeleteVendorDocuments.generated';
import { useAddVendorDocumentsMutation } from '@main/graphql/mutations/InsertVendorDocuments.generated';
import { isNonNullable, toError } from '@main/shared/utils';
import {
  errorToast,
  FileUpload,
  useDownloadStorageFile,
  useEagerMultipleFilesUpload,
} from '@main/ui';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { getCurrentUserSelectedOrgRole } from '../user/slice';
import { useGetVendorQuery } from './get-vendor.generated';

export const VendorDocumentsTab = ({
  vendorId,
  onFilesChange,
}: {
  vendorId: string;
  onFilesChange?: () => Promise<void>;
}) => {
  const { t } = useTranslation();
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canUpdateVendor = userRole.permissionMap?.write_vendors;

  const { data: vendorData } = useGetVendorQuery({ vendor_id: vendorId });
  const vendor = vendorData?.vendors_by_pk;

  const [deleteDocument] = useDeleteVendorDocumentsMutation();
  const [uploadDocuments] = useAddVendorDocumentsMutation();

  const fileUpload = useEagerMultipleFilesUpload({
    files: vendor?.documents.map(({ file }) => file).filter(isNonNullable),
    onFileDownload: useDownloadStorageFile(),
    onFileDelete: async (fileId) => {
      try {
        await deleteDocument({ fileId, vendorId }).unwrap();
        await onFilesChange?.();
      } catch (error) {
        errorToast(t('errorMessages.deleteFailed', { entity: t('entities.vendorDocument') }));
        datadogLogs.logger.error(
          'Failed deleting vendor document',
          { vendorId, fileId },
          toError(error),
        );
      }
    },
    onFilesUpload: async ({ errors, files }) => {
      if (errors.length) {
        errorToast(t('errorMessages.uploadFailed', { entity: t('entities.vendorDocument') }));
        for (const error of errors) {
          datadogLogs.logger.error(
            'Failed uploading vendor document files',
            { vendorId },
            toError(error),
          );
        }
        return;
      }

      const documents = files.map((file) => ({
        file_id: file.id,
        vendor_id: vendorId,
      }));
      try {
        datadogLogs.logger.info('Uploading vendor documents', { documents });
        await uploadDocuments({ documents_input: documents }).unwrap();
        await onFilesChange?.();
      } catch (e) {
        errorToast(t('errorMessages.uploadFailed', { entity: t('entities.vendorDocument') }));
        datadogLogs.logger.error('Failed uploading vendor documents', { documents }, toError(e));
      }
    },
  });

  return (
    <FormControl isReadOnly={!canUpdateVendor}>
      <FormLabel margin="0">{t('vendors.props.documents.heading')}</FormLabel>
      <Text fontSize="xs" mb={2}>
        {t('vendors.props.documents.subheading')}
      </Text>
      <FileUpload {...fileUpload}>
        <FileUpload.Dropzone />
      </FileUpload>
    </FormControl>
  );
};

import { createStandaloneToast, theme } from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { api as getEvidenceApi } from '@main/graphql/queries/GetEvidenceById.generated';
import { api as getProgramApi } from '@main/graphql/queries/GetProgram.generated';
import { api as getQuestionnaireApi } from '@main/graphql/queries/GetQuestionnaire.generated';
import { api as getReportApi } from '@main/graphql/queries/GetReportById.generated';
import { api as getRoleApi } from '@main/graphql/queries/GetRoleById.generated';
import { getUserOrganizationsSubcriptionApi } from '@main/graphql/subscriptions/GetUserOrganizationsSubscription';
import { OAuth2AuthCodePKCE } from '@main/oauth2-auth-code-pkce';
import { clearNhostSession, exhaustiveCheck, promiseRaceObject, toError } from '@main/shared/utils';
import { errorToast, successToast } from '@main/ui';
import { removeParameterFromWindow, SignInResponse } from '@nhost/nhost-js';
import {
  createListenerMiddleware,
  isAnyOf,
  ListenerEffectAPI,
  TaskAbortError,
  TypedStartListening,
} from '@reduxjs/toolkit';
import posthog from 'posthog-js';

import { router } from '../../router';
import { AppDispatch, AppRootState } from '../../store';
import { nhost } from '../../utils/nhostClient';
import { updateSandboxTranslations } from '../../utils/pwc-sandbox';
import { api as getControlApi } from '../controls/get-control.generated';
import { drawerLoaded } from '../layout/slice';
import { api as getPolicyApi } from '../policies/manage-policies.generated';
import { programIdPageLoaded } from '../program/slice';
import { reportIdPageLoaded } from '../reports/slice';
import { api as getRiskApi } from '../risks/get-risk.generated';
import { roleIdPageLoaded } from '../settings/organization/roles/slice';
import { api as getTaskApi } from '../tasks/get-task.generated';
import { api as getVendorApi } from '../vendors/get-vendor.generated';
import { api as getVendorQuestionnaireApi } from '../vendors/vendor-questionnaires/vendor-questionnaires.generated';
import {
  authenticatedUserIdReceived,
  authenticatedUserSetupFinished,
  errorOnLoadingSSOUser,
  getCurrentUserOrgs,
  getCurrentUserSelectedOrg,
  getCurrentUserSelectedOrgId,
  getCurrentUserSelectedOrgRole,
  getUserSSOState,
  impersonatePageLoaded,
  orgIdFoundInQueryParam,
  orgSwitchCompleted,
  orgSwitchRequested,
  samlSSOInitiated,
  ssoPageLoaded,
  userPreferenceOrgMismatched,
  userPreferenceOrgNotFound,
  userSessionInvalidated,
  userSignedOut,
} from './slice';

export const userListenerMiddleware = createListenerMiddleware();

const { toast } = createStandaloneToast({ theme });

const startListening = userListenerMiddleware.startListening as TypedStartListening<
  AppRootState,
  AppDispatch
>;

const oauthClient = new OAuth2AuthCodePKCE({
  authorizationUrl: `${import.meta.env.VITE_NHOST_AUTH_URL}/saml`,
  tokenUrl: `${import.meta.env.VITE_NHOST_AUTH_URL}/saml/token`,
  clientId: 'dummy',
  redirectUrl: `${window.location.origin}/sso`,
  scopes: [],
  onAccessTokenExpiry(refreshAccessToken) {
    return refreshAccessToken();
  },
  onInvalidGrant(refreshAuthCodeOrRefreshToken) {
    return refreshAuthCodeOrRefreshToken();
  },
});

const initObservabilityTools = (userId: string) => {
  datadogLogs.setUser({ id: userId });
  const dContext = datadogLogs.getInternalContext();

  const user = nhost.auth.getUser();

  if (!user) {
    datadogLogs.logger.error(`unable to fetch user ${userId}`, {});
    return;
  }

  const impersonatorId = nhost.auth.getHasuraClaim('impersonater-id');

  posthog.identify(userId, {
    email: user.email,
    userId,
    datadogSessionId: dContext?.session_id,
    appEnv: import.meta.env.VITE_APP_ENV,
    impersonatorId: impersonatorId ? impersonatorId : undefined,
  });
};

const setOrgDetailsInObservabilityTools = async ({
  getState,
  condition,
}: ListenerEffectAPI<AppRootState, AppDispatch>) => {
  const setOrgDetailsInTools = () => {
    const userOrg = getCurrentUserSelectedOrg(getState());
    const userRole = getCurrentUserSelectedOrgRole(getState());

    if (userOrg?.id) {
      datadogLogs.setUserProperty('orgId', userOrg.id);
      posthog.setPersonProperties({
        orgId: userOrg.id,
        orgName: userOrg.name,
        orgRole: userRole,
        parentOrgId: userOrg.parent_organization_id,
      });
      posthog.group('orgId', userOrg.id);
      updateSandboxTranslations(userOrg.id);
    }
  };

  setOrgDetailsInTools();

  // eslint-disable-next-line no-constant-condition
  while (true) {
    await condition((_, currentState, prevState) => {
      const prevOrgId = getCurrentUserSelectedOrgId(prevState);
      const currentOrgId = getCurrentUserSelectedOrgId(currentState);

      return prevOrgId !== currentOrgId;
    });
    setOrgDetailsInTools();
  }
};

const userSwitchOrg = async ({ take, dispatch }: ListenerEffectAPI<AppRootState, AppDispatch>) => {
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const unsubscribe = orgMismatchListener();
    const [orgSwitchAction] = await take(orgSwitchRequested.match);

    unsubscribe({ cancelActive: true });
    await router.navigate({ to: '/', replace: true });
    dispatch(orgSwitchCompleted({ orgId: orgSwitchAction.payload.orgId }));
    await router.navigate({ to: '/home', replace: true });
  }
};

const evaluateUserOrgOnLoad = ({
  getState,
  dispatch,
}: ListenerEffectAPI<AppRootState, AppDispatch>) => {
  const userSelectedOrgId = getCurrentUserSelectedOrgId(getState());
  const userOrgs = getCurrentUserOrgs(getState()) || [];

  // if explicit query param is set, it takes preference
  const urlParams = new URLSearchParams(window.location.search);
  const organizationIdFromQueryParam = urlParams.get('organizationId');
  const hasOrgFoundInQueryParamExist =
    organizationIdFromQueryParam && userOrgs.find((org) => org.id === organizationIdFromQueryParam);

  if (hasOrgFoundInQueryParamExist) {
    dispatch(orgIdFoundInQueryParam({ orgId: organizationIdFromQueryParam }));
    removeParameterFromWindow('organizationId');
    return;
  }

  const getPotentialOrgId = () => {
    let potentialOrgId: string | undefined;
    const mainOrg = userOrgs.find((org) => org.parent_organization_id === null);
    if (mainOrg?.id) {
      potentialOrgId = mainOrg.id;
    } else {
      potentialOrgId = userOrgs[0]?.id;
    }

    return potentialOrgId;
  };

  // if user has no org preference yet
  if (userOrgs.length > 0 && !userSelectedOrgId) {
    const potentialOrgId = getPotentialOrgId();
    potentialOrgId && dispatch(userPreferenceOrgNotFound({ orgId: potentialOrgId }));
  } else if (userOrgs.length > 0 && userSelectedOrgId) {
    const selectedOrg = userOrgs.find((org) => org.id === userSelectedOrgId);

    // if user preferred org is not available anymore
    if (!selectedOrg) {
      const potentialOrgId = getPotentialOrgId();
      potentialOrgId && dispatch(userPreferenceOrgNotFound({ orgId: potentialOrgId }));
    }
  }
};

startListening({
  actionCreator: authenticatedUserIdReceived,
  effect: async (userIdReceivedAction, listenerApi) => {
    const { dispatch, cancelActiveListeners, condition, fork } = listenerApi;
    cancelActiveListeners();

    const { userId } = userIdReceivedAction.payload;

    initObservabilityTools(userId);

    // get user organizations, their permissions in each organization and other users in the organization
    await dispatch(
      getUserOrganizationsSubcriptionApi.endpoints.GetUserOrganizations.initiate({
        user_id: userId,
      }),
    );

    const setOrgDetailsInObservabilityToolsTask = fork(() =>
      setOrgDetailsInObservabilityTools(listenerApi),
    );

    const orgSwitchTask = fork(() => userSwitchOrg(listenerApi));

    evaluateUserOrgOnLoad(listenerApi);

    // trigger action to indicate boostrap is finished
    dispatch(authenticatedUserSetupFinished());

    if (window.location.pathname === '/') {
      router.navigate({ to: '/home', replace: true });
    }

    try {
      // post login lifecycle management like user session invalidation on user disabled or user signed out
      const winner = await promiseRaceObject({
        userDisabledInOrg: condition((_, currentState) => !getCurrentUserSelectedOrg(currentState)),
        userSignedOut: condition(userSignedOut.match),
      });

      // clear long running tasks and cleanup
      setOrgDetailsInObservabilityToolsTask.cancel();
      orgSwitchTask.cancel();
      datadogLogs.clearUser();
      posthog.reset();

      await nhost.auth.signOut();

      router.navigate({ to: '/' });
      dispatch(userSessionInvalidated());

      if (winner.key === 'userDisabledInOrg') {
        toast({
          status: 'warning',
          title: 'Cannot load the data. You are disabled in this organization.',
        });
      }
    } catch (e) {
      if (e instanceof TaskAbortError) {
        return;
      }
      datadogLogs.logger.error(`error in user listener post auth`, {}, toError(e));
    }
  },
});

startListening({
  actionCreator: userPreferenceOrgMismatched,
  effect: async () => {
    successToast('Switched organization');
  },
});

const orgMismatchListener = () => {
  return startListening({
    matcher: isAnyOf(programIdPageLoaded, reportIdPageLoaded, roleIdPageLoaded, drawerLoaded),
    effect: async function switchOrgHandler(action, { dispatch, getState }) {
      if (drawerLoaded.match(action) && action.payload.drawerEntity) {
        switch (action.payload.drawerEntity) {
          case 'control': {
            const control = (
              await dispatch(
                getControlApi.endpoints.GetControl.initiate({
                  controlId: action.payload.drawerEntityId,
                }),
              )
            ).data?.controls_by_pk;
            const orgIdOfControl = control?.organization_id;

            if (orgIdOfControl && orgIdOfControl !== getCurrentUserSelectedOrgId(getState())) {
              dispatch(userPreferenceOrgMismatched({ orgId: orgIdOfControl }));
            }

            break;
          }
          case 'evidence': {
            const evidence = (
              await dispatch(
                getEvidenceApi.endpoints.GetEvidenceById.initiate({
                  evidence_id: action.payload.drawerEntityId,
                }),
              )
            ).data?.evidences_by_pk;
            const orgIdOfEvidence = evidence?.organization_id;

            if (orgIdOfEvidence && orgIdOfEvidence !== getCurrentUserSelectedOrgId(getState())) {
              dispatch(userPreferenceOrgMismatched({ orgId: orgIdOfEvidence }));
            }

            break;
          }
          case 'risk': {
            const risk = (
              await dispatch(
                getRiskApi.endpoints.GetRisk.initiate({ risk_id: action.payload.drawerEntityId }),
              )
            ).data?.risks_by_pk;
            const orgIdOfRisk = risk?.organization_id;

            if (orgIdOfRisk && orgIdOfRisk !== getCurrentUserSelectedOrgId(getState())) {
              dispatch(userPreferenceOrgMismatched({ orgId: orgIdOfRisk }));
            }

            break;
          }
          case 'vendor': {
            const vendor = (
              await dispatch(
                getVendorApi.endpoints.GetVendor.initiate({
                  vendor_id: action.payload.drawerEntityId,
                }),
              )
            ).data?.vendors_by_pk;
            const orgIdOfVendor = vendor?.organization_id;

            if (orgIdOfVendor && orgIdOfVendor !== getCurrentUserSelectedOrgId(getState())) {
              dispatch(userPreferenceOrgMismatched({ orgId: orgIdOfVendor }));
            }

            break;
          }
          case 'questionnaire': {
            const questionnaire = (
              await dispatch(
                getQuestionnaireApi.endpoints.GetQuestionnaire.initiate({
                  id: action.payload.drawerEntityId,
                }),
              )
            ).data?.questionnaires_by_pk;
            const orgIdOfQuestionnaire = questionnaire?.organization_id;

            if (
              orgIdOfQuestionnaire &&
              orgIdOfQuestionnaire !== getCurrentUserSelectedOrgId(getState())
            ) {
              dispatch(userPreferenceOrgMismatched({ orgId: orgIdOfQuestionnaire }));
            }

            break;
          }
          case 'task': {
            const result = (
              await dispatch(
                getTaskApi.endpoints.GetTask.initiate({ taskId: action.payload.drawerEntityId }),
              )
            ).data?.tasks_by_pk;
            const orgIdOfTask = result?.organization_id;

            if (orgIdOfTask && orgIdOfTask !== getCurrentUserSelectedOrgId(getState())) {
              dispatch(userPreferenceOrgMismatched({ orgId: orgIdOfTask }));
            }
            break;
          }
          case 'vendor-questionnaire': {
            const result = (
              await dispatch(
                getVendorQuestionnaireApi.endpoints.GetVendorQuestionnairesById.initiate({
                  vqId: action.payload.drawerEntityId,
                }),
              )
            ).data?.vendor_questionnaires_by_pk;
            const orgIdOfVendor = result?.vendor.organization_id;

            if (orgIdOfVendor && orgIdOfVendor !== getCurrentUserSelectedOrgId(getState())) {
              dispatch(userPreferenceOrgMismatched({ orgId: orgIdOfVendor }));
            }
            break;
          }
          case 'policy-version':
          case 'policy': {
            const [policyId] = action.payload.drawerEntityId.split(':');
            const result = (
              await dispatch(
                getPolicyApi.endpoints.GetPolicy.initiate({
                  // policyId would always be there as split would return at least one element
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  id: policyId!,
                }),
              )
            ).data?.policies_by_pk;
            const orgIdOfPolicy = result?.organization_id;

            if (orgIdOfPolicy && orgIdOfPolicy !== getCurrentUserSelectedOrgId(getState())) {
              dispatch(userPreferenceOrgMismatched({ orgId: orgIdOfPolicy }));
            }
            break;
          }
          default:
            return exhaustiveCheck(action.payload.drawerEntity);
        }
      } else if (reportIdPageLoaded.match(action)) {
        const report = (
          await dispatch(
            getReportApi.endpoints.GetReportById.initiate({ id: action.payload.reportId }),
          )
        ).data?.reports_by_pk;
        const orgIdOfReport = report?.organization_id;

        if (orgIdOfReport && orgIdOfReport !== getCurrentUserSelectedOrgId(getState())) {
          dispatch(userPreferenceOrgMismatched({ orgId: orgIdOfReport }));
        }
      } else if (programIdPageLoaded.match(action)) {
        const program = (
          await dispatch(
            getProgramApi.endpoints.GetProgram.initiate({ programId: action.payload.programId }),
          )
        ).data?.programs_by_pk;
        const orgIdOfProgram = program?.organization_id;

        if (orgIdOfProgram && orgIdOfProgram !== getCurrentUserSelectedOrgId(getState())) {
          dispatch(userPreferenceOrgMismatched({ orgId: orgIdOfProgram }));
        }
      } else if (roleIdPageLoaded.match(action)) {
        const role = (
          await dispatch(
            getRoleApi.endpoints.GetRoleById.initiate({ roleId: action.payload.roleId }),
          )
        ).data?.roles_by_pk;
        const orgIdOfRole = role?.organization_id;

        if (orgIdOfRole && orgIdOfRole !== getCurrentUserSelectedOrgId(getState())) {
          dispatch(userPreferenceOrgMismatched({ orgId: orgIdOfRole }));
        }
      }
    },
  });
};

startListening({
  actionCreator: ssoPageLoaded,
  effect: async (_, { take, fork, dispatch, cancelActiveListeners, getState }) => {
    cancelActiveListeners();

    fork(async () => {
      try {
        if (getUserSSOState(getState()).error) {
          return;
        }

        const hasAuthCode = await oauthClient.isReturningFromAuthServer();

        if (!hasAuthCode) {
          return;
        }
        const tokenResult = await oauthClient.getAccessToken();

        const res = await fetch(`${import.meta.env.VITE_NHOST_AUTH_URL}/saml/identify`, {
          method: 'POST',
          body: JSON.stringify({
            access_token: tokenResult.token?.value,
          }),
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const result = (await res.json()) as SignInResponse & { message: string };

        if (!res.ok) {
          throw new Error(result?.message);
        }

        await nhost.auth.refreshSession(result?.session?.refreshToken ?? undefined);
        router.navigate({ to: '/' });
      } catch (e) {
        if (e instanceof Error) {
          dispatch(
            errorOnLoadingSSOUser({
              error: e.message,
            }),
          );
        } else {
          dispatch(errorOnLoadingSSOUser());
        }
      } finally {
        oauthClient.reset();
      }
    });

    const [samlSSOInitiatedAction] = await take(samlSSOInitiated.match);
    const teamDomain = samlSSOInitiatedAction.payload.teamDomain;

    await oauthClient.fetchAuthorizationCode({
      tenant: teamDomain,
    });
  },
});

startListening({
  actionCreator: impersonatePageLoaded,
  effect: async (action, api) => {
    api.unsubscribe();

    try {
      const { token } = action.payload;

      await nhost.auth.signOut();
      clearNhostSession();

      const tokenResult = await nhost.auth.refreshSession(token);
      if (tokenResult.error) {
        throw tokenResult.error;
      }
      router.navigate({ to: '/' });
    } catch (e) {
      datadogLogs.logger.error(`error in user impersonation`, {}, toError(e));
      errorToast('Impersonation failed!, try again or log out first');
    } finally {
      api.subscribe();
    }
  },
});

import { Outlet, Route } from '@tanstack/react-router';

import { organizationSettingsLayoutRoute } from '../layout';

const RisksLayout = () => {
  return <Outlet />;
};

export const organizationRisksSettingsLayoutRoute = new Route({
  getParentRoute: () => organizationSettingsLayoutRoute,
  path: 'risks',
  component: RisksLayout,
});

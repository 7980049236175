import { Route } from '@tanstack/react-router';

import { UsersPage } from '../../../features/settings/organization/users';
import { organizationSettingsLayoutRoute } from './layout';

export const organizationUsersSettingsRoute = new Route({
  getParentRoute: () => organizationSettingsLayoutRoute,
  path: '/users',
  component: UsersPage,
});

import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Icon,
  IconButton,
  Skeleton,
  Spacer,
  Tooltip,
  useColorModeValue,
} from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { PlusIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { TaskStatus } from '@main/graphql/client-scalars';
import { toError } from '@main/shared/utils';
import { EmptyPlaceholder, errorToast } from '@main/ui';
import { createSelector } from '@reduxjs/toolkit';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { getCurrentUserId } from '../user/slice';
import {
  GetAssignedToMeTasksQuery,
  GetCreatedByMeTasksQuery,
  useGetAssignedToMeTasksQuery,
  useGetCreatedByMeTasksQuery,
  useUpdateStatusForTasksMutation,
} from './manage-task-sidebar.generated';
import { FilterCriteria, SidebarFilter, taskFilter } from './sidebar-filter';
import { SidebarSort, SortCriteria, taskSort } from './sidebar-sort';
import { getLinkedEntities } from './slice';
import { TaskSidebarItem, useRefetchTasks } from './task-sidebar-item';
import { useCreateTask } from './use-create-task';

type Tasks = GetAssignedToMeTasksQuery['tasks'] | GetCreatedByMeTasksQuery['tasks'];

// if we don't use a selector here, it will re-render all the time as selectFromResult does shallow equality check
const selectFormattedTasks = createSelector(
  (props: { tasks: Tasks; filters: FilterCriteria[]; sorts: SortCriteria }) => props,
  ({ tasks, filters, sorts }) => {
    const formattedTasks =
      tasks?.map((task) => ({
        ...task,
        linkedEntities: getLinkedEntities(task),
      })) ?? [];
    const taskIds = formattedTasks.map((task) => task.id) ?? [];
    const filteredTasks = formattedTasks
      .filter((task) => taskFilter(task, filters))
      .sort((taskA, taskB) => taskSort(taskA, taskB, sorts));

    return { tasks: filteredTasks, taskIds };
  },
);

export const AssignedToMeTab = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation();
  const [filters, setFilters] = useState<FilterCriteria[]>(['All']);
  const [sorts, setSorts] = useState<SortCriteria>({
    property: 'created_at',
    isAscending: true,
  });
  const userId = useAppSelector(getCurrentUserId);
  const { tasks, taskIds, isLoading } = useGetAssignedToMeTasksQuery(
    {
      userId,
    },
    {
      refetchOnMountOrArgChange: true,
      pollingInterval: 5000,
      selectFromResult: ({ data, isLoading }) => ({
        ...selectFormattedTasks({
          tasks: data?.tasks ?? [],
          filters,
          sorts,
        }),
        isLoading,
      }),
    },
  );

  const renderContent = () => {
    if (isLoading) {
      return <LoadingState />;
    }

    if (tasks.length === 0) {
      return (
        <EmptyState
          heading={t('tasks.sidebar.emptyState.assignedToMe.heading')}
          subheading={t('tasks.sidebar.emptyState.assignedToMe.subheading')}
        />
      );
    }

    return tasks.map((task) => (
      <TaskSidebarItem key={task.id} task={task} onClose={onClose} hideAssignee />
    ));
  };

  return (
    <Box>
      <Flex pt={3} px={6}>
        <SidebarFilter filters={filters} setFilters={setFilters} />
        <Spacer />
        <SidebarSort sorts={sorts} setSorts={setSorts} />
      </Flex>
      {renderContent()}
      <TabFooter taskIds={taskIds} onClose={onClose} />
    </Box>
  );
};

export const CreatedByMeTab = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation();
  const [filters, setFilters] = useState<FilterCriteria[]>([]);
  const [sorts, setSorts] = useState<SortCriteria>({
    property: 'created_at',
    isAscending: true,
  });
  const userId = useAppSelector(getCurrentUserId);
  const { onTaskCreate, isCreatingTask } = useCreateTask();
  const { tasks, taskIds, isLoading } = useGetCreatedByMeTasksQuery(
    {
      creatorId: userId,
    },
    {
      pollingInterval: 5000,
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ data, isLoading }) => ({
        ...selectFormattedTasks({
          tasks: data?.tasks ?? [],
          filters,
          sorts,
        }),
        isLoading,
      }),
    },
  );

  const renderContent = () => {
    if (isLoading) {
      return <LoadingState />;
    }

    if (tasks.length === 0) {
      return (
        <Box textAlign={'center'}>
          <EmptyState
            heading={t('tasks.sidebar.emptyState.createdByMe.heading')}
            subheading={t('tasks.sidebar.emptyState.createdByMe.subheading')}
          />
          <Button
            colorScheme="blue"
            leftIcon={<Icon as={PlusIcon} />}
            onClick={async () => {
              onClose();
              await onTaskCreate();
            }}
            isLoading={isCreatingTask}
          >
            {t('tasks.addTask')}
          </Button>
        </Box>
      );
    }

    return tasks.map((task) => <TaskSidebarItem key={task.id} task={task} onClose={onClose} />);
  };

  return (
    <Box>
      <Flex pt={3} px={6}>
        <SidebarFilter filters={filters} setFilters={setFilters} />
        <Spacer />
        <SidebarSort sorts={sorts} setSorts={setSorts} />
      </Flex>
      {renderContent()}
      <TabFooter taskIds={taskIds} onClose={onClose} />
    </Box>
  );
};

const TabFooter = ({ taskIds, onClose }: { taskIds: string[]; onClose: () => void }) => {
  const { t } = useTranslation();

  const actionButtonColor = useColorModeValue('gray.800', 'gray.300');
  const bgColor = useColorModeValue('white', 'gray.700');
  const refetchTasks = useRefetchTasks();
  const { onTaskCreate, isCreatingTask } = useCreateTask();
  const [updateTasks] = useUpdateStatusForTasksMutation();

  const markAllPending = async () => {
    try {
      await updateTasks({
        taskIds: taskIds,
        status: TaskStatus.PENDING,
        completed_at: null,
      });
      refetchTasks();
    } catch (error) {
      errorToast(t('errorMessages.updateFailed', { entity: t('entities.task') }));
      datadogLogs.logger.error(
        'Updating multiple tasks failed',
        { taskIds, value: new Date().toISOString() },
        toError(error),
      );
    }
  };

  const markAllComplete = async () => {
    try {
      await updateTasks({
        taskIds: taskIds,
        status: TaskStatus.COMPLETED,
        completed_at: 'now()',
      });
      refetchTasks();
    } catch (error) {
      errorToast(t('errorMessages.updateFailed', { entity: t('entities.task') }));
      datadogLogs.logger.error(
        'Updating multiple tasks failed',
        { taskIds, value: new Date().toISOString() },
        toError(error),
      );
    }
  };

  return (
    <Flex
      borderTopWidth="1px"
      justifyContent={'space-between'}
      p={3}
      width={'md'}
      position={'fixed'}
      bottom={0}
      right={0}
      bgColor={bgColor}
    >
      <ButtonGroup spacing={3}>
        <Button
          variant="outline"
          onClick={markAllComplete}
          textColor={actionButtonColor}
          fontSize={'sm'}
          fontWeight={'medium'}
        >
          {t('tasks.sidebar.buttons.markAllComplete')}
        </Button>
        <Button
          variant="outline"
          onClick={markAllPending}
          textColor={actionButtonColor}
          fontSize={'sm'}
          fontWeight={'medium'}
        >
          {t('tasks.sidebar.buttons.markAllPending')}
        </Button>
      </ButtonGroup>

      <Tooltip label={t('tasks.addTask')} placement="left">
        <IconButton
          variant={'outline'}
          aria-label="Create new task"
          icon={<Icon boxSize={5} as={PlusIcon} />}
          isLoading={isCreatingTask}
          onClick={async () => {
            onClose();
            await onTaskCreate();
          }}
        />
      </Tooltip>
    </Flex>
  );
};

const LoadingState = () => {
  const array = new Array(6).fill(0);
  const bottomBorderColor = useColorModeValue('gray.200', 'gray.500');

  return array.map((_, index) => (
    <Flex
      key={index}
      px={6}
      py={5}
      borderBottom={'1px'}
      borderColor={bottomBorderColor}
      direction={'column'}
      gap={2}
    >
      <Flex gap={3} alignItems={'center'}>
        <Skeleton h={5} w={24} />
        <Skeleton h={5} w={32} />
        <Spacer />
        <Skeleton h={9} w={9} rounded={'full'} />
      </Flex>
      <Skeleton h={12} w={'full'} />
      <Flex gap={3} alignItems={'center'}>
        <Skeleton h={3} w={24} />
        <Skeleton h={3} w={24} />
        <Skeleton h={3} w={24} />
      </Flex>
    </Flex>
  ));
};

const EmptyState = ({ heading, subheading }: { heading: string; subheading: string }) => {
  return (
    <EmptyPlaceholder py={8}>
      <EmptyPlaceholder.Icon as={XMarkIcon} />
      <EmptyPlaceholder.Content>
        <EmptyPlaceholder.Heading>{heading}</EmptyPlaceholder.Heading>
        <EmptyPlaceholder.Subheading>{subheading}</EmptyPlaceholder.Subheading>
      </EmptyPlaceholder.Content>
    </EmptyPlaceholder>
  );
};

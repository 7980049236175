import {
  Button,
  ButtonGroup,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Switch,
  TabPanel,
  TabPanels,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Field_Types_Enum } from '@main/graphql/types.generated';
import { ButtonTab, ButtonTabList, ButtonTabs, createEnumTabsControl, EditableTag } from '@main/ui';
import { FormEvent, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

export type FieldSchema =
  | (TextFieldSchema & {
      fieldType: Field_Types_Enum.Text;
    })
  | (SelectFieldSchema & {
      fieldType: Field_Types_Enum.Select;
    });

const textFieldSchema = z.object({
  name: z.string().trim().min(1),
});

type TextFieldSchema = z.infer<typeof textFieldSchema>;

const defaultTextValues: TextFieldSchema = {
  name: '',
};

const selectFieldSchema = z.object({
  name: z.string().trim().min(1),
  options: z.array(z.string().trim().min(1)).min(1),
  isMulti: z.boolean(),
  isCreatable: z.boolean(),
});

type SelectFieldSchema = z.infer<typeof selectFieldSchema>;

const defaultSelectValues: SelectFieldSchema = {
  name: '',
  options: [],
  isMulti: false,
  isCreatable: false,
};

const tabsControl = createEnumTabsControl([Field_Types_Enum.Text, Field_Types_Enum.Select]);

export const FieldConfigModal = ({
  editingFieldSchema,
  isOpen,
  onClose,
  onSubmit,
}: {
  editingFieldSchema?: FieldSchema;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: FieldSchema) => void;
}) => {
  const { t } = useTranslation();
  const actionType = editingFieldSchema ? 'update' : 'create';

  const [fieldType, setFieldType] = useState(
    editingFieldSchema?.fieldType || Field_Types_Enum.Text,
  );

  const textFieldForm = useForm<TextFieldSchema>({
    resolver: zodResolver(textFieldSchema),
    defaultValues:
      editingFieldSchema?.fieldType === Field_Types_Enum.Text
        ? editingFieldSchema
        : defaultTextValues,
  });

  const selectFieldForm = useForm<SelectFieldSchema>({
    resolver: zodResolver(selectFieldSchema),
    defaultValues:
      editingFieldSchema?.fieldType === Field_Types_Enum.Select
        ? editingFieldSchema
        : defaultSelectValues,
  });

  useEffect(() => {
    if (!isOpen) {
      textFieldForm.reset(defaultTextValues);
      selectFieldForm.reset(defaultSelectValues);
    }
  }, [isOpen, textFieldForm, selectFieldForm]);

  const isSubmitting =
    textFieldForm.formState.isSubmitting || selectFieldForm.formState.isSubmitting;

  const isDirty = (() => {
    switch (fieldType) {
      case Field_Types_Enum.Text:
        return textFieldForm.formState.isDirty;
      case Field_Types_Enum.Select:
        return selectFieldForm.formState.isDirty;
    }
  })();

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    switch (fieldType) {
      case Field_Types_Enum.Text: {
        textFieldForm.handleSubmit((data) => onSubmit({ fieldType, ...data }))();
        break;
      }

      case Field_Types_Enum.Select: {
        selectFieldForm.handleSubmit((data) => onSubmit({ fieldType, ...data }))();
        break;
      }
    }
  };

  return (
    <Modal size="xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t(`customFields.modal.${actionType}.heading`)}</ModalHeader>
        <ModalCloseButton />

        <form onSubmit={handleSubmit}>
          <ModalBody>
            <ButtonTabs {...tabsControl(fieldType, setFieldType)}>
              <FormControl>
                <FormLabel>{t('customFields.modal.fields.type.label')}:</FormLabel>
                <ButtonTabList isDisabled={actionType === 'update'}>
                  <ButtonTab>{t('customFields.modal.fields.type.values.text')}</ButtonTab>
                  <ButtonTab>{t('customFields.modal.fields.type.values.select')}</ButtonTab>
                </ButtonTabList>
              </FormControl>

              <TabPanels>
                <TabPanel px={0}>
                  <FormControl isInvalid={!!textFieldForm.formState.errors.name}>
                    <FormLabel>{t('customFields.modal.fields.name.label')}:</FormLabel>
                    <Input
                      type="text"
                      placeholder={t('customFields.modal.fields.name.placeholder')}
                      {...textFieldForm.register('name')}
                    />
                    <FormErrorMessage>
                      {textFieldForm.formState.errors.name?.message}
                    </FormErrorMessage>
                  </FormControl>
                </TabPanel>

                <TabPanel px={0}>
                  <Stack spacing={4}>
                    <FormControl isInvalid={!!selectFieldForm.formState.errors.name}>
                      <FormLabel>{t('customFields.modal.fields.name.label')}:</FormLabel>
                      <Input
                        type="text"
                        placeholder={t('customFields.modal.fields.name.placeholder')}
                        {...selectFieldForm.register('name')}
                      />
                      <FormErrorMessage>
                        {selectFieldForm.formState.errors.name?.message}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={!!selectFieldForm.formState.errors.options}>
                      <FormLabel>{t('customFields.modal.fields.options.label')}:</FormLabel>
                      <Controller
                        name="options"
                        control={selectFieldForm.control}
                        render={({ field }) => (
                          <EditableTag
                            isMulti
                            value={field.value.map((value) => ({ value, colorScheme: 'purple' }))}
                            getNewOptionData={(value) => ({ value, colorScheme: 'purple' })}
                            onChange={(values) => {
                              field.onChange(values.map(({ value }) => value));
                            }}
                          />
                        )}
                      />
                      <FormErrorMessage>
                        {selectFieldForm.formState.errors.options?.message}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={!!selectFieldForm.formState.errors.isMulti}>
                      <FormLabel>{t('customFields.modal.fields.isMulti.label')}:</FormLabel>
                      <Switch {...selectFieldForm.register('isMulti')} />
                      <FormErrorMessage>
                        {selectFieldForm.formState.errors.isMulti?.message}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={!!selectFieldForm.formState.errors.isCreatable}>
                      <FormLabel>{t('customFields.modal.fields.isCreatable.label')}:</FormLabel>
                      <Switch {...selectFieldForm.register('isCreatable')} />
                      <FormErrorMessage>
                        {selectFieldForm.formState.errors.isCreatable?.message}
                      </FormErrorMessage>
                    </FormControl>
                  </Stack>
                </TabPanel>
              </TabPanels>
            </ButtonTabs>
          </ModalBody>

          <ModalFooter>
            <ButtonGroup>
              <Button isDisabled={isSubmitting} onClick={onClose}>
                {t('buttons.cancel')}
              </Button>
              <Button
                type="submit"
                colorScheme="blue"
                isLoading={isSubmitting}
                isDisabled={!isDirty}
              >
                {t(`buttons.${actionType}`)}
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

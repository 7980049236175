import { Box, StackDivider, TableContainer, useColorModeValue, VStack } from '@chakra-ui/react';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { ColumnSizingState, Row, Table } from '@tanstack/react-table';
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef } from 'react';

import { shouldDisplayPagination, TablePagination } from './pagination';
import { TableItem, TableRowReorderHandler } from './table';
import { TableActionHandler } from './table-action';
import { TableColumnHeader } from './table-column-header';
import { TableRow } from './table-row';

export interface TableFilterResultProps<T> {
  itemName: TableItem;
  table: Table<T>;
  minColumnSize: number;
  actionHandler: TableActionHandler<T>;
  onRowClick?(row: Row<T>): void;
  setColumnSizing: Dispatch<SetStateAction<ColumnSizingState>>;
  onRowReorder?: TableRowReorderHandler<T>;
}

export function TableFilterResult<T>({
  itemName,
  table,
  minColumnSize,
  actionHandler,
  onRowClick,
  setColumnSizing,
  onRowReorder,
}: TableFilterResultProps<T>) {
  const theadColor = useColorModeValue('gray.500', 'gray.400');
  const bgColor = useColorModeValue(`gray.25`, `gray.700`);
  const borderColor = useColorModeValue(`gray.200`, `gray.600`);
  const resizeHandleColor = useColorModeValue('blue.500', 'blue.300');
  const headers = table.getFlatHeaders();
  const rows = table.getRowModel().rows;
  const rowSelection = table.getState().rowSelection;
  const columnSizingInfo = table.getState().columnSizingInfo;
  const columnSizing = table.getState().columnSizing;
  const isSizingApplied = useMemo(() => Object.keys(columnSizing).length > 0, [columnSizing]);
  const isReorderable = !!onRowReorder;

  const rowStyles = useMemo(
    () => ({
      position: 'relative',
      display: 'grid',
      gridTemplateColumns: 'subgrid',
      gridColumn: `1/${headers.length + 2}`,
      '+ .why-table--row .why-table--cell': {
        borderTop: '1px',
        borderTopColor: `${borderColor} !important`,
      },
      ':after': {
        content: '""',
        position: 'absolute',
        display: 'none',
        pointerEvents: 'none',
        inset: '0',
        border: '2px',
        borderColor: 'blue.200',
      },
      '.without-pagination &:last-of-type:after': {
        borderBottomLeftRadius: 'var(--card-radius)',
        borderBottomRightRadius: 'var(--card-radius)',
      },
      '&.why-table--row-selected:after': {
        display: 'block',
      },
      '&:nth-of-type(even)': {
        '.why-table--cell': {
          backgroundColor: bgColor,
        },
      },
    }),
    [bgColor, borderColor, headers.length],
  );

  const theadStyles = useMemo(
    () => ({
      position: 'relative',
      transition: 'background-color 0.2s ease-out',
      color: theadColor,
      backgroundColor: bgColor,
      borderColor: `${borderColor} !important`,
      borderBottom: '1px',
      fontWeight: 'medium',
      fontSize: 'xs',
      lineHeight: '4',
      textTransform: 'none',
      letterSpacing: 'normal',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      px: 4,
      py: 3,
      '&:hover .why-table--resize-handle': {
        backgroundColor: resizeHandleColor,
      },
    }),
    [bgColor, borderColor, resizeHandleColor, theadColor],
  );

  const gridTemplateColumns = useMemo(
    () =>
      headers
        .map((header) => {
          if (header.column.getIsResizing() || header.column.id in columnSizing) {
            return intToPxGrid(header.column.getSize());
          }

          if (header.column.columnDef.size !== undefined) {
            return intToPxGrid(header.column.columnDef.size);
          }

          if (
            header.column.columnDef.minSize !== undefined ||
            header.column.columnDef.maxSize !== undefined
          ) {
            return `minmax(${intToPxGrid(header.column.columnDef.minSize)}, ${intToPxGrid(
              header.column.columnDef.maxSize,
            )})`;
          }

          return `minmax(${intToPxGrid(minColumnSize)}, auto)`;
        })
        .join(' ') + ` ${isSizingApplied ? 'auto' : '0fr'}`,
    [headers, isSizingApplied, columnSizing, minColumnSize],
  );

  const headerRefs = useRef<Record<string, HTMLElement>>({});
  useEffect(() => void (headerRefs.current = { ...headerRefs.current }), [headers]);

  const updateColumnSizing = useCallback(async () => {
    const sizing = Object.fromEntries(
      Object.entries(headerRefs.current).map(([id, el]) => [id, el.getBoundingClientRect().width]),
    );

    setColumnSizing((prev) => ({ ...prev, ...sizing }));
  }, [setColumnSizing]);

  const handleDragEnd = (event: DragEndEvent) => {
    if (!onRowReorder || !event.over || event.active.id === event.over.id) {
      return;
    }

    const fromRow = table.getRow(event.active.id as string);
    const toRow = table.getRow(event.over.id as string);

    onRowReorder(fromRow, toRow, table);
  };

  const tHead = useMemo(
    () => (
      <Box
        role="row"
        className="why-table--row"
        userSelect={columnSizingInfo.isResizingColumn ? 'none' : undefined}
        __css={rowStyles}
      >
        {headers.map((header) => (
          <TableColumnHeader
            key={header.id}
            ref={(el) => el && (headerRefs.current[header.id] = el)}
            header={header}
            resizeHandleColor={resizeHandleColor}
            styles={theadStyles}
            updateColumnSizing={updateColumnSizing}
          />
        ))}
        <Box p={0} __css={theadStyles} />
      </Box>
    ),
    [
      columnSizingInfo.isResizingColumn,
      headers,
      resizeHandleColor,
      rowStyles,
      theadStyles,
      updateColumnSizing,
    ],
  );

  const tBody = useMemo(
    () =>
      rows.map((row) => (
        <TableRow
          key={row.id}
          row={row}
          itemName={itemName}
          borderColor={borderColor}
          rowStyles={rowStyles}
          isReorderable={isReorderable}
          actionHandler={actionHandler}
          onRowClick={onRowClick}
        />
      )),
    // HACK: Need `headers` and `rowSelection` to re-render when columns or row selection change
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      rows,
      itemName,
      borderColor,
      rowStyles,
      isReorderable,
      onRowClick,
      actionHandler,
      headers,
      rowSelection,
    ],
  );

  return (
    <DndContext
      collisionDetection={closestCenter}
      modifiers={[restrictToVerticalAxis]}
      onDragEnd={handleDragEnd}
      sensors={useSensors(
        useSensor(MouseSensor, {}),
        useSensor(TouchSensor, {}),
        useSensor(KeyboardSensor, {}),
      )}
    >
      <VStack align="normal" spacing={0} divider={<StackDivider />}>
        <TableContainer>
          <Box
            role="table"
            aria-label={itemName.plural}
            display="grid"
            gridTemplateColumns={gridTemplateColumns}
            placeItems="stretch"
            w="full"
          >
            <Box display="contents" role="rowgroup">
              {tHead}
            </Box>
            <Box display="contents" role="rowgroup">
              <SortableContext items={rows} strategy={verticalListSortingStrategy}>
                {tBody}
              </SortableContext>
            </Box>
          </Box>
        </TableContainer>
        {shouldDisplayPagination(table) && <TablePagination table={table} />}
      </VStack>
    </DndContext>
  );
}

function intToPxGrid(value?: number) {
  return value !== undefined ? `${value}px` : 'auto';
}

import { HStack, Icon, IconButton, Link, Tooltip } from '@chakra-ui/react';
import {
  ArrowDownTrayIcon,
  ArrowTopRightOnSquareIcon,
  PencilIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { Evidences_Bool_Exp } from '@main/graphql/types.generated';
import { isNonNullable } from '@main/shared/utils';
import {
  createColumnHelper,
  Table,
  useDownloadStorageFile,
  useDrawer,
  useTableFiltersQuery,
  useTableSearchQuery,
  useTableSortQuery,
} from '@main/ui';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { getCurrentUserSelectedOrgRole } from '../user/slice';
import { useDeleteEvidence } from './delete-evidence';
import { useOrgEvidenceTableQuery } from './evidence-query';
import { OrganizationEvidence } from './slice';
import { useEvidenceColumnHelper } from './table-columns';
import { useEvidenceTableSettings } from './table-settings';
import { useLazyIsUserAuthorizedToChangeEvidence } from './utils';

export const EvidenceTable: FC = () => {
  const { t } = useTranslation();

  const { columnVisibility, setColumnVisibility } = useEvidenceTableSettings(
    'evidence:table:column-visibility',
  );
  const columns = useEvidenceTableColumns();
  const [columnFilters, setColumnFilters] = useTableFiltersQuery({
    columns,
    searchParam: 'evidenceFilter',
  });
  const [globalFilter, setGlobalFilter] = useTableSearchQuery({
    searchParam: 'search',
  });
  const [sorting, setSorting] = useTableSortQuery({ searchParam: 'evidenceSort' });
  const tableItemName = useMemo(() => {
    return {
      singular: t('entities.evidence').toLowerCase(),
      plural: t('entities.evidence').toLowerCase(),
    };
  }, [t]);

  const tableProps = useOrgEvidenceTableQuery({
    columns,
    globalFilter,
    columnFilters,
    sorting,
  });

  return (
    <Table
      minW={'900px'}
      entity="evidence"
      columns={columns}
      itemName={tableItemName}
      columnFilters={columnFilters}
      onColumnFiltersChange={setColumnFilters}
      globalFilter={globalFilter}
      onGlobalFilterChange={setGlobalFilter}
      sorting={sorting}
      onSortingChange={setSorting}
      columnVisibility={columnVisibility}
      onColumnVisibilityChange={setColumnVisibility}
      {...tableProps}
    />
  );
};

function useEvidenceTableColumns() {
  const { t } = useTranslation();
  const drawer = useDrawer();

  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canViewControls = userRole.permissionMap?.read_controls;
  const isUserAuthorizedToChangeEvidence = useLazyIsUserAuthorizedToChangeEvidence();

  const deleteEvidence = useDeleteEvidence();

  const onEvidenceOpen = useCallback(
    (evidenceId: string) => drawer.open({ entity: 'evidence', entityId: evidenceId }),
    [drawer],
  );

  const downloadStorageFile = useDownloadStorageFile();
  const evidenceColumnHelper = useEvidenceColumnHelper();

  return useMemo(() => {
    const columnHelper = createColumnHelper<OrganizationEvidence, Evidences_Bool_Exp>();
    return [
      evidenceColumnHelper.status(),
      evidenceColumnHelper.internalId(),
      evidenceColumnHelper.name({ onClickOpenDrawer: true }),
      ...(canViewControls ? [evidenceColumnHelper.programs()] : []),
      evidenceColumnHelper.description(),
      evidenceColumnHelper.controlsCount(),
      evidenceColumnHelper.validityStart(),
      evidenceColumnHelper.tags(),
      evidenceColumnHelper.owner(),

      columnHelper.columns.actions({
        id: 'actions',
        size: 90,
        PrimaryAction: ({ row }) => {
          const { file } = row.original.currentVersion;
          return (
            <HStack spacing={2}>
              <IconButton
                minW={4}
                aria-label={'Edit'}
                variant="link"
                icon={<PencilIcon />}
                onClick={() => onEvidenceOpen(row.original.id)}
              />
              {file?.id && (
                <Tooltip label={t('evidences.tooltip.downloadBtn')}>
                  <IconButton
                    minW={4}
                    aria-label={'Download'}
                    variant="link"
                    icon={<ArrowDownTrayIcon />}
                    onClick={() => downloadStorageFile(file.id)}
                  />
                </Tooltip>
              )}
              {row.original.currentVersion.url && (
                <Tooltip label={t('evidences.tooltip.linkBtn')}>
                  <Link href={row.original.currentVersion.url} isExternal>
                    <IconButton
                      minW={4}
                      aria-label={'Link'}
                      variant="link"
                      icon={<ArrowTopRightOnSquareIcon />}
                    />
                  </Link>
                </Tooltip>
              )}
            </HStack>
          );
        },
        secondaryActions: ({ row }) =>
          [
            isUserAuthorizedToChangeEvidence(row.original)
              ? {
                  icon: <Icon as={TrashIcon} />,
                  label: t('buttons.delete'),
                  onClick: () => {
                    deleteEvidence(row.original);
                  },
                }
              : null,
          ].filter(isNonNullable),
      }),
    ];
  }, [
    evidenceColumnHelper,
    canViewControls,
    t,
    onEvidenceOpen,
    downloadStorageFile,
    isUserAuthorizedToChangeEvidence,
    deleteEvidence,
  ]);
}

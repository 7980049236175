import { Report_Types_Enum } from '@main/graphql/types.generated';
import { TFunction } from 'i18next';

export function getReportTypeOptions(t: TFunction<'translation'>) {
  return Object.values(Report_Types_Enum).map((type) => getReportTypeOption(t, type));
}

export function getReportTypeOption(t: TFunction<'translation'>, type: Report_Types_Enum) {
  return {
    value: type,
    label: t(`reports.type.${type}`),
    colorScheme: 'purple',
  };
}

import { Box, Flex, Stack, Text, useColorModeValue } from '@chakra-ui/react';
import { SidebarMenu } from '@main/ui';
import { Navigate, Outlet, Route } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

import { useOrganizationSettingsMenu } from '../../../features/layout/authenticated-menu';
import { settingsRoute } from '../';

const OrganizationSettingsLayout = () => {
  const { t } = useTranslation();
  const cardBorderColor = useColorModeValue('gray.200', 'gray.700');
  const cardbgColor = useColorModeValue('white', 'gray.800');
  const { menuItems } = useOrganizationSettingsMenu();

  return (
    <Stack spacing={6}>
      <Text fontSize={'3xl'} fontWeight={'semibold'}>
        {t('settings.organization.heading')}
      </Text>
      <Flex
        mb="14"
        bg={cardbgColor}
        borderColor={cardBorderColor}
        borderRadius="lg"
        borderWidth={'1px'}
        direction={{ base: 'column', lg: 'row' }}
        overflowX={'auto'}
      >
        <Box
          minW={{ base: 'full', md: 64 }}
          borderColor={{ base: 'none', lg: cardBorderColor }}
          borderRightWidth={{ base: '0', lg: '1px' }}
          px={3}
          py={6}
        >
          <SidebarMenu items={menuItems} />
        </Box>
        <Flex direction="column" gap="0" justifyContent="flex-start" grow={1}>
          <Outlet />
        </Flex>
      </Flex>
    </Stack>
  );
};

export const organizationSettingsLayoutRoute = new Route({
  getParentRoute: () => settingsRoute,
  path: 'organization',
  component: OrganizationSettingsLayout,
});

const OrganizationSettingsIndex = () => {
  return <Navigate to="/settings/organization/users" replace />;
};

export const organizationSettingsIndexRoute = new Route({
  getParentRoute: () => organizationSettingsLayoutRoute,
  path: '/',
  component: OrganizationSettingsIndex,
});

import { FilterMode } from '@main/shared/url-helpers';
import { Column } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { InlineSelect } from '../../combobox';
import { FilterHelper } from './filter-helper';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FilterModeSelectorProps<TData, TValue, TFilterValue>
  extends FilterHelper<TValue, TFilterValue> {
  column: Column<TData, TValue>;
  options?: { label: string; value: FilterMode }[];
}

export function FilterModeSelector<TData, TValue, TFilterValue>(
  props: FilterModeSelectorProps<TData, TValue, TFilterValue>,
) {
  const { t } = useTranslation('ui');

  const options = useMemo(
    () =>
      props.options ?? [
        { label: t('table.filter.includes'), value: FilterMode.Includes },
        { label: t('table.filter.excludes'), value: FilterMode.Excludes },
      ],
    [props.options, t],
  );
  const filterMode = props.getFilterMode(props.column);

  return (
    <InlineSelect
      options={options}
      value={filterMode}
      onChange={(value) => props.setFilterMode(props.column, value)}
    />
  );
}

import {
  Button,
  ButtonGroup,
  ButtonGroupProps,
  ButtonProps,
  Tab,
  TabList,
  Tabs,
  TabsProps,
} from '@chakra-ui/react';

export const ButtonTabs = (props: TabsProps) => {
  return <Tabs variant="unstyled" {...props} />;
};

export const ButtonTabList = (props: ButtonGroupProps) => {
  return <TabList variant="outline" isAttached {...props} as={ButtonGroup} />;
};

export const ButtonTab = (props: ButtonProps) => {
  return (
    <Tab
      fontSize="sm"
      fontWeight="normal"
      _selected={{
        bg: 'gray.100',
        _dark: {
          bg: 'gray.600',
        },
      }}
      {...props}
      as={Button}
    />
  );
};

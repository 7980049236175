import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Heading,
  Icon,
  Stack,
  Text,
} from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { PlusIcon } from '@heroicons/react/24/outline';
import { useAddRiskMutation } from '@main/graphql/mutations/InsertRisk.generated';
import { useGetRisksQuery } from '@main/graphql/queries/GetRisks.generated';
import { toError } from '@main/shared/utils';
import { errorToast, successToast, useDrawer } from '@main/ui';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { getCurrentUserSelectedOrg, getCurrentUserSelectedOrgRole } from '../user/slice';
import { RisksTable } from './table';

export const RiskRegister = () => {
  const { t } = useTranslation();
  const drawer = useDrawer();

  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canCreateRisk = userRole.permissionMap?.write_risks;
  const organization = useAppSelector(getCurrentUserSelectedOrg);

  const [createRisk, { isLoading: isCreatingRisk }] = useAddRiskMutation();
  const { refetch: refetchRisks } = useGetRisksQuery(
    { organization_id: organization.id },
    { pollingInterval: 5000 },
  );

  const onRiskCreate = async () => {
    try {
      const riskDrawer = await drawer.openLoading({ entity: 'risk' });

      const createRiskResult = await createRisk({
        risk_input: {
          organization_id: organization.id,
        },
      }).unwrap();

      if (!createRiskResult.insert_risks_one?.id) {
        throw new Error('Could not get risk id from response');
      }

      riskDrawer.load({ entityId: createRiskResult.insert_risks_one.id });
      await refetchRisks();
      successToast(t('successMessages.createSucceeded', { entity: t('entities.risk') }));
    } catch (error) {
      drawer.close();
      errorToast(t('errorMessages.createFailed', { entity: t('entities.risk') }));
      datadogLogs.logger.error('Risk create failed', {}, toError(error));
    }
  };

  return (
    <Stack spacing={6}>
      <Text fontSize="3xl" fontWeight={'semibold'}>
        {t('risks.breadcrumbTitle')}
      </Text>
      <Card variant="table-styles">
        <CardHeader>
          <Box>
            <Heading size="md">{t('risks.heading')}</Heading>
            <Text variant="subheading">{t('risks.subheading')}</Text>
          </Box>
          {canCreateRisk && (
            <Button
              leftIcon={
                <Icon color={'white'} _dark={{ color: 'black' }} w={4} h={4} as={PlusIcon} />
              }
              colorScheme="blue"
              variant="solid"
              isLoading={isCreatingRisk}
              onClick={onRiskCreate}
            >
              {t('risks.addButton')}
            </Button>
          )}
        </CardHeader>

        <CardBody>
          <RisksTable />
        </CardBody>
      </Card>
    </Stack>
  );
};

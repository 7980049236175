/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type CreateProgramMutationVariables = Types.Exact<{
  programInput: Types.CreateProgramInput;
}>;

export type CreateProgramMutation = {
  __typename?: 'mutation_root';
  create_program?: { __typename?: 'ProgramOutput'; id: string };
};

export const CreateProgramDocument = `
    mutation CreateProgram($programInput: CreateProgramInput!) {
  create_program(object: $programInput) {
    id
  }
}
    ` as string & TypedDocumentNode<CreateProgramMutation, CreateProgramMutationVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    CreateProgram: build.mutation<CreateProgramMutation, CreateProgramMutationVariables>({
      query: (variables) => ({ document: CreateProgramDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useCreateProgramMutation } = injectedRtkApi;

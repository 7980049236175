import { TrashIcon } from '@heroicons/react/24/outline';
import { useGetQuestionnaireSubscription } from '@main/graphql/subscriptions/GetQuestionnaireSubscription';
import { isNonNullable } from '@main/shared/utils';
import { useDrawer } from '@main/ui';
import { useTranslation } from 'react-i18next';

import { useDeleteQuestionnaire } from './delete-questionnaire';

export const useQuestionnaireDrawerActions = (questionnaireId: string) => {
  const deleteQuestionnaire = useQuestionnaireDeleteAction(questionnaireId);

  return {
    menuActions: [deleteQuestionnaire].filter(isNonNullable),
  };
};

function useQuestionnaireDeleteAction(questionnaireId: string) {
  const { t } = useTranslation();
  const drawer = useDrawer();
  const deleteQuestionnaire = useDeleteQuestionnaire();

  const questionnaireSubscription = useGetQuestionnaireSubscription({
    id: questionnaireId,
  });
  const questionnaire = questionnaireSubscription.data?.questionnaires_by_pk;

  if (!questionnaire || !deleteQuestionnaire) {
    return null;
  }

  return {
    icon: <TrashIcon />,
    label: t('buttons.delete'),
    onClick: () => {
      deleteQuestionnaire(questionnaire, () => drawer.close());
    },
  };
}

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type RemoveControlCriteriaTagMutationVariables = Types.Exact<{
  criteriaId: Types.Scalars['uuid']['input'];
  controlId: Types.Scalars['uuid']['input'];
}>;

export type RemoveControlCriteriaTagMutation = {
  __typename?: 'mutation_root';
  delete_control_criteria_by_pk?: {
    __typename?: 'control_criteria';
    criteria_id: string;
    control_id: string;
  };
};

export const RemoveControlCriteriaTagDocument = `
    mutation RemoveControlCriteriaTag($criteriaId: uuid!, $controlId: uuid!) {
  delete_control_criteria_by_pk(criteria_id: $criteriaId, control_id: $controlId) {
    criteria_id
    control_id
  }
}
    ` as string &
  TypedDocumentNode<RemoveControlCriteriaTagMutation, RemoveControlCriteriaTagMutationVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    RemoveControlCriteriaTag: build.mutation<
      RemoveControlCriteriaTagMutation,
      RemoveControlCriteriaTagMutationVariables
    >({
      query: (variables) => ({ document: RemoveControlCriteriaTagDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useRemoveControlCriteriaTagMutation } = injectedRtkApi;

import { createGqlSubscriptionApi } from '../create-subscription-api';
import {
  api,
  GetOrganizationControlStatusesDocument,
} from '../queries/GetOrganizationControlStatuses.generated';

export const {
  useGetOrganizationControlStatusesQuery: useGetOrganizationControlStatusesSubscription,
} = createGqlSubscriptionApi(
  api,
  'GetOrganizationControlStatuses',
  GetOrganizationControlStatusesDocument,
);

import { useGetRiskClassificationsQuery } from '@main/graphql/features/RiskClassifications.generated';
import { useGetRiskMatrixQuery } from '@main/graphql/features/RiskMatrix.generated';
import { useGetRisksQuery } from '@main/graphql/queries/GetRisks.generated';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { getCurrentUserSelectedOrgId } from '../user/slice';
import { useGetRiskSubscription } from './get-risk.sub';
import { getMappedRisk, getMappedRisks } from './slice';

export const useGetRiskDrawerData = ({ riskId }: { riskId: string }) => {
  const { data, ...props } = useGetRiskSubscription({ risk_id: riskId });
  const risk = useAppSelector((state) => getMappedRisk(state, riskId));

  return { risk, ...props };
};

export const useGetRiskTableData = () => {
  const organizationId = useAppSelector(getCurrentUserSelectedOrgId);
  /* Need matrix to calculate inherent and residual risk levels */
  useGetRiskMatrixQuery({ organizationId });
  /* Need classifications to calculate risk score */
  useGetRiskClassificationsQuery({
    organizationId,
  });

  const { isLoading } = useGetRisksQuery({
    organization_id: organizationId,
  });

  const risks = useAppSelector(getMappedRisks);

  return { risks, isRiskLoading: isLoading };
};

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type AddRiskMutationVariables = Types.Exact<{
  risk_input: Types.Risks_Insert_Input;
}>;

export type AddRiskMutation = {
  __typename?: 'mutation_root';
  insert_risks_one?: { __typename?: 'risks'; id: string };
};

export const AddRiskDocument = `
    mutation AddRisk($risk_input: risks_insert_input!) {
  insert_risks_one(object: $risk_input) {
    id
  }
}
    ` as string & TypedDocumentNode<AddRiskMutation, AddRiskMutationVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    AddRisk: build.mutation<AddRiskMutation, AddRiskMutationVariables>({
      query: (variables) => ({ document: AddRiskDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useAddRiskMutation } = injectedRtkApi;

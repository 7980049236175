import { useDrawer } from '@main/ui';
import { useTranslation } from 'react-i18next';

import { EntityReferenceCard } from '../shared/entity-reference-card';

export type PolicyReferenceCardProps = {
  id: string;
  name?: string;
  onUnlink: () => void;
};

export const PolicyReferenceCard = ({ id, name, onUnlink }: PolicyReferenceCardProps) => {
  const { t } = useTranslation();
  const drawer = useDrawer();

  const onClick = () => {
    drawer.open({ entity: 'policy', entityId: id });
  };

  return (
    <EntityReferenceCard onUnlink={onUnlink}>
      <EntityReferenceCard.Tags
        tags={[
          {
            label: t('entities.policy'),
            color: 'purple',
          },
        ]}
      />
      <EntityReferenceCard.Reference onClick={onClick}>{name}</EntityReferenceCard.Reference>
    </EntityReferenceCard>
  );
};

import { datadogLogs } from '@datadog/browser-logs';
import { useDeleteRiskByIdMutation } from '@main/graphql/mutations/DeleteRiskById.generated';
import { useGetRisksQuery } from '@main/graphql/queries/GetRisks.generated';
import { toError } from '@main/shared/utils';
import { errorToast, successToast, useAlertDialog } from '@main/ui';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { getCurrentUserSelectedOrgId, getCurrentUserSelectedOrgRole } from '../user/slice';

export function useDeleteRisk() {
  const { t } = useTranslation();
  const { openDialog } = useAlertDialog();

  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canDeleteRisk = !!userRole.permissionMap?.write_risks;

  const orgId = useAppSelector(getCurrentUserSelectedOrgId);
  const { refetch: refetchRisks } = useGetRisksQuery({ organization_id: orgId });
  const [deleteRiskById] = useDeleteRiskByIdMutation();

  const deleteRisk = useCallback(
    (riskId: string, onDelete?: () => void) => {
      openDialog({
        dialogHeader: t('risks.alert.delete.header'),
        dialogContent: t('risks.alert.delete.content'),
        confirmAction: {
          children: t('risks.alert.delete.confirm'),
          onClick: async () => {
            try {
              await deleteRiskById({ riskId }).unwrap();
              await refetchRisks();
              successToast(t('successMessages.deleteSucceeded', { entity: t('entities.risk') }));
              onDelete?.();
            } catch (error) {
              errorToast(t('errorMessages.deleteFailed', { entity: t('entities.risk') }));
              datadogLogs.logger.error('Risk delete failed', {}, toError(error));
            }
          },
        },
      });
    },
    [t, openDialog, deleteRiskById, refetchRisks],
  );

  return canDeleteRisk ? deleteRisk : null;
}

import { Control_Frequencies_Enum, Frequencies_Enum } from '@main/graphql/types.generated';
import type { TFunction } from 'i18next';

const CONTROL_FREQUENCY_ORDER = [
  Control_Frequencies_Enum.Daily,
  Control_Frequencies_Enum.Weekly,
  Control_Frequencies_Enum.Monthly,
  Control_Frequencies_Enum.Quarterly,
  Control_Frequencies_Enum.Biannual,
  Control_Frequencies_Enum.Annual,
  Control_Frequencies_Enum.Indefinite,
];

const ORDERED_CONTROL_FREQUENCIES = Object.values(Control_Frequencies_Enum).sort(
  (a, b) => CONTROL_FREQUENCY_ORDER.indexOf(a) - CONTROL_FREQUENCY_ORDER.indexOf(b),
);

export function getControlFrequencyOptions(t: TFunction) {
  return ORDERED_CONTROL_FREQUENCIES.map((frequency) => getControlFrequencyOption(t, frequency));
}

export function getControlFrequencyOption(t: TFunction, frequency: Control_Frequencies_Enum) {
  return {
    value: frequency,
    label: t(`controls.frequency.enums.${frequency}`),
    colorScheme: 'purple',
  };
}

const FREQUENCY_ORDER = [
  Frequencies_Enum.Daily,
  Frequencies_Enum.Weekly,
  Frequencies_Enum.Monthly,
  Frequencies_Enum.Quarterly,
  Frequencies_Enum.Biannual,
  Frequencies_Enum.Annual,
  Frequencies_Enum.TwoYears,
  Frequencies_Enum.ThreeYears,
  Frequencies_Enum.Indefinite,
];

const ORDERED_FREQUENCIES = Object.values(Frequencies_Enum).sort(
  (a, b) => FREQUENCY_ORDER.indexOf(a) - FREQUENCY_ORDER.indexOf(b),
);

export function getFrequencyOptions(t: TFunction) {
  return ORDERED_FREQUENCIES.map((frequency) => getFrequencyOption(t, frequency));
}

export function getFrequencyOption(t: TFunction, frequency: Frequencies_Enum) {
  return {
    value: frequency,
    label: t(`vendors.frequency.${frequency}`),
    colorScheme: 'purple',
  };
}

import { HStack, Icon, IconButton, Tooltip } from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import {
  ArrowDownTrayIcon,
  CheckIcon,
  LinkIcon,
  PencilIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { useMakeEvidenceVersionCurrentMutation } from '@main/graphql/features/MakeEvidenceVersionCurrent.generated';
import { useDeleteEvidenceVersionByIdMutation } from '@main/graphql/mutations/DeleteEvidenceVersionById.generated';
import { useGetEvidenceVersionsListByEvidenceIdQuery } from '@main/graphql/queries/GetEvidenceVersionsListByEvidenceId.generated';
import { isNonNullable, toError } from '@main/shared/utils';
import {
  createColumnHelper,
  errorToast,
  successToast,
  Table,
  useAlertDialog,
  useDownloadStorageFile,
} from '@main/ui';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../hooks/redux-toolkit-hooks';
import { COMPLYANCE_USER } from '../../../utils/constants';
import { getCurrentOrgUsersMap, getCurrentUserSelectedOrgRole } from '../../user/slice';
import { EvidenceVersionListItem } from '../slice';
import { EditEvidenceVersionModal } from './edit-evidence-version';

export interface EvidenceVersionTableProps {
  evidenceId: string;
  data: EvidenceVersionListItem[];
  isCurrent?: boolean;
}

export const EvidenceVersionTable = ({
  evidenceId,
  data,
  isCurrent,
}: EvidenceVersionTableProps) => {
  const { t } = useTranslation();
  const [editedVersionId, setEditedVersionId] = useState<string>('');

  const { isLoading: isVersionLoading, refetch: refetchEvidenceVersions } =
    useGetEvidenceVersionsListByEvidenceIdQuery({ evidenceId });

  const columns = useEvidenceVersionTableColumns({
    evidenceId,
    onVersionEdit: setEditedVersionId,
    isCurrent,
  });
  const tableItemName = useMemo(() => {
    return {
      singular: t(
        isCurrent ? 'entities.current_evidence_version' : 'entities.past_evidence_version',
      ).toLowerCase(),
      plural: t(
        isCurrent
          ? 'entities.plural.current_evidence_versions'
          : 'entities.plural.past_evidence_versions',
      ).toLowerCase(),
      alternateSubheading: true,
    };
  }, [isCurrent, t]);

  return (
    <>
      <Table
        minW={'400px'}
        data={data}
        isLoading={isVersionLoading}
        columns={columns}
        itemName={tableItemName}
      />
      <EditEvidenceVersionModal
        evidenceId={evidenceId || ''}
        evidenceVersionId={editedVersionId}
        isOpen={!!editedVersionId}
        onEdit={() => {
          refetchEvidenceVersions();
          setEditedVersionId('');
        }}
        onClose={() => {
          setEditedVersionId('');
        }}
      />
    </>
  );
};

function useEvidenceVersionTableColumns({
  evidenceId,
  onVersionEdit,
  isCurrent = false,
}: {
  evidenceId: string;
  onVersionEdit: (versionId: string) => void;
  isCurrent?: boolean;
}) {
  const { t } = useTranslation();
  const currentOrgUsers = useAppSelector(getCurrentOrgUsersMap);
  const { openDialog } = useAlertDialog();
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canEditEvidence = userRole.permissionMap?.write_evidence;

  const { refetch: refetchEvidenceVersionList } = useGetEvidenceVersionsListByEvidenceIdQuery({
    evidenceId,
  });

  const [deleteEvidenceVersion] = useDeleteEvidenceVersionByIdMutation();
  const [makeEvidenceVersionCurrent] = useMakeEvidenceVersionCurrentMutation();

  const onChangeCurrentVersion = useCallback(
    async (versionId: string) => {
      try {
        await makeEvidenceVersionCurrent({ evidenceId, versionId }).unwrap();
        await refetchEvidenceVersionList().unwrap();
        successToast(t('evidences.version.markedAsCurrentSuccess'));
      } catch (error) {
        errorToast(t('errorMessages.updateFailed', { entity: t('entities.evidence') }));
        datadogLogs.logger.error(
          'Failed to make evidence version current',
          { evidenceId, versionId },
          toError(error),
        );
      }
    },
    [makeEvidenceVersionCurrent, evidenceId, refetchEvidenceVersionList, t],
  );

  const onDelete = useCallback(
    async (versionId: string) => {
      try {
        await deleteEvidenceVersion({ id: versionId }).unwrap();
        await refetchEvidenceVersionList().unwrap();
      } catch (error) {
        errorToast(t('errorMessages.deleteFailed', { entity: t('entities.evidence') }));
        datadogLogs.logger.error('Evidence delete failed', {}, toError(error));
      }
    },
    [deleteEvidenceVersion, t, refetchEvidenceVersionList],
  );

  const downloadStorageFile = useDownloadStorageFile();

  return useMemo(() => {
    const columnHelper = createColumnHelper<EvidenceVersionListItem>();

    return [
      columnHelper.columns.tag({
        id: 'type',
        header: t('evidences.version.table.columns.type'),
        accessorFn: ({ type }) => {
          const value = type;
          if (!value) {
            return;
          }

          return {
            value: t(`evidences.version.type.${value}`),
            colorScheme: 'purple',
          };
        },
        size: 110,
      }),
      columnHelper.columns.text({
        id: 'name',
        variant: 'link',
        header: t('evidences.version.table.columns.name'),
        accessorFn: ({ name }) => name,
      }),

      columnHelper.columns.text({
        id: 'validity_start',
        header: t('evidences.version.table.columns.validityStart'),
        accessorFn: ({ validityStart }) => validityStart,
        size: 150,
        meta: {
          cell: {
            fontSize: 'xs',
            color: 'gray.500',
          },
        },
      }),

      columnHelper.columns.avatar({
        id: 'owner',
        header: t('evidences.version.table.columns.owner'),
        accessorFn: ({ createdBy }) => {
          if (!createdBy) {
            return COMPLYANCE_USER;
          }

          const user = currentOrgUsers[createdBy];
          if (!user) {
            return;
          }

          return {
            id: user.id,
            displayName: user.displayName,
          };
        },
        size: 70,
      }),

      columnHelper.columns.actions({
        id: 'actions',
        size: 100,
        PrimaryAction: ({ row }) => {
          const versionFileId = row.original.fileId;
          const versionURL = row.original.url;

          return (
            <HStack spacing={2}>
              {canEditEvidence && (
                <IconButton
                  minW={4}
                  aria-label={'Edit'}
                  variant="link"
                  icon={<PencilIcon />}
                  onClick={() => onVersionEdit(row.original.id)}
                />
              )}

              {versionFileId && (
                <IconButton
                  minW={4}
                  aria-label={'Download'}
                  variant="link"
                  icon={<ArrowDownTrayIcon />}
                  onClick={() => downloadStorageFile(versionFileId)}
                />
              )}
              {versionURL && (
                <Tooltip label={t('evidences.tooltip.copyLink')}>
                  <IconButton
                    onClick={() => {
                      navigator.clipboard.writeText(versionURL);
                    }}
                    minW={4}
                    aria-label={'Link'}
                    variant="link"
                    icon={<LinkIcon />}
                  />
                </Tooltip>
              )}
            </HStack>
          );
        },

        ...(canEditEvidence && !isCurrent
          ? {
              secondaryActions: ({ row }) =>
                [
                  {
                    icon: <Icon as={CheckIcon} />,
                    label: t('evidences.version.markAsCurrent'),
                    onClick: () =>
                      openDialog({
                        dialogHeader: t('evidences.version.changeCurrentVertionAlert.header'),
                        dialogContent: t('evidences.version.changeCurrentVertionAlert.content'),
                        confirmAction: {
                          children: t('evidences.version.changeCurrentVertionAlert.replace'),
                          onClick: () => onChangeCurrentVersion(row.original.id),
                        },
                      }),
                  },
                  {
                    icon: <Icon as={TrashIcon} />,
                    label: t('buttons.delete'),
                    onClick: () =>
                      openDialog({
                        dialogHeader: t('evidences.version.deleteAlert.header'),
                        dialogContent: t('evidences.version.deleteAlert.content'),
                        confirmAction: {
                          children: t('buttons.delete'),
                          onClick: () => onDelete(row.original.id),
                        },
                      }),
                  },
                ].filter(isNonNullable),
            }
          : {}),
      }),
    ];
  }, [
    t,
    canEditEvidence,
    isCurrent,
    currentOrgUsers,
    onVersionEdit,
    downloadStorageFile,
    openDialog,
    onChangeCurrentVersion,
    onDelete,
  ]);
}

import i18n from 'i18next';
/**
 * All updates / overrides / hacks in this file are valid for the duration
 * of PwC sandbox demo and should be removed after it is complete.
 */

const sandboxOrgIds =
  import.meta.env.VITE_PWC_SANDBOX_ORG_IDS?.split(',').map((orgId) => orgId.trim()) || [];

/**
 * Usage of feature flags to setup translation overrides is problematic
 * due to the feature flag resolution delay and tanstack table memoizing
 * rendered table cell values.
 *
 * Falling back to applying overrides with predefined org ids from env.
 */
export function updateSandboxTranslations(orgId: string) {
  if (!sandboxOrgIds.includes(orgId)) return;

  i18n.addResourceBundle(
    'en-US',
    'translation',
    {
      controls: {
        status: {
          enums: {
            Valid: 'Current',
            'At risk': 'Expiring',
            Failing: 'Expired',
          },
        },
      },
      permissions: {
        names: {
          notify_on_control_at_risk:
            'Receive “Expiring” and “Expired” notifications for all controls',
        },
      },
      notifications: {
        names: {
          control_status_changed_to_not_valid: '“Expiring” and “Expired” controls',
        },
      },
      notification: {
        empty: {
          subheading:
            "You'll be notified here for expiring controls, new mentions, requests and tasks assigned to you.",
        },
      },
    },
    true,
    true,
  );
  i18n.changeLanguage(i18n.language);
}

import {
  Button,
  Card,
  FormControl,
  FormLabel,
  Highlight,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Tag,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { PlusIcon, UserIcon } from '@heroicons/react/24/outline';
import { useChangeEvidenceOwnerMutation } from '@main/graphql/features/ChangeEvidenceOwner.generated';
import { useUpdateEvidenceByIdMutation } from '@main/graphql/mutations/UpdateEvidenceById.generated';
import { useGetEvidenceByIdQuery } from '@main/graphql/queries/GetEvidenceById.generated';
import { useGetEvidenceVersionsListByEvidenceIdQuery } from '@main/graphql/queries/GetEvidenceVersionsListByEvidenceId.generated';
import { isNonNullable } from '@main/shared/utils';
import { AvatarOption, DrawerProperty, EditableAutoResizeTextarea, EditableAvatar } from '@main/ui';
import { BarsIcon, DoubleTagsIcon } from '@main/ui/icons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { COMPLYANCE_USER } from '../../utils/constants';
import { getOrgProgramsMap } from '../program/slice';
import { getAssigneeOptions } from '../shared/get-assignee-options';
import { getCurrentOrgNonDisabledUsers } from '../user/slice';
import { ConfidentialBanner } from './confidential-banner';
import { EvidenceReferences } from './evidence-references';
import { CreateEvidenceVersionModal, EvidenceVersionTable } from './evidence-versions';
import { getMappedEvidenceVersions, mapAssociatedPrograms } from './slice';
import { EvidenceTags } from './tags';
import { useUpdateEvidenceOnDrawer } from './use-update-evidence-handler';
import {
  useIsUserAuthorizedToChangeEvidence,
  useIsUserAuthorizedToChangeOwner,
  useIsUserAuthorizedToViewVersions,
} from './utils';

export const EvidenceDetailsTab = ({ evidenceId }: { evidenceId: string }) => {
  const { t } = useTranslation();
  const textColor = useColorModeValue('gray.600', 'gray.500');
  const programPlaceholderColor = useColorModeValue('gray.400', 'gray.500');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [owner, setOwner] = useState<AvatarOption>();

  const orgProgramsMap = useAppSelector(getOrgProgramsMap);
  const { evidence, refetch: refetchEvidence } = useGetEvidenceByIdQuery(
    { evidence_id: evidenceId },
    {
      selectFromResult: ({ data }) => {
        const evidence = data?.evidences_by_pk;
        if (!evidence) {
          return {};
        }
        const programs = mapAssociatedPrograms(evidence, orgProgramsMap) || [];
        return {
          evidence: {
            ...evidence,
            programs,
          },
        };
      },
    },
  );
  const [changeEvidenceOwner, { isLoading: isUpdatingEvidence }] = useChangeEvidenceOwnerMutation();
  const isUserAuthorizedToChangeEvidence = useIsUserAuthorizedToChangeEvidence(evidence);
  const isUserAuthorizedToViewVersions = useIsUserAuthorizedToViewVersions(evidence);
  const isUserAuthorizedToChangeOwner = useIsUserAuthorizedToChangeOwner(evidence);
  const currentOrgNonDisabledUsers = useAppSelector(getCurrentOrgNonDisabledUsers);

  const [updateEvidenceHandler] = useUpdateEvidenceOnDrawer({ evidenceId });
  const [updateEvidence] = useUpdateEvidenceByIdMutation();

  const {
    isOpen: isEvidenceVersionModalOpen,
    onOpen: onEvidenceVersionModal,
    onClose: onEvidenceVersionModalClose,
  } = useDisclosure();
  const { refetch: refetchEvidenceVersions } = useGetEvidenceVersionsListByEvidenceIdQuery({
    evidenceId,
  });
  const { currentVersion } = useAppSelector((state) =>
    getMappedEvidenceVersions(state, evidenceId),
  );
  const versions = [currentVersion].filter(isNonNullable);
  const getEvidenceOwner = () => {
    if (!evidence?.owner?.id) {
      return COMPLYANCE_USER;
    }

    return {
      id: evidence.owner.id,
      displayName: evidence.owner?.displayName,
    };
  };

  const ownerNameColor = useColorModeValue('black', 'white');
  return (
    <>
      <Stack spacing={[6, 3]}>
        <DrawerProperty isReadOnly={!isUserAuthorizedToChangeEvidence}>
          <DrawerProperty.Label icon={BarsIcon}>{t('evidences.description')}</DrawerProperty.Label>
          <DrawerProperty.Content>
            <EditableAutoResizeTextarea
              defaultValue={evidence?.description}
              placeholder={t('evidences.placeholder.description')}
              color={textColor}
              onSubmit={(value) => {
                if (value === evidence?.description) {
                  return;
                }
                updateEvidenceHandler(
                  updateEvidence({
                    id: evidenceId,
                    updatePayload: {
                      description: value,
                    },
                  }),
                );
              }}
            />
          </DrawerProperty.Content>
        </DrawerProperty>

        <DrawerProperty isReadOnly={!isUserAuthorizedToChangeOwner}>
          <DrawerProperty.Label icon={UserIcon}>{t('evidences.owner')}</DrawerProperty.Label>
          <DrawerProperty.Content>
            <EditableAvatar
              isClearable={false}
              options={getAssigneeOptions(currentOrgNonDisabledUsers)}
              value={getEvidenceOwner()}
              onChange={(option) => {
                if (!option) {
                  return;
                }

                setOwner(option);
                onOpen();
              }}
            />
          </DrawerProperty.Content>
        </DrawerProperty>

        <DrawerProperty isReadOnly={!isUserAuthorizedToChangeEvidence}>
          <DrawerProperty.Label icon={DoubleTagsIcon}>{t('evidences.tags')}</DrawerProperty.Label>
          <DrawerProperty.Content>
            <EvidenceTags evidenceId={evidenceId} />
          </DrawerProperty.Content>
        </DrawerProperty>

        <DrawerProperty>
          <DrawerProperty.Label icon={DoubleTagsIcon}>
            {t('evidences.table.columns.programs')}
          </DrawerProperty.Label>
          <DrawerProperty.Content
            px={2}
            display={'flex'}
            alignItems={'center'}
            gap={2}
            flexWrap="wrap"
          >
            {!evidence?.programs.length ? (
              <Text fontSize="xs" textColor={programPlaceholderColor}>
                {t('evidences.noAssosiatedPrograms')}
              </Text>
            ) : (
              evidence.programs.map((program) => (
                <Tag key={program.id} colorScheme={program.colorScheme}>
                  {program.name}
                </Tag>
              ))
            )}
          </DrawerProperty.Content>
        </DrawerProperty>
      </Stack>
      <FormControl>
        {isUserAuthorizedToViewVersions ? (
          <>
            <FormLabel>{t('evidences.version.currentVersion')}</FormLabel>
            <Card variant={'table-styles'}>
              <EvidenceVersionTable evidenceId={evidenceId} data={versions} isCurrent />
            </Card>
          </>
        ) : (
          <ConfidentialBanner />
        )}
      </FormControl>

      {isUserAuthorizedToChangeEvidence && (
        <Button
          leftIcon={<Icon color={'white'} _dark={{ color: 'black' }} w={5} h={5} as={PlusIcon} />}
          colorScheme="blue"
          variant="solid"
          onClick={onEvidenceVersionModal}
          maxW={'fit-content'}
          alignSelf={'end'}
        >
          {currentVersion
            ? t('evidences.buttons.replaceEvidence')
            : t('evidences.buttons.addNewVersion')}
        </Button>
      )}

      <EvidenceReferences evidenceId={evidenceId} />

      <Modal
        size="xl"
        isCentered
        autoFocus={false}
        isOpen={isOpen}
        onClose={onClose}
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize="lg"> {t('evidences.alert.updateOwner.header')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Highlight
              query={owner?.displayName || ''}
              styles={{ fontWeight: 'semibold', color: ownerNameColor }}
            >
              {t('evidences.alert.updateOwner.content', { owner: owner?.displayName })}
            </Highlight>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="gray" mr={3} onClick={onClose}>
              {t('buttons.cancel')}
            </Button>
            <Button
              isLoading={isUpdatingEvidence}
              colorScheme="red"
              onClick={() => {
                owner?.id &&
                  updateEvidenceHandler(
                    changeEvidenceOwner({
                      evidenceId,
                      newOwnerId: owner.id,
                    }),
                  );
                onClose();
              }}
            >
              {t('evidences.alert.updateOwner.confirm')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <CreateEvidenceVersionModal
        evidenceId={evidenceId}
        isOpen={isEvidenceVersionModalOpen}
        onCreate={() => {
          refetchEvidenceVersions();
          refetchEvidence();
          onEvidenceVersionModalClose();
        }}
        onClose={onEvidenceVersionModalClose}
      />
    </>
  );
};

import { NhostClient } from '@nhost/nhost-js';

import { StreamDownloader } from './stream-downloader';
import { FetchStreamDownloader } from './stream-downloader-fetch';

export interface NhostFile {
  fileId: string;
}

export class NhostStreamDownloader implements StreamDownloader<NhostFile> {
  constructor(
    protected readonly nhost: NhostClient,
    protected readonly fetchDownloader = new FetchStreamDownloader(),
  ) {}

  async downloadFile(file: NhostFile, options?: { signal: AbortSignal }): Promise<ReadableStream> {
    const { error, presignedUrl } = await this.nhost.storage.getPresignedUrl({
      fileId: file.fileId,
    });

    if (error) {
      throw new Error(`Failed getting presigned URL for file ID ${file.fileId}: ${error.message}`);
    }

    return this.fetchDownloader.downloadFile(presignedUrl.url, options);
  }
}

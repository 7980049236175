/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type ExportPolicyMutationVariables = Types.Exact<{
  input: Types.ExportPolicyInput;
}>;

export type ExportPolicyMutation = {
  __typename?: 'mutation_root';
  export_policy?: { __typename?: 'ExportPolicyOutput'; fileId: string };
};

export const ExportPolicyDocument = `
    mutation ExportPolicy($input: ExportPolicyInput!) {
  export_policy(input: $input) {
    fileId
  }
}
    ` as string & TypedDocumentNode<ExportPolicyMutation, ExportPolicyMutationVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    ExportPolicy: build.mutation<ExportPolicyMutation, ExportPolicyMutationVariables>({
      query: (variables) => ({ document: ExportPolicyDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useExportPolicyMutation } = injectedRtkApi;

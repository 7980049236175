/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type ComplianceHealthGetControlsStatsQueryVariables = Types.Exact<{
  from: Types.Scalars['timestamptz']['input'];
  organizationId: Types.Scalars['uuid']['input'];
}>;

export type ComplianceHealthGetControlsStatsQuery = {
  __typename?: 'query_root';
  daily_control_stats_view: Array<{
    __typename?: 'daily_control_stats_view';
    created_at?: string;
    total_valid_count?: number;
    total_at_risk_count?: number;
    total_failing_count?: number;
    total_pending_count?: number;
  }>;
};

export const ComplianceHealthGetControlsStatsDocument = `
    query ComplianceHealthGetControlsStats($from: timestamptz!, $organizationId: uuid!) {
  daily_control_stats_view(
    where: {created_at: {_gte: $from}, organization_id: {_eq: $organizationId}}
    order_by: {created_at: asc}
  ) {
    created_at
    total_valid_count
    total_at_risk_count
    total_failing_count
    total_pending_count
  }
}
    ` as string &
  TypedDocumentNode<
    ComplianceHealthGetControlsStatsQuery,
    ComplianceHealthGetControlsStatsQueryVariables
  >;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    ComplianceHealthGetControlsStats: build.query<
      ComplianceHealthGetControlsStatsQuery,
      ComplianceHealthGetControlsStatsQueryVariables
    >({
      query: (variables) => ({ document: ComplianceHealthGetControlsStatsDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useComplianceHealthGetControlsStatsQuery,
  useLazyComplianceHealthGetControlsStatsQuery,
} = injectedRtkApi;

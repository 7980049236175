/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type GetRiskCategoriesQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['uuid']['input'];
  nameFilter?: Types.InputMaybe<Types.String_Comparison_Exp>;
}>;

export type GetRiskCategoriesQuery = {
  __typename?: 'query_root';
  organization_risk_categories: Array<{
    __typename?: 'organization_risk_categories';
    id: string;
    name: string;
  }>;
};

export type GetAllCategoriesQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['uuid']['input'];
}>;

export type GetAllCategoriesQuery = {
  __typename?: 'query_root';
  organization_risk_categories: Array<{
    __typename?: 'organization_risk_categories';
    id: string;
    name: string;
  }>;
};

export type CreateRiskCategoryMutationVariables = Types.Exact<{
  object: Types.Risk_Categories_Insert_Input;
}>;

export type CreateRiskCategoryMutation = {
  __typename?: 'mutation_root';
  insert_risk_categories_one?: { __typename?: 'risk_categories'; category_id: string };
};

export type AddRiskCategoryMutationVariables = Types.Exact<{
  categoryId: Types.Scalars['uuid']['input'];
  riskId: Types.Scalars['uuid']['input'];
}>;

export type AddRiskCategoryMutation = {
  __typename?: 'mutation_root';
  insert_risk_categories_one?: {
    __typename?: 'risk_categories';
    category_id: string;
    risk_id: string;
  };
};

export type RemoveRiskCategoryMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
}>;

export type RemoveRiskCategoryMutation = {
  __typename?: 'mutation_root';
  delete_risk_categories_by_pk?: { __typename?: 'risk_categories'; category_id: string };
};

export const GetRiskCategoriesDocument = `
    query GetRiskCategories($organizationId: uuid!, $nameFilter: String_comparison_exp = {}) {
  organization_risk_categories(
    order_by: {name: asc}
    where: {organization_id: {_eq: $organizationId}, name: $nameFilter}
  ) {
    id
    name
  }
}
    ` as string & TypedDocumentNode<GetRiskCategoriesQuery, GetRiskCategoriesQueryVariables>;
export const GetAllCategoriesDocument = `
    query GetAllCategories($organizationId: uuid!) {
  organization_risk_categories(where: {organization_id: {_eq: $organizationId}}) {
    id
    name
  }
}
    ` as string & TypedDocumentNode<GetAllCategoriesQuery, GetAllCategoriesQueryVariables>;
export const CreateRiskCategoryDocument = `
    mutation CreateRiskCategory($object: risk_categories_insert_input!) {
  insert_risk_categories_one(object: $object) {
    category_id
  }
}
    ` as string &
  TypedDocumentNode<CreateRiskCategoryMutation, CreateRiskCategoryMutationVariables>;
export const AddRiskCategoryDocument = `
    mutation AddRiskCategory($categoryId: uuid!, $riskId: uuid!) {
  insert_risk_categories_one(object: {category_id: $categoryId, risk_id: $riskId}) {
    category_id
    risk_id
  }
}
    ` as string & TypedDocumentNode<AddRiskCategoryMutation, AddRiskCategoryMutationVariables>;
export const RemoveRiskCategoryDocument = `
    mutation RemoveRiskCategory($id: uuid!) {
  delete_risk_categories_by_pk(id: $id) {
    category_id
  }
}
    ` as string &
  TypedDocumentNode<RemoveRiskCategoryMutation, RemoveRiskCategoryMutationVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetRiskCategories: build.query<GetRiskCategoriesQuery, GetRiskCategoriesQueryVariables>({
      query: (variables) => ({ document: GetRiskCategoriesDocument, variables }),
    }),
    GetAllCategories: build.query<GetAllCategoriesQuery, GetAllCategoriesQueryVariables>({
      query: (variables) => ({ document: GetAllCategoriesDocument, variables }),
    }),
    CreateRiskCategory: build.mutation<
      CreateRiskCategoryMutation,
      CreateRiskCategoryMutationVariables
    >({
      query: (variables) => ({ document: CreateRiskCategoryDocument, variables }),
    }),
    AddRiskCategory: build.mutation<AddRiskCategoryMutation, AddRiskCategoryMutationVariables>({
      query: (variables) => ({ document: AddRiskCategoryDocument, variables }),
    }),
    RemoveRiskCategory: build.mutation<
      RemoveRiskCategoryMutation,
      RemoveRiskCategoryMutationVariables
    >({
      query: (variables) => ({ document: RemoveRiskCategoryDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useGetRiskCategoriesQuery,
  useLazyGetRiskCategoriesQuery,
  useGetAllCategoriesQuery,
  useLazyGetAllCategoriesQuery,
  useCreateRiskCategoryMutation,
  useAddRiskCategoryMutation,
  useRemoveRiskCategoryMutation,
} = injectedRtkApi;

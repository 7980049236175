import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { EditorState, LexicalEditor } from 'lexical';
import { useEffect, useMemo } from 'react';

export interface ControlledLexicalPluginProps<T = EditorState> {
  value: T;
  updater?: (value: T, editor: LexicalEditor) => void;
}

export function ControlledLexicalPlugin<T = EditorState>({
  value,
  updater,
}: ControlledLexicalPluginProps<T>) {
  const [editor] = useLexicalComposerContext();
  const doUpdate = useMemo(
    () => updater ?? ((value: T) => editor.setEditorState(value as EditorState)),
    [editor, updater],
  );

  useEffect(() => {
    value !== undefined && editor.update(() => doUpdate(value, editor));
  }, [doUpdate, editor, value]);

  return null;
}

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type UpdateQuestionnaireMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
  updatePayload?: Types.InputMaybe<Types.Questionnaires_Set_Input>;
}>;

export type UpdateQuestionnaireMutation = {
  __typename?: 'mutation_root';
  update_questionnaires_by_pk?: { __typename?: 'questionnaires'; id: string };
};

export const UpdateQuestionnaireDocument = `
    mutation UpdateQuestionnaire($id: uuid!, $updatePayload: questionnaires_set_input = {}) {
  update_questionnaires_by_pk(pk_columns: {id: $id}, _set: $updatePayload) {
    id
  }
}
    ` as string &
  TypedDocumentNode<UpdateQuestionnaireMutation, UpdateQuestionnaireMutationVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    UpdateQuestionnaire: build.mutation<
      UpdateQuestionnaireMutation,
      UpdateQuestionnaireMutationVariables
    >({
      query: (variables) => ({ document: UpdateQuestionnaireDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useUpdateQuestionnaireMutation } = injectedRtkApi;

import {
  ClipboardDocumentCheckIcon,
  CubeIcon,
  FingerPrintIcon,
  ShieldCheckIcon,
  Square2StackIcon,
  TableCellsIcon,
} from '@heroicons/react/24/outline';
import { SearchEntitiesEnum } from '@main/graphql/types.generated';
import { UnionToTuple } from '@main/shared/types';
import { AppDrawerType } from '@main/shared/utils';
import { ElementType } from 'react';

export const entityToCategoryMap: Record<SearchEntitiesEnum, string> = {
  [SearchEntitiesEnum.Controls]: 'Control',
  [SearchEntitiesEnum.Evidences]: 'Evidence',
  [SearchEntitiesEnum.Risks]: 'Risk',
  [SearchEntitiesEnum.Tasks]: 'Task',
  [SearchEntitiesEnum.Vendors]: 'Vendor',
  [SearchEntitiesEnum.Policies]: 'Policy',
};

export function entityToCategory(entity?: string): string {
  return entityToCategoryMap[entity as SearchEntitiesEnum] ?? entity;
}

export const categoryToEntityMap: Record<string, SearchEntitiesEnum> = Object.fromEntries(
  Object.entries(entityToCategoryMap).map(([k, v]) => [v, k as SearchEntitiesEnum]),
);

export function categoryToEntity(category: string): SearchEntitiesEnum | undefined {
  return categoryToEntityMap[category];
}

export const entityToDrawerMap: Record<
  SearchEntitiesEnum,
  NonNullable<AppDrawerType['drawerEntity']>
> = {
  [SearchEntitiesEnum.Controls]: 'control',
  [SearchEntitiesEnum.Evidences]: 'evidence',
  [SearchEntitiesEnum.Risks]: 'risk',
  [SearchEntitiesEnum.Tasks]: 'task',
  [SearchEntitiesEnum.Vendors]: 'vendor',
  [SearchEntitiesEnum.Policies]: 'policy',
};

export function entityToDrawer(
  entity: SearchEntitiesEnum,
): NonNullable<AppDrawerType['drawerEntity']> {
  return entityToDrawerMap[entity];
}

const entityIcons: UnionToTuple<
  {
    [K in SearchEntitiesEnum]: [K, ElementType];
  }[SearchEntitiesEnum]
> = [
  [SearchEntitiesEnum.Controls, Square2StackIcon],
  [SearchEntitiesEnum.Evidences, FingerPrintIcon],
  [SearchEntitiesEnum.Policies, ShieldCheckIcon],
  [SearchEntitiesEnum.Risks, TableCellsIcon],
  [SearchEntitiesEnum.Tasks, ClipboardDocumentCheckIcon],
  [SearchEntitiesEnum.Vendors, CubeIcon],
];

export const categoryIcons = Object.fromEntries(
  entityIcons.map(([entity, icon]) => [entityToCategory(entity), icon]),
);

import { createGqlSubscriptionApi } from '../create-subscription-api';
import {
  api as getOrganizationControlsOnAlertApi,
  GetOrganizationControlsOnAlertDocument,
} from '../queries/GetOrganizationControlsOnAlert.generated';

export const api = createGqlSubscriptionApi(
  getOrganizationControlsOnAlertApi,
  'GetOrganizationControlsOnAlert',
  GetOrganizationControlsOnAlertDocument,
);

export const { useGetOrganizationControlsOnAlertQuery: useAlertsSubscription } = api;

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type RemovePermissionFromRoleMutationVariables = Types.Exact<{
  permissionId: Types.Scalars['uuid']['input'];
}>;

export type RemovePermissionFromRoleMutation = {
  __typename?: 'mutation_root';
  delete_role_permissions_by_pk?: {
    __typename?: 'role_permissions';
    permission: Types.Permissions_Enum;
  };
};

export const RemovePermissionFromRoleDocument = `
    mutation RemovePermissionFromRole($permissionId: uuid!) {
  delete_role_permissions_by_pk(id: $permissionId) {
    permission
  }
}
    ` as string &
  TypedDocumentNode<RemovePermissionFromRoleMutation, RemovePermissionFromRoleMutationVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    RemovePermissionFromRole: build.mutation<
      RemovePermissionFromRoleMutation,
      RemovePermissionFromRoleMutationVariables
    >({
      query: (variables) => ({ document: RemovePermissionFromRoleDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useRemovePermissionFromRoleMutation } = injectedRtkApi;

import { Outlet, Route } from '@tanstack/react-router';

import { organizationSettingsLayoutRoute } from '../layout';

const RolesLayout = () => {
  return <Outlet />;
};

export const organizationRolesSettingsLayoutRoute = new Route({
  getParentRoute: () => organizationSettingsLayoutRoute,
  path: 'roles',
  component: RolesLayout,
});

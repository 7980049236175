import { ZipWriter } from '@zip.js/zip.js';

import { BulkDownloaderLogger } from '../logger';
import { MergedAbortController } from '../merge-abort-controller';
import { StreamSink } from '../stream-sink';
import { LoggerSink } from './logger';
import type {
  ServiceWorkerStreamPortalSource,
  ServiceWorkerStreamSinkData,
  ServiceWorkerStreamSinkResult,
} from './service-worker';

export type { ServiceWorkerStreamPortalSource };

export class ServiceWorkerStreamSink implements StreamSink {
  protected readonly id = this.generateId();
  protected readonly fileName: string;
  protected readonly zipStream = new TransformStream();
  protected readonly zipWriter = new ZipWriter(this.zipStream.writable);
  protected readonly abortController = new AbortController();
  protected readonly swLoggerSink = new LoggerSink(navigator.serviceWorker, this.logger);

  constructor(
    fileName: string,
    protected readonly serviceWorker: ServiceWorker,
    protected readonly swStreamPortal: ServiceWorkerStreamPortalSource,
    protected readonly logger: BulkDownloaderLogger = console,
  ) {
    this.fileName = `${fileName}.zip`;

    navigator.serviceWorker.addEventListener('message', this.handleSWMessage.bind(this), {
      signal: this.abortController.signal,
    });

    this.logger.debug('ServiceWorkerStreamSink Sending stream to SW', {
      id: this.id,
      fileName: this.fileName,
    });
    this.swStreamPortal
      .send(this.zipStream.readable, { id: this.id, fileName: this.fileName })
      .catch((e) => this.abort(e));
  }

  async streamFile(
    stream: ReadableStream,
    fileName: string,
    options?: { signal?: AbortSignal },
  ): Promise<void> {
    if (this.abortController.signal.aborted) {
      throw this.abortController.signal.reason;
    }
    if (options?.signal?.aborted) {
      throw options.signal.reason;
    }

    const localAbortController = new MergedAbortController(this.abortController.signal);

    if (options?.signal) {
      localAbortController.merge(options.signal);
    }

    const filePath = fileName.replace(/[\\:*?"<>|]/g, '_');

    this.logger.debug('ServiceWorkerStreamSink Zipping file', { id: this.id, filePath });
    await this.zipWriter.add(filePath, stream, { signal: localAbortController.signal });
    this.logger.debug('ServiceWorkerStreamSink Zipped file', { id: this.id, filePath });

    localAbortController.abort(new Error('StreamZipped'));
  }

  async finalize() {
    try {
      this.logger.debug('ServiceWorkerStreamSink Finalizing', { id: this.id });
      await this.zipWriter.close();
    } finally {
      await this.abort('Finalized');
    }
  }

  async dispose() {
    try {
      this.logger.debug('ServiceWorkerStreamSink Disposing', { id: this.id });
      await this.zipWriter.close();
      await this.abort('Disposed');
    } catch {
      await this.abort('Disposed');
    }
  }

  async abort(reason?: unknown) {
    if (this.abortController.signal.aborted) {
      return;
    }

    this.logger.debug('ServiceWorkerStreamSink Aborting', { id: this.id, reason });

    try {
      await this.swStreamPortal.dispose?.();
    } finally {
      this.sendSWMessage({ type: 'stream-sink', id: this.id, done: true });
      this.abortController.abort(reason);
      // Wait for SW to flush final logs
      setTimeout(() => this.swLoggerSink.dispose(), 100);
    }
  }

  protected sendSWMessage(data: ServiceWorkerStreamSinkData) {
    this.serviceWorker.postMessage(data);
  }

  protected handleSWMessage(e: MessageEvent<unknown>) {
    const data = e.data as ServiceWorkerStreamSinkResult;

    if (!data || data.type !== 'stream-sink' || data.id !== this.id) {
      return;
    }

    this.logger.debug('ServiceWorkerStreamSink Opening download', { id: this.id, url: data.url });
    window.open(data.url, '_blank');
  }

  protected generateId() {
    return `${Math.round(Math.random() * 1000000)}${Date.now()}`;
  }
}

export interface Sortable<T = unknown> {
  order?: T;
}

export function createSorter(direction: 'asc' | 'desc' = 'asc') {
  const sortDirAsc = direction === 'asc' ? 1 : -1;
  const sortDirDesc = sortDirAsc * -1;

  return (a: Sortable, b: Sortable) => {
    if (a.order === b.order || !a.order || !b.order) {
      return 0;
    }

    return a.order > b.order ? sortDirAsc : sortDirDesc;
  };
}

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type GenerateWorkosAdminLinkMutationVariables = Types.Exact<{
  orgId: Types.Scalars['String']['input'];
  intent: Types.WorkosAdminPortalIntent;
}>;

export type GenerateWorkosAdminLinkMutation = {
  __typename?: 'mutation_root';
  generate_workos_admin_link?: { __typename?: 'GenerateWorkosAdminLinkOutput'; link?: string };
};

export const GenerateWorkosAdminLinkDocument = `
    mutation GenerateWorkosAdminLink($orgId: String!, $intent: WorkosAdminPortalIntent!) {
  generate_workos_admin_link(object: {orgId: $orgId, intent: $intent}) {
    link
  }
}
    ` as string &
  TypedDocumentNode<GenerateWorkosAdminLinkMutation, GenerateWorkosAdminLinkMutationVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GenerateWorkosAdminLink: build.mutation<
      GenerateWorkosAdminLinkMutation,
      GenerateWorkosAdminLinkMutationVariables
    >({
      query: (variables) => ({ document: GenerateWorkosAdminLinkDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGenerateWorkosAdminLinkMutation } = injectedRtkApi;

import { BulkDownloaderLogger } from '../logger';
import type { MessagableTarget } from '../stream-portal';

interface LoggerMessage {
  type: 'logger-message';
}

interface Log {
  level: LogLevel;
  args: unknown[];
  timestamp: number;
}

enum LogLevel {
  log,
  debug,
  error,
}

export class LoggerSource implements BulkDownloaderLogger {
  static BUFFER_TIME = 10;

  protected readonly channel;
  protected readonly logsQueue: Log[] = [];
  protected timer: ReturnType<typeof setTimeout> | undefined;

  constructor(
    protected readonly target: MessagableTarget,
    protected readonly mirrorLogger: BulkDownloaderLogger = console,
    protected readonly bufferTime = LoggerSource.BUFFER_TIME,
  ) {
    const channel = new MessageChannel();
    this.channel = channel.port1;
    this.channel.start();
    this.target.postMessage({ type: 'logger-message' } as LoggerMessage, [channel.port2]);
  }

  log(...args: unknown[]): void {
    this.mirrorLogger.log(...args);
    this.scheduleLog({ level: LogLevel.log, args, timestamp: Date.now() });
  }

  debug(...args: unknown[]): void {
    this.mirrorLogger.debug(...args);
    this.scheduleLog({ level: LogLevel.debug, args, timestamp: Date.now() });
  }

  error(...args: unknown[]): void {
    this.mirrorLogger.error(...args);
    this.scheduleLog({ level: LogLevel.error, args, timestamp: Date.now() });
  }

  dispose() {
    clearTimeout(this.timer);
    this.channel.close();
  }

  protected scheduleLog(log: Log) {
    this.logsQueue.push(log);
    clearTimeout(this.timer);
    this.timer = setTimeout(() => this.flushLogs(), this.bufferTime);
  }

  protected flushLogs() {
    const logs = this.logsQueue.splice(0, this.logsQueue.length);
    this.channel.postMessage(logs);
  }
}

export class LoggerSink {
  protected readonly disposeController = new AbortController();

  constructor(
    protected readonly target: EventTarget,
    protected readonly logger: BulkDownloaderLogger,
  ) {
    this.target.addEventListener('message', (e) => this.handleMessage(e as MessageEvent), {
      once: true,
      signal: this.disposeController.signal,
    });
  }

  dispose() {
    this.disposeController.abort();
  }

  protected handleMessage(event: MessageEvent) {
    const data = event.data as LoggerMessage;

    if (!data || data.type !== 'logger-message') {
      return;
    }

    const port = event.ports[0];

    if (!port) {
      throw new Error('LoggerSink: No port in message!');
    }

    port.addEventListener('message', (e) => this.handleLogs(e.data as Log[]), {
      signal: this.disposeController.signal,
    });
    port.start();
    this.disposeController.signal.addEventListener('abort', () => port.close(), { once: true });
  }

  protected handleLogs(logs: Log[]) {
    for (const log of logs) {
      switch (log.level) {
        case LogLevel.log:
          this.logger.log(...log.args);
          break;
        case LogLevel.debug:
          this.logger.debug(...log.args);
          break;
        case LogLevel.error:
          this.logger.error(...log.args);
          break;
      }
    }
  }
}

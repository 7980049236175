import useSize from 'ahooks/es/useSize';
import { useLayoutEffect, useRef, useState } from 'react';

export const useTextOverflow = <TElement extends HTMLElement>() => {
  const [isOverflow, setIsOverflow] = useState<boolean>(false);
  const ref = useRef<TElement>(null);
  const textContainerSize = useSize(ref);

  const checkOverflow = () => {
    const { current } = ref;
    if (!current) {
      return;
    }
    const hasOverflow = current.scrollHeight > current.clientHeight;

    setIsOverflow(hasOverflow);
  };

  useLayoutEffect(() => checkOverflow(), [ref, textContainerSize]);

  return { isOverflow, ref };
};

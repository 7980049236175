import { useTableSettings } from '../../utils/table-settings';

export const defaultColumnVisibility = {
  description: false,
};

export function useQuestionnairesTableSettings(storageKey: string) {
  return useTableSettings({
    storageKey,
    defaultColumnVisibility,
  });
}

import { Button, Card, Icon, IconButton, Stack, Tooltip } from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { LinkIcon } from '@heroicons/react/24/outline';
import { useUnlinkControlAndEvidenceMutation } from '@main/graphql/mutations/UnlinkControlAndEvidence.generated';
import {
  GetEvidenceLinkedControlsQuery,
  useGetEvidenceLinkedControlsQuery,
} from '@main/graphql/queries/GetEvidenceLinkedControls.generated';
import { SearchEntitiesEnum } from '@main/graphql/types.generated';
import { toError } from '@main/shared/utils';
import {
  createColumnHelper,
  errorToast,
  successToast,
  Table,
  useAlertDialog,
  useDrawer,
} from '@main/ui';
import { UnlinkIcon } from '@main/ui/icons';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { CONTROL_EVIDENCE_STATUSES } from '../../utils/constants';
import { AppGlobalSearchResult } from '../global-search/use-global-search';
import { EntitySearch } from '../link-entity/entity-search';
import { useLinkEvidenceToControlMutation } from '../link-entity/link-entity.generated';
import { LinkEntityModal } from '../link-entity/link-entity-modal';
import { useEntitySearch } from '../link-entity/use-entity-search';
import { getCurrentUserSelectedOrgRole } from '../user/slice';

export const EvidenceControlsTab = ({ evidenceId }: { evidenceId: string }) => {
  const { t } = useTranslation();
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canLinkEvidence = userRole.permissionMap?.link_controls_evidence;

  const { data: linkedControlData, isLoading: isControlLoading } =
    useGetEvidenceLinkedControlsQuery(
      { evidenceId },
      { refetchOnMountOrArgChange: true, pollingInterval: 5000 },
    );
  const controlSearch = useEntitySearch({
    entities: [SearchEntitiesEnum.Controls],
  });
  const { linkControlHandler, isLoading: isLinkingControl } = useLinkControl({
    evidenceId,
    onClose: () => controlSearch.disclosure.onClose?.(),
  });
  const columns = useControlsTableColumns(evidenceId);
  const controls = linkedControlData?.evidences_by_pk?.control_evidences || [];

  const tableItemName = useMemo(() => {
    return {
      singular: t('entities.control').toLowerCase(),
      plural: t('entities.plural.controls').toLowerCase(),
      alternateSubheading: true,
    };
  }, [t]);

  return (
    <Stack spacing={6}>
      <Card variant="table-styles">
        <Table
          minW="400px"
          data={controls}
          isLoading={isControlLoading}
          columns={columns}
          itemName={tableItemName}
        />
      </Card>
      {canLinkEvidence && (
        <Button
          alignSelf="end"
          mt={4}
          colorScheme="blue"
          leftIcon={<Icon as={LinkIcon} boxSize={5} />}
          onClick={() => controlSearch.disclosure.onOpen?.()}
        >
          {t('evidences.buttons.link')}
        </Button>
      )}

      <LinkEntityModal
        isOpen={controlSearch.disclosure.isOpen}
        onClose={() => controlSearch.disclosure.onClose?.()}
        entityName="control"
        onLinkEntity={() => linkControlHandler(controlSearch.selectedResults)}
        isLinkingEntity={isLinkingControl}
      >
        <EntitySearch
          input={{
            placeholder: t('shared.linkModal.placeholder', {
              entity: t('entities.control').toLowerCase(),
            }),
          }}
          {...controlSearch}
        />
      </LinkEntityModal>
    </Stack>
  );
};

function useLinkControl({ evidenceId, onClose }: { evidenceId: string; onClose: () => void }) {
  const { t } = useTranslation();
  const [linkEvidenceToControl, { isLoading }] = useLinkEvidenceToControlMutation();
  const { refetch: refetchLinkedControls } = useGetEvidenceLinkedControlsQuery({ evidenceId });

  const linkControlHandler = async (results: AppGlobalSearchResult[]) => {
    try {
      await linkEvidenceToControl({
        input: results.map((result) => ({ control_id: result.id, evidence_id: evidenceId })),
      }).unwrap();

      successToast(t('successMessages.linkSucceeded', { entity: t('entities.control') }));
      refetchLinkedControls();
      onClose();
    } catch (error) {
      errorToast(t('errorMessages.linkFailed', { entity: t('entities.control') }));
      datadogLogs.logger.error(
        `Failed linking control(s) to evidence`,
        { evidenceId, controls: results },
        toError(error),
      );
    }
  };

  return { linkControlHandler, isLoading };
}

type LinkedControl = NonNullable<
  GetEvidenceLinkedControlsQuery['evidences_by_pk']
>['control_evidences'][number];
function useControlsTableColumns(evidenceId: string) {
  const { t } = useTranslation();
  const drawer = useDrawer();
  const { openDialog } = useAlertDialog();
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canLinkEvidence = userRole.permissionMap?.link_controls_evidence;

  const { refetch: refetchLinkedControls } = useGetEvidenceLinkedControlsQuery({ evidenceId });
  const [unlinkControlFromEvidence] = useUnlinkControlAndEvidenceMutation();

  const onUnlink = useCallback(
    async (controlEvidenceId: string) => {
      if (!evidenceId) return;

      try {
        await unlinkControlFromEvidence({ controlEvidenceId }).unwrap();

        await refetchLinkedControls();
        successToast(t('successMessages.unlinkSucceeded', { entity: t('entities.control') }));
      } catch (error) {
        errorToast(t('errorMessages.unlinkFailed', { entity: t('entities.control') }));
        datadogLogs.logger.error('Control unlink failed', {}, toError(error));
      }
    },
    [t, unlinkControlFromEvidence, refetchLinkedControls, evidenceId],
  );

  return useMemo(() => {
    const columnHelper = createColumnHelper<LinkedControl>();
    return [
      columnHelper.columns.tag({
        id: 'id',
        header: t('controls.table.columns.internalId'),
        accessorFn: ({ control }) => {
          if (!control.internal_id) return;
          return { value: control.internal_id, colorScheme: 'gray' };
        },
        size: 90,
      }),

      columnHelper.columns.text({
        id: 'name',
        header: t('controls.table.columns.name'),
        accessorFn: ({ control }) => control.name,
        meta: {
          cell: {
            onClick: ({ row }) =>
              drawer.open({ entity: 'control', entityId: row.original.control.id }),
          },
        },
      }),

      columnHelper.columns.status({
        id: 'status',
        header: t('controls.table.columns.evidenceStatus'),
        accessorFn: ({ status }) => {
          const statusMeta = CONTROL_EVIDENCE_STATUSES[status];
          return {
            value: statusMeta.value,
            label: t(`evidences.status.${statusMeta.value}`),
            colorScheme: statusMeta.colorScheme,
          };
        },
        size: 120,
      }),

      columnHelper.columns.actions({
        id: 'actions',
        size: 50,
        ...(canLinkEvidence
          ? {
              PrimaryAction: ({ row }) => (
                <Tooltip label={t('buttons.unlink')}>
                  <IconButton
                    minW={4}
                    aria-label={'Unlink'}
                    variant="link"
                    icon={<Icon as={UnlinkIcon} />}
                    onClick={() =>
                      openDialog({
                        dialogHeader: t('controls.alert.unlinkFromEvidence.header'),
                        dialogContent: t('controls.alert.unlinkFromEvidence.content'),
                        confirmAction: {
                          onClick: () => onUnlink(row.original.id),
                        },
                      })
                    }
                  />
                </Tooltip>
              ),
            }
          : {}),
      }),
    ];
  }, [t, openDialog, onUnlink, canLinkEvidence, drawer]);
}

import { Box, FormControl, FormLabel, Stack } from '@chakra-ui/react';
import { QuestionnnaireForm, QuestionnnaireFormMode } from '@main/questionnaires-form';
import { getQuestionnaireType, QuestionnaireType } from '@main/shared/utils';
import {
  FileUpload,
  useDownloadStorageFile,
  useLazyFileUpload,
  useLazyMultipleFilesUpload,
} from '@main/ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetVendorQuestionnaireData } from './use-vendor-questionnaire-data';

export const SubmissionsTab = ({ vqId }: { vqId: string }) => {
  const { fields, answers, questionnaire } = useGetVendorQuestionnaireData(vqId);
  const type = questionnaire && getQuestionnaireType(questionnaire);

  return type === QuestionnaireType.Form ? (
    <QuestionnnaireForm mode={QuestionnnaireFormMode.View} fields={fields} answers={answers} />
  ) : (
    <QuestionnaireFile vqId={vqId} />
  );
};

const QuestionnaireFile = ({ vqId }: { vqId: string }) => {
  const { t } = useTranslation();
  const { vq } = useGetVendorQuestionnaireData(vqId);

  const supportingFiles = useMemo(() => {
    const uploads = vq?.uploads || [];
    const answerUploadId = vq?.answer_upload?.id;
    return uploads.filter((file) => file.id !== answerUploadId).map((upload) => upload.file);
  }, [vq?.uploads, vq?.answer_upload?.id]);

  const downloadStorageFile = useDownloadStorageFile();
  const answerFile = vq?.answer_upload?.file;

  const answerFileUpload = useLazyFileUpload({
    file: answerFile,
    onFileDownload: downloadStorageFile,
  });
  const supportingFileUpload = useLazyMultipleFilesUpload({
    files: supportingFiles,
    onFileDownload: downloadStorageFile,
  });
  return (
    <Stack spacing={6} pb={4}>
      <Box>
        <FormControl w="full" isReadOnly>
          <FormLabel mb={2}>
            {t('vendors.questionnaires.answerFilesModal.answerFileLabel')}
          </FormLabel>
          <FileUpload {...answerFileUpload.props}>
            <FileUpload.Dropzone />
          </FileUpload>
        </FormControl>
      </Box>
      <Box>
        <FormControl w="full" isReadOnly>
          <FormLabel mb={2}>
            {t('vendors.questionnaires.answerFilesModal.supportingFilesLabel')}
          </FormLabel>
          <FileUpload {...supportingFileUpload.props}>
            <FileUpload.Dropzone />
          </FileUpload>
        </FormControl>
      </Box>
    </Stack>
  );
};

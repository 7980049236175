import { useDisclosure } from '@chakra-ui/react';
import { LockClosedIcon, LockOpenIcon, TrashIcon } from '@heroicons/react/24/outline';
import {
  GetEvidenceByIdQuery,
  useGetEvidenceByIdQuery,
} from '@main/graphql/queries/GetEvidenceById.generated';
import { isNonNullable } from '@main/shared/utils';
import { DrawerActionsProps, MenuAction, SecondaryAction, useDrawer } from '@main/ui';
import { useTranslation } from 'react-i18next';

import { useDeleteEvidence } from './delete-evidence';
import { EvidenceAccessModal } from './evidence-access-modal';
import { useIsUserAuthorizedToChangeEvidence } from './utils';

type Evidence = GetEvidenceByIdQuery['evidences_by_pk'];

export const useEvidenceDrawerActions = (evidenceId: string): DrawerActionsProps => {
  const evidenceQuery = useGetEvidenceByIdQuery(
    { evidence_id: evidenceId },
    { refetchOnMountOrArgChange: true },
  );
  const evidence = evidenceQuery.data?.evidences_by_pk;

  const manageAccessAction = useManageAccessAction(evidence);
  const deleteAction = useDeleteAction(evidence);

  return {
    secondaryActions: [manageAccessAction].filter(isNonNullable),
    menuActions: [deleteAction].filter(isNonNullable),
  };
};

function useManageAccessAction(evidence: Evidence): SecondaryAction | null {
  const { t } = useTranslation();
  const accessModal = useDisclosure();
  if (!evidence) {
    return null;
  }

  const icon = evidence.is_confidential ? (
    <LockClosedIcon width="16px" strokeWidth="2px" />
  ) : (
    <LockOpenIcon width="16px" strokeWidth="2px" />
  );

  return {
    icon,
    variant: 'icon',
    label: t('evidences.accessModal.heading'),
    onClick: accessModal.onOpen,
    children: (
      <EvidenceAccessModal
        evidenceId={evidence.id}
        isOpen={accessModal.isOpen}
        onClose={accessModal.onClose}
      />
    ),
  };
}

function useDeleteAction(evidence: Evidence): MenuAction | null {
  const { t } = useTranslation();
  const drawer = useDrawer();
  const canDeleteEvidence = useIsUserAuthorizedToChangeEvidence(evidence);
  const deleteEvidence = useDeleteEvidence();
  if (!evidence || !canDeleteEvidence) {
    return null;
  }

  return {
    icon: <TrashIcon />,
    label: t('buttons.delete'),
    onClick: () => {
      deleteEvidence(evidence, () => drawer.close());
    },
  };
}

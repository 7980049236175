import dayjs from 'dayjs';

/**
 * This function adjusts the date to the beginning of the day for the date picker in the user's timezone if it is ahead of UTC.
 */

export function serializeDate(inputValue: string): string {
  if (!inputValue) {
    return '';
  }

  const now = new Date();
  const timezoneOffset = now.getTimezoneOffset();
  if (timezoneOffset > 0) {
    // Timezone is behind UTC
    return inputValue;
  } else {
    // Timezone is ahead of UTC
    return new Date(new Date(inputValue).setHours(0, 0, 0, 0)).toISOString();
  }
}

export function toDatePart(date?: string) {
  return dayjs(date).format('YYYY-MM-DD');
}

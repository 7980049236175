import { Icon, IconButton, Tooltip } from '@chakra-ui/react';
import { EyeIcon, EyeSlashIcon, PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline';
import { GetFieldConfigsQuery } from '@main/graphql/features/CustomFields.generated';
import { Promisable } from '@main/shared/types';
import { isNonNullable } from '@main/shared/utils';
import { createColumnHelper, Table, TableItem, useTableRowReorder } from '@main/ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

type FieldConfig = GetFieldConfigsQuery['field_configs'][number];

export const FieldConfigsTable = ({
  itemName,
  isLoading,
  fieldConfigs,
  canEditFieldConfigs,
  onFieldVisibilityChange,
  onFieldConfigEdit,
  onFieldConfigDelete,
  onFieldConfigsOrderUpdate,
}: {
  itemName: TableItem;
  isLoading?: boolean;
  fieldConfigs: FieldConfig[];
  canEditFieldConfigs: boolean;
  onFieldVisibilityChange: (id: string, hidden: boolean) => void;
  onFieldConfigEdit: (id: string) => void;
  onFieldConfigDelete: (id: string) => void;
  onFieldConfigsOrderUpdate?: (fieldConfigs: FieldConfig[]) => Promisable<void>;
}) => {
  const columns = useCustomFieldColumns({
    canEditFieldConfigs,
    onFieldVisibilityChange,
    onFieldConfigEdit,
    onFieldConfigDelete,
  });

  const tableRowReorder = useTableRowReorder({
    onRowsOrderUpdated: onFieldConfigsOrderUpdate ?? (() => void 0),
  });

  return (
    <Table
      isLoading={!!isLoading}
      minW={'400px'}
      columns={columns}
      data={fieldConfigs}
      itemName={itemName}
      {...(onFieldConfigsOrderUpdate ? tableRowReorder : {})}
    />
  );
};

function useCustomFieldColumns({
  canEditFieldConfigs,
  onFieldVisibilityChange,
  onFieldConfigEdit,
  onFieldConfigDelete,
}: {
  canEditFieldConfigs: boolean;
  onFieldVisibilityChange: (id: string, hidden: boolean) => void;
  onFieldConfigEdit: (id: string) => void;
  onFieldConfigDelete: (id: string) => void;
}) {
  const { t } = useTranslation();

  return useMemo(() => {
    const columnHelper = createColumnHelper<FieldConfig>();

    return [
      columnHelper.columns.tag({
        id: 'type',
        header: t('customFields.table.columns.type'),
        size: 150,
        accessorFn: ({ field_type }) => ({
          value: field_type,
          label: t(`customFields.type.${field_type}`),
          colorScheme: 'purple',
        }),
      }),

      columnHelper.columns.text({
        id: 'name',
        header: t('customFields.table.columns.name'),
        accessorFn: ({ name }) => name,
      }),

      canEditFieldConfigs
        ? columnHelper.columns.actions({
            id: 'actions',
            size: 80,

            PrimaryAction: ({ row }) => {
              const label = t(`buttons.${row.original.hidden ? 'show' : 'hide'}`);

              return (
                <Tooltip label={label} openDelay={500}>
                  <IconButton
                    minW={4}
                    aria-label={label}
                    variant="link"
                    icon={row.original.hidden ? <EyeSlashIcon /> : <EyeIcon />}
                    onClick={() => {
                      onFieldVisibilityChange(row.original.id, !row.original.hidden);
                    }}
                  />
                </Tooltip>
              );
            },
            secondaryActions: ({ row }) => [
              {
                icon: <Icon as={PencilSquareIcon} />,
                label: t('buttons.edit'),
                onClick: () => {
                  onFieldConfigEdit(row.original.id);
                },
              },
              {
                icon: <Icon as={TrashIcon} />,
                label: t('buttons.delete'),
                onClick: () => {
                  onFieldConfigDelete(row.original.id);
                },
              },
            ],
          })
        : null,
    ].filter(isNonNullable);
  }, [t, canEditFieldConfigs, onFieldVisibilityChange, onFieldConfigEdit, onFieldConfigDelete]);
}

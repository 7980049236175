import { VendorAuditRow } from '@main/graphql/client-scalars';
import { Vendor_Questionnaire_Statuses_Enum } from '@main/graphql/types.generated';
import { QUESTIONNAIRE_STATUSES } from '@main/shared/types';
import { exhaustiveCheck, hasProperty } from '@main/shared/utils';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { VENDOR_APPROVAL_STATUSES } from '../../utils/constants';
import { ActivityEntry, PaginatedHistoryList, TagActivityEntry } from '../activity-history/shared';
import { VENDOR_ASSESSMENT_STATUS_COLOR } from '../shared/status-color';
import { getCurrentOrgUsersMap } from '../user/slice';
import {
  GetVendorActivityHistoryQuery,
  useGetVendorActivityHistoryQuery,
} from './get-vendor.generated';

type Activity = GetVendorActivityHistoryQuery['audit_vendor_history_view'][number] & VendorAuditRow;

export const VendorHistoryTab: FC<{ vendorId: string }> = ({ vendorId }) => {
  const { t } = useTranslation();
  const [paginationParams, setPagination] = useState<{ limit: number; offset: number }>();
  const { data: activityData, isLoading: isLoadingHistory } = useGetVendorActivityHistoryQuery(
    {
      vendor_id: vendorId,
      ...paginationParams,
    },
    { refetchOnMountOrArgChange: true, skip: !paginationParams },
  );
  const currentOrgUsersById = useAppSelector(getCurrentOrgUsersMap);

  if (isLoadingHistory) {
    return null;
  }

  const getActivityNode = (activity: Activity) => {
    switch (activity.table_name) {
      case 'vendors': {
        switch (activity.action) {
          case 'I': {
            return (
              <ActivityEntry
                hasuraUser={activity.hasura_user}
                timestamp={activity.action_timestamp}
                title={t('vendors.history.vendorAdded')}
              />
            );
          }
          case 'U':
            if (hasProperty(activity.changed_fields, 'owner_id')) {
              const oldId = activity.row_data.owner_id;
              const newId = activity.changed_fields.owner_id;

              return (
                <TagActivityEntry
                  hasuraUser={activity.hasura_user}
                  timestamp={activity.action_timestamp}
                  title={t('vendors.history.ownerChanged')}
                  from={oldId ? currentOrgUsersById[oldId]?.displayName ?? 'N/A' : 'N/A'}
                  to={newId ? currentOrgUsersById[newId]?.displayName ?? 'N/A' : 'N/A'}
                />
              );
            }

            if (hasProperty(activity.changed_fields, 'terminated_at')) {
              return (
                <ActivityEntry
                  hasuraUser={activity.hasura_user}
                  timestamp={activity.action_timestamp}
                  title={t('vendors.history.terminatedVendor')}
                />
              );
            }

            if (hasProperty(activity.changed_fields, 'last_reviewed_date')) {
              return (
                <ActivityEntry
                  hasuraUser={activity.hasura_user}
                  timestamp={activity.action_timestamp}
                  title={t('vendors.history.updatedLastReviewedDate')}
                />
              );
            }

            if (hasProperty(activity.changed_fields, 'contact_email')) {
              return (
                <TagActivityEntry
                  hasuraUser={activity.hasura_user}
                  timestamp={activity.action_timestamp}
                  title={t('vendors.history.updatedContactEmail')}
                  from={activity.row_data.contact_email ?? 'N/A'}
                  to={activity.changed_fields.contact_email ?? 'N/A'}
                />
              );
            }

            if (hasProperty(activity.changed_fields, 'assessment_status')) {
              const oldStatus = activity.row_data.assessment_status;
              const newStatus = activity.changed_fields.assessment_status;

              return (
                <TagActivityEntry
                  hasuraUser={activity.hasura_user}
                  timestamp={activity.action_timestamp}
                  title={t('vendors.history.changedStatus')}
                  from={
                    oldStatus ? t(`vendors.enum.assessmentStatus.${oldStatus}`) ?? 'N/A' : 'N/A'
                  }
                  fromColorScheme={VENDOR_ASSESSMENT_STATUS_COLOR[oldStatus]}
                  to={newStatus ? t(`vendors.enum.assessmentStatus.${newStatus}`) ?? 'N/A' : 'N/A'}
                  toColorScheme={VENDOR_ASSESSMENT_STATUS_COLOR[newStatus]}
                />
              );
            }

            if (hasProperty(activity.changed_fields, 'review_frequency')) {
              const oldFrequency = activity.row_data.review_frequency;
              const newFrequency = activity.changed_fields.review_frequency;

              return (
                <TagActivityEntry
                  hasuraUser={activity.hasura_user}
                  timestamp={activity.action_timestamp}
                  title={t('vendors.history.changedFrequency')}
                  from={oldFrequency ? t(`vendors.frequency.${oldFrequency}`) ?? 'N/A' : 'N/A'}
                  to={newFrequency ? t(`vendors.frequency.${newFrequency}`) ?? 'N/A' : 'N/A'}
                />
              );
            }

            if (hasProperty(activity.changed_fields, 'approval_status')) {
              const oldApprovalStatus = activity.row_data.approval_status;
              const newApprovalStatus = activity.changed_fields.approval_status;

              return (
                <TagActivityEntry
                  hasuraUser={activity.hasura_user}
                  timestamp={activity.action_timestamp}
                  title={t('vendors.history.changedApprovalStatus')}
                  from={
                    oldApprovalStatus
                      ? t(`vendors.props.approvalStatus.enums.${oldApprovalStatus}`) ?? 'N/A'
                      : 'N/A'
                  }
                  fromColorScheme={
                    oldApprovalStatus
                      ? VENDOR_APPROVAL_STATUSES[oldApprovalStatus].colorScheme
                      : 'gray'
                  }
                  to={
                    newApprovalStatus
                      ? t(`vendors.props.approvalStatus.enums.${newApprovalStatus}`) ?? 'N/A'
                      : 'N/A'
                  }
                  toColorScheme={
                    newApprovalStatus
                      ? VENDOR_APPROVAL_STATUSES[newApprovalStatus].colorScheme
                      : 'gray'
                  }
                />
              );
            }

            return null;
          case 'D':
          case 'T': {
            return null;
          }
          default:
            return exhaustiveCheck(activity.action);
        }
      }

      case 'comments': {
        switch (activity.action) {
          case 'I': {
            return (
              <ActivityEntry
                hasuraUser={activity.hasura_user}
                timestamp={activity.action_timestamp}
                title={t('vendors.history.commentAdded')}
              />
            );
          }
          case 'D': {
            return (
              <ActivityEntry
                hasuraUser={activity.hasura_user}
                timestamp={activity.action_timestamp}
                title={t('vendors.history.commentDeleted')}
              />
            );
          }
          case 'U':
          case 'T': {
            return null;
          }
          default:
            return exhaustiveCheck(activity.action);
        }
      }

      case 'vendor_questionnaires': {
        switch (activity.action) {
          case 'I': {
            return (
              // as we create an entry in vendor_questionnaires table via a temporal job
              <ActivityEntry
                hasuraUser={{
                  'x-hasura-user-id': activity.row_data.sent_by_user_id,
                  'x-hasura-role': 'user',
                }}
                timestamp={activity.action_timestamp}
                title={t('vendors.history.sentQuestionnaire')}
              />
            );
          }
          case 'D': {
            return (
              <ActivityEntry
                hasuraUser={activity.hasura_user}
                timestamp={activity.action_timestamp}
                title={t('vendors.history.deletedQuestionnaire')}
              />
            );
          }
          case 'U': {
            if (hasProperty(activity.changed_fields, 'status')) {
              const oldStatus = activity.row_data.status;
              const newStatus = activity.changed_fields.status;

              if (
                newStatus === Vendor_Questionnaire_Statuses_Enum.Created ||
                newStatus === Vendor_Questionnaire_Statuses_Enum.Pending
              ) {
                return null;
              }

              return (
                <TagActivityEntry
                  hasuraUser={activity.hasura_user}
                  timestamp={activity.action_timestamp}
                  title={t('vendors.history.changedStatus')}
                  from={
                    oldStatus ? t(`vendors.questionnaires.status.${oldStatus}`) ?? 'N/A' : 'N/A'
                  }
                  fromColorScheme={QUESTIONNAIRE_STATUSES[oldStatus].colorScheme}
                  to={newStatus ? t(`vendors.questionnaires.status.${newStatus}`) ?? 'N/A' : 'N/A'}
                  toColorScheme={QUESTIONNAIRE_STATUSES[newStatus].colorScheme}
                />
              );
            }

            return null;
          }
          case 'T': {
            return null;
          }
          default:
            return exhaustiveCheck(activity.action);
        }
      }

      case 'vendor_risks': {
        switch (activity.action) {
          case 'I': {
            return (
              <ActivityEntry
                hasuraUser={activity.hasura_user}
                timestamp={activity.action_timestamp}
                title={t('vendors.history.linkedRisk')}
              />
            );
          }
          case 'D': {
            return (
              <ActivityEntry
                hasuraUser={activity.hasura_user}
                timestamp={activity.action_timestamp}
                title={t('vendors.history.deletedRisk')}
              />
            );
          }
          case 'U':
          case 'T': {
            return null;
          }
          default:
            return exhaustiveCheck(activity.action);
        }
      }
    }
  };

  return (
    <PaginatedHistoryList
      setPaginationParams={setPagination}
      currentPageData={activityData?.audit_vendor_history_view as Activity[]}
      isLoadingHistory={isLoadingHistory}
      getActivityNode={getActivityNode}
    />
  );
};

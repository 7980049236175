/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type GetUserByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
}>;

export type GetUserByIdQuery = {
  __typename?: 'query_root';
  user?: {
    __typename?: 'users';
    id: string;
    email?: string;
    displayName: string;
    metadata: ClientTypes.UserMetaData;
    avatarUrl: string;
    activeMfaType?: string;
    joined_with_sso?: boolean;
    myOrgRoles: Array<{
      __typename?: 'organization_users';
      organization_id: string;
      role: {
        __typename?: 'roles';
        id: string;
        name: string;
        system_role?: Types.System_Roles_Enum;
        role_permissions: Array<{
          __typename?: 'role_permissions';
          permission: Types.Permissions_Enum;
        }>;
      };
    }>;
  };
};

export const GetUserByIdDocument = `
    query GetUserById($id: uuid!) {
  user(id: $id) {
    id
    email
    displayName
    metadata
    avatarUrl
    activeMfaType
    joined_with_sso
    myOrgRoles: organization_users {
      organization_id
      role {
        id
        name
        system_role
        role_permissions {
          permission
        }
      }
    }
  }
}
    ` as string & TypedDocumentNode<GetUserByIdQuery, GetUserByIdQueryVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetUserById: build.query<GetUserByIdQuery, GetUserByIdQueryVariables>({
      query: (variables) => ({ document: GetUserByIdDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetUserByIdQuery, useLazyGetUserByIdQuery } = injectedRtkApi;

import {
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { toError } from '@main/shared/utils';
import { AutoResizeTextarea, errorToast, successToast } from '@main/ui';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useUpdatePolicyVersionMutation } from './manage-policies.generated';

export type RevisionDetailsModalProps = {
  policyVersionId: string;
  isOpen: boolean;
  onClose(): void;
  versionRevisionDetails?: string;
};

export function RevisionDetailsModal(props: RevisionDetailsModalProps) {
  const { t } = useTranslation();
  const [revisionDetails, setRevisionDetails] = useState(props.versionRevisionDetails ?? '');
  const [updateVersion, { isLoading: isUpdating }] = useUpdatePolicyVersionMutation();

  const submitRevisionDetails = async () => {
    try {
      await updateVersion({
        id: props.policyVersionId,
        updatePayload: {
          revision_details: revisionDetails,
        },
      }).unwrap();
      successToast(t('policies.versions.revisionDetails.successToast'));
      props.onClose();
    } catch (error) {
      errorToast(t('errorMessages.updateFailed', { entity: t('entities.policyVersion') }));
      datadogLogs.logger.error(
        'Policy version revision details update failed',
        { policyVersionId: props.versionRevisionDetails },
        toError(error),
      );
    }
  };

  return (
    <Modal size="xl" isCentered isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader as={Text} fontSize={'lg'} fontWeight={'bold'}>
          {t('policies.versions.revisionDetails.editLabel')}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text mb={6}>{t('policies.versions.revisionDetails.editInfo')}</Text>
          <FormControl>
            <FormLabel>{t('policies.versions.revisionDetails.label')}:</FormLabel>
            <AutoResizeTextarea
              minRows={3}
              defaultValue={revisionDetails}
              onChange={(e) => setRevisionDetails(e.target.value)}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button onClick={props.onClose} mr={3}>
            {t('buttons.cancel')}
          </Button>
          <Button colorScheme="blue" onClick={submitRevisionDetails} isLoading={isUpdating}>
            {t('buttons.submit')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

import { Box, Button, Card, Icon, IconButton, Stack, Text, Tooltip } from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { InformationCircleIcon, PencilIcon, PlusIcon } from '@heroicons/react/24/outline';
import { useUnlinkControlFromRiskMutation } from '@main/graphql/mutations/UnlinkControlFromRisk.generated';
import { SearchEntitiesEnum } from '@main/graphql/types.generated';
import { toError } from '@main/shared/utils';
import {
  createColumnHelper,
  errorToast,
  NoPermissionPlaceholder,
  successToast,
  Table,
  useAlertDialog,
  useDrawer,
} from '@main/ui';
import { UnlinkIcon } from '@main/ui/icons';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { CONTROL_STATUSES } from '../../utils/constants';
import { AppGlobalSearchResult } from '../global-search/use-global-search';
import { EntitySearch } from '../link-entity/entity-search';
import { useLinkControlToRiskMutation } from '../link-entity/link-entity.generated';
import { LinkEntityModal } from '../link-entity/link-entity-modal';
import { useEntitySearch } from '../link-entity/use-entity-search';
import { getCurrentUserSelectedOrgRole } from '../user/slice';
import { GetRiskQuery, useGetRiskQuery } from './get-risk.generated';

type RiskControlItem = NonNullable<GetRiskQuery['risks_by_pk']>['risk_controls'][number];

export function RiskControlsTab({ riskId }: { riskId: string }) {
  const { t } = useTranslation();

  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canLinkControlToRisk = userRole.permissionMap?.link_controls_risks;
  const canViewControls = userRole.permissionMap.read_controls;
  const [isLinkingPrimaryControl, setIsLinkingPrimaryControl] = useState(false);
  const { primaryControls, secondaryControls } = useGetRiskQuery(
    { risk_id: riskId },
    {
      selectFromResult: ({ data }) => {
        return {
          primaryControls:
            data?.risks_by_pk?.risk_controls?.filter((control) => control.is_control_primary) || [],
          secondaryControls:
            data?.risks_by_pk?.risk_controls?.filter((control) => !control.is_control_primary) ||
            [],
        };
      },
    },
  );
  const controlSearch = useEntitySearch({
    entities: [SearchEntitiesEnum.Controls],
  });
  const { linkControlHandler, isLoading: isLinkingControl } = useLinkControl({
    riskId,
    isPrimary: isLinkingPrimaryControl,
    onClose: () => controlSearch.disclosure.onClose?.(),
  });
  const columns = useControlsTableColumns(riskId);

  const tableItemName = useMemo(() => {
    return {
      singular: t('entities.control').toLowerCase(),
      plural: t('entities.plural.controls').toLowerCase(),
      alternateSubheading: true,
      hideSubheading: !canLinkControlToRisk,
    };
  }, [canLinkControlToRisk, t]);

  if (!canViewControls) {
    return <NoPermissionPlaceholder />;
  }

  return (
    <>
      <Box>
        <Stack direction="row" alignItems="center">
          <Text fontWeight="bold" fontSize="xs">
            {t('risks.controls.primaryControlsLabel')}
          </Text>
          <Tooltip
            label={t('risks.controls.primaryControlsTooltip')}
            fontSize="sm"
            hasArrow
            placement="top"
          >
            <Icon as={InformationCircleIcon} color="gray.400" w={4} h={4} />
          </Tooltip>
        </Stack>

        <Card variant="table-styles" mt={2}>
          <Table minW={'400px'} data={primaryControls} columns={columns} itemName={tableItemName} />
        </Card>
      </Box>
      <Stack>
        {canLinkControlToRisk && (
          <Button
            leftIcon={<Icon color={'white'} _dark={{ color: 'black' }} w={5} h={5} as={PlusIcon} />}
            colorScheme="blue"
            variant="solid"
            onClick={() => {
              controlSearch.disclosure.onOpen?.();
              setIsLinkingPrimaryControl(true);
            }}
            maxW={'fit-content'}
            alignSelf={'end'}
          >
            {t('risks.controls.linkPrimaryControlBtn')}
          </Button>
        )}
      </Stack>
      <Box>
        <Stack direction="row" alignItems="center">
          <Text fontWeight="bold" fontSize="xs">
            {t('risks.controls.secondaryControlsLabel')}
          </Text>{' '}
          <Tooltip
            label={t('risks.controls.secondaryControlsTooltip')}
            fontSize="sm"
            hasArrow
            placement="top"
          >
            <Icon as={InformationCircleIcon} color="gray.400" w={4} h={4} />
          </Tooltip>
        </Stack>

        <Card variant="table-styles" mt="2">
          <Table
            minW={'400px'}
            data={secondaryControls}
            columns={columns}
            itemName={tableItemName}
          />
        </Card>
      </Box>
      <Stack>
        {canLinkControlToRisk && (
          <Button
            leftIcon={<Icon color={'white'} _dark={{ color: 'black' }} w={5} h={5} as={PlusIcon} />}
            colorScheme="blue"
            variant="solid"
            onClick={() => {
              controlSearch.disclosure.onOpen?.();
              setIsLinkingPrimaryControl(false);
            }}
            maxW={'fit-content'}
            alignSelf={'end'}
          >
            {t('risks.controls.linkSecondaryControlBtn')}
          </Button>
        )}
      </Stack>

      <LinkEntityModal
        isOpen={controlSearch.disclosure.isOpen}
        onClose={() => controlSearch.disclosure.onClose?.()}
        entityName="control"
        onLinkEntity={() => linkControlHandler(controlSearch.selectedResults)}
        isLinkingEntity={isLinkingControl}
      >
        <EntitySearch
          input={{
            placeholder: t('shared.linkModal.placeholder', {
              entity: t('entities.control').toLowerCase(),
            }),
          }}
          {...controlSearch}
        />
      </LinkEntityModal>
    </>
  );
}

function useLinkControl({
  riskId,
  isPrimary,
  onClose,
}: {
  riskId: string;
  isPrimary: boolean;
  onClose: () => void;
}) {
  const { t } = useTranslation();
  const [linkControlToRisk, { isLoading }] = useLinkControlToRiskMutation();
  const { refetch: refetchLinkedControls } = useGetRiskQuery({ risk_id: riskId });

  const linkControlHandler = async (results: AppGlobalSearchResult[]) => {
    try {
      await linkControlToRisk({
        input: results.map((result) => ({
          control_id: result.id,
          risk_id: riskId,
          is_control_primary: isPrimary,
        })),
      }).unwrap();

      successToast(t('successMessages.linkSucceeded', { entity: t('entities.control') }));
      refetchLinkedControls();
      onClose();
    } catch (error) {
      errorToast(t('errorMessages.linkFailed', { entity: t('entities.control') }));
      datadogLogs.logger.error(
        'Linking control failed',
        { riskId, controls: results },
        toError(error),
      );
    }
  };

  return { linkControlHandler, isLoading };
}

function useControlsTableColumns(riskId: string) {
  const { t } = useTranslation();
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canLinkControlToRisk = userRole.permissionMap?.link_controls_risks;
  const drawer = useDrawer();
  const { openDialog } = useAlertDialog();
  const { refetch: refetchRisk } = useGetRiskQuery({ risk_id: riskId });
  const [unlinkControlFromRisk] = useUnlinkControlFromRiskMutation();

  const onDelete = useCallback(
    async (riskControlId: string) => {
      try {
        await unlinkControlFromRisk({ riskControlId }).unwrap();
        refetchRisk();
      } catch (error) {
        errorToast(t('errorMessages.deleteFailed', { entity: t('entities.riskControl') }));
        datadogLogs.logger.error('unlinking control from risk failed', {}, toError(error));
      }
    },
    [unlinkControlFromRisk, refetchRisk, t],
  );

  const onControlOpen = useCallback(
    (controlId: string) => {
      drawer.open({ entity: 'control', entityId: controlId });
    },
    [drawer],
  );

  return useMemo(() => {
    const columnHelper = createColumnHelper<RiskControlItem>();
    return [
      columnHelper.columns.tag({
        id: 'id',
        header: t('controls.table.columns.internalId'),
        accessorFn: ({ control }) => ({
          value: control.internal_id ?? '',
          colorScheme: 'purple',
          label: control.internal_id,
        }),
        size: 100,
      }),
      columnHelper.columns.status({
        id: 'status',
        header: t('controls.table.columns.status'),
        accessorFn: ({ control }) => {
          const statusMeta = CONTROL_STATUSES[control.status];

          if (!statusMeta) {
            return;
          }

          return {
            value: t(`controls.status.enums.${statusMeta.value}`),
            colorScheme: statusMeta.colorScheme,
          };
        },
        size: 120,
      }),
      columnHelper.columns.text({
        id: 'name',
        header: t('controls.table.columns.name'),
        accessorFn: ({ control }) => control.name,
        meta: {
          cell: { onClick: (cell) => onControlOpen(cell.row.original.control.id) },
        },
      }),

      columnHelper.columns.actions({
        id: 'actions',
        size: 80,
        PrimaryAction: ({ row }) => {
          const controlId = row.original.control.id;

          return (
            <IconButton
              minW={4}
              aria-label={'Edit'}
              variant="link"
              icon={<PencilIcon />}
              onClick={() => onControlOpen(controlId)}
            />
          );
        },

        ...(canLinkControlToRisk
          ? {
              secondaryActions: ({ row }) => [
                {
                  icon: <Icon as={UnlinkIcon} />,
                  label: t('buttons.unlink'),
                  onClick: () =>
                    openDialog({
                      dialogHeader: t('risks.controls.unlink.header'),
                      dialogContent: t('risks.controls.unlink.content'),
                      confirmAction: {
                        children: t('risks.controls.unlink.confirm', {
                          internalId: row.original.control.internal_id,
                        }),
                        onClick: () => onDelete(row.original.id),
                      },
                    }),
                },
              ],
            }
          : {}),
      }),
    ];
  }, [canLinkControlToRisk, onControlOpen, onDelete, openDialog, t]);
}

import { useUpdateRiskMutation } from '@main/graphql/mutations/UpdateRisk.generated';
import { createColumnHelper, useDrawer } from '@main/ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { CONTROL_STATUSES, RISK_LEVELS } from '../../utils/constants';
import { RISK_ASSESSMENT_STATUS_COLOR } from '../shared/status-color';
import { getCurrentOrgNonDisabledUsers, getCurrentUserSelectedOrgRole } from '../user/slice';
import { categoryColumnId } from './constants';
import { OrganizationRisk } from './slice';
import { useUpdateRiskHandler } from './use-update-risk-handler';

export function useRiskColumnHelper() {
  const { t } = useTranslation();
  const drawer = useDrawer();
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canUpdateRisks = userRole.permissionMap?.write_risks;
  const currentOrgNonDisabledUsers = useAppSelector(getCurrentOrgNonDisabledUsers);
  const updateRiskHandler = useUpdateRiskHandler();
  const [updateRisk] = useUpdateRiskMutation();

  return useMemo(() => {
    const columnHelper = createColumnHelper<OrganizationRisk>();

    return {
      status() {
        return columnHelper.columns.status({
          id: 'approval',
          header: t('risks.tableColumns.status'),
          accessorFn: ({ assessment_status }) => {
            return {
              label: t(`risks.enum.assessmentStatus.${assessment_status}`),
              value: assessment_status,
              colorScheme: RISK_ASSESSMENT_STATUS_COLOR[assessment_status],
            };
          },
          enableColumnFilter: true,
          enableGlobalFilter: true,
          enableSorting: true,
          size: 115,
        });
      },

      internalId() {
        return columnHelper.columns.tag({
          id: 'internalId',
          header: t('risks.tableColumns.internalId'),
          accessorFn: ({ internal_id }) => ({
            value: internal_id ?? '',
            colorScheme: 'purple',
          }),
          enableGlobalFilter: true,
          enableColumnFilter: true,
          enableSorting: true,
          size: 100,
        });
      },

      name(options?: { onClickOpenDrawer?: boolean }) {
        return columnHelper.columns.text({
          id: 'name',
          header: t('risks.tableColumns.name'),
          accessorFn: ({ name }) => name,
          enableGlobalFilter: true,
          enableSorting: true,
          meta: {
            cell: {
              onClick: options?.onClickOpenDrawer
                ? (cell) => drawer.open({ entity: 'risk', entityId: cell.row.original.id })
                : undefined,
            },
          },
        });
      },

      description() {
        return columnHelper.columns.text({
          id: 'description',
          header: t('risks.tableColumns.description'),
          accessorFn: ({ description }) => description,
          enableGlobalFilter: true,
          enableColumnFilter: true,
          enableSorting: true,
          size: 300,
        });
      },

      inherentRiskImpact() {
        return columnHelper.columns.tag({
          id: 'inherentRiskImpact',
          header: 'Inherent risk impact',
          accessorFn: ({ inherent_impact }) => defaultOption(inherent_impact?.name),
          enableColumnFilter: true,
          enableSorting: true,
          size: 120,
        });
      },

      inherentRiskLikelihood() {
        return columnHelper.columns.tag({
          id: 'inherentRiskLikelihood',
          header: 'Inherent risk likelihood',
          accessorFn: ({ inherent_likelihood }) => defaultOption(inherent_likelihood?.name),
          enableColumnFilter: true,
          enableSorting: true,
          size: 120,
        });
      },

      residualRiskImpact() {
        return columnHelper.columns.tag({
          id: 'residualRiskImpact',
          header: 'Residual risk impact',
          accessorFn: ({ residual_impact }) => defaultOption(residual_impact?.name),
          enableColumnFilter: true,
          enableSorting: true,
          size: 120,
        });
      },

      residualRiskLikelihood() {
        return columnHelper.columns.tag({
          id: 'residualRiskLikelihood',
          header: 'Residual risk likelihood',
          accessorFn: ({ residual_likelihood }) => defaultOption(residual_likelihood?.name),
          enableColumnFilter: true,
          enableSorting: true,
          size: 120,
        });
      },

      linkedControls() {
        return columnHelper.columns.status({
          id: 'linkedControls',
          header: t('risks.tableColumns.linkedControls'),
          accessorFn: ({ controlStatus }) => {
            if (!controlStatus) {
              return;
            }

            return {
              ...CONTROL_STATUSES[controlStatus],
              label: t(`controls.status.enums.${controlStatus}`),
            };
          },
          size: 120,
          enableColumnFilter: true,
          enableSorting: true,
        });
      },

      inherentRisk() {
        return columnHelper.columns.tag({
          id: 'inherentRisk',
          header: t('risks.tableColumns.inherentRisk'),
          accessorFn: ({ inherentRiskLevel }) => {
            if (!inherentRiskLevel) {
              return;
            }

            return {
              ...RISK_LEVELS[inherentRiskLevel],
              label: t(`risks.enum.level.${inherentRiskLevel}`),
            };
          },
          enableColumnFilter: true,
          enableGlobalFilter: true,
          enableSorting: true,
          size: 90,
        });
      },

      residualRisk() {
        return columnHelper.columns.tag({
          id: 'residualRisk',
          header: t('risks.tableColumns.residualRisk'),
          accessorFn: ({ residualRiskLevel }) => {
            if (!residualRiskLevel) {
              return;
            }

            return {
              ...RISK_LEVELS[residualRiskLevel],
              label: t(`risks.enum.level.${residualRiskLevel}`),
            };
          },
          enableColumnFilter: true,
          enableGlobalFilter: true,
          enableSorting: true,
          size: 105,
        });
      },

      inherentScore() {
        return columnHelper.columns.text({
          id: 'inherentScore',
          header: t('risks.tableColumns.inherentScore'),
          accessorFn: ({ inherentScore }) => inherentScore,
          enableColumnFilter: true,
          enableGlobalFilter: true,
          enableSorting: true,
          size: 105,
        });
      },

      residualScore() {
        return columnHelper.columns.text({
          id: 'residualScore',
          header: t('risks.tableColumns.residualScore'),
          accessorFn: ({ residualScore }) => residualScore,
          enableColumnFilter: true,
          enableGlobalFilter: true,
          enableSorting: true,
          size: 120,
        });
      },

      tags() {
        return columnHelper.columns.tag({
          id: 'tag',
          header: 'Tag',
          accessorFn: ({ tags }) => {
            return tags.map(({ tag }) => ({
              value: tag.id,
              label: tag.name,
              colorScheme: 'purple',
            }));
          },
          size: 200,
          enableColumnFilter: true,
          enableSorting: true,
          isMulti: true,
        });
      },

      category() {
        return columnHelper.columns.tag({
          id: categoryColumnId,
          header: t('risks.tableColumns.category'),
          accessorFn: ({ categories }) => {
            return categories.map(({ category }) => ({
              value: category.id,
              label: category.name,
              colorScheme: 'purple',
            }));
          },
          isMulti: true,
          enableColumnFilter: true,
          enableSorting: true,
          size: 90,
        });
      },

      treatmentPlan() {
        return columnHelper.columns.tag({
          id: 'treatmentPlan',
          header: t('risks.tableColumns.treatmentPlan'),
          accessorFn: ({ treatment_plan }) => {
            return {
              value: treatment_plan,
              label: t(`risks.enum.treatment.${treatment_plan}`),
              colorScheme: 'purple',
            };
          },
          enableColumnFilter: true,
          enableSorting: true,
          size: 95,
        });
      },

      owner(options?: { isEditable?: boolean }) {
        return columnHelper.columns.avatar({
          id: 'owner',
          header: t('risks.tableColumns.owner'),
          accessorFn: ({ assignee }) => {
            if (!assignee) {
              return;
            }

            return {
              id: assignee.id,
              displayName: assignee.displayName,
            };
          },
          size: 70,
          enableColumnFilter: true,
          enableSorting: true,
          edit:
            options?.isEditable && canUpdateRisks
              ? {
                  options: currentOrgNonDisabledUsers.map((user) => ({
                    id: user.id,
                    displayName: user.displayName,
                  })),
                  onChange: (row, user) => {
                    return updateRiskHandler(
                      updateRisk({
                        id: row.original.id,
                        risk_input: {
                          assignee_id: user?.id ?? null,
                        },
                      }),
                    );
                  },
                }
              : undefined,
        });
      },
    };
  }, [t, drawer, canUpdateRisks, currentOrgNonDisabledUsers, updateRiskHandler, updateRisk]);
}

function defaultOption(value?: string) {
  if (!value) return;
  return {
    value,
    colorScheme: 'purple',
  };
}

import { Avatar, Box, Flex, Icon, Text, useColorModeValue } from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import {
  ArchiveBoxIcon,
  ArchiveBoxXMarkIcon,
  BellAlertIcon,
  EnvelopeIcon,
  EnvelopeOpenIcon,
} from '@heroicons/react/24/outline';
import { toError } from '@main/shared/utils';
import {
  errorToast,
  FloatingActionButtons,
  FloatingButtonsContainer,
  getHashedAvatarColor,
} from '@main/ui';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { getAllMembersOfOrgsCurrentUserJoined } from '../user/slice';
import { GetInboxNotificationsQuery } from './get-notifications.generated';
import { useUpdateNotificationMutation } from './update-notification.generated';
import { useGetNotificationContent } from './use-get-notification-content';

export type Notification = GetInboxNotificationsQuery['user_notifications'][number];

export const NotificationItem = ({
  onClose,
  notification,
  refetchNotifications,
}: {
  notification: Notification;
  refetchNotifications: () => void;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const users = useAppSelector(getAllMembersOfOrgsCurrentUserJoined);
  const [updateNotification] = useUpdateNotificationMutation();

  const bgColor = useColorModeValue('gray.50', 'gray.700');
  const avatarBgColor = useColorModeValue('blackAlpha.50', 'blackAlpha.300');
  const avatarIconColor = useColorModeValue('gray.500', 'gray.300');
  const unreadIndicatorColor = useColorModeValue('gray.50', 'gray.700');
  const bottomBorderColor = useColorModeValue('gray.200', 'gray.500');
  const dateTextColor = useColorModeValue('gray.400', 'gray.500');

  const assignorId =
    'assignorId' in notification.content.params
      ? notification.content.params.assignorId
      : undefined;

  const assignor = users[assignorId ?? '']?.displayName;

  const markAsRead = async (value: string | null) => {
    try {
      await updateNotification({
        notificationIds: notification.id,
        input: {
          read_at: value,
        },
      });
      refetchNotifications();
    } catch (error) {
      errorToast(t('errorMessages.updateFailed', { entity: t('entities.notification') }));
      datadogLogs.logger.error(
        'Updating notification failed',
        { notificationId: notification.id, value },
        toError(error),
      );
    }
  };

  const archive = async (value: string | null) => {
    try {
      await updateNotification({
        notificationIds: notification.id,
        input: {
          archived_at: value,
        },
      });
      refetchNotifications();
    } catch (error) {
      errorToast(t('errorMessages.updateFailed', { entity: t('entities.notification') }));
      datadogLogs.logger.error(
        'Updating notification failed',
        { notificationId: notification.id, value },
        toError(error),
      );
    }
  };

  const notificationContent = useGetNotificationContent({ notification });

  if (!notificationContent) {
    return null;
  }

  return (
    <FloatingButtonsContainer
      as={Flex}
      position={'relative'}
      borderBottom={'1px'}
      borderBottomColor={bottomBorderColor}
      px={6}
      py={5}
      bgColor={!notification.read_at ? bgColor : 'transparent'}
      onClick={() => {
        notificationContent.redirect();
        markAsRead(new Date().toISOString());
        onClose();
      }}
      cursor={'pointer'}
    >
      <Box position={'relative'}>
        {assignor ? (
          <Avatar name={assignor} size={'sm'} mr={3} {...getHashedAvatarColor(assignor)} />
        ) : (
          <Avatar
            icon={<Icon as={BellAlertIcon} w={4} h={4} color={avatarIconColor} />}
            size={'sm'}
            mr={3}
            bg={avatarBgColor}
          />
        )}

        {!notification.read_at && (
          <Box
            position={'absolute'}
            bgColor={'blue.400'}
            rounded={'full'}
            w={3}
            h={3}
            top={0}
            left={-0.5}
            border={'2px'}
            borderColor={unreadIndicatorColor}
          />
        )}
      </Box>
      <Box>
        {notificationContent.render()}
        <Text fontSize={'xs'} textColor={dateTextColor}>
          {dayjs(notification.created_at).fromNow(true)} {t('notification.createdTime')}
        </Text>
      </Box>
      <FloatingActionButtons
        position={'absolute'}
        top={2}
        right={2}
        buttons={[
          {
            'aria-label': 'Mark as read',
            icon: notification.read_at ? <EnvelopeIcon /> : <EnvelopeOpenIcon />,
            tooltip: notification.read_at
              ? t('notification.actions.markAsUnread')
              : t('notification.actions.markAsRead'),
            onClick: () =>
              notification.read_at ? markAsRead(null) : markAsRead(new Date().toISOString()),
          },
          {
            'aria-label': 'Mark as archive',
            icon: notification.archived_at ? <ArchiveBoxXMarkIcon /> : <ArchiveBoxIcon />,
            tooltip: notification.archived_at
              ? t('notification.actions.unarchive')
              : t('notification.actions.archive'),
            onClick: () =>
              notification.archived_at ? archive(null) : archive(new Date().toISOString()),
          },
        ]}
      />
    </FloatingButtonsContainer>
  );
};

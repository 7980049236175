import { Flex, Text } from '@chakra-ui/react';
import { Integration_Names_Enum } from '@main/graphql/types.generated';
import { useTranslation } from 'react-i18next';

export const IntegrationDetails = ({
  name,
  helpMessage,
}: {
  name: Integration_Names_Enum;
  helpMessage?: string;
}) => {
  const { t } = useTranslation('integrations');

  return (
    <Flex direction="column" gap={1}>
      <Text fontSize="md" lineHeight={6} fontWeight="bold">
        {t(`integrations.${name}.name`)}
      </Text>
      <Text fontSize="md" lineHeight={6}>
        {t(`integrations.${name}.description`)}
      </Text>
      {helpMessage && (
        <Text fontSize="sm" lineHeight={5} color="gray.400">
          {helpMessage}
        </Text>
      )}
    </Flex>
  );
};

import {
  Button,
  ButtonGroup,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Textarea,
} from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { zodResolver } from '@hookform/resolvers/zod';
import { useUpdateProgramMutation } from '@main/graphql/mutations/UpdateProgram.generated';
import { useGetProgramQuery } from '@main/graphql/queries/GetProgram.generated';
import { toError } from '@main/shared/utils';
import { errorToast, successToast } from '@main/ui';
import { useParams } from '@tanstack/react-router';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as z from 'zod';

const programSchema = z.object({
  programName: z.string().min(1),
  programDescription: z.string().optional(),
});

export type ProgramSchema = z.infer<typeof programSchema>;

export const EditProgram = ({ closeModal }: { closeModal: () => void }) => {
  const { t } = useTranslation();
  const params = useParams({ from: '/programs/$programId' });
  const programId = params.programId;

  const [updateProgram] = useUpdateProgramMutation();
  const { data: programData, refetch: refetchProgram } = useGetProgramQuery({ programId });

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<ProgramSchema>({
    resolver: zodResolver(programSchema),
    shouldFocusError: false,
    defaultValues: {
      programName: programData?.programs_by_pk?.name || '',
      programDescription: programData?.programs_by_pk?.description || '',
    },
  });

  const onSubmit: SubmitHandler<ProgramSchema> = async (data) => {
    try {
      await updateProgram({
        id: programId,
        programInput: {
          name: data.programName,
          description: data.programDescription,
        },
      }).unwrap();

      successToast(t('successMessages.editSucceeded', { entity: t('entities.program') }));
      refetchProgram();
      closeModal();
    } catch (error) {
      errorToast(t('errorMessages.updateFailed', { entity: t('entities.program') }));
      datadogLogs.logger.error('Editing a program failed', {}, toError(error));
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={6} pb={4} pt={2} px={6}>
        <FormControl>
          <FormLabel>{t('programs.edit.form.label.name')}</FormLabel>
          <Input
            {...register('programName')}
            maxLength={200}
            isInvalid={!!errors.programName}
            placeholder={t('programs.edit.form.placeholder.name')}
          ></Input>
        </FormControl>
        <FormControl>
          <FormLabel>{t('programs.edit.form.label.description')}</FormLabel>
          <Textarea
            {...register('programDescription')}
            placeholder={t('programs.edit.form.placeholder.description')}
            isInvalid={!!errors.programDescription}
          ></Textarea>
        </FormControl>
        <ButtonGroup display={'flex'} justifyContent="end" width="full" spacing={3}>
          <Button
            colorScheme={'gray'}
            onClick={() => {
              closeModal();
            }}
          >
            {t('buttons.cancel')}
          </Button>
          <Button isLoading={isSubmitting} type={'submit'} colorScheme="blue">
            {t('buttons.submit')}
          </Button>
        </ButtonGroup>
      </Stack>
    </form>
  );
};

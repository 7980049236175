import {
  Flex,
  HStack,
  Icon,
  Skeleton,
  StackDivider,
  TableColumnHeaderProps,
  TableContainer,
  TableContainerProps,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Nullable } from '@main/shared/types';
import { FilterMode } from '@main/shared/url-helpers';
import { useScheduler } from '@main/shared/utils';
import {
  ColumnDef,
  ColumnFiltersState,
  CoreOptions,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel as originalGetSortedRowModel,
  OnChangeFn,
  PaginationState,
  Row,
  RowData,
  RowModel,
  RowSelectionState,
  SortDirection,
  SortingState,
  Table as TanstackTable,
  TableMeta,
  useReactTable,
  VisibilityState,
} from '@tanstack/react-table';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { isSafari } from '../../utils';
import { useDrawer } from '../drawer';
import { EmptyPlaceholder } from '../placeholder';
import { FilterColumnMeta, getColumnCanGlobalFilter, globalFilterFn } from './filters';
import { shouldDisplayPagination } from './pagination';
import { ColumnTableAction, TableActionHandler, TableActionType } from './table-action';
import { TableCellColumnMeta } from './table-cell';
import { TableFilterResult } from './table-filter-result';
import { shouldDisplayMenu, TableMenu } from './table-menu';
import { useTableColumnSizing } from './use-table-column-sizing';

declare module '@tanstack/react-table' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface TableMeta<TData extends RowData> {
    query?: {
      variables?: object;
    };
  }
}

declare module '@tanstack/react-table' {
  interface ColumnMeta<TData, TValue> {
    name?: string;
    header?: TableColumnHeaderProps;
    cell?: TableCellColumnMeta<TData>;
    filter?: FilterColumnMeta<TData, TValue>;
    globalFilterFn?: (value: TValue, filterValue: string) => boolean;
    action?: ColumnTableAction<TData>;
    getGlobalFilterCondition?<TFilter>(filterValue: string): Nullable<TFilter>;
    getColumnFilterCondition?<TFilter>(
      filterMode: FilterMode,
      filterValue: unknown,
    ): Nullable<TFilter>;
    getColumnSort?<TSort>(sortDirection: SortDirection): TSort;
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface TableRegistry {}

export type TableEntity = TableRegistry extends { entities: infer E } ? E : string;

export type TableItem = {
  singular: string;
  plural: string;
  hideSubheading?: boolean;
  alternateSubheading?: boolean;
};

export type TableRowReorderHandler<T> = (
  fromRow: Row<T>,
  toRow: Row<T>,
  table: TanstackTable<T>,
) => void;

export type TableProps<T> = TableContainerProps & {
  data: T[];
  isLoading?: boolean;
  // not possible to use `unknown` with typed accessors
  // https://github.com/TanStack/table/issues/4241
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: ColumnDef<T, any>[];
  defaultColumnVisibility?: VisibilityState;
  columnVisibility?: VisibilityState;
  /**
   * When `onColumnVisibilityChange` callback is provided, the table will display the UI
   * with which user can modify table column's visibility state.
   */
  onColumnVisibilityChange?: OnChangeFn<VisibilityState>;
  pageSize?: number;
  onRowClick?: (row: Row<T>) => void;

  /**
   * `columnFilters` should be validated before passing to the table:
   * passing filters without matching columnd ids / with invalid structure will throw.
   * `isColumnFilter` / `isValidColumnFilter` predicates can be used for parsing filters
   * before passing them to the table
   */
  columnFilters?: ColumnFiltersState;
  onColumnFiltersChange?: OnChangeFn<ColumnFiltersState>;
  globalFilter?: string;
  onGlobalFilterChange?: OnChangeFn<string>;
  onFilteredDataChange?: (data: T[]) => void;
  itemName: TableItem;
  entity?: TableEntity;
  activeRow?: string;
  disableActiveRow?: boolean;
  getRowId?: CoreOptions<T>['getRowId'];
  rowCount?: number;
  manualFiltering?: boolean;
  manualPagination?: boolean;
  manualSorting?: boolean;
  pagination?: PaginationState;
  onPaginationChange?: OnChangeFn<PaginationState>;
  meta?: TableMeta<T>;
  sorting?: SortingState;
  onSortingChange?: OnChangeFn<SortingState>;
  minColumnSize?: number;
  onRowReorder?: TableRowReorderHandler<T>;
  getSortedRowModel?(table: TanstackTable<T>): () => RowModel<T>;
};

function EditableTable<T>({
  data,
  isLoading,
  columns,
  itemName,
  defaultColumnVisibility,
  columnVisibility,
  onColumnVisibilityChange,
  pageSize = Number.MAX_SAFE_INTEGER,
  columnFilters,
  globalFilter,
  entity,
  activeRow,
  disableActiveRow,
  onColumnFiltersChange,
  onGlobalFilterChange,
  onRowClick,
  onFilteredDataChange,
  getRowId,
  rowCount,
  manualFiltering,
  manualPagination,
  manualSorting,
  pagination,
  onPaginationChange,
  meta,
  sorting,
  onSortingChange,
  minColumnSize = 35,
  onRowReorder,
  getSortedRowModel,
  ...props
}: TableProps<T>) {
  disableActiveRow = useMemo(() => disableActiveRow ?? isSafari(), [disableActiveRow]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getRowId = useMemo(() => getRowId ?? ((row: any, i: number) => row.id ?? String(i)), [getRowId]);

  const tableName = testIdFromItemName(itemName.plural);
  const drawer = useDrawer();

  // Using row selection feature to track active row
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({
    ...(activeRow && { [activeRow]: true }),
  });
  const { schedule: scheduleHideActiveRow, cancel: cancelHideActiveRow } = useScheduler({
    fn: useCallback(() => setRowSelection({}), [setRowSelection]),
    delayMs: 1500,
  });

  const { columnSizing, onColumnSizingChange } = useTableColumnSizing(tableName);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel ?? originalGetSortedRowModel(),
    getColumnCanGlobalFilter: getColumnCanGlobalFilter,
    globalFilterFn: globalFilterFn,
    autoResetPageIndex: false,
    rowCount,
    manualFiltering,
    manualPagination,
    manualSorting,
    initialState: {
      columnVisibility: defaultColumnVisibility,
      pagination: {
        pageSize,
      },
    },
    getRowId,
    enableMultiRowSelection: false,
    state: {
      ...(columnVisibility && { columnVisibility }),
      ...(globalFilter && { globalFilter }),
      ...(columnFilters && { columnFilters }),
      ...(sorting && { sorting }),
      ...(pagination && { pagination }),
      rowSelection: disableActiveRow ? {} : rowSelection,
      columnSizing,
    },
    meta,
    columnResizeMode: 'onChange',
    defaultColumn: {
      size: undefined,
      minSize: minColumnSize,
      maxSize: undefined,
    },
    ...(onPaginationChange && { onPaginationChange }),
    ...(onSortingChange && { onSortingChange }),
    ...(onGlobalFilterChange && { onGlobalFilterChange }),
    ...(onColumnFiltersChange && { onColumnFiltersChange }),
    ...(onColumnVisibilityChange && { onColumnVisibilityChange }),
    onColumnSizingChange,
  });
  const state = table.getState();
  const filteredRows = table.getFilteredRowModel().rows;

  const actionHandler: TableActionHandler<T> = useCallback(
    (action, row) => {
      switch (action.type) {
        case TableActionType.HighlightRow:
          setRowSelection({ [row.id]: true });
          break;
      }
    },
    [setRowSelection],
  );

  // reset pagination when filters or sorting change
  useEffect(() => {
    table.setPageIndex(0);
  }, [table, state.globalFilter, state.columnFilters, state.sorting]);

  useEffect(() => {
    onFilteredDataChange && onFilteredDataChange(filteredRows.map((row) => row.original));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredRows]);

  // Highlight row opened in drawer
  useEffect(
    () =>
      entity && !disableActiveRow
        ? drawer.onDrawer((data) => {
            if (data?.entity === entity && data.entityId) {
              if (data.entityId in rowSelection === false) {
                cancelHideActiveRow();
                setRowSelection({ [data.entityId]: true });
              }
            } else if (Object.keys(rowSelection).length) {
              scheduleHideActiveRow();
            }
          })
        : undefined,
    [entity, drawer, rowSelection, disableActiveRow, scheduleHideActiveRow, cancelHideActiveRow],
  );

  useEffect(() => {
    const pagination = table.getState().pagination;
    const startingRow = pagination.pageSize * pagination.pageIndex;
    const rowCount = table.getRowCount();

    if (rowCount && startingRow >= rowCount) {
      table.previousPage();
    }
  }, [table]);

  return (
    <TableContainer
      pb="2px"
      minW={table.options.data.length || filteredRows.length ? props.minW : 'full'}
      {...props}
      className={shouldDisplayPagination(table) ? 'with-pagination' : 'without-pagination'}
      data-testid={tableName}
    >
      <VStack align="normal" spacing={0} divider={<StackDivider />}>
        {shouldDisplayMenu(table) && (
          <TableMenu table={table} canChangeColumnVisibility={!!onColumnVisibilityChange} />
        )}
        {isLoading ? (
          <TableLoadingState />
        ) : table.options.data.length === 0 ? (
          <TableEmptyState itemName={itemName} />
        ) : filteredRows.length === 0 ? (
          <TableEmptyFilterResult itemName={itemName} />
        ) : (
          <TableFilterResult
            itemName={itemName}
            table={table}
            minColumnSize={minColumnSize}
            onRowClick={onRowClick}
            actionHandler={actionHandler}
            setColumnSizing={onColumnSizingChange}
            onRowReorder={onRowReorder}
          />
        )}
      </VStack>
    </TableContainer>
  );
}

const TableLoadingState = () => {
  return (
    <VStack px={6} py={5} spacing={4}>
      {new Array(6).fill(0).map((_, index) => {
        return (
          <HStack w={'full'} spacing={4} key={index}>
            <Skeleton height="38px" minW="120px" />
            <Skeleton height="38px" w="full" />
          </HStack>
        );
      })}
    </VStack>
  );
};

const TableEmptyState = ({ itemName }: { itemName: TableItem }) => {
  const { t } = useTranslation('ui');

  return (
    <EmptyPlaceholder>
      <EmptyPlaceholder.Icon as={XMarkIcon} />
      <EmptyPlaceholder.Content>
        <EmptyPlaceholder.Heading>
          {t('table.empty.heading', { item: itemName.plural })}
        </EmptyPlaceholder.Heading>
        {!itemName.hideSubheading && (
          <EmptyPlaceholder.Subheading>
            {t(
              itemName.alternateSubheading
                ? 'table.empty.subheadingAlternate'
                : 'table.empty.subheading',
              {
                item: itemName.singular,
              },
            )}
          </EmptyPlaceholder.Subheading>
        )}
      </EmptyPlaceholder.Content>
    </EmptyPlaceholder>
  );
};

const TableEmptyFilterResult = ({ itemName }: { itemName: TableItem }) => {
  const { t } = useTranslation('ui');

  const outerBgColor = useColorModeValue('blue.50', 'blue.100');
  const innerBgColor = useColorModeValue('blue.100', 'blue.200');

  return (
    <Flex direction="column" alignItems="center" py={8}>
      <Flex
        w={12}
        h={12}
        justify="center"
        alignItems="center"
        bgColor={outerBgColor}
        rounded="full"
      >
        <Flex
          w={9}
          h={9}
          justify="center"
          alignItems="center"
          bgColor={innerBgColor}
          rounded="full"
        >
          <Icon w={6} h={6} as={MagnifyingGlassIcon} color="blue.600" />
        </Flex>
      </Flex>
      <Text fontWeight="semibold" mt={4}>
        {`${t('table.filter.notFoundHeading', { item: itemName.plural })}`}
      </Text>
      <Text mt={1} color="gray.500" fontSize="sm" width={'320px'} textAlign="center" as="span">
        {`${t('table.filter.notFoundSubheadingFirstLine', { item: itemName.singular })}`}
      </Text>
      <Text color="gray.500" fontSize="sm" width={'320px'} textAlign="center" as="span">
        {`${t('table.filter.notFoundSubheadingSecondLine', { item: itemName.singular })}`}
      </Text>
    </Flex>
  );
};

function testIdFromItemName(name: string) {
  return `${name.replace(/ /g, '-')}-table`;
}

// react's memo does not preserve generic component's type
// https://github.com/DefinitelyTyped/DefinitelyTyped/issues/37087
export const Table = memo(EditableTable) as typeof EditableTable;

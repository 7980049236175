import type { EditorThemeClasses } from 'lexical';

const theme: EditorThemeClasses = {
  ltr: 'ltr',
  rtl: 'rtl',
  placeholder: 'editor-placeholder',
  paragraph: 'editor-paragraph',
  heading: {
    h1: 'MarkdownEditorTheme__h1',
    h2: 'MarkdownEditorTheme__h2',
    h3: 'MarkdownEditorTheme__h3',
    h4: 'MarkdownEditorTheme__h4',
    h5: 'MarkdownEditorTheme__h5',
    h6: 'MarkdownEditorTheme__h6',
  },
  code: 'MarkdownEditorTheme__code',
  quote: 'MarkdownEditorTheme__quote',
  link: 'MarkdownEditorTheme__link',
  text: {
    bold: 'MarkdownEditorTheme__textBold',
    code: 'MarkdownEditorTheme__textCode',
    italic: 'MarkdownEditorTheme__textItalic',
    strikethrough: 'MarkdownEditorTheme__textStrikethrough',
    subscript: 'MarkdownEditorTheme__textSubscript',
    superscript: 'MarkdownEditorTheme__textSuperscript',
    underline: 'MarkdownEditorTheme__textUnderline',
    underlineStrikethrough: 'MarkdownEditorTheme__textUnderlineStrikethrough',
  },
  list: {
    listitem: 'MarkdownEditorTheme__listItem',
    listitemChecked: 'MarkdownEditorTheme__listItemChecked',
    listitemUnchecked: 'MarkdownEditorTheme__listItemUnchecked',
    nested: {
      listitem: 'MarkdownEditorTheme__nestedListItem',
    },
    olDepth: [
      'MarkdownEditorTheme__ol1',
      'MarkdownEditorTheme__ol2',
      'MarkdownEditorTheme__ol3',
      'MarkdownEditorTheme__ol4',
      'MarkdownEditorTheme__ol5',
    ],
    ul: 'MarkdownEditorTheme__ul',
  },
};

export default theme;

import { datadogLogs } from '@datadog/browser-logs';
import { toError } from '@main/shared/utils';
import { errorToast } from '@main/ui';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { getCurrentUserSelectedOrgId } from '../user/slice';
import { useLazyGetEvidenceInternalIdsQuery } from './Evidence.generated';
import { useLazyGetEvidenceTagsQuery } from './EvidenceTags.generated';

export function useSearchEvidenceTags() {
  const { t } = useTranslation();
  const organizationId = useAppSelector(getCurrentUserSelectedOrgId);
  const [getEvidenceTags] = useLazyGetEvidenceTagsQuery();

  return useCallback(
    async (inputValue = '') => {
      try {
        const data = await getEvidenceTags({
          organizationId,
          ...(inputValue.trim() && { nameFilter: { _ilike: `%${inputValue}%` } }),
        }).unwrap();
        return data.organization_evidence_tags;
      } catch (error) {
        errorToast(t('errorMessages.searchFailed', { entity: t('entities.tag') }));
        datadogLogs.logger.error('Failed to search evidence tags', { inputValue }, toError(error));
        return [];
      }
    },
    [t, organizationId, getEvidenceTags],
  );
}

export const useGetEvidenceInternalIds = () => {
  const { t } = useTranslation();
  const organizationId = useAppSelector(getCurrentUserSelectedOrgId);
  const [getEvidenceInternalIds] = useLazyGetEvidenceInternalIdsQuery();

  return useCallback(async () => {
    try {
      const data = await getEvidenceInternalIds({
        organizationId,
      }).unwrap();
      return data.evidences;
    } catch (error) {
      errorToast(
        t('errorMessages.searchFailed', { entity: t('evidences.table.columns.internalId') }),
      );
      datadogLogs.logger.error('Failed to fetch evidence internalIds', toError(error));
      return [];
    }
  }, [getEvidenceInternalIds, organizationId, t]);
};

import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { useGetReportByIdQuery } from '@main/graphql/queries/GetReportById.generated';
import { OverflowContainer } from '@main/ui';
import { useParams } from '@tanstack/react-router';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { CSVInput, exportToCSV, formatCSVInput } from '../../utils/export-csv';
import { getCurrentUserSelectedOrgRole } from '../user/slice';
import { EditReport } from './edit-report';
import { ReportCard } from './report-card';
import { getReportById } from './slice';

export const ReportDetails = () => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const params = useParams({ from: '/reports/$reportId' });
  const reportId = params.reportId;
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canEditReport = userRole.permissionMap?.write_reports;

  const { isLoading: isLoadingReport } = useGetReportByIdQuery({
    id: reportId,
  });

  const reportData = useAppSelector((state) => getReportById(state, reportId));

  const onExportCSV = (data: CSVInput[]) => {
    const fileName = `${reportData?.name}_${dayjs().format('YYYYMMDDHHmm')}`;
    exportToCSV(formatCSVInput(data), fileName);
  };

  if (isLoadingReport || !reportData) {
    return <Spinner />;
  }

  return (
    <Stack spacing={6}>
      <ReportHeader
        name={reportData.name || ''}
        description={reportData.description || ''}
        onNameEdit={canEditReport ? onOpen : undefined}
      />

      <ReportCard reportId={reportId} type={reportData.type} onExportCSV={onExportCSV} />

      <Modal isOpen={isOpen} onClose={onClose} isCentered size="xl" motionPreset="slideInBottom">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('reports.form.headingEdit')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody p={0}>
            <EditReport closeModal={onClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Stack>
  );
};

const ReportHeader = ({
  name,
  description,
  onNameEdit,
}: {
  name: string;
  description: string;
  onNameEdit?: () => void;
}) => {
  const subheadingColor = useColorModeValue('gray.700', 'gray.400');

  return (
    <Box>
      <OverflowContainer>
        <OverflowContainer.Tooltip label={name} fontSize="sm" openDelay={500}>
          <Text isTruncated fontWeight="semibold" fontSize="3xl">
            {name}
          </Text>
        </OverflowContainer.Tooltip>
        {onNameEdit && <OverflowContainer.EditTrigger onClick={onNameEdit} />}
      </OverflowContainer>
      <Text color={subheadingColor} fontSize="sm" noOfLines={1}>
        {description}
      </Text>
    </Box>
  );
};

import {
  Avatar,
  Box,
  Button,
  Flex,
  Icon,
  Spinner,
  Stack,
  Tag,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { ArrowRightIcon } from '@heroicons/react/24/outline';
import { AuditTableHasuraUser } from '@main/graphql/client-scalars';
import { formatDate, isNonNullable } from '@main/shared/utils';
import { getHashedAvatarColor } from '@main/ui';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { getCurrentOrgUsersMap } from '../user/slice';

const useGetUserNameForAction = (hasuraUser: AuditTableHasuraUser) => {
  const currentOrgUsersById = useAppSelector(getCurrentOrgUsersMap);
  let userName;

  if (hasuraUser['x-hasura-impersonater-id']) {
    userName = 'Complyance support';
  } else if (hasuraUser['x-hasura-user-id']) {
    userName = currentOrgUsersById[hasuraUser['x-hasura-user-id']]?.displayName;
  } else {
    userName = 'Complyance';
  }

  return userName;
};

const UserAction = ({
  hasuraUser,
  timestamp,
  children,
}: {
  hasuraUser: AuditTableHasuraUser;
  timestamp?: string;
  children: React.ReactNode;
}) => {
  const userName = useGetUserNameForAction(hasuraUser);

  return (
    <Flex gap={3}>
      <Avatar size="sm" name={userName} {...getHashedAvatarColor(userName)} />
      <Stack direction="column">
        {children}
        <Text fontSize="xs" color="gray.400">
          {formatDate(timestamp)}
        </Text>
      </Stack>
    </Flex>
  );
};

const UserName = ({ hasuraUser }: { hasuraUser: AuditTableHasuraUser }) => {
  const userName = useGetUserNameForAction(hasuraUser);

  return (
    <Text as="b" fontSize={'sm'}>
      {userName}
    </Text>
  );
};

export const TagActivityEntry = ({
  hasuraUser,
  timestamp,
  title,
  from,
  to,
  fromColorScheme = 'purple',
  toColorScheme = 'purple',
}: {
  hasuraUser: AuditTableHasuraUser;
  timestamp?: string;
  title: React.ReactNode;
  from: string;
  to: string;
  fromColorScheme?: string;
  toColorScheme?: string;
}) => {
  return (
    <UserAction hasuraUser={hasuraUser} timestamp={timestamp}>
      <Stack direction="row">
        <UserName hasuraUser={hasuraUser} />
        <Text fontSize={'sm'}>{title}</Text>
      </Stack>
      <Stack direction="row" alignItems="center">
        <Tag colorScheme={fromColorScheme}>{from}</Tag>
        <Icon as={ArrowRightIcon} color="gray.300" w={4} h={4} />
        <Tag colorScheme={toColorScheme}>{to}</Tag>
      </Stack>
    </UserAction>
  );
};

export const ActivityEntry = ({
  hasuraUser,
  timestamp,
  title,
}: {
  hasuraUser: AuditTableHasuraUser;
  timestamp?: string;
  title: React.ReactNode;
}) => {
  return (
    <UserAction hasuraUser={hasuraUser} timestamp={timestamp}>
      <Stack direction="row">
        <UserName hasuraUser={hasuraUser} />
        <Text fontSize={'sm'}>{title}</Text>
      </Stack>
    </UserAction>
  );
};

export const NoHistoryPlaceholder = () => {
  const { t } = useTranslation();

  return (
    <Stack
      w="full"
      height="10rem"
      border="1px"
      borderRadius={10}
      borderStyle="dashed"
      borderColor="gray.400"
      justifyContent="center"
      alignItems="center"
      spacing={0}
    >
      <Text fontSize="14px" lineHeight="24px" align="center">
        {t('noHistory')}
      </Text>
    </Stack>
  );
};

const pageSize = 30;

export const PaginatedHistoryList = <T extends { action_timestamp?: string; table_name: string }>({
  currentPageData,
  isLoadingHistory,
  getActivityNode,
  setPaginationParams,
}: {
  currentPageData: T[] | undefined;
  isLoadingHistory: boolean;
  getActivityNode: (activity: T) => React.ReactNode;
  setPaginationParams: ({ limit, offset }: { limit: number; offset: number }) => void;
}) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const pageRef = useRef(page);
  const isPaginationParamsInitialized = useRef(false);
  const [allHistory, setAllHistory] = useState<Record<number, T[]>>({});
  const loadMoreColor = useColorModeValue('gray.800', 'gray.200');

  useEffect(() => {
    isPaginationParamsInitialized.current = true;
    setPaginationParams({
      limit: pageSize,
      offset: page * pageSize,
    });
  }, [page, setPaginationParams]);

  useEffect(() => {
    setAllHistory((prevHistory) => ({
      ...prevHistory,
      [pageRef.current]: currentPageData ?? [],
    }));

    if (currentPageData && currentPageData.length < pageSize) {
      setHasMore(false);
    }
  }, [currentPageData]);

  const handleLoadMore = () => {
    setPage((prevPage) => {
      const newPage = prevPage + 1;
      pageRef.current = newPage;

      return newPage;
    });
  };

  const history = Object.values(allHistory)
    .flat()
    .map((activity, index) => {
      const activityNode = getActivityNode(activity);
      if (!activityNode) {
        return null;
      }

      return (
        <Box key={`${activity.action_timestamp}${activity.table_name}${index}`}>{activityNode}</Box>
      );
    });

  const isHistoryEmpty = !history?.filter(isNonNullable).length && !hasMore;

  if (!isPaginationParamsInitialized.current || (page === 0 && isLoadingHistory)) {
    return <Spinner />;
  }

  return (
    <Box>
      <Stack spacing={5}>{isHistoryEmpty ? <NoHistoryPlaceholder /> : history}</Stack>
      {hasMore && (
        <Box pl="10" pt="4">
          <Button
            variant="link"
            color={loadMoreColor}
            fontSize="sm"
            fontWeight="medium"
            onClick={handleLoadMore}
            isLoading={isLoadingHistory}
          >
            {t('loadMore')}
          </Button>
        </Box>
      )}
    </Box>
  );
};

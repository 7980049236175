import {
  Button,
  Card,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ThemingProps,
} from '@chakra-ui/react';
import { createColumnHelper, Table } from '@main/ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { getCurrentOrgUsersMap } from '../user/slice';
import { PolicyAcknowledgement } from './acknowledgements-tab';
import { getMappedPolicy } from './slice';

export const AcknowledgementUsersModal = ({
  policyId,
  acknowledgementId,
  isOpen,
  onClose,
}: {
  policyId: string;
  acknowledgementId: string;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const policy = useAppSelector((state) => getMappedPolicy(state, policyId));

  const acknowledgement = useMemo(
    () =>
      policy.acknowledgements.find((acknowledgement) => acknowledgement.id === acknowledgementId),
    [acknowledgementId, policy.acknowledgements],
  );

  const columns = useAcknowledgementUsersTableColumn();
  const tableItemName = useMemo(() => {
    return {
      singular: t('entities.acknowledgement').toLowerCase(),
      plural: t('entities.plural.acknowledgements').toLowerCase(),
    };
  }, [t]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('policies.acknowledgement.displayAcknowledgements')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Card variant={'table-styles'}>
            <Table
              minW="400px"
              data={acknowledgement?.policy_acknowledgement_users ?? []}
              columns={columns}
              itemName={tableItemName}
              pageSize={10}
            />
          </Card>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="gray" onClick={onClose}>
            {t('buttons.close')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

type PolicyAcknowledgementUser = PolicyAcknowledgement['policy_acknowledgement_users'][number];

function useAcknowledgementUsersTableColumn() {
  const { t } = useTranslation();
  const currentOrgUsers = useAppSelector(getCurrentOrgUsersMap);

  return useMemo(() => {
    const columnHelper = createColumnHelper<PolicyAcknowledgementUser>();
    return [
      columnHelper.columns.status({
        id: 'status',
        header: t('policies.acknowledgement.tableColumns.status'),
        accessorFn: ({
          acknowledged_at,
        }): { value: string; colorScheme: ThemingProps['colorScheme'] } => {
          if (acknowledged_at) {
            return {
              value: t('policies.acknowledgement.acknowledgementUsers.status.acknowledged'),
              colorScheme: 'green',
            };
          }

          return {
            value: t('policies.acknowledgement.acknowledgementUsers.status.pending'),
            colorScheme: 'gray',
          };
        },
        size: 200,
        enableColumnFilter: true,
        enableGlobalFilter: true,
        enableSorting: true,
      }),

      columnHelper.columns.avatar({
        id: 'userName',
        header: t('policies.acknowledgement.tableColumns.name'),
        accessorFn: ({ user_id }) => {
          const user = currentOrgUsers[user_id];
          if (!user) {
            return;
          }

          return {
            id: user.id,
            displayName: user.displayName,
          };
        },
        size: 400,
        variant: 'detailed',
        enableColumnFilter: true,
        enableGlobalFilter: true,
        enableSorting: true,
      }),

      columnHelper.columns.date({
        id: 'acknowledgedAt',
        header: t('policies.acknowledgement.tableColumns.acknowledgedAt'),
        accessorFn: ({ acknowledged_at }) => acknowledged_at,
        enableColumnFilter: true,
        enableGlobalFilter: true,
        enableSorting: true,
      }),
    ];
  }, [currentOrgUsers, t]);
}

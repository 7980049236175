import { StringOrNumberValueKeys } from './string-or-number-value-keys';

export function groupBy<T, K extends StringOrNumberValueKeys<T>>(
  array: T[],
  key: K,
): Record<string, T[]> {
  return array.reduce((accumulator: Record<string, T[]>, currentValue: T) => {
    const groupKey: string = currentValue[key] as unknown as string;

    if (!accumulator[groupKey]) {
      accumulator[groupKey] = [];
    }

    accumulator[groupKey]?.push(currentValue);

    return accumulator;
  }, {});
}

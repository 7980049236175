import {
  Button,
  ButtonGroup,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Textarea,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Report_Types_Enum } from '@main/graphql/types.generated';
import { Select } from '@main/ui';
import { FC } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as z from 'zod';

import { getReportTypeOption, getReportTypeOptions } from './report-type';

const reportSchema = z.object({
  reportName: z.string().min(1),
  reportType: z.nativeEnum(Report_Types_Enum),
  reportDescription: z.string().optional(),
});

export type ReportSchema = z.infer<typeof reportSchema>;

type ReportFormProps = {
  defaultValues?: ReportSchema;
  onSubmit: SubmitHandler<ReportSchema>;
  closeModal: () => void;
};

export const ReportForm: FC<ReportFormProps> = ({ defaultValues, onSubmit, closeModal }) => {
  const { t } = useTranslation();

  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isSubmitting },
  } = useForm<ReportSchema>({
    resolver: zodResolver(reportSchema),
    defaultValues: {
      ...defaultValues,
    },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={6} pb={4} pt={2} px={6}>
        <FormControl>
          <FormLabel>{t('reports.form.label.name')}</FormLabel>
          <Input
            {...register('reportName')}
            maxLength={200}
            isInvalid={!!errors.reportName}
            placeholder={t('reports.form.placeholder.name')}
          ></Input>
        </FormControl>

        <Controller
          name="reportType"
          control={control}
          render={({ field, fieldState }) => (
            <FormControl isInvalid={!!fieldState.error} isDisabled={!!defaultValues?.reportType}>
              <FormLabel>{t('reports.form.label.type')}</FormLabel>
              <Select
                {...field}
                options={getReportTypeOptions(t)}
                value={field.value && getReportTypeOption(t, field.value)}
                onChange={(value) => value && field.onChange(value.value)}
                placeholder={t('reports.form.placeholder.type')}
                useBasicStyles
              />
            </FormControl>
          )}
        />

        <FormControl>
          <FormLabel>{t('reports.form.label.description')}</FormLabel>
          <Textarea
            maxLength={5000}
            {...register('reportDescription')}
            placeholder={t('reports.form.placeholder.description')}
            isInvalid={!!errors.reportDescription}
          ></Textarea>
        </FormControl>
        <ButtonGroup display={'flex'} justifyContent="end" width="full" spacing={3}>
          <Button
            colorScheme={'gray'}
            onClick={() => {
              closeModal();
            }}
          >
            {t('buttons.cancel')}
          </Button>
          <Button isLoading={isSubmitting} type={'submit'} colorScheme="blue">
            {t('buttons.submit')}
          </Button>
        </ButtonGroup>
      </Stack>
    </form>
  );
};

import { FormControl, FormLabel } from '@chakra-ui/react';
import { useGetEvidenceByIdQuery } from '@main/graphql/queries/GetEvidenceById.generated';
import { successToast } from '@main/ui';
import { useTranslation } from 'react-i18next';

import { PolicyReferenceCard } from '../policies/policy-reference-card';
import { useDeleteEvidencePolicyByIdMutation } from './evidence-references.generated';
import { useUpdateEvidenceOnDrawer } from './use-update-evidence-handler';

export const EvidenceReferences = ({ evidenceId }: { evidenceId: string }) => {
  const { t } = useTranslation();

  const { data } = useGetEvidenceByIdQuery({ evidence_id: evidenceId });
  const evidencePolicy = data?.evidences_by_pk?.evidence_policy;

  const [updateEvidenceHandler] = useUpdateEvidenceOnDrawer({ evidenceId });
  const [deleteEvidencePolicyById] = useDeleteEvidencePolicyByIdMutation();

  if (!evidencePolicy) {
    return null;
  }

  const onEvidencePolicyUnlink = async () => {
    const result = await updateEvidenceHandler(
      deleteEvidencePolicyById({
        evidencePolicyId: evidencePolicy.id,
      }),
    );
    if (result) {
      successToast(t('successMessages.unlinkSucceeded', { entity: t('entities.policy') }));
    }
  };

  return (
    <FormControl>
      <FormLabel>{t('evidences.references')}:</FormLabel>
      <PolicyReferenceCard
        id={evidencePolicy.policy.id}
        name={evidencePolicy.policy.name}
        onUnlink={onEvidencePolicyUnlink}
      />
    </FormControl>
  );
};

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type UpdateNotificationMutationVariables = Types.Exact<{
  notificationIds: Array<Types.Scalars['uuid']['input']> | Types.Scalars['uuid']['input'];
  input: Types.User_Notifications_Set_Input;
}>;

export type UpdateNotificationMutation = {
  __typename?: 'mutation_root';
  update_user_notifications?: {
    __typename?: 'user_notifications_mutation_response';
    affected_rows: number;
  };
};

export const UpdateNotificationDocument = `
    mutation UpdateNotification($notificationIds: [uuid!]!, $input: user_notifications_set_input!) {
  update_user_notifications(where: {id: {_in: $notificationIds}}, _set: $input) {
    affected_rows
  }
}
    ` as string &
  TypedDocumentNode<UpdateNotificationMutation, UpdateNotificationMutationVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    UpdateNotification: build.mutation<
      UpdateNotificationMutation,
      UpdateNotificationMutationVariables
    >({
      query: (variables) => ({ document: UpdateNotificationDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useUpdateNotificationMutation } = injectedRtkApi;

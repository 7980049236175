import { ControlEvidenceStatus } from '@main/graphql/client-scalars';
import { Severity } from '@main/shared/utils';

export type EvidenceFindingType = 'atRiskEvidence' | 'expiredEvidence';

export function getEvidenceFindingFromStatus(status: ControlEvidenceStatus): {
  severity: Severity;
  type: EvidenceFindingType;
} | null {
  switch (status) {
    case ControlEvidenceStatus.AT_RISK:
      return {
        severity: Severity.MEDIUM,
        type: 'atRiskEvidence',
      };
    case ControlEvidenceStatus.INVALID:
      return {
        severity: Severity.HIGH,
        type: 'expiredEvidence',
      };
    default:
      return null;
  }
}

import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

import { createColorModePicker } from '../theme/utils';

const helpers = createMultiStyleConfigHelpers(['table', 'th', 'td', 'tr']);

export const Table = helpers.defineMultiStyleConfig({
  variants: {
    brand: (props) => {
      const { colorScheme: c, theme } = props;
      const mode = createColorModePicker(props.colorMode);
      const borderColor = mode(`${c}.200`, `${c}.600`);

      return {
        table: {
          tableLayout: 'fixed',
        },

        th: {
          color: mode('gray.500', 'gray.400'),
          backgroundColor: mode(`${c}.25`, `${c}.700`),
          borderBottom: '1px',
          borderColor,
          fontWeight: 'medium',
          textTransform: 'none',
          letterSpacing: 'normal',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          px: 4,
          py: 3,
        },

        td: {
          p: 0,
          backgroundClip: 'padding-box',

          '+ td': {
            borderLeft: '1px',
            borderColor,
          },
        },

        tr: {
          '+ tr': {
            borderTop: '1px',
            borderTopColor: `${borderColor} !important`,
          },

          '.highlight': {
            display: 'none',
            position: 'absolute',
            inset: 0,
            pointerEvents: 'none',
            boxShadow: `inset 0 0 0 2px ${theme['colors'].blue[200]}`,
            zIndex: 1,
          },

          '&.selected': {
            '.highlight': {
              display: 'block',
            },

            '.without-pagination &:last-of-type .highlight': {
              borderBottomLeftRadius: 'var(--card-radius)',
              borderBottomRightRadius: 'var(--card-radius)',
            },
          },

          '&:nth-of-type(even)': {
            td: {
              backgroundColor: mode(`${c}.25`, `${c}.700`),
            },
          },
        },
      };
    },
    grouped: (props) => {
      const { colorScheme: c } = props;
      const mode = createColorModePicker(props.colorMode);

      return {
        table: {
          tableLayout: 'fixed',
        },

        th: {
          color: mode('gray.500', 'gray.400'),
          backgroundColor: mode('white', `${c}.700`),
          fontWeight: 'medium',
          textTransform: 'none',
          letterSpacing: 'normal',
          px: 4,
          py: 4,
          minW: 'auto',
        },
        td: {
          color: mode(`${c}.600`, `${c}.400`),
          lineHeight: '5',
          fontSize: 'sm',
        },
        tr: {
          backgroundColor: mode('white', `${c}.700`),
          py: 4,
        },
      };
    },
  },
});

import { useGetOrganizationControlsQuery } from '@main/graphql/queries/GetOrganizationControls.generated';
import { useGetOrganizationControlStatusesSubscription } from '@main/graphql/subscriptions/GetOrganizationControlStatusesSubscription';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { getOrgControls } from './slice';

export function useGetOrganizationControls(organizationId: string) {
  useGetOrganizationControlStatusesSubscription({ organizationId });
  const { isLoading } = useGetOrganizationControlsQuery(
    { organizationId },
    { pollingInterval: 5000 },
  );
  const controls = useAppSelector(getOrgControls);
  return {
    controls,
    isLoading,
  };
}

import { Interpolation } from '@emotion/react';

export const hideScrollbar = (): Interpolation<{}> => {
  return {
    /* For Chrome and Safari */
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    /* For Firefox */
    scrollbarWidth: 'none',
  };
};

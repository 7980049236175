export function createEnumTabsControl<T>(values: T[]) {
  return (value: T, onChange: (value: T) => void) => {
    return {
      index: Math.max(0, values.indexOf(value)),

      onChange: (index: number) => {
        const value = values[index];
        if (value) {
          onChange(value);
        }
      },
    };
  };
}

import { Route } from '@tanstack/react-router';

import { AlertCenter } from '../../features/alerts/alert-center';
import { authLayout } from '../login';

export const alertRoute = new Route({
  getParentRoute: () => authLayout,
  path: 'alerts',
  component: AlertCenter,
});

import { ControlAuditRow } from '@main/graphql/client-scalars';
import { exhaustiveCheck, hasProperty } from '@main/shared/utils';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { CONTROL_STATUSES } from '../../utils/constants';
import { ActivityEntry, PaginatedHistoryList, TagActivityEntry } from '../activity-history/shared';
import { getCurrentOrgUsersMap } from '../user/slice';
import {
  GetControlActivityHistoryQuery,
  useGetControlActivityHistoryQuery,
} from './get-control.generated';

type Activity = GetControlActivityHistoryQuery['audit_control_history_view'][number] &
  ControlAuditRow;

export const ControlHistoryTab: FC<{ controlId: string }> = ({ controlId }) => {
  const { t } = useTranslation();
  const [paginationParams, setPagination] = useState<{ limit: number; offset: number }>();
  const { data: activityData, isLoading: isLoadingHistory } = useGetControlActivityHistoryQuery(
    {
      control_id: controlId,
      ...paginationParams,
    },
    {
      skip: !paginationParams,
      refetchOnMountOrArgChange: true,
    },
  );
  const currentOrgUsersById = useAppSelector(getCurrentOrgUsersMap);

  const getActivityNode = (activity: Activity) => {
    switch (activity.table_name) {
      case 'controls': {
        switch (activity.action) {
          case 'I': {
            return (
              <ActivityEntry
                hasuraUser={activity.hasura_user}
                timestamp={activity.action_timestamp}
                title={t('controls.history.controlAdded')}
              />
            );
          }
          case 'U': {
            if (hasProperty(activity.changed_fields, 'assignee_id')) {
              const oldId = activity.row_data.assignee_id;
              const newId = activity.changed_fields.assignee_id;

              return (
                <TagActivityEntry
                  hasuraUser={activity.hasura_user}
                  timestamp={activity.action_timestamp}
                  title={t('controls.history.assigneeChanged')}
                  from={oldId ? currentOrgUsersById[oldId]?.displayName ?? 'N/A' : 'N/A'}
                  to={newId ? currentOrgUsersById[newId]?.displayName ?? 'N/A' : 'N/A'}
                />
              );
            }

            if (hasProperty(activity.changed_fields, 'frequency')) {
              const oldFrequency = activity.row_data.frequency;
              const newFrequency = activity.changed_fields.frequency;

              return (
                <TagActivityEntry
                  hasuraUser={activity.hasura_user}
                  timestamp={activity.action_timestamp}
                  title={t('controls.history.changedFrequency')}
                  from={
                    oldFrequency ? t(`controls.frequency.enums.${oldFrequency}`) ?? 'N/A' : 'N/A'
                  }
                  to={newFrequency ? t(`controls.frequency.enums.${newFrequency}`) ?? 'N/A' : 'N/A'}
                />
              );
            }

            if (hasProperty(activity.changed_fields, 'status')) {
              const oldStatus = activity.row_data.status;
              const newStatus = activity.changed_fields.status;

              return (
                <TagActivityEntry
                  hasuraUser={activity.hasura_user}
                  timestamp={activity.action_timestamp}
                  title={t('controls.history.changedStatus')}
                  from={oldStatus ? t(`controls.status.enums.${oldStatus}`) ?? 'N/A' : 'N/A'}
                  fromColorScheme={CONTROL_STATUSES[oldStatus].colorScheme}
                  to={newStatus ? t(`controls.status.enums.${newStatus}`) ?? 'N/A' : 'N/A'}
                  toColorScheme={CONTROL_STATUSES[newStatus].colorScheme}
                />
              );
            }

            return null;
          }
          case 'D':
          case 'T': {
            return null;
          }
          default:
            return exhaustiveCheck(activity.action);
        }
      }

      case 'comments': {
        switch (activity.action) {
          case 'I': {
            return (
              <ActivityEntry
                hasuraUser={activity.hasura_user}
                timestamp={activity.action_timestamp}
                title={t('controls.history.commentAdded')}
              />
            );
          }
          case 'D': {
            return (
              <ActivityEntry
                hasuraUser={activity.hasura_user}
                timestamp={activity.action_timestamp}
                title={t('controls.history.commentDeleted')}
              />
            );
          }
          case 'U':
          case 'T': {
            return null;
          }
          default:
            return exhaustiveCheck(activity.action);
        }
      }

      case 'control_evidences': {
        switch (activity.action) {
          case 'I': {
            return (
              <ActivityEntry
                hasuraUser={activity.hasura_user}
                timestamp={activity.action_timestamp}
                title={t('controls.history.linkedEvidence')}
              />
            );
          }
          case 'D': {
            return (
              <ActivityEntry
                hasuraUser={activity.hasura_user}
                timestamp={activity.action_timestamp}
                title={t('controls.history.unlinkedEvidence')}
              />
            );
          }
          case 'U':
          case 'T': {
            return null;
          }
          default:
            return exhaustiveCheck(activity.action);
        }
      }
    }
  };

  return (
    <PaginatedHistoryList
      setPaginationParams={setPagination}
      currentPageData={activityData?.audit_control_history_view as Activity[]}
      isLoadingHistory={isLoadingHistory}
      getActivityNode={getActivityNode}
    />
  );
};

import { useEffect, useState } from 'react';

import {
  AppGlobalSearchResult,
  useGlobalSearch,
  UseGlobalSearchProps,
} from '../global-search/use-global-search';
import { EntitySearchProps } from './entity-search';
import { EntitySearchResultCard } from './entity-search-result-card';

export function useEntitySearch(props: UseGlobalSearchProps): EntitySearchProps {
  const globalSearch = useGlobalSearch(props);
  const [selectedResults, setSelectedResults] = useState<AppGlobalSearchResult[]>([]);

  useEffect(() => setSelectedResults([]), [globalSearch.disclosure.isOpen]);

  function onResultsSelect(results: AppGlobalSearchResult[]) {
    setSelectedResults(results);
  }

  return {
    ...globalSearch,
    provider: {
      ...globalSearch.provider,
      onResultsSelect,
      selectedResults,
      /* We don't want to preserve previous state when modal is re-opened */
      initialSearch: undefined,
    },
    results: {
      ...globalSearch.results,
      renderResult: EntitySearchResultCard,
    },
    selectedResults,
  };
}

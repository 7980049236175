type AsString<T> = T extends string ? T : never;

/**
 * Create an index hash table for an array of objects using id key.
 */
export function hashArrayIndexes<T, K extends keyof T, R extends string = AsString<T[K]>>(
  array: T[],
  idKey: K,
): Record<R, number> {
  const indexHashTable: Record<string, number> = {};

  array.forEach((value, i) => {
    indexHashTable[String(value[idKey])] = i;
  });

  return indexHashTable;
}

/**
 * Create an index hash table for an array of objects by key `id`.
 * @see {@link hashArrayIndexes}
 */
export function hashArrayIndexesById(array: { id: string }[]): Record<string, number> {
  return hashArrayIndexes(array, 'id');
}

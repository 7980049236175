import { Order_By } from '@main/graphql/types.generated';
import { SortDirection } from '@tanstack/react-table';

export function hasuraSortDirection(sortDirection: SortDirection) {
  switch (sortDirection) {
    case 'asc':
      return Order_By.Asc;
    case 'desc':
      return Order_By.Desc;
  }
}

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { CommentsFragmentFragmentDoc } from '../comments/comments.fragment.generated';
import { FieldValueFragmentDoc } from '../../../../../libs/graphql/src/fragments/FieldValueFragments.generated';
import { api } from '@main/graphql/shared-api';
export type GetRiskQueryVariables = Types.Exact<{
  risk_id: Types.Scalars['uuid']['input'];
}>;

export type GetRiskQuery = {
  __typename?: 'query_root';
  risks_by_pk?: {
    __typename?: 'risks';
    id: string;
    name?: string;
    last_reviewed_date?: string;
    description?: string;
    internal_id?: string;
    treatment_details?: string;
    treatment_plan: Types.Treatment_Plan_Enum;
    assessment_status: ClientTypes.RiskAssessmentStatus;
    organization_id: string;
    review_frequency: Types.Frequencies_Enum;
    assignee?: { __typename?: 'users'; displayName: string; avatarUrl: string; id: string };
    risks_comments: Array<{
      __typename?: 'comments';
      id: string;
      text: string;
      created_at: string;
      updated_at: string;
      user_id: string;
      comments_comment_uploads: Array<{
        __typename?: 'comment_uploads';
        id: string;
        comment_uploads_file: {
          __typename?: 'files';
          id: string;
          name?: string;
          size?: number;
          createdAt: string;
        };
      }>;
    }>;
    documents: Array<{
      __typename?: 'risk_documents';
      file?: { __typename?: 'files'; id: string; name?: string; size?: number };
    }>;
    risk_controls: Array<{
      __typename?: 'risk_controls';
      id: string;
      is_control_primary: boolean;
      control: {
        __typename?: 'controls';
        id: string;
        internal_id?: string;
        status: ClientTypes.ControlStatus;
        name?: string;
      };
    }>;
    tags: Array<{
      __typename?: 'risk_tags';
      id: string;
      tag: { __typename?: 'organization_risk_tags'; id: string; name: string };
    }>;
    categories: Array<{
      __typename?: 'risk_categories';
      id: string;
      category: { __typename?: 'organization_risk_categories'; id: string; name: string };
    }>;
    inherent_impact?: { __typename?: 'risk_impacts'; name: string };
    inherent_likelihood?: { __typename?: 'risk_likelihoods'; name: string };
    residual_impact?: { __typename?: 'risk_impacts'; name: string };
    residual_likelihood?: { __typename?: 'risk_likelihoods'; name: string };
    field_values: Array<{
      __typename?: 'field_values';
      field_config_id: string;
      entity_name: Types.Field_Entities_Enum;
      field_type: Types.Field_Types_Enum;
      text_field_value?: {
        __typename?: 'text_field_values';
        field_value_id: string;
        value: string;
      };
      select_field_value?: {
        __typename?: 'select_field_values';
        field_value_id: string;
        select_field_option_id: string;
      };
    }>;
  };
};

export const GetRiskDocument = `
    query GetRisk($risk_id: uuid!) {
  risks_by_pk(id: $risk_id) {
    id
    name
    last_reviewed_date
    description
    internal_id
    assignee {
      displayName
      avatarUrl
      id
    }
    treatment_details
    treatment_plan
    assessment_status
    organization_id
    review_frequency
    risks_comments(order_by: {created_at: asc}) {
      ...CommentsFragment
    }
    documents {
      file {
        id
        name
        size
      }
    }
    risk_controls {
      id
      is_control_primary
      control {
        id
        internal_id
        status
        name
      }
    }
    tags {
      id
      tag {
        id
        name
      }
    }
    categories {
      id
      category {
        id
        name
      }
    }
    inherent_impact {
      name
    }
    inherent_likelihood {
      name
    }
    residual_impact {
      name
    }
    residual_likelihood {
      name
    }
    field_values {
      ...FieldValue
    }
  }
}
    ${CommentsFragmentFragmentDoc}
${FieldValueFragmentDoc}` as string & TypedDocumentNode<GetRiskQuery, GetRiskQueryVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetRisk: build.query<GetRiskQuery, GetRiskQueryVariables>({
      query: (variables) => ({ document: GetRiskDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetRiskQuery, useLazyGetRiskQuery } = injectedRtkApi;

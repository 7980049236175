import { Flex, useColorModeValue, Wrap } from '@chakra-ui/react';

import { selectValueToArray } from '../../combobox';
import { defaultProps, EditableStatus, StatusOption } from '../../editable/editable-status';
import { OverflowContainer } from '../../overflow-tooltip';
import { CellContainer } from '../shared/cell';
import { createSelectHelper } from '../shared/select';

export const status = createSelectHelper<StatusOption>({
  getOptionValue: defaultProps.getOptionValue,
  getOptionLabel: defaultProps.getOptionLabel,

  components: {
    Preview: ({ value }) => {
      const tooltipLabel = (
        <Wrap py={2} px={1}>
          {selectValueToArray(value).map((data, i) => (
            <EditableStatus.Option key={`${data.value}-${i}`} {...data} />
          ))}
        </Wrap>
      );
      const tooltipBgColor = useColorModeValue('gray.700', 'gray.900');

      return (
        <CellContainer p={0} data-group>
          <OverflowContainer>
            <OverflowContainer.Tooltip
              label={tooltipLabel}
              placement="bottom-start"
              fontSize="sm"
              openDelay={500}
              bgColor={tooltipBgColor}
            >
              <Flex gap={2} maxW="100%" p={4}>
                {selectValueToArray(value).map((data, i) => (
                  <EditableStatus.Option key={`${data.value}-${i}`} {...data} />
                ))}
              </Flex>
            </OverflowContainer.Tooltip>
          </OverflowContainer>
        </CellContainer>
      );
    },

    Edit: EditableStatus,
  },
});

import { HStack, Tag } from '@chakra-ui/react';
import { ChatBubbleLeftRightIcon, ListBulletIcon } from '@heroicons/react/24/outline';
import { TaskTabKeys } from '@main/shared/utils';
import { Drawer, EditableAutoResizeTextarea, getVisibleTabs, StatusTag } from '@main/ui';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { CommentsTab } from '../comments/comments-tab';
import { getCurrentUserSelectedOrgRole } from '../user/slice';
import { TASK_STATUSES } from './constants';
import { TaskDetailsTab } from './details-tab';
import { useGetTaskQuery } from './get-task.generated';
import { useUpdateTaskByIdMutation } from './manage-tasks.generated';
import { useGetTaskSubscription } from './task-subscription';
import { useTaskDrawerActions } from './use-drawer-actions';
import { useUpdateTaskHandler } from './use-update-task-handler';

type TaskDrawerProps = {
  taskId: string;
};

export const TaskDrawer: FC<TaskDrawerProps> = ({ taskId }) => {
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canViewTask = userRole.permissionMap?.read_tasks;
  const { data: taskData, isLoading: isTasksLoading } = useGetTaskSubscription({ taskId });

  const { tabs } = useTaskDrawerTabs(taskId);
  const { primaryAction, secondaryActions, menuActions } = useTaskDrawerActions(taskId);

  return (
    <Drawer.Layout
      isLoading={isTasksLoading}
      canView={canViewTask}
      isNotFound={!taskData?.tasks_by_pk}
    >
      <Drawer.Toolbar
        primaryAction={primaryAction}
        secondaryActions={secondaryActions}
        menuActions={menuActions}
      />
      <Drawer.Tabs tabs={tabs}>
        <TaskDrawerHeader taskId={taskId} />
      </Drawer.Tabs>
    </Drawer.Layout>
  );
};

const TaskDrawerHeader = ({ taskId }: { taskId: string }) => {
  const { t } = useTranslation();
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canEditTask = userRole.permissionMap?.write_tasks;

  const updateTaskHandler = useUpdateTaskHandler();
  const [updateTask] = useUpdateTaskByIdMutation();

  const { data: taskData } = useGetTaskSubscription({ taskId });
  const task = taskData?.tasks_by_pk;

  if (!task) {
    return null;
  }

  return (
    <>
      <HStack mb={4}>
        <Tag colorScheme="purple">
          {t('entities.task')} / {task.internal_id}
        </Tag>
        <StatusTag
          size="sm"
          minW="auto"
          maxW="none"
          colorScheme={TASK_STATUSES[task.status].colorScheme}
        >
          {t(`tasks.enum.status.${task.status}`)}
        </StatusTag>
      </HStack>

      <EditableAutoResizeTextarea
        isDisabled={!canEditTask}
        ml={-2}
        fontSize={'xl'}
        fontWeight={'bold'}
        defaultValue={task?.name}
        placeholder={t('tasks.name')}
        onSubmit={(value) => {
          updateTaskHandler(
            updateTask({
              taskId,
              updatePayload: { name: value },
            }),
          );
        }}
      />
    </>
  );
};

const useTaskDrawerTabs = (taskId: string) => {
  const { t } = useTranslation();
  const { comments, refetch } = useGetTaskQuery(
    { taskId },
    { selectFromResult: ({ data }) => ({ comments: data?.tasks_by_pk?.tasks_comments ?? [] }) },
  );

  const refetchComments = useCallback(async () => {
    await refetch();
  }, [refetch]);

  const tabs = useMemo(() => {
    return getVisibleTabs<TaskTabKeys>({
      details: {
        label: t('tasks.tabs.details'),
        icon: ListBulletIcon,
        panel: <TaskDetailsTab taskId={taskId} />,
        hideTabTitle: true,
      },

      comments: {
        label: t('tasks.tabs.comments'),
        icon: ChatBubbleLeftRightIcon,
        panel: (
          <CommentsTab
            entity="task"
            entityId={taskId}
            comments={comments}
            refetchComments={refetchComments}
          />
        ),
      },
    });
  }, [refetchComments, t, comments, taskId]);

  return { tabs };
};

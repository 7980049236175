import { Field_Entities_Enum } from '@main/graphql/types.generated';

export function underEntityIdKey<T>(entityName: Field_Entities_Enum, value: T) {
  switch (entityName) {
    case Field_Entities_Enum.Risk:
      return { risk_id: value };
    case Field_Entities_Enum.Vendor:
      return { vendor_id: value };
    case Field_Entities_Enum.Control:
      return { control_id: value };
    case Field_Entities_Enum.Task:
      return { task_id: value };
    case Field_Entities_Enum.Policy:
      return { policy_id: value };
  }
}

import { FilterMode } from '@main/shared/url-helpers';
import { rangeToDates } from '@main/ui';

export function filterModeInComparison<T>(filterMode: FilterMode, filterValues: T[]) {
  switch (filterMode) {
    case FilterMode.Includes:
      return { _in: filterValues };
    case FilterMode.Excludes:
      return { _nin: filterValues };
    default:
      return undefined;
  }
}

export function filterModeILikeComparison(filterMode: FilterMode, filterValue: string) {
  switch (filterMode) {
    case FilterMode.Includes:
      return { _ilike: `%${filterValue}%` };
    case FilterMode.Excludes:
      return { _nilike: `%${filterValue}%` };
    default:
      return undefined;
  }
}

export function filterModeNegateCondition<T>(filterMode: FilterMode, condition: T) {
  switch (filterMode) {
    case FilterMode.Includes:
      return condition;
    case FilterMode.Excludes:
      return { _not: condition };
    default:
      return undefined;
  }
}

export function filterModeOrNullCondition<T>(
  filterMode: FilterMode,
  getCondition: (isNullComparison?: { _is_null: true }) => T,
) {
  switch (filterMode) {
    case FilterMode.Includes:
      return getCondition();
    case FilterMode.Excludes:
      return {
        _or: [getCondition({ _is_null: true }), getCondition()],
      };
    default:
      return undefined;
  }
}

export function filterModeDateRangeComparison(filterMode: FilterMode, range: (string | null)[]) {
  const [first, second] = range;

  if (!first) {
    return;
  }

  const [firstDate, secondDate] = rangeToDates(first, second);

  const firstValue = firstDate.toISOString();
  const secondValue = secondDate.toISOString();

  switch (filterMode) {
    case FilterMode.Includes:
      return { _gte: firstValue, _lte: secondValue };
    case FilterMode.Before:
      return { _lte: secondValue };
    case FilterMode.After:
      return { _gte: firstValue };
    case FilterMode.Between:
      return second ? { _gte: firstValue, _lte: secondValue } : undefined;
    default:
      return undefined;
  }
}

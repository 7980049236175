/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type UpdateEvidenceVersionByIdMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
  updatePayload: Types.Evidence_Versions_Set_Input;
}>;

export type UpdateEvidenceVersionByIdMutation = {
  __typename?: 'mutation_root';
  update_evidence_versions_by_pk?: { __typename?: 'evidence_versions'; id: string };
};

export const UpdateEvidenceVersionByIdDocument = `
    mutation UpdateEvidenceVersionById($id: uuid!, $updatePayload: evidence_versions_set_input!) {
  update_evidence_versions_by_pk(pk_columns: {id: $id}, _set: $updatePayload) {
    id
  }
}
    ` as string &
  TypedDocumentNode<UpdateEvidenceVersionByIdMutation, UpdateEvidenceVersionByIdMutationVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    UpdateEvidenceVersionById: build.mutation<
      UpdateEvidenceVersionByIdMutation,
      UpdateEvidenceVersionByIdMutationVariables
    >({
      query: (variables) => ({ document: UpdateEvidenceVersionByIdDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useUpdateEvidenceVersionByIdMutation } = injectedRtkApi;

import { datadogLogs } from '@datadog/browser-logs';
import { toError } from '@main/shared/utils';
import { errorToast, successToast, useAlertDialog } from '@main/ui';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../hooks/redux-toolkit-hooks';
import { getCurrentUserSelectedOrgRole } from '../../user/slice';
import { useDeteleVendorQuestionnaireMutation } from './vendor-questionnaires.generated';

export function useDeleteVendorQuestionnaire() {
  const { t } = useTranslation();
  const { openDialog } = useAlertDialog();

  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canDeleteVendor = !!userRole.permissionMap?.write_vendors;

  const [deteleVendorQuestionnaire] = useDeteleVendorQuestionnaireMutation();

  const deleteVendorQuestionnaire = useCallback(
    (vendorQuestionnaireId: string, onDelete?: () => void) => {
      openDialog({
        dialogHeader: t('vendors.questionnaires.deleteAlert.header'),
        dialogContent: t('vendors.questionnaires.deleteAlert.content'),
        confirmAction: {
          children: t('buttons.delete'),
          onClick: async () => {
            try {
              await deteleVendorQuestionnaire({ vendorQuestionnaireId }).unwrap();
              successToast(t('vendors.questionnaires.toasts.deleteSuccess'));
              onDelete?.();
            } catch (error) {
              errorToast(t('vendors.questionnaires.toasts.deleteFailed'));
              datadogLogs.logger.error(
                'Vendor questionnaire deletion failed',
                { vendorQuestionnaireId },
                toError(error),
              );
            }
          },
        },
      });
    },
    [openDialog, t, deteleVendorQuestionnaire],
  );

  return canDeleteVendor ? deleteVendorQuestionnaire : null;
}

import { CheckIcon, TrashIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useUpdateVendorMutation } from '@main/graphql/mutations/UpdateVendor.generated';
import { isNonNullable } from '@main/shared/utils';
import { useAlertDialog, useDrawer } from '@main/ui';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { getCurrentUserSelectedOrgRole } from '../user/slice';
import { useDeleteVendor } from './delete-vendor';
import { getMappedVendor } from './slice';
import { useUpdateVendorHandler } from './use-udpate-vendor-handler';

export const useVendorDrawerActions = (vendorId: string) => {
  const deleteVendor = useVendorDeleteAction(vendorId);
  const vendor = useAppSelector((state) => getMappedVendor(state, vendorId));
  const terminateVendor = useLazyVendorTerminateAction()({
    vendorId,
    terminated_at: vendor?.terminated_at,
  });

  return {
    menuActions: [terminateVendor, deleteVendor].filter(isNonNullable),
  };
};

export function useLazyVendorTerminateAction() {
  const { t } = useTranslation();
  const { openDialog } = useAlertDialog();

  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canUpdateVendor = userRole.permissionMap?.write_vendors;
  const updateVendorHandler = useUpdateVendorHandler();

  const [updateVendor] = useUpdateVendorMutation();

  return useCallback(
    ({ vendorId, terminated_at }: { vendorId: string; terminated_at: string | undefined }) => {
      if (!canUpdateVendor) {
        return;
      }

      if (!terminated_at) {
        return {
          icon: <XMarkIcon />,
          label: t('buttons.retire'),
          onClick: () => {
            openDialog({
              dialogHeader: t('vendors.retire.header'),
              dialogContent: t('vendors.retire.content'),
              confirmAction: {
                children: t('buttons.retire'),
                onClick: async () => {
                  updateVendorHandler(
                    updateVendor({
                      id: vendorId,
                      vendor_input: {
                        terminated_at: 'now()',
                      },
                    }),
                  );
                },
              },
            });
          },
        };
      }

      return {
        icon: <CheckIcon />,
        label: t('buttons.reinstate'),
        onClick: () => {
          updateVendorHandler(
            updateVendor({
              id: vendorId,
              vendor_input: {
                terminated_at: null,
              },
            }),
          );
        },
      };
    },
    [canUpdateVendor, openDialog, t, updateVendor, updateVendorHandler],
  );
}

function useVendorDeleteAction(vendorId: string) {
  const { t } = useTranslation();

  const drawer = useDrawer();
  const deleteVendor = useDeleteVendor();

  if (!deleteVendor) {
    return null;
  }

  return {
    icon: <TrashIcon />,
    label: t('buttons.delete'),
    onClick: () => {
      deleteVendor(vendorId, () => drawer.close());
    },
  };
}

import { datadogLogs } from '@datadog/browser-logs';
import { toError } from '@main/shared/utils';
import { errorToast } from '@main/ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useUnlinkControlTaskMutation } from '../controls/tasks-tab.generated';
import { useUnlinkPolicyTaskMutation } from '../policies/manage-tasks.generated';
import { useUnlinkRiskTaskMutation } from '../risks/tasks-tab.generated';
import { useUnlinkVendorTaskMutation } from '../vendors/tasks-tab.generated';
import { useTaskApi } from './use-task-api';

export function useUnlinkTaskHandler() {
  const { t } = useTranslation();
  const taskApi = useTaskApi();

  return useMemo(() => {
    return async (
      resultPromise: ReturnType<
        ReturnType<
          | typeof useUnlinkControlTaskMutation
          | typeof useUnlinkRiskTaskMutation
          | typeof useUnlinkVendorTaskMutation
          | typeof useUnlinkPolicyTaskMutation
        >[0]
      >,
    ) => {
      const taskId = resultPromise.arg.originalArgs.taskId;

      const undo = taskApi.optimisticDeleteFromLinkedTaskTable({
        taskId,
      });
      try {
        await resultPromise.unwrap();
      } catch (error) {
        errorToast(
          t('errorMessages.unlinkFailed', {
            entity: `${t('entities.task').toLowerCase()}`,
          }),
        );
        datadogLogs.logger.error('Failed to unlink task', { taskId }, toError(error));
        undo();
      }
    };
  }, [t, taskApi]);
}

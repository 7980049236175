import { Route } from '@tanstack/react-router';

import { RoleDetailsPage } from '../../../../features/settings/organization/roles/role-details-page';
import { organizationRolesSettingsLayoutRoute } from './layout';

export const organizationRolesIdSettingsRoute = new Route({
  getParentRoute: () => organizationRolesSettingsLayoutRoute,
  path: '/$roleId',
  component: RoleDetailsPage,
});

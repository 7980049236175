/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type CreateGroupTagMutationVariables = Types.Exact<{
  object: Types.Control_Groups_Insert_Input;
}>;

export type CreateGroupTagMutation = {
  __typename?: 'mutation_root';
  insert_control_groups_one?: { __typename?: 'control_groups'; group_id: string };
};

export const CreateGroupTagDocument = `
    mutation CreateGroupTag($object: control_groups_insert_input!) {
  insert_control_groups_one(object: $object) {
    group_id
  }
}
    ` as string & TypedDocumentNode<CreateGroupTagMutation, CreateGroupTagMutationVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    CreateGroupTag: build.mutation<CreateGroupTagMutation, CreateGroupTagMutationVariables>({
      query: (variables) => ({ document: CreateGroupTagDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useCreateGroupTagMutation } = injectedRtkApi;

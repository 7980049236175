import {
  $createParagraphNode,
  $createTextNode,
  $getRoot,
  $insertNodes,
  LexicalEditor,
} from 'lexical';

export function safeEditorState(serializedState?: string | null) {
  return (editor: LexicalEditor) => {
    if (serializedState) {
      const parsedState = editor.parseEditorState(serializedState);
      if (!parsedState.isEmpty()) {
        editor.setEditorState(parsedState);
        return;
      }
    }

    $getRoot().select();
    $insertNodes([$createParagraphNode().append($createTextNode(''))]);
  };
}

import { TabId } from './types';

export interface TabsServiceConfig<TId extends TabId = TabId> {
  tabs: TId[];
  navigate(tab?: TabId, replace?: boolean): Promise<void>;
  defaultTab?: TId;
}

export interface ITabsService<TId extends TabId = TabId> {
  activateTab(tab?: TId): number;
  activateTabIndex(index: number): number;
  getActiveIndex(): number;
  deactivate(): void;
}

export class TabsService<TId extends TabId = TabId> implements ITabsService<TId> {
  protected readonly tabs = this.config.tabs;
  protected readonly defaultTab = this.config.defaultTab ?? this.tabs[0];
  protected readonly navigate = this.config.navigate;
  protected activeTab?: TId;
  protected activeTabIndex = -1;

  constructor(protected config: TabsServiceConfig<TId>) {}

  activateTab(tab?: TId) {
    if (this.activeTab === tab) {
      return this.activeTabIndex;
    }
    this.setActiveTab(tab);
    this.navigate(this.activeTab, true);
    return this.activeTabIndex;
  }

  activateTabIndex(index: number) {
    return this.activateTab(this.tabs[index] ?? this.defaultTab);
  }

  getActiveIndex() {
    return this.activeTabIndex;
  }

  deactivate() {
    this.navigate(undefined, true);
  }

  setActiveTab(tab?: TId) {
    this.activeTab = tab && this.tabs.includes(tab) ? tab : this.defaultTab;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.activeTabIndex = this.tabs.indexOf(this.activeTab!);
  }
}

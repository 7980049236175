import { ComboboxItem } from '@ariakit/react';
import { Box, BoxProps, useColorModeValue } from '@chakra-ui/react';
import { ReactNode } from 'react';

import { GlobalSearchResult } from './global-search-provider';
import { GlobalSearchResultProps } from './global-search-results';

export interface GlobalSearchResultCardProps<TResult extends GlobalSearchResult>
  extends GlobalSearchResultProps<TResult>,
    BoxProps {
  children?: ReactNode;
}

export function GlobalSearchResultCard<TResult extends GlobalSearchResult>({
  result,
  children,
  ...props
}: GlobalSearchResultCardProps<TResult>) {
  return (
    <Box
      p="2"
      _selected={{ backgroundColor: useColorModeValue('gray.100', 'gray.600') }}
      transition="background-color 0.16s ease-out"
      cursor="default"
      as={ComboboxItem}
      value={result.id}
      hideOnClick
      focusOnHover
      blurOnHoverEnd={false}
      resetValueOnSelect={false}
      data-group
      {...props}
      __css={{
        '&[data-active-item]': {
          backgroundColor: useColorModeValue('gray.100', 'gray.600'),
        },
        ...props.__css,
      }}
    >
      {children}
    </Box>
  );
}

import { FieldConfigFragment } from '@main/graphql/fragments/FieldConfigFragments.generated';
import { FieldValueFragment } from '@main/graphql/fragments/FieldValueFragments.generated';
import { Field_Types_Enum } from '@main/graphql/types.generated';

import { getSelectFieldValues, getTextFieldValue } from '../field-value';
import { useSetSelectFieldValues } from '../use-set-select-field-values';
import { useSetTextFieldValue } from '../use-set-text-field-value';
import { SelectFieldInput } from './select-field-input';
import { TextFieldInput } from './text-field-input';

export const CustomFieldInput = ({
  entityId,
  config,
  values = [],
  onChange,
  isReadOnly,
}: {
  entityId: string;
  config: FieldConfigFragment;
  values?: FieldValueFragment[];
  onChange?: () => void;
  isReadOnly?: boolean;
}) => {
  const setTextFieldValue = useSetTextFieldValue();
  const setSelectFieldValues = useSetSelectFieldValues();

  switch (config.field_type) {
    case Field_Types_Enum.Text: {
      const textFieldValue = getTextFieldValue(config, values);

      return (
        <TextFieldInput
          key={config.id}
          name={config.name}
          isReadOnly={isReadOnly}
          value={textFieldValue?.value}
          onChange={async (nextValue) => {
            await setTextFieldValue({
              fieldConfig: config,
              fieldValue: textFieldValue,
              entityId,
              nextValue,
            });

            onChange?.();
          }}
        />
      );
    }

    case Field_Types_Enum.Select: {
      const options =
        config.select_field_config?.select_field_options.map(({ value }) => value) || [];
      const selectValues = getSelectFieldValues(config, values).map(({ value }) => value);

      return (
        <SelectFieldInput
          key={config.id}
          name={config.name}
          isReadOnly={isReadOnly}
          options={options}
          values={selectValues}
          isMulti={config.select_field_config?.is_multi}
          isCreatable={config.select_field_config?.is_creatable}
          onChange={async (nextValues) => {
            await setSelectFieldValues({
              fieldConfigId: config.id,
              entityId,
              values: nextValues,
            });

            onChange?.();
          }}
        />
      );
    }
  }
};

import { Route } from '@tanstack/react-router';

import { PolicyCenter } from '../../features/policies/policy-center';
import { authLayout } from '../login';

export const policiesRoute = new Route({
  getParentRoute: () => authLayout,
  path: 'policies',
  component: PolicyCenter,
});

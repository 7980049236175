import {
  AwsGuarddutyCheck,
  AwsInspectorCheck,
  AwsObjectStorageCheck,
  DBIntegrationCheck,
  EvidenceIntegrationType,
} from '@main/graphql/client-scalars';
import { Integration_Names_Enum } from '@main/graphql/types.generated';
import { IntegrationConfigSchemas } from '@main/integrations/shared';
import { z } from 'zod';

export const AwsConfigSchemas: IntegrationConfigSchemas<Integration_Names_Enum.Aws> = {
  name: Integration_Names_Enum.Aws,

  orgConfig: z.object({
    accessKeyId: z.string().min(1).describe('Access Key ID'),
    secretAccessKey: z.string().min(1).describe('Secret Access Key'),
  }),

  evidenceConfig: z.discriminatedUnion('type', [
    z.object({
      type: z.literal(EvidenceIntegrationType.Database),
      region: z.string(),
      dbInstanceIds: z.array(z.string()).optional(),
      dbClusterIds: z.array(z.string()).optional(),
      checks: z.array(z.nativeEnum(DBIntegrationCheck)),
    }),

    z.object({
      type: z.literal(EvidenceIntegrationType.ObjectStorage),
      region: z.string(),
      buckets: z.array(z.string()),
      check: z.nativeEnum(AwsObjectStorageCheck),
    }),

    z.object({
      type: z.literal(EvidenceIntegrationType.GuarddutyReport),
      region: z.string(),
      checks: z.array(z.nativeEnum(AwsGuarddutyCheck)),
    }),

    z.object({
      type: z.literal(EvidenceIntegrationType.Inspector),
      region: z.string(),
      checks: z.array(z.nativeEnum(AwsInspectorCheck)),
    }),
  ]),
};

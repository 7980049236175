import { Text } from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { useDeleteEvidenceMutation } from '@main/graphql/mutations/DeleteEvidence.generated';
import { api as getOrganizationEvidenceApi } from '@main/graphql/queries/GetOrganizationEvidences.generated';
import { toError, useUpdateAllQueryData } from '@main/shared/utils';
import { errorToast, successToast, useAlertDialog } from '@main/ui';
import { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { api as evidenceApi } from './Evidence.generated';

export function useDeleteEvidence() {
  const { t } = useTranslation();
  const { openDialog } = useAlertDialog();

  const updateAllQueryData = useUpdateAllQueryData();
  const [deleteEvidenceById] = useDeleteEvidenceMutation();

  return useCallback(
    (evidence: { id: string; name?: string }, onDelete?: () => void) => {
      openDialog({
        dialogHeader: t('evidences.alert.delete.header'),
        dialogContent: (
          <Text>
            <Trans
              i18nKey="evidences.alert.delete.content"
              values={{ name: evidence.name ?? '' }}
              components={{ bold: <strong /> }}
            />
          </Text>
        ),
        confirmAction: {
          children: t('evidences.alert.delete.confirm'),
          onClick: async () => {
            const undoUpdateEvidence = updateAllQueryData(
              getOrganizationEvidenceApi,
              'GetOrganizationEvidences',
              (draft) => {
                const index = draft.evidences.findIndex(
                  (draftEvidence) => draftEvidence.id === evidence.id,
                );
                if (index !== -1) {
                  draft.evidences.splice(index, 1);
                }
              },
            );
            const undoUpdateEvidencePaginated = updateAllQueryData(
              evidenceApi,
              'GetEvidencePaginated',
              (draft) => {
                const index = draft.evidences.findIndex(
                  (draftEvidence) => draftEvidence.id === evidence.id,
                );
                if (index === -1) return;
                draft.evidences.splice(index, 1);
                if (draft.evidences_aggregate.aggregate?.count) {
                  draft.evidences_aggregate.aggregate.count -= 1;
                }
              },
            );
            try {
              await deleteEvidenceById({ evidenceId: evidence.id }).unwrap();
              successToast(
                t('successMessages.deleteSucceeded', { entity: t('entities.evidence') }),
              );
              onDelete?.();
            } catch (error) {
              undoUpdateEvidence();
              undoUpdateEvidencePaginated();

              errorToast(t('errorMessages.deleteFailed', { entity: t('entities.evidence') }));
              datadogLogs.logger.error('Evidence delete failed', {}, toError(error));
            }
          },
        },
      });
    },
    [t, openDialog, updateAllQueryData, deleteEvidenceById],
  );
}

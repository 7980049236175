import { Route } from '@tanstack/react-router';
import { useEffect } from 'react';

import { homepageLoaded } from '../features/program/slice';
import { useAppDispatch } from '../hooks/redux-toolkit-hooks';
import { authLayout } from './login';

const HomePage = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(homepageLoaded());
  }, [dispatch]);

  return null;
};

export const homeRoute = new Route({
  getParentRoute: () => authLayout,
  path: '/home',
  component: HomePage,
});

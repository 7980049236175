import { Checkbox, CheckboxGroup, Radio, RadioGroup, Stack } from '@chakra-ui/react';
import { useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { DynamicFormComponentProps } from '../field-registry';
import { BaseDynamicFormFieldConfig, DynamicFormValidation } from '../types';

export interface OptionsFieldConfig extends BaseDynamicFormFieldConfig {
  value?: string | string[];
  placeholder?: string;
  options?: OptionsFieldOption[];
  isMultiOptions?: boolean;
  validation?: OptionsFieldValidation;
}

export interface OptionsFieldOption {
  value: string;
  label: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface OptionsFieldValidation extends DynamicFormValidation {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface OptionsFieldProps extends DynamicFormComponentProps<OptionsFieldConfig> {}

type ControllerRenderProps = Parameters<Parameters<typeof Controller>[0]['render']>[0];

export function OptionsField({ config }: OptionsFieldProps) {
  const { control } = useFormContext();

  const fieldComponent = useCallback(
    ({ field: { value, onChange } }: ControllerRenderProps) =>
      config.isMultiOptions ? (
        <CheckboxGroup size="sm" value={value} onChange={onChange}>
          <Stack>
            {config.options?.map((option) => (
              <Checkbox key={option.value} value={option.value}>
                {option.label}
              </Checkbox>
            ))}
          </Stack>
        </CheckboxGroup>
      ) : (
        <RadioGroup size="sm" value={value} onChange={onChange}>
          <Stack>
            {config.options?.map((option) => (
              <Radio key={option.value} value={option.value}>
                {option.label}
              </Radio>
            ))}
          </Stack>
        </RadioGroup>
      ),
    [config.isMultiOptions, config.options],
  );

  return (
    <Controller
      name={config.name}
      control={control}
      rules={config.validation}
      render={fieldComponent}
    />
  );
}

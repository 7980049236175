import { datadogLogs } from '@datadog/browser-logs';
import { useUpdateQuestionnaireMutation } from '@main/graphql/mutations/UpdateQuestionnaire.generated';
import { api as getQuestionnaireApi } from '@main/graphql/queries/GetQuestionnaire.generated';
import { api as getQuestionnairesApi } from '@main/graphql/queries/GetQuestionnaires.generated';
import { toError } from '@main/shared/utils';
import { errorToast } from '@main/ui';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from '../../hooks/redux-toolkit-hooks';

export function useUpdateQuestionnaireHandler({
  questionnaireId,
  organizationId,
}: {
  questionnaireId?: string;
  organizationId: string;
}) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  return useCallback(
    async (resultPromise: ReturnType<ReturnType<typeof useUpdateQuestionnaireMutation>[0]>) => {
      try {
        await resultPromise.unwrap();

        await Promise.all([
          questionnaireId &&
            dispatch(
              getQuestionnaireApi.endpoints.GetQuestionnaire.initiate(
                { id: questionnaireId },
                { subscribe: false, forceRefetch: true },
              ),
            ),
          dispatch(
            getQuestionnairesApi.endpoints.GetQuestionnaires.initiate(
              { organization_id: organizationId },
              { subscribe: false, forceRefetch: true },
            ),
          ),
        ]);
        return { isSuccess: true };
      } catch (error) {
        errorToast(t('errorMessages.updateFailed', { entity: t('entities.questionnaire') }));
        datadogLogs.logger.error('questionnaire update failed', {}, toError(error));
        return { isSuccess: false };
      }
    },
    [questionnaireId, dispatch, organizationId, t],
  );
}

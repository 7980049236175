import { Button, Icon, IconButton, Tooltip, useColorModeValue } from '@chakra-ui/react';
import { ArrowTopRightOnSquareIcon, ClipboardDocumentCheckIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const CreateFindingTaskButton = ({
  onOpen,
  onCreate,
}: {
  onOpen: (() => Promise<void>) | undefined;
  onCreate: () => Promise<void>;
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const actionBtnColor = useColorModeValue('gray.500', 'gray.300');

  if (onOpen) {
    return (
      <Tooltip
        label={t('controls.findings.tooltips.openTask')}
        rounded="md"
        hasArrow
        placement="top"
      >
        <Button
          size="sm"
          variant="outline"
          color={actionBtnColor}
          onClick={onOpen}
          rightIcon={<Icon as={ArrowTopRightOnSquareIcon} w={4} h={4} color={actionBtnColor} />}
        >
          {t('entities.task')}
        </Button>
      </Tooltip>
    );
  }

  return (
    <Tooltip
      label={t('controls.findings.tooltips.createTask')}
      rounded="md"
      hasArrow
      placement="top"
    >
      <IconButton
        onClick={async () => {
          setIsLoading(true);
          await onCreate();
          setIsLoading(false);
        }}
        variant="outline"
        aria-label="Create task"
        size="sm"
        isLoading={isLoading}
        icon={<Icon as={ClipboardDocumentCheckIcon} w={4} h={4} color={actionBtnColor} />}
      />
    </Tooltip>
  );
};

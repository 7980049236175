import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Image,
  Input,
  Link as ChakraLink,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { useJoinOrganizationMutation } from '@main/graphql/mutations/JoinOrganization.generated';
import { toError } from '@main/shared/utils';
import { errorToast } from '@main/ui';
import { Link, RouteApi } from '@tanstack/react-router';
import { FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PASSWORD_PATTERN } from '../../utils/constants';
import { nhost } from '../../utils/nhostClient';

const signupRouteApi = new RouteApi({ id: '/signup' });

const Signup = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const { t } = useTranslation();
  const [isSignUpInProgress, setIsSignUpInProgress] = useState(false);

  const search = signupRouteApi.useSearch();

  const invitationId = search.invitationId;

  const bg = useBreakpointValue({ base: 'transparent', md: 'bg-surface' });
  const shadow = { base: 'none', md: useColorModeValue('md', 'dark-lg') };
  const linkColor = useColorModeValue('blue.600', 'blue.200');
  const logo = useColorModeValue('/logo.svg', '/logo_dark.svg');
  const tipColor = useColorModeValue('gray.400', 'gray.200');

  const [joinOrganization, { isSuccess: isJoinOrganizationSuccess }] =
    useJoinOrganizationMutation();

  const handleInvitationSignup = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!invitationId) {
      errorToast(t('signup.missingInvitationCode'));
      return;
    }
    setIsSignUpInProgress(true);

    try {
      const signUpResponse = await fetch(
        `${import.meta.env.VITE_NHOST_AUTH_URL}/signup/invitation-code`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            invitationCode: invitationId,
            password,
            options: {
              displayName: `${firstName} ${lastName}`.trim(),
              metadata: {
                firstName,
                lastName,
                isOnboardingFinished: true,
              },
            },
          }),
        },
      );

      if (!signUpResponse.ok) {
        const errorData = await signUpResponse.json();

        errorToast(errorData.message);

        datadogLogs.logger.info('invitation signup failed', {}, toError(errorData));
        return;
      }

      /**
       * if a user is logged in the platform, with 'vendor' role alone(via forgot password route)
       * note: it was hard to debug because the request rejection was in vercel edge middleware but was never logged in datadog
       * Scenario:
       * - User is invited to the platform via vendor questionnaire
       * - User now has only 'vendor' role in user_roles table
       * - Now user clicks forgot password with this email, a session is created in the browser because it's a user in auth.users table
       * - User now clicks signup with this email, join organization would fail as the user now has 'vendor' role alone and workflows middleware would reject it
       * Result:
       * - the signup request with invitation code was successful
       * - but failed to join organization
       * */

      nhost.auth.isAuthenticated() && (await nhost.auth.signOut());

      try {
        await joinOrganization({
          invitationId,
        }).unwrap();
      } catch (error) {
        errorToast(t('signup.errors.failedToJoinOrg'));
        datadogLogs.logger.error(
          'user failed to join organization but signed up',
          {},
          toError(error),
        );
      }
    } finally {
      setIsSignUpInProgress(false);
    }
  };

  return (
    <Stack spacing={8} mx={'auto'} maxW={'lg'} w={'lg'}>
      <Box
        py={{ base: '0', md: '12' }}
        px={{ base: '4', md: '10' }}
        bg={bg}
        boxShadow={shadow}
        borderRadius={{ base: 'none', md: 'xl' }}
      >
        <Box position="relative" width="100%" height="14" p={3}>
          <Image
            alt="logo"
            object-fit="contain"
            sizes="100vw"
            src={logo}
            decoding="async"
            width="100%"
            height="100%"
          />
        </Box>

        {isJoinOrganizationSuccess ? (
          <Text my="8" textAlign="center">
            {t('signup.needsEmailVerification')}
          </Text>
        ) : (
          <form onSubmit={handleInvitationSignup}>
            <Flex direction={{ base: 'column', md: 'row' }} gap="6" mt="12">
              <FormControl variant="default">
                <FormLabel variant="default">First name</FormLabel>
                <Input
                  size={'lg'}
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  disabled={isSignUpInProgress}
                  required
                />
              </FormControl>
              <FormControl variant="default">
                <FormLabel variant="default">Last name</FormLabel>
                <Input
                  size={'lg'}
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  disabled={isSignUpInProgress}
                  required
                />
              </FormControl>
            </Flex>

            <FormControl variant="default" mt="6">
              <FormLabel variant="default">Create password</FormLabel>
              <Input
                type="password"
                size={'lg'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                disabled={isSignUpInProgress}
                required
                title={t('signup.passwordFormat')}
                pattern={PASSWORD_PATTERN}
              />
              <Text fontSize={'xs'} color={tipColor} mb="3">
                {t('signup.passwordHint')}
              </Text>
            </FormControl>

            <Button
              type="submit"
              isDisabled={isSignUpInProgress}
              isLoading={isSignUpInProgress}
              width={'100%'}
              mt={6}
              colorScheme={'blue'}
              size={'lg'}
            >
              Create account
            </Button>
          </form>
        )}
      </Box>
      <Box mt="8">
        <Text color="gray.500" textAlign="center">
          Already have an account?{' '}
          <Link to="/">
            <ChakraLink as="span" color={linkColor}>
              Sign in
            </ChakraLink>
          </Link>
        </Text>
      </Box>
    </Stack>
  );
};

export default Signup;

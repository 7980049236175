import {
  Card,
  CardBody,
  CardBodyProps,
  CardHeader,
  CardProps,
  Stack,
  Text,
} from '@chakra-ui/react';
import { ReactNode, useMemo } from 'react';

export type DashboardCardProps = CardProps;

export const DashboardCard = (props: DashboardCardProps) => {
  return <Card variant="outline" borderRadius="lg" gap={6} {...props} />;
};

export type DashboardCardHeaderProps = {
  heading: ReactNode;
  subheading?: ReactNode;
  children?: (props: { header: ReactNode }) => ReactNode;
};

const DashboardCardHeader = ({ heading, subheading, children }: DashboardCardHeaderProps) => {
  const header = useMemo(
    () => (
      <Stack spacing={1}>
        <Text fontSize="xl" lineHeight={7} fontWeight="semibold">
          {heading}
        </Text>
        {subheading && (
          <Text variant="subheading" lineHeight={4}>
            {subheading}
          </Text>
        )}
      </Stack>
    ),
    [heading, subheading],
  );

  const content = useMemo(() => children?.({ header }) ?? header, [children, header]);

  return (
    <CardHeader p={6} pb={0}>
      {content}
    </CardHeader>
  );
};
DashboardCard.Header = DashboardCardHeader;

export type DashboardCardBodyProps = CardBodyProps;

const DashboardCardBody = (props: DashboardCardBodyProps) => {
  return <CardBody p={6} pt={0} {...props} />;
};
DashboardCard.Body = DashboardCardBody;

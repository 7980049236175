/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type GrantEvidenceAccessMutationVariables = Types.Exact<{
  userId: Types.Scalars['uuid']['input'];
  evidenceId: Types.Scalars['uuid']['input'];
}>;

export type GrantEvidenceAccessMutation = {
  __typename?: 'mutation_root';
  grant_evidence_access?: { __typename?: 'GrantEvidenceAccessOutput'; evidence_acl_id: string };
};

export type RevokeEvidenceAccessMutationVariables = Types.Exact<{
  evidenceAclId: Types.Scalars['uuid']['input'];
}>;

export type RevokeEvidenceAccessMutation = {
  __typename?: 'mutation_root';
  revoke_evidence_access?: { __typename?: 'RevokeEvidenceAccessOutput'; evidence_acl_id: string };
};

export type SetEvidenceConfidentialMutationVariables = Types.Exact<{
  evidenceId: Types.Scalars['uuid']['input'];
  isConfidential: Types.Scalars['Boolean']['input'];
}>;

export type SetEvidenceConfidentialMutation = {
  __typename?: 'mutation_root';
  set_evidence_confidential?: { __typename?: 'SetEvidenceConfidentialOutput'; evidence_id: string };
};

export const GrantEvidenceAccessDocument = `
    mutation GrantEvidenceAccess($userId: uuid!, $evidenceId: uuid!) {
  grant_evidence_access(input: {user_id: $userId, evidence_id: $evidenceId}) {
    evidence_acl_id
  }
}
    ` as string &
  TypedDocumentNode<GrantEvidenceAccessMutation, GrantEvidenceAccessMutationVariables>;
export const RevokeEvidenceAccessDocument = `
    mutation RevokeEvidenceAccess($evidenceAclId: uuid!) {
  revoke_evidence_access(input: {evidence_acl_id: $evidenceAclId}) {
    evidence_acl_id
  }
}
    ` as string &
  TypedDocumentNode<RevokeEvidenceAccessMutation, RevokeEvidenceAccessMutationVariables>;
export const SetEvidenceConfidentialDocument = `
    mutation SetEvidenceConfidential($evidenceId: uuid!, $isConfidential: Boolean!) {
  set_evidence_confidential(
    input: {evidence_id: $evidenceId, is_confidential: $isConfidential}
  ) {
    evidence_id
  }
}
    ` as string &
  TypedDocumentNode<SetEvidenceConfidentialMutation, SetEvidenceConfidentialMutationVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GrantEvidenceAccess: build.mutation<
      GrantEvidenceAccessMutation,
      GrantEvidenceAccessMutationVariables
    >({
      query: (variables) => ({ document: GrantEvidenceAccessDocument, variables }),
    }),
    RevokeEvidenceAccess: build.mutation<
      RevokeEvidenceAccessMutation,
      RevokeEvidenceAccessMutationVariables
    >({
      query: (variables) => ({ document: RevokeEvidenceAccessDocument, variables }),
    }),
    SetEvidenceConfidential: build.mutation<
      SetEvidenceConfidentialMutation,
      SetEvidenceConfidentialMutationVariables
    >({
      query: (variables) => ({ document: SetEvidenceConfidentialDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useGrantEvidenceAccessMutation,
  useRevokeEvidenceAccessMutation,
  useSetEvidenceConfidentialMutation,
} = injectedRtkApi;

import { datadogLogs } from '@datadog/browser-logs';
import { useUpdateReportMutation } from '@main/graphql/mutations/UpdateReport.generated';
import { useGetReportByIdQuery } from '@main/graphql/queries/GetReportById.generated';
import { useGetReportsForOrganizationQuery } from '@main/graphql/queries/GetReportsForOrganization.generated';
import { toError } from '@main/shared/utils';
import { errorToast, successToast } from '@main/ui';
import { useParams } from '@tanstack/react-router';
import { SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { getCurrentUserSelectedOrg } from '../user/slice';
import { ReportForm, ReportSchema } from './report-form';
import { getReportById } from './slice';

export const EditReport = ({ closeModal }: { closeModal: () => void }) => {
  const { t } = useTranslation();
  const params = useParams({ from: '/reports/$reportId' });
  const reportId = params.reportId;

  const organization = useAppSelector(getCurrentUserSelectedOrg);
  const reportData = useAppSelector((state) => getReportById(state, reportId));

  const [updateReport] = useUpdateReportMutation();
  const { refetch: refetchReportsList } = useGetReportsForOrganizationQuery({
    organizationId: organization.id,
  });
  const { refetch: refetchCurrentReport } = useGetReportByIdQuery({
    id: reportId,
  });

  const onSubmit: SubmitHandler<ReportSchema> = async (data) => {
    try {
      await updateReport({
        id: reportId,
        reportInput: {
          name: data.reportName,
          description: data.reportDescription,
        },
      }).unwrap();

      refetchCurrentReport();
      refetchReportsList();
      successToast(t('successMessages.editSucceeded', { entity: t('entities.report') }));
      closeModal();
    } catch (error) {
      errorToast(t('errorMessages.updateFailed', { entity: t('entities.report') }));
      datadogLogs.logger.error('Editing a report failed', {}, toError(error));
    }
  };

  if (!reportData) {
    return null;
  }

  return (
    <ReportForm
      onSubmit={onSubmit}
      closeModal={closeModal}
      defaultValues={{
        reportName: reportData.name || '',
        reportType: reportData.type,
        reportDescription: reportData.description,
      }}
    />
  );
};

import { RiskAuditRow } from '@main/graphql/client-scalars';
import { useGetRiskClassificationsQuery } from '@main/graphql/features/RiskClassifications.generated';
import {
  GetRiskHistoryQuery,
  useGetRiskHistoryQuery,
} from '@main/graphql/queries/GetRiskHistory.generated';
import { exhaustiveCheck, hasProperty } from '@main/shared/utils';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { ActivityEntry, PaginatedHistoryList, TagActivityEntry } from '../activity-history/shared';
import { getCurrentOrgUsersMap, getCurrentUserSelectedOrgId } from '../user/slice';
import { getRiskClassificationMap } from './slice';

type Activity = GetRiskHistoryQuery['audit_risk_history_view'][number] & RiskAuditRow;

export const RiskHistoryTab: FC<{ riskId: string }> = ({ riskId }) => {
  const { t } = useTranslation();
  const [paginationParams, setPagination] = useState<{ limit: number; offset: number }>();
  const { data: activityData, isLoading: isLoadingHistory } = useGetRiskHistoryQuery(
    {
      risk_id: riskId,
      ...paginationParams,
    },
    { refetchOnMountOrArgChange: true, skip: !paginationParams },
  );
  const organizationId = useAppSelector(getCurrentUserSelectedOrgId);
  const currentOrgUsersById = useAppSelector(getCurrentOrgUsersMap);

  useGetRiskClassificationsQuery({ organizationId });
  const classificationMap = useAppSelector(getRiskClassificationMap);

  if (isLoadingHistory) {
    return null;
  }

  const getActivityNode = (activity: Activity) => {
    switch (activity.table_name) {
      case 'risks': {
        switch (activity.action) {
          case 'I': {
            return (
              <ActivityEntry
                hasuraUser={activity.hasura_user}
                timestamp={activity.action_timestamp}
                title={t('risks.history.riskAdded')}
              />
            );
          }
          case 'U': {
            if (hasProperty(activity.changed_fields, 'residual_impact_id')) {
              const oldId = activity.row_data.residual_impact_id;
              const newId = activity.changed_fields.residual_impact_id;

              return (
                <TagActivityEntry
                  hasuraUser={activity.hasura_user}
                  timestamp={activity.action_timestamp}
                  title={t('risks.history.residualImpactChanged')}
                  from={oldId ? classificationMap?.impacts[oldId]?.name ?? 'N/A' : 'N/A'}
                  to={newId ? classificationMap?.impacts[newId]?.name ?? 'N/A' : 'N/A'}
                />
              );
            }

            if (hasProperty(activity.changed_fields, 'residual_likelihood_id')) {
              const oldId = activity.row_data.residual_likelihood_id;
              const newId = activity.changed_fields.residual_likelihood_id;

              return (
                <TagActivityEntry
                  hasuraUser={activity.hasura_user}
                  timestamp={activity.action_timestamp}
                  title={t('risks.history.residualLikelihoodChanged')}
                  from={oldId ? classificationMap?.likelihoods[oldId]?.name ?? 'N/A' : 'N/A'}
                  to={newId ? classificationMap?.likelihoods[newId]?.name ?? 'N/A' : 'N/A'}
                />
              );
            }

            if (hasProperty(activity.changed_fields, 'inherent_impact_id')) {
              const oldId = activity.row_data.inherent_impact_id;
              const newId = activity.changed_fields.inherent_impact_id;

              return (
                <TagActivityEntry
                  hasuraUser={activity.hasura_user}
                  timestamp={activity.action_timestamp}
                  title={t('risks.history.inherentImpactChanged')}
                  from={oldId ? classificationMap?.impacts[oldId]?.name ?? 'N/A' : 'N/A'}
                  to={newId ? classificationMap?.impacts[newId]?.name ?? 'N/A' : 'N/A'}
                />
              );
            }

            if (hasProperty(activity.changed_fields, 'inherent_likelihood_id')) {
              const oldId = activity.row_data.inherent_likelihood_id;
              const newId = activity.changed_fields.inherent_likelihood_id;

              return (
                <TagActivityEntry
                  hasuraUser={activity.hasura_user}
                  timestamp={activity.action_timestamp}
                  title={t('risks.history.inherentLikelihoodChanged')}
                  from={oldId ? classificationMap?.likelihoods[oldId]?.name ?? 'N/A' : 'N/A'}
                  to={newId ? classificationMap?.likelihoods[newId]?.name ?? 'N/A' : 'N/A'}
                />
              );
            }

            if (hasProperty(activity.changed_fields, 'assignee_id')) {
              const oldId = activity.row_data.assignee_id;
              const newId = activity.changed_fields.assignee_id;

              return (
                <TagActivityEntry
                  hasuraUser={activity.hasura_user}
                  timestamp={activity.action_timestamp}
                  title={t('risks.history.assigneeChanged')}
                  from={oldId ? currentOrgUsersById[oldId]?.displayName ?? 'N/A' : 'N/A'}
                  to={newId ? currentOrgUsersById[newId]?.displayName ?? 'N/A' : 'N/A'}
                />
              );
            }

            if (hasProperty(activity.changed_fields, 'treatment_plan')) {
              const oldPlan = activity.row_data.treatment_plan;
              const newPlan = activity.changed_fields.treatment_plan;

              return (
                <TagActivityEntry
                  hasuraUser={activity.hasura_user}
                  timestamp={activity.action_timestamp}
                  title={t('risks.history.treatmentPlanChanged')}
                  from={oldPlan ?? 'N/A'}
                  to={newPlan ?? 'N/A'}
                />
              );
            }

            return null;
          }
          case 'D':
          case 'T': {
            return null;
          }
          default:
            return exhaustiveCheck(activity.action);
        }
      }

      case 'risk_controls': {
        switch (activity.action) {
          case 'I': {
            return (
              <ActivityEntry
                hasuraUser={activity.hasura_user}
                timestamp={activity.action_timestamp}
                title={t('risks.history.controlLinked', {
                  controlType: activity.row_data.is_control_primary
                    ? t('risks.history.primaryControl')
                    : t('risks.history.secondaryControl'),
                })}
              />
            );
          }
          case 'U':
          case 'D': {
            return (
              <ActivityEntry
                hasuraUser={activity.hasura_user}
                timestamp={activity.action_timestamp}
                title={t('risks.history.controlUnlinked', {
                  controlType: activity.row_data.is_control_primary
                    ? t('risks.history.primaryControl')
                    : t('risks.history.secondaryControl'),
                })}
              />
            );
          }
          case 'T': {
            return null;
          }
          default:
            return exhaustiveCheck(activity.action);
        }
      }
    }
  };

  return (
    <PaginatedHistoryList
      setPaginationParams={setPagination}
      currentPageData={activityData?.audit_risk_history_view as Activity[]}
      isLoadingHistory={isLoadingHistory}
      getActivityNode={getActivityNode}
    />
  );
};

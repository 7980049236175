import { Report_Types_Enum } from '@main/graphql/types.generated';

import { CSVInput } from '../../../utils/export-csv';
import { ControlsReportCard } from './controls-report-card';
import { EvidenceReportCard } from './evidence-report-card';
import { RisksReportCard } from './risks-report-card';
import { TasksReportCard } from './tasks-report-card';
import { VendorsReportCard } from './vendors-report-card';

export const ReportCard = (props: {
  reportId: string;
  type: Report_Types_Enum;
  onExportCSV: (data: CSVInput[]) => void;
}) => {
  switch (props.type) {
    case Report_Types_Enum.Controls:
      return <ControlsReportCard {...props} />;
    case Report_Types_Enum.Evidence:
      return <EvidenceReportCard {...props} />;
    case Report_Types_Enum.Risks:
      return <RisksReportCard {...props} />;
    case Report_Types_Enum.Vendors:
      return <VendorsReportCard {...props} />;
    case Report_Types_Enum.Tasks:
      return <TasksReportCard {...props} />;
  }
};

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type GetPolicyNameQueryVariables = Types.Exact<{
  policyId: Types.Scalars['uuid']['input'];
}>;

export type GetPolicyNameQuery = {
  __typename?: 'query_root';
  policies_by_pk?: { __typename?: 'policies'; name?: string };
};

export type InsertEvidencePolicyMutationVariables = Types.Exact<{
  evidencePolicy: Types.Evidence_Policies_Insert_Input;
}>;

export type InsertEvidencePolicyMutation = {
  __typename?: 'mutation_root';
  insert_evidence_policies_one?: { __typename?: 'evidence_policies'; id: string };
};

export const GetPolicyNameDocument = `
    query GetPolicyName($policyId: uuid!) {
  policies_by_pk(id: $policyId) {
    name
  }
}
    ` as string & TypedDocumentNode<GetPolicyNameQuery, GetPolicyNameQueryVariables>;
export const InsertEvidencePolicyDocument = `
    mutation InsertEvidencePolicy($evidencePolicy: evidence_policies_insert_input!) {
  insert_evidence_policies_one(
    object: $evidencePolicy
    on_conflict: {constraint: evidence_policies_evidence_id_key, update_columns: [policy_id, with_versions_chapter, with_revision_details, with_approvals_chapter, with_acknowledgements_chapter]}
  ) {
    id
  }
}
    ` as string &
  TypedDocumentNode<InsertEvidencePolicyMutation, InsertEvidencePolicyMutationVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetPolicyName: build.query<GetPolicyNameQuery, GetPolicyNameQueryVariables>({
      query: (variables) => ({ document: GetPolicyNameDocument, variables }),
    }),
    InsertEvidencePolicy: build.mutation<
      InsertEvidencePolicyMutation,
      InsertEvidencePolicyMutationVariables
    >({
      query: (variables) => ({ document: InsertEvidencePolicyDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetPolicyNameQuery, useLazyGetPolicyNameQuery, useInsertEvidencePolicyMutation } =
  injectedRtkApi;

import { EvidenceStatus } from '@main/graphql/client-scalars';
import { TFunction } from 'i18next';

export const EvidenceStatusColors = {
  [EvidenceStatus.VALID]: 'green',
  [EvidenceStatus.AT_RISK]: 'orange',
  [EvidenceStatus.INVALID]: 'red',
  [EvidenceStatus.PENDING]: 'gray',
};

// Can rely on non-integer object key order:
// https://2ality.com/2015/10/property-traversal-order-es6.html
const orderedEvidenceStatus = Object.keys(EvidenceStatusColors) as EvidenceStatus[];

export function getAllEvidenceStatusOptions(t: TFunction<'translation'>) {
  return orderedEvidenceStatus.map((value) => getEvidenceStatusOption(t, value));
}

export function getEvidenceStatusOption(t: TFunction<'translation'>, value: EvidenceStatus) {
  return {
    value,
    label: t(`evidences.status.${value}`),
    colorScheme: EvidenceStatusColors[value],
  };
}

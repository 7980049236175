import { useMemo } from 'react';

/**
 *  We need this hook to remove one layer (editorState) from the stored state.
 */
export const useGetCommentState = (text: string) => {
  return useMemo(() => {
    const parsedState = JSON.parse(text);
    return JSON.stringify(parsedState.editorState);
  }, [text]);
};

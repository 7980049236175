import { HStack, Select, Skeleton } from '@chakra-ui/react';
import { ChartBarIcon } from '@heroicons/react/24/outline';
import {
  GetRiskClassificationsQuery,
  useGetRiskClassificationsQuery,
} from '@main/graphql/features/RiskClassifications.generated';
import { useGetRiskMatrixQuery } from '@main/graphql/features/RiskMatrix.generated';
import { useGetRisksQuery } from '@main/graphql/queries/GetRisks.generated';
import { DashboardCard, EmptyPlaceholder } from '@main/ui';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { HeatMap } from '../risks/risk-heat-map';
import { getRiskHeatmapData, MatrixItemWithRiskCount } from '../risks/slice';
import { getCurrentUserSelectedOrgId } from '../user/slice';

export enum ActiveRiskHeatMap {
  Inherent = 'inherent',
  Residual = 'residual',
}

export interface RiskHeatMapProps {
  data?: RiskHeatMapData;
}

export interface RiskHeatMapData {
  inherentLevelsHeatmap: Map<string, MatrixItemWithRiskCount>;
  residualLevelsHeatmap: Map<string, MatrixItemWithRiskCount>;
  impacts: GetRiskClassificationsQuery['impacts'];
  likelihoods: GetRiskClassificationsQuery['likelihoods'];
}

export function RiskHeatMap(props: RiskHeatMapProps) {
  const { t } = useTranslation();
  const [activeHeatmap, setActiveHeatmap] = useState(ActiveRiskHeatMap.Residual);

  const data = props.data;
  const isLoading = data === undefined;
  const isEmpty = data ? data.impacts.length === 0 || data.likelihoods.length === 0 : false;

  const heading = useMemo(
    () =>
      activeHeatmap === ActiveRiskHeatMap.Inherent
        ? t('dashboard.riskHeatMap.heading.inherent')
        : t('dashboard.riskHeatMap.heading.residual'),
    [activeHeatmap, t],
  );

  const heatmap = useMemo(
    () =>
      activeHeatmap === ActiveRiskHeatMap.Inherent
        ? data?.inherentLevelsHeatmap
        : data?.residualLevelsHeatmap,
    [activeHeatmap, data?.inherentLevelsHeatmap, data?.residualLevelsHeatmap],
  );

  return (
    <DashboardCard>
      <DashboardCard.Header heading={heading}>
        {({ header }) => (
          <HStack justifyContent="space-between" alignItems="center">
            {header}
            <Select
              key={activeHeatmap}
              variant="filled"
              maxW="177px"
              defaultValue={activeHeatmap}
              onChange={(e) => setActiveHeatmap(e.target.value as ActiveRiskHeatMap)}
            >
              <option value={ActiveRiskHeatMap.Residual}>
                {t('dashboard.riskHeatMap.selector.residual')}
              </option>
              <option value={ActiveRiskHeatMap.Inherent}>
                {t('dashboard.riskHeatMap.selector.inherent')}
              </option>
            </Select>
          </HStack>
        )}
      </DashboardCard.Header>
      <DashboardCard.Body minH={240}>
        <Skeleton
          isLoaded={!isLoading}
          h="full"
          w="full"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {isEmpty ? (
            <ComplianceHealthEmpty />
          ) : (
            !isLoading && (
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              <HeatMap map={heatmap!} impacts={data.impacts} likelihoods={data.likelihoods} />
            )
          )}
        </Skeleton>
      </DashboardCard.Body>
    </DashboardCard>
  );
}

export function useRiskHeatMap(): RiskHeatMapProps {
  const organizationId = useAppSelector(getCurrentUserSelectedOrgId);
  const { isFetching: isMatrixLoading } = useGetRiskMatrixQuery(
    { organizationId },
    { refetchOnMountOrArgChange: true },
  );
  const { isFetching: isRisksLoading } = useGetRisksQuery({ organization_id: organizationId });
  const { data: classifications, isFetching: isClassificationsLoading } =
    useGetRiskClassificationsQuery({
      organizationId,
    });
  const heatmapData = useAppSelector(getRiskHeatmapData);

  if (isMatrixLoading || isRisksLoading || isClassificationsLoading) {
    return {};
  }

  return {
    data: {
      ...heatmapData,
      impacts: classifications?.impacts ?? [],
      likelihoods: classifications?.likelihoods ?? [],
    },
  };
}

function ComplianceHealthEmpty() {
  const { t } = useTranslation();

  return (
    <EmptyPlaceholder>
      <EmptyPlaceholder.Icon as={ChartBarIcon} />
      <EmptyPlaceholder.Content>
        <EmptyPlaceholder.Heading>
          {t('dashboard.riskHeatMap.emptyState.heading')}
        </EmptyPlaceholder.Heading>
        <EmptyPlaceholder.Subheading>
          {t('dashboard.riskHeatMap.emptyState.subheading')}
        </EmptyPlaceholder.Subheading>
      </EmptyPlaceholder.Content>
    </EmptyPlaceholder>
  );
}

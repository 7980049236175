/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type UpdateRiskMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
  risk_input?: Types.InputMaybe<Types.Risks_Set_Input>;
}>;

export type UpdateRiskMutation = {
  __typename?: 'mutation_root';
  update_risks_by_pk?: { __typename?: 'risks'; id: string };
};

export const UpdateRiskDocument = `
    mutation UpdateRisk($id: uuid!, $risk_input: risks_set_input = {}) {
  update_risks_by_pk(pk_columns: {id: $id}, _set: $risk_input) {
    id
  }
}
    ` as string & TypedDocumentNode<UpdateRiskMutation, UpdateRiskMutationVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    UpdateRisk: build.mutation<UpdateRiskMutation, UpdateRiskMutationVariables>({
      query: (variables) => ({ document: UpdateRiskDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useUpdateRiskMutation } = injectedRtkApi;

import { datadogLogs } from '@datadog/browser-logs';
import { useUpdateEvidenceVersionByIdMutation } from '@main/graphql/mutations/UpdateEvidenceVersionById.generated';
import { toError } from '@main/shared/utils';
import { errorToast, successToast } from '@main/ui';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../hooks/redux-toolkit-hooks';
import { getEvidenceVersion, getEvidenceVersionType } from '../slice';
import { EvidenceVersionModal } from './evidence-version-modal';
import { EvidenceVersionType } from './evidence-version-type';
import { LinkEvidenceFormSchema, useLinkEvidenceForm } from './link-evidence-form';
import { PolicyEvidenceFormSchema, usePolicyEvidenceForm } from './policy-evidence-form';
import {
  SingleFileEvidenceFormSchema,
  useSingleFileEvidenceForm,
} from './single-file-evidence-form';

export const EditEvidenceVersionModal = ({
  evidenceId,
  evidenceVersionId,
  isOpen,
  onEdit,
  onClose,
}: {
  evidenceId: string;
  evidenceVersionId: string;
  isOpen: boolean;
  onEdit: () => void;
  onClose: () => void;
}) => {
  const { t } = useTranslation();

  const evidenceVersion = useAppSelector((state) =>
    getEvidenceVersion(state, evidenceId, evidenceVersionId),
  );
  const versionType = evidenceVersion && getEvidenceVersionType(evidenceVersion);

  const handleErrors = useHandleErrors({ onEdit });
  const editFileEvidenceVersion = useEditFileEvidenceVersion({ evidenceVersionId });
  const editLinkEvidenceVersion = useEditLinkEvidenceVersion({ evidenceVersionId });
  const editEvidencePolicyVersion = useEditEvidencePolicyVersion({
    evidenceVersionId,
  });

  const forms = [
    {
      type: EvidenceVersionType.File,
      form: useSingleFileEvidenceForm({
        defaultValues: {
          validityStart: evidenceVersion?.validity_start,
          file: evidenceVersion?.evidence_version_file?.file,
        },

        onSubmit(data) {
          return handleErrors(editFileEvidenceVersion(data));
        },
      }),
    },

    {
      type: EvidenceVersionType.Link,
      form: useLinkEvidenceForm({
        defaultValues: {
          validityStart: evidenceVersion?.validity_start,
          url: evidenceVersion?.url,
        },

        onSubmit(data) {
          return handleErrors(editLinkEvidenceVersion(data));
        },
      }),
    },

    {
      type: EvidenceVersionType.Policy,
      form: usePolicyEvidenceForm({
        defaultValues: {
          validityStart: evidenceVersion?.validity_start,
          policyName: evidenceVersion?.evidence.evidence_policy?.policy.name,
        },
        onSubmit(data) {
          return handleErrors(editEvidencePolicyVersion(data));
        },
      }),
    },
  ];

  return (
    <EvidenceVersionModal
      header={t('evidences.version.editVersionHeader')}
      versionType={versionType}
      forms={forms}
      isOpen={isOpen}
      onClose={onClose}
    />
  );
};

function useHandleErrors({ onEdit }: { onEdit: () => void }) {
  const { t } = useTranslation();

  return async (promise: Promise<unknown>) => {
    try {
      await promise;
      successToast(t('successMessages.editSucceeded', { entity: t('entities.evidence_version') }));
      onEdit();
    } catch (error) {
      errorToast(t('errorMessages.updateFailed', { entity: t('entities.evidence_version') }));
      datadogLogs.logger.error('Failed updating evidence version', {}, toError(error));
    }
  };
}

function useEditFileEvidenceVersion({ evidenceVersionId }: { evidenceVersionId: string }) {
  const [updateEvidenceVersion] = useUpdateEvidenceVersionByIdMutation();

  return async ({ validityStart }: SingleFileEvidenceFormSchema) => {
    await updateEvidenceVersion({
      id: evidenceVersionId,
      updatePayload: {
        validity_start: validityStart,
        url: null,
      },
    }).unwrap();
  };
}

function useEditLinkEvidenceVersion({ evidenceVersionId }: { evidenceVersionId: string }) {
  const [updateEvidenceVersion] = useUpdateEvidenceVersionByIdMutation();

  return async ({ validityStart, url }: LinkEvidenceFormSchema) => {
    await updateEvidenceVersion({
      id: evidenceVersionId,
      updatePayload: {
        validity_start: validityStart,
        url,
      },
    }).unwrap();
  };
}

function useEditEvidencePolicyVersion({ evidenceVersionId }: { evidenceVersionId: string }) {
  const [updateEvidenceVersion] = useUpdateEvidenceVersionByIdMutation();

  return async ({ validityStart }: PolicyEvidenceFormSchema) => {
    await updateEvidenceVersion({
      id: evidenceVersionId,
      updatePayload: {
        validity_start: validityStart,
      },
    }).unwrap();
  };
}

import { datadogLogs } from '@datadog/browser-logs';
import { Policy_Statuses_Enum_Comparison_Exp } from '@main/graphql/types.generated';
import { toError } from '@main/shared/utils';
import { errorToast } from '@main/ui';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { getCurrentUserSelectedOrgId } from '../user/slice';
import {
  SearchPoliciesQueryVariables,
  useLazySearchPoliciesQuery,
} from './policy-search.generated';

export function useSearchPolicies({
  statusFilter,
}: {
  statusFilter?: Policy_Statuses_Enum_Comparison_Exp;
} = {}) {
  const { t } = useTranslation();
  const orgId = useAppSelector(getCurrentUserSelectedOrgId);
  const [searchPolicies] = useLazySearchPoliciesQuery();

  return async (input: string) => {
    const normalizedInput = input.trim();

    const searchPayload: SearchPoliciesQueryVariables = {
      orgId,
      nameFilter: normalizedInput ? { _ilike: `%${normalizedInput}%` } : {},
      statusFilter,
      limit: normalizedInput ? null : 10,
    };

    const { error, data } = await searchPolicies(searchPayload);

    if (error) {
      errorToast(t('errorMessages.searchFailed', { entity: t('entities.policy') }));
      datadogLogs.logger.error('Failed searching policies', searchPayload, toError(error));
      return [];
    }

    return (
      data?.policies.map((policy) => ({
        value: policy.id,
        label: policy.name,
        internalId: policy.internal_id,
        type: policy.type,
      })) ?? []
    );
  };
}

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type MakeEvidenceVersionCurrentMutationVariables = Types.Exact<{
  evidenceId: Types.Scalars['uuid']['input'];
  versionId: Types.Scalars['uuid']['input'];
}>;

export type MakeEvidenceVersionCurrentMutation = {
  __typename?: 'mutation_root';
  update_evidence_versions?: {
    __typename?: 'evidence_versions_mutation_response';
    affected_rows: number;
  };
  update_evidence_versions_by_pk?: {
    __typename?: 'evidence_versions';
    id: string;
    is_current: boolean;
  };
};

export const MakeEvidenceVersionCurrentDocument = `
    mutation MakeEvidenceVersionCurrent($evidenceId: uuid!, $versionId: uuid!) {
  update_evidence_versions(
    where: {evidence_id: {_eq: $evidenceId}, is_current: {_eq: true}}
    _set: {is_current: false}
  ) {
    affected_rows
  }
  update_evidence_versions_by_pk(
    pk_columns: {id: $versionId}
    _set: {is_current: true}
  ) {
    id
    is_current
  }
}
    ` as string &
  TypedDocumentNode<
    MakeEvidenceVersionCurrentMutation,
    MakeEvidenceVersionCurrentMutationVariables
  >;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    MakeEvidenceVersionCurrent: build.mutation<
      MakeEvidenceVersionCurrentMutation,
      MakeEvidenceVersionCurrentMutationVariables
    >({
      query: (variables) => ({ document: MakeEvidenceVersionCurrentDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useMakeEvidenceVersionCurrentMutation } = injectedRtkApi;

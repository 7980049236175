/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type GetCriteriaTagsQueryVariables = Types.Exact<{
  orgId: Types.Scalars['uuid']['input'];
  nameFilter?: Types.InputMaybe<Types.String_Comparison_Exp>;
}>;

export type GetCriteriaTagsQuery = {
  __typename?: 'query_root';
  criteria: Array<{ __typename?: 'criteria'; id: string; name: string }>;
};

export const GetCriteriaTagsDocument = `
    query GetCriteriaTags($orgId: uuid!, $nameFilter: String_comparison_exp = {}) {
  criteria(
    order_by: {name: asc}
    where: {organization_id: {_eq: $orgId}, name: $nameFilter}
  ) {
    id
    name
  }
}
    ` as string & TypedDocumentNode<GetCriteriaTagsQuery, GetCriteriaTagsQueryVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetCriteriaTags: build.query<GetCriteriaTagsQuery, GetCriteriaTagsQueryVariables>({
      query: (variables) => ({ document: GetCriteriaTagsDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetCriteriaTagsQuery, useLazyGetCriteriaTagsQuery } = injectedRtkApi;

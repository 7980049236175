/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type DeleteEvidenceMutationVariables = Types.Exact<{
  evidenceId: Types.Scalars['uuid']['input'];
}>;

export type DeleteEvidenceMutation = {
  __typename?: 'mutation_root';
  delete_evidences_by_pk?: { __typename?: 'evidences'; id: string };
};

export const DeleteEvidenceDocument = `
    mutation DeleteEvidence($evidenceId: uuid!) {
  delete_evidences_by_pk(id: $evidenceId) {
    id
  }
}
    ` as string & TypedDocumentNode<DeleteEvidenceMutation, DeleteEvidenceMutationVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    DeleteEvidence: build.mutation<DeleteEvidenceMutation, DeleteEvidenceMutationVariables>({
      query: (variables) => ({ document: DeleteEvidenceDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useDeleteEvidenceMutation } = injectedRtkApi;

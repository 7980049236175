import { Divider, Flex } from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { Global } from '@emotion/react';
import { ArrowUpCircleIcon, AtSymbolIcon, PaperClipIcon } from '@heroicons/react/24/outline';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { toError } from '@main/shared/utils';
import { $getRoot, CLEAR_EDITOR_COMMAND } from 'lexical';
import { ReactNode, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { FloatingActionButtons, FloatingButtonsContainer } from '../floating-action-buttons';
import { MentionedObject, MentionNode } from '../lexical/nodes/MentionNode';
import defaultTheme from '../lexical/themes/default';
import defaultStyles from '../lexical/themes/default/styles';
import { errorToast } from '../toast';
import { FileUpload } from '../upload-file';
import { CommentPlugins } from './comment-plugins';
import { CommentSaveParams } from './editable-comment-item';
import { useFileUploads } from './hooks/use-file-uploads';
import { useIsEditorActive } from './hooks/use-is-editor-active';
import { useMentions } from './hooks/use-mentions';

export type CommentEditorProps = {
  children: ReactNode;
  mentionsData: MentionedObject[];
  onSave: (data: CommentSaveParams) => Promise<void>;
  placeholder?: string;
};

export const CommentEditor: React.FC<CommentEditorProps> = (props) => {
  return (
    <LexicalComposer
      initialConfig={{
        editable: true,
        namespace: 'comment-input',
        onError(error: unknown) {
          console.error(error);
        },
        nodes: [MentionNode],
        theme: defaultTheme,
      }}
    >
      <Global styles={defaultStyles} />
      <CommentLexical {...props} />
    </LexicalComposer>
  );
};

const CommentLexical: React.FC<CommentEditorProps> = ({
  onSave,
  children,
  mentionsData = [],
  placeholder = 'Add a comment...',
}) => {
  const { t } = useTranslation('ui');
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [editor] = useLexicalComposerContext();
  const isEditorActive = useIsEditorActive();
  const mentionUsers = useMentions();
  const { stagedFiles, filesUpload, clearStagedFiles } = useFileUploads();

  const sendNewComment = () => {
    editor.update(async () => {
      if ($getRoot().getTextContent().trim() === '' && stagedFiles.length === 0) {
        return;
      }

      const { errors, files } = await filesUpload.upload();

      if (errors.length > 0) {
        errorToast(t('comments.toast.uploadFailed'));
        datadogLogs.logger.error('Creating new control failed', {}, toError(errors[0]));
        return;
      }

      const uploadFileIds = files.map((file) => file.id);

      const editorState = JSON.stringify(editor.toJSON());
      await onSave({ text: editorState, uploadedFiles: uploadFileIds });
      editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
      clearStagedFiles();
    });
  };

  const onAttachIconClicked = () => fileInputRef.current?.click();

  return (
    <FloatingButtonsContainer as={Flex} mt={4} showButtons={isEditorActive}>
      <Flex direction="column" width="full">
        <Flex gap={3}>
          {children}
          <Flex direction={'column'} w={'full'}>
            <Flex>
              <CommentPlugins
                onSubmit={sendNewComment}
                mentionsList={mentionsData}
                placeholder={placeholder}
              />

              <FloatingActionButtons
                buttons={[
                  {
                    'aria-label': 'Attach file',
                    tooltip: t('comments.actions.attach'),
                    icon: <PaperClipIcon />,
                    onClick: onAttachIconClicked,
                  },
                  {
                    'aria-label': 'Mention user',
                    tooltip: t('comments.actions.mention'),
                    icon: <AtSymbolIcon />,
                    onClick: mentionUsers,
                  },
                  {
                    'aria-label': 'Send comment',
                    tooltip: t('comments.actions.send'),
                    icon: <ArrowUpCircleIcon />,
                    onClick: sendNewComment,
                  },
                ]}
              />
            </Flex>
            <FileUpload {...filesUpload.props}>
              <FileUpload.Input display={'none'} ref={fileInputRef} />
            </FileUpload>
          </Flex>
        </Flex>

        <Divider mt={2} />
      </Flex>
    </FloatingButtonsContainer>
  );
};

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type GetEvidenceTagsQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['uuid']['input'];
  nameFilter?: Types.InputMaybe<Types.String_Comparison_Exp>;
}>;

export type GetEvidenceTagsQuery = {
  __typename?: 'query_root';
  organization_evidence_tags: Array<{
    __typename?: 'organization_evidence_tags';
    id: string;
    name: string;
  }>;
};

export type CreateEvidenceTagMutationVariables = Types.Exact<{
  object: Types.Evidence_Tags_Insert_Input;
}>;

export type CreateEvidenceTagMutation = {
  __typename?: 'mutation_root';
  insert_evidence_tags_one?: { __typename?: 'evidence_tags'; tag_id: string };
};

export type AddEvidenceTagMutationVariables = Types.Exact<{
  tagId: Types.Scalars['uuid']['input'];
  evidenceId: Types.Scalars['uuid']['input'];
}>;

export type AddEvidenceTagMutation = {
  __typename?: 'mutation_root';
  insert_evidence_tags_one?: { __typename?: 'evidence_tags'; tag_id: string; evidence_id: string };
};

export type RemoveEvidenceTagMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
}>;

export type RemoveEvidenceTagMutation = {
  __typename?: 'mutation_root';
  delete_evidence_tags_by_pk?: { __typename?: 'evidence_tags'; tag_id: string };
};

export const GetEvidenceTagsDocument = `
    query GetEvidenceTags($organizationId: uuid!, $nameFilter: String_comparison_exp = {}) {
  organization_evidence_tags(
    order_by: {name: asc}
    where: {organization_id: {_eq: $organizationId}, name: $nameFilter}
  ) {
    id
    name
  }
}
    ` as string & TypedDocumentNode<GetEvidenceTagsQuery, GetEvidenceTagsQueryVariables>;
export const CreateEvidenceTagDocument = `
    mutation CreateEvidenceTag($object: evidence_tags_insert_input!) {
  insert_evidence_tags_one(object: $object) {
    tag_id
  }
}
    ` as string & TypedDocumentNode<CreateEvidenceTagMutation, CreateEvidenceTagMutationVariables>;
export const AddEvidenceTagDocument = `
    mutation AddEvidenceTag($tagId: uuid!, $evidenceId: uuid!) {
  insert_evidence_tags_one(object: {tag_id: $tagId, evidence_id: $evidenceId}) {
    tag_id
    evidence_id
  }
}
    ` as string & TypedDocumentNode<AddEvidenceTagMutation, AddEvidenceTagMutationVariables>;
export const RemoveEvidenceTagDocument = `
    mutation RemoveEvidenceTag($id: uuid!) {
  delete_evidence_tags_by_pk(id: $id) {
    tag_id
  }
}
    ` as string & TypedDocumentNode<RemoveEvidenceTagMutation, RemoveEvidenceTagMutationVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetEvidenceTags: build.query<GetEvidenceTagsQuery, GetEvidenceTagsQueryVariables>({
      query: (variables) => ({ document: GetEvidenceTagsDocument, variables }),
    }),
    CreateEvidenceTag: build.mutation<
      CreateEvidenceTagMutation,
      CreateEvidenceTagMutationVariables
    >({
      query: (variables) => ({ document: CreateEvidenceTagDocument, variables }),
    }),
    AddEvidenceTag: build.mutation<AddEvidenceTagMutation, AddEvidenceTagMutationVariables>({
      query: (variables) => ({ document: AddEvidenceTagDocument, variables }),
    }),
    RemoveEvidenceTag: build.mutation<
      RemoveEvidenceTagMutation,
      RemoveEvidenceTagMutationVariables
    >({
      query: (variables) => ({ document: RemoveEvidenceTagDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useGetEvidenceTagsQuery,
  useLazyGetEvidenceTagsQuery,
  useCreateEvidenceTagMutation,
  useAddEvidenceTagMutation,
  useRemoveEvidenceTagMutation,
} = injectedRtkApi;

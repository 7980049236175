import { Combobox, ComboboxProps, useComboboxContext } from '@ariakit/react';
import {
  forwardRef,
  Icon,
  IconButton,
  IconButtonProps,
  IconProps,
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
  InputRightElement,
} from '@chakra-ui/react';
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

export interface GlobalSearchInputProps extends InputProps, Omit<ComboboxProps, keyof InputProps> {
  leftElement?(): ReactNode;
  rightElement?(): ReactNode;
}

export const GlobalSearchInput = forwardRef<GlobalSearchInputProps, 'input'>(
  ({ leftElement, rightElement, ...inputProps }, ref) => {
    const { t } = useTranslation('ui');

    return (
      <InputGroup>
        <InputLeftElement pointerEvents="none">
          {leftElement?.() ?? <GlobalSearchIcon />}
        </InputLeftElement>

        <Input
          as={Combobox}
          ref={ref}
          fontSize={'lg'}
          variant="outline"
          border="0"
          focusBorderColor="transparent"
          placeholder={inputProps?.placeholder ?? t('globalSearch.placeholder')}
          _placeholder={{ color: 'gray.400' }}
          {...inputProps}
        />

        <InputRightElement w={'auto'}>
          {rightElement?.() ?? <GlobalSearchClearButton aria-label="Search clear" />}
        </InputRightElement>
      </InputGroup>
    );
  },
);

export const GlobalSearchIcon = forwardRef<IconProps, 'svg'>((props, ref) => (
  <Icon ref={ref} w={6} h={6} as={MagnifyingGlassIcon} color={'gray.400'} {...props} />
));

export const GlobalSearchClearButton = forwardRef<IconButtonProps, 'button'>((props, ref) => {
  const combobox = useComboboxContext();

  if (!combobox?.getState().value) {
    return;
  }

  return (
    <IconButton
      ref={ref}
      h={'full'}
      py={1}
      variant={'link'}
      icon={<Icon as={XMarkIcon} w={5} h={5} />}
      color={'gray.400'}
      onClick={() => combobox.setValue('')}
      onKeyDown={(e) => {
        if (e.code === 'Enter' || e.code === 'Space') {
          combobox.setValue('');
        }
      }}
      {...props}
    />
  );
});

import * as jsurl from 'jsurl2';

export function encodeColumnFilters(
  columnFilters: { id: string; value: unknown }[],
): string | undefined {
  const nonEmptyColumnFilters = columnFilters.filter(({ value }) => value !== null);
  if (!nonEmptyColumnFilters.length) {
    return;
  }
  return jsurl.stringify(nonEmptyColumnFilters);
}

export enum FilterMode {
  Includes = 'includes',
  Excludes = 'excludes',
  After = 'after',
  Before = 'before',
  Between = 'between',
}

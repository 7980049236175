/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type AppendFormItemMutationVariables = Types.Exact<{
  form_id: Types.Scalars['uuid']['input'];
  config?: Types.InputMaybe<Types.Scalars['jsonb']['input']>;
}>;

export type AppendFormItemMutation = {
  __typename?: 'mutation_root';
  update_forms_by_pk?: { __typename?: 'forms'; id: string };
};

export type UpdateFormConfigMutationVariables = Types.Exact<{
  form_id: Types.Scalars['uuid']['input'];
  config: Types.Scalars['jsonb']['input'];
}>;

export type UpdateFormConfigMutation = {
  __typename?: 'mutation_root';
  update_forms_by_pk?: { __typename?: 'forms'; id: string };
};

export type DeleteFormMutationVariables = Types.Exact<{
  form_id: Types.Scalars['uuid']['input'];
}>;

export type DeleteFormMutation = {
  __typename?: 'mutation_root';
  delete_forms_by_pk?: { __typename?: 'forms'; id: string };
};

export const AppendFormItemDocument = `
    mutation AppendFormItem($form_id: uuid!, $config: jsonb) {
  update_forms_by_pk(_append: {config: $config}, pk_columns: {id: $form_id}) {
    id
  }
}
    ` as string & TypedDocumentNode<AppendFormItemMutation, AppendFormItemMutationVariables>;
export const UpdateFormConfigDocument = `
    mutation UpdateFormConfig($form_id: uuid!, $config: jsonb!) {
  update_forms_by_pk(pk_columns: {id: $form_id}, _set: {config: $config}) {
    id
  }
}
    ` as string & TypedDocumentNode<UpdateFormConfigMutation, UpdateFormConfigMutationVariables>;
export const DeleteFormDocument = `
    mutation DeleteForm($form_id: uuid!) {
  delete_forms_by_pk(id: $form_id) {
    id
  }
}
    ` as string & TypedDocumentNode<DeleteFormMutation, DeleteFormMutationVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    AppendFormItem: build.mutation<AppendFormItemMutation, AppendFormItemMutationVariables>({
      query: (variables) => ({ document: AppendFormItemDocument, variables }),
    }),
    UpdateFormConfig: build.mutation<UpdateFormConfigMutation, UpdateFormConfigMutationVariables>({
      query: (variables) => ({ document: UpdateFormConfigDocument, variables }),
    }),
    DeleteForm: build.mutation<DeleteFormMutation, DeleteFormMutationVariables>({
      query: (variables) => ({ document: DeleteFormDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useAppendFormItemMutation, useUpdateFormConfigMutation, useDeleteFormMutation } =
  injectedRtkApi;

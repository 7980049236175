/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type UpdateUserMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
  users_set_input?: Types.InputMaybe<Types.Users_Set_Input>;
  users_append_metadata?: Types.InputMaybe<Types.Users_Append_Input>;
}>;

export type UpdateUserMutation = {
  __typename?: 'mutation_root';
  updateUser?: {
    __typename?: 'users';
    id: string;
    displayName: string;
    metadata: ClientTypes.UserMetaData;
  };
};

export const UpdateUserDocument = `
    mutation UpdateUser($id: uuid!, $users_set_input: users_set_input = {}, $users_append_metadata: users_append_input = {}) {
  updateUser(
    pk_columns: {id: $id}
    _append: $users_append_metadata
    _set: $users_set_input
  ) {
    id
    displayName
    metadata
  }
}
    ` as string & TypedDocumentNode<UpdateUserMutation, UpdateUserMutationVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    UpdateUser: build.mutation<UpdateUserMutation, UpdateUserMutationVariables>({
      query: (variables) => ({ document: UpdateUserDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useUpdateUserMutation } = injectedRtkApi;

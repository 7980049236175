import { Card } from '@chakra-ui/react';
import { OrganizationUserDetailsFragment } from '@main/graphql/fragments/OrganizationUserDetailsFragment.generated';
import { createColumnHelper, Table, useTableSearchQuery } from '@main/ui';
import { useParams } from '@tanstack/react-router';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../../hooks/redux-toolkit-hooks';
import { getCurrentOrgUsersMap, getOrgUsersByRole } from '../../../user/slice';
import { STATUSES } from '../../constants';

export const UsersTab = () => {
  const { t } = useTranslation();
  const params = useParams({ from: '/settings/organization/roles/$roleId' });
  const roleId = params.roleId;

  const users = useAppSelector((state) => getOrgUsersByRole(state, roleId));
  const [globalFilter, setGlobalFilter] = useTableSearchQuery({ searchParam: 'usersSearch' });
  const columns = useUsersTableColumns();

  return (
    <Card variant="table-styles">
      <Table
        data={users}
        columns={columns}
        itemName={{
          singular: t('entities.user').toLowerCase(),
          plural: t('entities.plural.users').toLowerCase(),
          hideSubheading: true,
        }}
        pageSize={15}
        globalFilter={globalFilter}
        onGlobalFilterChange={setGlobalFilter}
      />
    </Card>
  );
};

function useUsersTableColumns() {
  const { t } = useTranslation();
  const currentOrgUsers = useAppSelector(getCurrentOrgUsersMap);

  return useMemo(() => {
    const columnHelper = createColumnHelper<OrganizationUserDetailsFragment>();

    return [
      columnHelper.columns.status({
        id: 'status',
        header: t('users.tableColumns.status'),
        accessorFn: ({ disabled }) => {
          const statusMeta = disabled ? STATUSES.disabled : STATUSES.active;

          return {
            value: statusMeta.value,
            colorScheme: statusMeta.colorScheme,
          };
        },
        size: 120,
      }),
      columnHelper.columns.avatar({
        id: 'avatar',
        header: t('users.tableColumns.name'),
        variant: 'detailed',
        accessorFn: ({ user }) => {
          if (!user) {
            return;
          }

          const member = currentOrgUsers[user.id];
          if (!member) {
            return;
          }

          return {
            id: member.id,
            displayName: member.displayName,
          };
        },
        enableGlobalFilter: true,
      }),
    ];
  }, [currentOrgUsers, t]);
}

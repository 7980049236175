import { Route } from '@tanstack/react-router';

import { Notifications } from '../../../features/user/notifications';
import { accountSettingsLayoutRoute } from './layout';

export const accountNotificationsSettingsRoute = new Route({
  getParentRoute: () => accountSettingsLayoutRoute,
  path: '/notifications',
  component: Notifications,
});

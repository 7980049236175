import { Route, useParams } from '@tanstack/react-router';
import { useEffect } from 'react';

import { ReportDetails } from '../../features/reports/report-details';
import { reportIdPageLoaded } from '../../features/reports/slice';
import { useAppDispatch } from '../../hooks/redux-toolkit-hooks';
import { reportRoute } from './reports';

export const ReportIdPage = () => {
  const dispatch = useAppDispatch();
  const params = useParams({ from: reportRoute.id });
  const reportId = params.reportId as string;

  useEffect(() => {
    dispatch(reportIdPageLoaded({ reportId }));
  }, [reportId, dispatch]);

  return <ReportDetails />;
};

export const reportIdRoute = new Route({
  getParentRoute: () => reportRoute,
  path: '$reportId',
  component: ReportIdPage,
});

import { Field_Entities_Enum } from '@main/graphql/types.generated';
import { Route } from '@tanstack/react-router';

import { FieldConfigsCard } from '../../../../features/custom-fields/field-configs-card';
import { organizationRisksSettingsLayoutRoute } from './layout';

export const organizationRisksCustomFieldsSettingsRoute = new Route({
  getParentRoute: () => organizationRisksSettingsLayoutRoute,
  path: '/custom-fields',
  component: () => <FieldConfigsCard entityName={Field_Entities_Enum.Risk} />,
});

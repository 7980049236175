import { datadogLogs } from '@datadog/browser-logs';
import { toError } from '@main/shared/utils';
import { errorToast, successToast, useDrawer } from '@main/ui';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { getCurrentUserSelectedOrgId } from '../user/slice';
import { useCreateTaskMutation } from './manage-tasks.generated';

export const useCreateTask = () => {
  const { t } = useTranslation();
  const drawer = useDrawer();
  const orgId = useAppSelector(getCurrentUserSelectedOrgId);
  const [createTask, { isLoading: isCreatingTask }] = useCreateTaskMutation();

  const onTaskCreate = useCallback(async () => {
    try {
      const taskDrawer = await drawer.openLoading({ entity: 'task' });

      const result = await createTask({
        input: {
          organization_id: orgId,
        },
      }).unwrap();

      if (!result.insert_tasks_one?.id) {
        throw new Error('Failed to get task id from response');
      }

      taskDrawer.load({ entityId: result.insert_tasks_one.id });
      successToast(t('successMessages.createSucceeded', { entity: t('entities.task') }));
    } catch (error) {
      drawer.close();
      errorToast(t('errorMessages.createFailed', { entity: t('entities.task') }));
      datadogLogs.logger.error('Task create failed', {}, toError(error));
    }
  }, [createTask, drawer, orgId, t]);

  return { onTaskCreate, isCreatingTask };
};

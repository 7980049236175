import { Flex, FlexProps, Text, useColorModeValue } from '@chakra-ui/react';
import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { PlainTextPlugin } from '@lexical/react/LexicalPlainTextPlugin';
import { useRef } from 'react';

import { MentionedObject } from '../lexical';
import { CommentPlugin } from '../lexical/plugins/CommentPlugin';
import MentionsPlugin from '../lexical/plugins/MentionsPlugin';

type CommentPluginProps = {
  onSubmit: () => void;
  mentionsList: MentionedObject[];
  placeholder: string;
} & FlexProps;
export const CommentPlugins = ({
  onSubmit,
  mentionsList,
  placeholder,
  ...restProps
}: CommentPluginProps) => {
  const mentionTypeaheadRef = useRef<HTMLDivElement>(null);
  const placeholderColor = useColorModeValue('gray.300', 'gray.500');

  return (
    <Flex {...restProps} w={'full'} minH={8} alignItems={'center'} position={'relative'}>
      <PlainTextPlugin
        contentEditable={<ContentEditable className="editor-input" />}
        placeholder={
          <Text className="editor-placeholder" color={placeholderColor}>
            {placeholder}
          </Text>
        }
        ErrorBoundary={LexicalErrorBoundary}
      />
      <MentionsPlugin ref={mentionTypeaheadRef} data={mentionsList} />

      {/* Handles Enter key press, and submits the comment form */}
      <CommentPlugin onSubmit={onSubmit} typeaheadRef={mentionTypeaheadRef} />

      {/**
       * Provides "CLEAR_EDITOR_COMMAND" command,
       * that can be dispatched to clear the editor content.
       */}
      <ClearEditorPlugin />
    </Flex>
  );
};

import { datadogLogs } from '@datadog/browser-logs';
import { useCreateCustomReportMutation } from '@main/graphql/mutations/CreateCustomReport.generated';
import { useGetReportsForOrganizationQuery } from '@main/graphql/queries/GetReportsForOrganization.generated';
import { toError } from '@main/shared/utils';
import { errorToast, successToast } from '@main/ui';
import { SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { getCurrentUserSelectedOrg } from '../user/slice';
import { ReportForm, ReportSchema } from './report-form';

export const CreateReport = ({ closeModal }: { closeModal: () => void }) => {
  const { t } = useTranslation();
  const [createReport] = useCreateCustomReportMutation();
  const organization = useAppSelector(getCurrentUserSelectedOrg);

  const { refetch: refetchReportsList } = useGetReportsForOrganizationQuery({
    organizationId: organization.id,
  });

  const onSubmit: SubmitHandler<ReportSchema> = async (data) => {
    try {
      await createReport({
        reportObject: {
          type: data.reportType,
          name: data.reportName,
          description: data.reportDescription,
          organization_id: organization.id,
          filters: [],
        },
      }).unwrap();

      refetchReportsList();
      successToast(t('successMessages.createSucceeded', { entity: t('entities.report') }));
      closeModal();
    } catch (error) {
      errorToast(t('errorMessages.createFailed', { entity: t('entities.report') }));
      datadogLogs.logger.error('Creating new report failed', {}, toError(error));
    }
  };

  return <ReportForm onSubmit={onSubmit} closeModal={closeModal} />;
};

import { Box, Text, useColorModeValue } from '@chakra-ui/react';
import { Global } from '@emotion/react';
import { CodeNode } from '@lexical/code';
import { LinkNode } from '@lexical/link';
import { ListItemNode, ListNode } from '@lexical/list';
import {
  $convertFromMarkdownString,
  $convertToMarkdownString,
  CHECK_LIST,
  TRANSFORMERS,
} from '@lexical/markdown';
import { InitialConfigType, LexicalComposer } from '@lexical/react/LexicalComposer';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { TabIndentationPlugin } from '@lexical/react/LexicalTabIndentationPlugin';
import { useLexicalEditable } from '@lexical/react/useLexicalEditable';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { useEffectOnce } from '@main/shared/utils';
import { EditorState, LexicalEditor } from 'lexical';
import { ReactNode, useCallback, useMemo, useState } from 'react';

import { BlurEditorPlugin } from '../lexical/plugins/BlurEditorPlugin';
import { BreakListsPlugin } from '../lexical/plugins/BreakListsPlugin';
import { ControlledLexicalPlugin } from '../lexical/plugins/ControlledPlugin';
import theme from '../lexical/themes/markdown';
import styles from '../lexical/themes/markdown/styles';

const MD_TRANSFORMERS = [CHECK_LIST, ...TRANSFORMERS];

export interface MarkdownEditorProps extends MarkdownPluginsProps {
  id?: string;
  config?: Partial<InitialConfigType>;
  placeholder?: ReactNode;
  contentEditable?: ReactNode;
}

export function MarkdownEditor(props: MarkdownEditorProps) {
  const defaultValue = props.defaultValue ?? '';
  const placeholderColor = useColorModeValue('gray.400', 'gray.500');

  return (
    <LexicalComposer
      key={defaultValue}
      initialConfig={{
        namespace: `markdown-${props.id ?? 'editor'}`,
        editorState: () => $convertFromMarkdownString(defaultValue, MD_TRANSFORMERS),
        onError: console.error,
        nodes: [HeadingNode, QuoteNode, CodeNode, ListNode, ListItemNode, LinkNode],
        theme,
        ...props.config,
      }}
    >
      <Global styles={styles} />
      <Box className="editor-container" minH="initial">
        <RichTextPlugin
          placeholder={
            <Text className="editor-placeholder" color={placeholderColor}>
              {props.placeholder}
            </Text>
          }
          contentEditable={
            props.contentEditable ? (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <>{props.contentEditable}</>
            ) : (
              <ContentEditable className="editor-input" />
            )
          }
          ErrorBoundary={LexicalErrorBoundary}
        />
      </Box>
      <MarkdownPlugins {...props} defaultValue={defaultValue} />
    </LexicalComposer>
  );
}

export interface MarkdownPluginsProps {
  value?: string;
  defaultValue?: string;
  onValueChange?(value: string, editor: LexicalEditor): void;
  onChange?(state: EditorState, editor: LexicalEditor): void;
  onBlur?(value: string, editor: LexicalEditor): void;
}

export function MarkdownPlugins({
  onValueChange,
  onChange,
  onBlur,
  ...props
}: MarkdownPluginsProps) {
  const [editor] = useLexicalComposerContext();
  const isEditable = useLexicalEditable();
  const [prevValue, setPrevValue] = useState(props.defaultValue);

  const updater = useCallback(
    (value = '') => $convertFromMarkdownString(value, MD_TRANSFORMERS),
    [],
  );

  const handleOnChange: MarkdownPluginsProps['onChange'] = useMemo(
    () =>
      onValueChange || onChange
        ? (state, editor, ...args) => {
            if (onValueChange) {
              state.read(() => {
                const value = $convertToMarkdownString(MD_TRANSFORMERS);

                if (value === prevValue) {
                  return;
                }

                setPrevValue(value);
                onValueChange(value, editor);
              });
            }

            onChange?.(state, editor, ...args);
          }
        : undefined,
    [onValueChange, onChange, prevValue],
  );

  const handleOnBlur = useMemo(
    () =>
      onBlur
        ? (editor: LexicalEditor) =>
            editor
              .getEditorState()
              .read(() => onBlur($convertToMarkdownString(MD_TRANSFORMERS), editor))
        : undefined,
    [onBlur],
  );

  useEffectOnce(() => void setTimeout(() => editor.blur(), 0));

  return (
    <>
      <MarkdownShortcutPlugin transformers={MD_TRANSFORMERS} />
      <LinkPlugin validateUrl={() => true} />
      <TabIndentationPlugin />
      <BreakListsPlugin />
      <ControlledLexicalPlugin value={props.value} updater={updater} />
      {handleOnChange && isEditable ? <OnChangePlugin onChange={handleOnChange} /> : ''}
      {handleOnBlur ? <BlurEditorPlugin onBlur={handleOnBlur} /> : ''}
    </>
  );
}

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { mergeRegister } from '@lexical/utils';
import { $getRoot, BLUR_COMMAND, COMMAND_PRIORITY_LOW, FOCUS_COMMAND } from 'lexical';
import { useLayoutEffect, useState } from 'react';

/**
 *
 * We detect editor's active state by following two conditions:
 * 1 - If Editor is focused -> state is @Active
 * 2 - If Editor is not empty -> state is @Active
 *
 * The following approach of detecting the focus state is suggested by Lexical maintainer:
 * https://stackoverflow.com/questions/72205629/how-can-i-detect-if-a-lexical-react-editor-is-focused/72212077#72212077
 *
 */

export const useIsEditorActive = () => {
  const [editor] = useLexicalComposerContext();
  const [hasFocus, setHasFocus] = useState(() => {
    return editor.getRootElement() === document.activeElement;
  });

  useLayoutEffect(() => {
    setHasFocus(editor.getRootElement() === document.activeElement);

    return mergeRegister(
      editor.registerCommand(
        FOCUS_COMMAND,
        () => {
          setHasFocus(true);
          return false;
        },
        COMMAND_PRIORITY_LOW,
      ),
      editor.registerCommand(
        BLUR_COMMAND,
        () => {
          /* On blur check if input has any value */
          if ($getRoot().getTextContent().trim() !== '') {
            return true;
          }

          setHasFocus(false);
          return false;
        },
        COMMAND_PRIORITY_LOW,
      ),
    );
  }, [editor]);

  return hasFocus;
};

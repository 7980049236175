import '@main/polyfills/promise-with-resolvers';

import { WorkerPool, WorkerPoolEvent } from '@pooley/core';
import { PromiseWorkerProcessorFactory } from '@pooley/promise';
import { StaticWorkerPoolScaler } from '@pooley/scalers';

import { BulkDownloaderLogger } from './logger';
import { PausableQueue } from './pausable-queue';
import { StreamDownloader } from './stream-downloader';
import { StreamSink } from './stream-sink';

export interface DownloadFile<TFile> {
  file: TFile;
  name: string;
}

export class BulkDownloader<TFile> {
  constructor(
    protected readonly downloader: StreamDownloader<TFile>,
    protected readonly sink: StreamSink,
    protected readonly logger: BulkDownloaderLogger = console,
  ) {}

  start(files: readonly DownloadFile<TFile>[]) {
    return new BulkDownloaderProgress(files, this.downloader, this.sink, this.logger);
  }
}

interface DownloadResult<TFile> {
  download: DownloadFile<TFile>;
  error?: unknown;
}

export class BulkDownloaderProgress<TFile> {
  static POOL_SIZE = 4;
  readonly totalFilesCount: number;
  protected readonly files: DownloadFile<TFile>[];
  protected readonly downloadedFiles: DownloadFile<TFile>[] = [];
  protected status = BulkDownloaderStatus.Paused;
  protected progress = 0;
  protected donePromise = Promise.withResolvers<void>();
  protected canceledReason?: unknown;
  protected abortController = new AbortController();
  protected retryCount = 0;
  protected readonly queue = new PausableQueue<DownloadFile<TFile>>();
  protected readonly pool = new WorkerPool({
    task: (download: DownloadFile<TFile>) => this.downloadFileTask(download),
    queue: this.queue,
    poolScaler: new StaticWorkerPoolScaler(this.poolSize),
    processorFactory: new PromiseWorkerProcessorFactory<
      DownloadFile<TFile>,
      DownloadResult<TFile>
    >(),
  });
  protected readonly errors: unknown[] = [];

  constructor(
    files: readonly DownloadFile<TFile>[],
    protected readonly downloader: StreamDownloader<TFile>,
    protected readonly sink: StreamSink,
    protected readonly logger: BulkDownloaderLogger = console,
    protected readonly eventBus: BulkDownloaderEventTarget = new EventTarget(),
    protected readonly poolSize = BulkDownloaderProgress.POOL_SIZE,
  ) {
    this.files = [...files];
    this.totalFilesCount = files.length;
    this.queue.pause();
    this.queue.pushAll(this.files);
    this.resume();
  }

  getPendingFiles() {
    return [...this.files];
  }

  getDownloadedFiles() {
    return [...this.downloadedFiles];
  }

  getStatus() {
    return this.status;
  }

  onStatusChange(cb: (status: BulkDownloaderStatus) => void, once?: boolean) {
    const listener = (event: StatusChangeEvent) => cb(event.status);
    this.eventBus.addEventListener(StatusChangeEvent.eventName, listener, { once });
    return () => this.eventBus.removeEventListener(StatusChangeEvent.eventName, listener);
  }

  getProgress() {
    return this.progress;
  }

  onProgressChange(cb: (progress: number) => void, once?: boolean) {
    const listener = (event: ProgressChangeEvent) => cb(event.progress);
    this.eventBus.addEventListener(ProgressChangeEvent.eventName, listener, { once });
    return () => this.eventBus.removeEventListener(ProgressChangeEvent.eventName, listener);
  }

  getCanceledReason() {
    return this.canceledReason;
  }

  getRetryCount() {
    return this.retryCount;
  }

  getErrors() {
    return [...this.errors];
  }

  async pause() {
    if (this.status !== BulkDownloaderStatus.Downloading) {
      throw new Error('Illegal state: Cannot pause when not downloading');
    }
    this.logger.debug('Pausing download');

    this.queue.pause();
    this.setStatus(BulkDownloaderStatus.Paused);
    this.abortController.abort(new Error(BulkDownloaderStatus.Paused));
  }

  resume() {
    if (this.status !== BulkDownloaderStatus.Paused) {
      throw new Error('Illegal state: Cannot resume when not paused');
    }
    this.logger.debug('Resuming download');

    this.abortController = new AbortController();
    this.setStatus(BulkDownloaderStatus.Downloading);
    this.resumeDownload();
  }

  async cancel(reason: unknown = new Error(BulkDownloaderStatus.Canceled)) {
    if (
      this.status === BulkDownloaderStatus.Completed ||
      this.status === BulkDownloaderStatus.Canceled
    ) {
      return;
    }
    this.logger.debug('Cancelling download', { reason });

    this.queue.pause();
    this.pool.terminate();
    this.canceledReason = reason;
    this.setStatus(BulkDownloaderStatus.Canceled);
    this.donePromise.reject(this.canceledReason);
    this.abortController.abort(this.canceledReason);
  }

  retry() {
    if (this.status !== BulkDownloaderStatus.Canceled) {
      throw new Error('Illegal state: Cannot retry when not cancelled');
    }
    this.logger.debug('Retrying download', {
      retryCount: this.retryCount,
      errors: this.errors,
    });

    this.retryCount++;
    this.errors.length = 0;
    this.canceledReason = undefined;
    this.abortController = new AbortController();
    this.donePromise = Promise.withResolvers();
    this.setStatus(BulkDownloaderStatus.Downloading);
    this.resumeDownload();
  }

  whenDone(): Promise<void> {
    return this.donePromise.promise;
  }

  protected resumeDownload() {
    const cancellables = [
      this.pool.on(WorkerPoolEvent.Data, (data) => {
        if (data.data.error) {
          if (
            this.status === BulkDownloaderStatus.Canceled ||
            this.status === BulkDownloaderStatus.Paused ||
            (data.data.error instanceof Error && data.data.error.message === 'Skipped')
          ) {
            this.logger.debug('Skipped download', {
              ...data.data,
              progress: this.progress,
              errorsCount: this.errors.length,
            });
            // Add back to the queue skipped download
            this.queue.pushAll([data.data.download]);
            return;
          }
          this.logger.error('Error downloading file', {
            ...data.data,
            progress: this.progress,
            errorsCount: this.errors.length,
          });
          this.errors.push(data.data.error);
          this.cancel(data.data.error);
        } else {
          this.downloadedFiles.push(data.data.download);
          this.files.splice(this.files.indexOf(data.data.download), 1);
          this.setProgress((this.downloadedFiles.length / this.totalFilesCount) * 100);
          this.logger.debug('Downloaded file', {
            download: data.data.download,
            progress: this.progress,
            errorsCount: this.errors.length,
          });
        }
      }),
      this.pool.on(WorkerPoolEvent.Empty, () => {
        if (this.files.length === 0 && this.errors.length === 0) {
          this.logger.log('Download completed', {
            donwloadedFiles: this.downloadedFiles,
            retries: this.retryCount,
          });
          this.pool.destroy();
          this.setStatus(BulkDownloaderStatus.Completed);
          this.donePromise.resolve();
          this.abortController.abort(BulkDownloaderStatus.Completed);
        } else if (this.errors.length > 0) {
          this.logger.error('Download failed', {
            errors: this.errors,
            retry: this.retryCount,
            pendingFiles: this.files,
            donwloadedFiles: this.downloadedFiles,
          });
          this.cancel(new BulkDownloadFailedError(this.errors));
        } else {
          this.logger.debug('Download paused');
        }
      }),
    ];

    this.abortController.signal.addEventListener(
      'abort',
      () => cancellables.forEach((cancellable) => cancellable()),
      { once: true },
    );

    this.queue.resume();
  }

  protected async downloadFileTask(download: DownloadFile<TFile>): Promise<DownloadResult<TFile>> {
    if (this.status !== BulkDownloaderStatus.Downloading) {
      return { download, error: new Error('Skipped') };
    }

    this.logger.debug('Downloading file', { download });

    try {
      const fileStream = await this.downloader.downloadFile(download.file, {
        signal: this.abortController.signal,
      });
      await this.sink.streamFile(fileStream, download.name, {
        signal: this.abortController.signal,
      });

      return { download };
    } catch (e) {
      const error = e ?? new Error('Something went wrong');
      return { download, error };
    }
  }

  protected setStatus(status: BulkDownloaderStatus) {
    if (this.status === status) {
      return;
    }

    this.status = status;
    this.eventBus.dispatchEvent(new StatusChangeEvent(status));
  }

  protected setProgress(progress: number) {
    if (this.progress === progress) {
      return;
    }

    this.progress = progress;
    this.eventBus.dispatchEvent(new ProgressChangeEvent(progress));
  }
}

export enum BulkDownloaderStatus {
  Downloading = 'downloading',
  Paused = 'paused',
  Completed = 'completed',
  Canceled = 'canceled',
}

export interface BulkDownloaderEventMap {
  [StatusChangeEvent.eventName]: StatusChangeEvent;
  [ProgressChangeEvent.eventName]: ProgressChangeEvent;
}

export interface BulkDownloaderEventTarget<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TEventMap extends BulkDownloaderEventMap & Record<string, any> = BulkDownloaderEventMap,
> extends EventTarget {
  addEventListener<K extends keyof TEventMap>(
    type: K,
    callback: ((event: TEventMap[K]) => void) | EventListenerObject | null,
    options?: AddEventListenerOptions,
  ): void;
  removeEventListener<K extends keyof TEventMap>(
    type: K,
    callback: ((event: TEventMap[K]) => void) | EventListenerObject | null,
  ): void;
  dispatchEvent(event: TEventMap[keyof TEventMap]): boolean;
}

class StatusChangeEvent extends Event {
  static readonly eventName = 'bulk-downloader:status-change';
  constructor(public readonly status: BulkDownloaderStatus) {
    super(StatusChangeEvent.eventName);
  }
}

class ProgressChangeEvent extends Event {
  static readonly eventName = 'bulk-downloader:progress-change';
  constructor(public readonly progress: number) {
    super(ProgressChangeEvent.eventName);
  }
}

export class BulkDownloadFailedError extends Error {
  constructor(public readonly errors: unknown[]) {
    super(`Bulk download failed with ${errors.length} errors:\n-${errors.join('\n-')}`);
  }
}

import { MergedAbortController } from './merge-abort-controller';

/**
 * Wait for the Promise to resolve or reject when signal is aborted.
 */
export async function awaitUntil<T>(promise: Promise<T>, signal: AbortSignal): Promise<T> {
  const controller = new MergedAbortController(signal);

  try {
    return await Promise.race([promise, signalToPromise(controller.signal)]);
  } finally {
    controller.abort();
  }
}

/**
 * Wait for the Promise to resolve or reject when timeout is reached.
 */
export async function awaitTimeout<T>(
  promise: Promise<T>,
  timeoutMs: number,
  timeoutError = () => new Error('Timeout'),
): Promise<T> {
  const timeout = Promise.withResolvers<never>();
  const timeoutId = setTimeout(() => timeout.reject(timeoutError()), timeoutMs);

  try {
    return await Promise.race([promise, timeout.promise]);
  } finally {
    clearTimeout(timeoutId);
  }
}

/**
 * Convert an {@link AbortSignal} to a Promise, which rejects when it becomes aborted and never resolves.
 *
 * Make sure that signal is aborted at some point, otherwise the Promise will never be rejected
 * and will cause a memory leak.
 */
export function signalToPromise(signal: AbortSignal): Promise<never> {
  if (signal.aborted) {
    return Promise.reject(signal.reason);
  }

  return new Promise((_, reject) => signal.addEventListener('abort', reject, { once: true }));
}

import {
  Box,
  Button,
  ButtonProps,
  Card,
  CardBody,
  Flex,
  forwardRef,
  HStack,
  Icon,
  IconButton,
  Tag,
  ThemingProps,
  Tooltip,
  useColorModeValue,
} from '@chakra-ui/react';
import { getHashedColor } from '@main/ui';
import { UnlinkIcon } from '@main/ui/icons';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

export type EntityReferenceCardProps = {
  children: ReactNode;
  onUnlink?: () => void;
};

export const EntityReferenceCard = ({ children, onUnlink }: EntityReferenceCardProps) => {
  return (
    <Card variant="outline">
      <CardBody p={4}>
        <Flex justify="space-between" gap={6}>
          <Flex direction="column" gap={2} w={'full'}>
            {children}
          </Flex>

          {onUnlink && (
            <Box>
              <UnlinkButton onUnlink={onUnlink} />
            </Box>
          )}
        </Flex>
      </CardBody>
    </Card>
  );
};

const EntityReference = forwardRef<ButtonProps, 'button'>((props, ref) => {
  const referenceColor = useColorModeValue('gray.800', 'gray.200');

  return (
    <Button
      ref={ref}
      w={'full'}
      fontSize={'sm'}
      fontWeight={'medium'}
      variant={'link'}
      textAlign={'left'}
      justifyContent={'left'}
      whiteSpace={'normal'}
      color={referenceColor}
      {...props}
    />
  );
});

type TagProps = {
  label: string;
  color?: ThemingProps['colorScheme'];
};

const EntityTags = ({ tags }: { tags: TagProps[] }) => {
  return (
    <HStack spacing={1}>
      {tags?.map((tag, index) => (
        <Tag key={tag.label + index} colorScheme={tag.color ?? getHashedColor(tag.label)}>
          {tag.label}
        </Tag>
      ))}
    </HStack>
  );
};

const UnlinkButton = ({ onUnlink }: { onUnlink: () => void }) => {
  const { t } = useTranslation();

  return (
    <Tooltip label={t('buttons.unlink')} placement="top" openDelay={300} hasArrow>
      <IconButton
        variant="outline"
        size="sm"
        w="38px"
        color="gray.500"
        aria-label={t('buttons.unlink')}
        icon={<Icon as={UnlinkIcon} />}
        onClick={onUnlink}
      />
    </Tooltip>
  );
};

EntityReferenceCard.Reference = EntityReference;
EntityReferenceCard.Tags = EntityTags;

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { RoleDetailsFragmentDoc } from '../fragments/RoleDetailsFragment.generated';
import { api } from '@main/graphql/shared-api';
export type GetRoleByIdQueryVariables = Types.Exact<{
  roleId: Types.Scalars['uuid']['input'];
}>;

export type GetRoleByIdQuery = {
  __typename?: 'query_root';
  roles_by_pk?: {
    __typename?: 'roles';
    id: string;
    name: string;
    system_role?: Types.System_Roles_Enum;
    organization_id: string;
  };
};

export const GetRoleByIdDocument = `
    query GetRoleById($roleId: uuid!) {
  roles_by_pk(id: $roleId) {
    ...RoleDetails
  }
}
    ${RoleDetailsFragmentDoc}` as string &
  TypedDocumentNode<GetRoleByIdQuery, GetRoleByIdQueryVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetRoleById: build.query<GetRoleByIdQuery, GetRoleByIdQueryVariables>({
      query: (variables) => ({ document: GetRoleByIdDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetRoleByIdQuery, useLazyGetRoleByIdQuery } = injectedRtkApi;

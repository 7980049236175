import { StringOrNumberValueKeys } from './string-or-number-value-keys';

export function mapByKey<T, K extends StringOrNumberValueKeys<T>>(
  array: T[],
  key: K,
): Record<string, T> {
  return array.reduce((accumulator: Record<string, T>, currentValue: T) => {
    const groupKey: string = currentValue[key] as unknown as string;

    accumulator[groupKey] = currentValue;

    return accumulator;
  }, {});
}

import { Row } from '@tanstack/react-table';
import { generateKeyBetween, generateNKeysBetween } from 'fractional-indexing';

import { OrderableTableData } from './use-table-row-reorder';

export function updateRowsOrder<T extends OrderableTableData>(
  fromRow: Row<T>,
  toRow: Row<T>,
  rows: Row<T>[] | readonly Row<T>[],
): T[] {
  const fromIndex = rows.indexOf(fromRow);
  const toIndex = rows.indexOf(toRow);
  const direction = fromIndex < toIndex ? 1 : -1;
  const toIndex2 = toIndex + direction;
  const aIndex = Math.min(toIndex, toIndex2);
  const bIndex = Math.max(toIndex, toIndex2);
  const fromOrder = fromRow.original.order ?? null;
  let aOrder = rows[aIndex]?.original.order ?? null;
  let bOrder = rows[bIndex]?.original.order ?? null;

  let updatedFromIndex = 0;
  let updatedRows = [{ ...fromRow.original }];

  if ((aOrder === null || fromOrder === null) && bOrder === null) {
    // Generate new indexes until max(aIndex, bIndex, fromIndex)
    const lastIndex = Math.min(Math.max(aIndex, bIndex, fromIndex), rows.length - 1);
    const firstIndex = rows.findLastIndex((row) => !!row.original.order);
    const offsetIndex = firstIndex + 1;
    const lastOrder = rows[firstIndex]?.original.order ?? null;
    const totalIndexes = Math.min(lastIndex - offsetIndex + 1, rows.length);

    const orders = generateNKeysBetween(lastOrder, null, totalIndexes);
    updatedRows = orders.map(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      (order, i) => ({ ...rows[i + offsetIndex]!.original, order } as T),
    );

    aOrder = updatedRows[aIndex - offsetIndex]?.order ?? null;
    bOrder = updatedRows[bIndex - offsetIndex]?.order ?? null;
    updatedFromIndex = Math.max(fromIndex - offsetIndex, 0);

    if (offsetIndex > fromIndex) {
      updatedFromIndex = 0;
      updatedRows.unshift({ ...fromRow.original });
    }
  }

  const order = generateKeyBetween(aOrder, bOrder);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  updatedRows[updatedFromIndex]!.order = order;

  return updatedRows;
}

import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  CloseButton,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Textarea,
  useDisclosure,
  UseDisclosureReturn,
} from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { zodResolver } from '@hookform/resolvers/zod';
import { SearchEntitiesEnum } from '@main/graphql/types.generated';
import { getTodayDateString, toError } from '@main/shared/utils';
import { errorToast, successToast, useLocalStorageState, useStableCallback } from '@main/ui';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { AppGlobalSearchResult } from '../global-search/use-global-search';
import { EntitySearch } from '../link-entity/entity-search';
import { EntitySearchResultsPopover } from '../link-entity/entity-search-results';
import { useEntitySearch } from '../link-entity/use-entity-search';
import { getCurrentUserSelectedOrg } from '../user/slice';
import { useSendQuestionnaireModalToVendorsMutation } from './send-questionnaire-modal.generated';

const vendorQuestionnaireSchema = z
  .object({
    vendors: z.array(z.string().uuid()),
    allVendors: z.boolean(),
    dueDate: z.string().min(1),
    subject: z.string().min(1),
    message: z.string().optional(),
  })
  .refine((data) => data.allVendors || data.vendors.length > 0, {
    message: 'questionnaires.sendQuestionnareModal.form.allVendors.error',
    path: ['allVendors'],
  });
type VendorQuestionnaireSchema = z.infer<typeof vendorQuestionnaireSchema>;

export interface SendQuestionnaireModalProps {
  questionnaireId: string;
  disclosure: UseDisclosureReturn;
}

export function SendQuestionnaireModal(props: SendQuestionnaireModalProps) {
  const { t } = useTranslation();

  return (
    <Modal {...props.disclosure} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('questionnaires.sendQuestionnareModal.heading')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody mb={2}>
          <SendQuestionnaireForm {...props} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export function SendQuestionnaireForm({
  questionnaireId,
  disclosure,
}: SendQuestionnaireModalProps) {
  const { t } = useTranslation();
  const organization = useAppSelector(getCurrentUserSelectedOrg);
  const [showAlertMessage, setShowAlertMessage] = useLocalStorageState(
    'questionnaire:sendQuestionnaireModalAlert',
    { defaultValue: true },
  );

  const defaultSubject = t('questionnaires.sendQuestionnareModal.form.subject.defaultValue', {
    orgName: organization.name,
  });

  const form = useForm<VendorQuestionnaireSchema>({
    shouldFocusError: false,
    resolver: zodResolver(vendorQuestionnaireSchema),
    defaultValues: {
      vendors: [],
      allVendors: false,
      dueDate: dayjs().add(7, 'days').format('YYYY-MM-DD'),
      subject: defaultSubject,
    },
  });

  const [sendQuestionnaire, { isLoading }] = useSendQuestionnaireModalToVendorsMutation();

  const vendorsSearch = useEntitySearch({ entities: [SearchEntitiesEnum.Vendors] });
  const vendorResultsDisclosure = useDisclosure();

  function onVendorsSearchChange() {
    if (!vendorResultsDisclosure.isOpen) {
      vendorResultsDisclosure.onOpen();
    }
  }

  function onVendorsSearchFocus() {
    vendorResultsDisclosure.onOpen();
  }

  function onVendorsSearchBlur() {
    vendorResultsDisclosure.onClose();
  }

  function setVendors(vendors: AppGlobalSearchResult[]) {
    vendorsSearch.provider.onResultsSelect?.(vendors);
    form.setValue(
      'vendors',
      vendors.map((result) => result.id),
      { shouldValidate: true },
    );
  }

  const allVendors = form.watch('allVendors');
  const [stashedVendors, setStashedVendors] = useState<AppGlobalSearchResult[]>([]);
  const stashVendors = useStableCallback(() => {
    setStashedVendors(vendorsSearch.selectedResults);
    setVendors([]);
  });
  const restoreVendors = useStableCallback(() => {
    setVendors(stashedVendors);
    setStashedVendors([]);
  });
  useEffect(() => {
    if (allVendors) {
      stashVendors();
    } else {
      restoreVendors();
    }
  }, [allVendors, restoreVendors, stashVendors]);

  useEffect(() => form.watch(() => form.trigger()).unsubscribe, [form]);

  const onSubmit = form.handleSubmit(async (data) => {
    if (!form.formState.isValid) {
      return;
    }

    try {
      await sendQuestionnaire({
        input: {
          questionnaireId,
          vendors: data.vendors?.length ? data.vendors.map((id) => ({ id })) : undefined,
          dueDate: data.dueDate,
          subject: data.subject,
          customMessage: data.message,
        },
      }).unwrap();

      successToast(
        t('questionnaires.sendQuestionnareModal.successToast.title'),
        t('questionnaires.sendQuestionnareModal.successToast.description'),
      );
      disclosure.onClose();
    } catch (error) {
      errorToast(t('questionnaires.sendQuestionnareModal.errorToast.title'));
      datadogLogs.logger.error(
        'Failed to send vendor questionnaire',
        { questionnaireId, vendors: data.vendors },
        toError(error),
      );
    }
  });

  return (
    <form onSubmit={onSubmit}>
      <Stack spacing={6}>
        {showAlertMessage && (
          <Alert status="warning">
            <AlertIcon />
            <AlertTitle fontWeight="normal">
              {t('questionnaires.sendQuestionnareModal.warningMessage')}
            </AlertTitle>
            <CloseButton
              alignSelf="flex-start"
              position="relative"
              right={-1}
              top={-1}
              onClick={() => setShowAlertMessage(false)}
            />
          </Alert>
        )}

        <FormControl isDisabled={!!allVendors}>
          <FormLabel>{t('questionnaires.sendQuestionnareModal.form.vendors.label')}</FormLabel>
          <Box mx="-4">
            <EntitySearch
              {...vendorsSearch}
              overflow="hidden"
              provider={{
                ...vendorsSearch.provider,
                onResultsSelect: setVendors,
              }}
              input={{
                ...vendorsSearch.input,
                placeholder: t('questionnaires.sendQuestionnareModal.form.vendors.placeholder'),
                onChange: onVendorsSearchChange,
                onFocus: onVendorsSearchFocus,
                onBlur: onVendorsSearchBlur,
              }}
            >
              <EntitySearchResultsPopover
                {...vendorsSearch.results}
                resultsHeader=""
                popoverDisclosure={vendorResultsDisclosure}
                p="0"
                popoverWidth="464px"
                popoverHeight="300px"
              />
            </EntitySearch>
          </Box>
        </FormControl>

        <FormControl
          isInvalid={!!form.formState.errors.allVendors && form.formState.isSubmitted}
          mt="2!"
        >
          <Checkbox {...form.register('allVendors')}>
            {t('questionnaires.sendQuestionnareModal.form.allVendors.label')}
          </Checkbox>

          <FormErrorMessage>
            {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
            {t(form.formState.errors.allVendors?.message as any)}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!form.formState.errors.dueDate && form.formState.isSubmitted}>
          <FormLabel>{t('questionnaires.sendQuestionnareModal.form.dueDate.label')}</FormLabel>
          <Input
            {...form.register('dueDate')}
            placeholder={t('questionnaires.sendQuestionnareModal.form.dueDate.placeholder')}
            type="date"
            min={getTodayDateString()}
          ></Input>
        </FormControl>

        <FormControl isInvalid={!!form.formState.errors.subject && form.formState.isSubmitted}>
          <FormLabel>{t('questionnaires.sendQuestionnareModal.form.subject.label')}:</FormLabel>
          <Input
            {...form.register('subject')}
            placeholder={t('questionnaires.sendQuestionnareModal.form.subject.placeholder')}
          />
        </FormControl>

        <FormControl isInvalid={!!form.formState.errors.message && form.formState.isSubmitted}>
          <FormLabel>{t('questionnaires.sendQuestionnareModal.form.message.label')}:</FormLabel>
          <Textarea
            {...form.register('message')}
            placeholder={t('questionnaires.sendQuestionnareModal.form.message.placeholder')}
          />
        </FormControl>

        <ButtonGroup justifyContent="end">
          <Button colorScheme="gray" onClick={disclosure.onClose}>
            {t('buttons.cancel')}
          </Button>
          <Button type="submit" colorScheme="blue" isLoading={isLoading}>
            {t('buttons.send')}
          </Button>
        </ButtonGroup>
      </Stack>
    </form>
  );
}

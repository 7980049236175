import { ResourceStatus } from '@aws-sdk/client-inspector2';

export type EvidenceIntegrationConfig =
  | AwsRdsIntegrationConfig
  | CrowdstrikeAntiMalwareIntegrationConfig
  | GcpCloudSqlIntegrationConfig
  | JiraIssuesExportIntegrationConfig
  | QualysVulnerabilityIntegrationConfig
  | SharepointFileSyncIntegrationConfig
  | VeracodeVulnerabilityIntegrationConfig
  | OneloginSuperUsersIntegrationConfig
  | OneloginMfaIntegrationConfig
  | AwsObjectStorageIntegrationConfig
  | AwsGuarddutyReportIntegrationConfig
  | AwsInspectorIntegrationConfig;

export enum EvidenceIntegrationType {
  AntiMalware = 'anti-malware',
  Database = 'database',
  FileSync = 'file-sync',
  IssuesExport = 'issues-export',
  OldestChange = 'oldest-change',
  PageExport = 'page-export',
  Vulnerability = 'vulnerability',
  SuperUsers = 'super-users',
  Mfa = 'mfa',
  ObjectStorage = 'object-storage',
  GuarddutyReport = 'guardduty-report',
  Inspector = 'inspector',
}

export type AwsRdsIntegrationConfig = {
  type: EvidenceIntegrationType.Database;
  region: string;
  dbInstanceIds?: string[];
  dbClusterIds?: string[];
  checks: DBIntegrationCheck[];
};

export enum DBIntegrationCheck {
  Encryption = 'encryption',
  MultiAZ = 'multiAZ',
}

export type AzureDatabaseIntegrationConfig = {
  type: EvidenceIntegrationType.Database;
  subscriptionId: string;
  checks: DBIntegrationCheck[];
  dbSelectors: AzureDatabaseSelector[];
};

export type AzureDatabaseSelector = {
  type: AzureDatabaseType;
  resourceGroup: string;
  name: string;
};

export enum AzureDatabaseType {
  SQLServer = 'azure.sql-server',
  SQLManagedInstance = 'azure.sql-managed-instance',
  MySQLFlexible = 'azure.mysql-flexible',
  PostgreSQLFlexible = 'azure.postgresql-flexible',
}

export type ConfluencePageExportIntegrationConfig = {
  type: EvidenceIntegrationType.PageExport;
  pageId: number;
};

export type CrowdstrikeAntiMalwareIntegrationConfig = {
  type: EvidenceIntegrationType.AntiMalware;
};

export type JiraIssuesExportIntegrationConfig = {
  type: EvidenceIntegrationType.IssuesExport;
  jqlQuery: string;
};

export type GcpCloudSqlIntegrationConfig = {
  type: EvidenceIntegrationType.Database;
  dbInstanceNames: string[];
  checks: DBIntegrationCheck[];
};

export type GcpFileSyncIntegrationConfig = {
  type: EvidenceIntegrationType.FileSync;
  fileId: string;
};

export type GithubOldestChangeIntegrationConfig = {
  type: EvidenceIntegrationType.OldestChange;
  repoName: string;
};

export type QualysVulnerabilityIntegrationConfig = {
  type: EvidenceIntegrationType.Vulnerability;
  webAppIds: number[];
};

export type SharepointFileSyncIntegrationConfig = {
  type: EvidenceIntegrationType.FileSync;
  driveId: string;
  filePath: string;
};

export type VeracodeVulnerabilityIntegrationConfig = {
  type: EvidenceIntegrationType.Vulnerability;
  businessUnit?: string;
  policy?: string;
  policyCompliance?: string;
  policyGuid?: string;
  scanStatus?: string[];
  scanType?: string;
  tag?: string;
  team?: string;
  buildId?: string;
  context?: string;
};

export type OneloginSuperUsersIntegrationConfig = {
  type: EvidenceIntegrationType.SuperUsers;
};

export type OneloginMfaIntegrationConfig = {
  type: EvidenceIntegrationType.Mfa;
};

export type AwsObjectStorageIntegrationConfig = {
  type: EvidenceIntegrationType.ObjectStorage;
  region: string;
  buckets: string[];
  check: AwsObjectStorageCheck;
};

export enum AwsObjectStorageCheck {
  BlockPublicAccess = 'block-public-access',
  RequireSSL = 'require-ssl',
  Replication = 'replication',
  Encryption = 'encryption',
  Versioning = 'versioning',
  MFADelete = 'mfa-delete',
  ObjectLock = 'object-lock',
  Logging = 'logging',
}

export type AwsGuarddutyReportIntegrationConfig = {
  type: EvidenceIntegrationType.GuarddutyReport;
  region: string;
  checks: AwsGuarddutyCheck[];
};

export enum AwsGuarddutyCheck {
  EnabledService = 'enabled-service',
  SnsSubscription = 'sns-subscription',
  HighSeverity = 'high-severity',
}

export type ResourceStatusKeys = keyof ResourceStatus;

export type AwsInspectorIntegrationConfig = {
  type: EvidenceIntegrationType.Inspector;
  region: string;
  checks: AwsInspectorCheck[];
};

export enum AwsInspectorCheck {
  HighSeverityFindings = 'high-severity-findings',
  ServiceEC2 = 'service-ec2',
  ServiceECR = 'service-ecr',
  ServiceLambda = 'service-lambda',
  ServiceLambdaCode = 'service-lambda-code',
}

import { Icon, IconButton, Text } from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
import { RoleDetailsFragment } from '@main/graphql/fragments/RoleDetailsFragment.generated';
import { useDeleteOrganizationRoleMutation } from '@main/graphql/mutations/DeleteOrganizationRole.generated';
import { useGetOrganizationRolesQuery } from '@main/graphql/queries/GetOrganizationRoles.generated';
import { System_Roles_Enum } from '@main/graphql/types.generated';
import { isGraphqlError, toError } from '@main/shared/utils';
import { createColumnHelper, errorToast, successToast, useAlertDialog } from '@main/ui';
import { useNavigate } from '@tanstack/react-router';
import { useCallback, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../../hooks/redux-toolkit-hooks';
import { getCurrentUserSelectedOrgId, getCurrentUserSelectedOrgRole } from '../../../user/slice';
import { ROLES_MAP } from '../../constants';

function getRoleTagConfig(role: System_Roles_Enum | undefined) {
  if (role) {
    return { value: role, label: 'System', colorScheme: 'orange' };
  }
  return { value: 'custom-role', label: 'Custom', colorScheme: 'gray' };
}

export function useRolesTableColumns({
  openModal,
}: {
  openModal: (selectedRole: RoleDetailsFragment) => void;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate({ from: '/settings/organization/roles' });
  const { openDialog } = useAlertDialog();
  const organizationId = useAppSelector(getCurrentUserSelectedOrgId);
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canCreateRole = userRole.permissionMap?.write_roles;

  const [deleteRole] = useDeleteOrganizationRoleMutation();
  const { refetch: refetchRoles } = useGetOrganizationRolesQuery({ organizationId });
  const deleteHandler = useCallback(
    async (roleId: string) => {
      try {
        await deleteRole({
          roleId,
        }).unwrap();

        refetchRoles();
        successToast(t('successMessages.deleteSucceeded', { entity: t('entities.role') }));
      } catch (error) {
        if (isGraphqlError(error)) {
          switch (error[0]?.extensions.code) {
            case 'constraint-violation':
              errorToast(t('settings.organization.roles.errorMsg.roleInUse'));
              return;
            default:
              errorToast(t('errorMessages.deleteFailed', { entity: t('entities.role') }));
              datadogLogs.logger.error('Role delete failed', {}, toError(error));
              break;
          }
        }
      }
    },
    [t, deleteRole, refetchRoles],
  );

  return useMemo(() => {
    const columnHelper = createColumnHelper<RoleDetailsFragment>();

    const openRoleDetails = (roleId: string) =>
      navigate({
        to: '/settings/organization/roles/$roleId',
        params: { roleId },
      });

    return [
      columnHelper.columns.text({
        id: 'name',
        header: t('settings.organization.roles.tableColumns.name'),
        accessorFn: ({ name, system_role }) => (system_role ? ROLES_MAP[system_role].label : name),
        meta: { cell: { onClick: (cell) => openRoleDetails(cell.row.original.id) } },
        enableGlobalFilter: true,
      }),
      columnHelper.columns.tag({
        id: 'type',
        header: 'Type',
        accessorFn: ({ system_role }) => getRoleTagConfig(system_role),
        size: 120,
        enableColumnFilter: true,
      }),
      ...(canCreateRole
        ? [
            columnHelper.columns.actions({
              id: 'actions',
              header: t('settings.organization.roles.tableColumns.actions'),
              size: 90,
              PrimaryAction: ({ row }) =>
                row.original.system_role ? null : (
                  <IconButton
                    minW={4}
                    variant="link"
                    aria-label={t('settings.organization.roles.actions.edit')}
                    icon={<PencilIcon />}
                    onClick={() => openModal(row.original)}
                  />
                ),
              secondaryActions: ({ row }) =>
                row.original.system_role
                  ? []
                  : [
                      {
                        icon: <Icon as={TrashIcon} />,
                        label: t('buttons.delete'),
                        onClick: () =>
                          openDialog({
                            dialogHeader: t('settings.organization.roles.alert.header'),
                            dialogContent: (
                              <Text textColor="gray.700" _dark={{ textColor: 'gray.300' }}>
                                <Trans
                                  i18nKey="settings.organization.roles.alert.content"
                                  values={{ roleName: row.original.name }}
                                  components={{ bold: <strong /> }}
                                />
                              </Text>
                            ),
                            confirmAction: {
                              children: t('settings.organization.roles.alert.delete', {
                                roleName: row.original.name,
                              }),
                              onClick: () => deleteHandler(row.original.id),
                            },
                          }),
                      },
                    ],
            }),
          ]
        : []),
    ];
  }, [t, canCreateRole, navigate, openModal, openDialog, deleteHandler]);
}

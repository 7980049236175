import { Route } from '@tanstack/react-router';

import { MatrixConfigurations } from '../../../../features/settings/organization/risks/matrix/matrix-configuration';
import { organizationRisksSettingsLayoutRoute } from './layout';

export const organizationRisksMatrixSettingsRoute = new Route({
  getParentRoute: () => organizationRisksSettingsLayoutRoute,
  path: 'matrix',
  component: MatrixConfigurations,
});

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type AddRiskDocumentsMutationVariables = Types.Exact<{
  documents_input: Array<Types.Risk_Documents_Insert_Input> | Types.Risk_Documents_Insert_Input;
}>;

export type AddRiskDocumentsMutation = {
  __typename?: 'mutation_root';
  insert_risk_documents?: {
    __typename?: 'risk_documents_mutation_response';
    affected_rows: number;
  };
};

export const AddRiskDocumentsDocument = `
    mutation AddRiskDocuments($documents_input: [risk_documents_insert_input!]!) {
  insert_risk_documents(
    objects: $documents_input
    on_conflict: {constraint: risk_documents_pkey, update_columns: []}
  ) {
    affected_rows
  }
}
    ` as string & TypedDocumentNode<AddRiskDocumentsMutation, AddRiskDocumentsMutationVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    AddRiskDocuments: build.mutation<AddRiskDocumentsMutation, AddRiskDocumentsMutationVariables>({
      query: (variables) => ({ document: AddRiskDocumentsDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useAddRiskDocumentsMutation } = injectedRtkApi;

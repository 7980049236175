import { Page404 } from '@main/ui';
import { Route } from '@tanstack/react-router';

import { rootRoute } from './__root';

export const route404 = new Route({
  getParentRoute: () => rootRoute,
  path: '*',
  component: Page404,
});

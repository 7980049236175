/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type CreateOrganizationRoleMutationVariables = Types.Exact<{
  name: Types.Scalars['String']['input'];
  organizationId: Types.Scalars['uuid']['input'];
}>;

export type CreateOrganizationRoleMutation = {
  __typename?: 'mutation_root';
  insert_roles_one?: { __typename?: 'roles'; id: string };
};

export const CreateOrganizationRoleDocument = `
    mutation CreateOrganizationRole($name: String!, $organizationId: uuid!) {
  insert_roles_one(object: {name: $name, organization_id: $organizationId}) {
    id
  }
}
    ` as string &
  TypedDocumentNode<CreateOrganizationRoleMutation, CreateOrganizationRoleMutationVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    CreateOrganizationRole: build.mutation<
      CreateOrganizationRoleMutation,
      CreateOrganizationRoleMutationVariables
    >({
      query: (variables) => ({ document: CreateOrganizationRoleDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useCreateOrganizationRoleMutation } = injectedRtkApi;

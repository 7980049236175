/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type LinkEvidenceToControlMutationVariables = Types.Exact<{
  input: Array<Types.Control_Evidences_Insert_Input> | Types.Control_Evidences_Insert_Input;
}>;

export type LinkEvidenceToControlMutation = {
  __typename?: 'mutation_root';
  insert_control_evidences?: {
    __typename?: 'control_evidences_mutation_response';
    affected_rows: number;
  };
};

export type LinkControlsToProgramMutationVariables = Types.Exact<{
  input: Array<Types.Program_Controls_Insert_Input> | Types.Program_Controls_Insert_Input;
}>;

export type LinkControlsToProgramMutation = {
  __typename?: 'mutation_root';
  insert_program_controls?: {
    __typename?: 'program_controls_mutation_response';
    affected_rows: number;
  };
};

export type LinkControlToRiskMutationVariables = Types.Exact<{
  input: Array<Types.Risk_Controls_Insert_Input> | Types.Risk_Controls_Insert_Input;
}>;

export type LinkControlToRiskMutation = {
  __typename?: 'mutation_root';
  insert_risk_controls?: { __typename?: 'risk_controls_mutation_response'; affected_rows: number };
};

export type LinkRiskToVendorMutationVariables = Types.Exact<{
  input: Array<Types.Vendor_Risks_Insert_Input> | Types.Vendor_Risks_Insert_Input;
}>;

export type LinkRiskToVendorMutation = {
  __typename?: 'mutation_root';
  insert_vendor_risks?: { __typename?: 'vendor_risks_mutation_response'; affected_rows: number };
};

export type LinkTaskToVendorMutationVariables = Types.Exact<{
  input: Array<Types.Vendor_Tasks_Insert_Input> | Types.Vendor_Tasks_Insert_Input;
}>;

export type LinkTaskToVendorMutation = {
  __typename?: 'mutation_root';
  insert_vendor_tasks?: { __typename?: 'vendor_tasks_mutation_response'; affected_rows: number };
};

export type LinkTaskToRiskMutationVariables = Types.Exact<{
  input: Array<Types.Risk_Tasks_Insert_Input> | Types.Risk_Tasks_Insert_Input;
}>;

export type LinkTaskToRiskMutation = {
  __typename?: 'mutation_root';
  insert_risk_tasks?: { __typename?: 'risk_tasks_mutation_response'; affected_rows: number };
};

export type LinkTaskToControlMutationVariables = Types.Exact<{
  input: Array<Types.Control_Tasks_Insert_Input> | Types.Control_Tasks_Insert_Input;
}>;

export type LinkTaskToControlMutation = {
  __typename?: 'mutation_root';
  insert_control_tasks?: { __typename?: 'control_tasks_mutation_response'; affected_rows: number };
};

export type LinkTaskToPolicyMutationVariables = Types.Exact<{
  input: Array<Types.Policy_Tasks_Insert_Input> | Types.Policy_Tasks_Insert_Input;
}>;

export type LinkTaskToPolicyMutation = {
  __typename?: 'mutation_root';
  insert_policy_tasks?: { __typename?: 'policy_tasks_mutation_response'; affected_rows: number };
};

export const LinkEvidenceToControlDocument = `
    mutation LinkEvidenceToControl($input: [control_evidences_insert_input!]!) {
  insert_control_evidences(
    objects: $input
    on_conflict: {constraint: control_evidences_control_id_evidence_id_key}
  ) {
    affected_rows
  }
}
    ` as string &
  TypedDocumentNode<LinkEvidenceToControlMutation, LinkEvidenceToControlMutationVariables>;
export const LinkControlsToProgramDocument = `
    mutation LinkControlsToProgram($input: [program_controls_insert_input!]!) {
  insert_program_controls(
    objects: $input
    on_conflict: {constraint: program_organization_controls_pkey}
  ) {
    affected_rows
  }
}
    ` as string &
  TypedDocumentNode<LinkControlsToProgramMutation, LinkControlsToProgramMutationVariables>;
export const LinkControlToRiskDocument = `
    mutation LinkControlToRisk($input: [risk_controls_insert_input!]!) {
  insert_risk_controls(
    objects: $input
    on_conflict: {constraint: risk_controls_risk_id_control_id_key}
  ) {
    affected_rows
  }
}
    ` as string & TypedDocumentNode<LinkControlToRiskMutation, LinkControlToRiskMutationVariables>;
export const LinkRiskToVendorDocument = `
    mutation LinkRiskToVendor($input: [vendor_risks_insert_input!]!) {
  insert_vendor_risks(
    objects: $input
    on_conflict: {constraint: vendor_risks_vendor_id_risk_id_key}
  ) {
    affected_rows
  }
}
    ` as string & TypedDocumentNode<LinkRiskToVendorMutation, LinkRiskToVendorMutationVariables>;
export const LinkTaskToVendorDocument = `
    mutation LinkTaskToVendor($input: [vendor_tasks_insert_input!]!) {
  insert_vendor_tasks(
    objects: $input
    on_conflict: {constraint: vendor_tasks_vendor_id_task_id_key}
  ) {
    affected_rows
  }
}
    ` as string & TypedDocumentNode<LinkTaskToVendorMutation, LinkTaskToVendorMutationVariables>;
export const LinkTaskToRiskDocument = `
    mutation LinkTaskToRisk($input: [risk_tasks_insert_input!]!) {
  insert_risk_tasks(
    objects: $input
    on_conflict: {constraint: risk_tasks_risk_id_task_id_key}
  ) {
    affected_rows
  }
}
    ` as string & TypedDocumentNode<LinkTaskToRiskMutation, LinkTaskToRiskMutationVariables>;
export const LinkTaskToControlDocument = `
    mutation LinkTaskToControl($input: [control_tasks_insert_input!]!) {
  insert_control_tasks(
    objects: $input
    on_conflict: {constraint: control_tasks_control_id_task_id_key}
  ) {
    affected_rows
  }
}
    ` as string & TypedDocumentNode<LinkTaskToControlMutation, LinkTaskToControlMutationVariables>;
export const LinkTaskToPolicyDocument = `
    mutation LinkTaskToPolicy($input: [policy_tasks_insert_input!]!) {
  insert_policy_tasks(
    objects: $input
    on_conflict: {constraint: policy_tasks_policy_id_task_id_key}
  ) {
    affected_rows
  }
}
    ` as string & TypedDocumentNode<LinkTaskToPolicyMutation, LinkTaskToPolicyMutationVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    LinkEvidenceToControl: build.mutation<
      LinkEvidenceToControlMutation,
      LinkEvidenceToControlMutationVariables
    >({
      query: (variables) => ({ document: LinkEvidenceToControlDocument, variables }),
    }),
    LinkControlsToProgram: build.mutation<
      LinkControlsToProgramMutation,
      LinkControlsToProgramMutationVariables
    >({
      query: (variables) => ({ document: LinkControlsToProgramDocument, variables }),
    }),
    LinkControlToRisk: build.mutation<
      LinkControlToRiskMutation,
      LinkControlToRiskMutationVariables
    >({
      query: (variables) => ({ document: LinkControlToRiskDocument, variables }),
    }),
    LinkRiskToVendor: build.mutation<LinkRiskToVendorMutation, LinkRiskToVendorMutationVariables>({
      query: (variables) => ({ document: LinkRiskToVendorDocument, variables }),
    }),
    LinkTaskToVendor: build.mutation<LinkTaskToVendorMutation, LinkTaskToVendorMutationVariables>({
      query: (variables) => ({ document: LinkTaskToVendorDocument, variables }),
    }),
    LinkTaskToRisk: build.mutation<LinkTaskToRiskMutation, LinkTaskToRiskMutationVariables>({
      query: (variables) => ({ document: LinkTaskToRiskDocument, variables }),
    }),
    LinkTaskToControl: build.mutation<
      LinkTaskToControlMutation,
      LinkTaskToControlMutationVariables
    >({
      query: (variables) => ({ document: LinkTaskToControlDocument, variables }),
    }),
    LinkTaskToPolicy: build.mutation<LinkTaskToPolicyMutation, LinkTaskToPolicyMutationVariables>({
      query: (variables) => ({ document: LinkTaskToPolicyDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useLinkEvidenceToControlMutation,
  useLinkControlsToProgramMutation,
  useLinkControlToRiskMutation,
  useLinkRiskToVendorMutation,
  useLinkTaskToVendorMutation,
  useLinkTaskToRiskMutation,
  useLinkTaskToControlMutation,
  useLinkTaskToPolicyMutation,
} = injectedRtkApi;

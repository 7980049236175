import { datadogLogs } from '@datadog/browser-logs';
import { toError } from '@main/shared/utils';
import { errorToast, successToast } from '@main/ui';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useLinkTaskToRiskMutation } from '../link-entity/link-entity.generated';

export function useLinkTaskHandler({ callback }: { callback?: () => void }) {
  const { t } = useTranslation();

  return useCallback(
    async (resultPromise: ReturnType<ReturnType<typeof useLinkTaskToRiskMutation>[0]>) => {
      try {
        await resultPromise.unwrap();

        successToast(t('successMessages.linkSucceeded', { entity: t('entities.task') }));
        callback?.();
      } catch (error) {
        errorToast(t('errorMessages.linkFailed', { entity: t('entities.task') }));
        datadogLogs.logger.error('Linking task(s) to entity failed', {}, toError(error));
      }
    },
    [callback, t],
  );
}

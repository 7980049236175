import { Box, BoxProps, forwardRef } from '@chakra-ui/react';
import { createContext, useContext } from 'react';

import { ActionButtonProps, ActionButtons } from './action-buttons';

export const FloatingActionButtons = forwardRef<ActionButtonProps, 'button'>((props, ref) => {
  const { showButtons } = useFloatingButtonsContext();

  return (
    <ActionButtons
      {...props}
      {...(!showButtons && { display: 'none' })}
      _groupHover={{ display: 'flex' }}
      ref={ref}
    />
  );
});

type FloatingButtonsContainer = {
  /* This prop can override internal hide/show logic of the Floating component */
  showButtons?: boolean;
} & BoxProps;
export const FloatingButtonsContainer = forwardRef<FloatingButtonsContainer, 'div'>(
  ({ showButtons, ...restProps }, ref) => {
    return (
      <Context.Provider value={{ showButtons }}>
        <Box ref={ref} {...restProps} data-group />
      </Context.Provider>
    );
  },
);

const Context = createContext<{ showButtons?: boolean } | null>(null);

function useFloatingButtonsContext() {
  const context = useContext(Context);

  if (!context) {
    throw new Error(
      'FloatingActionButtons component must be wrapped in <FloatingButtonsContainer />',
    );
  }

  return context;
}

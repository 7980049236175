import { HStack, Select, Skeleton } from '@chakra-ui/react';
import { ChartBarIcon } from '@heroicons/react/24/outline';
import { getToday } from '@main/shared/utils';
import {
  AreaChart,
  ChartAccessor,
  ChartScaleType,
  DashboardCard,
  EmptyPlaceholder,
  formatDate,
} from '@main/ui';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { getCurrentUserSelectedOrgId } from '../user/slice';
import {
  ComplianceHealthGetControlsStatsQuery,
  useComplianceHealthGetControlsStatsQuery,
} from './compliance-health.generated';

export interface ComplianceHealthDatum {
  date: Date;
  health: number;
}

export enum ComplianceHealthRange {
  Last24Hours = '24h',
  LastMonth = '30d',
  LastYear = '1y',
}

export interface ComplianceHealthProps {
  data?: ComplianceHealthDatum[];
  range?: ComplianceHealthRange;
  onRangeChange?(range: ComplianceHealthRange): void;
}

export function ComplianceHealth({ onRangeChange, ...props }: ComplianceHealthProps) {
  const { t } = useTranslation();

  const range = props.range ?? ComplianceHealthRange.LastMonth;
  const isLoading = props.data === undefined;
  const isEmpty = props.data ? props.data.length < 2 : false;
  const data = useMemo(() => props.data ?? [], [props.data]);
  const xAccessor: ChartAccessor<ComplianceHealthDatum> = useMemo(
    () => ({
      type: ChartScaleType.Time,
      accessor: (d) => d.date,
      tooltip: (d) => formatDate(d),
    }),
    [],
  );
  const yAccessor: ChartAccessor<ComplianceHealthDatum> = useMemo(
    () => ({
      type: ChartScaleType.Linear,
      accessor: (d) => d.health,
      domain: [0, 100],
      tooltip: (d) => `${d}%`,
    }),
    [],
  );

  return (
    <DashboardCard>
      <DashboardCard.Header
        heading={t('dashboard.complyanceHealth.heading')}
        subheading={t('dashboard.complyanceHealth.subheading')}
      >
        {({ header }) => (
          <HStack justifyContent="space-between" alignItems="start">
            {header}
            <Select
              key={range}
              variant="filled"
              maxW="177px"
              defaultValue={range}
              onChange={(e) => onRangeChange?.(e.target.value as ComplianceHealthRange)}
            >
              <option value={ComplianceHealthRange.Last24Hours}>
                {t('dashboard.complyanceHealth.range.last24Hours')}
              </option>
              <option value={ComplianceHealthRange.LastMonth}>
                {t('dashboard.complyanceHealth.range.lastMonth')}
              </option>
              <option value={ComplianceHealthRange.LastYear}>
                {t('dashboard.complyanceHealth.range.lastYear')}
              </option>
            </Select>
          </HStack>
        )}
      </DashboardCard.Header>
      <DashboardCard.Body minH={240}>
        <Skeleton
          isLoaded={!isLoading}
          h="full"
          w="full"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {isEmpty ? (
            <ComplianceHealthEmpty />
          ) : (
            <AreaChart data={data} xAccessor={xAccessor} yAccessor={yAccessor} />
          )}
        </Skeleton>
      </DashboardCard.Body>
    </DashboardCard>
  );
}

enum TimeOffsetMs {
  Day = 24 * 60 * 60 * 1000,
  Month = 30 * 24 * 60 * 60 * 1000,
  Year = 365 * 24 * 60 * 60 * 1000,
}

export interface UseComplianceHealthProps {
  range?: ComplianceHealthRange;
}

export function useComplianceHealth(props?: UseComplianceHealthProps): ComplianceHealthProps {
  const todayTime = getToday().getTime();
  const organizationId = useAppSelector(getCurrentUserSelectedOrgId);
  const [range, setRange] = useState(props?.range ?? ComplianceHealthRange.LastMonth);

  const from = useMemo(
    () =>
      range === ComplianceHealthRange.Last24Hours
        ? new Date(todayTime - TimeOffsetMs.Day)
        : range === ComplianceHealthRange.LastMonth
        ? new Date(todayTime - TimeOffsetMs.Month)
        : new Date(todayTime - TimeOffsetMs.Year),
    [range, todayTime],
  );

  const { data: stats, isFetching } = useComplianceHealthGetControlsStatsQuery(
    { from: from.toISOString(), organizationId },
    { pollingInterval: TimeOffsetMs.Day },
  );

  const data = useMemo(() => (isFetching ? undefined : mapStatsData(stats)), [isFetching, stats]);

  return { data, range, onRangeChange: setRange };
}

function mapStatsData(stats?: ComplianceHealthGetControlsStatsQuery): ComplianceHealthDatum[] {
  return (
    stats?.daily_control_stats_view.map((stat) => {
      const valid_count = stat.total_valid_count ?? 0;
      const at_risk_count = stat.total_at_risk_count ?? 0;
      const failing_count = stat.total_failing_count ?? 1;
      const pending_count = stat.total_pending_count ?? 0;

      return {
        date: new Date(stat.created_at ?? ''),
        health: Math.round(
          ((valid_count + at_risk_count) /
            (failing_count + valid_count + at_risk_count + pending_count)) *
            100,
        ),
      };
    }) ?? []
  );
}

function ComplianceHealthEmpty() {
  const { t } = useTranslation();

  return (
    <EmptyPlaceholder>
      <EmptyPlaceholder.Icon as={ChartBarIcon} />
      <EmptyPlaceholder.Content>
        <EmptyPlaceholder.Heading>
          {t('dashboard.complyanceHealth.emptyState.heading')}
        </EmptyPlaceholder.Heading>
        <EmptyPlaceholder.Subheading>
          {t('dashboard.complyanceHealth.emptyState.subheading')}
        </EmptyPlaceholder.Subheading>
      </EmptyPlaceholder.Content>
    </EmptyPlaceholder>
  );
}

import {
  Button,
  ButtonGroup,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  TabPanel,
  TabPanels,
  useControllableProp,
} from '@chakra-ui/react';
import { UseFormWithViewReturn } from '@main/shared/types';
import { ButtonTab, ButtonTabList, ButtonTabs, createEnumTabsControl } from '@main/ui';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { EvidenceVersionType } from './evidence-version-type';

type EvidenceVersionModalProps = {
  header: string;
  versionType?: EvidenceVersionType;
  forms: EvidenceVersionForm[];
  isOpen: boolean;
  onClose: () => void;
};

type EvidenceVersionForm = {
  type: EvidenceVersionType;
  form: UseFormWithViewReturn;
};

export const EvidenceVersionModal = ({
  header,
  versionType: propVersionType,
  forms,
  isOpen,
  onClose,
}: EvidenceVersionModalProps) => {
  const { t } = useTranslation();
  const [stateVersionType, setStateVersionType] = useState(forms[0]?.type);
  const [isVersionTypeControlled, versionType] = useControllableProp(
    propVersionType,
    stateVersionType,
  );

  useEffect(() => {
    if (isOpen) {
      setStateVersionType(forms[0]?.type);

      for (const { form } of forms) {
        form.reset();
      }
    }
    // we only care about the switch from closed to opened state
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  if (!versionType) {
    return null;
  }

  const formConfig = forms.find(({ type }) => type === versionType);
  if (!formConfig) {
    return null;
  }

  const tabsControl = createEnumTabsControl(forms.map(({ type }) => type));
  const { formState, handleSubmit } = formConfig.form;

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" motionPreset="slideInBottom">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{header}</ModalHeader>
        <ModalCloseButton />

        <form onSubmit={handleSubmit}>
          <ModalBody>
            <ButtonTabs {...tabsControl(versionType, setStateVersionType)}>
              <FormControl>
                <FormLabel>{t('evidences.version.label.type')}:</FormLabel>
                <ButtonTabList isDisabled={isVersionTypeControlled}>
                  {forms.map(({ type }) => (
                    <ButtonTab key={type}>{t(`evidences.version.type.${type}`)}</ButtonTab>
                  ))}
                </ButtonTabList>
              </FormControl>

              <TabPanels>
                {forms.map(({ type, form }) => (
                  <TabPanel key={type} px={0}>
                    {form.view}
                  </TabPanel>
                ))}
              </TabPanels>
            </ButtonTabs>
          </ModalBody>

          <ModalFooter>
            <ButtonGroup spacing={3}>
              <Button isDisabled={formState.isSubmitting} onClick={onClose}>
                {t('buttons.cancel')}
              </Button>
              <Button
                type="submit"
                colorScheme="blue"
                isLoading={formState.isSubmitting}
                isDisabled={!formState.isDirty}
              >
                {t('buttons.submit')}
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

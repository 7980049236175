import { Route } from '@tanstack/react-router';

import { ControlCenter } from '../../features/controls/control-center';
import { authLayout } from '../login';

export const controlsRoute = new Route({
  getParentRoute: () => authLayout,
  path: 'controls',
  component: ControlCenter,
});

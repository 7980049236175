import {
  Box,
  BoxProps,
  Divider,
  HStack,
  Icon,
  IconButton,
  IconButtonProps,
  Tooltip,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import {
  ArrowUturnLeftIcon,
  ArrowUturnRightIcon,
  CodeBracketIcon,
  LinkIcon,
  TableCellsIcon,
} from '@heroicons/react/24/outline';
import {
  $createCodeNode,
  $isCodeNode,
  CODE_LANGUAGE_FRIENDLY_NAME_MAP,
  CODE_LANGUAGE_MAP,
  getDefaultCodeLanguage,
} from '@lexical/code';
import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link';
import {
  $isListNode,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  ListNode,
} from '@lexical/list';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  $createHeadingNode,
  $createQuoteNode,
  $isHeadingNode,
  HeadingTagType,
} from '@lexical/rich-text';
import {
  $getSelectionStyleValueForProperty,
  $isAtNodeEnd,
  $patchStyleText,
  $setBlocksType,
} from '@lexical/selection';
import { $isTableSelection, TableSelection } from '@lexical/table';
import { $findMatchingParent, $getNearestNodeOfType, mergeRegister } from '@lexical/utils';
import { GroupBase } from 'chakra-react-select';
import {
  $createParagraphNode,
  $getNodeByKey,
  $getSelection,
  $isElementNode,
  $isRangeSelection,
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  COMMAND_PRIORITY_LOW,
  ElementFormatType,
  FORMAT_ELEMENT_COMMAND,
  FORMAT_TEXT_COMMAND,
  RangeSelection,
  REDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
  UNDO_COMMAND,
} from 'lexical';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { BoldIcon } from '../../../icons/bold';
import { ItalicIcon } from '../../../icons/italic';
import { StrikethroughIcon } from '../../../icons/strikethrough';
import { UnderlineIcon } from '../../../icons/underline';
import { Select, SelectProps } from '../../combobox';
import { InsertTableDialog } from './table';

const FONT_FAMILY_OPTIONS = [
  'Arial',
  'Courier New',
  'Georgia',
  'Times New Roman',
  'Trebuchet MS',
  'Verdana',
] as const;
const FONT_FAMILY_DEFAULT = FONT_FAMILY_OPTIONS[0];

const FONT_SIZE_OPTIONS = ['10', '11', '12', '14', '16', '18', '20', '24', '30'];
const FONT_SIZE_DEFAULT = '16';

const ELEMENT_FORMAT_OPTIONS: {
  type: Exclude<ElementFormatType, ''>;
  name: string;
}[] = [
  { type: 'left', name: 'Left Align' },
  { type: 'center', name: 'Center Align' },
  { type: 'right', name: 'Right Align' },
  { type: 'justify', name: 'Justify Align' },
  { type: 'start', name: 'Start Align' },
  { type: 'end', name: 'End Align' },
];
const ELEMENT_FORMAT_DEFAULT = 'left';

const BLOCK_TYPES = [
  { value: 'paragraph', label: 'Normal' },
  { value: 'h1', label: 'Heading 1' },
  { value: 'h2', label: 'Heading 2' },
  { value: 'h3', label: 'Heading 3' },
  { value: 'h4', label: 'Heading 4' },
  { value: 'h5', label: 'Heading 5' },
  { value: 'quote', label: 'Quote' },
  { value: 'ul', label: 'Bulleted List' },
  { value: 'ol', label: 'Numbered List' },
  { value: 'code', label: 'Code Block' },
] as const;
const BLOCK_TYPE_DEFAULT = 'paragraph';

type BlockType = (typeof BLOCK_TYPES)[number]['value'];

const supportedBlockTypes = new Set(BLOCK_TYPES.map((type) => type.value));

function isSupportedBlockTypes(type: string): type is BlockType {
  return supportedBlockTypes.has(type as BlockType);
}

function getSelectedNode(selection: RangeSelection | TableSelection) {
  const anchor = selection.anchor;
  const focus = selection.focus;
  const anchorNode = selection.anchor.getNode();
  const focusNode = selection.focus.getNode();
  if (anchorNode === focusNode) {
    return anchorNode;
  }
  const isBackward = selection.isBackward();
  if (isBackward) {
    return $isAtNodeEnd(focus) ? anchorNode : focusNode;
  } else {
    return $isAtNodeEnd(anchor) ? focusNode : anchorNode;
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ToolbarPluginProps extends BoxProps {}

export function ToolbarPlugin(boxProps: ToolbarPluginProps) {
  const [editor] = useLexicalComposerContext();

  const [isEditable, setIsEditable] = useState(() => editor.isEditable());
  const [fontSize, setFontSize] = useState<string>(FONT_SIZE_DEFAULT);
  const [fontFamily, setFontFamily] = useState<string>(FONT_FAMILY_DEFAULT);
  const [elementFormat, setElementFormat] = useState<ElementFormatType>(ELEMENT_FORMAT_DEFAULT);
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);
  const [blockType, setBlockType] = useState<BlockType>(BLOCK_TYPE_DEFAULT);
  const [selectedElementKey, setSelectedElementKey] = useState<string | null>(null);
  const [codeLanguage, setCodeLanguage] = useState('');
  const [isLink, setIsLink] = useState(false);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isStrikethrough, setIsStrikethrough] = useState(false);
  const [isCode, setIsCode] = useState(false);
  const tableModal = useDisclosure();

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const element =
        anchorNode.getKey() === 'root' ? anchorNode : anchorNode.getTopLevelElementOrThrow();
      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);

      if (elementDOM !== null) {
        setSelectedElementKey(elementKey);

        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode);
          const type = parentList ? parentList.getTag() : element.getTag();
          if (isSupportedBlockTypes(type)) {
            setBlockType(type);
          }
        } else {
          const type = $isHeadingNode(element) ? element.getTag() : element.getType();

          if (isSupportedBlockTypes(type)) {
            setBlockType(type);
          }

          if ($isCodeNode(element)) {
            const language = element.getLanguage() as keyof typeof CODE_LANGUAGE_MAP;
            setCodeLanguage(
              language ? CODE_LANGUAGE_MAP[language] || language : getDefaultCodeLanguage(),
            );
          }
        }
      }

      // Update text format
      setIsBold(selection.hasFormat('bold'));
      setIsItalic(selection.hasFormat('italic'));
      setIsUnderline(selection.hasFormat('underline'));
      setIsStrikethrough(selection.hasFormat('strikethrough'));
      setIsCode(selection.hasFormat('code'));
      setFontFamily(
        $getSelectionStyleValueForProperty(selection, 'font-family', FONT_FAMILY_DEFAULT),
      );

      // Update links
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true);
      } else {
        setIsLink(false);
      }

      let matchingParent;
      if ($isLinkNode(parent)) {
        // If node is a link, we need to fetch the parent paragraph node to set format
        matchingParent = $findMatchingParent(
          node,
          (parentNode) => $isElementNode(parentNode) && !parentNode.isInline(),
        );
      }

      // If matchingParent is a valid node, pass it's format type
      setElementFormat(
        $isElementNode(matchingParent)
          ? matchingParent.getFormatType()
          : $isElementNode(node)
          ? node.getFormatType()
          : parent?.getFormatType() || ELEMENT_FORMAT_DEFAULT,
      );
    }

    if ($isRangeSelection(selection) || $isTableSelection(selection)) {
      setFontSize($getSelectionStyleValueForProperty(selection, 'font-size', FONT_SIZE_DEFAULT));
    }
  }, [editor]);

  useEffect(() => {
    return mergeRegister(
      editor.registerEditableListener(setIsEditable),
      editor.registerUpdateListener(({ editorState }) => editorState.read(updateToolbar)),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateToolbar();
          return false;
        },
        COMMAND_PRIORITY_LOW,
      ),
      editor.registerCommand(
        CAN_UNDO_COMMAND,
        (payload) => {
          setCanUndo(payload);
          return false;
        },
        COMMAND_PRIORITY_LOW,
      ),
      editor.registerCommand(
        CAN_REDO_COMMAND,
        (payload) => {
          setCanRedo(payload);
          return false;
        },
        COMMAND_PRIORITY_LOW,
      ),
    );
  }, [editor, updateToolbar]);

  function onCodeLanguageSelect(lang = getDefaultCodeLanguage()) {
    editor.update(() => {
      if (selectedElementKey !== null) {
        const node = $getNodeByKey(selectedElementKey);
        if ($isCodeNode(node)) {
          node.setLanguage(lang);
        }
      }
    });
  }

  function toggleLink() {
    if (!isLink) {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, 'https://');
    } else {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
    }
  }

  return (
    <HStack alignItems="stretch" overflowX="auto" {...boxProps}>
      <HStack spacing={0}>
        <ToolbarButtonIcon
          aria-label="Undo"
          icon={<Icon as={ArrowUturnLeftIcon} />}
          isDisabled={!canUndo || !isEditable}
          onClick={() => editor.dispatchCommand(UNDO_COMMAND, undefined)}
        />
        <ToolbarButtonIcon
          aria-label="Redo"
          icon={<Icon as={ArrowUturnRightIcon} />}
          isDisabled={!canRedo || !isEditable}
          onClick={() => editor.dispatchCommand(REDO_COMMAND, undefined)}
        />
      </HStack>
      <Box>
        <Divider orientation="vertical" />
      </Box>
      <Box flexShrink="0">
        <ToolbarBlockFormatDropdown blockType={blockType} onBlockTypeChange={setBlockType} />
      </Box>
      <Box>
        <Divider orientation="vertical" />
      </Box>
      {blockType === 'code' ? (
        <HStack flexShrink="0">
          <ToolbarCodeLanguageDropdown
            codeLanguage={codeLanguage}
            onCodeLanguageChange={onCodeLanguageSelect}
          />
        </HStack>
      ) : (
        <HStack spacing={1} flexShrink="0">
          <ToolbarFontFamilyDropdown fontFamily={fontFamily} onFontFamilyChange={setFontFamily} />
          <ToolbarFontSizeDropdown fontSize={fontSize} onFontSizeChange={setFontSize} />
        </HStack>
      )}
      <Box>
        <Divider orientation="vertical" />
      </Box>
      <HStack spacing={0}>
        <ToolbarButtonIcon
          aria-label="Bold"
          isActive={isBold}
          isDisabled={!isEditable}
          icon={<Icon as={BoldIcon} />}
          onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold')}
        />
        <ToolbarButtonIcon
          aria-label="Italic"
          isActive={isItalic}
          isDisabled={!isEditable}
          icon={<Icon as={ItalicIcon} />}
          onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic')}
        />
        <ToolbarButtonIcon
          aria-label="Underline"
          isActive={isUnderline}
          isDisabled={!isEditable}
          icon={<Icon as={UnderlineIcon} />}
          onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline')}
        />
        <ToolbarButtonIcon
          aria-label="Strikethrough"
          isActive={isStrikethrough}
          isDisabled={!isEditable}
          icon={<Icon as={StrikethroughIcon} />}
          onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'strikethrough')}
        />
        <ToolbarButtonIcon
          aria-label="Insert Code"
          isActive={isCode}
          isDisabled={!isEditable}
          icon={<Icon as={CodeBracketIcon} />}
          onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'code')}
        />
        <ToolbarButtonIcon
          aria-label="Insert Link"
          isActive={isLink}
          isDisabled={!isEditable}
          icon={<Icon as={LinkIcon} />}
          onClick={toggleLink}
        />
        <ToolbarButtonIcon
          aria-label="Insert Table"
          isDisabled={!isEditable}
          icon={<Icon as={TableCellsIcon} />}
          onClick={tableModal.onOpen}
        />
        <InsertTableDialog {...tableModal} />
      </HStack>
      <Box>
        <Divider orientation="vertical" />
      </Box>
      <Box flexShrink="0">
        <ToolbarElementFormatDropdown
          elementFormat={elementFormat}
          onElementFormatChange={setElementFormat}
        />
      </Box>
    </HStack>
  );
}

interface ToolbarButtonIconProps extends IconButtonProps {
  isActive?: boolean;
}

function ToolbarButtonIcon(props: ToolbarButtonIconProps) {
  const label = props.title ?? props['aria-label'];
  const colorLight = props.isActive ? 'gray.900' : 'gray.600';
  const colorDark = props.isActive ? 'gray.100' : 'gray.400';
  return (
    <Tooltip isDisabled={!label} label={label}>
      <IconButton
        size="sm"
        variant={props.isActive ? 'outline' : 'ghost'}
        color={useColorModeValue(colorLight, colorDark)}
        {...props}
      />
    </Tooltip>
  );
}

interface ToolbarBlockFormatDropdownProps
  extends SelectProps<
    (typeof BLOCK_TYPES)[number],
    false,
    GroupBase<(typeof BLOCK_TYPES)[number]>
  > {
  blockType: BlockType;
  onBlockTypeChange(type: BlockType): void;
}

function ToolbarBlockFormatDropdown({
  blockType,
  onBlockTypeChange,
  ...props
}: ToolbarBlockFormatDropdownProps) {
  const [editor] = useLexicalComposerContext();

  const selectedBlockType = useMemo(
    () => BLOCK_TYPES.find((type) => type.value === blockType),
    [blockType],
  );

  function formatParagraph() {
    editor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        $setBlocksType(selection, () => $createParagraphNode());
      }
    });
  }

  function formatHeading(headingSize: HeadingTagType) {
    if (blockType !== headingSize) {
      editor.update(() => {
        const selection = $getSelection();
        $setBlocksType(selection, () => $createHeadingNode(headingSize));
      });
    }
  }

  function formatBulletList() {
    if (blockType !== 'ul') {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
    } else {
      formatParagraph();
    }
  }

  function formatNumberedList() {
    if (blockType !== 'ol') {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
    } else {
      formatParagraph();
    }
  }

  function formatQuote() {
    editor.update(() => {
      const selection = $getSelection();
      $setBlocksType(selection, () => $createQuoteNode());
    });
  }

  function formatCode() {
    editor.update(() => {
      let selection = $getSelection();

      if (selection !== null) {
        if (selection.isCollapsed()) {
          $setBlocksType(selection, () => $createCodeNode());
        } else {
          const textContent = selection.getTextContent();
          const codeNode = $createCodeNode();
          selection.insertNodes([codeNode]);
          selection = $getSelection();
          if ($isRangeSelection(selection)) {
            selection.insertRawText(textContent);
          }
        }
      }
    });
  }

  function handleBlockTypeChange(type: BlockType) {
    switch (type) {
      case 'paragraph':
        formatParagraph();
        break;
      case 'h1':
      case 'h2':
      case 'h3':
      case 'h4':
      case 'h5':
        formatHeading(type);
        break;
      case 'ul':
        formatBulletList();
        break;
      case 'ol':
        formatNumberedList();
        break;
      case 'quote':
        formatQuote();
        break;
      case 'code':
        formatCode();
        break;
    }
    onBlockTypeChange(type);
  }

  return (
    <ToolbarSelect
      isDisabled={!editor.isEditable()}
      options={BLOCK_TYPES}
      value={selectedBlockType}
      onChange={(option) => option && handleBlockTypeChange(option.value)}
      {...props}
    />
  );
}

interface ToolbarFontFamilyDropdownProps
  extends SelectProps<
    { value: string; label: string },
    false,
    GroupBase<{ value: string; label: string }>
  > {
  fontFamily: string;
  onFontFamilyChange(fontFamily: string): void;
}

function ToolbarFontFamilyDropdown({
  fontFamily,
  onFontFamilyChange,
  ...props
}: ToolbarFontFamilyDropdownProps) {
  const [editor] = useLexicalComposerContext();

  const options = useMemo(
    () => FONT_FAMILY_OPTIONS.map((font) => ({ value: font, label: font })),
    [],
  );
  const selectedOption = useMemo(
    () => options.find((option) => option.value === fontFamily),
    [fontFamily, options],
  );

  function handleChange(fontFamily: string) {
    editor.update(() => {
      const selection = $getSelection();
      if (selection !== null) {
        $patchStyleText(selection, { 'font-family': fontFamily });
      }
      onFontFamilyChange(fontFamily);
    });
  }

  return (
    <ToolbarSelect
      isDisabled={!editor.isEditable()}
      options={options}
      value={selectedOption}
      onChange={(option) => option && handleChange(option.value)}
      {...props}
    />
  );
}

interface ToolbarFontSizeDropdownProps
  extends SelectProps<
    { value: string; label: string },
    false,
    GroupBase<{ value: string; label: string }>
  > {
  fontSize: string;
  onFontSizeChange(fontFamily: string): void;
}

function ToolbarFontSizeDropdown({
  fontSize,
  onFontSizeChange,
  ...props
}: ToolbarFontSizeDropdownProps) {
  const [editor] = useLexicalComposerContext();

  const options = useMemo(
    () => FONT_SIZE_OPTIONS.map((font) => ({ value: `${font}px`, label: font })),
    [],
  );
  const selectedOption = useMemo(
    () => options.find((option) => option.value === fontSize),
    [fontSize, options],
  );

  function handleChange(fontSize: string) {
    editor.update(() => {
      const selection = $getSelection();
      if (selection !== null) {
        $patchStyleText(selection, { 'font-size': fontSize });
      }
      onFontSizeChange(fontSize);
    });
  }

  return (
    <ToolbarSelect
      isDisabled={!editor.isEditable()}
      options={options}
      value={selectedOption}
      onChange={(option) => option && handleChange(option.value)}
      {...props}
    />
  );
}

interface ToolbarElementFormatDropdownProps
  extends SelectProps<
    { value: ElementFormatType; label: string },
    false,
    GroupBase<{ value: ElementFormatType; label: string }>
  > {
  elementFormat: ElementFormatType;
  onElementFormatChange(format: ElementFormatType): void;
}

function ToolbarElementFormatDropdown({
  elementFormat,
  onElementFormatChange,
  ...props
}: ToolbarElementFormatDropdownProps) {
  const [editor] = useLexicalComposerContext();

  const options = useMemo(
    () => ELEMENT_FORMAT_OPTIONS.map((format) => ({ value: format.type, label: format.name })),
    [],
  );
  const selectedOption = useMemo(
    () => options.find((option) => option.value === elementFormat),
    [elementFormat, options],
  );

  function handleChange(format: ElementFormatType) {
    editor.update(() => {
      editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, format);
      onElementFormatChange(format);
    });
  }

  return (
    <ToolbarSelect
      isDisabled={!editor.isEditable()}
      options={options}
      value={selectedOption}
      onChange={(option) => option && handleChange(option.value)}
      {...props}
    />
  );
}

interface ToolbarCodeLanguageDropdownProps
  extends SelectProps<
    { value: string; label: string },
    false,
    GroupBase<{ value: string; label: string }>
  > {
  codeLanguage: string;
  onCodeLanguageChange(language: string): void;
}

function ToolbarCodeLanguageDropdown({
  codeLanguage,
  onCodeLanguageChange,
  ...props
}: ToolbarCodeLanguageDropdownProps) {
  const [editor] = useLexicalComposerContext();

  const options = useMemo(
    () =>
      Object.entries(CODE_LANGUAGE_FRIENDLY_NAME_MAP).map(([lang, name]) => ({
        value: lang,
        label: name,
      })),
    [],
  );
  const selectedOption = useMemo(
    () => options.find((option) => option.value === codeLanguage),
    [codeLanguage, options],
  );

  return (
    <ToolbarSelect
      isDisabled={!editor.isEditable()}
      options={options}
      value={selectedOption}
      onChange={(option) => option && onCodeLanguageChange(option.value)}
      {...props}
    />
  );
}

function ToolbarSelect<T>(props: SelectProps<T, false, GroupBase<T>>) {
  return (
    <Select
      size="sm"
      menuPortalTarget={document.body}
      styles={{
        menuPortal: (base) => ({ ...base, zIndex: 'calc(var(--chakra-zIndices-modal, 0) + 1)' }),
      }}
      useBasicStyles
      {...props}
    />
  );
}

import {
  As,
  Box,
  BoxProps,
  Flex,
  FormControl,
  FormControlProps,
  FormLabel,
  FormLabelProps,
  Icon,
  Tooltip,
  useColorModeValue,
} from '@chakra-ui/react';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { ReactNode } from 'react';

interface DrawerPropertyProps extends FormControlProps {
  children: ReactNode;
}
const DEFAULT_HEIGHT = '38px';
export function DrawerProperty({ children, ...formProps }: DrawerPropertyProps) {
  return (
    <FormControl
      display="flex"
      gap={2}
      minH={DEFAULT_HEIGHT}
      flexDirection={['column', 'column', 'row']}
      {...formProps}
    >
      {children}
    </FormControl>
  );
}

interface LabelProps extends FormLabelProps {
  children: string | string[];
  icon?: As;
  tooltip?: string;
}
const Label = ({ children, icon, tooltip, ...labelProps }: LabelProps) => {
  return (
    <Flex flex="0 0 135px" gap={2} color="gray.500" py={2.5}>
      {icon && <Icon as={icon} boxSize={4} mt={0.5} />}
      <FormLabel {...labelProps} m={0} fontWeight={'medium'}>
        {children}
      </FormLabel>
      {tooltip && (
        <Tooltip
          label={tooltip}
          hasArrow
          placement="right"
          openDelay={300}
          fontSize={'sm'}
          rounded={4}
        >
          <Icon as={InformationCircleIcon} boxSize={4} ml={-1} />
        </Tooltip>
      )}
    </Flex>
  );
};

interface ContentProps extends BoxProps {
  children: ReactNode;
}
const Content = ({ children, ...boxProps }: ContentProps) => {
  const contentColor = useColorModeValue('gray.600', 'gray.500');
  return (
    <Box flexGrow={1} color={contentColor} {...boxProps}>
      {children}
    </Box>
  );
};

DrawerProperty.Label = Label;
DrawerProperty.Content = Content;

if (typeof Promise.withResolvers !== 'function') {
  Promise.withResolvers = function withResolvers<T>() {
    let resolve: (value: T | PromiseLike<T>) => void = () => undefined,
      reject: (reason: unknown) => void = () => undefined;

    const promise = new this((res, rej) => {
      resolve = res;
      reject = rej;
    }) as Promise<T>;

    return { promise, resolve, reject };
  };
}

export {};

import { createGqlSubscriptionApi } from '@main/graphql/create-subscription-api';

import {
  api as getUnreadNotificationsApi,
  GetUnreadNotificationsDocument,
} from './get-notifications.generated';

export const api = createGqlSubscriptionApi(
  getUnreadNotificationsApi,
  'GetUnreadNotifications',
  GetUnreadNotificationsDocument,
);

export const { useGetUnreadNotificationsQuery: useNotificationsSubscription } = api;

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type AddControlCategoryTagMutationVariables = Types.Exact<{
  categoryId: Types.Scalars['uuid']['input'];
  controlId: Types.Scalars['uuid']['input'];
}>;

export type AddControlCategoryTagMutation = {
  __typename?: 'mutation_root';
  insert_control_categories_one?: {
    __typename?: 'control_categories';
    category_id: string;
    control_id: string;
  };
};

export const AddControlCategoryTagDocument = `
    mutation AddControlCategoryTag($categoryId: uuid!, $controlId: uuid!) {
  insert_control_categories_one(
    object: {category_id: $categoryId, control_id: $controlId}
  ) {
    category_id
    control_id
  }
}
    ` as string &
  TypedDocumentNode<AddControlCategoryTagMutation, AddControlCategoryTagMutationVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    AddControlCategoryTag: build.mutation<
      AddControlCategoryTagMutation,
      AddControlCategoryTagMutationVariables
    >({
      query: (variables) => ({ document: AddControlCategoryTagDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useAddControlCategoryTagMutation } = injectedRtkApi;

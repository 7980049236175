/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type UpdateControlByIdMutationVariables = Types.Exact<{
  controlId: Types.Scalars['uuid']['input'];
  updatePayload: Types.Controls_Set_Input;
}>;

export type UpdateControlByIdMutation = {
  __typename?: 'mutation_root';
  update_controls_by_pk?: { __typename?: 'controls'; id: string };
};

export const UpdateControlByIdDocument = `
    mutation UpdateControlById($controlId: uuid!, $updatePayload: controls_set_input!) {
  update_controls_by_pk(pk_columns: {id: $controlId}, _set: $updatePayload) {
    id
  }
}
    ` as string & TypedDocumentNode<UpdateControlByIdMutation, UpdateControlByIdMutationVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    UpdateControlById: build.mutation<
      UpdateControlByIdMutation,
      UpdateControlByIdMutationVariables
    >({
      query: (variables) => ({ document: UpdateControlByIdDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useUpdateControlByIdMutation } = injectedRtkApi;

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type UseGlobalSearchQueryVariables = Types.Exact<{
  input: Types.SearchInput;
}>;

export type UseGlobalSearchQuery = {
  __typename?: 'query_root';
  global_search?: {
    __typename?: 'SearchOutput';
    data?: Array<{
      __typename?: 'SearchObject';
      id: string;
      internal_id?: string;
      name?: string;
      entity: Types.SearchEntitiesEnum;
      updated_at?: string;
    }>;
  };
};

export const UseGlobalSearchDocument = `
    query UseGlobalSearch($input: SearchInput!) {
  global_search(input: $input) {
    data {
      id
      internal_id
      name
      entity
      updated_at
    }
  }
}
    ` as string & TypedDocumentNode<UseGlobalSearchQuery, UseGlobalSearchQueryVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    UseGlobalSearch: build.query<UseGlobalSearchQuery, UseGlobalSearchQueryVariables>({
      query: (variables) => ({ document: UseGlobalSearchDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useUseGlobalSearchQuery, useLazyUseGlobalSearchQuery } = injectedRtkApi;

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type GetCustomTagsQueryVariables = Types.Exact<{
  orgId: Types.Scalars['uuid']['input'];
  nameFilter?: Types.InputMaybe<Types.String_Comparison_Exp>;
}>;

export type GetCustomTagsQuery = {
  __typename?: 'query_root';
  tags: Array<{ __typename?: 'tags'; id: string; name: string }>;
};

export const GetCustomTagsDocument = `
    query GetCustomTags($orgId: uuid!, $nameFilter: String_comparison_exp = {}) {
  tags(
    order_by: {name: asc}
    where: {organization_id: {_eq: $orgId}, name: $nameFilter}
  ) {
    id
    name
  }
}
    ` as string & TypedDocumentNode<GetCustomTagsQuery, GetCustomTagsQueryVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetCustomTags: build.query<GetCustomTagsQuery, GetCustomTagsQueryVariables>({
      query: (variables) => ({ document: GetCustomTagsDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetCustomTagsQuery, useLazyGetCustomTagsQuery } = injectedRtkApi;

import { Route } from '@tanstack/react-router';

import { QuestionnairesManagement } from '../../features/questionnaires/questionnaires-management';
import { authLayout } from '../login';

export const questionnairesRoute = new Route({
  getParentRoute: () => authLayout,
  path: 'questionnaires',
  component: QuestionnairesManagement,
});

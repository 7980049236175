import { Box } from '@chakra-ui/react';
import { AreaChart, ChartAccessor, ChartScaleType, StatsCard } from '@main/ui';
import { useNavigate } from '@tanstack/react-router';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { getCurrentUserSelectedOrgId } from '../user/slice';
import { AlertsCountStatsQuery, useAlertsCountStatsQuery } from './alerts-count.generated';

export interface AlertsCountProps {
  statsDate: string;
  period: number;
}

export function AlertsCount(props: AlertsCountProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const organizationId = useAppSelector(getCurrentUserSelectedOrgId);
  const { data, isLoading } = useAlertsCountStatsQuery(
    { organizationId, statsDate: props.statsDate },
    { refetchOnMountOrArgChange: true },
  );

  const stats = useMemo(() => {
    const alertsAmount = data?.alerts.aggregate?.count ?? 0;
    const lastStat = data?.stats[0];
    const lastAlertsAmount =
      (lastStat?.total_at_risk_count || 0) + (lastStat?.total_failing_count || 0);
    const percentage =
      alertsAmount || lastAlertsAmount
        ? Math.round(
            (Math.abs(alertsAmount - lastAlertsAmount) / Math.max(alertsAmount, lastAlertsAmount)) *
              100,
          )
        : 0;
    const isPositive = alertsAmount > lastAlertsAmount;

    return { percentage, isPositive, alertsAmount, period: props.period, flipColor: true };
  }, [data?.alerts.aggregate?.count, data?.stats, props.period]);

  const statsData = useMemo(() => data?.stats.map(alertStatsToChartData) ?? [], [data?.stats]);
  const xAccessor: ChartAccessor<AlertsChartData> = useMemo(
    () => ({ type: ChartScaleType.Time, accessor: (d) => d.date }),
    [],
  );
  const yAccessor: ChartAccessor<AlertsChartData> = useMemo(
    () => ({ type: ChartScaleType.Linear, accessor: (d) => d.alerts }),
    [],
  );

  return (
    <StatsCard
      title={t('dashboard.alerts')}
      stat={`${stats.alertsAmount}`}
      isLoading={isLoading}
      change={stats}
      card={{ cursor: 'pointer', onClick: () => navigate({ to: '/alerts' }) }}
    >
      <Box h="full" maxW="128px" alignContent="center" flexGrow="1">
        <AreaChart
          data={statsData}
          xAccessor={xAccessor}
          yAccessor={yAccessor}
          noTooltip
          noAxis
          lineColor="#F04438"
          verticalGridColor="transparent"
          horizontalGridColor="transparent"
        />
      </Box>
    </StatsCard>
  );
}

interface AlertsChartData {
  date: Date;
  alerts: number;
}

function alertStatsToChartData(stat: AlertsCountStatsQuery['stats'][number]): AlertsChartData {
  return {
    date: new Date(stat.created_at || ''),
    alerts: (stat.total_at_risk_count || 0) + (stat.total_failing_count || 0),
  };
}

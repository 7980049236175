import { StreamDownloader } from './stream-downloader';

export class FetchStreamDownloader implements StreamDownloader<string> {
  async downloadFile(file: string, options?: { signal: AbortSignal }): Promise<ReadableStream> {
    const response = await fetch(file, { ...options });

    if (!response.ok || !response.body) {
      throw new Error(`Failed to fetch file ${file}: ${response.status} ${response.statusText}`);
    }

    return response.body;
  }
}

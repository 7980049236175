/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type CreateVendorQuestionnaireAiReviewEntryMutationVariables = Types.Exact<{
  vendorQuestionnaireId: Types.Scalars['uuid']['input'];
}>;

export type CreateVendorQuestionnaireAiReviewEntryMutation = {
  __typename?: 'mutation_root';
  insert_vendor_questionnaire_ai_review_history?: {
    __typename?: 'vendor_questionnaire_ai_review_history_mutation_response';
    returning: Array<{ __typename?: 'vendor_questionnaire_ai_review_history'; id: string }>;
  };
};

export const CreateVendorQuestionnaireAiReviewEntryDocument = `
    mutation CreateVendorQuestionnaireAiReviewEntry($vendorQuestionnaireId: uuid!) {
  insert_vendor_questionnaire_ai_review_history(
    objects: {vendor_questionnaire_id: $vendorQuestionnaireId}
  ) {
    returning {
      id
    }
  }
}
    ` as string &
  TypedDocumentNode<
    CreateVendorQuestionnaireAiReviewEntryMutation,
    CreateVendorQuestionnaireAiReviewEntryMutationVariables
  >;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    CreateVendorQuestionnaireAiReviewEntry: build.mutation<
      CreateVendorQuestionnaireAiReviewEntryMutation,
      CreateVendorQuestionnaireAiReviewEntryMutationVariables
    >({
      query: (variables) => ({
        document: CreateVendorQuestionnaireAiReviewEntryDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useCreateVendorQuestionnaireAiReviewEntryMutation } = injectedRtkApi;

import type { GroupBase } from 'chakra-react-select';

import type { ComboboxProps } from './combobox';

const IS_NEW_SYMBOL = Symbol('isNew');

export function isNewOption<TOption extends {}>(
  option: TOption,
): option is TOption & { [IS_NEW_SYMBOL]?: boolean } {
  return IS_NEW_SYMBOL in option;
}

/**
 * `react-select` does not provide information if the option is creatable during render.
 * This helper wraps required handlers to add unique symbol to the creatable option
 * and then remove it before the options reaches `onChange` callback.
 * To differentiate such option from the rest, use the `isNewOption` helper.
 */
export function tagNewOption<
  TOption extends { [IS_NEW_SYMBOL]?: boolean },
  IsMulti extends boolean,
  TGroup extends GroupBase<TOption>,
>(props: ComboboxProps<TOption, IsMulti, TGroup>): ComboboxProps<TOption, IsMulti, TGroup> {
  if (!props.getNewOptionData) {
    return props;
  }

  const { getNewOptionData, onChange } = props;

  return {
    ...props,

    getNewOptionData: (...args) => {
      const newOption = getNewOptionData(...args);
      newOption[IS_NEW_SYMBOL] = true;
      return newOption;
    },

    onChange: (...args) => {
      const { option } = args[1];
      if (option && isNewOption(option)) {
        delete option[IS_NEW_SYMBOL];
      }
      return onChange?.(...args);
    },
  };
}

import {
  ChakraProps,
  Collapse,
  Menu,
  MenuButton,
  MenuList,
  Stack,
  Tooltip,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { LinkProps } from '@tanstack/react-router';
import { MouseEvent } from 'react';

import { SidebarNavGroupTitle } from './sidebar-nav-group-title';
import { SidebarNavItem } from './sidebar-nav-item';

export interface SidebarMenuProps extends React.ComponentProps<'a'> {
  /** Define menu items using {@link menuItem} */
  items: readonly SidebarMenuItem[];
  isSubmenu?: boolean;
  isGroup?: boolean;
  isSidebarCollapsed?: boolean;
  isNested?: boolean;
}

export const SidebarMenu = ({
  items,
  isSubmenu,
  isGroup,
  isNested,
  isSidebarCollapsed,
  children,
  ...rest
}: SidebarMenuProps) => {
  return (
    <Stack
      spacing={0}
      py={0}
      px={isSubmenu || isGroup ? 0 : 3}
      pl={isGroup || (isSubmenu && isSidebarCollapsed) ? 0 : 3}
    >
      {items.map((item, i) => (
        <SidebarMenuItem
          key={item.href || i}
          {...rest}
          item={item}
          isSubitem={isSubmenu}
          isNested={isNested}
          isSidebarCollapsed={isSidebarCollapsed}
        />
      ))}
      {children}
    </Stack>
  );
};

export interface SidebarMenuItemProps extends React.ComponentProps<'a'> {
  item: SidebarMenuItem;
  isSubitem?: boolean;
  isSidebarCollapsed?: boolean;
  isNested?: boolean;
}

export type SidebarMenuItem = {
  label: React.ReactNode;
  icon?: React.ElementType;
  shortcut?: React.ReactNode;
  children?: readonly SidebarMenuItem[];
  chakraProps?: ChakraProps;
  isGroup?: boolean;
} & Omit<LinkProps, 'children'>;

export function menuItem<TItem extends SidebarMenuItem>(item: TItem): TItem {
  return item;
}

export const SidebarMenuItem = ({ item, isNested, ...rest }: SidebarMenuItemProps) => {
  const { icon, children, isGroup, ...restItem } = item;
  const hasChildren = !!children?.length;
  const disclosure = useDisclosure({ defaultIsOpen: true });
  const menuDisclosure = useDisclosure();
  const isOpen = hasChildren ? disclosure.isOpen : undefined;
  const bgHoverColor = useColorModeValue('blue.25', 'rgba(214, 188, 250, 0.10)');
  const onClick = (event: MouseEvent<HTMLAnchorElement>) => {
    if (hasChildren) {
      disclosure.onToggle();
    } else {
      restItem.onClick?.(event);
      rest.onClick?.(event);
    }
    menuDisclosure.onClose();
  };

  return (
    <>
      {isGroup ? (
        <SidebarNavGroupTitle isSidebarCollapsed={rest.isSidebarCollapsed}>
          {item.label}
        </SidebarNavGroupTitle>
      ) : (
        (!rest.isSidebarCollapsed || (rest.isSidebarCollapsed && !hasChildren)) && (
          <SidebarNavItem
            {...rest}
            {...restItem}
            icon={icon}
            onClick={onClick}
            hasSubitems={hasChildren}
            isOpen={isOpen}
            isSidebarCollapsed={rest.isSidebarCollapsed}
            showAsTooltip={rest.isSidebarCollapsed && !isNested}
          >
            {item.label}
          </SidebarNavItem>
        )
      )}
      {hasChildren &&
        (rest.isSidebarCollapsed && !isGroup ? (
          <Menu {...menuDisclosure}>
            <Tooltip label={item.label} placement="right">
              <MenuButton w="full" borderRadius="md" _hover={{ bg: bgHoverColor }}>
                <SidebarNavItem
                  {...rest}
                  {...restItem}
                  icon={icon}
                  onClick={undefined}
                  isSidebarCollapsed={rest.isSidebarCollapsed}
                  showAsTooltip={true}
                >
                  {item.label}
                </SidebarNavItem>
              </MenuButton>
            </Tooltip>
            <MenuList
              p={2}
              shadow={'lg'}
              rounded={'md'}
              maxW={'sm  '}
              maxH="300px"
              overflowY="auto"
            >
              <SidebarMenu
                {...rest}
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                items={item.children!}
                isSubmenu
                isGroup={isGroup}
                isNested={true}
                onClick={onClick}
              />
            </MenuList>
          </Menu>
        ) : (
          <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
            <SidebarMenu
              {...rest}
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              items={item.children!}
              isSubmenu={!isGroup}
              isGroup={isGroup}
              isNested={false}
              onClick={isGroup ? rest.onClick : undefined}
            />
          </Collapse>
        ))}
    </>
  );
};

import { Box } from '@chakra-ui/react';
import { FieldConfigFragment } from '@main/graphql/fragments/FieldConfigFragments.generated';
import { useGetVendorsQuery } from '@main/graphql/queries/GetVendors.generated';
import { Field_Entities_Enum } from '@main/graphql/types.generated';
import { NoPermissionPlaceholder, Table } from '@main/ui';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../hooks/redux-toolkit-hooks';
import { CSVInput } from '../../../utils/export-csv';
import { serializeCustomFields } from '../../custom-fields/custom-field';
import { customFieldColumn } from '../../custom-fields/custom-field-column';
import { useGetFieldConfigsQuery } from '../../custom-fields/field-config';
import { getCurrentUserSelectedOrgId, getCurrentUserSelectedOrgRole } from '../../user/slice';
import { getMappedVendors, OrganizationVendor } from '../../vendors/slice';
import { useVendorColumnHelper } from '../../vendors/table-columns';
import { useVendorsTableSettings } from '../../vendors/table-settings';
import { useUpdateReportHandler } from '../use-update-report-handler';
import { ReportCardBase } from './report-card-base';

export const VendorsReportCard = ({
  reportId,
  onExportCSV,
}: {
  reportId: string;
  onExportCSV: (data: CSVInput[]) => void;
}) => {
  const { t } = useTranslation();
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canViewVendors = userRole.permissionMap?.read_vendors;

  const organizationId = useAppSelector(getCurrentUserSelectedOrgId);
  const { isLoading } = useGetVendorsQuery({ organization_id: organizationId });
  const fieldConfigsQuery = useGetFieldConfigsQuery({
    orgId: organizationId,
    entityName: Field_Entities_Enum.Vendor,
  });
  const vendors = useAppSelector(getMappedVendors);
  const { columnVisibility, setColumnVisibility } = useVendorsTableSettings(
    `reports:${reportId}:vendors-table:column-visibility`,
  );
  const columns = useVendorTableColumns({
    fieldConfigs: fieldConfigsQuery.data?.field_configs,
  });

  const {
    filters: [columnFilters, setColumnFilters],
    sorting: [sorting, setSorting],
  } = useUpdateReportHandler({ columns });
  const [filteredRows, setFilteredRows] = useState<OrganizationVendor[]>([]);

  const tableItemName = useMemo(() => {
    return {
      singular: t('entities.vendor').toLowerCase(),
      plural: t('entities.plural.vendors').toLowerCase(),
    };
  }, [t]);

  const handleExportCSV = () => {
    const fieldConfigs = fieldConfigsQuery.data?.field_configs || [];
    const filteredData = filteredRows.map((row) => {
      return {
        Name: row.name,
        Description: row.description,
        Status: t(`vendors.enum.assessmentStatus.${row.assessment_status}`),
        Approval:
          row.approval_status && t(`vendors.props.approvalStatus.enums.${row.approval_status}`),
        'Risk level': row.risk_level?.name,
        Tier: row.tier?.name,
        Owner: row.owner?.displayName,
        'Vendor contact': row.contact_email,
        'Review frequency': t(`vendors.frequency.${row.review_frequency}`),
        'Last review date': row.last_reviewed_date,
        ...serializeCustomFields(fieldConfigs, row.field_values),
      };
    });
    onExportCSV(filteredData);
  };

  if (!canViewVendors) {
    return <NoPermissionPlaceholder />;
  }

  return (
    <ReportCardBase
      heading={t('entities.plural.vendors')}
      subheading={t('vendors.subheading')}
      onExportCSV={handleExportCSV}
    >
      <Box borderRadius="inherit" overflowX="auto">
        <Table
          entity="vendor"
          minW="900px"
          isLoading={isLoading}
          data={vendors}
          columns={columns}
          itemName={tableItemName}
          pageSize={15}
          columnVisibility={columnVisibility}
          onColumnVisibilityChange={setColumnVisibility}
          columnFilters={columnFilters}
          onColumnFiltersChange={setColumnFilters}
          onFilteredDataChange={setFilteredRows}
          sorting={sorting}
          onSortingChange={setSorting}
        />
      </Box>
    </ReportCardBase>
  );
};

function useVendorTableColumns({ fieldConfigs = [] }: { fieldConfigs?: FieldConfigFragment[] }) {
  const vendorColumnHelper = useVendorColumnHelper();

  return useMemo(
    () => [
      vendorColumnHelper.status(),
      vendorColumnHelper.internalId(),
      vendorColumnHelper.name({ onClickOpenDrawer: true }),
      vendorColumnHelper.description(),
      vendorColumnHelper.riskLevel(),
      vendorColumnHelper.tier(),
      vendorColumnHelper.approvalStatus(),
      vendorColumnHelper.questionnaireStatus(),
      vendorColumnHelper.assessmentDate(),
      vendorColumnHelper.owner(),
      ...fieldConfigs.map(customFieldColumn<OrganizationVendor>),
    ],
    [vendorColumnHelper, fieldConfigs],
  );
}

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type GetOrganizationControlStatusesQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['uuid']['input'];
}>;

export type GetOrganizationControlStatusesQuery = {
  __typename?: 'query_root';
  controls: Array<{ __typename?: 'controls'; id: string; status: ClientTypes.ControlStatus }>;
};

export const GetOrganizationControlStatusesDocument = `
    query GetOrganizationControlStatuses($organizationId: uuid!) {
  controls(where: {organization_id: {_eq: $organizationId}}) {
    id
    status
  }
}
    ` as string &
  TypedDocumentNode<
    GetOrganizationControlStatusesQuery,
    GetOrganizationControlStatusesQueryVariables
  >;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetOrganizationControlStatuses: build.query<
      GetOrganizationControlStatusesQuery,
      GetOrganizationControlStatusesQueryVariables
    >({
      query: (variables) => ({ document: GetOrganizationControlStatusesDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useGetOrganizationControlStatusesQuery,
  useLazyGetOrganizationControlStatusesQuery,
} = injectedRtkApi;

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type VendorsTabGetVendorsQueryVariables = Types.Exact<{
  questionnaireId: Types.Scalars['uuid']['input'];
}>;

export type VendorsTabGetVendorsQuery = {
  __typename?: 'query_root';
  vendor_questionnaires: Array<{
    __typename?: 'vendor_questionnaires';
    id: string;
    status: Types.Vendor_Questionnaire_Statuses_Enum;
    due_date?: string;
    is_disabled: boolean;
    sent_by_user: { __typename?: 'users'; id: string; displayName: string };
    vendor: {
      __typename?: 'vendors';
      id: string;
      name?: string;
      assessment_status: ClientTypes.VendorAssessmentStatus;
    };
  }>;
};

export const VendorsTabGetVendorsDocument = `
    query VendorsTabGetVendors($questionnaireId: uuid!) {
  vendor_questionnaires(where: {questionnaire_id: {_eq: $questionnaireId}}) {
    id
    status
    due_date
    is_disabled
    sent_by_user {
      id
      displayName
    }
    vendor {
      id
      name
      assessment_status
    }
  }
}
    ` as string & TypedDocumentNode<VendorsTabGetVendorsQuery, VendorsTabGetVendorsQueryVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    VendorsTabGetVendors: build.query<
      VendorsTabGetVendorsQuery,
      VendorsTabGetVendorsQueryVariables
    >({
      query: (variables) => ({ document: VendorsTabGetVendorsDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useVendorsTabGetVendorsQuery, useLazyVendorsTabGetVendorsQuery } = injectedRtkApi;

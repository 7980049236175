/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type DeleteOrganizationRoleMutationVariables = Types.Exact<{
  roleId: Types.Scalars['uuid']['input'];
}>;

export type DeleteOrganizationRoleMutation = {
  __typename?: 'mutation_root';
  delete_roles_by_pk?: { __typename?: 'roles'; name: string };
};

export const DeleteOrganizationRoleDocument = `
    mutation DeleteOrganizationRole($roleId: uuid!) {
  delete_roles_by_pk(id: $roleId) {
    name
  }
}
    ` as string &
  TypedDocumentNode<DeleteOrganizationRoleMutation, DeleteOrganizationRoleMutationVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    DeleteOrganizationRole: build.mutation<
      DeleteOrganizationRoleMutation,
      DeleteOrganizationRoleMutationVariables
    >({
      query: (variables) => ({ document: DeleteOrganizationRoleDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useDeleteOrganizationRoleMutation } = injectedRtkApi;

import { ThemeTypings } from '@chakra-ui/react';
import { RiskAssessmentStatus } from '@main/graphql/client-scalars';
import { api as getVendorsApi } from '@main/graphql/queries/GetVendors.generated';
import { Severity } from '@main/shared/utils';
import { createSelector } from '@reduxjs/toolkit';

import { AppRootState } from '../../store';
import { getInherentAndResidualLevelForRisk, getMatrixLevelsMap } from '../risks/slice';
import { getCurrentUserSelectedOrgId } from '../user/slice';
import { api as getVendorApi } from './get-vendor.generated';
import { api as getVendorQuestionnaireApi } from './vendor-questionnaires/vendor-questionnaires.generated';
import { api as getVendorRisksApi } from './VendorRisks.generated';

const emptyArray: never[] = [];
const getVendorApiData = createSelector(
  (state: AppRootState, vendorId: string) => ({ state, vendorId }),
  ({ state, vendorId }) => {
    return getVendorApi.endpoints.GetVendor.select({
      vendor_id: vendorId,
    })(state).data?.vendors_by_pk;
  },
);

const getVendorsApiData = createSelector(
  [(state: AppRootState) => state, getCurrentUserSelectedOrgId],
  (state, orgId) => {
    return (
      getVendorsApi.endpoints.GetVendors.select({
        organization_id: orgId,
      })(state).data?.vendors || emptyArray
    );
  },
);

const getVendorRisksApiData = createSelector(
  (state: AppRootState, vendorId: string) => ({ state, vendorId }),
  ({ state, vendorId }) => {
    return (
      getVendorRisksApi.endpoints.GetVendorRisks.select({
        vendorId,
      })(state).data?.vendor_risks || emptyArray
    );
  },
);

const getLatestAiReviewForVendorApiData = createSelector(
  (state: AppRootState, vendorQuestionnaireId: string) => ({ state, vendorQuestionnaireId }),
  ({ state, vendorQuestionnaireId }) => {
    return (
      getVendorQuestionnaireApi.endpoints.GetVendorQuestionnairesById.select({
        vqId: vendorQuestionnaireId,
      })(state).data?.vendor_questionnaires_by_pk?.vendor_questionnaire_ai_review_histories ||
      emptyArray
    );
  },
);

export const getLatestAiReviewForVendorQuestionnaire = createSelector(
  [getLatestAiReviewForVendorApiData],
  (data) => data[0],
);

export const getAiReviewImprovements = createSelector(
  [getLatestAiReviewForVendorQuestionnaire],
  (latestAiReview) => {
    return (
      latestAiReview?.result
        ?.map((entry, idx) => ({ ...entry, questionIdx: idx + 1 }))
        .filter((entry) => entry.has_review && entry.assessment?.high_confidence === false) ??
      emptyArray
    );
  },
);

export type OrganizationVendor = ReturnType<typeof getMappedVendors>[number];

export const getMappedVendors = createSelector([getVendorsApiData], (vendors) =>
  vendors.map((vendor) => ({
    ...vendor,
  })),
);

export const getMappedVendor = createSelector([getVendorApiData], (vendor) => {
  if (!vendor) {
    return;
  }

  return {
    ...vendor,
  };
});

export const getVendorTiers = createSelector([getVendorApiData], (vendor) => {
  if (!vendor?.tier) {
    return undefined;
  }

  return {
    value: vendor.tier.id,
    label: vendor.tier.name,
    colorScheme: 'purple',
  };
});

export const getVendorRiskLevels = createSelector([getVendorApiData], (vendor) => {
  if (!vendor?.risk_level) {
    return undefined;
  }

  return {
    value: vendor.risk_level.id,
    label: vendor.risk_level.name,
    colorScheme: 'purple',
  };
});

export const getVendorRisks = createSelector(
  [getVendorRisksApiData, getMatrixLevelsMap],
  (risks, matrixLevelsMap) => {
    return risks.map(({ risk, id }) => ({
      ...risk,
      vendorRiskId: id,
      internalId: risk.internal_id,
      ...getInherentAndResidualLevelForRisk(risk, matrixLevelsMap),
    }));
  },
);

export const getVendorRisksSeverity = createSelector([getVendorRisks], (risks) => {
  const isOverdue = risks.some((risk) => risk.assessment_status === RiskAssessmentStatus.OVERDUE);
  if (isOverdue) {
    return Severity.HIGH;
  }
  return Severity.LOW;
});

export type Option = {
  value: string;
  label: string;
  colorScheme?: ThemeTypings['colorSchemes'];
};

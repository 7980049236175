import {
  Button,
  ButtonGroup,
  FormControl,
  FormLabel,
  Input,
  ModalBody,
  ModalFooter,
  Stack,
  Textarea,
} from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  useCreateRiskImpactMutation,
  useCreateRiskLikelihoodMutation,
  useGetRiskClassificationsQuery,
  useUpdateRiskImpactMutation,
  useUpdateRiskLikelihoodMutation,
} from '@main/graphql/features/RiskClassifications.generated';
import { toError } from '@main/shared/utils';
import { errorToast, successToast } from '@main/ui';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { useAppSelector } from '../../../../../hooks/redux-toolkit-hooks';
import { getCurrentUserSelectedOrgId } from '../../../../user/slice';
import { Classification } from './table';

const formSchema = z.object({
  name: z.string().min(1),
  description: z.string().min(1),
});
type ClassificationFormSchema = z.infer<typeof formSchema>;
export type ClassificationType = 'impact' | 'likelihood';

type ClassificationFormProps = {
  classificationType: ClassificationType;
  actionType: 'create' | 'edit';
  onClose: () => void;
  value?: Classification;
};

export function ClassificationForm({
  classificationType,
  actionType,
  onClose,
  value,
}: ClassificationFormProps) {
  const { t } = useTranslation();
  const organizationId = useAppSelector(getCurrentUserSelectedOrgId);
  const [createImpact] = useCreateRiskImpactMutation();
  const [updateImpact] = useUpdateRiskImpactMutation();
  const [createLikelihood] = useCreateRiskLikelihoodMutation();
  const [updateLikelihood] = useUpdateRiskLikelihoodMutation();
  const { refetch: refetchClassifications } = useGetRiskClassificationsQuery({ organizationId });

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<ClassificationFormSchema>({
    resolver: zodResolver(formSchema),
    shouldFocusError: false,
    defaultValues: {
      name: value?.name || '',
      description: value?.description || '',
    },
  });

  const onCreate: SubmitHandler<ClassificationFormSchema> = async (data) => {
    const translation =
      classificationType === 'impact'
        ? t('entities.impact').toLowerCase()
        : t('entities.likelihood').toLowerCase();
    try {
      if (classificationType === 'impact') {
        await createImpact({
          object: {
            name: data.name,
            description: data.description,
            organization_id: organizationId,
          },
        });
      } else {
        await createLikelihood({
          object: {
            name: data.name,
            description: data.description,
            organization_id: organizationId,
          },
        });
      }

      refetchClassifications();
      onClose();
      reset();
      successToast(
        t('successMessages.createSucceeded', {
          entity: `${t('entities.risk')} ${translation}`,
        }),
      );
    } catch (error) {
      errorToast(
        t('errorMessages.createFailed', {
          entity: `${t('entities.risk')} ${translation}`,
        }),
      );
      datadogLogs.logger.error(
        `Creating new risk ${classificationType} failed`,
        {},
        toError(error),
      );
    }
  };

  const onUpdate: SubmitHandler<ClassificationFormSchema> = async (data) => {
    if (!value) {
      return;
    }
    const translation =
      classificationType === 'impact'
        ? t('entities.impact').toLowerCase()
        : t('entities.likelihood').toLowerCase();
    try {
      if (classificationType === 'impact') {
        await updateImpact({
          id: value.id,
          input: {
            name: data.name,
            description: data.description,
          },
        });
      } else {
        await updateLikelihood({
          id: value.id,
          input: {
            name: data.name,
            description: data.description,
          },
        });
      }

      refetchClassifications();
      onClose();
      reset();
      successToast(
        t('successMessages.editSucceeded', {
          entity: `${t('entities.risk')} ${translation}`,
        }),
      );
    } catch (error) {
      errorToast(
        t('errorMessages.updateFailed', {
          entity: `${t('entities.risk')} ${translation}`,
        }),
      );
      datadogLogs.logger.error(
        `Updating new risk ${classificationType} failed`,
        {},
        toError(error),
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(actionType === 'create' ? onCreate : onUpdate)}>
      <ModalBody as={Stack} gap={4}>
        <FormControl>
          <FormLabel>{t('settings.organization.risks.classifications.form.name.label')}</FormLabel>
          <Input
            placeholder={t('settings.organization.risks.classifications.form.name.placeholder', {
              classification:
                classificationType === 'impact'
                  ? t('entities.impact').toLowerCase()
                  : t('entities.likelihood').toLowerCase(),
            })}
            {...register('name')}
            isInvalid={!!errors.name}
          />
        </FormControl>
        <FormControl>
          <FormLabel>
            {t('settings.organization.risks.classifications.form.description.label')}
          </FormLabel>
          <Textarea
            placeholder={t(
              'settings.organization.risks.classifications.form.description.placeholder',
              {
                classification:
                  classificationType === 'impact'
                    ? t('entities.impact').toLowerCase()
                    : t('entities.likelihood').toLowerCase(),
              },
            )}
            {...register('description')}
            isInvalid={!!errors.description}
          />
        </FormControl>
      </ModalBody>
      <ModalFooter>
        <ButtonGroup spacing={3}>
          <Button onClick={onClose}>{t('buttons.cancel')}</Button>
          <Button colorScheme="blue" type="submit" isLoading={isSubmitting}>
            {actionType === 'create' ? t('buttons.create') : t('buttons.update')}
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </form>
  );
}

import { Outlet, Route } from '@tanstack/react-router';

import { organizationSettingsLayoutRoute } from '../layout';

const PoliciesLayout = () => {
  return <Outlet />;
};

export const organizationPoliciesSettingsLayoutRoute = new Route({
  getParentRoute: () => organizationSettingsLayoutRoute,
  path: 'policies',
  component: PoliciesLayout,
});

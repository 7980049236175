import {
  useGetVendorQuestionnairesByIdQuery,
  useGetVendorQuestionnairesByIdSubscription,
} from './vendor-questionnaires.subs';

export const useGetVendorQuestionnaireData = (vqId: string) => {
  const { isLoading } = useGetVendorQuestionnairesByIdQuery({
    vqId,
  });
  const { vq, answers, fields, findings, questionnaire, vendor } =
    useGetVendorQuestionnairesByIdSubscription(
      {
        vqId,
      },
      {
        selectFromResult: ({ data }) => {
          return {
            vq: data?.vendor_questionnaires_by_pk,
            questionnaire: data?.vendor_questionnaires_by_pk?.questionnaire,
            vendor: data?.vendor_questionnaires_by_pk?.vendor,
            fields: data?.vendor_questionnaires_by_pk?.form?.config_snapshot ?? [],
            answers: data?.vendor_questionnaires_by_pk?.form?.answers ?? [],
            findings:
              data?.vendor_questionnaires_by_pk?.vendor_questionnaire_ai_review_findings ?? [],
          };
        },
      },
    );

  return {
    vq,
    fields,
    answers,
    findings,
    questionnaire,
    vendor,
    isLoading,
  };
};

import { As, Flex, Icon, Text, useColorModeValue } from '@chakra-ui/react';
import { MouseEventHandler } from 'react';

type OptionCardProps = {
  icon: As;
  title: string;
  subTitle: string;
  onClick: MouseEventHandler<HTMLDivElement>;
};

export const OptionCard = ({ icon, title, subTitle, onClick }: OptionCardProps) => {
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const borderColorOnHover = useColorModeValue('purple.400', 'purple.500');

  return (
    <Flex
      as="button"
      _hover={{ borderColor: borderColorOnHover }}
      _focus={{ borderColor: borderColorOnHover }}
      cursor="pointer"
      flexDir="column"
      justify="center"
      align="center"
      border="1px"
      borderColor={borderColor}
      px={{ base: '3', sm: '6' }}
      py="6"
      rounded="lg"
      onClick={onClick}
    >
      <Icon
        as={icon}
        boxSize="12"
        bg="blue.50"
        p="3"
        rounded="md"
        _dark={{ backgroundColor: 'blue.200' }}
        color="blue.400"
        mb={4}
      />
      <Text mb={1} fontWeight="semibold">
        {title}
      </Text>
      <Text color="gray.500" align="center" fontSize={'sm'}>
        {subTitle}
      </Text>
    </Flex>
  );
};

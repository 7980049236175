import { TrashIcon } from '@heroicons/react/24/outline';
import { isNonNullable } from '@main/shared/utils';
import { useDrawer } from '@main/ui';
import { useTranslation } from 'react-i18next';

import { useDeleteRisk } from './delete-risk';

export const useRiskDrawerActions = (riskId: string) => {
  const deleteRisk = useDeleteRiskAction(riskId);

  return {
    menuActions: [deleteRisk].filter(isNonNullable),
  };
};

function useDeleteRiskAction(riskId: string) {
  const { t } = useTranslation();
  const drawer = useDrawer();

  const deleteRisk = useDeleteRisk();
  if (!deleteRisk) {
    return null;
  }

  return {
    icon: <TrashIcon />,
    label: t('buttons.delete'),
    onClick: () => {
      deleteRisk(riskId, () => drawer.close());
    },
  };
}

import { Button, Card, Flex, Icon, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { SearchEntitiesEnum } from '@main/graphql/types.generated';
import { isNonNullable, toError } from '@main/shared/utils';
import { errorToast, NoPermissionPlaceholder, successToast, Table, TableItem } from '@main/ui';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { EntitySearch } from '../link-entity/entity-search';
import { useLinkTaskToPolicyMutation } from '../link-entity/link-entity.generated';
import { LinkEntityModal } from '../link-entity/link-entity-modal';
import { useEntitySearch } from '../link-entity/use-entity-search';
import { useTaskColumnHelper } from '../tasks/table-columns';
import { useGetTasksSubscription } from '../tasks/tasks-subscription';
import { useUnlinkTask } from '../tasks/unlink-task';
import { useLinkTaskHandler } from '../tasks/use-link-task-handler';
import { getCurrentUserSelectedOrgId, getCurrentUserSelectedOrgRole } from '../user/slice';
import { useCreatePolicyTaskMutation } from './manage-tasks.generated';

export const PolicyTasksTab = ({ policyId }: { policyId: string }) => {
  const { t } = useTranslation();
  const [newTaskId, setNewTaskId] = useState<string>();
  const orgId = useAppSelector(getCurrentUserSelectedOrgId);
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canUpdatePolicy = userRole.permissionMap?.write_policies;
  const canCreateTask = userRole.permissionMap?.write_tasks;
  const canViewTask = userRole.permissionMap?.read_tasks;
  const [createTask, { isLoading: isTaskCreationLoading }] = useCreatePolicyTaskMutation();
  const columns = usePolicyTaskTableColumns(policyId, newTaskId);

  const taskSearch = useEntitySearch({
    entities: [SearchEntitiesEnum.Tasks],
  });
  const [linkTaskToPolicy, { isLoading: isLinkingTask }] = useLinkTaskToPolicyMutation();
  const linkHandler = useLinkTaskHandler({ callback: () => taskSearch.disclosure.onClose?.() });

  const { tasks, isLoading: isTaskLoading } = useGetTasksSubscription(
    {
      where: {
        tasks_policy_tasks: {
          policy_id: {
            _eq: policyId,
          },
        },
      },
    },
    {
      selectFromResult: ({ data, isLoading }) => {
        return { tasks: data?.tasks ?? [], isLoading };
      },
    },
  );

  const createTaskHandler = async () => {
    try {
      const { insert_policy_tasks_one } = await createTask({
        input: {
          policy_id: policyId,
          policy_tasks_task: {
            data: {
              organization_id: orgId,
            },
          },
        },
      }).unwrap();

      setNewTaskId(insert_policy_tasks_one?.task_id);
      successToast(t('successMessages.createSucceeded', { entity: t('entities.task') }));
    } catch (error) {
      errorToast(t('errorMessages.createFailed', { entity: t('entities.task') }));
      datadogLogs.logger.error('Failed to create policy task', {}, toError(error));
    }
  };

  const tableItemName = useMemo((): TableItem => {
    return {
      singular: t('entities.task').toLowerCase(),
      plural: t('entities.plural.tasks').toLowerCase(),
      alternateSubheading: true,
      hideSubheading: !canCreateTask,
    };
  }, [canCreateTask, t]);

  if (!canViewTask) {
    return <NoPermissionPlaceholder />;
  }

  return (
    <Flex direction="column" justify="center" gap="8">
      <Card variant={'table-styles'}>
        <Table
          minW="400px"
          entity="task"
          data={tasks}
          isLoading={isTaskLoading}
          columns={columns}
          itemName={tableItemName}
        />
      </Card>
      {canUpdatePolicy && (
        <Menu>
          <MenuButton
            as={Button}
            isLoading={isTaskCreationLoading}
            rightIcon={<Icon as={ChevronDownIcon} />}
            colorScheme="blue"
            alignSelf="end"
          >
            {t('tasks.addTask')}
          </MenuButton>
          <MenuList>
            {canCreateTask && (
              <MenuItem onClick={createTaskHandler}>{t('tasks.addNewTask')}</MenuItem>
            )}
            <MenuItem onClick={() => taskSearch.disclosure.onOpen?.()}>
              {t('tasks.linkExistingTask')}
            </MenuItem>
          </MenuList>
        </Menu>
      )}

      <LinkEntityModal
        isOpen={taskSearch.disclosure.isOpen}
        onClose={() => taskSearch.disclosure.onClose?.()}
        entityName="task"
        onLinkEntity={() =>
          linkHandler(
            linkTaskToPolicy({
              input: taskSearch.selectedResults.map((result) => ({
                policy_id: policyId,
                task_id: result.id,
              })),
            }),
          )
        }
        isLinkingEntity={isLinkingTask}
      >
        <EntitySearch
          input={{
            placeholder: t('shared.linkModal.placeholder', {
              entity: t('entities.task').toLowerCase(),
            }),
          }}
          {...taskSearch}
        />
      </LinkEntityModal>
    </Flex>
  );
};

const usePolicyTaskTableColumns = (policyId: string, newTaskId: string | undefined) => {
  const columnHelper = useTaskColumnHelper();
  const unlinkTask = useUnlinkTask();

  return useMemo(() => {
    return [
      columnHelper.status(),
      columnHelper.name({ isEditable: true, newTaskId }),
      columnHelper.dueDate({ isEditable: true }),
      columnHelper.owner({ isEditable: true }),
      columnHelper.actions({
        onUnlink: (task) =>
          unlinkTask({ taskId: task.id, linkedEntity: 'policy', entityId: policyId }),
      }),
    ].filter(isNonNullable);
  }, [columnHelper, newTaskId, unlinkTask, policyId]);
};

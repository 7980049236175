import { $isListItemNode, ListItemNode } from '@lexical/list';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $createParagraphNode } from 'lexical';
import { useEffect } from 'react';

/**
 * Breaks the list when current and previous sibling nodes are an empty list items,
 * by replacing the current node with a paragraph node and removing the previous sibling node.
 */
export function BreakListsPlugin() {
  const [editor] = useLexicalComposerContext();

  useEffect(
    () =>
      editor.registerNodeTransform(ListItemNode, (node) => {
        const prevNode = node.getPreviousSibling();

        if (
          node.getTextContentSize() !== 0 ||
          !$isListItemNode(prevNode) ||
          prevNode.getTextContentSize() !== 0
        ) {
          return node;
        }

        const pNode = $createParagraphNode();
        pNode.select();
        prevNode.remove();

        return node.replace(pNode);
      }),
    [editor],
  );

  return null;
}

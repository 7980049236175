/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import {
  FieldConfigFragmentDoc,
  SelectFieldConfigFragmentDoc,
  SelectFieldOptionFragmentDoc,
} from '../fragments/FieldConfigFragments.generated';
import { FieldValueFragmentDoc } from '../fragments/FieldValueFragments.generated';
import { api } from '@main/graphql/shared-api';
export type GetFieldConfigByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
}>;

export type GetFieldConfigByIdQuery = {
  __typename?: 'query_root';
  field_configs_by_pk?: {
    __typename?: 'field_configs';
    id: string;
    organization_id: string;
    entity_name: Types.Field_Entities_Enum;
    field_type: Types.Field_Types_Enum;
    name: string;
    hidden: boolean;
    order?: string;
    select_field_config?: {
      __typename?: 'select_field_configs';
      field_config_id: string;
      is_multi: boolean;
      is_creatable: boolean;
      select_field_options: Array<{
        __typename?: 'select_field_options';
        id: string;
        value: string;
      }>;
    };
  };
};

export type GetFieldConfigsQueryVariables = Types.Exact<{
  where: Types.Field_Configs_Bool_Exp;
}>;

export type GetFieldConfigsQuery = {
  __typename?: 'query_root';
  field_configs: Array<{
    __typename?: 'field_configs';
    id: string;
    organization_id: string;
    entity_name: Types.Field_Entities_Enum;
    field_type: Types.Field_Types_Enum;
    name: string;
    hidden: boolean;
    order?: string;
    select_field_config?: {
      __typename?: 'select_field_configs';
      field_config_id: string;
      is_multi: boolean;
      is_creatable: boolean;
      select_field_options: Array<{
        __typename?: 'select_field_options';
        id: string;
        value: string;
      }>;
    };
  }>;
};

export type GetFieldConfigWithValuesQueryVariables = Types.Exact<{
  fieldConfigId: Types.Scalars['uuid']['input'];
  whereFieldValues?: Types.InputMaybe<Types.Field_Values_Bool_Exp>;
}>;

export type GetFieldConfigWithValuesQuery = {
  __typename?: 'query_root';
  field_configs_by_pk?: {
    __typename?: 'field_configs';
    id: string;
    organization_id: string;
    entity_name: Types.Field_Entities_Enum;
    field_type: Types.Field_Types_Enum;
    name: string;
    hidden: boolean;
    order?: string;
    field_values: Array<{
      __typename?: 'field_values';
      field_config_id: string;
      entity_name: Types.Field_Entities_Enum;
      field_type: Types.Field_Types_Enum;
      text_field_value?: {
        __typename?: 'text_field_values';
        field_value_id: string;
        value: string;
      };
      select_field_value?: {
        __typename?: 'select_field_values';
        field_value_id: string;
        select_field_option_id: string;
      };
    }>;
    select_field_config?: {
      __typename?: 'select_field_configs';
      field_config_id: string;
      is_multi: boolean;
      is_creatable: boolean;
      select_field_options: Array<{
        __typename?: 'select_field_options';
        id: string;
        value: string;
      }>;
    };
  };
};

export type InsertFieldConfigMutationVariables = Types.Exact<{
  fieldConfig: Types.Field_Configs_Insert_Input;
}>;

export type InsertFieldConfigMutation = {
  __typename?: 'mutation_root';
  insert_field_configs_one?: { __typename?: 'field_configs'; id: string };
};

export type UpdateFieldConfigMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
  fieldConfig: Types.Field_Configs_Set_Input;
}>;

export type UpdateFieldConfigMutation = {
  __typename?: 'mutation_root';
  update_field_configs_by_pk?: { __typename?: 'field_configs'; id: string };
};

export type UpdateFieldConfigsMutationVariables = Types.Exact<{
  updates: Array<Types.Field_Configs_Updates> | Types.Field_Configs_Updates;
}>;

export type UpdateFieldConfigsMutation = {
  __typename?: 'mutation_root';
  update_field_configs_many?: Array<{
    __typename?: 'field_configs_mutation_response';
    returning: Array<{ __typename?: 'field_configs'; id: string }>;
  }>;
};

export type UpdateSelectFieldConfigMutationVariables = Types.Exact<{
  fieldConfigId: Types.Scalars['uuid']['input'];
  setFieldConfig?: Types.InputMaybe<Types.Field_Configs_Set_Input>;
  setSelectFieldConfig?: Types.InputMaybe<Types.Select_Field_Configs_Set_Input>;
  deleteOptionIds?: Types.InputMaybe<
    Array<Types.Scalars['uuid']['input']> | Types.Scalars['uuid']['input']
  >;
  insertOptions?:
    | Array<Types.Select_Field_Options_Insert_Input>
    | Types.Select_Field_Options_Insert_Input;
}>;

export type UpdateSelectFieldConfigMutation = {
  __typename?: 'mutation_root';
  update_field_configs_by_pk?: { __typename?: 'field_configs'; id: string };
  update_select_field_configs_by_pk?: {
    __typename?: 'select_field_configs';
    field_config_id: string;
  };
  delete_select_field_options?: {
    __typename?: 'select_field_options_mutation_response';
    returning: Array<{ __typename?: 'select_field_options'; id: string }>;
  };
  insert_select_field_options?: {
    __typename?: 'select_field_options_mutation_response';
    returning: Array<{ __typename?: 'select_field_options'; id: string; value: string }>;
  };
};

export type DeleteFieldConfigMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
}>;

export type DeleteFieldConfigMutation = {
  __typename?: 'mutation_root';
  delete_field_configs_by_pk?: { __typename?: 'field_configs'; id: string };
};

export type InsertFieldValueMutationVariables = Types.Exact<{
  fieldValue: Types.Field_Values_Insert_Input;
}>;

export type InsertFieldValueMutation = {
  __typename?: 'mutation_root';
  insert_field_values_one?: { __typename?: 'field_values'; id: string };
};

export type UpdateTextFieldValueMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
  value: Types.Scalars['String']['input'];
}>;

export type UpdateTextFieldValueMutation = {
  __typename?: 'mutation_root';
  update_text_field_values_by_pk?: { __typename?: 'text_field_values'; field_value_id: string };
};

export type SetSelectFieldValuesMutationVariables = Types.Exact<{
  input: Types.SetSelectFieldValuesInput;
}>;

export type SetSelectFieldValuesMutation = {
  __typename?: 'mutation_root';
  set_select_field_values?: {
    __typename?: 'SetSelectFieldValuesOutput';
    deletedFieldValueIds: Array<string>;
    insertedFieldValueIds: Array<string>;
  };
};

export type DeleteFieldValueMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
}>;

export type DeleteFieldValueMutation = {
  __typename?: 'mutation_root';
  delete_field_values_by_pk?: { __typename?: 'field_values'; id: string };
};

export const GetFieldConfigByIdDocument = `
    query GetFieldConfigById($id: uuid!) {
  field_configs_by_pk(id: $id) {
    ...FieldConfig
  }
}
    ${FieldConfigFragmentDoc}
${SelectFieldConfigFragmentDoc}
${SelectFieldOptionFragmentDoc}` as string &
  TypedDocumentNode<GetFieldConfigByIdQuery, GetFieldConfigByIdQueryVariables>;
export const GetFieldConfigsDocument = `
    query GetFieldConfigs($where: field_configs_bool_exp!) {
  field_configs(where: $where, order_by: {order: asc_nulls_last, created_at: asc}) {
    ...FieldConfig
  }
}
    ${FieldConfigFragmentDoc}
${SelectFieldConfigFragmentDoc}
${SelectFieldOptionFragmentDoc}` as string &
  TypedDocumentNode<GetFieldConfigsQuery, GetFieldConfigsQueryVariables>;
export const GetFieldConfigWithValuesDocument = `
    query GetFieldConfigWithValues($fieldConfigId: uuid!, $whereFieldValues: field_values_bool_exp) {
  field_configs_by_pk(id: $fieldConfigId) {
    ...FieldConfig
    field_values(where: $whereFieldValues) {
      ...FieldValue
    }
  }
}
    ${FieldConfigFragmentDoc}
${SelectFieldConfigFragmentDoc}
${SelectFieldOptionFragmentDoc}
${FieldValueFragmentDoc}` as string &
  TypedDocumentNode<GetFieldConfigWithValuesQuery, GetFieldConfigWithValuesQueryVariables>;
export const InsertFieldConfigDocument = `
    mutation InsertFieldConfig($fieldConfig: field_configs_insert_input!) {
  insert_field_configs_one(object: $fieldConfig) {
    id
  }
}
    ` as string & TypedDocumentNode<InsertFieldConfigMutation, InsertFieldConfigMutationVariables>;
export const UpdateFieldConfigDocument = `
    mutation UpdateFieldConfig($id: uuid!, $fieldConfig: field_configs_set_input!) {
  update_field_configs_by_pk(pk_columns: {id: $id}, _set: $fieldConfig) {
    id
  }
}
    ` as string & TypedDocumentNode<UpdateFieldConfigMutation, UpdateFieldConfigMutationVariables>;
export const UpdateFieldConfigsDocument = `
    mutation UpdateFieldConfigs($updates: [field_configs_updates!]!) {
  update_field_configs_many(updates: $updates) {
    returning {
      id
    }
  }
}
    ` as string &
  TypedDocumentNode<UpdateFieldConfigsMutation, UpdateFieldConfigsMutationVariables>;
export const UpdateSelectFieldConfigDocument = `
    mutation UpdateSelectFieldConfig($fieldConfigId: uuid!, $setFieldConfig: field_configs_set_input = {}, $setSelectFieldConfig: select_field_configs_set_input = {}, $deleteOptionIds: [uuid!] = [], $insertOptions: [select_field_options_insert_input!]! = []) {
  update_field_configs_by_pk(
    pk_columns: {id: $fieldConfigId}
    _set: $setFieldConfig
  ) {
    id
  }
  update_select_field_configs_by_pk(
    pk_columns: {field_config_id: $fieldConfigId}
    _set: $setSelectFieldConfig
  ) {
    field_config_id
  }
  delete_select_field_options(where: {id: {_in: $deleteOptionIds}}) {
    returning {
      id
    }
  }
  insert_select_field_options(objects: $insertOptions) {
    returning {
      id
      value
    }
  }
}
    ` as string &
  TypedDocumentNode<UpdateSelectFieldConfigMutation, UpdateSelectFieldConfigMutationVariables>;
export const DeleteFieldConfigDocument = `
    mutation DeleteFieldConfig($id: uuid!) {
  delete_field_configs_by_pk(id: $id) {
    id
  }
}
    ` as string & TypedDocumentNode<DeleteFieldConfigMutation, DeleteFieldConfigMutationVariables>;
export const InsertFieldValueDocument = `
    mutation InsertFieldValue($fieldValue: field_values_insert_input!) {
  insert_field_values_one(object: $fieldValue) {
    id
  }
}
    ` as string & TypedDocumentNode<InsertFieldValueMutation, InsertFieldValueMutationVariables>;
export const UpdateTextFieldValueDocument = `
    mutation UpdateTextFieldValue($id: uuid!, $value: String!) {
  update_text_field_values_by_pk(
    pk_columns: {field_value_id: $id}
    _set: {value: $value}
  ) {
    field_value_id
  }
}
    ` as string &
  TypedDocumentNode<UpdateTextFieldValueMutation, UpdateTextFieldValueMutationVariables>;
export const SetSelectFieldValuesDocument = `
    mutation SetSelectFieldValues($input: SetSelectFieldValuesInput!) {
  set_select_field_values(input: $input) {
    deletedFieldValueIds
    insertedFieldValueIds
  }
}
    ` as string &
  TypedDocumentNode<SetSelectFieldValuesMutation, SetSelectFieldValuesMutationVariables>;
export const DeleteFieldValueDocument = `
    mutation DeleteFieldValue($id: uuid!) {
  delete_field_values_by_pk(id: $id) {
    id
  }
}
    ` as string & TypedDocumentNode<DeleteFieldValueMutation, DeleteFieldValueMutationVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetFieldConfigById: build.query<GetFieldConfigByIdQuery, GetFieldConfigByIdQueryVariables>({
      query: (variables) => ({ document: GetFieldConfigByIdDocument, variables }),
    }),
    GetFieldConfigs: build.query<GetFieldConfigsQuery, GetFieldConfigsQueryVariables>({
      query: (variables) => ({ document: GetFieldConfigsDocument, variables }),
    }),
    GetFieldConfigWithValues: build.query<
      GetFieldConfigWithValuesQuery,
      GetFieldConfigWithValuesQueryVariables
    >({
      query: (variables) => ({ document: GetFieldConfigWithValuesDocument, variables }),
    }),
    InsertFieldConfig: build.mutation<
      InsertFieldConfigMutation,
      InsertFieldConfigMutationVariables
    >({
      query: (variables) => ({ document: InsertFieldConfigDocument, variables }),
    }),
    UpdateFieldConfig: build.mutation<
      UpdateFieldConfigMutation,
      UpdateFieldConfigMutationVariables
    >({
      query: (variables) => ({ document: UpdateFieldConfigDocument, variables }),
    }),
    UpdateFieldConfigs: build.mutation<
      UpdateFieldConfigsMutation,
      UpdateFieldConfigsMutationVariables
    >({
      query: (variables) => ({ document: UpdateFieldConfigsDocument, variables }),
    }),
    UpdateSelectFieldConfig: build.mutation<
      UpdateSelectFieldConfigMutation,
      UpdateSelectFieldConfigMutationVariables
    >({
      query: (variables) => ({ document: UpdateSelectFieldConfigDocument, variables }),
    }),
    DeleteFieldConfig: build.mutation<
      DeleteFieldConfigMutation,
      DeleteFieldConfigMutationVariables
    >({
      query: (variables) => ({ document: DeleteFieldConfigDocument, variables }),
    }),
    InsertFieldValue: build.mutation<InsertFieldValueMutation, InsertFieldValueMutationVariables>({
      query: (variables) => ({ document: InsertFieldValueDocument, variables }),
    }),
    UpdateTextFieldValue: build.mutation<
      UpdateTextFieldValueMutation,
      UpdateTextFieldValueMutationVariables
    >({
      query: (variables) => ({ document: UpdateTextFieldValueDocument, variables }),
    }),
    SetSelectFieldValues: build.mutation<
      SetSelectFieldValuesMutation,
      SetSelectFieldValuesMutationVariables
    >({
      query: (variables) => ({ document: SetSelectFieldValuesDocument, variables }),
    }),
    DeleteFieldValue: build.mutation<DeleteFieldValueMutation, DeleteFieldValueMutationVariables>({
      query: (variables) => ({ document: DeleteFieldValueDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useGetFieldConfigByIdQuery,
  useLazyGetFieldConfigByIdQuery,
  useGetFieldConfigsQuery,
  useLazyGetFieldConfigsQuery,
  useGetFieldConfigWithValuesQuery,
  useLazyGetFieldConfigWithValuesQuery,
  useInsertFieldConfigMutation,
  useUpdateFieldConfigMutation,
  useUpdateFieldConfigsMutation,
  useUpdateSelectFieldConfigMutation,
  useDeleteFieldConfigMutation,
  useInsertFieldValueMutation,
  useUpdateTextFieldValueMutation,
  useSetSelectFieldValuesMutation,
  useDeleteFieldValueMutation,
} = injectedRtkApi;

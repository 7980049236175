import { datadogLogs } from '@datadog/browser-logs';
import { useDeleteControlMutation } from '@main/graphql/mutations/DeleteControl.generated';
import { toError } from '@main/shared/utils';
import { errorToast, successToast, useAlertDialog } from '@main/ui';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { getCurrentUserSelectedOrgRole } from '../user/slice';

export function useDeleteControl() {
  const { t } = useTranslation();
  const { openDialog } = useAlertDialog();

  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canDeleteControl = !!userRole.permissionMap?.create_controls;

  const [deleteControlById] = useDeleteControlMutation();

  const deleteControl = useCallback(
    (controlId: string, onDelete?: () => void) => {
      openDialog({
        dialogHeader: t('controls.alert.delete.header'),
        dialogContent: t('controls.alert.delete.content'),
        confirmAction: {
          children: t('controls.alert.delete.confirm'),
          onClick: async () => {
            try {
              await deleteControlById({ controlId }).unwrap();
              successToast(t('successMessages.deleteSucceeded', { entity: t('entities.control') }));
              onDelete?.();
            } catch (error) {
              errorToast(t('errorMessages.deleteFailed', { entity: t('entities.control') }));
              datadogLogs.logger.error('Control delete failed', {}, toError(error));
            }
          },
        },
      });
    },
    [t, openDialog, deleteControlById],
  );

  return canDeleteControl ? deleteControl : null;
}

import {
  Notification_Delivery_Types_Enum,
  Notification_Types_Enum,
} from '@main/graphql/types.generated';
import { TFunction } from 'i18next';

type NotificationDeliveryType = {
  [K in Notification_Delivery_Types_Enum]: {
    value: boolean;
    isNotAvailable?: boolean;
    valueWhenNotAvailable?: boolean;
  };
};

export type Notification = NotificationDeliveryType & {
  name: Notification_Types_Enum;
  group: string;
};

type NotificationGroup = {
  [K in Notification_Types_Enum]: Notification;
};

const notifications = Object.values(Notification_Types_Enum);

export const getMappedNotifications = (t?: TFunction) => {
  return notifications.reduce<NotificationGroup>((acc, notification) => {
    const newMap = {
      ...acc,
      [notification]: {
        group: t ? t('notifications.groups.notificationType') : 'Notification Type',
        name: notification,
        platform: {
          value: true,
        },
        email: {
          value: true,
        },
      } satisfies Notification,
    };

    // following notifications have no email delivery
    if (
      notification === Notification_Types_Enum.OverdueTasksAssignedToYou ||
      notification === Notification_Types_Enum.OverdueTasksCreatedByYou
    ) {
      newMap[notification].email.isNotAvailable = true;
      newMap[notification].email.valueWhenNotAvailable = false;
    }

    return newMap;
  }, {} as NotificationGroup);
};

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type GetAvailableFrameworksForOrganizationQueryVariables = Types.Exact<{
  orgId: Types.Scalars['uuid']['input'];
}>;

export type GetAvailableFrameworksForOrganizationQuery = {
  __typename?: 'query_root';
  templateFrameworks: Array<{ __typename: 'template_frameworks'; id: string; name: string }>;
  customFrameworks: Array<{ __typename: 'frameworks'; id: string; name: string }>;
};

export const GetAvailableFrameworksForOrganizationDocument = `
    query GetAvailableFrameworksForOrganization($orgId: uuid!) {
  templateFrameworks: template_frameworks(
    order_by: {name: asc}
    where: {active: {_eq: true}, organization_template_frameworks: {organization_id: {_eq: $orgId}}}
  ) {
    id
    name
    __typename
  }
  customFrameworks: frameworks(
    order_by: {name: asc}
    where: {organization_id: {_eq: $orgId}}
  ) {
    id
    name
    __typename
  }
}
    ` as string &
  TypedDocumentNode<
    GetAvailableFrameworksForOrganizationQuery,
    GetAvailableFrameworksForOrganizationQueryVariables
  >;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetAvailableFrameworksForOrganization: build.query<
      GetAvailableFrameworksForOrganizationQuery,
      GetAvailableFrameworksForOrganizationQueryVariables
    >({
      query: (variables) => ({
        document: GetAvailableFrameworksForOrganizationDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useGetAvailableFrameworksForOrganizationQuery,
  useLazyGetAvailableFrameworksForOrganizationQuery,
} = injectedRtkApi;

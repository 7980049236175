import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { BLUR_COMMAND, COMMAND_PRIORITY_EDITOR, LexicalEditor } from 'lexical';
import { useEffect } from 'react';

export interface BlurEditorPluginProps {
  onBlur(editor: LexicalEditor): void;
}

export function BlurEditorPlugin({ onBlur }: BlurEditorPluginProps) {
  const [editor] = useLexicalComposerContext();

  useEffect(
    () =>
      editor.registerCommand(
        BLUR_COMMAND,
        () => {
          onBlur(editor);
          return false;
        },
        COMMAND_PRIORITY_EDITOR,
      ),
    [editor, onBlur],
  );

  return null;
}

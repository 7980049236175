/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type UnlinkControlFromRiskMutationVariables = Types.Exact<{
  riskControlId: Types.Scalars['uuid']['input'];
}>;

export type UnlinkControlFromRiskMutation = {
  __typename?: 'mutation_root';
  delete_risk_controls_by_pk?: { __typename?: 'risk_controls'; control_id: string };
};

export const UnlinkControlFromRiskDocument = `
    mutation UnlinkControlFromRisk($riskControlId: uuid!) {
  delete_risk_controls_by_pk(id: $riskControlId) {
    control_id
  }
}
    ` as string &
  TypedDocumentNode<UnlinkControlFromRiskMutation, UnlinkControlFromRiskMutationVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    UnlinkControlFromRisk: build.mutation<
      UnlinkControlFromRiskMutation,
      UnlinkControlFromRiskMutationVariables
    >({
      query: (variables) => ({ document: UnlinkControlFromRiskDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useUnlinkControlFromRiskMutation } = injectedRtkApi;

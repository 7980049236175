import { createSelector } from '@reduxjs/toolkit';

import { AppRootState } from '../../store';
import { api as getTaskApi } from './get-task.generated';
import { TaskLinkedEntityFragment } from './tasks.fragment.generated';

const getTaskApiData = createSelector(
  (state: AppRootState, taskId: string) => ({ state, taskId }),
  ({ state, taskId }) => {
    return getTaskApi.endpoints.GetTask.select({
      taskId,
    })(state).data?.tasks_by_pk;
  },
);

export type TaskLinkedEntity = 'control' | 'risk' | 'vendor' | 'policy';
export type LinkedEntityObject = Record<
  TaskLinkedEntity,
  {
    id: string;
    name?: string;
    internal_id?: string;
  }[]
>;

export const getLinkedEntities = <T extends TaskLinkedEntityFragment>(task: T) => {
  const linkedEntities: LinkedEntityObject = {
    control: [],
    risk: [],
    vendor: [],
    policy: [],
  };

  task?.controls.forEach(({ control }) =>
    linkedEntities.control.push({
      ...control,
    }),
  );
  task?.risks.forEach(({ risk }) =>
    linkedEntities.risk.push({
      ...risk,
    }),
  );
  task?.vendors.forEach(({ vendor }) =>
    linkedEntities.vendor.push({
      ...vendor,
    }),
  );
  task?.tasks_policy_tasks.forEach(({ policy_tasks_policy }) =>
    linkedEntities.policy.push({
      ...policy_tasks_policy,
    }),
  );

  return linkedEntities;
};

export const getMappedTask = createSelector(getTaskApiData, (task) => {
  if (!task) {
    return;
  }

  const linkedEntities = getLinkedEntities(task);

  return {
    ...task,
    linkedEntities,
  };
});

import { Route } from '@tanstack/react-router';

import { TaskCenter } from '../../features/tasks/task-center';
import { authLayout } from '../login';

export const tasksRoute = new Route({
  getParentRoute: () => authLayout,
  path: 'tasks',
  component: TaskCenter,
});

import { AnyFunction } from '@main/shared/types';
import { useCallback, useRef } from 'react';

/**
 * Returns a stable version of the callback that never changes but calls latest provided callback.
 */
export function useStableCallback<TFn extends AnyFunction>(callback?: TFn) {
  const cbRef = useRef(callback);
  cbRef.current = callback;
  return useCallback((...args: Parameters<TFn>) => cbRef.current?.(...args) as ReturnType<TFn>, []);
}

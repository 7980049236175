/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type InsertTaskMutationVariables = Types.Exact<{
  object: Types.Control_Tasks_Insert_Input;
}>;

export type InsertTaskMutation = {
  __typename?: 'mutation_root';
  insert_control_tasks_one?: { __typename?: 'control_tasks'; task_id: string };
};

export const InsertTaskDocument = `
    mutation InsertTask($object: control_tasks_insert_input!) {
  insert_control_tasks_one(object: $object) {
    task_id
  }
}
    ` as string & TypedDocumentNode<InsertTaskMutation, InsertTaskMutationVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    InsertTask: build.mutation<InsertTaskMutation, InsertTaskMutationVariables>({
      query: (variables) => ({ document: InsertTaskDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useInsertTaskMutation } = injectedRtkApi;

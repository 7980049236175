/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type GetEvidenceVersionsListByEvidenceIdQueryVariables = Types.Exact<{
  evidenceId: Types.Scalars['uuid']['input'];
}>;

export type GetEvidenceVersionsListByEvidenceIdQuery = {
  __typename?: 'query_root';
  evidence_versions: Array<{
    __typename?: 'evidence_versions';
    url?: string;
    validity_start: string;
    updated_by?: string;
    created_by?: string;
    id: string;
    is_current: boolean;
    policy_version_id?: string;
    updated_at: string;
    evidence_version_file?: {
      __typename?: 'evidence_version_file';
      file: { __typename?: 'files'; name?: string; size?: number; id: string };
    };
    evidence: {
      __typename?: 'evidences';
      evidence_policy?: {
        __typename?: 'evidence_policies';
        policy: { __typename?: 'policies'; id: string; name?: string };
      };
    };
  }>;
};

export const GetEvidenceVersionsListByEvidenceIdDocument = `
    query GetEvidenceVersionsListByEvidenceId($evidenceId: uuid!) {
  evidence_versions(
    where: {evidence_id: {_eq: $evidenceId}}
    order_by: {validity_start: desc}
  ) {
    url
    validity_start
    updated_by
    created_by
    id
    is_current
    policy_version_id
    updated_at
    evidence_version_file {
      file {
        name
        size
        id
      }
    }
    evidence {
      evidence_policy {
        policy {
          id
          name
        }
      }
    }
  }
}
    ` as string &
  TypedDocumentNode<
    GetEvidenceVersionsListByEvidenceIdQuery,
    GetEvidenceVersionsListByEvidenceIdQueryVariables
  >;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetEvidenceVersionsListByEvidenceId: build.query<
      GetEvidenceVersionsListByEvidenceIdQuery,
      GetEvidenceVersionsListByEvidenceIdQueryVariables
    >({
      query: (variables) => ({ document: GetEvidenceVersionsListByEvidenceIdDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useGetEvidenceVersionsListByEvidenceIdQuery,
  useLazyGetEvidenceVersionsListByEvidenceIdQuery,
} = injectedRtkApi;

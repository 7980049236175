import {
  Box,
  Center,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Spinner,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { useGetProgramQuery } from '@main/graphql/queries/GetProgram.generated';
import { useGetProgramControlsOnAlertQuery } from '@main/graphql/queries/GetProgramControlsOnAlert.generated';
import { useGetProgramSummaryQuery } from '@main/graphql/queries/GetProgramSummary.generated';
import { encodeColumnFilters, FilterMode } from '@main/shared/url-helpers';
import { OverflowContainer, Page404, StatsCard, StatsCardProgressCircle } from '@main/ui';
import { Route, useNavigate, useParams } from '@tanstack/react-router';
import { ColumnFiltersState } from '@tanstack/react-table';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { ALERT_CENTER_TABLE_FILTER_PARAM_NAME } from '../../features/alerts/constants';
import { programColumnId } from '../../features/controls/use-table-columns';
import { ProgramControls } from '../../features/program/controls';
import { EditProgram } from '../../features/program/edit-program';
import { programIdPageLoaded } from '../../features/program/slice';
import { getCurrentUserId, getCurrentUserSelectedOrgRole } from '../../features/user/slice';
import { useAppDispatch, useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { authLayout } from '../login';

const ProgramIdPage = () => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = useRef(null);
  const userId = useAppSelector(getCurrentUserId);
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canEditProgram = userRole.permissionMap?.write_programs;

  const dispatch = useAppDispatch();
  const params = useParams({ from: programIdRoute.id });
  const navigate = useNavigate();

  const programId = params.programId;
  const { isLoading: isLoadingProgram, data: programData } = useGetProgramQuery({ programId });
  const { data: alertData, isLoading: isAlertLoading } = useGetProgramControlsOnAlertQuery(
    { programId },
    { pollingInterval: 5000 },
  );
  const { isLoading: isLoadingSummary, data: summaryData } = useGetProgramSummaryQuery(
    { programId, userId },
    { pollingInterval: 5000 },
  );

  useEffect(() => {
    dispatch(programIdPageLoaded({ programId }));
  }, [programId, dispatch]);

  const alertAmount = alertData?.program_controls_aggregate.aggregate?.count;

  const countControls = summaryData?.countControls.aggregate?.count || 0;
  const countValidControls = summaryData?.countValidControls.aggregate?.count || 0;
  const controlsPercentage =
    countControls > 0 ? Math.round((countValidControls / countControls) * 100) : 0;
  const countMyControls = summaryData?.myControls.aggregate?.count || 0;
  const countMyValidControls = summaryData?.myValidControls.aggregate?.count || 0;
  const myControlsPercentage =
    countMyControls > 0 ? Math.round((countMyValidControls / countMyControls) * 100) : 0;

  const subheadingColor = useColorModeValue('gray.700', 'gray.400');

  function openProgramAlerts() {
    const columnFilter = [
      { id: programColumnId, value: { value: [programId], mode: FilterMode.Includes } },
    ] satisfies ColumnFiltersState;

    navigate({
      to: '/alerts',
      search: () => ({
        [ALERT_CENTER_TABLE_FILTER_PARAM_NAME]: encodeColumnFilters(columnFilter),
      }),
    });
  }

  if (isLoadingProgram) {
    return (
      <Center h="100vh">
        <Spinner size="xl" thickness="4px" color="blue.500" />
      </Center>
    );
  } else if (!programData?.programs_by_pk) {
    return <Page404 />;
  }

  return (
    <Stack key={programId} spacing={6}>
      <Box>
        <OverflowContainer>
          <OverflowContainer.Tooltip
            label={programData.programs_by_pk?.name}
            fontSize="sm"
            openDelay={500}
            data-group
          >
            <Text isTruncated fontWeight="semibold" fontSize="3xl" data-group>
              {programData.programs_by_pk?.name}
            </Text>
          </OverflowContainer.Tooltip>
          {canEditProgram ? <OverflowContainer.EditTrigger onClick={onOpen} /> : null}
        </OverflowContainer>
        <Text color={subheadingColor} fontSize="sm" noOfLines={1}>
          {programData.programs_by_pk?.description}
        </Text>
      </Box>

      <SimpleGrid columns={{ base: 1, lg: 3 }} spacing={{ base: 5, lg: 6 }}>
        <StatsCard
          title={t('programs.controls')}
          stat={`${countValidControls}/${countControls}`}
          isLoading={isLoadingSummary}
        >
          <StatsCardProgressCircle percent={controlsPercentage} isLoading={isLoadingSummary} />
        </StatsCard>
        <StatsCard
          title={t('programs.myControls')}
          stat={`${countMyValidControls}/${countMyControls}`}
          isLoading={isLoadingSummary}
        >
          <StatsCardProgressCircle percent={myControlsPercentage} isLoading={isLoadingSummary} />
        </StatsCard>
        <StatsCard
          title={t('dashboard.alerts')}
          stat={`${alertAmount}`}
          isLoading={isAlertLoading}
          card={{ cursor: 'pointer', onClick: openProgramAlerts }}
        />
      </SimpleGrid>
      <ProgramControls />
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        initialFocusRef={initialRef}
        isCentered
        size="xl"
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('programs.edit.heading')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody p={0}>
            <EditProgram closeModal={onClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Stack>
  );
};

export const programIdRoute = new Route({
  getParentRoute: () => authLayout,
  path: 'programs/$programId',
  component: ProgramIdPage,
});

import {
  Box,
  Button,
  ButtonGroup,
  Center,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Icon,
  IconButton,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import {
  ArchiveBoxIcon,
  BellIcon,
  Cog8ToothIcon,
  InboxArrowDownIcon,
  InboxIcon,
  RectangleStackIcon,
} from '@heroicons/react/24/outline';
import { toError } from '@main/shared/utils';
import { EmptyPlaceholder, errorToast, useDrawerAnimation } from '@main/ui';
import { Link } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { getCurrentUserId } from '../user/slice';
import {
  api as getAllNotificationsApi,
  api as getArchivedNotificationsApi,
  api as getInboxNotificationsApi,
  useGetAllNotificationsQuery,
  useGetArchivedNotificationsQuery,
  useGetInboxNotificationsQuery,
} from './get-notifications.generated';
import { NotificationItem } from './notification-item';
import { useNotificationsSubscription } from './unread-notification-subscription';
import { useUpdateNotificationMutation } from './update-notification.generated';

export const NotificationCenter = () => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { motionProps } = useDrawerAnimation();
  const userId = useAppSelector(getCurrentUserId);
  const { count } = useNotificationsSubscription(
    { userId },
    {
      selectFromResult: ({ data }) => ({
        count: data?.user_notifications_aggregate.aggregate?.count ?? 0,
      }),
    },
  );
  const { notificationIds, refetch: refetchNotification } = useGetInboxNotificationsQuery(
    {
      userId,
    },
    {
      selectFromResult: ({ data }) => ({
        notificationIds: data?.user_notifications.map((notification) => notification.id) ?? [],
      }),
      skip: !isOpen,
    },
  );
  const [updateNotification] = useUpdateNotificationMutation();

  const tabColor = useColorModeValue('gray.400', 'gray.500');
  const activeTabColor = useColorModeValue('gray.700', 'white');
  const activeTabIndicatorColor = useColorModeValue('blue.500', 'purple.400');
  const tabListBorderColor = useColorModeValue('gray.200', 'gray.600');
  const tabListBgColor = useColorModeValue('white', 'gray.700');
  const actionButtonColor = useColorModeValue('gray.800', 'gray.300');

  const markAllAsRead = async () => {
    try {
      await updateNotification({
        notificationIds: notificationIds,
        input: {
          read_at: new Date().toISOString(),
        },
      });
      refetchNotification();
    } catch (error) {
      errorToast(t('errorMessages.updateFailed', { entity: t('entities.notification') }));
      datadogLogs.logger.error(
        'Updating multiple notifications failed',
        { notificationIds, value: new Date().toISOString() },
        toError(error),
      );
    }
  };

  const archiveAll = async () => {
    try {
      await updateNotification({
        notificationIds: notificationIds,
        input: {
          archived_at: new Date().toISOString(),
          read_at: new Date().toISOString(),
        },
      });
      refetchNotification();
    } catch (error) {
      errorToast(t('errorMessages.updateFailed', { entity: t('entities.notification') }));
      datadogLogs.logger.error(
        'Updating multiple notifications failed',
        { notificationIds, value: new Date().toISOString() },
        toError(error),
      );
    }
  };

  return (
    <>
      <Center position={'relative'} cursor={'pointer'} onClick={onOpen} w={9} h={9}>
        <IconButton
          color={'gray.500'}
          variant={'unstyled'}
          size={'xs'}
          aria-label="Open notification drawer"
          icon={<Icon as={BellIcon} w={6} h={6} />}
        />
        {count ? (
          <Center
            position={'absolute'}
            bgColor={'red.500'}
            rounded={'md'}
            w={'18px'}
            h={'18px'}
            top={0}
            right={0}
            fontSize={'10px'}
            color={'white'}
          >
            {count}
          </Center>
        ) : null}
      </Center>
      <Drawer size={'sm'} isOpen={isOpen} onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent motionProps={motionProps}>
          <DrawerCloseButton />
          <DrawerHeader fontSize={'xl'} fontWeight={'bold'}>
            {t('notification.header')}
          </DrawerHeader>
          <DrawerBody p={0}>
            <Tabs variant="unstyled" isLazy position="relative" isFitted>
              <Box position={'sticky'} top={0} zIndex={1}>
                <TabList
                  px={5}
                  borderBottom={'1px'}
                  borderColor={tabListBorderColor}
                  bg={tabListBgColor}
                  position="relative"
                >
                  {notificationTabItems.map((tab) => (
                    <Tab
                      key={tab.label}
                      gap={2}
                      px={5}
                      fontSize={'sm'}
                      _selected={{ color: activeTabColor }}
                      textColor={tabColor}
                    >
                      <Icon boxSize={5} as={tab.icon} />
                      <Text>{t(`notification.tabs.${tab.label}`)}</Text>
                      {tab.label === 'inbox' && count ? (
                        <Text
                          bgColor={'blue.500'}
                          px={'6.5px'}
                          rounded={'md'}
                          color={'white'}
                          fontSize={'xs'}
                        >
                          {count}
                        </Text>
                      ) : null}
                    </Tab>
                  ))}
                </TabList>
                <TabIndicator mt="-2px" height="2px" bg={activeTabIndicatorColor} />
              </Box>
              <TabPanels>
                <TabPanel p={0}>
                  <InboxTab onClose={onClose} />
                </TabPanel>
                <TabPanel p={0}>
                  <ArchiveTab onClose={onClose} />
                </TabPanel>
                <TabPanel p={0}>
                  <AllNotificationsTab onClose={onClose} />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </DrawerBody>
          <DrawerFooter borderTopWidth="1px" justifyContent={'space-between'} p={3}>
            <ButtonGroup spacing={3}>
              <Button
                variant="outline"
                onClick={markAllAsRead}
                textColor={actionButtonColor}
                fontSize={'sm'}
                fontWeight={'medium'}
              >
                {t('notification.actions.markAllAsRead')}
              </Button>
              <Button
                variant="outline"
                onClick={archiveAll}
                textColor={actionButtonColor}
                fontSize={'sm'}
                fontWeight={'medium'}
              >
                {t('notification.actions.archiveAll')}
              </Button>
            </ButtonGroup>
            <Link to="/settings/account/notifications" onClick={onClose}>
              <IconButton
                variant={'outline'}
                aria-label="Notification settings"
                icon={<Icon boxSize={5} as={Cog8ToothIcon} />}
              />
            </Link>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export const InboxTab = ({ onClose }: { onClose: () => void }) => {
  const dispatch = useAppDispatch();
  const userId = useAppSelector(getCurrentUserId);
  const { data } = useGetInboxNotificationsQuery(
    {
      userId,
    },
    {
      refetchOnMountOrArgChange: true,
      pollingInterval: 5000,
    },
  );

  return data?.user_notifications.length === 0 ? (
    <EmptyTab />
  ) : (
    data?.user_notifications.map((notification) => (
      <NotificationItem
        key={notification.id}
        notification={notification}
        refetchNotifications={() =>
          dispatch(
            getInboxNotificationsApi.endpoints.GetInboxNotifications.initiate(
              {
                userId,
              },
              {
                subscribe: false,
                forceRefetch: true,
              },
            ),
          )
        }
        onClose={onClose}
      />
    ))
  );
};

export const ArchiveTab = ({ onClose }: { onClose: () => void }) => {
  const dispatch = useAppDispatch();
  const userId = useAppSelector(getCurrentUserId);
  const { data } = useGetArchivedNotificationsQuery(
    {
      userId,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  return data?.user_notifications.length === 0 ? (
    <EmptyTab />
  ) : (
    data?.user_notifications.map((notification) => (
      <NotificationItem
        key={notification.id}
        notification={notification}
        refetchNotifications={() =>
          dispatch(
            getArchivedNotificationsApi.endpoints.GetArchivedNotifications.initiate(
              {
                userId,
              },
              {
                subscribe: false,
                forceRefetch: true,
              },
            ),
          )
        }
        onClose={onClose}
      />
    ))
  );
};

export const AllNotificationsTab = ({ onClose }: { onClose: () => void }) => {
  const dispatch = useAppDispatch();
  const userId = useAppSelector(getCurrentUserId);
  const { data } = useGetAllNotificationsQuery(
    {
      userId,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  return data?.user_notifications.length === 0 ? (
    <EmptyTab />
  ) : (
    data?.user_notifications.map((notification) => (
      <NotificationItem
        key={notification.id}
        notification={notification}
        refetchNotifications={() =>
          dispatch(
            getAllNotificationsApi.endpoints.GetAllNotifications.initiate(
              {
                userId,
              },
              {
                subscribe: false,
                forceRefetch: true,
              },
            ),
          )
        }
        onClose={onClose}
      />
    ))
  );
};

const notificationTabItems = [
  {
    label: 'inbox',
    icon: InboxArrowDownIcon,
  },
  {
    label: 'archived',
    icon: ArchiveBoxIcon,
  },
  {
    label: 'all',
    icon: RectangleStackIcon,
  },
] as const;

const EmptyTab = () => {
  const { t } = useTranslation();

  return (
    <EmptyPlaceholder py={8}>
      <EmptyPlaceholder.Icon as={InboxIcon} />
      <EmptyPlaceholder.Content>
        <EmptyPlaceholder.Heading>{t('notification.empty.heading')}</EmptyPlaceholder.Heading>
        <EmptyPlaceholder.Subheading>
          {t('notification.empty.subheading')}
        </EmptyPlaceholder.Subheading>
      </EmptyPlaceholder.Content>
    </EmptyPlaceholder>
  );
};

import { Client, ClientOptions, createClient } from 'graphql-ws';

import { graphqlUrl, RequiredGraphqlState } from './shared-api';

interface RestartableClient extends Client {
  restart(): void;
}

interface SubscriptionHandlers {
  next: (data: any) => void;
  error: (error: any) => void;
  complete: () => void;
}

interface SubscriptionOptions extends RequiredGraphqlState {
  query: string;
  variables?: Record<string, any>;
}

interface SubscriptionEntry {
  options: SubscriptionOptions;
  handlers: SubscriptionHandlers;
  unsubscribe?: () => void;
}

export class SubscriptionManager {
  private static instance: SubscriptionManager;
  private client: RestartableClient | null = null;
  private activeSubscriptions: Map<string, SubscriptionEntry> = new Map();
  private shouldRefreshToken = false;
  private isTabActive = true;
  private pauseTimeoutId: NodeJS.Timeout | null = null;
  private pauseDelay = 2 * 60 * 1000; // 2 minutes

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {
    this.initializeVisibilityChangeHandler();
  }

  public static getInstance(): SubscriptionManager {
    if (!SubscriptionManager.instance) {
      SubscriptionManager.instance = new SubscriptionManager();
    }
    return SubscriptionManager.instance;
  }

  private initializeVisibilityChangeHandler(): void {
    document.addEventListener('visibilitychange', this.handleVisibilityChange.bind(this));
  }

  private handleVisibilityChange(): void {
    if (document.hidden || document.visibilityState === 'hidden') {
      this.schedulePauseSubscriptions();
    } else {
      this.clearPauseTimeout();
      this.resumeAllSubscriptions();
    }
  }

  private schedulePauseSubscriptions(): void {
    this.pauseTimeoutId = setTimeout(() => {
      this.pauseAllSubscriptions();
    }, this.pauseDelay);
  }

  private clearPauseTimeout(): void {
    if (this.pauseTimeoutId) {
      clearTimeout(this.pauseTimeoutId);
      this.pauseTimeoutId = null;
    }
  }

  private pauseAllSubscriptions(): void {
    this.activeSubscriptions.forEach((entry) => {
      if (entry.unsubscribe) {
        entry.unsubscribe();
      }
    });
    this.isTabActive = false;
  }

  private resumeAllSubscriptions(): void {
    if (this.isTabActive) {
      return;
    }

    this.activeSubscriptions.forEach((entry, id) => {
      const { options, handlers } = entry;
      this.subscribe(id, options, handlers);
    });

    this.isTabActive = true;
  }

  private createRestartableClient(options: ClientOptions): RestartableClient {
    let restartRequested = false;
    let timedOut: NodeJS.Timeout;
    let restart = () => {
      restartRequested = true;
    };

    const client = createClient({
      ...options,
      keepAlive: 10_000, // ping server every 10 seconds
      on: {
        ...options.on,
        opened: (originalSocket) => {
          const socket = originalSocket as WebSocket;
          options.on?.opened?.(socket);

          restart = () => {
            if (socket.readyState === WebSocket.OPEN) {
              // if the socket is still open for the restart, do the restart
              socket.close(4205, 'Client Restart');
            } else {
              // otherwise the socket might've closed, indicate that you want
              // a restart on the next opened event
              restartRequested = true;
            }
          };

          // just in case you were eager to restart
          if (restartRequested) {
            restartRequested = false;
            restart();
          }
        },
        ping: (received) => {
          if (!received /* sent */) {
            timedOut = setTimeout(() => {
              // a close event `4499: Terminated` is issued to the current WebSocket and an
              // artificial `{ code: 4499, reason: 'Terminated', wasClean: false }` close-event-like
              // object is immediately emitted without waiting for the one coming from `WebSocket.onclose`
              //
              // calling terminate is not considered fatal and a connection retry will occur as expected
              //
              // see: https://github.com/enisdenjo/graphql-ws/discussions/290
              client.terminate();
              restart();
            }, 5_000);
          }
        },
        pong: (received) => {
          if (received) {
            clearTimeout(timedOut);
          }
        },
        closed: (event) => {
          options.on?.closed?.(event);
          // if closed with the `4403: Forbidden` or 4400: BadRequest close event
          // the client or the server is communicating that the token
          // is no longer valid and should be therefore refreshed

          if (event instanceof CloseEvent && event.reason.includes('JWTExpired')) {
            this.shouldRefreshToken = true;
          }
        },
        error: (error) => {
          options.on?.error?.(error);

          restart();
        },
      },
    });

    return {
      ...client,
      restart: () => restart(),
    };
  }

  private getClient({ nhostClient, defaultRole }: RequiredGraphqlState): RestartableClient {
    if (!this.client) {
      this.client = this.createRestartableClient({
        url: graphqlUrl.startsWith('https')
          ? graphqlUrl.replace(/^https/, 'wss')
          : graphqlUrl.replace(/^http/, 'ws'),
        // https://github.com/nhost/nhost/blob/4ad27e9d72ce3357d0cb09f1da9280f8189ad4f1/integrations/apollo/src/index.ts#L122
        shouldRetry: () => true,
        retryAttempts: Infinity,
        retryWait: async (retries) => {
          const baseDelay = 1000;
          const maxJitter = 3000;
          return new Promise((resolve) =>
            setTimeout(
              resolve,
              Math.min(baseDelay * (2 * retries) + Math.floor(Math.random() * maxJitter), 5_000),
            ),
          );
        },
        connectionParams: async () => {
          if (this.shouldRefreshToken) {
            await nhostClient.auth.refreshSession();
            this.shouldRefreshToken = false;
          }
          const token = nhostClient.auth.getAccessToken();

          return {
            'Sec-WebSocket-Protocol': 'graphql-ws',
            headers: { authorization: `Bearer ${token}`, 'x-hasura-role': defaultRole },
          };
        },
      });

      /**
       * We don't have to handle token changed event, because hasura closes websocket connection
       * when access token expires and sends a event with code 1106, the client automatically restarts
       * during this time if the token is expired in client side we set shouldRefreshToken to true and on the next restart
       * it should be taken care of
       */
      nhostClient.auth.onAuthStateChanged((event) => {
        if (event === 'SIGNED_OUT') {
          this.client?.dispose();
          this.client = null;
        }
      });
    }
    return this.client;
  }

  subscribe(id: string, options: SubscriptionOptions, handlers: SubscriptionHandlers): void {
    this.activeSubscriptions.set(id, { options, handlers });

    const client = this.getClient(options);
    const unsubscribe = client.subscribe(
      { query: options.query, variables: options.variables },
      {
        next: handlers.next,
        error: handlers.error,
        complete: handlers.complete,
      },
    );

    this.activeSubscriptions.set(id, { options, handlers, unsubscribe });
  }

  unsubscribe(id: string): void {
    const entry = this.activeSubscriptions.get(id);
    if (entry && entry.unsubscribe) {
      entry.unsubscribe();
    }
    this.activeSubscriptions.delete(id);
  }
}

import {
  Box,
  Popover,
  PopoverAnchor,
  PopoverBody,
  PopoverContent,
  Text,
  UseDisclosureReturn,
  VStack,
} from '@chakra-ui/react';
import {
  GlobalSearchNoResults,
  GlobalSearchResults,
  GlobalSearchResultsContainer,
  GlobalSearchResultsProps,
  useGlobalSearchContext,
} from '@main/ui';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { AppGlobalSearchResult } from '../global-search/use-global-search';

export function EntitySearchResults(props: GlobalSearchResultsProps<AppGlobalSearchResult>) {
  const { t } = useTranslation('ui');

  return (
    <GlobalSearchResultsContainer>
      <GlobalSearchResults px={4} {...props} />
      <GlobalSearchNoResults>
        <VStack py="12" px="4" spacing="1">
          <Text fontSize="sm" fontWeight="medium">
            {t('globalSearch.noResults.heading')}
          </Text>
          <Text fontSize="xs" color="gray.500">
            {t('globalSearch.noResults.description')}
          </Text>
        </VStack>
      </GlobalSearchNoResults>
    </GlobalSearchResultsContainer>
  );
}

export interface EntitySearchResultsPopoverProps
  extends GlobalSearchResultsProps<AppGlobalSearchResult> {
  popoverDisclosure: UseDisclosureReturn;
  popoverWidth?: string;
  popoverHeight?: string;
  children?: ReactNode;
}

export function EntitySearchResultsPopover({
  popoverDisclosure,
  popoverWidth,
  popoverHeight,
  children,
  ...props
}: EntitySearchResultsPopoverProps) {
  const globalSearch = useGlobalSearchContext<AppGlobalSearchResult>();
  const canBeOpen = globalSearch.hasResults || globalSearch.isNoResults;

  return (
    <Popover
      placement="bottom"
      isOpen={canBeOpen && popoverDisclosure.isOpen}
      onClose={popoverDisclosure.onClose}
      autoFocus={false}
      returnFocusOnClose={false}
    >
      <PopoverAnchor>
        <Box w="full" h="1px"></Box>
      </PopoverAnchor>
      <PopoverContent w={popoverWidth}>
        <PopoverBody p="0" maxH={popoverHeight} overflowY="auto">
          {children ?? <EntitySearchResults {...props} />}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

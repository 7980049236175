/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type GetEvidenceLinkedControlsQueryVariables = Types.Exact<{
  evidenceId: Types.Scalars['uuid']['input'];
}>;

export type GetEvidenceLinkedControlsQuery = {
  __typename?: 'query_root';
  evidences_by_pk?: {
    __typename?: 'evidences';
    control_evidences: Array<{
      __typename?: 'control_evidences';
      id: string;
      status: ClientTypes.ControlEvidenceStatus;
      control: { __typename?: 'controls'; id: string; name?: string; internal_id?: string };
    }>;
  };
};

export const GetEvidenceLinkedControlsDocument = `
    query GetEvidenceLinkedControls($evidenceId: uuid!) {
  evidences_by_pk(id: $evidenceId) {
    control_evidences(order_by: {control: {internal_id: asc}}) {
      id
      status
      control {
        id
        name
        internal_id
      }
    }
  }
}
    ` as string &
  TypedDocumentNode<GetEvidenceLinkedControlsQuery, GetEvidenceLinkedControlsQueryVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetEvidenceLinkedControls: build.query<
      GetEvidenceLinkedControlsQuery,
      GetEvidenceLinkedControlsQueryVariables
    >({
      query: (variables) => ({ document: GetEvidenceLinkedControlsDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetEvidenceLinkedControlsQuery, useLazyGetEvidenceLinkedControlsQuery } =
  injectedRtkApi;

import { useToast } from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { useUpdateControlByIdMutation } from '@main/graphql/mutations/UpdateControlById.generated';
import { api as getOrganizationControlsApi } from '@main/graphql/queries/GetOrganizationControls.generated';
import { toError } from '@main/shared/utils';
import { errorToast } from '@main/ui';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { getCurrentUserSelectedOrg } from '../user/slice';

export function useUpdateControlOnTableHandler() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const organization = useAppSelector(getCurrentUserSelectedOrg);
  const toast = useToast();

  return useCallback(
    async (resultPromise: ReturnType<ReturnType<typeof useUpdateControlByIdMutation>[0]>) => {
      const patchResult = dispatch(
        getOrganizationControlsApi.util.updateQueryData(
          'GetOrganizationControls',
          { organizationId: organization.id },
          (draft) => {
            for (const control of draft.controls) {
              if (control.id === resultPromise.arg.originalArgs.controlId) {
                Object.assign(control, resultPromise.arg.originalArgs.updatePayload);
              }
            }
          },
        ),
      );

      try {
        await resultPromise.unwrap();
      } catch (error) {
        toast({
          status: 'error',
          title: t('errorMessages.updateFailed', { entity: 'Control' }),
          duration: 5000,
          isClosable: true,
        });
        datadogLogs.logger.error('control update failed', {}, toError(error));

        patchResult.undo();
      }
    },
    [dispatch, organization.id, toast, t],
  );
}

export function useUpdateControlOnDrawerHandler() {
  const { t } = useTranslation();

  return useCallback(
    async (resultPromise: ReturnType<ReturnType<typeof useUpdateControlByIdMutation>[0]>) => {
      try {
        return await resultPromise.unwrap();
      } catch (error) {
        errorToast(t('errorMessages.updateFailed', { entity: t('entities.control') }));
        datadogLogs.logger.error('control update failed', {}, toError(error));
        return null;
      }
    },
    [t],
  );
}

import { useState } from 'react';

import { useDownloadStorageFile } from '../../hooks';
import { UploadedFile, useLazyMultipleFilesUpload } from '../../upload-file';

export const useFileUploads = (props?: { uploadedAttachments: UploadedFile[] }) => {
  const downloadStorageFile = useDownloadStorageFile();
  const [stagedFiles, setStagedFiles] = useState<File[]>([]);

  const filesUpload = useLazyMultipleFilesUpload({
    files: props?.uploadedAttachments,
    onFilesAdd: (files) => {
      setStagedFiles((prev) => [...prev, ...files]);
    },
    onFileDelete: (name) => {
      setStagedFiles((prev) => prev.filter((attachment) => attachment.name !== name));
    },
    onFileDownload: downloadStorageFile,
  });

  const clearStagedFiles = () => {
    setStagedFiles([]);
    filesUpload.clear();
  };

  const undoDeletedFiles = () => {
    filesUpload.clearDeletedFileIds();
  };
  return { filesUpload, stagedFiles, clearStagedFiles, undoDeletedFiles };
};

import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Image,
  Input,
  Link as ChakraLink,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react';
import { useResetPassword } from '@nhost/react';
import { Link } from '@tanstack/react-router';
import { FormEvent, useState } from 'react';

const SignIn = () => {
  const [email, setEmail] = useState('');
  const bg = useBreakpointValue({ base: 'transparent', md: 'bg-surface' });
  const shadow = { base: 'none', md: useColorModeValue('md', 'dark-lg') };

  const { resetPassword, isLoading, isSent, isError, error } = useResetPassword();

  const handleOnSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await resetPassword(email, { redirectTo: '/settings/account/password' });
  };

  const disableForm = isLoading || isSent;

  return (
    <Stack spacing={8} mx={'auto'} maxW={'lg'} w={'lg'}>
      <Box
        py={{ base: '0', md: '12' }}
        px={{ base: '4', md: '10' }}
        bg={bg}
        boxShadow={shadow}
        borderRadius={{ base: 'none', md: 'xl' }}
      >
        <Box position="relative" width="100%" height="14" p={3}>
          <Image
            alt="logo"
            object-fit="contain"
            sizes="100vw"
            src={useColorModeValue('/logo.svg', '/logo_dark.svg')}
            decoding="async"
            width="100%"
            height="100%"
          />
        </Box>
        <Heading as="h1" size="md" textAlign={'center'} mt="4">
          Reset your password
        </Heading>

        {isSent ? (
          <Text my="8" textAlign="center">
            An email has been sent to <b>{email}</b>. Please follow the link in the email to reset
            your password.
          </Text>
        ) : (
          <form onSubmit={handleOnSubmit}>
            <FormControl variant="default" mt="8">
              <FormLabel variant="default">Email address</FormLabel>
              <Input
                type="email"
                size={'lg'}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={disableForm}
                required
              />
            </FormControl>

            <Button
              type="submit"
              isDisabled={disableForm}
              isLoading={isLoading}
              width={'100%'}
              mt={6}
              colorScheme={'blue'}
              size={'lg'}
            >
              Reset your password
            </Button>

            {isError ? (
              <Text mt="4" textAlign="center" color="red.500">
                {error?.message}
              </Text>
            ) : null}
          </form>
        )}
      </Box>
      <Box mt="8">
        <Text color="gray.500" textAlign="center">
          Already have an account?{' '}
          <Link to="/">
            <ChakraLink as="span" color={useColorModeValue('blue.600', 'blue.200')}>
              Sign in
            </ChakraLink>
          </Link>
        </Text>
      </Box>
    </Stack>
  );
};

export default SignIn;

import { ThemeTypings } from '@chakra-ui/react';
import { System_Roles_Enum } from '@main/graphql/types.generated';

export const STATUSES = {
  active: { value: 'Active' as const, colorScheme: 'green' },
  disabled: { value: 'Disabled' as const, colorScheme: 'gray' },
};

export const ROLES_MAP = {
  admin: { label: 'Admin' as const, colorScheme: 'orange' },
  member: { label: 'Member' as const, colorScheme: 'gray' },
  auditor: { label: 'Auditor' as const, colorScheme: 'purple' },
} satisfies Record<
  System_Roles_Enum,
  {
    label: string;
    colorScheme: ThemeTypings['colorSchemes'];
  }
>;

export function getRoleTagConfig(role: {
  id: string;
  name: string;
  system_role?: System_Roles_Enum;
}) {
  return {
    value: role.id,
    ...(role.system_role ? ROLES_MAP[role.system_role] : { label: role.name, colorScheme: 'gray' }),
  };
}
